import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/team';
const NEW_END_POINT = '/api/v1/admin/team';

export default {
    /**
     * GET: Organizers
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    index: (params) => adminHttp.get(END_POINT,{ params: params }),

    fromStage: (id, params) => adminHttp.get(`/api/v1/admin/tournament-team/stage/${ id }`, { params }),

    show: (id) => adminHttp.get(END_POINT + `/${id}`),

    store: (params) => adminHttp.post(END_POINT + '/store', params),

    update: (teamId, params) => adminHttp.put(END_POINT + `/${teamId}`, params),

    delete: (id)=> adminHttp.delete(END_POINT + `/${id}`),

    updateTeamMemberRole: (teamId, userId, roleId)=> adminHttp.post(END_POINT + `/change/member/role/${teamId}/${userId}`, {role_id: roleId}),

    storeTeamMember: (teamId, params) => adminHttp.post(END_POINT + '/store-member' + `/${teamId}`, params),

    showTeamLogs: (teamId) => adminHttp.get(END_POINT + '/log' + `/${teamId}`),

    trashed: (params) => adminHttp.get(END_POINT + '/trashed',{params: params}),

    teamLogs: (params) => adminHttp.get(END_POINT + '/logs' + `/${params.teamId}`, {params: params}),

    teamTournaments: (params) => adminHttp.get(END_POINT + '/tournaments' + `/${params.teamId}`, {params: params}),

    newDeleteTeam: (params) => adminHttp.post('/api/v1/admin/tournament-team/delete', params),

    teamTournamentLogs: (params) => adminHttp.get(NEW_END_POINT + '/entry-logs' + `/${params.teamId}`, {params: params}),

    fromTournament: (tournamentId, params) => adminHttp.get(`/api/v1/admin/tournament/teams/${ tournamentId }`, { params }),

    deleteTeamFromGroup: (teamId, params) => adminHttp.delete(`/api/v1/admin/group_teams/${teamId}`, {params: params}),

    deleteTeamFromStage: (teamId, params) => adminHttp.delete(`/api/v1/admin/stage_teams/${teamId}`, {params: params}),

    deleteTeamFromTournament: (teamId, params) => adminHttp.delete(`/api/v1/admin/tournament_teams/${teamId}`, {params: params}),
}
