export const languages =  [
    {
        id: 1,
        title: 'Английский',
        shortcode: 'en',
        fullname: 'en-US'
    },
    {
        id: 2,
        title: 'Русский',
        shortcode: 'ru',
        fullname: 'ru-RU'
    },
    {
        id: 3,
        title: 'Узбекский',
        shortcode: 'uz',
        fullname: 'uz-UZ'
    },
    {
        id: 4,
        title: 'Казахский',
        shortcode: 'kz',
        fullname: 'ru-KZ'
    },
]
