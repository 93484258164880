<template>
  <div class="posts-create">
    <Breadcrumbs main="Новости" title="Добавить новость"></Breadcrumbs>
    <div class="container">
      <b-card>
        <b-tabs class="mt-3 d-flex justify-content-md-start justify-content-center flex-wrap">
          <b-tab v-for="lang in languages"
                 :title="lang.language"
                 :key="'lang-' + lang.id"
                 @click="getLanguageFromBtn(lang.language.toLowerCase())"
                 nav-class="mr-2 mb-2">
            <template #title>
              <div class="d-flex justify-content-center">
                <img :src="lang.img" width="20" height="20" class="mr-2">
                <span>{{ lang.language }}</span>
              </div>
            </template>
          </b-tab>
        </b-tabs>

        <b-form>
          <!-- Заголовок для статей -->
          <label class="mt-3"><b>Заголовок {{ language.toUpperCase() }}</b></label>
          <ValidationProvider rules="min:2|required" v-slot="{errors}">
            <b-form-input type="text" placeholder="Заголовок" v-model="newPostTitle[language]"></b-form-input>
            <div class="text-danger">{{ errors[0] }}</div>
          </ValidationProvider>

          <!-- Мета теги для статьи -->
          <label class="mt-3"><b>SEO Мета Описание {{ language.toUpperCase() }}</b></label>
          <b-form-input type="text" placeholder="Описание" v-model="newPostMetaDescription[language]"></b-form-input>
          
          <!-- Превью статьи -->
          <label for="" class="mt-3"><b>Превью {{ language.toUpperCase() }}</b></label>
          <b-form-input type="text" placeholder="Превью" v-model="newPostPreview[language]"></b-form-input>

          <!-- Категория статьи -->
          <label class="mt-3"><b>Категория</b></label>
          <b-form-select v-model="newPostType" :options="postTypes"></b-form-select>
          
          <!-- Описание статьи -->
          <label class="mt-3"><b>Описание {{ language.toUpperCase() }}</b></label>
          <template v-for="lang in Object.keys(newPostDescription)">
            <EditorJS :key="lang + '-postDescription'"
                  v-if="language === lang"
                  v-model="newPostDescription[lang]"
                  :editorId="'postDescription-' + lang"
            />
          </template>
        </b-form>

        <!-- Выбор обложки -->
        <div class="posts-create__form-group">
          <label class="posts-create__label"><b>Выбрать обложку</b></label>
          <div 
            v-if="!previewImage"
            class="posts-create__file-drop-area"
            :class="{ 'is-dragover': isDragover }"
            @dragenter.prevent="isDragover = true"
            @dragleave.prevent="isDragover = false"
            @dragover.prevent
            @drop.prevent="handleFileDrop"
            @click="$refs.fileInput.click()"
          >
            <input 
              ref="fileInput"
              type="file"
              @change="handleFileSelect"
              style="display: none;"
              accept="image/*"
            >
            <div class="posts-create__file-drop-content">
              <img :src="require('@/assets/icons/image-drop.svg')" alt="Drop image" class="posts-create__file-icon">
              <p class="posts-create__file-text">{{ isDragover ? 'Перетащите изображение сюда' : 'Перетащите изображение сюда или нажмите, чтобы загрузить' }}</p>
            </div>
          </div>
          <div v-else class="posts-create__preview-container">
            <img :src="previewImage" class="posts-create__preview-image"/>
            <button @click="deleteImage" class="posts-create__delete-btn">Удалить</button>
          </div>
        </div>

        <b-btn variant="success" class="posts-create__submit-btn mt-5" @click.prevent="savePost">
          Сохранить
        </b-btn>
      </b-card>
    </div>
  </div>
</template>

<script>
import {PostsAdapterMixin} from "@/mixins/Posts/PostsAdapterMixin";
import {LanguageAdapterMixin} from "@/mixins/Language/LanguageAdapterMixin";
import EditorJS from "@/components/EditorJS.vue";

export default {
  name: 'PostsCreate',
  mixins: [PostsAdapterMixin, LanguageAdapterMixin],
  components: {EditorJS},

  data() {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
      isDragover: false,
      previewImage: null,
    }
  },
  methods: {
    handleFileSelect(event) {
      const file = event.target.files[0];
      this.processFile(file);
    },
    handleFileDrop(event) {
      this.isDragover = false;
      const file = event.dataTransfer.files[0];
      this.processFile(file);
    },
    processFile(file) {
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
          this.newPostLogo = file;
        };
        reader.readAsDataURL(file);
      }
    },
    deleteImage() {
      this.previewImage = null;
      this.newPostLogo = null;
    },
    savePost() {
      this.storeBlogPostsPage({
        title: this.newPostTitle,
        description: this.newPostDescription,
        preview: this.newPostPreview,
        meta_description: this.newPostMetaDescription,
        type: this.newPostType,
        logo: this.newPostLogo
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.posts-create {
  &__file-drop-area {
    width: 100%;
    height: 180px;
    border: 3px dashed #ddd;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;

    &.is-dragover {
      background-color: rgba(0, 0, 0, 0.05);
      border-color: #999;
    }
  }

  &__file-drop-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__file-text {
    color: #777777;
    max-width: 200px;
    margin: 0;
    font-size: 14px;
    line-height: 1.4;
  }

  &__file-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 10px;
    color: black;
  }

  &__submit-btn {
    margin-top: 30px;
    padding: 10px 30px;
    border-radius: 20px;
  }

  &__preview-container {
    position: relative;
    display: inline-block;
  }

  &__preview-image {
    max-width: 100%;
    margin-top: 10px;
    border-radius: 4px;
  }

  &__delete-btn {
    position: absolute;
    top: 20px;
    right: 10px;
    background-color: rgba(255, 49, 49, 0.8);
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(202, 40, 40, 0.8);
    }
  }
}
</style>