<template>
  <div>
    <b-table
        responsive
        show-empty
        stacked="md"
        :striped="true"
        :bordered="true"
        :fields="fields"
        :items="members"
        :tbody-tr-class="trClass"
        :current-page=0
        class="mt-2 d-flex">

      <template #cell(nick)="data">
        <div>{{ data.item.username }}</div>
      </template>
      <template #cell(pubg_nick)="data">
        <div>
          {{
            Object.keys(data.item.gameProfiles).length === 0 ? 'нет игрового профиля' :
                data.item.gameProfiles.find((profile) => profile.game.slug = 'pubg_mobile')?.fields.nick
          }}
        </div>
      </template>
      <template #cell(pubg_id)="data">
        <div>
          {{
            Object.keys(data.item.gameProfiles).length === 0 ? 'нет игрового профиля' :
                data.item.gameProfiles.find((profile) => profile.game.slug = 'pubg_mobile')?.fields.pubg_id
          }}
        </div>
      </template>
      <template #cell(status)="data">
        <div>{{data.item.gameProfiles.length <= 0 ? 'Не игровой/' : 'Игровой/'}}</div>
        <div>{{data.item.verified_social_accounts_count <= 0 ? 'Не активирован' : 'Активирован'}}</div>
      </template>
      <template #cell(country)="data">
        <div>{{ data.item?.country ? data.item?.country?.name : 'не указано' }}</div>
      </template>
      <template #cell(socials)="data">
        <div v-for="account in data.item?.socialAccounts" :key="'account-' + account.id">
          <div v-if="isShowProvider(account)">
            <a target="_blank"
               :href="getAccountsLink(account.provider, account).provider">
              {{ account.provider }}
            </a>
          </div>
        </div>
      </template>
      <template #cell(role)="data">
        <div class=" mb-2">{{ data.item.role_in_team.name }}</div>
        <div>
          <b-dropdown variant="primary" id="dropdown-1" text="Выбрать роль" class="">
            <b-dropdown-item>
              <b-btn variant="primary"
                     @click.prevent="changeMemberRole({ teamId:$route.params.id, userId:data.item.id, roleId:2 })"
                     :id="'makeMember' + data.item.id"
              >
                Назначить игроком
              </b-btn>
            </b-dropdown-item>
            <b-dropdown-item>
              <b-btn variant="primary"
                     @click.prevent="changeMemberRole({ teamId:$route.params.id, userId:data.item.id, roleId:3 })"
                     :id="'makeManager' + data.item.id"
              >
                Назначить менеджером
              </b-btn>
            </b-dropdown-item>
            <b-dropdown-item>
              <b-btn variant="primary"
                     @click.prevent="changeMemberRole({ teamId:$route.params.id, userId:data.item.id, roleId:1 })"
                     :id="'makeOwner' + data.item.id"
              >
                Назначить владельцем
              </b-btn>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template #cell(inspect)="data">
        <b-btn variant="primary" class="p-1" @click="showUserInfo(data.item.id)"><i
            class="fa fa-eye"></i></b-btn>
      </template>
    </b-table>
  </div>
</template>


<script>
import {UsersAdapterMixin} from "@/mixins/Users/UsersAdapterMixin";
import {TeamsAdapterMixin} from "@/mixins/Teams/TeamsAdapterMixin";
import {ACCOUNTS} from '@/constants/SocialAccounts/providers';

export default {
  name: 'PubgPlayerTable',
  mixins: [UsersAdapterMixin, TeamsAdapterMixin],
  props: {
    members: {
      type: Array,
      required: true
    },
    trClass: {
      required: true,
      type: String
    }
  },

  data() {
    return {
      fields: [
        {key: 'id', label: '№',},
        {key: 'nick', label: 'Nick',},
        {key: 'pubg_nick', label: 'Pubg nick'},
        {key: 'pubg_id', label: 'Pubg id',},
        {key: 'status', label: 'Cтатус',},
        {key: 'country', label: 'Страна',},
        {key: 'socials', label: 'Социальные сети',},
        {key: 'role', label: 'Роль',},
        {key: 'inspect', label: 'Просмотр',},
      ],
    }
  },
  methods: {
    getAccountsLink(provider, userDataForLink) {
      if (provider === 'discord') return { provider: `${ACCOUNTS[provider]}${userDataForLink.provider_id}`}
      if (provider === 'telegram') return { provider: `${ACCOUNTS[provider]}${userDataForLink.data.username}`}
      if (provider === 'email') return { provider: `${ACCOUNTS[provider]}${userDataForLink.data.email}`}
      if (provider === 'google') return { provider: `${ACCOUNTS[provider]}${userDataForLink.provider_id}`}
      return { provider: `${ACCOUNTS[provider]}${userDataForLink.provider_id}`}
    },
    isShowProvider(account) {
      return !['phone', 'whatsapp'].includes(account.provider)
    }
  }
}
</script>
