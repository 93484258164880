import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/feature';

export default {
    /**
     * GET: Show
     * @returns {Promise<AxiosResponse<any>>}
     */
    index: () => adminHttp.get(END_POINT),
}