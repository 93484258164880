import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/help-desk';

export default {
    index:()=> adminHttp.get(END_POINT),
    show: (id)=> adminHttp.get(END_POINT + `/${id}`),
    store: (params)=> adminHttp.post(END_POINT + `/store`, params),
    update: (data)=> adminHttp.put(END_POINT + `/${data.id}`, data),
    delete: (id) => adminHttp.delete(END_POINT + `/${id}`),
    changePosition: (data) => adminHttp.post(END_POINT + '/change-position', data)
}