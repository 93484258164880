<template>
  <div>
    <Breadcrumbs main="Watches" title="Создание Watch видео"/>
    <div class="container-fluid">
      <b-card>
        <div class="d-flex justify-content-between align-items-center mb-4">
          <h5>Список Watch видео</h5>
          <b-button variant="primary" @click="showCreateForm">Добавить видео</b-button>
        </div>

        <b-table
          responsive
          :items="watches"
          :fields="fields"
          :busy="isLoading"
        >
          <template #cell(actions)="row">
            <div class="d-flex justify-content-end align-items-center">
              <b-button size="sm" variant="primary" @click="editWatch(row.item)" class="mr-2">
                <i class="fa fa-edit"></i>
              </b-button>
              <b-button size="sm" variant="danger" @click="openDeleteModal(row.item.id)">
                <i class="fa fa-trash"></i>
              </b-button>
            </div>
          </template>
        </b-table>

        <b-modal v-model="showForm" :title="formMode === 'create' ? 'Добавить новое видео' : 'Редактировать видео'" size="lg">
          <b-form @submit.prevent="saveWatch">
            <div class="form-group">
              <label class="font-weight-bold">Ссылка на YouTube</label>
              <b-form-input
                v-model="currentWatch.youtube_link"
                placeholder="https://youtu.be/WxBX4N79r4w"
                :state="isYoutubeLinkValid"
              ></b-form-input>
              <b-form-invalid-feedback v-if="!isYoutubeLinkValid">
                Неверный URL YouTube
              </b-form-invalid-feedback>
            </div>

            <b-tabs>
              <b-tab v-for="lang in languages" :key="lang.id" :title="lang.language.toUpperCase()">
                <div class="mt-3">
                  <label class="font-weight-bold">Название</label>
                  <b-form-input v-model="currentWatch.title[lang.language.toLowerCase()]" :placeholder="`Название ${lang.language.toUpperCase()}`"></b-form-input>
                </div>
                
                <div class="mt-3">
                  <label class="font-weight-bold">Meta Description {{ lang.language.toUpperCase() }}</label>
                  <b-form-input v-model="currentWatch.meta_description[lang.language.toLowerCase()]" :placeholder="`Meta Description ${lang.language.toUpperCase()}`"></b-form-input>
                </div>
                
                <div class="mt-3">
                  <label class="font-weight-bold">Описание</label>
                  <ckeditor :editor="editor" v-model="currentWatch.description[lang.language.toLowerCase()]" :config="editorConfig"></ckeditor>
                </div>
              </b-tab>
            </b-tabs>
          </b-form>
          <template #modal-footer>
            <b-button variant="secondary" @click="showForm = false">Отмена</b-button>
            <b-button variant="primary" @click="saveWatch" :disabled="!isFormValid">Сохранить</b-button>
          </template>
        </b-modal>
        <b-modal v-model="showDeleteModal" title="Удаление видео" hide-footer>
          <DeleteWatchVideo 
            :params="{ id: watchToDeleteId }" 
            @closeModal="closeDeleteModal"
          />
        </b-modal>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { LanguageAdapterMixin } from "@/mixins/Language/LanguageAdapterMixin";
import DeleteWatchVideo from '@/components/modals/DeleteWatchVideo';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue';

export default {
  name: "CreateWatches",
  components: {
    DeleteWatchVideo,
    ckeditor: CKEditor.component
  },
  mixins: [LanguageAdapterMixin],
  data() {
    return {
      showForm: false,
      showDeleteModal: false,
      formMode: 'create',
      isLoading: false,
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'youtube_link', label: 'YouTube ссылка' },
        { key: 'title.ru', label: 'Название (RU)' },
        { key: 'actions', label: 'Действия' }
      ],
      currentWatch: this.getEmptyWatch(),
      watchToDeleteId: null,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
        language: 'ru',
      }
    }
  },
  computed: {
    ...mapState('moduleWatch', ['watches']),
    isYoutubeLinkValid() {
      const regex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
      return regex.test(this.currentWatch.youtube_link);
    },
    isFormValid() {
      return this.isYoutubeLinkValid;
    }
  },
  methods: {
    ...mapActions('moduleWatch', ['fetchWatches', 'createWatch', 'updateWatch', 'deleteWatch']),
    getEmptyWatch() {
      return {
        youtube_link: '',
        title: { ru: '', en: '', uz: '', kz: '' },
        meta_description: { ru: '', en: '', uz: '', kz: '' },
        description: { ru: '', en: '', uz: '', kz: '' }
      }
    },
    showCreateForm() {
      this.formMode = 'create';
      this.currentWatch = this.getEmptyWatch();
      this.showForm = true;
    },
    editWatch(watch) {
      this.formMode = 'edit';
      this.currentWatch = { ...watch };
      // Ensure meta_description exists
      if (!this.currentWatch.meta_description) {
        this.currentWatch.meta_description = { ru: '', en: '', uz: '', kz: '' };
      }
      this.showForm = true;
    },
    async saveWatch() {
      if (!this.isFormValid) {
        this.$toast.error('Неверный URL YouTube', { position: 'top-right' });
        return;
      }

      try {
        if (this.formMode === 'create') {
          await this.createWatch(this.currentWatch);
          this.$toast.success('Видео успешно добавлено', { position: 'top-right', timeout: 2000 });
        } else {
          await this.updateWatch({ id: this.currentWatch.id, watchData: this.currentWatch });
          this.$toast.success('Видео успешно обновлено', { position: 'top-right', timeout: 2000 });
        }
        this.showForm = false;
        await this.fetchWatches();
      } catch (error) {
        console.error('Error saving watch:', error);
        this.$toast.error('Ошибка при сохранении видео', { position: 'top-right', timeout: 2000 });
      }
    },
    openDeleteModal(id) {
      this.watchToDeleteId = id;
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.watchToDeleteId = null;
      this.fetchWatches();
    }
  },
  created() {
    this.isLoading = true;
    this.fetchWatches().finally(() => {
      this.isLoading = false;
    });
    this.grabLanguages();
  }
}
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-control {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.table td {
  vertical-align: middle;
}

@media (max-width: 767.98px) {
  .table td {
    padding: 0.5rem;
  }

  .btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
}

.ck-editor__editable {
  min-height: 300px;
}
</style>