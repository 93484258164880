<template>
  <div class="olympic-matches-card">
    <PairResultsData v-for="(pairResult, index) in pairs" :key="'pairResultIndex' + index"
                     :pairResult="pairResult"
                     class="content"/>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import PairResultsData from "@/components/Tournament/MLBB/components/PairResultsData.vue";

export default {
  name: "OlympicMatchesCard",
  components: {PairResultsData},
  data() {
    return {
      isShowAddMatchModal: false
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['pairs']),
  }
}
</script>

<style scoped lang="scss">
.olympic-matches-card {

  .content {
    box-shadow: 0 0 3px 0 #00000026;
    background: white;
    padding: 20px;
    border-radius: 10px;
    transition: all .2s;
    z-index: 3;
    margin-bottom: 10px;
  }
}
</style>