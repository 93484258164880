import Vue from 'vue'
import api from '/src/api/api';

const getDefaultState = () => {
    return {
        fakeUsers: null,
        totalPagesOfFakeUsers: 1,
    }
}

// initial state
const state = getDefaultState()

const actions = {
    async getFakeUsers({ commit }, data) {
        const response = await api.fakeUsers.index(data)
        commit('setFirstLevelData',{
            name: 'fakeUsers',
            value: response.data.users
        })
        commit('setFirstLevelData', {
            name: 'totalPagesOfFakeUsers',
            value: response.data.pagination.total
        })
        return response.data.users
    },
}

const mutations = {
    setFirstLevelData(state, { name, value }) {
        Vue.set(state, name, value)
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}