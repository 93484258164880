import * as mutypes from "@/store/mutation.types";
import api from "@/api/api";

export default {
    state: () => {
        return {
            help: [],
            currentHelp: {},
        }
    },
    mutations: {
        [mutypes.SET_HELP]: (state, value) => state.help = value,
        [mutypes.SET_CURRENT_HELP]: (state, value) => state.currentHelp = value,

    },
    getters: {
        getHelp: state => state.help,
        getCurrentHelp: state => state.currentHelp,

    },
    actions: {
        async indexHelpDesk({commit, getters}){
            let response = await api.helpdesk.index()
            commit(mutypes.SET_HELP, response.data.helpdesks || [])
        },
        async showHelpDesk({commit, getters}, id){
            let response = await api.helpdesk.show(id)
            commit(mutypes.SET_CURRENT_HELP, response.data.helpdesk)
        },
        async deleteHelpDesk({commit,getters}, id){
            let response = await api.helpdesk.delete(id)
            let helpdesk = getters.getHelp.filter(t => t.id !== id)
            await commit(mutypes.SET_HELP, helpdesk);
        },
        async updateHelpDesk({commit,getters},data ){
            let response = await api.helpdesk.update(data)
        },
        async storeHelpDesk({commit,getters}, params){
            let response = await api.helpdesk.store(params)
        }
    }
}