import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            newDivisions: []
        }
    },
    mutations: {
        [mutypes.SET_NEW_DIVISIONS]: (state, value) => state.newDivisions = value,
    },

    getters: {
        getNewDivisions: state => state.newDivisions,
    },

    actions: {
        grabNewDivisions: async ({commit, getters}, params) => {
            const response = await api.divisions.indexDivisions({
                slug: params.slug
            })
            commit(mutypes.SET_NEW_DIVISIONS, response.data.divisions)
        },
    }
}