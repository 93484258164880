<template>
  <div class="generate-matches-wrapper">
    <b-form-group label="Кол-во матчей" label-for="matches_count">
      <b-form-input v-model="matchesCount" id="matches_count" type="number" required />
    </b-form-group>
    <b-alert v-if="errorMessageByGeneration" show variant="danger">{{ errorMessageByGeneration }}</b-alert>
    <footer>
      <b-button @click="submitForm" :disabled="isLoadingMatchesGeneration" variant="primary">
        {{ isLoadingMatchesGeneration ? 'Генерируем...' : 'Сгенерировать' }}
      </b-button>
    </footer>
  </div>
</template>

<script>
import api from "@/api/api";
import {mapState} from "vuex";

export default {
  name: "GenerateMatchesForOlympic",
  data() {
    return {
      isLoadingMatchesGeneration: false,
      errorMessageByGeneration: null,
      matchesCount: null
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['selectedGroupId']),
  },
  methods: {
    async submitForm() {
      try {
        this.isLoadingMatchesGeneration = true
        this.errorText = null
        await api.tournament.generateOlympicMatches(this.selectedGroupId, {match_count: this.matchesCount})
        await this.$emit('closeModal')
      } catch (e) {
        this.errorMessageByGeneration = e.response.data.message || 'Не удалось сгенерировать новые матчи'
      } finally {
        this.isLoadingMatchesGeneration = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.generate-matches-wrapper {

  footer {
    border-top: 1px solid #dee2e6;
    padding-top: 15px;
    margin-top: 15px;
    text-align: right;
  }
}
</style>