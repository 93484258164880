import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/v1/admin/group-match';

export default {
    /**
     * PATCH: Rate and Kill
     * @param matchId
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    storeRate: (matchId, params) => adminHttp.patch(END_POINT + '/event' + `/${matchId}` + '/change-team', params),

    storeKill: (matchId, params) => adminHttp.patch(END_POINT + '/event' + `/${matchId}` + '/change-user', params),

    /**
     * GET: Show
     * @returns {Promise<AxiosResponse<any>>}
     */
    showEvent: (matchId) => adminHttp.get(END_POINT + '/event' + `/${matchId}`),

    /**
     * GET: Show tournament members status in event
     * @returns {Promise<AxiosResponse<any>>}
     */
    showEventTournamentStatus: (matchId) => adminHttp.get(END_POINT + '/event/status' + `/${matchId}`),

    /**
     * Get: Get group matches
     * @returns {Promise<AxiosResponse<any>>}
     */
    showMatchesBook: (id) => adminHttp.get(END_POINT + `/group/${id}`),
    
    /**
     * Post: Store match
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    storeMatch: (params) => adminHttp.post(END_POINT, params),

    /**
     * del: Delete match
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteMatch: (id) => adminHttp.delete(END_POINT + `/${id}`),

    /**
     * Post: Update matches
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateMatch: (params) => adminHttp.put(END_POINT + `/mass-update`, params),
}