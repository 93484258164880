<template>
  <div class="edit-fake-user-wrapper">
    <b-form @submit="submitForm">
      <b-form-checkbox class="mb-2"
                       v-model="formModel.type" :value="0">
         Сделать реальным пользователем
      </b-form-checkbox>
      <b-form-group label="username" label-for="name">
        <b-form-input v-model="formModel.username" id="name" type="text" required/>
      </b-form-group>
      <b-form-group label="email" label-for="email">
        <b-form-input v-model="formModel.email" id="email" type="text" required/>
      </b-form-group>
      <GameProfile v-for="gameProfile in fakeUserGameProfiles"
                   :key="'gameProfileId' + gameProfile.id"
                   :gameProfile="gameProfile"
                   :userId="formModel.id"/>
      <div class="mt-3 mb-3">
        <b-btn v-if="isShowAddGameProfileMLBB" @click="addMlbbGameProfile" variant="primary" class="mb-2">Добавить игровой профиль по MLBB</b-btn>
        <b-btn v-if="isShowAddGameProfilePubgMobile" @click="addPubgMobileGameProfile" variant="primary">Добавить игровой профиль по PUBG MOBILE</b-btn>
      </div>
      <b-alert v-if="errorText" show variant="danger">{{ errorText }}</b-alert>
      <div class="form-footer">
        <b-button :disabled="isLoadingSaveForm" type="submit" variant="primary">
          {{ isLoadingSaveForm ? 'Сохраняем...' : 'Сохранить' }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import GameProfile from "@/components/FakeUserGameProfile.vue";
import api from "@/api/api";

const FAKE_USER = 1;

export default {
  name: "EditFakeUser",
  components: { GameProfile },
  props: {
    params: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isLoadingSaveForm: false,
      errorText: '',
      realUser: 1,
      formModel: {
        id: 0,
        username: '',
        full_name: '',
        game_profiles: [],
        email: '',
        type: FAKE_USER
      },
      newGameProfiles: [],
    }
  },
  computed: {
    fakeUserGameProfiles() {
      return [...this.formModel?.game_profiles, ...this.newGameProfiles]
    },
    isShowAddGameProfilePubgMobile() {
      return !this.fakeUserGameProfiles.find(( game ) => game.slug === 'pubg_mobile')
    },
    isShowAddGameProfileMLBB() {
      return !this.fakeUserGameProfiles.find(( game ) => game.slug === 'mlbb')
    },
  },
  methods: {
    async submitForm(event) {
      try {
        event.preventDefault()
        this.isLoadingSaveForm = true
        this.errorText = null
        await api.fakeUsers.update(this.params.id, this.formModel)
        await this.$store.dispatch('moduleFakeUsers/getFakeUsers', {
          page: localStorage.getItem('pageNumberFakeUsers'),
        })
        await this.$emit('closeModal')
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSaveForm = false
      }
    },

    async getDefaultData() {
      this.newGameProfiles = []
      // Set fake user data
      const fakeUser = (await api.fakeUsers.show(this.params.id)).data.user
      this.formModel.id = fakeUser.id
      this.formModel.username = fakeUser.username
      this.formModel.full_name = fakeUser.full_name
      this.formModel.game_profiles = fakeUser.game_profiles.map((gameProfile) => ({
        id: gameProfile.id,
        fields: gameProfile.fields,
        game_id: gameProfile.game_id,
        slug: gameProfile.game.slug,
      }))
      this.formModel.email = fakeUser.email
    },
    addPubgMobileGameProfile() {
      this.newGameProfiles.push({
        user_id: this.formModel.id,
        game_id: 1,
        slug: 'pubg_mobile',
        fields: {
          pubg_id: '',
          nick: ''
        }
      })
    },
    addMlbbGameProfile() {
      this.newGameProfiles.push({
        user_id: this.formModel.id,
        game_id: 3,
        slug: 'mlbb',
        fields: {
          mlbb_id: '',
          nick: ''
        }
      })
    },
  },
  created() {
    (async () => {
      await this.getDefaultData()
    })()
  }
}
</script>

<style scoped lang="scss">
.edit-fake-user-wrapper {
  .form-group {
    width: 100%;
  }

  .form-footer {
    border-top: 1px solid #dee2e6;
    text-align: right;
    padding-top: 15px;
  }
}
</style>