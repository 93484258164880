import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            dashboard: {},
        }
    },
    mutations: {
        [mutypes.SET_DASHBOARD]: (state, value) => state.dashboard = value,
    },

    getters: {
        getDashboard: state => state.dashboard,
    },

    actions: {
        grabDashboard: async ({commit, getters}) => {
            try {
                const [
                    response,
                    notLoggerUsers,
                    lastQuarterLoggedUsersCount,
                    tournamentParticipatedTeamsCount,
                    teamsCanParticipateInTournamentCount,
                    teamMembersCountMean,
                    tournamentWithFullyRegisteredTeamsCount,
                    playedDuoTeamsCount
                ] = await Promise.all([
                    api.dashboard.index(),
                    api.dashboard.fetchNotLoggedUsersCount(),
                    api.dashboard.fetchLastQuarterLoggedUsersCount(),
                    api.dashboard.fetchTournamentParticipatedTeamsCount(),
                    api.dashboard.fetchTeamsCanParticipateInTournamentCount(),
                    api.dashboard.fetchTeamMembersCountMean(),
                    api.dashboard.fetchTournamentWithFullyRegisteredTeamsCount(),
                    api.dashboard.fetchPlayedDuoTeamsCount()
                ]);
        
                const analyticsData = {
                    ...response.data,
                    not_logger_users: notLoggerUsers.data.count,
                    last_quarter_logged_users_count: lastQuarterLoggedUsersCount.data.count,
                    tournament_participated_teams_count: tournamentParticipatedTeamsCount.data.count,
                    teams_can_participate_in_tournament_count: teamsCanParticipateInTournamentCount.data.count,
                    team_members_count_mean: teamMembersCountMean.data.count,
                    tournament_with_fully_registered_teams_count: tournamentWithFullyRegisteredTeamsCount.data.count,
                    played_duo_teams_count: playedDuoTeamsCount.data.count
                };
                commit(mutypes.SET_DASHBOARD, analyticsData);
        
                return response.status;
            } catch (error) {
                console.error("Error fetching dashboard data:", error);
            }
        },
        
    }
}