import Vue from 'vue'
import api from '/src/api/api';
import moment from 'moment'

import { stageTypesById } from "@/common/tournamentCommon";

const getDefaultState = () => {
    return {
        tournament: null,
        selectedDashboard: null,
        selectedStageId: null,
        selectedRoundId: null,
        selectedGroupId: null,
        selectedTeamId: null,
        selectedGroupResultId: null,
        teamsByGroup: null,
        groupsWithTeamsByMLBB: null,
        pairs: [],
        selectedTeam: null,
        stageTeamsCount: null,
         
        tournamentStatistics: [],
        teamStatistics: {},
        filteredTeamIds: [] 
    }
}

// initial state
const state = getDefaultState()

const actions = {
    async getTournament({ commit }, { id }) {
        const response = await api.tournament.grabTournamentStandings(id)
        console.log('Fetched Tournament Data:', response.data.tournament);
        commit('setFirstLevelData', {
            name: 'tournament',
            value: response.data.tournament
        })
        console.log('Tournament loaded:', response.data.tournament);
        return response.data.tournament
    },
    async getTeamsByGroup({ commit }, { id }) {
        const response = await api.tournament.getTeamsByGroup({ id })
        commit('setFirstLevelData', {
            name: 'teamsByGroup',
            value: response.data.group_teams
        })
        return response.data.group_teams
    },
    async getGroupsWithTeamsByMLBB({ commit }, { roundId }) {
        if (!roundId) {
            commit('setFirstLevelData', {
                name: 'groupsWithTeamsByMLBB',
                value: []
            })
            return []
        }

        const response = await api.tournament.getGroupsWithTeamsByMLBB(roundId)
        commit('setFirstLevelData', {
            name: 'groupsWithTeamsByMLBB',
            value: response.data.data.groups
        })
        commit('setFirstLevelData', {
            name: 'stageTeamsCount',
            value: response.data.data?.stage_teams_count
        })
        return response.data.groups
    },
    async getOlympicMatches({ commit }, { groupId, teamId }) {
        const response = await api.tournament.getOlympicMatches(groupId, teamId)
        commit('setFirstLevelData', {
            name: 'pairs',
            value: response.data.pairs
        })
        return response.data.pairs
    },
    async fetchTournamentStatistics({ commit }, tournamentId) {
        try {
            const statistics = await api.adminTournamentStatistics.getTournamentStatistics(tournamentId);
            commit('setTournamentStatistics', statistics);
        } catch (error) {
            console.error('Error fetching tournament statistics:', error);
        }
    },
    async fetchTeamStatistics({ commit, state }, { tournamentId, teamId, gameId }) {
        // Check if the statistics for the team are already loaded
        if (!state.teamStatistics[teamId]) {
            try {
                const statistics = await api.adminTournamentStatistics.getTournamentTeamStatistics(tournamentId, teamId);
                commit('setTeamStatistics', { teamId, statistics });
            } catch (error) {
                console.error('Error fetching team statistics:', error);
            }
        }
    },    
    setFilteredTeamIds({ commit }, teamIds) {
        commit('setFilteredTeamIds', teamIds);
    },
    getDivisions: async (_, { id }) => (await api.divisions.getDefaultStageDivisions(id)).data,
    getStage: async (_, { id }) => (await api.tournamentStage.show(id)).data.stage,
    createStage: async (_, data) => (await api.tournamentStage.store(data)).data,
    updateStage: async (_, { id, data }) => (await api.tournamentStage.update(id, data)).data,
    deleteStage: async (_, { id }) => (await api.tournamentStage.delete(id)).data,
    createGroup: async (_, data) => (await api.tournamentStageGroup.store(data)).data,
    getGroup: async (_, { id }) => (await api.tournamentStageGroup.show(id)).data.group,
    updateGroup: async (_, { id, data }) => (await api.tournamentStageGroup.update(id, data)).data,
    deleteGroup: async (_, { id }) => (await api.tournamentStageGroup.del(id)).data,
    createRound: async (_, { stageId, ...params }) => (await api.tournamentStage.createRound(stageId, params)).data,
    updateRound: async (_, { id, data }) => (await api.tournamentStage.updateRound(id, data)).data
}

const mutations = {
    setFirstLevelData(state, { name, value }) {
        Vue.set(state, name, value)
    },
    resetState (state) {
        Object.assign(state, getDefaultState())
    },
    // NEW FUNCTIONALITY
    setTournamentStatistics(state, statistics) {
        state.tournamentStatistics = statistics;
    },
    setTeamStatistics(state, { teamId, statistics }) {
        Vue.set(state.teamStatistics, teamId, state.teamStatistics[teamId] || statistics);
    },
    setFilteredTeamIds(state, teamIds) {
        state.filteredTeamIds = teamIds;
    },
}

const getters = {
    // Stats
    tournament: state => state.tournament,
    filteredTeamIds: state => state.filteredTeamIds,
    tournamentStatistics: state => state.tournamentStatistics,
    teamStatistics: state => teamId => state.teamStatistics[teamId] || [],

    // By tournament
    gameSlug: (state) => state.tournament.game.slug,

    // By stages
    stages: (state) => state.tournament && state.tournament.stages || [],
    selectedStage: (state, getters) => state.selectedStageId && getters.stages.find(({ id }) => id === state.selectedStageId) || null,
    formatBySelectedStage: (state, getters) => stageTypesById.get(getters.selectedStage?.format_id)?.name || false,

    // By rounds
    rounds: (_, getters) => getters.selectedStage?.stage_rounds || [],
    selectedRound: (state, getters) => getters.rounds.find(({ id }) => id === state.selectedRoundId) || null,

    // By groups
    groups: (state, getters) => {
        const gameName = state.tournament.game.slug
        // By other games
        if (['mlbb'].includes(gameName) && getters.formatBySelectedStage !== 'olympic') return getters.selectedRound?.groups || []
        // By default
        return getters.selectedStage?.groups || []
    },
    selectedGroup: (state, getters) => getters.groups.find(({ id }) => id === state.selectedGroupId) || null,

    // Other
    selectedTeam: (state) => state.selectedTeamId && state.teamsByGroup && state.teamsByGroup.find(({ team: { id } }) => id === state.selectedTeamId).team,
    isCompletedTournament: (state) => {
        const currentDate = moment()
        const completeDate = state.tournament?.end_time ? moment(state.tournament?.end_time.split('+')[0], 'YYYY-MM-DDTHH:mm:ss') : null
        return completeDate ? currentDate.isAfter(completeDate) : false
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}