<template>
  <b-input @input="getListBySearch" type="search" v-model="searchModel" :placeholder="$props.placeholderName" class="search-input"/>
</template>

<script>
import lodash from 'lodash'

export default {
  name: "SearchInput",
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholderName: {
      type: String,
      default: ''
    }
  },
  computed: {
    searchModel: {
      get() { return this.value },
      set(newValue) { this.$emit('input', newValue) }
    }
  },
  methods: {
    getFilteredList() {
      this.$emit('search');
    },
    getListBySearch: lodash.debounce(async function () {
      await this.getFilteredList()
    }, 1000),
  },
}
</script>

<style scoped>
.search-input {
  width: 200px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}
</style>