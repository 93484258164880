<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Рейтинг" title="Все турниры - рейтинг"/>
      <b-card>
        <div class="d-flex justify-content-between align-items-center">
          <SearchInput v-model="searchForTournamentsRatingModel" @search="getFilteredTournamentsRating()"
                       :placeholder-name="'Поиск турниров'"/>
        </div>
        <div class="notification-for-search mb-4">Поиск по id и названию турнира</div>
        <div v-if="isLoadingRatingForTournaments" class="loading-wrapper">
          <b-spinner class="spinner"></b-spinner>
        </div>
        <div v-else>
          <b-table
              responsive
              show-empty
              stacked="md"
              :striped="striped"
              :bordered="bordered"
              :fields="fields"
              :items="tournaments"
              :tbody-tr-class="trClass"
              :current-page=0
              :per-page=10
              class="mb-5"
          >
            <template v-slot:cell(tournament)="{item}">
              {{ item.name }}
            </template>
            <template v-slot:cell(created_at)="{item}">
              {{ item.created_at }}
            </template>
            <template v-slot:cell(action)="{item}">
              <div class="align-items-center">
                <router-link :to="{ name: 'tournament_ratings_info', params: { id: item.id } }">
                    <span><b-btn variant="primary" class="mr-2 p-1 px-2 mb-1">Посмотреть</b-btn></span>
                </router-link>
              </div>
            </template>
          </b-table>
          <b-col md="6" class="p-0">
            <b-pagination
                @change="handlePageChange"
                v-model="currentPage"
                :total-rows="total"
                :per-page="10"
                class="my-0"
            ></b-pagination>
          </b-col>
        </div>

      </b-card>
    </div>
  </div>
</template>

<script>
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import {TournamentAdapterMixin} from "@/mixins/Tournament/TournamentAdapterMixin";
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: "ListTournamentsRating",
  components: {SearchInput},
  mixins: [ModalAdapterMixin, PaginationHelperMixin, TournamentAdapterMixin],
  created() {
    this.isLoadingRatingForTournaments = true
    this.recordPageToStorage(this.currentPage, this.pageKey)
    this.indexTournaments({
      pageNumber: this.getCurrentPageNumber(this.pageKey),
    }).then(() => this.isLoadingRatingForTournaments = false)
  },
  data() {
    return {
      searchForTournamentsRatingModel: '',
      pageKey: 'tournamentsRatingPageNumber',
      fields: [
        {key: 'id', label: 'id', sortable: true},
        {key: 'tournament', label: 'Турнир', sortable: true},
        {key: 'created_at', label: 'Дата создания', sortable: true},
        {key: 'action', label: 'Действия', sortable: false},
      ],
      striped: true,
      bordered: true,
      isLoadingRatingForTournaments: false
    };
  },
  methods: {
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.indexTournaments({
        pageNumber: pageNumber,
        ...(this.searchForTournamentsRatingModel && {search: this.searchForTournamentsRatingModel}),
      })
    },
    getFilteredTournamentsRating() {
      this.isLoadingRatingForTournaments = true
      this.indexTournaments({search: this.searchForTournamentsRatingModel})
          .then(() => {
            this.isLoadingRatingForTournaments = false
          })
    },
    trClass: rowData => rowData?.team_ratings_count > 0 && 'table-warning',
  },

};
</script>

<style scoped>
.loading-wrapper {
  min-height: calc(100vh - 180px);
  display: flex;
  align-items: center;
  justify-content: center;

  & > .spinner {
    scale: 2;
  }
}
</style>