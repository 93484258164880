export const NOTSENDED = 'not_sended';
export const NOTSTARTED = 'not_started';
export const LIVE = 'live';
export const ENDED = 'ended';
export const SUSPENDED = 'suspended';
export const REPLAY = 'replay';


export const MatchStatuses = {
    [NOTSENDED]: 'Не установлен',
    [NOTSTARTED]: 'Матч не начался',
    [LIVE]: 'live',
    [ENDED]: 'Матч завершился',
    [SUSPENDED]: 'Матч на паузе',
    [REPLAY]: 'Переигровка матча',
}
export const StatusesBackground = {
    [NOTSENDED]: '#d6d8db',
    [NOTSTARTED]: '#ff5500',
    [LIVE]: '#23993d',
    [ENDED]: '#8F8F8F',
    [SUSPENDED]: '#f7d52a',
    [REPLAY]: '#8F8F8F',
}