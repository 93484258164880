import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";


export const TournamentMatchAdapterMixin = {
    data() {
        return {
            id:null,
            name:null,
            login:null,
            password:'',
            date:'',
            matchIndex:null,
            status: '',
        }
    },

    computed: {
    },
    methods: {

    }
}