import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/main-banner';

export default {
    /**
     * GET: Main banner
     * @returns {Promise<AxiosResponse<any>>}
     */
    get: () => adminHttp.get(END_POINT),

    /**
     * UPDATE: Main banner
     * @returns {Promise<AxiosResponse<any>>}
     */
    update: (body) => adminHttp.post(END_POINT + '/update', body)
}
