//COMMON
export const SET_FIRST_LEVEL_DATA = 'SET_FIRST_LEVEL_DATA'

//Tournament page

export const SET_TOURNAMENTS = `SET_TOURNAMENTS`
export const STORE_TOURNAMENT = `STORE_TOURNAMENT`
export const SET_TIMEZONES = `SET_TIMEZONES`
export const SET_COUNTRIES = `SET_COUNTRIES`

export const SET_TOURNAMENT_STANDING_CURRENT_STATUS = 'SET_TOURNAMENT_STANDING_CURRENT_STATUS'

export const SET_TOURNAMENTS_PAGINATION_TOTAL = `SET_TOURNAMENTS_PAGINATION_TOTAL`
export const SET_TOURNAMENTS_PAGINATION_CURRENT_PAGE = `SET_TOURNAMENTS_PAGINATION_CURRENT_PAGE`
export const SET_TOURNAMENTS_FORMATS = 'SET_TOURNAMENTS_FORMATS'


export const SET_TOURNAMENT_GAMES = 'SET_TOURNAMENT_GAMES'
export const SET_TOURNAMENT_GAME_ID = `SET_TOURNAMENT_GAME_ID`

export const SET_TOURNAMENT_ORGANIZERS = 'SET_TOURNAMENT_ORGANIZERS'
export const SET_TOURNAMENT_ORGANIZER_ID = `SET_TOURNAMENT_ORGANIZER_ID`
export const SET_TOURNAMENT_END_REG_TIME = `SET_TOURNAMENT_END_REG_TIME`
export const SET_TOURNAMENT_REG_TIME = `SET_TOURNAMENT_REG_TIME`
export const SET_TOURNAMENT_START_TIME = `SET_TOURNAMENT_START_TIME`
export const SET_TOURNAMENT_NAME = `SET_TOURNAMENT_NAME`
export const SET_TOURNAMENT_DESCRIPTION = `SET_TOURNAMENT_DESCRIPTION`
export const SET_TOURNAMENT_RULES = `SET_TOURNAMENT_RULES`
export const SET_TOURNAMENT = `SET_TOURNAMENT`
export const SET_TOURNAMENT_SLOT_COUNT = 'SET_TOURNAMENT_SLOT_COUNT'
export const SET_TOURNAMENT_FORMATS = 'SET_TOURNAMENT_FORMATS'
export const SET_TOURNAMENT_CURRENT_FORMATS = 'SET_TOURNAMENT_CURRENT_FORMATS'

export const SET_TOURNAMENT_CURRENT_STATUS = 'SET_TOURNAMENT_CURRENT_STATUS'
export const SET_TOURNAMENT_CURRENT_COUNTRY = 'SET_TOURNAMENT_CURRENT_COUNTRY'
export const SET_TOURNAMENT_CURRENT_IMAGE = 'SET_TOURNAMENT_CURRENT_IMAGE'
export const SET_TOURNAMENT_CURRENT_FEATURE = 'SET_TOURNAMENT_CURRENT_FEATURE'
export const SET_TOURNAMENT_CURRENT_YOUTUBE_LINK = 'SET_TOURNAMENT_CURRENT_YOUTUBE_LINK'

export const SET_TOURNAMENT_CURRENT_SLUG = 'SET_TOURNAMENT_CURRENT_SLUG'

export const SET_TOURNAMENT_CURRENT_RELATED_TOURNAMENTS = 'SET_TOURNAMENT_CURRENT_RELATED_TOURNAMENTS'
export const SET_TOURNAMENT_PLACES = 'SET_TOURNAMENT_PLACES'
export const SET_TOURNAMENT_PRIZES = 'SET_TOURNAMENT_PRIZES'
export const SET_IS_HASH_TOURNAMENT = 'SET_IS_HASH_TOURNAMENT'
export const SET_WITH_COMMENTS = 'SET_WITH_COMMENTS'

export const SET_TOURNAMENT_META_TITLE = 'SET_TOURNAMENT_META_TITLE'
export const SET_TOURNAMENT_META_DESCRIPTION = 'SET_TOURNAMENT_META_DESCRIPTION'

export const SET_TOURNAMENT_TIMEZONE = 'SET_TOURNAMENT_TIMEZONE'
export const SET_TOURNAMENT_SCHEDULE = 'SET_TOURNAMENT_SCHEDULE'

export const SET_TOURNAMENT_PRIZE_FOND = 'SET_TOURNAMENT_PRIZE_FOND'


export const SET_TOURNAMENT_STAGE = 'SET_TOURNAMENT_STAGE'
export const SET_TOURNAMENT_STAGE_NAME = 'SET_TOURNAMENT_STAGE_NAME'
export const SET_TOURNAMENT_STAGE_RATING_COEFFICIENT = 'SET_TOURNAMENT_STAGE_RATING_COEFFICIENT'
export const SET_TOURNAMENT_STAGE_STATUS = 'SET_TOURNAMENT_STAGE_STATUS'
export const SET_TOURNAMENT_STAGE_THRESHOLD_RATING = 'SET_TOURNAMENT_STAGE_THRESHOLD_RATING'
export const SET_TOURNAMENT_STAGE_DIVISIONS = 'SET_TOURNAMENT_STAGE_DIVISIONS'
export const SET_TOURNAMENT_STAGE_COEF_PERSONAL_RATING = 'SET_TOURNAMENT_STAGE_COEF_PERSONAL_RATING'

export const SET_TOURNAMENT_STAGE_REPLACEMENT = 'SET_TOURNAMENT_STAGE_REPLACEMENT'
export const SET_TOURNAMENT_DIVISIONS = 'SET_TOURNAMENT_DIVISIONS'
export const SET_TOURNAMENT_CURRENT_DIVISIONS = 'SET_TOURNAMENT_CURRENT_DIVISIONS'

export const SET_TOURNAMENT_VARIATIONS = 'SET_TOURNAMENT_VARIATIONS';

export const SET_TOURNAMENT_STATISTICS = 'SET_TOURNAMENT_STATISTICS';
export const SET_TEAM_STATISTICS = 'SET_TEAM_STATISTICS';

//Tournaments outer
export const SET_TOURNAMENTS_OUTER = `SET_TOURNAMENTS_OUTER`
export const SET_TOURNAMENTS_OUTER_PAGINATION_TOTAL = `SET_TOURNAMENTS_OUTER_PAGINATION_TOTAL`
export const SET_TOURNAMENTS_OUTER_PAGINATION_CURRENT_PAGE = `SET_TOURNAMENTS_OUTER_PAGINATION_CURRENT_PAGE`
export const SET_TOURNAMENT_ORGANIZERS_OUTER = `SET_TOURNAMENT_ORGANIZERS_OUTER`
export const SET_TOURNAMENTS_OUTER_FORMATS = `SET_TOURNAMENTS_OUTER_FORMATS`


//standig teams

export const SET_TOURNAMENT_STANDING_NAME = `SET_TOURNAMENT_STANDING_NAME`
export const SET_TOURNAMENT_STANDING_GAME = `SET_TOURNAMENT_STANDING_GAME`
export const SET_TOURNAMENT_STANDING_STAGES = `SET_TOURNAMENT_STANDING_STAGES`
export const SET_TOURNAMENT_STANDING_GROUPS = `SET_TOURNAMENT_STANDING_GROUPS`
export const SET_TOURNAMENT_STANDING_TEAMS = `SET_TOURNAMENT_STANDING_TEAMS`
export const SET_TOURNAMENT_STANDING_STATUS = `SET_TOURNAMENT_STANDING_STATUS`
export const APPEND_TOURNAMENT_STANDING_RESULT_KEY = `APPEND_TOURNAMENT_STANDING_RESULT_KEY`
export const SET_TOURNAMENT_STANDING_SELECTED_STAGE_ID = `SET_TOURNAMENT_STANDING_SELECTED_STAGE_ID`
export const SET_TOURNAMENT_STANDING_SELECTED_GROUP_ID = `SET_TOURNAMENT_STANDING_SELECTED_GROUP_ID`
export const SET_TOURNAMENT_STANDING_STAGES_GROUP = `SET_TOURNAMENT_STANDING_STAGES_GROUP`
export const SET_TOURNAMENT_STANDING_STAGES_GROUP_ID = `SET_TOURNAMENT_STANDING_STAGES_GROUP_ID`
export const SET_TOURNAMENT_STANDING_STAGES_TEAM_ID = `SET_TOURNAMENT_STANDING_STAGES_TEAM_ID`
export const SET_TOURNAMENT_STANDING_STAGES_GROUP_NAME = `SET_TOURNAMENT_STANDING_STAGES_GROUP_NAME`
export const SET_TOURNAMENT_STANDING_STAGES_GROUP_SLOT_COUNT = `SET_TOURNAMENT_STANDING_STAGES_GROUP_SLOT_COUNT`
export const SET_TOURNAMENT_STANDING_STAGES_GROUP_STATUS = `SET_TOURNAMENT_STANDING_STAGES_GROUP_STATUS`

export const SET_TOURNAMENT_STAGES_GROUP_GAME_TIME = 'SET_TOURNAMENT_STAGES_GROUP_GAME_TIME'

export const SET_TOURNAMENT_SEARCH_TEAM = 'SET_TOURNAMENT_SEARCH_TEAM'
export const SET_TOURNAMENT_STANDING_MATCHES = `SET_TOURNAMENT_STANDING_MATCHES`
export const SET_TOURNAMENT_STANDING_PREV_STAGE_ID = `SET_TOURNAMENT_STANDING_PREV_STAGE_ID`

export const SET_TOURNAMENT_STANDING_SELECTED_GROUP_RESULT = `SET_TOURNAMENT_STANDING_SELECTED_GROUP_RESULT`
export const SET_TOURNAMENT_STANDING_MEMBERS = `SET_TOURNAMENT_STANDING_MEMBERS`
export const SET_TOURNAMENT_STANDING_MEMBERS_TEAM = `SET_TOURNAMENT_STANDING_MEMBERS_TEAM`

export const SET_TOURNAMENT_STANDING_TEAM_SLOTS = `SET_TOURNAMENT_STANDING_TEAM_SLOTS`

export const SELECT_TOURNAMENT_STANDING_TEAM = `SELECT_TOURNAMENT_STANDING_TEAM`
export const SELECT_TOURNAMENT_STANDING_GROUPS_FOR_NEXT_STAGE = `SELECT_TOURNAMENT_STANDING_GROUPS_FOR_NEXT_STAGE`

export const SET_CURRENT_STAGE = 'SET_CURRENT_STAGE'


export const SET_TOURNAMENT_STAGES_GROUP_NEW_NAME = 'SET_TOURNAMENT_STAGES_GROUP_NEW_NAME'
export const SET_TOURNAMENT_STAGES_GROUP_NEW_SLOT = 'SET_TOURNAMENT_STAGES_GROUP_NEW_SLOT'


export const SET_TOURNAMENT_MATCHES = `SET_TOURNAMENT_MATCHES`

export const SET_TOURNAMENT_CURRENT_MATCHES = 'SET_TOURNAMENT_CURRENT_MATCHES'
export const APPEND_TOURNAMENT_MATCH = `APPEND_TOURNAMENT_MATCH`
export const SET_TOURNAMENT_CURRENT_MATCHES_SELECTED_MATCH_ID = `SET_TOURNAMENT_CURRENT_MATCHES_SELECTED_MATCH_ID`

export const SET_TOURNAMENT_GROUP_RESULT_MATCHES = `SET_TOURNAMENT_GROUP_RESULT_MATCHES`
export const SET_TOURNAMENT_GROUP_RESULT_PlACE_PTS = `SET_TOURNAMENT_GROUP_RESULT_PlACE_PTS`
export const SELECT_TOURNAMENT_GROUP_RESULT_MATCH_ID = `SELECT_TOURNAMENT_GROUP_RESULT_MATCH_ID`
export const SET_TOURNAMENT_GROUP_MATCH_RESULTS = `SET_TOURNAMENT_GROUP_MATCH_RESULTS`
export const SET_TOURNAMENT_GROUP_MATCH_RESULT_TEAM_ID = `SET_TOURNAMENT_GROUP_MATCH_RESULT_TEAM_ID`
//TEAMS
export const SET_TEAMS = 'SET_TEAMS'
export const SET_TEAMS_ID = 'SET_TEAMS_ID'
export const SET_TEAMS_NAME = 'SET_TEAMS_NAME'
export const SET_TEAMS_GAME_ID = 'SET_TEAMS_GAME_ID'
export const SET_TEAMS_GAMES = 'SET_TEAMS_GAMES'

export const SET_TEAMS_PAGINATION = `SET_TEAMS_PAGINATION`
export const SET_TEAMS_PAGINATION_TOTAL = `SET_TEAMS_PAGINATION_TOTAL`
export const SET_TEAMS_PAGINATION_CURRENT_PAGE = `SET_TEAMS_PAGINATION_CURRENT_PAGE`

export const SET_TEAMS_MEMBERS = `SET_TEAMS_MEMBERS`
export const SET_TEAMS_CURRENT_DIVISION = `SET_TEAMS_CURRENT_DIVISION`
export const SET_TEAMS_TEAM_RATING = `SET_TEAMS_TEAM_RATING`
export const SET_TEAMS_CAPTAIN_LANG = `SET_TEAMS_CAPTAIN_LANG`
export const SET_TEAMS_SHOW = 'SET_TEAMS_SHOW'
export const SET_TEAM_LOGS = `SET_TEAM_LOGS`
export const SET_TEAM_LOGS_PAGINATION = `SET_TEAM_LOGS_PAGINATION`
export const SET_TEAM_LOGS_PAGINATION_TOTAL = `SET_TEAM_LOGS_PAGINATION_TOTAL`
export const SET_TEAM_LOGS_PAGINATION_CURRENT_PAGE = `SET_TEAM_LOGS_PAGINATION_CURRENT_PAGE`
export const SET_DELETED_TEAMS = 'SET_DELETED_TEAMS'
export const SET_DELETED_TEAMS_PAGINATION = `SET_DELETED_TEAMS_PAGINATION`
export const SET_DELETED_TEAMS_PAGINATION_TOTAL = `SET_DELETED_TEAMS_PAGINATION_TOTAL`
export const SET_DELETED_TEAMS_PAGINATION_CURRENT_PAGE = `SET_DELETED_TEAMS_PAGINATION_CURRENT_PAGE`
//USER FAKE
export const SET_USER_FAKE = 'SET_USER_FAKE'
export const SET_USER_FAKE_NAME = 'SET_USER_FAKE_NAME'
export const SET_USER_FAKE_EMAIL = 'SET_USER_FAKE_EMAIL'
export const SET_USER_FAKE_PASSWORD = 'SET_USER_FAKE_PASSWORD'
export const SET_USER_FAKE_TEAM_NAME = 'SET_USER_FAKE_TEAM_NAME'

//ban user
export const SET_USER_BAN = 'SET_USER_BAN'
export const SET_USER_SHOW_BANS = 'SET_USER_SHOW_BANS'
export const SET_GAMES = 'SET_GAMES'
export const SET_GAME_ID = 'SET_GAME_ID'
export const SET_BAN_USERS_PAGINATION_TOTAL = 'SET_BAN_USERS_PAGINATION_TOTAL'
export const SET_BAN_USERS_PAGINATION_CURRENT_PAGE = 'SET_BAN_USERS_PAGINATION_CURRENT_PAGE'


//HELPDESK
export const SET_HELP = 'SET_HELP'
export const SET_CURRENT_HELP = 'SET_CURRENT_HELP'
export const SET_HELP_PAGINATION_TOTAL = 'SET_HELP_PAGINATION_TOTAL'
export const SET_HELP_PAGINATION_CURRENT_PAGE = 'SET_HELP_PAGINATION_CURRENT_PAGE'

//POSTS
export const SET_POSTS = 'SET_POSTS'
export const SET_CURRENT_POST = 'SET_CURRENT_POST'
export const SET_POSTS_TOTAL = 'SET_POSTS_TOTAL'
export const SET_POSTS_CURRENT_PAGE = 'SET_POSTS_CURRENT_PAGE'
export const SET_POSTS_CURRENT_IMAGE = 'SET_POSTS_CURRENT_IMAGE'
export const SET_POSTS_CURRENT_PREVIEW = 'SET_POSTS_CURRENT_PREVIEW'


//AUTH

export const SET_AUTH_TOKEN = `SET_AUTH_TOKEN`
export const AUTH_LOGOUT = `AUTH_LOGOUT`
export const SET_AUTH_DATA_EMAIL = `SET_AUTH_DATA_EMAIL`
export const SET_AUTH_DATA_PASSWORD = `SET_AUTH_DATA_PASSWORD`
export const SET_AUTH_DATA = `SET_AUTH_DATA`
export const SET_AUTH_USER_NAME = `SET_AUTH_USER_NAME`
export const SET_AUTH_USER_EMAIL = `SET_AUTH_USER_EMAIL`
export const SET_USER_ORGANIZATIONS = `SET_USER_ORGANIZATIONS`
export const SET_USER_EMAIL = `SET_USER_EMAIL`
export const SET_USER_ROLES = `SET_USER_ROLES`


export const SET_TOURNAMENT_ENTRIES = `SET_TOURNAMENT_ENTRIES`
export const SET_TOURNAMENT_PAGINATION = `SET_TOURNAMENT_PAGINATION`
export const SET_TOURNAMENT_ENTRIES_PAGINATION_TOTAL = `SET_TOURNAMENT_ENTRIES_PAGINATION_TOTAL`
export const SET_TOURNAMENT_ENTRIES_PAGINATION_CURRENT_PAGE = `SET_TOURNAMENT_ENTRIES_PAGINATION_CURRENT_PAGE`


export const SET_TOURNAMENT_ENTRY = `SET_TOURNAMENT_ENTRY`


export const SET_ORGANIZER_USERS = `SET_ORGANIZER_USERS`

export const SET_ORGANIZER_PERMISSIONS = 'SET_ORGANIZER_PERMISSIONS'
export const SET_ORGANIZER_ROLES = 'SET_ORGANIZER_ROLES'
export const SET_ORGANIZER_CURRENT_USER = 'SET_ORGANIZER_CURRENT_USER'
export const SET_ORGANIZER_PAGINATION_TOTAL = 'SET_ORGANIZER_PAGINATION_TOTAL'
export const SET_ORGANIZER_PAGINATION_CURRENT_PAGE = 'SET_ORGANIZER_PAGINATION_CURRENT_PAGE'


export const SET_FAKE_USERS = `SET_FAKE_USERS`
export const SET_FAKE_USERS_PAGINATION_TOTAL = `SET_FAKE_USERS_PAGINATION_TOTAL`
export const SET_FAKE_USERS_PAGINATION_CURRENT_PAGE = `SET_FAKE_USERS_PAGINATION_CURRENT_PAGE`

export const SET_FAKE_USER_EMAIL = `SET_FAKE_USER_EMAIL`
export const SET_FAKE_USER_NAME = `SET_FAKE_USER_NAME`
export const SET_FAKE_USER_PASSWORD = `SET_FAKE_USER_PASSWORD`


export const SET_USERS = `SET_USERS`
export const SET_USER_SHOW = 'SET_USER_SHOW'
export const SET_USER_SOCIAL_ACCOUNTS_SHOW = 'SET_USER_SOCIAL_ACCOUNTS_SHOW'
export const SET_USERS_PAGINATION_TOTAL = `SET_USERS_PAGINATION_TOTAL`
export const SET_USERS_PAGINATION_CURRENT_PAGE = `SET_USERS_PAGINATION_CURRENT_PAGE`

// users levels
export const SET_USERS_LEVELS = `SET_USERS_LEVELS`
export const SET_USERS_LEVELS_PAGINATION_TOTAL = `SET_USERS_LEVELS_PAGINATION_TOTAL`
export const SET_USERS_LEVELS_PAGINATION_CURRENT_PAGE = `SET_USERS_LEVELS_PAGINATION_CURRENT_PAGE`
export const SET_USER_LEVELS_LOGS = `SET_USER_LEVELS_LOGS`
export const SET_USER_LEVELS_LOGS_PAGINATION_TOTAL = `SET_USER_LEVELS_LOGS_PAGINATION_TOTAL`
export const SET_USER_LEVELS_LOGS_PAGINATION_CURRENT_PAGE = `SET_USER_LEVELS_LOGS_PAGINATION_CURRENT_PAGE`
export const SET_USER_LOGIN_LOGS = `SET_USER_LOGIN_LOGS`
export const SET_USER_LOGIN_LOGS_PAGINATION_TOTAL = `SET_USER_LOGIN_LOGS_PAGINATION_TOTAL`
export const SET_USER_LOGIN_LOGS_PAGINATION_CURRENT_PAGE = `SET_USER_LOGIN_LOGS_PAGINATION_CURRENT_PAGE`

//ban team
export const SET_TEAMS_BAN = 'SET_TEAM_BAN'
export const SET_BAN_GAMES_BY_TEAM = 'SET_BAN_GAMES_BY_TEAM'
export const SET_BAN_GAME_ID_BY_TEAM = 'SET_BAN_GAME_ID_BY_TEAM'
export const SET_BAN_MEMBERS_BY_TEAM = 'SET_BAN_MEMBERS_BY_TEAM'
export const SET_BAN_TEAMS_PAGINATION_TOTAL = 'SET_BAN_TEAMS_PAGINATION_TOTAL'
export const SET_BAN_TEAMS_PAGINATION_CURRENT_PAGE = 'SET_BAN_TEAMS_PAGINATION_CURRENT_PAGE'

//teams rating
export const SET_TEAMS_RATING = `SET_TEAMS_RATING`
export const SET_TEAM_RATINGS_SHOW = 'SET_TEAM_RATINGS_SHOW'

export const SET_TEAMS_RATING_PAGINATION_TOTAL = `SET_TEAMS_RATING_PAGINATION_TOTAL`
export const SET_TEAMS_RATING_PAGINATION_CURRENT_PAGE = `SET_TEAMS_RATING_PAGINATION_CURRENT_PAGE`
export const SET_TEAM_RATINGS_SHOW_PAGINATION_CURRENT_PAGE = `SET_TEAM_RATINGS_SHOW_PAGINATION_CURRENT_PAGE`
export const SET_TEAM_RATINGS_SHOW_PAGINATION_TOTAL = `SET_TEAM_RATINGS_SHOW_PAGINATION_TOTAL`
export const SET_SELECTED_DIVISION = `SET_SELECTED_DIVISION`
export const SET_NEW_DIVISIONS = `SET_NEW_DIVISIONS`

//users rating
export const SET_USERS_RATING = `SET_USERS_RATING`
export const SET_USERS_RATING_PAGINATION_TOTAL = `SET_USERS_RATING_PAGINATION_TOTAL`
export const SET_USERS_RATING_PAGINATION_CURRENT_PAGE = `SET_USERS_RATING_PAGINATION_CURRENT_PAGE`
export const SET_USER_RATINGS_SHOW = `SET_USER_RATINGS_SHOW`
export const SET_USER_RATINGS_SHOW_PAGINATION_TOTAL = `SET_USER_RATINGS_SHOW_PAGINATION_TOTAL`
export const SET_USER_RATINGS_SHOW_PAGINATION_CURRENT_PAGE = `SET_USER_RATINGS_SHOW_PAGINATION_CURRENT_PAGE`

//tournaments rating
export const SET_TOURNAMENT_TEAMS_RATING = `SET_TOURNAMENT_TEAMS_RATING`
export const SET_TOURNAMENT_TEAMS_RATING_PAGINATION_TOTAL = `SET_TOURNAMENT_TEAMS_RATING_PAGINATION_TOTAL`
export const SET_TOURNAMENT_TEAMS_RATING_PAGINATION_CURRENT_PAGE = `SET_TOURNAMENT_TEAMS_RATING_PAGINATION_CURRENT_PAGE`
export const SET_TOURNAMENT_TEAM_SHOW_RATING = `SET_TOURNAMENT_TEAM_SHOW_RATING`

//Organizers
export const SET_ORGANIZERS = `SET_ORGANIZERS`
export const SET_ORGANIZER_SHOW = `SET_ORGANIZER_SHOW`

//Platforms

export const SET_PLATFORMS = `SET_PLATFORMS`

//Events

export const SET_EVENT_SHOW = `SET_EVENT_SHOW`
export const SET_TEAMS_FOR_LIVE = `SET_TEAMS_FOR_LIVE`
export const SET_TOURNAMENT_CURRENT_MATCHES_BOOK = `SET_TOURNAMENT_CURRENT_MATCHES_BOOK`
export const SET_TOURNAMENT_CURRENT_MATCHES_SELECTED_MATCH_ID_BOOK = `SET_TOURNAMENT_CURRENT_MATCHES_SELECTED_MATCH_ID_BOOK`
export const SET_SELECTED_MATCH = `SET_SELECTED_MATCH`
export const SET_SELECTED_MATCH_NAME = `SET_SELECTED_MATCH_NAME`
export const SET_SELECTED_MATCH_LOGIN = `SET_SELECTED_MATCH_LOGIN`
export const SET_SELECTED_MATCH_PASSWORD = `SET_SELECTED_MATCH_PASSWORD`
export const SET_SELECTED_MATCH_DATE = `SET_SELECTED_MATCH_DATE`

export const SET_MATCH_WITH_BOOKMAKER = `SET_MATCH_WITH_BOOKMAKER`
export const SET_MATCH_HOST_COUNT = `SET_MATCH_HOST_COUNT`

export const SET_MATCH_LIVE_COUNT = `SET_MATCH_LIVE_COUNT`
export const SET_MATCH_LIVE_TEAMS_COUNT = `SET_MATCH_LIVE_TEAMS_COUNT`

export const SET_SEO_PAGES = 'SET_SEO_PAGES'
export const SET_CURRENT_SEO_PAGE = 'SET_CURRENT_SEO_PAGE'


//Dashboard
export const SET_DASHBOARD = `SET_DASHBOARD`


//Docs
export const SET_USER_DOCS = `SET_USER_DOCS`

//Features
export const SET_FEATURES = `SET_FEATURES`

//Mailing
export const SET_MAILING = `SET_MAILING`
export const SET_MAILING_PAGINATION_TOTAL = `SET_MAILING_PAGINATION_TOTAL`
export const SET_MAILING_PAGINATION_CURRENT_PAGE = `SET_MAILING_PAGINATION_CURRENT_PAGE`
export const SET_MAILING_COUNTRIES = `SET_MAILING_COUNTRIES`
export const SET_MAILING_DIVISIONS = `SET_MAILING_DIVISIONS`
export const SET_MAILING_TOURNAMENTS = `SET_MAILING_TOURNAMENTS`
export const SET_MAILING_MESSAGE_TYPES = `SET_MAILING_MESSAGE_TYPES`
export const SET_MAILING_TOURNAMENT_TYPES = `SET_MAILING_TOURNAMENT_TYPES`
export const SET_MAILING_ROLES = `SET_MAILING_ROLES`
export const SET_MAILING_RESULT = `SET_MAILING_RESULT`
export const SET_TELEGRAM_MAILING = `SET_TELEGRAM_MAILING`
export const SET_TELEGRAM_MAILING_PAGINATION_TOTAL = `SET_TELEGRAM_MAILING_PAGINATION_TOTAL`
export const SET_TELEGRAM_MAILING_PAGINATION_CURRENT_PAGE = `SET_TELEGRAM_MAILING_PAGINATION_CURRENT_PAGE`

//ROLES
export const SET_ROLES = 'SET_ROLES'
export const SET_CURRENT_ROLE = 'SET_CURRENT_ROLE'
export const SET_ROLE_PERMISSIONS = 'SET_ROLE_PERMISSIONS'

// Wow Streamers
export const SET_WOW_STREAMERS = 'SET_WOW_STREAMERS';
export const SET_WOW_STREAMER = 'SET_WOW_STREAMER';
export const SET_WOW_STREAMERS_PAGINATION_TOTAL = 'SET_WOW_STREAMERS_PAGINATION_TOTAL';
export const SET_WOW_STREAMERS_PAGINATION_CURRENT_PAGE = 'SET_WOW_STREAMERS_PAGINATION_CURRENT_PAGE';
export const SET_WOW_STREAMER_ENTRIES = 'SET_WOW_STREAMER_ENTRIES';
export const SET_WOW_STREAMER_APPROVED = 'SET_WOW_STREAMER_APPROVED';
export const SET_WOW_STREAMER_BANNED = 'SET_WOW_STREAMER_BANNED';
// Wow Streamers Ban
export const SET_WOW_STREAMERS_BAN = 'SET_WOW_STREAMERS_BAN';
export const SET_BAN_STREAMERS_PAGINATION_TOTAL = 'SET_BAN_STREAMERS_PAGINATION_TOTAL';
export const SET_BAN_STREAMERS_PAGINATION_CURRENT_PAGE = 'SET_BAN_STREAMERS_PAGINATION_CURRENT_PAGE';

export const SET_WOW_STREAMER_ENTRIES_PAGINATION_TOTAL = 'SET_WOW_STREAMER_ENTRIES_PAGINATION_TOTAL';
export const SET_WOW_STREAMER_ENTRIES_PAGINATION_CURRENT_PAGE = 'SET_WOW_STREAMER_ENTRIES_PAGINATION_CURRENT_PAGE';

// WOWStreamers
export const SET_WOWSTREAMERS = 'SET_WOWSTREAMERS';
export const SET_WOWSTREAMER = 'SET_WOWSTREAMER';
export const SET_WOWSTREAMERS_PAGINATION_TOTAL = 'SET_WOWSTREAMERS_PAGINATION_TOTAL';
export const SET_WOWSTREAMERS_PAGINATION_CURRENT_PAGE = 'SET_WOWSTREAMERS_PAGINATION_CURRENT_PAGE';
export const SET_WOWSTREAMER_ENTRIES = 'SET_WOWSTREAMER_ENTRIES';
export const SET_APPROVED_WOWSTREAMERS = 'SET_APPROVED_WOWSTREAMERS';
export const SET_REJECTED_WOWSTREAMERS = 'SET_REJECTED_WOWSTREAMERS';
export const SET_BANNED_WOWSTREAMERS = 'SET_BANNED_WOWSTREAMERS';

// Watch module mutation types
export const SET_WATCHES = 'SET_WATCHES';
export const SET_MAIN_BANNER = 'SET_MAIN_BANNER';
export const SET_CURRENT_WATCH = 'SET_CURRENT_WATCH';
export const ADD_WATCH = 'ADD_WATCH';
export const UPDATE_WATCH = 'UPDATE_WATCH';
export const REMOVE_WATCH = 'REMOVE_WATCH';
