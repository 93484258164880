import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            platforms: [],
        }
    },
    mutations: {
        [mutypes.SET_PLATFORMS]: (state, value) => state.platforms = value,
    },

    getters: {
        getPlatformsList: state => state.platforms,

    },

    actions: {
        grabPlatformsList: async ({commit, getters}) => {
            let response = await api.platforms.index();
            let values = []
            response.data.platforms.forEach((el,index)=> {
                values[index] = {
                    value: el.id,
                    text: el.name
                }
            })
            commit(mutypes.SET_PLATFORMS, values);

        },
    }
}