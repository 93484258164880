<template>
  <b-form-group :label="label" class="editor-extra-stage-points-wrapper">
    <div v-for="(extraPoints, index) in modelValue" :key="'extraPoints-' + (extraPoints?.place)"
         class="grid-mini-inputs mb-2">
      <span>{{ extraPoints.place + ' место' }}</span>
      <b-form-input v-model="extraPoints['experience_points']"
                    :value="extraPoints.experience_points"
                    placeholder="Введите количество очков">
      </b-form-input>
    </div>
    <div class="d-flex justify-content-center flex-wrap">
      <b-button @click="addExtraExperiencePoints" class="mt-4" type="button" variant="primary">
        Добавить место
      </b-button>
      <b-button @click="modelValue.pop()" class="mt-4 ml-2" type="button" variant="danger">
        Удалить место
      </b-button>
    </div>
  </b-form-group>
</template>

<script>
export default {
  name: "EditorExtraStagePoints",
  props: {
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    addExtraExperiencePoints() {
      const lastPlace = this.modelValue.slice(-1)[0];
      let latestPlace = lastPlace?.place !== undefined ? lastPlace?.place : -1

      this.modelValue.push({
        place: latestPlace + 1,
        experience_points: 0,
      })
    },
  }
}
</script>

<style scoped lang="scss">
.editor-extra-stage-points-wrapper {

}
</style>