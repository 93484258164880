import adminHttpImg from "@/api/adminHttpImg";

const END_POINT = '/api/v1/admin/file-upload';

export default {
    /**
     * POST: Tournament, can be stored by id
     * @returns {Promise<AxiosResponse<any>>}
     */
    store: (params) => adminHttpImg.post(END_POINT, params),
}