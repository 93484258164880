import axios from "axios";
import Env from "/env";
import Vue from "vue";

const adminHttp = axios.create({
    baseURL: `${Env.API_URL}`,
    headers: {
        "Content-Type": "application/json",
    }
})

// const adminHttpAdmin = axios.create({
//     baseURL: `${Env.API_URL_ADMIN || 'https://backendtest.bigplay.gg/api/admin'}`,
//     headers: {
//         "Content-Type": "application/json",
//     }
// });

/**
 * Request Interceptors
 */
const getAuthToken = () => localStorage.getItem('token')
const authInterceptor = (config) => {
    const token = getAuthToken()
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
    }
    config.headers['Accept-Language'] = 'ru'
    return config
}
adminHttp.interceptors.request.use(authInterceptor)
// adminHttpAdmin.interceptors.request.use(authInterceptor);

const errorInterceptor = e => {
    switch (e.response.status) {
        case 401:
            console.log('###', e.response.status, e.message)
            break
        case 403:
            console.log('###', e.response.status, e.message)
            Vue.toasted.error('У вашей роли нет доступа', {
                duration: 5000
            });
            break
        default:
            if(process.env.NODE_ENV === 'development')
                console.log('### ', e.response.status, e.message, e.response)
    }
    return Promise.reject(e)
}

const responseInterceptor = r => {
    return r
}

adminHttp.interceptors.response.use(responseInterceptor, errorInterceptor)
// adminHttpAdmin.interceptors.response.use(responseInterceptor, errorInterceptor);


export default adminHttp;