import adminHttp from "@/api/adminHttp";

const END_POINT = '/api/v1/admin/team/fake'

export default {
    index: (params) => adminHttp.get(END_POINT, params && {params}),

    show: (id) => adminHttp.get(END_POINT + `/${id}`),

    store: (params) => adminHttp.post(END_POINT, params),

    update: (id, params) => adminHttp.post(END_POINT + `/${id}`, params),

    delete: (id) => adminHttp.delete(END_POINT + `/${id}`)
}
