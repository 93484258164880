import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            tournament: {
                games: null,
                game_id: null,
                organizers: null,
                organizer_id: null,
                name: "",
                description: {
                    ru: '',
                    en: '',
                    uz: '',
                    kz: '',
                },
                rules: {
                    ru: '',
                    en: '',
                    uz: ''
                },
                prize_fond: '',
                slot_count: '',
                formats: null,
                timezone: null,
            },
            organizersOuter:null,
            games: null,
            currentFormats: null,
            currentStatus: null,
            start_reg_time: null,
            end_reg_time: null,
            start_time: null,
            schedule: {
                ru: '',
                en: '',
                uz: '',
            },
            currentImage: null,
            currentTournamentSlug: null,
        }
    },
    mutations: {
        [mutypes.SET_TOURNAMENT]: (state, value) => state.tournament = value,
        [mutypes.SET_TOURNAMENT_GAMES]: (state, value) => state.games = value,
        [mutypes.SET_TOURNAMENT_GAME_ID]: (state, value) => state.tournament.game_id = value,
        [mutypes.SET_TOURNAMENT_ORGANIZERS_OUTER]: (state, value) => state.organizersOuter = value,
        [mutypes.SET_TOURNAMENT_ORGANIZER_ID]: (state, value) => state.tournament.organizer_id = value,
        [mutypes.SET_TOURNAMENT_END_REG_TIME]: (state, value) => state.end_reg_time = value,
        [mutypes.SET_TOURNAMENT_REG_TIME]: (state, value) => state.start_reg_time = value,
        [mutypes.SET_TOURNAMENT_START_TIME]: (state, value) => state.start_time = value,
        [mutypes.SET_TOURNAMENT_NAME]: (state, value) => state.tournament.name = value,
        [mutypes.SET_TOURNAMENT_DESCRIPTION]: (state, value) => state.tournament.description = value,
        [mutypes.SET_TOURNAMENT_RULES]: (state, value) => state.tournament.rules = value,
        [mutypes.SET_TOURNAMENT_PRIZE_FOND]: (state, value) => state.tournament.prize_fond = value,
        [mutypes.SET_TOURNAMENT_SLOT_COUNT]: (state, value) => state.tournament.slot_count = value,
        [mutypes.SET_TOURNAMENT_FORMATS]: (state, value) => state.tournament.formats = value,
        [mutypes.SET_TOURNAMENT_TIMEZONE]: (state, value) => state.tournament.timezone = value,
        [mutypes.SET_TOURNAMENT_CURRENT_FORMATS]: (state, value) => state.currentFormats = value,
        [mutypes.SET_TOURNAMENT_CURRENT_STATUS]: (state, value) => state.currentStatus = value,
        [mutypes.SET_TOURNAMENT_SCHEDULE]: (state, value) => state.schedule = value,
        [mutypes.SET_TOURNAMENT_CURRENT_IMAGE]: (state, value) => state.currentImage = value,
        [mutypes.SET_TOURNAMENT_CURRENT_SLUG]: (state, value) => state.currentTournamentSlug = value,
    },

    getters: {
        getTournament: state => state.tournament,
        getTournamentOuterGames: state => state.games,
        getTournamentOuterGameId: state => state.tournament.game_id,
        getTournamentOuterOrganizers: state => state.organizersOuter,
        getTournamentOuterOrganizerId: state => state.tournament.organizer_id,
        getTournamentOuterEndRegTime: state => state.end_reg_time,
        getTournamentOuterStartRegTime: state => state.start_reg_time,
        getTournamentOuterStartTime: state => state.start_time,
        getTournamentName: state => state.tournament.name,
        getTournamentOuterDescription: state => state.tournament.description,
        getTournamentOuterRules: state => state.tournament.rules,
        getTournamentOuterPrize: state => state.tournament.prize_fond,
        getTournamentOuterSlotCount: state => state.tournament.slot_count,
        getTournamentFormats: state => state.tournament.formats,
        getTournamentTimezone: state => state.tournament.timezone,
        getTournamentCurrentFormats: state => state.currentFormats,
        getTournamentCurrentStatus: state => state.currentStatus,
        getTournamentSchedule: state => state.schedule,
        getTournamentCurrentImage: state => state.currentImage,
        getTournamentCurrentSlug: state => state.currentTournamentSlug,
    },

    actions: {
        storeTournamentOuter: async ({commit, getters}, params) => {
            let response = await api.tournament.store(params);
        },
        grabTournamentOuter: async ({commit, getters}, id) => {
            let response = await api.tournament.grabTournament(id);
            commit(mutypes.SET_TOURNAMENT, response.data.tournament);
            commit(mutypes.SET_TOURNAMENT_REG_TIME, response.data.tournament.start_req_time);
            commit(mutypes.SET_TOURNAMENT_END_REG_TIME, response.data.tournament.end_req_time);
            commit(mutypes.SET_TOURNAMENT_PRIZE_FOND, response.data.tournament.prize_fond);
            commit(mutypes.SET_TOURNAMENT_SLOT_COUNT, response.data.tournament.slot_count)
            commit(mutypes.SET_TOURNAMENT_TIMEZONE, response.data.tournament.timezone)
            commit(mutypes.SET_TOURNAMENT_START_TIME, response.data.tournament.start_time)
            commit(mutypes.SET_TOURNAMENT_SCHEDULE, response.data.tournament.schedule)
            commit(mutypes.SET_TOURNAMENT_CURRENT_IMAGE, response.data.tournament?.logo?.original_url)
            commit(mutypes.SET_TOURNAMENT_CURRENT_SLUG, response.data.tournament.slug)
            let statusValues = {}
            const statusId = response.data.tournament.status.id
            const statusCode = response.data.tournament.status.code
            statusValues ={
                value: statusId,
                text: statusCode
            }
            commit(mutypes.SET_TOURNAMENT_CURRENT_STATUS, statusValues)
            let values = []
            response.data.tournament.formats.forEach((el,index)=> {
                values[index] = {
                    value: el.id,
                    text: el.name
                }
            })
            commit(mutypes.SET_TOURNAMENT_CURRENT_FORMATS, values)
        },
        updateTournamentOuter: async ({commit, getters}, params) => {
            const formData = new FormData()
            formData.append('_method', 'put');
            formData.append("game_id", getters.getTournamentGameId);
            formData.append("organizer_id", getters.getTournamentOrganizerId);
            formData.append("end_reg_time", getters.getTournamentEndRegTime + ":00");
            formData.append("start_reg_time", getters.getTournamentStartRegTime + ":00");
            formData.append("start_time", getters.getTournamentStartTime + ":00");
            formData.append("name", getters.getTournamentName);
            for (const [key, value] of Object.entries(getters.getTournamentDescription)) {
                formData.append(`description[${key}]`, value);
            }
            for (const [key, value] of Object.entries(getters.getTournamentRules)) {
                formData.append(`rules[${key}]`, value);
            }
            for (const [key, value] of Object.entries(getters.getTournamentSchedule)) {
                formData.append(`schedule[${key}]`, value);
            }
            formData.append("prize_fond", getters.getTournamentPrize);
            formData.append("formats[]", params.formats);
            formData.append("timezone", getters.getTournamentTimezone);
            formData.append("slot_count", getters.getTournamentSlotCount);
            formData.append('slug', getters.getTournamentCurrentSlug);
            if (params.logo) {
                formData.append("logo", params.logo);
            }
            formData.append('place_points', '[]');

            let response = await api.tournament.update(params.id, formData)
        },
        grabGamesOuter: async ({commit, getters}) => {
            await api.games.index().then(res => {
                let values = []
                res.data.games.forEach((item, index)=>{
                    values[index] = {
                        value: item.id,
                        text: item.name
                    }
                })

                commit(mutypes.SET_TOURNAMENT_GAMES, values);
                localStorage.setItem('games', JSON.stringify(values));
            }).catch();
        },
        grabOrganizersOuter: async ({commit, getters}) => {
            let response = await api.organizers.index()
            let values = [];
            response.data.organizers.forEach((item, index) => {
                values[index] = {
                    value:item.id,
                    text:item.name
                };
            })
            commit(mutypes.SET_TOURNAMENT_ORGANIZERS_OUTER, values);

        },
    }
}