var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{style:(_vm.member.death > 0 ? 'background-color: #d6d6d6' : '')},[_c('td',{staticClass:"align-middle",staticStyle:{"width":"140px"}},[_vm._v(" "+_vm._s(_vm.member.info.nick))]),_c('td',{staticClass:"align-middle mr-2"},[_vm._v(_vm._s(_vm.member.kills + _vm.killForSend))]),_c('td',{staticClass:"p-1 align-middle"},[_c('b-btn',{staticClass:"p-1 mr-4",attrs:{"disabled":_vm.showEventData?.type === 'ended',"variant":"primary"},on:{"click":function($event){return _vm.increaseKill()}}},[_c('i',{staticClass:"fa fa-caret-up p-1 px-2"})])],1),_c('td',{staticClass:"p-1 align-middle"},[_c('b-btn',{staticClass:"p-1 mr-4",attrs:{"disabled":_vm.showEventData?.type === 'ended',"variant":"danger"},on:{"click":function($event){return _vm.decreaseKill()}}},[_c('i',{staticClass:"fa fa-caret-down p-1 px-2"})])],1),_c('td',{staticClass:"p-1 align-middle"},[_c('b-btn',{staticClass:"px-2 mr-4",attrs:{"disabled":_vm.showEventData?.type === 'ended',"variant":_vm.member.death > 0 ? 'dark' : 'primary'},on:{"click":function($event){return _vm.changeDeath(_vm.member.death)}}},[_vm._v(" "+_vm._s(_vm.deathInString)+" ")])],1),_c('td',{staticClass:"p-1 align-middle"},[_c('b-btn',{staticClass:"p-2",attrs:{"disabled":_vm.showEventData?.type === 'ended',"variant":"primary"},on:{"click":function($event){$event.preventDefault();_vm.storeKill({
                                            matchId: _vm.selectedMatch,
                                            token: _vm.team.hash,
                                            user_id: _vm.member.user_id,
                                            kill: _vm.calculateKills(),
                                            death: _vm.deathForSend
                                          }).then((res)=>{
                                              _vm.killForSend = 0;
                                          })}}},[_vm._v("Send ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }