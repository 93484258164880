<template>
  <div>
  <b-modal centered id="modal-TeamDelete" title="Предупреждение" v-model="showModal">
    <p class="my-4">Вы действительно хотите удалить команду?</p>
    <template #modal-footer>
      <div>
        <b-btn variant="primary" class="mr-3" @click="deleteTeamModal">Подтвердить</b-btn>
        <b-btn variant="danger" @click="showModal=false">Отмена</b-btn>
      </div>
    </template>
  </b-modal>
  <b-card class="p-0">
    <b-table
        :items="getFilteredTeams"
        :fields="tablefields"
        :striped="striped"
        :bordered="bordered"
        stacked="md"
        class="align-items-center text-center"
        :tbody-tr-class="rowClass"
        responsive>
      <template #cell(num)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(teams)="data">
        <div class="flex justify-content-start text-left">
          <router-link :to="{name: 'team_info_standings_tournament', params: {
            group_result_id: data.item.id,
            tournament_id:$route.params.standingId,
          }}"
                       class="tournament__btn text-left">{{ data.item.team?.name }} || {{data.item.team?.division?.name}}
          </router-link>
        </div>
      </template>
      <template #cell(players)="data">
        <div>
          <b-btn variant="info"
                 sm
                 @click="showTeamMembers(data.item.team.id)"
                 v-b-toggle="'players'+ data.item.id"
                 class="px-3"
          >
            <i class="fa fa-users"></i></b-btn>
        </div>
        <b-collapse :id="'players'+ data.item.id">
          <div class="mt-3">
            <b-list-group :key="'team_member_stage' + index + data.item.id" v-for="(teamMember,index) in getFilteredMembersByTeamId(data.item.team.id)">
              <b-list-group-item class="p-0 px-1 text-left">
                {{
                  teamMember?.user?.gameProfile ? teamMember?.user?.gameProfile?.fields.nick : 'не указан'
                }}
              </b-list-group-item>

            </b-list-group>
          </div>
        </b-collapse>

      </template>
      <template #cell(kills)="data">
        <div>
          <p>{{ data.item.result_fields.kill_pts }}</p>
        </div>
      </template>
      <template #cell(place)="data">
        <div class="">
          <p>{{ data.item.result_fields.place_pts }}</p>
        </div>
      </template>
      <template #cell(total)="data">
        <div>
          <p>{{ data.item.result_fields.place_pts + data.item.result_fields.kill_pts }}</p>
        </div>
      </template>
      <template #cell(matches)="data">
        <b-button size="lg" @click="showTeamDetails(data)" class="px-1 py-0" variant="none">
          <i class="fa fa-arrow-circle-o-down"></i>
        </b-button>
      </template>
      <template #row-details="data">
        <div class="card p-0 m-0">
          <b-table :items="selectedTeamResults" :fields="oneTeamFields" striped hover responsive>
            <template #cell(match_name)="data">
              {{ data.item.match.name }}
            </template>
            <template #cell(kills_match)="data">
              {{ data.item.kill_pts }}
            </template>
            <template #cell(place_match)="data">
              {{ data.item.place_pts }}
            </template>
            <template #cell(total_match)="data">
              {{ data.item.place_pts + data.item.kill_pts }}
            </template>

          </b-table>
        </div>
      </template>

      <template #cell(delete)="data">
        <div v-if="prevStageId > 0">
          <button
              :disabled="data.item.result_fields.place_pts > 0"
              @click.prevent="deleteTeam(data.item.id)"
              class="btn btn-danger px-2"><i class="fa fa-trash"></i></button>

        </div>
        <div v-else>
          <b-dropdown
              no-caret
              size="sm"
              :disabled="data.item.result_fields.place_pts > 0"
              style="position: static"
              @hide="resetCheckbox()"
              variant="none"
              checkbox-menu
              allow-focus
              dropright>
            <template #button-content>
              <i class="fa fa-trash bg-danger px-2 py-2 rounded-lg"></i>
            </template>
            <b-dropdown-form
                v-for="reason in reasonsForDelete"
                :key="reason.id"
                :value="reason.id"
            >
              <b-form-checkbox
                  :value="reason.id"
                  v-model="selectedReasonText">
                {{reason.label}}
              </b-form-checkbox>
            </b-dropdown-form>
            <div class="d-flex justify-content-center">
              <b-btn :disabled="selectedReasonText.length <= 0" @click="removeStandingTeam({id:data.item.id,reject_description: selectedReasonText})">
                Ok
              </b-btn>
            </div>
          </b-dropdown>
        </div>
      </template>
      <template #cell(slot)="data">
        <div style="width: 50px; display: flex; justify-content: center">
          <change_slot_number_input
              :slot_number="data.item.slot_number"
              :item="data.item"
              @changeSlotNumber="updateSlotNumber"
          />
        </div>
      </template>
      <template #cell(select)="data">
        <div>
          <b-form-checkbox v-model="data.item.selected"
                           @change="selectTeamRow(data.item)"

          />
        </div>
      </template>
    </b-table>
  </b-card>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {TournamentStandingsAdapterMixin} from "../../../mixins/Tournament/TournamentStandingsAdapterMixin";
import change_slot_number_input from "../../../components/change_slot_number_input";
import Change_slot_number_input from "../../../components/change_slot_number_input";

export default {
  name: 'TeamsStandingTournament',
  components: {Change_slot_number_input},
  mixins: [TournamentStandingsAdapterMixin],
  data() {
    return {
      showModal: false,
      teamId: null,
      tablefields: [
        {key: 'num', label: '№'},
        {key: 'teams', label: 'Команды'},
        {key: 'players', label: 'Игроки'},
        {key: 'kills', label: 'Kills'},
        {key: 'place', label: 'Place'},
        {key: 'total', label: 'Total'},
        {key: 'matches', label: 'Матчи'},
        {key: 'delete', label: 'Удалить'},
        {key: 'slot', label: 'Slot'},
        {key: 'select', label: 'Выбрать'},
      ],
      oneTeamFields: [
        {key: 'match_name', label: 'match', thClass: 'p-1', tdClass: 'p-1'},
        {key: 'kills_match', label: 'Kills', thClass: 'p-1', tdClass: 'p-1'},
        {key: 'place_match', label: 'Place', thClass: 'p-1', tdClass: 'p-1'},
        {key: 'total_match', label: 'Total', thClass: 'p-1', tdClass: 'p-1'},
      ],
      selected: [],
      teamStagePlayers: {},
      striped: true,
      bordered: true,
    }
  },
  computed: {
    ...mapState({
      stageMembers: state => state.moduleTournamentStandings.stageMembers,
      prevStageId: state => state.moduleTournamentStandings.prevStageId

    }),
    getFilteredTeams() {
      if (this.groupTeams && this.groupTeams.length) {
        // By Game Pubg
        let idxFoundNicknames = this.stageMembers.filter((member) => member.user.gameProfile.fields.nick.toLowerCase().indexOf(this.searchTeam.toLowerCase()) > -1)
        let idxFoundIds = this.stageMembers.filter((member) => member.user.gameProfile.fields.pubg_id == this.searchTeam)
        return this.groupTeams.filter(groupTeam => {
          const isFoundTeamName = groupTeam.team.name.toLowerCase().indexOf(this.searchTeam.toLowerCase()) !== -1
          const hasTeamByNickname = idxFoundNicknames.some(({ team_id }) => team_id === groupTeam.team.id)
          const hasTeamById = idxFoundIds.some(({ team_id }) => team_id === groupTeam.team.id)

          return [isFoundTeamName, hasTeamByNickname, hasTeamById].some(s => s)
        })
      }
      return [];
    },
  },
  methods: {
    showTeamDetails(data) {
      this.grabSelectedGroupMatchesResult(data.item.id);
      data.toggleDetails();
    },
    updateSlotNumber(data) {
      this.changeSlotNumber(data);
    },
    selectTeamRow(item) {
      // item._rowVariant = item.selected ? 'default' : 'info'
      this.selectStandingTournamentTeam(item.team.tournamentTeams[0].id);
    },
    getFilteredMembersByTeamId(teamId) {
      return this.stageMembers.filter((member) => {
        return member.team_id === teamId;
      })
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item?.team?.['deleted_at']) return 'trashed'
    },

    async deleteTeam(id) {
      this.teamId = id
      this.showModal = true
    },
    deleteTeamModal() {
      this.removeStandingTeam({id:this.teamId, reject_description: ''})
          .then(() => this.showModal = false)
          .finally(() =>
              this.teamId = null
          )
    },
  },

}
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::v-deep {
  .table-responsive {
    .trashed {
      background: #ff000047;
    }
  }
}

</style>
