import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/group_result';
const MATCH_END_POINT = '/api/admin/match';
const GROUP_END_POINT = '/api/admin/group';

export default {
    /**
     * Get: Show command matches result
     * @returns {Promise<AxiosResponse<any>>}
     */
    show: (id) => adminHttp.get(END_POINT + '/' + id),
    newShow: (id) => adminHttp.get(`/api/v1/admin/group-match/results/${ id }`),

    /**
     * DELETE: delete one command matches result
     * @returns {Promise<AxiosResponse<any>>}
     */
    destroy: (params) => adminHttp.delete(END_POINT + `/${params.id}`, params),
    newDestroy: (params) => adminHttp.post(END_POINT + `/${params.id}`, params),

    /**
     * Post: Store command matches result
     * @param id
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    store: (id, params) => adminHttp.post(MATCH_END_POINT + '/' + id, params),
    newStore: (id, params) => adminHttp.post(`/api/v1/admin/group-match/results/add/${id}`, params),

    /**
     * Get: One team group matches results
     * @param id
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    grabGroupMatchesResult: (id, params = {}) => adminHttp.get(MATCH_END_POINT + '/group_matches/' + id, params),
    newGrabGroupMatchesResult: (id) => adminHttp.get(`/api/v1/admin/group-match/results/${ id }`),
    
    /**
     * PUT: Update Group Result slot number
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateSlotNumber: (params) => adminHttp.put(END_POINT + `/slot_number/${params.id}`, params),
    newUpdateGroupResult: (groupResultId, params) => adminHttp.post(`/api/v1/admin/group-result/slot_number/${ groupResultId }`, params),
    
    /**
     * Get: Show command team slots
     * @returns {Promise<AxiosResponse<any>>}
     */
    grabTeamSlots: (params) => adminHttp.get(GROUP_END_POINT + `/slots/${params.groupId}/${params.stageId}`),

    addData: (id, data) => adminHttp.post(`/api/v1/admin/group-result/add/${ id }`, data),
    sendWinner: (id, data) => adminHttp.post(`/api/v1/admin/tournament/make-winner/${ id }`, data)
}