import Vue from 'vue'
import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            entries: [],
            rejectedMessages: {},
            pagination: {
                by: 10,
                currentPage: 1,
                totalPages: 1,
            },
        }
    },
    mutations: {
        [mutypes.SET_TOURNAMENT_ENTRIES]: (state, value) => state.entries = value,
        [mutypes.SET_TOURNAMENT_PAGINATION]: (state, value) => state.pagination = value,
        [mutypes.SET_TOURNAMENT_ENTRIES_PAGINATION_TOTAL]: (state, value) => state.pagination.totalPages = value,
        [mutypes.SET_TOURNAMENT_ENTRIES_PAGINATION_CURRENT_PAGE]: (state, value) => state.pagination.currentPage = value,
        [mutypes.SET_FIRST_LEVEL_DATA]: (state, { name, value }) => Vue.set(state, name, value)
    },

    getters: {
        getTournamentEntries: state => state.entries,
        getEntriesPaginationTotal: state => state.pagination.totalPages,
        getEntriesPaginationCurrentPage: state => state.pagination.currentPage,
    },

    actions: {
        indexTournamentEntries: async ({commit, getters}, params) => {
            const response = await api.tournament.grabTournamentEntries(params.id, {
                page: params.pageNumber,
                "search": params.search,
                "filter[team.division.slug]": params.division,
                "filter[stage_id]": params.stage,
                "filter[status]": params.status
            });
            commit(mutypes.SET_TOURNAMENT_ENTRIES, response.data.entries);
            commit(mutypes.SET_TOURNAMENT_ENTRIES_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_TOURNAMENT_ENTRIES_PAGINATION_CURRENT_PAGE, params.pageNumber);

            return getters.getTournamentEntries;
        },
        deleteTournamentEntries: async ({commit, getters}, id) => {
            await api.tournament.deleteTournamentEntriesTeam(id)
            let entries = getters.getTournamentEntries.filter(t => t.id !== id)
            commit(mutypes.SET_TOURNAMENT_ENTRIES, entries)
        },
        deleteTournamentEntry: async ({dispatch}, params) => {
            api.tournament.deleteTournamentEntry(params.entryId).then(() => {
                dispatch('indexTournamentEntries', {id: params.tournamentId});
            })
        },
        async getTranslationMailing({ commit }) {
            const response = await api.tournament.getTranslationMailing()
            commit(mutypes.SET_FIRST_LEVEL_DATA, {
                name: 'rejectedMessages',
                value: response
            })
        }
    }
}
