<template>
  <div>
    <Breadcrumbs main="Помощь" title="Cортировка"></Breadcrumbs>
    <div class="container">
      <b-card>
        <b-form>
        <b-table striped :fields="fields" responsive>
          <template #cell(id)>
            <b-form-input></b-form-input>
          </template>
          <template #cell(header)>
            <div></div>
          </template>
        </b-table>
        </b-form>
        <b-btn variant="success" class="mt-5">Сохранить</b-btn>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpFilter',
  data() {
    return {
      fields: [
        {key: 'id', label: 'ID',},
        {key: 'header', label: 'Заголовок',},
      ]
    }
  },

}
</script>