<template>
  <div class="create-fake-user-wrapper">
    <b-form @submit="submitForm">
      <label class="font-weight-bold star">Дисциплина</label>
      <Multiselect :options="games" v-model="formModel.game_ids" label="text" track-by="value"
                   :multiple="true" :hide-selected="true"></Multiselect>
      <b-alert v-if="errorText" show variant="danger">{{ errorText }}</b-alert>
      <div class="form-footer">
        <b-button :disabled="isLoadingCreateFakeUser" type="submit" variant="primary">
          {{ isLoadingCreateFakeUser ? 'Сохраняем...' : 'Сохранить' }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import api from "@/api/api";
import Multiselect from "vue-multiselect";

export default {
  name: "CreateFakeUser",
  components: {Multiselect},
  data() {
    return {
      errorText: '',
      formModel: {
        game_ids: [],
      },
      isLoadingCreateFakeUser: false,
      games: []
    }
  },
  methods: {
    async submitForm(event) {
      try {
        event.preventDefault()
        this.isLoadingCreateFakeUser = true
        this.errorText = null
        const formData = new FormData()

        let selectedGamesIds = []
        this.formModel.game_ids.forEach((item) => {
          selectedGamesIds.push(
              item.value
          )
        })

        for (const [key, value] of Object.entries(selectedGamesIds)) {
          formData.append(`game_ids[]`, value)
        }

        await api.fakeUsers.store(formData)
        await this.$store.dispatch('moduleFakeUsers/getFakeUsers', {
          page: localStorage.getItem('pageNumberFakeUsers'),
        })
        await this.$emit('closeModal')
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingCreateFakeUser = false
      }
    },
  },
  mounted() {
    (async () => {
      const response = (await api.games.index()).data
      this.games = response.games.map((item) => ({
        value: item.id,
        text: item.name
      }))
    })()
  }
}
</script>

<style scoped lang="scss">
.create-fake-user-wrapper {
  .form-group {
    width: 100%;
  }

  .form-footer {
    border-top: 1px solid #dee2e6;
    text-align: right;
    padding-top: 15px;
  }
}
</style>