<template>
  <div class="edit-results-team-card">
    <div class="header">
      <h2>
        {{ model.match.name }}
      </h2>
      <div class="ranking-points">
        <template v-if="!isEditForm">
          <img :src="RankingPointsIcon" alt="" />
          <span>{{ model.place }}</span>
        </template>
        <template v-else>
          <div class="input-group">
            <span class="title-property">Place №</span>
            <b-input v-model="model.place" type="number" style="width: 100% !important; max-width: none;" />
          </div>
        </template>
      </div>
    </div>
    <div class="players">
      <div v-for="player in model.groupMatchResultPlayers" class="player" :key="'player-result-' + player.id + player.user.username">
        <div class="header">
          <h3>{{ player.user.gameProfile.fields.nick }}</h3>
          <span class="property">id: {{ player.user.gameProfile.fields.pubg_id }}</span>
        </div>
        <div class="interactive">
          <div class="property">
            <template v-if="!isEditForm">
              <img class="icon" :src="GunIcon" alt="" />
              {{ player.kill_pts }}
            </template>
            <template v-else>
              <div class="input-group">
                <span class="title-property">Кол-во убийств</span>
                <b-input v-model="player.kill_pts" type="number" />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <button v-if="!isEditForm && !isCompletedTournament" @click="isEditForm = true">Редактировать</button>
<!--      <template v-else>-->
<!--        <button @click="isEditForm = false">Сбросить изменения</button>-->
<!--      </template>-->
    </div>
  </div>
</template>

<script>
import GunIcon from '@/assets/icons/gun.svg'
import RankingPointsIcon from '@/assets/icons/ranking-points.svg'
import {mapGetters} from "vuex";

export default {
  name: "EditResultsTeamCard",
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters('moduleNewTournament', ['isCompletedTournament']),
    model: {
      get() { return this.value },
      set(newValue) { this.$emit('newValue', newValue) }
    }
  },
  data() {
    return {
      GunIcon,
      RankingPointsIcon,
      isEditForm: false
    }
  }
}
</script>

<style scoped lang="scss">
.edit-results-team-card {
  background: white;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;

  h2 {
    font-size: 19px;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ranking-points {
      margin-left: 10px;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        height: 24px;
        margin-right: 5px;
      }

      span {
        font-size: 16px;
        font-weight: 300;
      }
    }
  }

  .actions {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    button {
      display: block;
      font-size: 11px;
      border-radius: 13px;
      padding: 5px 13px 5px 13px;
      background: white;
      color: #000000bf;
      border: 1px solid #000000bf;
      transition: .2s;

      &:hover {
        background: #000000bf;
        color: white;
      }
    }
  }

  .players {
    margin-top: 20px;

    & > .player:not(:last-child) {
      border-bottom: 1px solid #d7d7d7;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    .player {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .interactive {
        .icon {
          width: 17px;
        }
        .property {
          display: flex;
          gap: 10px;
          align-items: center;
        }
      }

      .header {
        h3 {
          font-size: 16px;
          margin: 0;
        }
        .property {
          color: #aaaaaa;
          font-size: 13px;
        }
      }
    }
  }

  .title-property {
    color: gray !important;
    font-size: 13px !important;
    font-weight: 400 !important;
  }

  .input-group {
    display: flex;
    flex-direction: column;

    & > * {
      width: 100%;
    }

    input {
      width: auto !important;
      max-width: 120px;
    }
  }
}
</style>