import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/social_account/detach'

export default {
    async detach({ id, userId }) {
        await adminHttp.post(`${END_POINT}/${id}`, {
            user_id: userId
        })
    }
}
