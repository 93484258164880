<template>
    <div>
        <Breadcrumbs main="Организации" title="Список организаторов"/>
        <b-modal centered id="modal-EntryDelete" title="Предупреждение" v-model="show">
            <p class="my-4">Вы действительно хотите удалить организатора?</p>
            <template #modal-footer>
                <div>
                    <b-btn variant="primary" class="mr-3" @click="deleteOrganizerModal">Подтвердить</b-btn>
                    <b-btn variant="danger" @click="show=false">Отмена</b-btn>
                </div>
            </template>
        </b-modal>
        <b-card>
            <div class="d-flex justify-content-start">
                <b-btn @click="createOrganizer()" class="mb-5" variant="primary">Создать организатора</b-btn>
            </div>
            <b-table
                    responsive
                    show-empty
                    stacked="md"
                    :striped="striped"
                    :bordered="bordered"
                    :fields="fields"
                    :items="organizers"
                    :current-page=0
                    :per-page=10
                    class="mb-5"
            >
                <template #cell(name)="data">
                    <div>{{ data.item.name }}</div>
                </template>
                <template #cell(link)="data">
                     <div>{{ data.item.link ? data.item.link : 'Не указано' }}</div>
                </template>
                <template #cell(platform)="data">
                    <div> {{ data.item.platform?.name ? data.item.platform.name : 'Не указано' }}</div>
                </template>
                <template #cell(rating)="data">
                    <div>
                        {{ data.item.rating ? data.item.rating : 'Не указано' }}
                    </div>
                </template>
                <template #cell(action)="data">
                    <div class="row justify-content-center">
                        <router-link
                                :to="{ name: 'organizer_info', params: { id: data.item.id }}"
                        ><span>
                      <b-btn variant="primary" class="mr-2 p-1 px-2 mb-1"><i class="fa fa-eye"></i></b-btn>
                    </span>
                        </router-link>
                        <router-link
                                :to="{ name: 'organizer_edit', params: { id: data.item.id }}"
                        ><span>
                      <b-btn variant="primary" class="mr-2 p-1 px-2 mb-1"><i
                              class="fa fa-edit"></i></b-btn>
                    </span>
                        </router-link>
                        <span><b-btn variant="danger" class="mr-2 p-1 px-2 mb-1"
                                     @click="deleteOrganizerInList(data.item.id)"
                        ><i class="fa fa-trash" style="font-size: 18px"></i></b-btn></span>
                    </div>
                </template>
            </b-table>
        </b-card>
    </div>
</template>

<script>
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {OrganizersAdapterMixin} from "@/mixins/Organizers/OrganizersAdapterMixin";

export default {
    name: "ListOrganizers",
    mixins: [ModalAdapterMixin, OrganizersAdapterMixin],
    created() {
        this.grabOrganizersList();
    },
    data() {
        return {
            show: false,
            fields: [
                {key: 'name', label: 'Название', sortable: true,},
                {key: 'link', label: 'Ссылка на сайт', sortable: true,},
                {key: 'platform', label: 'Платформа', sortable: true,},
                {key: 'rating', label: 'Рейтинг', sortable: true,},
                {key: 'action', label: 'Действие', sortable: true,},
            ],
            striped: true,
            bordered: true
        }
    },
    methods: {
        async deleteOrganizerInList(id) {
            this.organizerId = id
            this.show = true
        },
        deleteOrganizerModal() {
            this.deleteOrganizer({organizerId: this.organizerId})
                .then(() => this.show = false)
        },
    },
}
</script>

<style scoped>

</style>