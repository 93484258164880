import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const HelpAdapterMixin = {
    data() {
        return {
            newHelpDeskTitle: {
                ru: '',
                en: '',
                uz: '',
                kz: ''
            },
            newHelpDeskDescription: {
                ru: '',
                en: '',
                uz: '',
                kz: ''
            },
            newHelpDeskData: null,
            // newHelpDeskPosition: null,
            newHelpDeskGameId: null,
            games: [
                {
                    value: 2,
                    text: 'PUBG Mobile'
                }
            ]

        }
    },
    computed: {
        ...mapGetters({
            getHelp: 'getHelp',
            getCurrentHelp: 'getCurrentHelp',
        }),
        currentHelp: {
            get(){
                return this.getCurrentHelp
            },
            set(value){
                this.setCurrentHelp(value)
            },

        }
    },
    methods: {
        ...mapMutations({
            setCurrentHelp: mutypes.SET_CURRENT_HELP,
        }),
        ...mapActions([
            'indexHelpDesk',
            'showHelpDesk',
            'deleteHelpDesk',
            'updateHelpDesk',
            'storeHelpDesk'
        ]),
        async showHelpDeskCurrent(id){
            this.showHelpDesk(id).then(()=>{
                this.$router.push({name: 'help_edit', params: { id: id}})
            })
        },
        async updateHelpDeskEdit(data){
            this.updateHelpDesk(data).then(()=>{
                this.$router.push({name: 'help'})
            })
        },
        async storeHelpDeskCreate(data){
            this.storeHelpDesk(data).then(()=>{
                this.$router.push({name: 'help'})
            })
        }
    }
}
