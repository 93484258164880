<template>
<div>
  <Breadcrumbs  title="SEO"/>
  <b-modal centered id="modal-seoDelete" title="Предупреждение" v-model="show">
    <p class="my-4">Вы подтверждаете удаление?</p>
    <template #modal-footer>
      <div>
        <b-btn variant="primary" class="mr-3" @click="closeModalAfterDelete(seoId)">Подтвердить</b-btn>
        <b-btn variant="danger" @click="show=false">Отмена</b-btn>
      </div>
    </template>
  </b-modal>
  <div class="container">
    <div class="card">
      <div class="p-3">
        <b-btn variant="primary" class="mb-3" @click="$router.push({name: 'SeoCreate'})">Создать</b-btn>
      <b-table :items="seo" :fields="fields" striped responsive>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(actions)="data">
          <div>
            <b-btn variant="primary" class="mr-3" @click="$router.push({name: 'SeoEdit', params:{id: data.item.id}})">Редактировать</b-btn>
            <b-btn variant="danger" @click.prevent="deleteSeoBtn(data.item.id)">Удалить</b-btn>
          </div>
        </template>
      </b-table>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import {SeoAdapterMixin} from "@/mixins/Seo/SeoAdapterMixin";
export default {
  name: "SeoList",
  mixins: [SeoAdapterMixin],
  data() {
    return {
      fields: [
        {key: 'index', label: '№',},
        {key: 'title', label: 'Title',},
        {key: 'description', label: 'Description',},
        {key: 'slug', label: 'Slug',},
        {key: 'actions', label: 'Инструменты',},
      ],
      show: false,
      seoId: null,
    }
  },
  created() {
    this.indexSeoPages()
  },
  methods: {
    deleteSeoBtn(id) {
      this.show = true
      this.seoId = id
    },
    closeModalAfterDelete(id){
      this.deleteSeo(id).then(()=>{
        this.show = false
      })
    }
  },
}
</script>

<style scoped>

</style>