<template>
  <div class="edit-round-tournament-wrapper">
    <b-form @submit="submitForm">
      <b-form-group label="Название раунда" label-for="round_name">
        <b-form-input v-model="formModel.name" id="round_name" required />
      </b-form-group>
      <b-form-group label="Описание раунда" label-for="round_description">
        <b-form-textarea v-model="formModel.description" id="round_description" />
      </b-form-group>
      <div class="form-footer">
        <b-button :disabled="isLoadingSaveForm" type="submit" variant="primary">
          {{ isLoadingSaveForm ? 'Сохраняем...' : 'Сохранить' }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  name: "EditRoundTournament",
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoadingSaveForm: false,
      errorText: '',
      formModel: {
        name: this.params?.round?.name || '',
        description: this.params?.round?.description || '',
      }
    }
  },
  methods: {
    async submitForm(event) {
      try {
        event.preventDefault()
        this.isLoadingSaveForm = true
        this.errorText = null
        await this.$store.dispatch('moduleNewTournament/updateRound', {
          id: this.params.round.id,
          data: {
            name: this.formModel.name,
            ...(this.formModel.description && { description: this.formModel.description })
          }
        })
        await this.$store.dispatch('moduleNewTournament/getTournament', {
          id: this.$route.params.standingId
        })
        await this.$emit('closeModal')
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSaveForm = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.edit-round-tournament-wrapper {
  .form-footer {
    border-top: 1px solid #dee2e6;
    text-align: right;
    padding-top: 15px;
  }
}
</style>