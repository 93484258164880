export const ENTRY_STORE = 'entry-store';
export const ENTRY_DECLINE = 'entry-decline';
export const GAME_PROFILE_DATA_CHANGED = 'game-profile-data-changed';
export const DATA_CHANGE = 'data-change';
export const SOCIAL_ACCOUNT_ATTACHED = 'social-account-attached';
export const TOOK_PART_TEAM = 'took-part-team';
export const SOCIAL_ACCOUNT_DETACHED = 'social-account-detached';
export const LEAVE_TEAM = 'leave-team';
export const VERIFICATION_PHONE_DETACHED = 'verification-phone-detached';
export const VERIFICATION_PHONE_ATTACHED = 'verification-phone-attached';
export const DELETE_FOR_TEAM = 'delete-for-team';

export const CODES = {
    [ENTRY_STORE]: `Подана заявка на турнир`,
    [ENTRY_DECLINE]: `Заявка отозвана с турнира`,
    [GAME_PROFILE_DATA_CHANGED]: 'Изменены данные игрового профиля',
    [DATA_CHANGE]: 'Изменены данные',
    [SOCIAL_ACCOUNT_ATTACHED]: 'Пользователь привязал соц. сеть',
    [SOCIAL_ACCOUNT_DETACHED]: 'Пользователь отвязал соц. сеть',
    [TOOK_PART_TEAM]: 'Пользователь вступил в команду',
    [LEAVE_TEAM]: 'Пользователь покинул команду',
    [DELETE_FOR_TEAM]: 'Пользователь был удален из команды',
    [VERIFICATION_PHONE_DETACHED]: 'Пользователь отвязал номер',
    [VERIFICATION_PHONE_ATTACHED]: 'Пользователь привязал номер',
};






