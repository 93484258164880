import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            tournamentsOuter:[],
            pagination:{
                by:10,
                currentPage:1,
                totalPages:1,
            },
            formats: [],
            formatNames: [],
            formatId: [],
            formatFormats: [],
            timezones: [],
        }
    },
    mutations: {
        [mutypes.SET_TOURNAMENTS_OUTER]: (state, value) => state.tournamentsOuter = value,
        [mutypes.SET_TOURNAMENTS_OUTER_PAGINATION_TOTAL]: (state, value) => state.pagination.totalPages = value,
        [mutypes.SET_TOURNAMENTS_OUTER_PAGINATION_CURRENT_PAGE]: (state, value) => state.pagination.currentPage = value,
        [mutypes.SET_TOURNAMENTS_OUTER_FORMATS]: (state, value) => state.formats = value,
        [mutypes.SET_TIMEZONES]: (state, value) => state.timezones = value,
    },

    getters:{
        getTournamentsOuter                           : state => state.tournamentsOuter,
        getTournamentsOuterPaginationTotal            : state => state.pagination.totalPages,
        getTournamentsOuterPaginationCurrentPage      : state => state.pagination.currentPage,
        getFormats                                    : state => state.formats,
        getTimezones                                  : state => state.timezones,
    },

    actions: {
        indexTournamentsOuter: async ({commit,getters}, params) => {
            const response = await api.tournament.indexOuter({
                page: params.pageNumber,
                "search": params.search
            });

            await commit(mutypes.SET_TOURNAMENTS_OUTER, response.data.tournaments);
            await commit(mutypes.SET_TOURNAMENTS_OUTER_PAGINATION_TOTAL, response.data.pagination.total);
            await commit(mutypes.SET_TOURNAMENTS_OUTER_PAGINATION_CURRENT_PAGE, params.pageNumber);

            return getters.getTournamentsOuter;
        },
        deleteOuterTournaments: async ({commit, getters}, params) => {
            api.tournament.destroy(params.tournamentId)
            let tournaments = getters.getTournamentsOuter.filter(t => t.id !== params.tournamentId)
            await commit(mutypes.SET_TOURNAMENTS_OUTER, tournaments);
        },
        indexFormat: async({commit, getters}) => {
            const response = await api.format.index()
            let values = [];
            response.data.formats.forEach((item, index) => {
                values[index] = {
                    text:item.name,
                    formats: item.formats,
                    value:item.id,
                };
            })

            commit(mutypes.SET_TOURNAMENTS_OUTER_FORMATS, values)

            localStorage.setItem('formats', JSON.stringify(values));
        },
        indexTimeZones: async ({commit, getters}) => {
            const response = await api.timezones.index()
            commit(mutypes.SET_TIMEZONES, response.data.timezones)
            localStorage.setItem('timezones', JSON.stringify(response.data.timezones));
        }
    }
}