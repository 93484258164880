<template>
  <div class="confirm-reject-wow-streamer-wrapper">
    <b-form @submit.prevent="submitForm">
      <h5>Вы действительно хотите отклонить заявку стримера?</h5>
      <b-form-group label="Описание причины отклонения" label-for="rejectedDescription" class="mt-3">
        <b-form-textarea
          id="rejectedDescription"
          v-model="formModel.rejectedDescription"
          placeholder="Укажите причину отклонения"
          required
        ></b-form-textarea>
      </b-form-group>
      <b-alert v-if="errorText" show variant="danger" class="mt-3">{{ errorText }}</b-alert>
      <footer>
        <b-button type="submit" :disabled="isLoadingSaveForm" variant="primary" class="m-1">
          {{ isLoadingSaveForm ? 'В процессе...' : 'ОК' }}
        </b-button>
        <b-button @click="$emit('closeModal')" variant="secondary" class="m-1">
          Отмена
        </b-button>
      </footer>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { WowStreamersAdapterMixin } from "@/mixins/WowStreamers/WowStreamersAdapterMixin";

export default {
  name: "ConfirmRejectWowStreamer",
  mixins: [WowStreamersAdapterMixin],
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoadingSaveForm: false,
      errorText: null,
      formModel: {
        rejectedDescription: '',
      },
    };
  },
  methods: {
    ...mapActions(['rejectWowStreamer', 'fetchWowStreamerEntries']),
    async submitForm() {
      if (!this.formModel.rejectedDescription) {
        this.errorText = "Пожалуйста, заполните все поля";
        return;
      }

      try {
        this.isLoadingSaveForm = true;
        this.errorText = null;
        await this.rejectWowStreamer({
          streamerId: this.params.id,
          data: { rejected_description: this.formModel.rejectedDescription },
        });
        this.$emit("closeModal");
        this.$emit("actionSuccess");
        this.$toast.success('Заявка отклонена', { position: 'top-right' });
        this.fetchWowStreamerEntries({ pageNumber: this.getCurrentPage });
      } catch (e) {
        this.errorText = e.response.data.message;
        this.$toast.error(`Ошибка при отклонении стримера: ${this.errorText}`, { position: 'top-right' });
      } finally {
        this.isLoadingSaveForm = false;
      }
    },
  },
};
</script>

<style scoped>
.confirm-reject-wow-streamer-wrapper {
  footer {
    border-top: 1px solid #dee2e6;
    padding-top: 15px;
    margin-top: 15px;
    text-align: right;
  }
}
</style>
