import * as mutypes from "@/store/mutation.types";
import api from "@/api/api";

export default {
    state: () => {
        return {
            bannedTeams: [],
            pagination: {
                by: 10,
                currentPage: 1,
                totalPages: 1,
            },
            banGamesByTeam: null,
            banGameIdByTeam: null,
            banMembersByTeam: null,
        }
    },
    mutations: {
        [mutypes.SET_TEAMS_BAN]: (state, value) => state.bannedTeams = value,
        [mutypes.SET_BAN_GAMES_BY_TEAM]: (state, value) => state.banGamesByTeam = value,
        [mutypes.SET_BAN_GAME_ID_BY_TEAM]: (state, value) => state.banGameIdByTeam = value,
        [mutypes.SET_BAN_MEMBERS_BY_TEAM]: (state, value) => state.banMembersByTeam = value,
        [mutypes.SET_BAN_TEAMS_PAGINATION_TOTAL]: (state, value) => state.pagination.totalPages = value,
        [mutypes.SET_BAN_TEAMS_PAGINATION_CURRENT_PAGE]: (state, value) => state.pagination.currentPage = value,
    },
    getters: {
        getTeamsBan: state => state.bannedTeams,
        getBanGamesByTeam: state => state.banGamesByTeam,
        getBanGameIdByTeam: state => state.banGameIdByTeam,
        getBanMembersByTeam: state => state.banMembersByTeam,
        getBanTeamsTotalPagination: state => state.pagination.totalPages,
        getBanTeamsPaginationCurrentPage: state => state.pagination.currentPage,
    },
    actions: {
        async indexTeamsBan({commit, getters}, params) {
            let response = await api.teamBan.index({
                page: params.pageNumber,
                "search": params.search,
            })
            commit(mutypes.SET_TEAMS_BAN, response.data.teams)
            commit(mutypes.SET_BAN_TEAMS_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_BAN_TEAMS_PAGINATION_CURRENT_PAGE, params.pageNumber);
        },
        grabTeamGames: async ({commit}) => {
            await api.games.index().then(res => {
                let values = []
                res.data.games.forEach((item) =>
                    values.push({
                        value: item.id,
                        text: item.name
                    })
                )
                commit(mutypes.SET_BAN_GAMES_BY_TEAM, values);
            })
        },
        deleteTeamBan: async ({dispatch}, params) => {
            api.teamBan.delete(params.teamId).then(() => {
                dispatch('indexTeamsBan', {pageNumber: localStorage.getItem('pageNumberBanTeams')});
                dispatch('indexTeams', {pageNumber: localStorage.getItem('pageNumberTeamList')});
            })
        },
    }
}