<template>
  <div class="tournament-tabs-wrapper">
    <template v-if="list.length">
      <div v-for="(item, itemIdx) in list" class="tournament-tab" :key="item.id + (item?.name || 'nothing')">
        <div class="content"
             :id="idName + item.id"
             @click="clickByTab(item)"
             :class="[modelTab === item.id && 'active']">
          <slot name="item" :item="{item, isActive: modelTab === item.id && 'active'}">{{ item.name }}</slot>
        </div>
        <div class="actions" v-if="!hideActions">
          <template v-if="!onlyViewAction">
            <slot name="moreActions" :item="{ item, itemIdx }" />
            <button @click="$emit('edit', item.id)" v-b-tooltip.hover.top="`Редактировать ${ tabName }`"><img :src="PenIcon" alt=""></button>
            <button v-if="!hideDeleteAction" @click="$emit('delete', item.id)" v-b-tooltip.hover.top="`Удалить ${ tabName }`"><img :src="TrashIcon" alt=""></button>
          </template>
          <template v-else>
            <button @click="$emit('view', item.id)"><img :src="EyeIcon" alt=""></button>
          </template>
        </div>
      </div>
    </template>
    <div v-else class="pug-text">
      <slot name="pug">
        <h6>Элементов нет</h6>
      </slot>
    </div>
  </div>
</template>

<script>
import PenIcon from '@/assets/icons/pen.svg'
import TrashIcon from '@/assets/icons/trash-black.svg'
import EyeIcon from '@/assets/icons/eye.svg'

export default {
  name: "TournamentTabs",
  props: {
    value: {
      type: [Number, String],
    },
    list: {
      type: Array,
      required: true
    },
    hideActions: {
      type: Boolean,
      default: false
    },
    idName: {
      type: String,
      default: ''
    },
    tabName: {
      type: String,
      default: ''
    },
    onlyViewAction: {
      type: Boolean,
      default: false
    },
    hideDeleteAction: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      PenIcon,
      TrashIcon,
      EyeIcon
    }
  },
  computed: {
    modelTab: {
      get() { return this.value },
      set(newValue) {
        this.$emit('input', newValue)
      }
    },
  },
  methods: {
    clickByTab(item) {
      this.modelTab = item.id
      this.$emit('change', item)
    }
  }
}
</script>

<style scoped lang="scss">
.tournament-tabs-wrapper {

  & > .tournament-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .content {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;

      padding: 10px 15px;
      cursor: pointer;
      border-radius: 10px;
      transition: .2s;

      &:hover {
        background: #dad6ff;
        color: #7366ff;
      }

      & * {
        white-space: nowrap;
      }

      img {
        width: 15px;
        height: 15px;
        margin-left: 10px;
      }

      &.active {
        background: #7366ff;
        color: white;
        box-shadow: 0 0 7px 0 #7366ff;
      }
    }

    .actions {
      display: flex;
      gap: 5px;

      button {
        padding: 0;
        width: 25px;
        height: 25px;
        border-radius: 5px;
        border: none;
        transition: .2s;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: #dad6ff;
        }
      }

      img {
        width: 10px;
        height: 10px;
        display: block;
      }
    }
  }

  & > .tournament-tab:not(:last-child) {
    position: relative;

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      background: #80808033;

      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .pug-text {
    h6 {
      text-align: center;
      margin: 15px 0;
      color: #808080b0;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
</style>