<template>
  <div class="main-actions">
    <h6>
      Выбранные команды:
    </h6>
    <div class="header-actions">
      <b-button variant="outline-dark"
                :disabled="!selectedTeams.length"
                @click="isShowMoveToNextStageTournamentModal = true"
                class="header-action">
        Перенести в следующий этап
      </b-button>
    </div>
    <Modals v-model="isShowMoveToNextStageTournamentModal"
            :name="tournament?.game.slug === 'mlbb' ? 'MoveToNextStageMLBBTournament' : 'MoveToNextStageTournament'"
            title="Перенос команд на следующий этап"
            @submit="submitMove"
            :params="{ teams: selectedTeams }" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Modals from "@/components/modals/index.vue";

export default {
  name: "MainActions",
  components: {
    Modals,
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isShowMoveToNextStageTournamentModal: false
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['tournament']),
    selectedTeams: {
      get() { return this.value },
      set(newValue) { this.$emit('input', newValue) }
    }
  },
  methods: {
    submitMove() {
      this.selectedTeams = []
    }
  }
}
</script>

<style scoped lang="scss">
.main-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  background: white;
  padding: 20px;
  border-radius: 10px;
  transition: .2s;

  position: sticky;
  top: 0;
  left: 0;
  z-index: 6;
  box-shadow: 0 0 14px 0 #00000045;

  .header-action {
    border-radius: 15px;
    font-size: 11px;
    padding: 5px 18px;

    @media screen and (max-width: 800px) {
      font-size: 9px;
    }
  }

  h6 {
    margin: 0;

    @media screen and (max-width: 800px) {
      font-size: 12px;
    }
  }

  .group-header {
    margin: 0 !important;
  }
}
</style>