import axios from "axios";
import Env from "/env";
import Vue from "vue";

const adminHttpImg = axios.create({
    baseURL: `${Env.API_URL}`,
    headers: {
        "Content-Type": "multipart/form-data",
    }
})

/**
 * Request Interceptors
 */
const getAuthToken = () => localStorage.getItem('token')
const authInterceptor = (config) => {
    const token = getAuthToken()
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
    }
    config.headers['Accept-Language'] = 'ru'
    return config
}
adminHttpImg.interceptors.request.use(authInterceptor)

const errorInterceptor = e => {
    switch (e.response.status) {
        case 401:
            console.log('###', e.response.status, e.message)
            break
        case 403:
            Vue.toasted.error('У вашей роли нет доступа', {
                duration: 5000
            });
            break
        default:
            if(process.env.NODE_ENV === 'development')
                console.log('### ', e.response.status, e.message, e.response)
    }
    return Promise.reject(e)
}

const responseInterceptor = r => {
    return r
}

adminHttpImg.interceptors.response.use(responseInterceptor, errorInterceptor)

export default adminHttpImg;