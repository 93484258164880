import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/organizers';

export default {
    /**
     * GET: Organizers
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    index: () => adminHttp.get(END_POINT),

    show: (id) => adminHttp.get(END_POINT + `/${id}`),

    update: (organizerId, params) => adminHttp.put(END_POINT + `/${organizerId}`, params),

    store: (params) => adminHttp.post(END_POINT, params),

    delete: (id) => adminHttp.delete(END_POINT + `/${id}`),
}