<template>
  <div style="overflow-x: auto">
    <h5 class="mb-2 mt-4">
      Участники
    </h5>
    <div class="container">
      <div class="row" v-if="showEventData">
        <div  v-for="team in showEventData.teams" :key="Math.random() + 'teams-event-data' ">
          <div v-if="host.hash == team.hash" class="mr-5 ">
            <h5 class="mb-4 mt-4">Команда: {{ team.info?.name }} Slot: {{ team.slot_number }}</h5>
            <table class="table">
              <thead>
              <tr>
                <th scope="col" class="p-0" style="width: 140px">Nick</th>
                <th scope="col" class="p-0">Kill</th>
                <th scope="col" class="p-0">Up</th>
                <th scope="col" class="p-0">Down</th>
                <th scope="col" class="p-0">Жив</th>
                <th scope="col" class="p-0"></th>
              </tr>
              </thead>
              <tbody>
              <RowInHostsBook :disabled="showEventData?.type === 'ended'"
                              v-for="member in team.members"
                              :key="member.id"
                              :member="member"
                              :selected-match="selectedMatch"
                              :team="team"
              />
              <b-btn class="mt-2"
                     v-b-modal="'deathTeam' + team.team_id"
                     :disabled="showEventData?.type === 'ended'"
                     @click="teamNotEnterToLobby(team)"
              >
                Не вошла
              </b-btn>
              </tbody>
            </table>
          </div>
        </div>

        <b-modal centered
                 id="deathTeamModal"
                 ref="deathTeamModal"
        >
          <p class="my-4">Команда {{ selectedTeam?.info?.name }} точно не вошла?</p>
          <template #modal-footer>
            <div>
              <b-btn variant="primary"
                     class="mr-3"
                     @click="storeAllTeamsWithDeath({
                                     matchId: selectedMatch,
                                     token: selectedTeam?.hash,
                                     teamId: selectedTeam?.team_id});
                                     $refs.deathTeamModal.hide();">
                Да
              </b-btn>
              <b-btn variant="danger" @click="$refs.deathTeamModal.hide();">Отмена</b-btn>
            </div>
          </template>
        </b-modal>
      </div>
      <div v-else-if="!showEventData?.teams" class="loading-wrapper">
        <b-spinner class="spinner"></b-spinner>
      </div>
    </div>
  </div>
</template>
<script>

import {BookAdapterMixin} from "@/mixins/Book/BookAdapterMixin";
import RowInHostsBook from "@/components/Live/RowInHostsBook.vue";

export default {
  name: 'HostsLive',
  components: {RowInHostsBook},
  mixins: [BookAdapterMixin],
  props: {
    host: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      membersFields: [
        {key: 'pubg_id', label: 'Pubg Id', sortable: true},
        {key: 'pubg_nick', label: 'Pubg Nick', sortable: true},
        {key: 'kill', label: 'Kill', sortable: true},
        {key: 'up', label: 'Up', sortable: false},
        {key: 'down', label: 'Down', sortable: false},
        {key: 'alive', label: 'Жив', sortable: false},
        {key: 'send', label: '', sortable: false},

      ],
      striped: true,
      bordered: true,
      selectedTeam: {},
    }
  },
  methods: {
    teamNotEnterToLobby(team) {
      this.selectedTeam = team;
      this.$refs.deathTeamModal.show();
    }
  },
}
</script>
