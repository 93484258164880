<template>
  <div class="tab-label">
    <span>
      {{ data.name }}
    </span>
    <div>
      <span v-b-tooltip.hover.top="`Количество групп`">{{ data.groups.length }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabLabelRound",
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">

</style>