<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Стримеры Wow" title="Список стримеров Wow"/>
      <b-card>
        <div class="d-flex">
          <SearchInput v-model="searchModel" @search="getFilteredStreamers" :placeholder-name="'Поиск стримеров Wow'"/>
        </div>
        <div class="notification-for-search mb-4">Поиск по идентификатору, имени пользователя, электронной почте или контактам</div>
        <div v-if="isLoading" class="loading-wrapper">
          <b-spinner class="spinner"></b-spinner>
        </div>
        <b-table
            v-else
            responsive
            show-empty
            stacked="md"
            :striped="striped"
            :bordered="bordered"
            :fields="fields"
            :items="currentList"
            :tbody-tr-class="trClass"
            class="mb-5"
        >
          <template #cell(username)="data">
            <div>
              <router-link :to="`/users/${data.item.user.id}`">{{ data.item.user.username }}</router-link>
            </div>
          </template>
          <template #cell(contacts)="data">
            <div v-if="data.item.telegram">
              <a :href="data.item.telegram" target="_blank">{{ data.item.telegram }}</a>
            </div>
            <div v-if="data.item.user.email">{{ data.item.user.email }}</div>
            <div v-else-if="data.item.user.social_accounts[0]?.data.email">{{ data.item.user.social_accounts[0].data.email }}</div>
            <div v-else>-</div>
          </template>
          <template #cell(action)="data">
            <div class="row justify-content-center">
              <b-btn 
                variant="primary" 
                class="p-1 m-1" 
                @click="showWowStreamerInfo(data.item.id)"
              >
                <i class="fa fa-eye mx-1"></i>
              </b-btn>
              <b-btn 
                variant="primary" 
                class="p-1 m-1" 
                @click="toggleStreamInfo(data.item.id)"
              >
                <i class="fa fa-info-circle mr-1"></i>Подробнее
              </b-btn>
              <b-btn 
                variant="danger" 
                class="p-1 m-1" 
                @click="showBanModal(data.item.id)" 
                :disabled="data.item.banned_at"
              >
                <i class="fa fa-ban mr-1"></i>Забанить
              </b-btn>
            </div>
            <b-collapse :id="'collapse-' + data.item.id" class="mt-2">
              <b-card v-if="data.item.active_wow_stream">
                <p><strong>Название:</strong> {{ data.item.active_wow_stream.name }}</p>
                <p><strong>Описание:</strong> {{ data.item.active_wow_stream.description }}</p>
                <p><strong>ID лобби:</strong> {{ data.item.active_wow_stream.data.lobby_number }}</p>
                <p><strong>Пароль лобби:</strong> {{ data.item.active_wow_stream.data.password }}</p>
                <p><strong>Приз:</strong> {{ data.item.active_wow_stream.data.prize }}</p>
              </b-card>
              <b-card v-else>
                <p>Нет активного стрима</p>
              </b-card>
            </b-collapse>
          </template>
        </b-table>
        <b-col md="6" class="p-0">
          <b-pagination
              @change="handlePageChange"
              v-model="currentPage"
              :total-rows="totalPagination"
              :per-page="10"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-card>
    </div>
    <Modal
      name="BanWowStreamer"
      title="Бан Wow стримера"
      v-model="isShowBanWowStreamerModal"
      :params="{ id: selectedStreamerId }"
      @close="resetModal"
      @banSuccess="refreshStreamers"
    />
  </div>
</template>

<script>
import { WowStreamersAdapterMixin } from "@/mixins/WowStreamers/WowStreamersAdapterMixin";
import SearchInput from "@/components/SearchInput.vue";
import Modal from "@/components/modals/index.vue";

export default {
  name: "ListWowStreamers",
  components: { SearchInput, Modal },
  mixins: [WowStreamersAdapterMixin],
  async created() {
    this.isLoading = true;
    this.currentListType = 'approved';
    
    try {
      await this.fetchCurrentList({
        pageNumber: this.getCurrentPage
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },
  data() {
    return {
      searchModel: '',
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'username', label: 'Имя пользователя', sortable: true },
        { key: 'contacts', label: 'Контакты', sortable: true },
        { key: 'action', label: 'Инструменты', sortable: false, class: 'tools-col' },
      ],
      striped: true,
      bordered: true,
      isLoading: false,
      isShowBanWowStreamerModal: false,
      selectedStreamerId: null,
    };
  },
  methods: {
    handlePageChange(pageNumber) {
      this.fetchCurrentList({
        pageNumber,
        ...(this.searchModel && { search: this.searchModel }),
      });
    },
    getFilteredStreamers() {
      this.isLoading = true;
      this.fetchCurrentList({ search: this.searchModel }).finally(() => {
        this.isLoading = false;
      });
    },
    showBanModal(streamerId) {
      this.selectedStreamerId = streamerId;
      this.isShowBanWowStreamerModal = true;
    },
    refreshStreamers() {
      this.getFilteredStreamers();
    },
    resetModal() {
      this.isShowBanWowStreamerModal = false;
      this.selectedStreamerId = null;
    },
    async banEntry(id) {
      await this.banWowStreamer(id);
      this.getFilteredStreamers();
    },
    trClass() {
      return '';
    },
    toggleStreamInfo(streamerId) {
      this.$nextTick(() => {
        this.$root.$emit('bv::toggle::collapse', 'collapse-' + streamerId);
      });
    },
    async showWowStreamerInfo(id) {
      this.$router.push({ name: 'wow_streamer_info', params: { id } });
    }
  },
};
</script>

<style scoped>
.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.row .b-btn {
  margin-top: 5px;
  margin-bottom: 5px;
}

@media (max-width: 767.98px) {
  .row .b-btn {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
