<template>
  <div class="mt-4">
    <div v-for="(tournamentPlace,index) in tournamentPlaces" :key="'placePts_' + tournamentPlace.id">
      <AddTournamentPlaceForm
          :index="tournamentPlace.id"
          :place="tournamentPlace.place"
          :place_pts="tournamentPlace.place_pts"
          :key="'place_form_' + tournamentPlace.id"
      />
    </div>

    <div class="d-flex justify-content-center flex-wrap">
      <button class="btn btn-success mt-4"
              @click="addTournamentPlace">Добавить место
      </button>
      <button class="btn btn-danger mt-4 ml-2"
              @click="deleteTournamentPlace">Удалить место
      </button>
    </div>

  </div>
</template>

<script>
import AddTournamentPlaceForm from "@/pages/Tournament/AddTournamentPlaceForm.vue";
import {TournamentAdapterMixin} from "@/mixins/Tournament/TournamentAdapterMixin";
import {TournamentGroupResultAdapterMixin} from "@/mixins/TournamentGroupResult/TournamentGroupResultAdapterMixin";
import {TournamentStandingsAdapterMixin} from "@/mixins/Tournament/TournamentStandingsAdapterMixin";

export default {
  name: 'AddTournamentPlace',
  components: {AddTournamentPlaceForm},
  mixins: [TournamentAdapterMixin, TournamentGroupResultAdapterMixin, TournamentStandingsAdapterMixin],
  beforeDestroy() {
    this.tournamentPlaces = []
  },
  created() {
    if (this.$route.name === 'tournament_edit') {
      this.getPlacePtsFromGroupResults()
    }
  }
}
</script>