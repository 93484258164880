import api from "/src/api/api";
import * as mutypes from "/src/store/mutation.types";


export default {
    state: ()=> {
        return {
            tournamentStageGroup: {
                id: null,
                name: '',
                slot_count: null,
                status: null,
            },
            tournamentStageGroupGameTime:null,
            tournamentStageGroupNewName: '',
            tournamentStageGroupNewSlot: ''
        }
    },
    mutations: {
        [mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP]          : (state,value) => state.tournamentStageGroup = value,
        [mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP_ID]       : (state,value) => state.tournamentStageGroup.id = value,
        [mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP_NAME]     : (state,value) => state.tournamentStageGroup.name = value,
        [mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP_SLOT_COUNT]     : (state,value) => state.tournamentStageGroup.slot_count = value,
        [mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP_STATUS]     : (state,value) => state.tournamentStageGroup.status = value,
        [mutypes.SET_TOURNAMENT_STAGES_GROUP_NEW_NAME]             : (state,value) => state.tournamentStageGroupNewName = value,
        [mutypes.SET_TOURNAMENT_STAGES_GROUP_NEW_SLOT]             : (state,value) => state.tournamentStageGroupNewSlot = value,
        [mutypes.SET_TOURNAMENT_STAGES_GROUP_GAME_TIME]             : (state,value) => state.tournamentStageGroupGameTime = value,
    },
    getters: {
        getTournamentStageGroup         : state => state.tournamentStageGroup,
        getTournamentStageGroupId       : state => state.tournamentStageGroup.id,
        getTournamentStageGroupName     : state => state.tournamentStageGroup.name,
        getTournamentStageGroupSlot     : state => state.tournamentStageGroup.slot_count,
        getTournamentStageGroupStatus   : state => state.tournamentStageGroup.status,
        getTournamentStageGroupNewName   : state => state.tournamentStageGroupNewName,
        getTournamentStageGroupNewSlot   : state => state.tournamentStageGroupNewSlot,
        getTournamentStageGroupGameTime   : state => state.tournamentStageGroupGameTime,
    },
    actions: {
        async indexTournamentStageGroup({commit,getters}, ){
            let response = await api.tournamentStageGroup.index()
            commit(mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP, response.data.groups)
        },
        async storeTournamentStageGroup({commit, getters}, {stage_id, name, slot_count, status, game_time}){
           await api.tournamentStageGroup.store({
                stage_id,
                name,
                slot_count,
                game_time,
                status
            })
        },

        async showTournamentStageGroup({commit, getters}, groupId){
            let response = await api.tournamentStageGroup.show(groupId)
            commit(mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP, response.data.group)
            commit(mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP_ID, response.data.group.id)
            commit(mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP_NAME, response.data.group.name)
            commit(mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP_SLOT_COUNT, response.data.group.slot_count)
            commit(mutypes.SET_TOURNAMENT_STAGES_GROUP_GAME_TIME, response.data.group.game_time)

            let statusCode = response.data.group.status.code
            let statusId = response.data.group.status.id
            commit(mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP_STATUS, {
                value: statusId,
                text: statusCode
            })
        },
        async updateTournamentStageGroup({commit,getters},id){
            let response = await api.tournamentStageGroup.update(id,{
                'name':  getters.getTournamentStageGroupName,
                'slot_count': getters.getTournamentStageGroupSlot,
                'status'    : getters.getTournamentStageGroupStatus.value,
                'game_time' : getters.getTournamentStageGroupGameTime
            })
        }
    }
}
