<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Бан" title="История банов пользователя"/>
      <b-card>
        <div>
          <b-form>
            <div>
              <b-table
                  table-variant="secondary"
                  responsive
                  show-empty
                  stacked="md"
                  :striped="striped"
                  :bordered="bordered"
                  :fields="fields"
                  :items="userBansList"
                  :current-page=0
                  :per-page=10
                  class="mb-5"
              >
                <template v-slot:cell(title)="{item}">
                  <div>{{ item.game.name }}</div>
                </template>
                <template v-slot:cell(start)="{item}">
                  <div>{{ item.from }}</div>
                </template>
                <template v-slot:cell(end)="{item}">
                  <div>{{ item.to }}</div>
                </template>
                <template v-slot:cell(reason)="{item}">
                  <div>{{ item.description }}</div>
                </template>
              </b-table>
            </div>
          </b-form>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>

import {UserBanAdapterMixin} from "@/mixins/UserBan/UserBanAdapterMixin";

export default {
  name: "UserBanInfoList",
  mixins: [UserBanAdapterMixin],
  created() {
      this.showUsersBan(this.$route.params.id)
  },
  data() {
    return {
      fields: [
        {key: 'id', label: 'id', sortable: true},
        {key: 'title', label: 'Тайтл игры', sortable: true},
        {key: 'start', label: 'Дата начала бана', sortable: true},
        {key: 'end', label: 'Дата окончания бана', sortable: true},
        {key: 'reason', label: 'Причина бана', sortable: true},
      ],
      striped: true,
      bordered: true,
    };
  },
};
</script>