import * as mutypes from "@/store/mutation.types";
import api from "@/api/api";


export default {
    state: () => {
        return {
            name: [],
            email: '',
            password: '',
            usersFake: [],
            userFakeTeamName: '',
        }
    },
    mutations: {
        [mutypes.SET_USER_FAKE]: (state, value) => state.usersFake = value,
        [mutypes.SET_USER_FAKE_NAME]: (state, value) => state.name = value,
        [mutypes.SET_USER_FAKE_EMAIL]: (state, value) => state.email = value,
        [mutypes.SET_USER_FAKE_PASSWORD]: (state, value) => state.password = value,
        [mutypes.SET_USER_FAKE_TEAM_NAME]: (state, value) => state.userFakeTeamName = value,
    },
    getters: {
        getUserFake: state => state.usersFake,
        getUserFakeName: state => state.name,
        getUserFakeEmail: state => state.email,
        getUserFakePassword: state => state.password,
        getUserFakeTeamName: state => state.userFakeTeamName,
    },
    actions: {
        async indexUserFake({commit, getters}, params) {
            let response = await api.userFake.index(params)
            return response.data.fake_users;
        },
        async showUserFake({commit, getters}, id) {
            let response = await api.userFake.show(id)
        },
        async storeUserFake({commit, getters}, params) {

            let response = await api.userFake.store(params)
        },
        async updateUserFake({commit, getters}, id, {params}) {
            let response = await api.userFake.update(id, {params})
        },
        async deleteUserFake({commit, getters}, id) {
            let response = await api.userFake.delete(id)
        }

    }
}