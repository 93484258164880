import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const BookAdapterMixin = {
    data() {
        return {
            uniqueHash: [],
            name: null,
            login: null,
            password: '',
            date: '',
            hostCount: 4,
            withBookmaker: false,
        }
    },
    computed: {
        ...mapGetters({
            getEventShow: 'getEventShow',
            getTeamsForLive: 'getTeamsForLive',
            getTournamentCurrentMatchesBook: 'getTournamentCurrentMatchesBook',
            getTournamentCurrentMatchesSelectedMatchIdBook: 'getTournamentCurrentMatchesSelectedMatchIdBook',
            getSelectedMatch: 'getSelectedMatch',
            getSelectedMatchName: 'getSelectedMatchName',
            getSelectedMatchLogin: 'getSelectedMatchLogin',
            getSelectedMatchPassword: 'getSelectedMatchPassword',
            getSelectedMatchDate: 'getSelectedMatchDate',
            getMatchWithBookmaker: 'getMatchWithBookmaker',
            getMatchHostCount: 'getMatchHostCount',
            getLiveMembersCount: 'getLiveMembersCount',
            getLiveTeamsCount: 'getLiveTeamsCount',
        }),
        showEventData: {
            get() {
                return this.getEventShow
            }, set(value) {
                this.setEventShow(value)
            }
        },
        teamsForLive: {
            get() {
                return this.getTeamsForLive
            }, set(value) {
                this.setTeamsForLive(value)
            }
        },
        currentMatchesBook: {
            get() {
                return this.getTournamentCurrentMatchesBook
            }, set(value) {
                this.setTournamentCurrentMatchesBook(value)
            }
        },
        selectedCurrentMatchIdBook: {
            get() {
                return this.getTournamentCurrentMatchesSelectedMatchIdBook
            }, set(value) {
                this.setTournamentCurrentMatchesSelectedMatchIdBook(value)
            }
        },
        selectedMatch: {
            get() {
                return this.getSelectedMatch
            }, set(value) {
                this.setSelectedMatch(value)
            }
        },
        selectedCurrentMatchName: {
            get() {
                return this.getSelectedMatchName
            }, set(value) {
                this.setSelectedMatchName(value)
            }
        },
        selectedCurrentMatchLogin: {
            get() {
                return this.getSelectedMatchLogin
            }, set(value) {
                this.setSelectedMatchLogin(value)
            }
        },
        selectedCurrentMatchPassword: {
            get() {
                return this.getSelectedMatchPassword
            }, set(value) {
                this.setSelectedMatchPassword(value)
            }
        },
        selectedCurrentMatchDate: {
            get() {
                return this.getSelectedMatchDate
            }, set(value) {
                this.setSelectedMatchDate(value)
            }
        },
        matchWithBookmaker: {
            get() {
                return this.getMatchWithBookmaker
            }, set(value) {
                this.setMatchWithBookmaker(value)
            }
        },
        liveMembersCount: {
            get() {
                return this.getLiveMembersCount
            }, set(value) {
                this.setLiveMembersCount(value)
            }
        },
        liveTeamsCount: {
            get() {
                return this.getLiveTeamsCount
            }, set(value) {
                this.setLiveTeamsCount(value)
            }
        },
        selectedCurrentHostCount: {
            get() {
                return this.getMatchHostCount
            }, set(value) {
                this.setMatchHostCount(value)
            }
        },
    },
    methods: {
        ...mapMutations({
            setEventShow: mutypes.SET_EVENT_SHOW,
            setTeamsForLive: mutypes.SET_TEAMS_FOR_LIVE,
            setTournamentCurrentMatchesBook: mutypes.SET_TOURNAMENT_CURRENT_MATCHES_BOOK,
            setTournamentCurrentMatchesSelectedMatchIdBook: mutypes.SET_TOURNAMENT_CURRENT_MATCHES_SELECTED_MATCH_ID_BOOK,
            setSelectedMatch: mutypes.SET_SELECTED_MATCH,
            setSelectedMatchName: mutypes.SET_SELECTED_MATCH_NAME,
            setSelectedMatchLogin: mutypes.SET_SELECTED_MATCH_LOGIN,
            setSelectedMatchPassword: mutypes.SET_SELECTED_MATCH_PASSWORD,
            setSelectedMatchDate: mutypes.SET_SELECTED_MATCH_DATE,
            setMatchWithBookmaker: mutypes.SET_MATCH_WITH_BOOKMAKER,
            setMatchHostCount: mutypes.SET_MATCH_HOST_COUNT,
            setLiveTeamsCount: mutypes.SET_MATCH_LIVE_TEAMS_COUNT,
            setLiveMembersCount: mutypes.SET_MATCH_LIVE_COUNT,
        }),
        ...mapActions([
            'showGroupMatchesBook',
            'storeRate',
            'showEventResult',
            'addRate',
            'storePlace',
            'storeLive',
            'storeKill',
            'storeEnd',
            'storeAllTeamsWithDeath',
            'storeMatch',
            'deleteMatch',
            'updateMatchBook',
            'showSelectedMatch',
            'showMembersTournamentCount'
        ]),
        getUniqueHashForRenderTeams() {
            /* @todo надо разобраться,
             пока я просто добавляю тут сортировку по рейту чтобы
             сортировка бэка не ломала логику хостов
             Нужно так потому что в дальнейшем 1 хост будет видеть только свою вкладку отправляя hash token
             а сейчас фронт его не отправляет
             */

            //this.event
            const teams = this.showEventData.teams;
            const sortedTeamsForHost = teams.sort((a,b) => a.slot_number - b.slot_number);

            this.uniqueHash = sortedTeamsForHost.reduce((team, hash) =>
                team.map[hash.hash] ? team : ((team.map[hash.hash] = true),
                    team.uniqueHash.push(hash), team), {
                map: {},
                uniqueHash: []
            }).uniqueHash;
        },
    }
}
//11261