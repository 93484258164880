<template>
  <div class="edit-match-modal-wrapper">
    <b-form>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Название матча</label>
        <ValidationProvider rules="min:2|required" v-slot="{errors}">
          <b-form-input v-model="selectedCurrentMatchName"/>
          <div class="text-danger">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Lobby number</label>
        <ValidationProvider rules="min:2|required" v-slot="{errors}">
          <b-form-input v-model="selectedCurrentMatchLogin"></b-form-input>
          <div class="text-danger">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Password</label>
        <ValidationProvider rules="min:2|required" v-slot="{errors}">
          <b-form-input v-model="selectedCurrentMatchPassword"></b-form-input>
          <div class="text-danger">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Дата</label>
        <DatePicker v-model="selectedCurrentMatchDate" mode="dateTime" is24hr
                    :model-config="modelConfig" :popover="{visibility: 'click'}">
          <template v-slot="{inputValue, inputEvents}">
            <b-form-input :value="inputValue" v-on="inputEvents"/>
          </template>
        </DatePicker>
      </div>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Кол-во хостов</label>
        <ValidationProvider rules="required" v-slot="{errors}">
          <b-form-input v-model="selectedCurrentHostCount"
                        placeholder="Введите количество хостов"></b-form-input>
          <div class="text-danger">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>
      <b-alert v-if="errorText" show variant="danger">{{ errorText }}</b-alert>
      <div class="form-footer">
        <b-btn :disabled="isLoadingSaveForm" variant="primary" @click="editMatch">
          {{ isLoadingSaveForm ? 'Сохраняем...' : 'Сохранить' }}
        </b-btn>
      </div>
    </b-form>
  </div>
</template>

<script>
import {BookAdapterMixin} from "@/mixins/Book/BookAdapterMixin";
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: "EditMatchForBook",
  components: {DatePicker},
  mixins: [BookAdapterMixin],
  data() {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
      isLoadingSaveForm: false,
      errorText: null,
    };
  },
  methods: {
    async editMatch(event) {
      try {
        event.preventDefault()
        this.isLoadingSaveForm = true
        this.errorText = null
        await this.updateMatchBook({
              matchId: this.selectedMatch,
              name: this.selectedCurrentMatchName,
              group_id: this.$route.params.group_id,
              lobby_number: this.selectedCurrentMatchLogin,
              password: this.selectedCurrentMatchPassword,
              start_time: this.selectedCurrentMatchDate,
              status: 0,
              host_count: this.selectedCurrentHostCount,
              with_bookmaker: 1
            })
        await this.showGroupMatchesBook(this.$route.params.group_id)
        await this.$emit('submit')
        await this.$emit('closeModal')
      } catch (e) {
        const message = e.response.data.message
        let errors = ''
        if (e.response.data.errors) errors = Object.entries(e.response.data.errors).map(([ name, values ]) => `${ name }: ${ values.join(', ') }`).join('. ')
        this.errorText = `${ message }; ${ errors }.`
      } finally {
        this.isLoadingSaveForm = false
      }
    },
  }
};
</script>

<style scoped lang="scss">
.edit-match-modal-wrapper {

  .form-footer {
    border-top: 1px solid #dee2e6;
    text-align: right;
    padding-top: 15px;
  }
}
</style>