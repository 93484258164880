<template>
  <div class="have-teams-olympic-wrapper">
    <MainActions v-model="selectedTeams" v-if="!isCompletedTournament" />
    <div ref="actionsBlock" class="actions">
      <div>
        <b-input v-model="searchTeamModel"
                 class="search-teams-input-custom"
                 v-b-tooltip.hover.top="'Поиск доступен по названию команды, nick и MLBB_ID'"
                 placeholder="Поиск команды" />
      </div>
      <div class="buttons" v-if="!isCompletedTournament">
        <template class="d-flex">
          <button @click="isShowGenerateMatchesModal = true" class="action-button">
           Сгенерировать матчи
          </button>
          <button v-for="action in actions"
                  class="action-button"
                  :key="action.title"
                  @click="action.method">
            <span class="d-none d-sm-block">{{ action.title }}</span>
            <span v-if="action.icon" class="d-sm-none d-block" v-html="action.icon" />
          </button>
        </template>
      </div>
    </div>
    <transition-group name="list-complete" tag="div" class="transition-list">
      <template v-for="({ team, group_members, ...other }, index) in filteredTeams">
        <TeamCardByOlympic :team="team"
                           v-if="team"
                           :serialNumber="index + 1"
                           v-model="selectedTeams"
                           :result="other"
                           :members="group_members"
                           @changeWorkspace="(name) => $emit('changeWorkspace', name)"
                           :key="`team-card-${ other.id } ${ other.team_id } ${ other.group_id }`" />
      </template>
    </transition-group>
    <Modal name="GenerateMatchesForOlympic" title="Генерация матчей" v-model="isShowGenerateMatchesModal" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MainActions from "@/components/Tournament/MainActions.vue";
import TeamCardByOlympic from "@/components/Tournament/MLBB/components/TeamCardByOlympic.vue";
import Modal from '@/components/modals/index.vue'

export default {
  name: "HaveTeamsOlympic",
  components: {
    MainActions,
    TeamCardByOlympic,
    Modal
  },
  data() {
    return {
      selectedTeams: [],
      searchTeamModel: '',
      isShowAllActions: false,
      isShowGenerateMatchesModal: false,
      actions: [
        {
          main: true,
          title: 'Добавить команду',
          icon: '<i class="fa fa-users fa-lg" aria-hidden="true"></i> +',
          method: () => {
            this.$emit('changeWorkspace', 'AddTeam')
          }
        }
      ]
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['teamsByGroup', 'tournament']),
    ...mapGetters('moduleNewTournament', ['isCompletedTournament']),
    filteredTeams() {
      const byTeamNames = this.teamsByGroup.filter((group) => {
        return group.team ? group.team.name.toLowerCase().search(this.searchTeamModel) > -1 : false
      })

      const byMembersNickAndId = this.teamsByGroup.filter((group) => group.group_members.some((groupMember) => {
        const gameProfile = groupMember.user.game_profiles.find(({ game_id }) => game_id === this.tournament.game_id)
        const nick = gameProfile?.fields?.nick || ''
        const id = String(gameProfile?.fields?.mlbb_id) || ''
        return nick.toLowerCase().search(this.searchTeamModel.toLowerCase()) > -1 || id.toLowerCase().search(this.searchTeamModel.toLowerCase()) > -1
      }))

      const resultTeams = [
        ...byTeamNames,
        ...byMembersNickAndId
      ]
      const unicalResultTeams = [...new Set(resultTeams.map(({ id }) => id ))]

      return unicalResultTeams.map((unicalId) => resultTeams.find(({ id }) => id === unicalId))
    }
  },
  mounted() {
    const resizeObserverActionsBlock = new ResizeObserver((entries) => {
      try {
        const parentWrapperWidth = entries[0].target.offsetWidth
        // console.log('parentWrapperWidth ', parentWrapperWidth)
        this.isShowAllActions = parentWrapperWidth >= 640
      } catch (e) {
        console.log('e ', { e })
      }
    })

    resizeObserverActionsBlock.observe(this.$refs.actionsBlock)
  }
}
</script>

<style scoped lang="scss">
.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}
.list-complete-enter, .list-complete-leave-to
  /* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(30px);
}
.list-complete-leave-active {
  position: absolute;
}
.have-teams-olympic-wrapper {
  position: relative;

  .search-teams-input-custom {
    font-size: 13px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    @media screen and (max-width: 500px) {
      display: block;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      @media screen and (max-width: 500px) {
        margin-top: 14px;
        justify-content: flex-start;
      }
    }
  }

  & > .transition-list {
    margin-top: 20px;

    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .action-button {
    display: block;
    font-size: 11px;
    border-radius: 15px;
    padding: 10px 20px;
    background: #7366ff;
    color: white;
    border: none;
    white-space: nowrap;

    @media screen and (max-width: 800px) {
      padding: 10px 15px;
    }
  }
}

.action-more-dropdown::v-deep {
  button {
    display: block;
    font-size: 11px;
    border-radius: 15px;
    padding: 10px 20px;
    background: #7366ff;
    color: white;
    border: none;
    line-height: 12px;
  }
}
</style>