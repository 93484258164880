<template>
  <div class="ban-user-wrapper">
    <p class="">{{userShow.id}}. {{userShow.username}}</p>
    <p class="">Язык пользователя - {{userShow?.lang?.code}}</p>
    <div>
      <label for="">Дисциплина</label>
      <b-form-select :options="banGamesByUser" v-model="gameIdForBan"></b-form-select>
    </div>
    <div>
      <label for="">Дата начала бана</label>
      <datetime format="YYYY-MM-DD H:i" v-model="startBanTime"></datetime>
    </div>
    <div>
      <label for="">Дата окончания бана</label>
      <datetime format="YYYY-MM-DD H:i" v-model="endBanTime"></datetime>
    </div>
    <div class="mt-3">
      <label for="">Причина бана</label>
      <b-form-textarea v-model="descriptionForBan" ></b-form-textarea>
    </div>
    <b-alert v-if="errorText" show variant="danger" class="mt-3">{{ errorText }}</b-alert>
    <footer>
      <b-button @click="submitForm" :disabled="isLoadingSaveForm" variant="primary">
        {{ isLoadingSaveForm ? 'Сохраняем...' : 'Сохранить' }}
      </b-button>
    </footer>
  </div>
</template>

<script>
import datetime from "vuejs-datetimepicker";
import {UsersAdapterMixin} from "@/mixins/Users/UsersAdapterMixin";
import {LanguageAdapterMixin} from "@/mixins/Language/LanguageAdapterMixin";
import api from "@/api/api";
import {UserBanAdapterMixin} from "@/mixins/UserBan/UserBanAdapterMixin";

export default {
  name: "BanUser",
  components: {datetime},
  mixins: [UsersAdapterMixin, LanguageAdapterMixin, UserBanAdapterMixin],
  props: {
    params: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isLoadingSaveForm: false,
      errorText: null,
      gameIdForBan: null,
      startBanTime: null,
      endBanTime: null,
      descriptionForBan: null
    }
  },
  methods: {
    async submitForm() {
      try {
        this.isLoadingSaveForm = true
        this.errorText = null
        await api.userBan.store({
          game_id: this.gameIdForBan,
          from: this.startBanTime,
          to: this.endBanTime,
          description: this.descriptionForBan,
          model_id: this.params.id
        })
        await this.$store.dispatch('grabUsers', {pageNumber: localStorage.getItem('pageNumberUsersList')})
        await this.$emit('closeModal')
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSaveForm = false
      }
    }
  },
  mounted() {
    this.showUser(this.params.id)
    this.grabUserGames()
  },
}
</script>


<style scoped lang="scss">
.ban-user-wrapper {

  footer {
    border-top: 1px solid #dee2e6;
    padding-top: 15px;
    margin-top: 15px;
    text-align: right;
  }
}
</style>