<template>
  <div class="tournament-dashboard-wrapper">
    <div class="workspace">
      <slot name="workspace"></slot>
    </div>
    <div class="sidebar">
      <slot name="sidebar"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TournamentDashboard"
}
</script>

<style scoped lang="scss">
.tournament-dashboard-wrapper {
  padding-top: 25px;
  display: grid;
  grid-template-columns: auto minmax(200px, 350px);
  gap: 20px;
  padding-left: 20px;
  padding-bottom: 30px;

  overflow-y: auto;
  max-height: calc(100vh - 86px - 53px);


  @media screen and (max-width: 800px) {
    grid-template-columns: 100%;
    padding-right: 20px;
  }

  .sidebar {
    background: white;
    padding: 20px;
    border-radius: 10px;

    position: sticky;
    top: 0;
    right: 0;
    max-height: 60vh;
    overflow-y: auto;

    @media screen and (max-width: 800px) {
      grid-row-start: 1;
      position: static;
      max-height: none;
    }

    & > *:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}
</style>