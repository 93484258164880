import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";


export const TournamentEntriesAdapterMixin = {
    data() {
        return {
            selectedDivisionSlug: '',
            selectedEntryStatus: 0,
            isLoading: false,
            selectedStage: 0,
            searchForEntriesModel: '',
        }
    },
    computed: {
        ...mapGetters({
            getTournamentEntries: 'getTournamentEntries',
            getEntriesPaginationTotal: 'getEntriesPaginationTotal',
            getEntriesPaginationCurrentPage: 'getEntriesPaginationCurrentPage',
        }),
        entries: {
            get() {
                return this.getTournamentEntries
            }, set(value) {
                this.setEntries(value)
            }
        },
        total: {
            get() {
                return this.getEntriesPaginationTotal
            }, set(value) {
                this.setEntriesPaginationTotal(value)
            }
        },
        currentPage: {
            get() {
                return this.getEntriesPaginationCurrentPage
            }, set(value) {
                this.setEntriesCurrentPage(value)
            }
        },

    },
    methods: {
        ...mapMutations({
            setEntries: mutypes.SET_TOURNAMENT_ENTRIES,
            setEntriesPaginationTotal: mutypes.SET_TOURNAMENT_ENTRIES_PAGINATION_TOTAL,
            setEntriesCurrentPage: mutypes.SET_TOURNAMENT_ENTRIES_PAGINATION_CURRENT_PAGE,
        }),
        ...mapActions([
            'indexTournamentEntries',
            'deleteTournamentEntries',
            'deleteTournamentEntry'
        ]),
        async getEntriesByDivision(division) {
            this.isLoading = true
            await this.indexTournamentEntries({
                id: this.$route.params.id,
                ...(division && {division: division}),
                ...(this.selectedStage && {stage: this.selectedStage}),
                ...(this.selectedEntryStatus && {status: this.selectedEntryStatus}),
                ...(this.searchForEntriesModel && {search: this.searchForEntriesModel}),
            })
                .finally(() => {
                    this.selectedDivisionSlug = division
                    this.isLoading = false
                })

        },
        async getEntriesByStage(stage) {
            this.isLoading = true
            await this.indexTournamentEntries({
                id: this.$route.params.id,
                ...(stage && {stage: stage}),
                ...(this.selectedDivisionSlug && {division: this.selectedDivisionSlug}),
                ...(this.selectedEntryStatus && {status: this.selectedEntryStatus}),
                ...(this.searchForEntriesModel && {search: this.searchForEntriesModel}),
            })
                .finally(() => {
                    this.selectedStage = stage
                    this.isLoading = false
                })

        },
        async getEntriesByStatus(status) {
            this.isLoading = true
            await this.indexTournamentEntries({
                id: this.$route.params.id,
                ...(this.selectedDivisionSlug && {division: this.selectedDivisionSlug}),
                ...(this.selectedStage && {stage: this.selectedStage}),
                ...(status && {status: status}),
                ...(this.searchForEntriesModel && {search: this.searchForEntriesModel}),
            })
                .finally(() => {
                    this.selectedEntryStatus = status
                    this.isLoading = false
                })

        },
    }
}