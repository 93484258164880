import * as mutypes from "@/store/mutation.types";
import api from "@/api/api";

export default {
    state: () => {
        return {
            seoPages: [],
            currentSeoPage: []
        }
    },
    mutations: {
        [mutypes.SET_SEO_PAGES]: (state, value) => state.seoPages = value,
        [mutypes.SET_CURRENT_SEO_PAGE]: (state, value) => state.currentSeoPage = value
    },
    getters: {
        getSeoPages: state => state.seoPages,
        getCurrentSeoPage: state => state.currentSeoPage,
    },
    actions: {
        async indexSeoPages({commit, getters}){
            const response = await api.seo.getSeoList()
            commit(mutypes.SET_SEO_PAGES, response.data.seo_pages)
        },
        async showSeoPage({commit, getters}, id){
            const response = await api.seo.showSeo(id)
            commit(mutypes.SET_CURRENT_SEO_PAGE, response.data.seo_page)
        },
        async postSeo({commit, getters}, params){
            const response = await api.seo.postSeo(params)
        },
        async updateSeo({commit, getters}, params){
            const response = await api.seo.updateSeo(params.id, params)
        },
        async deleteSeo({commit, getters}, id){
            const response = await api.seo.deleteSeo(id)
            let seo = getters.getSeoPages.filter(t => t.id !== id)
            await commit(mutypes.SET_SEO_PAGES, seo)
        }
    }
}