<template>
  <b-modal v-model="isShow"
           centered
           :size="size"
           :title="title"
           :no-close-on-backdrop="noCloseOnBackdrop"
           :modal-footer="false">
    <component v-if="isShow"
               :is="currentModalComponent"
               @submit="(data) => $emit('submit', data)"
               @closeModal="closeModal"
               :params="params"
               :size="size"/>
  </b-modal>
</template>

<script>
import modals from './list'

export default {
  name: "ModalWrapper",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    params: {
      type: Object,
      default: () => {}
    },
    size: {
      type: String,
      required: false,
      default: 'md'
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentModalComponent() { return modals.get(this.name) },
    isShow: {
      get: function () { return this.value },
      set: function (newValue) { this.$emit('input', newValue) }
    }
  },
  methods: {
    closeModal() {
      this.isShow = false
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .modal-footer {
    display: none !important;
  }
}
</style>