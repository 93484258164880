<template>
  <div>
    <Breadcrumbs main="Турниры" title="Добавление результатов команде"></Breadcrumbs>

    <div class="container-fluid">
      <b-card>
        <div>
          <b-tabs>
            <b-tab v-for="match in matches" :title="match.name" :key="match.id">
              <div class="mb-2 mt-3">
                <span>Place</span>
                <place_pts_input
                    :match_id="match.id"
                    :place_pts="match.place_pts"
                />
              </div>
              <b-table responsive striped bordered :fields="fields" :items="match.player_results">
                <template v-slot:cell(index)="data">
                  {{ data.index + 1 }}
                </template>
                <template v-slot:cell(nick)="data">
                  {{ data.item.user.gameProfile.fields.nick }}
                </template>
                <template v-slot:cell(pubg_id)="data">
                  {{ data.item.user.gameProfile.fields.pubg_id }}
                </template>
                <template v-slot:cell(kill_pts)="data">
                  <kill_pts_input
                      :user_id="data.item.user_id"
                      :team_id="match.team_id"
                      :match_id="match.id"
                      :kill_pts="data.item.kill_pts"
                      :id="data.item.id"
                  />
                </template>
              </b-table>
            </b-tab>
          </b-tabs>

        </div>
        <div class="!flex mt-2">
          <b-btn variant="primary" class="mr-3" @click="$router.go(-1)">Назад</b-btn>
          <b-btn variant="success" @click.prevent="saveResults">Cохранить</b-btn>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  TournamentGroupResultAdapterMixin
} from "../../../mixins/TournamentGroupResult/TournamentGroupResultAdapterMixin";
import kill_pts_input from "../../../components/kill_pts_input";
import place_pts_input from "../../../components/PlacePtsInput.vue";
import * as mutypes from "../../../store/mutation.types";

export default {
  name: 'TeamInfoStandingsTournament',
  mixins: [TournamentGroupResultAdapterMixin],
  data() {
    return {
      placePts: [],
      fields: [
        {key: 'index', label: '№'},
        {key: 'nick', label: 'PUBG M nickname',},
        {key: 'pubg_id', label: 'PUBG M ID',},
        {key: 'kill_pts', label: 'Kill pts',},
      ],
    }
  },

  components: {
    kill_pts_input,
    place_pts_input
  },
  created() {
    this.showMatchesResult(this.$route.params.group_result_id);
  },
  methods: {
    saveResults() {
      this.storeMatchesResult(this.$route.params.group_result_id).then(() => {
        this.clearMatchesResultState();

        this.$router.push({
          name: 'tournament_standings', params: {
            standingId: this.$route.params.tournament_id
          }
        })
      }).catch((error) => {
        if (error.response) {
          this.$toasted.show(error.response.data.message, {
            theme: "outline",
            position: "top-right",
            type: "error",
            duration: 2000,
          });
        }
      })
    }
  }
}
</script>