<template>
  <div>
    <Breadcrumbs main="Турниры" title="Создать турнир"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Создание турнира</h5><span></span>
            </div>
            <div class="card-body">
              <b-card>
                <b-tabs>
                  <ValidationObserver ref="form">
                  <b-tab title="Основные" active>
                    <template #title>
                      <span class="fa fa-home mr-2"></span>
                      <span>Основные</span>
                    </template>
                    <b-form>
                      <div class="mt-3">
                        <ValidationProvider>
                          <b-form-checkbox v-model="isHashTournament">Показывать только по ссылке</b-form-checkbox>
                        </ValidationProvider>
                      </div>
                      <div class="mt-3">
                        <ValidationProvider>
                          <b-form-checkbox v-model="withComments">Включить чат флудилку</b-form-checkbox>
                        </ValidationProvider>
                      </div>
                      <b-tabs class="mt-3 d-flex justify-content-md-start justify-content-center flex-wrap">
                        <b-tab v-for="lang in languages"
                               :title="lang.language"
                               :key="'lang-' + lang.id"
                               @click="getLanguageFromBtn(lang.language.toLowerCase())"
                               nav-class="mr-2 mb-2">
                          <template #title>
                            <div class="d-flex justify-content-center">
                              <img :src="lang.img" width="20" height="20" class="mr-2">
                              <span>{{ lang.language }}</span>
                            </div>
                          </template>
                        </b-tab>
                      </b-tabs>
                      <div class="mt-3">
                        <label class="font-weight-bold star">Название турнира {{ language }}</label>
                          <ValidationProvider name="Название турнира" rules="min:2|required" v-slot="{errors}">
                            <b-form-input v-model="newTournamentName" type="text"
                                          placeholder="Название турнира" :state="!errors.length ? null : false"></b-form-input>
                            <div class="text-danger">{{ errors[0] }}</div>
                          </ValidationProvider>
                      </div>
                      <div style="margin-top: 10px;">
                        <label class="font-weight-bold star">Дисциплина</label>
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <b-form-select :options="games" v-model="gameId"
                                        @change="handleGameChange" :state="!errors.length ? null : false"></b-form-select>
                          <div class="text-danger">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="mt-3">
                        <label class="font-weight-bold star">Тип турнира</label>
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <b-form-select :options="variations" v-model="newVariation"></b-form-select>
                          <div class="text-danger">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div style="margin-top: 10px;">
                        <label class="font-weight-bold star">Организатор турнира</label>
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <b-form-select :options="organizers" v-model="organizerId" :state="!errors.length ? null : false"></b-form-select>
                          <div class="text-danger">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="mt-3">
                        <label class="font-weight-bold star">Призовой фонд</label>
                          <ValidationProvider rules="required" v-slot="{errors}">
                            <b-form-input type="text" v-model="newPrizeFond" :state="!errors.length ? null : false"></b-form-input>
                            <div class="text-danger">{{ errors[0] }}</div>
                          </ValidationProvider>
                      </div>
                      <div>
                        <label class="font-weight-bold star">Кол-во команд</label>
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <b-form-input type="number" v-model="newSlotCount" :state="!errors.length ? null : false"></b-form-input>
                          <div class="text-danger">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="mt-3">
                        <label class="font-weight-bold star">Формат</label>
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <b-form-select :options="formats" v-model="newFormats" :state="!errors.length ? null : false"></b-form-select>
                          <div class="text-danger">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <!--                    <div class="mt-3">-->
                      <!--                      <label class="font-weight-bold">Slug</label>-->
                      <!--                      <b-form-input v-model="newSlug"></b-form-input>-->
                      <!--                    </div>-->

                      <!--                    Был статус раньше, пока выпилили, мб пригодится-->
                      <!--                      <div class="mb-2">-->
                      <!--                        <label class="font-weight-bold"></label>-->
                      <!--                        <ValidationProvider rules="required" v-slot="{errors}">-->
                      <!--                          <b-form-select  v-model="newStatus" :options="newStatusOptions"></b-form-select>-->
                      <!--                          <div class="text-danger">{{errors[0]}}</div>-->
                      <!--                        </ValidationProvider>-->
                      <!--                      </div>-->
                      <div class="mb-2 mt-3">
                        <label class="font-weight-bold">Выбор страны</label>
                        <b-form-select v-model="newCountry" :options="countries"></b-form-select>
                      </div>
                      <div class="mt-3">
                        <label class="font-weight-bold star">Подача для Tier</label>
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <Multiselect :options="divisions" v-model="selectedDivision" label="text" track-by="value"
                                      :multiple="true" :hide-selected="true" :state="!errors.length ? null : false"></Multiselect>
                          <div class="text-danger">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="mt-3">
                        <label class="font-weight-bold">Ссылка трансляции</label>
                        <b-form-input type="text" v-model="newYoutubeLink"></b-form-input>
                      </div>
                      <div class="mt-3">
                        <label class="font-weight-bold">Серия турниров</label>
                        <b-form-input type="text" v-model="selectedTournamentSeries"></b-form-input>
                      </div>
                      <div class="mt-3">
                        <label class="font-weight-bold">Feature</label>
                        <Multiselect :options="featuresList" v-model="selectedFeature" label="text" track-by="value"
                                     :multiple="true" :hide-selected="true"></Multiselect>
                      </div>
                    </b-form>
                  </b-tab>

                  <b-tab title="Выбор времени">
                    <template #title>
                      <span class="fa fa-globe mr-2"></span>
                      <span>Выбор времени</span>
                    </template>
                    <b-form>
                      <div class="custom-control custom-checkbox pl-0">
                        <div class="mt-5">
                          <label for="" class="font-weight-bold">Часовой пояс <span class="text-danger">*</span></label>
                          <ValidationProvider rules="required" v-slot="{errors}">
                            <b-form-select format="YYYY-MM-DD H:i" :options="timezones"
                                           v-model="newTimezone" :state="!errors.length ? null : false"></b-form-select>
                            <div class="text-danger">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>
                        <div>
                          <label for="" class="font-weight-bold">Начало регистрации <span class="text-danger">*</span></label>
                          <ValidationProvider rules="required" v-slot="{errors}">
                            <DatePicker mode="dateTime" v-model='newStartReg' is24hr :model-config="modelConfig"
                                        :popover="{visibility: 'click'}">
                              <template v-slot="{inputValue, inputEvents}">
                                <b-form-input :value="inputValue" v-on="inputEvents" :state="!errors.length ? null : false"/>
                              </template>
                            </DatePicker>
                            <div class="text-danger">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>
                        <div>
                          <label for="" class="font-weight-bold">Завершение регистрации <span class="text-danger">*</span></label>
                          <ValidationProvider rules="required" v-slot="{errors}">
                            <DatePicker mode="dateTime" v-model='newEndReg' is24hr :model-config="modelConfig"
                                        :popover="{visibility: 'click'}">
                              <template v-slot="{inputValue, inputEvents}">
                                <b-form-input :value="inputValue" v-on="inputEvents" :state="!errors.length ? null : false"/>
                              </template>
                            </DatePicker>
                            <div class="text-danger">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>
                        <div>
                          <label for="" class="font-weight-bold">Дата начала турнира <span class="text-danger">*</span></label>
                          <ValidationProvider rules="required" v-slot="{errors}">
                            <DatePicker mode="dateTime" v-model="newStartTournament" is24hr :model-config="modelConfig"
                                        :popover="{visibility: 'click'}">
                              <template v-slot="{inputValue, inputEvents}">
                                <b-form-input :value="inputValue" v-on="inputEvents" :state="!errors.length ? null : false"/>
                              </template>
                            </DatePicker>
                            <div class="text-danger">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </b-form>
                  </b-tab>

                  <b-tab title="Описание">
                    <template #title>
                      <span class="fa fa-list-alt mr-2"></span>
                      <span>Описание</span>
                    </template>
                    <b-tabs class="mt-3 d-flex justify-content-md-start justify-content-center flex-wrap">
                      <b-tab v-for="lang in languages"
                             :title="lang.language"
                             :key="'lang-' + lang.id"
                             @click="getLanguageFromBtn(lang.language.toLowerCase())"
                             nav-class="mr-2 mb-2">
                        <template #title>
                          <div class="d-flex justify-content-center">
                            <img :src="lang.img" width="20" height="20" class="mr-2">
                            <span>{{ lang.language }}</span>
                          </div>
                        </template>
                      </b-tab>
                    </b-tabs>
                    <div class="mt-5">
                      <label for="" class="font-weight-bold star">Расписание {{ language.toUpperCase() }}</label>
                      <template v-for="lang in Object.keys(newSchedule)">
                        <ValidationProvider v-if="lang === 'ru'" rules="required" v-slot="{errors}">
                          <SunEditor :key="lang + '-schedule'"
                                     v-if="language === lang"
                                     v-model="newSchedule[lang]"
                                     :editorId="'schedule'"
                          />
                          <div class="text-danger">{{ errors[0] }}</div>
                        </ValidationProvider>
                        <div v-else>
                          <SunEditor :key="lang + '-schedule'"
                                     v-if="language === lang"
                                     v-model="newSchedule[lang]"
                                     :editorId="'schedule'"
                          />
                        </div>
                      </template>
                    </div>
                    <div class="mt-2">
                      <label class="font-weight-bold star">Описание {{ language.toUpperCase() }}</label>
                      <template v-for="lang in Object.keys(newDescription)">
                        <ValidationProvider v-if="lang === 'ru'" rules="required" v-slot="{errors}">
                        <SunEditor :key="lang + '-description'"
                                   v-if="language === lang"
                                   v-model="newDescription[lang]"
                                   :editorId="'description'"
                        />
                        <div class="text-danger">{{ errors[0] }}</div>
                        </ValidationProvider>
                        <div v-else>
                          <SunEditor :key="lang + '-description'"
                                     v-if="language === lang"
                                     v-model="newDescription[lang]"
                                     :editorId="'description'"
                          />
                        </div>
                      </template>
                    </div>
                    <div class="mt-2">
                      <label class="font-weight-bold star">Правила {{ language.toUpperCase() }}</label>
                      <template v-for="lang in Object.keys(newRules)">
                        <ValidationProvider v-if="lang === 'ru'" rules="required" v-slot="{errors}">
                        <SunEditor :key="lang + '-rules'"
                                   v-if="language === lang"
                                   v-model="newRules[lang]"
                                   :editorId="'rules'"
                        />
                          <div class="text-danger">{{ errors[0] }}</div>
                        </ValidationProvider>
                        <div v-else>
                          <SunEditor :key="lang + '-rules'"
                                     v-if="language === lang"
                                     v-model="newRules[lang]"
                                     :editorId="'rules'"
                          />
                        </div>
                      </template>
                    </div>

                    <div class="mt-2 font-weight-bold">
                      <label>Выбрать обложку</label>
                      <b-form-file v-model="newImage" @change="showImage($event)" class="mb-3"></b-form-file>
                      <img id="outputCreate" class="w-100">
                    </div>
                  </b-tab>
                  <b-tab title="Place pts">
                    <template #title>
                      <span class="fa fa-database mr-2"></span>
                      <span>Очки за place</span>
                    </template>
                    <AddTournamentPlace></AddTournamentPlace>
                  </b-tab>
                  <b-tab title="Place pts">
                      <template #title>
                        <span class="fa fa-database mr-2"></span>
                        <span>Призы за place</span>
                      </template>
                      <AddTournamentPrize />
                    </b-tab>
                  <b-tab title="Мета данные">
                    <template #title>
                      <span class="fa fa-database mr-2"></span>
                      <span>Мета данные</span>
                    </template>
                    <div>
                      <b-tabs class="mt-3 d-flex justify-content-md-start justify-content-center flex-wrap">
                        <b-tab v-for="lang in languages"
                               :title="lang.language"
                               :key="'lang-' + lang.id"
                               @click="getLanguageFromBtn(lang.language.toLowerCase())"
                               nav-class="mr-2 mb-2">
                          <template #title>
                            <div class="d-flex justify-content-center">
                              <img :src="lang.img" width="20" height="20" class="mr-2">
                              <span>{{ lang.language }}</span>
                            </div>
                          </template>
                        </b-tab>
                      </b-tabs>

                      <div class="mt-5">
                        <div>
                          <label class="font-weight-bold">Мета заголовок {{ language }}</label>
                          <b-form-input v-model="newMetaTitle[language]" placeholder="Название"></b-form-input>
                        </div>
                        <div class="mt-2">
                          <label class="font-weight-bold">Мета описание {{ language }}</label>
                          <b-textarea v-model="newMetaDescription[language]" class="h-30"></b-textarea>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                  </ValidationObserver>
                </b-tabs>

                <div class="mt-3 justify-content-center align-items-center">
                  <button @click="createTournament()" type="button" class="btn btn-success">Сохранить</button>
                  <div>{{ errorMessage }}</div>
                </div>
              </b-card>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import {TournamentAdapterMixin} from "../../mixins/Tournament/TournamentAdapterMixin";
import {LanguageAdapterMixin} from "../../mixins/Language/LanguageAdapterMixin";
import Multiselect from "vue-multiselect";
import {FeaturesAdapterMixin} from "@/mixins/Features/FeaturesAdapterMixin";
import {DivisionsAdapterMixin} from "@/mixins/Divisions/DivisionsAdapterMixin";
import AddTournamentPlace from "@/pages/Tournament/AddTournamentPlace.vue";
import AddTournamentPrize from './AddTournamentPrize.vue';
import SunEditor from "@/components/SunEditor.vue";
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'CreateTournament',
  mixins: [TournamentAdapterMixin, LanguageAdapterMixin, FeaturesAdapterMixin, DivisionsAdapterMixin],
  components: {SunEditor, DatePicker, Multiselect, AddTournamentPlace, AddTournamentPrize, ValidationObserver, ValidationProvider},
  data() {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
      striped: true,
      bordered: true,
      invalid: null,
      selectedDivision: [],
    }
  },
  mounted() {
    this.grabGames();
    this.grabOrganizers();
    this.indexTimeZones()
    this.getCountriesQuery();
    this.grabFeaturesList();
    this.grabVariations();
  },
  methods: {
    showImage(event) {
      let output = document.getElementById('outputCreate')
      output.src = URL.createObjectURL(event.target.files[0])
      output.onload = function () {
        URL.revokeObjectURL(output.src)
      }
    },
    handleGameChange() {
      // Сброс значений в поле Multiselect при смене игры
      this.selectedDivision = [];
      // Вызов метода для получения форматов по ID игры
      this.getFormatsByGameId();
    },
  },

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
@import "~@/assets/scss/star.scss";
</style>