<template>
  <div>
    <div>
      <div class="d-flex  mb-5">
        <div>
          <label>Search</label>
          <b-form @submit.prevent="filterTeams()">
            <b-input type="search" class="w-100" v-model="dataForFilter"/>
            <div class="mt-4">
              <b-form-checkbox-group
                  v-model="filteredArray"
                  :options="filters"
              >
              </b-form-checkbox-group>
            </div>
            <b-btn variant="primary"
                   @click.prevent="filterTeams()"
                   class="mt-2 ml-2 row justify-content-center justify-content-md-start">Поиск
            </b-btn>
          </b-form>
        </div>
      </div>
      <div class="d-flex mb-3">
        <div>
          <b-btn variant="success" v-b-toggle="'add_team_in_next_stage'" class="mr-3">
            <i class="fa fa-users"></i>
            Перенести в следующий этап
          </b-btn>
          <b-collapse :id="'add_team_in_next_stage'">
            <div class="p-0 mt-3 mr-2">
              <div class="align-items-center">
                <div>
                  Выбрать стадию
                </div>
                <b-select
                    @change="filterGroupsForNextStageTransfer"
                    v-model="selectedNextStage"
                >
                  <option
                      value="null"
                  >
                    не выбрано
                  </option>

                  <option
                      v-for="stage in tournamentStages"
                      :key="'stage_select_next_' + stage.id"
                      :value="stage.id"
                  >
                    {{ stage.name }}
                  </option>
                </b-select>
              </div>
              <div
                  v-if="groupsForNextStage.length > 0"
                  class="mt-2 align-items-center"
              >
                <div>
                  Выбрать группу
                </div>
                <b-select
                    v-model="selectedNextGroup"
                >
                  <option
                      v-for="group in groupsForNextStage"
                      :key="'group_select_next_' + group.id"
                      :value="group.id"
                  >
                    {{ group.name }}
                  </option>
                </b-select>
              </div>
              <div>
                <button
                    class="mt-2 btn btn-primary w-100"
                    @click="transferTeamToNextStageWithMessage"
                >
                  Перенести выбранные команды
                </button>
              </div>

            </div>
          </b-collapse>
        </div>
        <div>
          <b-dropdown
              no-caret
              class="my-class"
              style="position: static"
              @hide="resetCheckbox()"
              variant="danger"
              checkbox-menu
              allow-focus
              dropright>
            <template #button-content>
              <div>Удалить из этапа</div>
            </template>
            <b-dropdown-form
                v-for="reason in reasonsForDelete"
                :key="'reasonsForDelete-' + reason.id"
                :value="reason.label"
            >
              <b-form-checkbox
                  :value="reason.label"
                  v-model="selectedReasonText">
                {{reason.label}}
              </b-form-checkbox>
            </b-dropdown-form>
            <div class="d-flex justify-content-center">
              <b-btn :disabled="selectedReasonText.length <= 0" @click="removeTeamInTournamentStage({
              team_id:selectedStandingTournamentTeams[0],
              reject_description: selectedReasonText,
              stage_id: currentStage.id
              })">
                Ok
              </b-btn>
            </div>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div>
      <b-table
          responsive
          show-empty
          stacked="md"
          :striped="striped"
          :bordered="bordered"
          :fields="fields"
          :items="teams"
          :current-page=0
          :per-page=10
          class="mb-5">
        <template #cell(team_name)="data">
          {{ data.item.name }}
        </template>
        <template #cell(team_cap)="data">
          {{ data.item.teamOwner.username }}
        </template>
        <template #cell(checkbox)="data">
          <b-form-checkbox
              class="d-flex justify-content-center"
              :value="data.item.id"
              v-model="selectedStandingTournamentTeams">
          </b-form-checkbox>
        </template>
      </b-table>
    </div>
    <b-col md="6" class="p-0">
      <b-pagination
          @change="handlePageChange"
          v-model="currentPage"
          :total-rows="total"
          :per-page="10"
          class="my-0"
      ></b-pagination>
    </b-col>
  </div>
</template>

<script>
import {TeamsAdapterMixin} from "@/mixins/Teams/TeamsAdapterMixin";
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {TournamentStandingsAdapterMixin} from "@/mixins/Tournament/TournamentStandingsAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";

export default {
  name: "FromStageStandingTournament",
  mixins: [TeamsAdapterMixin, ModalAdapterMixin, TournamentStandingsAdapterMixin, PaginationHelperMixin],

  data() {
    return {
      filteredArray: [],
      dataForFilter: '',
      selectedTeams: [],
      pageKey: 'pageNumberTeamListStage',
      fields: [
        {key: 'id', label: 'id', sortable: false,},
        {key: 'team_name', label: 'Название команды', sortable: true,},
        {key: 'team_cap', label: 'Капитан команды', sortable: true,},
        {key: 'checkbox', label: 'Выбрать команду', sortable: false,},
      ],
      filters: {
        name: {text: "Найти по названию команды", value: "name"},
      },
      striped: true,
      bordered: true
    }
  },
  created() {
    this.grabStandingTournamentWithId(this.$route.params.standingId);
    this.recordPageToStorage(this.currentPage, this.pageKey)
    this.indexTeams({
      pageNumber: this.getCurrentPageNumber(this.pageKey),
    })
  },
  methods: {
    handlePageChange(pageNumber) {
      console.log(this.$route.params);
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.indexTeams({
        pageNumber: pageNumber,
        stageId: this.$route.params.stage_id,
        tournament_id:this.$route.params.standingId
      })
    },
    filterTeams() {
      let filteredData = {};
      this.filteredArray.forEach((item) => {
        filteredData[item] = this.dataForFilter;
      })
      filteredData['tournament_id'] = this.$route.params.standingId
      this.indexTeams(filteredData);
    }
  },
}
</script>

<style scoped>

</style>