<template>
  <div class="edit-fake-user-wrapper">
    <div v-if="isLoadingGames" class="loading-wrapper">
      <b-spinner class="spinner"></b-spinner>
    </div>
    <b-form v-else @submit="submitForm">
      <b-form-group label="Название" label-for="name">
        <b-form-input v-model="formModel.name" id="name" type="text" placeholder="Введите название команды" required/>
      </b-form-group>
      <b-form-group label="Дисциплина" label-for="game">
        <b-form-select :options="gamesForSelect" v-model="formModel.game_id"
                       placeholder="Выберите дисциплину"></b-form-select>
      </b-form-group>
      <b-form-group label="Количество фейковых пользователей" label-for="members">
        <b-form-input v-model="formModel.member_count" id="members" type="number" required/>
      </b-form-group>
      <b-form-group label="Лого" label-for="logo">
        <b-form-file id="select-image"
                     ref="default"
                     placeholder="Кликните, чтобы выбрать изображение"
                     @change="loadLogoForTeam"
                     browse-text="Выбрать"
        />
      </b-form-group>
      <div class="d-flex justify-content-center" v-if="uploadedImage(formModel?.logo?.['original_url'])">
        <img :src="uploadedImage(formModel?.logo?.['original_url'])"
             class="render-image"
             alt=""
        />
      </div>
      <b-alert v-if="errorText" show variant="danger">{{ errorText }}</b-alert>
      <div class="form-footer">
        <b-button :disabled="isLoadingSaveForm" type="submit" variant="primary">
          {{ isLoadingSaveForm ? 'Сохраняем...' : 'Сохранить' }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import api from "@/api/api";
import {mapGetters} from "vuex";
import {uploadedImage} from "@/common/imageUtils";
export default {
  name: "CreateFakeTeam",
  data() {
    return {
      uploadedImage,
      isLoadingSaveForm: false,
      errorText: '',
      formModel: {
        name: '',
        game_id: 0,
        member_count: 0,
        logo: {original_url: ''}
      },
      isLoadingGames: false
    }
  },
  created() {
    (async () => {
      this.isLoadingGames = true
      await this.$store.dispatch('moduleFakeTeams/getGames')
      this.isLoadingGames = false
    })()
  },
  computed: {
    ...mapGetters('moduleFakeTeams', ['gamesForSelect']),
  },
  methods: {
    async submitForm(event) {
      try {
        event.preventDefault()
        this.isLoadingSaveForm = true
        this.errorText = null

        // set formModel data for create team
        const formData = new FormData()
        formData.append("name", this.formModel.name)
        formData.append("game_id", this.formModel.game_id)
        formData.append("member_count", this.formModel.member_count)
        const imageFile = this.$refs.default.$el.children[0].files[0]
        if (imageFile) formData.append('logo', imageFile)

        await api.fakeTeams.store(formData)

        // update fake teams list
        await this.$store.dispatch('moduleFakeTeams/getFakeTeams', {
          page: localStorage.getItem('pageNumberFakeTeams'),
        })

        await this.$emit('closeModal')
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSaveForm = false
      }
    },
    loadLogoForTeam(event) {
      this.formModel.logo.original_url = event.target.files[0]
    }
  },
}
</script>

<style scoped lang="scss">
.edit-fake-user-wrapper {
  .form-group {
    width: 100%;
  }

  .form-footer {
    border-top: 1px solid #dee2e6;
    text-align: right;
    padding-top: 15px;
  }
}

.render-image {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  object-fit: cover;
}
</style>
