import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const MailingAdapterMixin = {
    data() {
        return {
            typeMessage: null,
            tournamentType: null,
            tournaments: [],
            nameMailing: '',
            exceptTournaments: null,
            roleType: [],
            division: [],
            country: [],
            mailingView: [],
            mailingImage: null,
            newRoles: null,
            textMailing: '',
            roleTypesList: [
                {value: 1, text: 'Капитан'},
                {value: 2, text: 'Игроки'},
                {value: 3, text: 'Менеджер'},
            ],
            viaList: [
                {value: 2, text: 'telegram'},
                // {value: 3, text: 'discord'},
                // {value: 4, text: 'email'},
            ],
            titleTelegramMailing: '',
            textTelegramMailing: '',
            telegramMailingImage: null,
            showModal: false,
            isLoaded: false,
            filters: {
                tournaments: [],
                roleTypes: [],
                divisions: [],
                countries: [],
                withoutTeam:[],
            },
        }
    },
    computed: {
        ...mapGetters({
            getMailing: 'getMailing',
            getMailingPaginationTotal: 'getMailingPaginationTotal',
            getMailingPaginationCurrentPage: 'getMailingPaginationCurrentPage',
            getMailingCountries: 'getMailingCountries',
            getMailingDivisions: 'getMailingDivisions',
            getMailingTournaments: 'getMailingTournaments',
            getMessageTypes: 'getMessageTypes',
            getTournamentTypes: 'getTournamentTypes',
            getMailingRoles: 'getMailingRoles',
            getMailingResult: 'getMailingResult',
            getTelegramMailing: 'getTelegramMailing',
            getTelegramMailingPaginationTotal: 'getTelegramMailingPaginationTotal',
            getTelegramMailingPaginationCurrentPage: 'getTelegramMailingPaginationCurrentPage',
        }),
        mailingList: {
            get() {
                return this.getMailing
            }, set(value) {
                this.setMailing(value)
            }
        },
        totalPagination: {
            get() {
                return this.getMailingPaginationTotal
            }, set(value) {
                this.setMailingPaginationTotal(value)
            }
        },
        currentPage: {
            get() {
                return this.getMailingPaginationCurrentPage
            }, set(value) {
                this.setMailingPaginationCurrentPage(value)
            }
        },
        mailingCountries: {
            get() {
                return this.getMailingCountries
            }, set(value) {
                this.setMailingCountries(value)
            }
        },
        mailingDivisions: {
            get() {
                return this.getMailingDivisions
            }, set(value) {
                this.setMailingDivisions(value)
            }
        },
        mailingTournaments: {
            get() {
                return this.getMailingTournaments
            }, set(value) {
                this.setMailingTournaments(value)
            }
        },
        messageTypes: {
            get() {
                return this.getMessageTypes
            }, set(value) {
                this.setMessageTypes(value)
            }
        },
        tournamentTypes: {
            get() {
                return this.getTournamentTypes
            }, set(value) {
                this.setTournamentTypes(value)
            }
        },
        mailingRoles: {
            get() {
                return this.getMailingRoles
            }, set(value) {
                this.setMailingRoles(value)
            }
        },
        totalMailingResult: {
            get() {
                return this.getMailingResult
            }, set(value) {
                this.setMailingResult(value)
            }
        },
        telegramMailingList: {
            get() {
                return this.getTelegramMailing
            }, set(value) {
                this.setTelegramMailing(value)
            }
        },
        telegramMailingTotalPagination: {
            get() {
                return this.getTelegramMailingPaginationTotal
            }, set(value) {
                this.setTelegramMailingPaginationTotal(value)
            }
        },
        telegramMailingCurrentPage: {
            get() {
                return this.getTelegramMailingPaginationCurrentPage
            }, set(value) {
                this.setTelegramMailingPaginationCurrentPage(value)
            }
        },
    },
    methods: {
        ...mapMutations({
            setMailing: mutypes.SET_MAILING,
            setMailingPaginationTotal: mutypes.SET_MAILING_PAGINATION_TOTAL,
            setMailingPaginationCurrentPage: mutypes.SET_MAILING_PAGINATION_CURRENT_PAGE,
            setMailingCountries: mutypes.SET_MAILING_COUNTRIES,
            setMailingDivisions: mutypes.SET_MAILING_DIVISIONS,
            setMailingTournaments: mutypes.SET_MAILING_TOURNAMENTS,
            setMessageTypes: mutypes.SET_MAILING_MESSAGE_TYPES,
            setTournamentTypes: mutypes.SET_MAILING_TOURNAMENT_TYPES,
            setMailingRoles: mutypes.SET_MAILING_ROLES,
            setMailingResult: mutypes.SET_MAILING_RESULT,
            setTelegramMailing: mutypes.SET_TELEGRAM_MAILING,
            setTelegramMailingPaginationTotal: mutypes.SET_TELEGRAM_MAILING_PAGINATION_TOTAL,
            setTelegramMailingPaginationCurrentPage: mutypes.SET_TELEGRAM_MAILING_PAGINATION_CURRENT_PAGE,
        }),
        ...mapActions([
            'grabMailingList',
            'storeMailing',
            'getCountries',
            'getDivisions',
            'getTournamentsList',
            'getMessageTypesList',
            'getTournamentTypesList',
            'getRoles',
            'filteredMailingFunc',
            'grabTelegramMailingList',
            'storeTelegramMailing'
        ]),


        async createMailing() {
            let view = []
            this.mailingView.forEach((item) => {
                view.push(item.text)
            })

            const formData = new FormData()
            formData.append("body", this.textMailing)

            if (this.mailingImage instanceof File) {
                formData.append("image", this.mailingImage)
            }


            for (const [key, value] of Object.entries(view)) {
                formData.append("via[]", value)
            }
            formData.append("text", this.textMailing)
            formData.append("title", this.nameMailing)
            formData.append("tournament_type", this.tournamentType)
            formData.append("message_type", this.typeMessage)


            const allFilters = Object.keys(this.filters).reduce((acc, key) => {
                acc[key] = this.filters[key].map(item => item.value);
                return acc;
            }, {});


            formData.append("data", JSON.stringify({
                filters: allFilters
            }));


            await this.storeMailing(formData).then(() => this.$router.push({name: 'telegram_newsletters'})).catch((err) => {
                alert(err)
            })
        },
        async useFilter() {
            let countries = []
            if (this.country) {

                this.country.forEach((item) => {
                    countries.push(item.value)
                })
            }
            let roleId = []
            if (this.roleType) {

                this.roleType.forEach((item) => {
                    roleId.push(item.value)
                })
            }
            let divisions = []
            if (this.division) {

                this.division.forEach((item) => {
                    divisions.push(item.value)
                })
            }
            let tournaments = []
            if (this.tournaments) {

                this.tournaments.forEach((item) => {
                    tournaments.push(item.value)
                })
            }

            let exceptTournaments = []
            if (this.exceptTournaments) {

                this.exceptTournaments.forEach((item) => {
                    exceptTournaments.push(item.value)
                })
            }
            let view = []
            if (this.mailingView) {
                this.mailingView.forEach((item) => {
                    view.push(item.text)
                })
            }

            const object = {
                filter: {
                    countries: countries,
                    roles: roleId,
                    divisions: divisions,
                    tournament_ids: tournaments,
                    tournament_except_ids: exceptTournaments
                },
                via: view
            }

            for (const [key, value] of Object.entries(object.filter)) {
                if (value.length <= 0) {
                    delete object.filter[key]
                }
            }

            await this.filteredMailingFunc(object)
        },
    }
}