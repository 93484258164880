import * as mutypes from "@/store/mutation.types";
import api from "@/api/api";

export default {
    state: () => {
        return {
            bannedUsers: [],
            pagination: {
                by: 10,
                currentPage: 1,
                totalPages: 1,
            },
            banGamesByUser: null,
            banGameIdByUser: null,
            userBansInfo: []
        }
    },
    mutations: {
        [mutypes.SET_USER_BAN]: (state, value) => state.bannedUsers = value,
        [mutypes.SET_GAMES]: (state, value) => state.banGamesByUser = value,
        [mutypes.SET_GAME_ID]: (state, value) => state.banGameIdByUser = value,
        [mutypes.SET_BAN_USERS_PAGINATION_TOTAL]: (state, value) => state.pagination.totalPages = value,
        [mutypes.SET_BAN_USERS_PAGINATION_CURRENT_PAGE]: (state, value) => state.pagination.currentPage = value,
        [mutypes.SET_USER_SHOW_BANS]: (state, value) => state.userBansInfo = value,
    },
    getters: {
        getUsersBan: state => state.bannedUsers,
        getBanGamesByUser: state => state.banGamesByUser,
        getBanGameIdByUser: state => state.banGameIdByUser,
        getBanUsersPaginationTotal: state => state.pagination.totalPages,
        getBanUsersPaginationCurrentPage: state => state.pagination.currentPage,
        getUsersBansInfo: state => state.userBansInfo,
    },
    actions: {
        async indexUsersBan({commit}, params) {
            let response = await api.userBan.index({
                page: params.pageNumber,
                "search": params.search,
            })
            commit(mutypes.SET_USER_BAN, response.data.users)
            commit(mutypes.SET_BAN_USERS_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_BAN_USERS_PAGINATION_CURRENT_PAGE, params.pageNumber);
        },
        async showUsersBan({commit}, id) {
            let response = await api.userBan.show(id)
            commit(mutypes.SET_USER_SHOW_BANS, response.data.user.bans || [])
        },
        grabUserGames: async ({commit}) => {
            await api.games.index().then(res => {
                let values = []
                res.data.games.forEach((item) =>
                    values.push({
                        value: item.id,
                        text: item.name
                    })
                )
                commit(mutypes.SET_GAMES, values);
            }).catch();
        },
        deleteUserBan: async ({dispatch}, params) => {
            api.userBan.delete(params.userId).then(() => {
                dispatch('indexUsersBan', {pageNumber: localStorage.getItem('pageNumberBanUsers')});
                dispatch('grabUsers', {pageNumber: localStorage.getItem('pageNumberUsersList')});
            })
        },
    }
}