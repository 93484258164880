<template>
  <div>
    <Breadcrumbs main="Рекламные турниры" title="Создать рекламный турнир"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Создание рекламного турнира</h5>
            </div>
            <div class="card-body">
              <b-card>
                <b-tabs>
                  <b-tab title="Основные" active>
                    <template #title>
                      <span class="fa fa-home mr-2"></span>
                      <span>Основные</span>
                    </template>
                    <b-form>
                      <b-tabs class="mt-3">
                        <b-tab title="RU" @click="getLanguageFromBtn('RU')">
                          <template #title>
                            <div class="d-flex justify-content-center">
                              <img src="@/assets/images/ru.svg" width="20" height="20"
                                   class="mr-2">
                              <span>RU</span>
                            </div>
                          </template>
                        </b-tab>
                        <b-tab title="EN" @click="getLanguageFromBtn('EN')">
                          <template #title>
                            <div class="d-flex justify-content-center">
                              <img src="@/assets/images/usa.svg" width="20" height="20"
                                   class="mr-2">
                              <span>EN</span>
                            </div>
                          </template>
                        </b-tab>
                        <b-tab title="UZ" @click="getLanguageFromBtn('UZ')">
                          <template #title>
                            <div class="d-flex justify-content-center">
                              <img src="@/assets/images/uz.svg" width="20" height="20"
                                   class="mr-2">
                              <span>UZ</span>
                            </div>
                          </template>
                        </b-tab>
                        <b-tab title="KZ" @click="getLanguageFromBtn('kz')">
                          <template #title>
                            <div class="d-flex justify-content-center">
                              <img src="@/assets/images/kz.png" width="20" height="20"
                                   class="mr-2">
                              <span>KZ</span>
                            </div>
                          </template>
                        </b-tab>
                      </b-tabs>

                      <div class="mt-3">
                        <label class="font-weight-bold">Название турнира {{ language.toUpperCase() }}</label>
                        <ValidationProvider name="Название турнира" rules="min:2|required"
                                            v-slot="{errors}">
                          <b-form-input v-model="newTournamentName" type="text"
                                        placeholder="Название турнира"></b-form-input>
                          <div class="text-danger">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div style="margin-top: 10px;">
                        <span class="font-weight-bold">Тайтл турнира</span>
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <b-form-select :options="games" v-model="gameId"></b-form-select>
                          <div class="text-danger">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="mt-3">
                        <label class="font-weight-bold">Количество команд</label>
                        <ValidationProvider name="Количество команд" rules="required"
                                            v-slot="{errors}">
                          <b-form-input type="number" v-model="newSlotCount"
                                        placeholder="Количество команд"></b-form-input>
                          <div class="text-danger">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="mt-3">
                        <label class="font-weight-bold">Формат</label>
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <Multiselect :options="formats" v-model="newFormats" label="text" track-by="value"
                                       :multiple="true"></Multiselect>
                          <div class="text-danger">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="mt-3">
                        <label class="font-weight-bold">Prize fond</label>
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <b-form-input v-model="newPrizeFond" type="text" placeholder="Prize fond"></b-form-input>
                          <div class="text-danger">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div style="margin-top: 10px;">
                        <span class="font-weight-bold">Организатор турнира</span>
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <b-form-select :options="organizersOuter"
                                         v-model="organizerId"></b-form-select>
                          <div class="text-danger">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                    </b-form>
                  </b-tab>
                  <b-tab title="Выбор времени">
                    <template #title>
                      <span class="fa fa-globe mr-2"></span>
                      <span>Выбор времени</span>
                    </template>
                    <b-form>
                      <div class="custom-control custom-checkbox  pl-0">
                        <div class="mt-5">
                          <label for="" class="font-weight-bold">Часовой пояс</label>
                          <ValidationProvider rules="required" v-slot="{errors}">
                            <b-form-select format="YYYY-MM-DD H:i" :options="timezones"
                                           v-model="newTimezone"></b-form-select>
                            <div class="text-danger">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>

                        <div>
                          <label for="" class="font-weight-bold">Начало регистрации</label>
                          <ValidationProvider rules="required" v-slot="{errors}">
                            <DatePicker mode="dateTime" v-model='newStartReg' is24hr
                                        :model-config="modelConfig"
                                        :popover="{visibility: 'click'}">
                              <template v-slot="{inputValue, inputEvents}">
                                <b-form-input :value="inputValue" v-on="inputEvents" placeholder="дд.мм.гггг"/>
                              </template>
                            </DatePicker>
                            <div class="text-danger">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>
                        <div>
                          <label for="" class="font-weight-bold">Завершение
                            регистрации</label>
                          <ValidationProvider rules="required" v-slot="{errors}">
                            <DatePicker mode="dateTime" v-model='newEndReg' is24hr
                                        :model-config="modelConfig"
                                        :popover="{visibility: 'click'}">
                              <template v-slot="{inputValue, inputEvents}">
                                <b-form-input :value="inputValue" v-on="inputEvents" placeholder="дд.мм.гггг"/>
                              </template>
                            </DatePicker>
                            <div class="text-danger">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>
                        <div>
                          <label for="" class="font-weight-bold">Дата начала турнира</label>
                          <ValidationProvider rules="required" v-slot="{errors}">
                            <DatePicker mode="dateTime" v-model="newStartTournament" is24hr
                                        :model-config="modelConfig"
                                        :popover="{visibility: 'click'}">
                              <template v-slot="{inputValue, inputEvents}">
                                <b-form-input :value="inputValue" v-on="inputEvents" placeholder="дд.мм.гггг"/>
                              </template>
                            </DatePicker>
                            <div class="text-danger">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </b-form>
                  </b-tab>


                  <b-tab title="Описание">
                    <template #title>
                      <span class="fa fa-list-alt mr-2"></span>
                      <span>Описание</span>
                    </template>

                    <b-tabs class="mt-3">
                      <b-tab title="RU" @click="getLanguageFromBtn('ru')">
                        <template #title>
                          <div class="d-flex justify-content-center">
                            <img src="@/assets/images/ru.svg" width="20" height="20"
                                 class="mr-2">
                            <span>RU</span>
                          </div>
                        </template>
                      </b-tab>
                      <b-tab title="EN" @click="getLanguageFromBtn('en')">
                        <template #title>
                          <div class="d-flex justify-content-center">
                            <img src="@/assets/images/usa.svg" width="20" height="20"
                                 class="mr-2">
                            <span>EN</span>
                          </div>
                        </template>
                      </b-tab>
                      <b-tab title="UZ" @click="getLanguageFromBtn('uz')">
                        <template #title>
                          <div class="d-flex justify-content-center">
                            <img src="@/assets/images/uz.svg" width="20" height="20"
                                 class="mr-2">
                            <span>UZ</span>
                          </div>
                        </template>
                      </b-tab>
                      <b-tab title="KZ" @click="getLanguageFromBtn('kz')">
                        <template #title>
                          <div class="d-flex justify-content-center">
                            <img src="@/assets/images/kz.png" width="20" height="20"
                                 class="mr-2">
                            <span>KZ</span>
                          </div>
                        </template>
                      </b-tab>
                    </b-tabs>

                    <div class="mt-5">
                      <label class="font-weight-bold">Описание {{ language.toUpperCase() }}</label>
                      <vue-editor v-if="language === 'ru'" v-model='newDescription.ru'></vue-editor>
                      <vue-editor v-if="language === 'en'" v-model='newDescription.en'></vue-editor>
                      <vue-editor v-if="language === 'uz'" v-model='newDescription.uz'></vue-editor>
                      <vue-editor v-if="language === 'kz'" v-model='newDescription.kz'></vue-editor>

                      <div class="mt-2 font-weight-bold">
                        <label>Выбрать обложку</label>
                        <b-form-file v-model="newImage" @change="showImage($event)" class="mb-3"></b-form-file>
                        <img id="outputCreateOuter" class="w-100">
                      </div>
                    </div>
                  </b-tab>


                  <b-tab title="Мета данные">
                    <template #title>
                      <span class="fa fa-database mr-2"></span>
                      <span>Мета данные</span>
                    </template>
                    <div>
                      <b-tabs class="mt-3">
                        <b-tab title="RU" @click="getLanguageFromBtn('ru')">
                          <template #title>
                            <div class="d-flex justify-content-center">
                              <img src="@/assets/images/ru.svg" width="20" height="20"
                                   class="mr-2">
                              <span>RU</span>
                            </div>
                          </template>
                        </b-tab>
                        <b-tab title="EN" @click="getLanguageFromBtn('en')">
                          <template #title>
                            <div class="d-flex justify-content-center">
                              <img src="@/assets/images/usa.svg" width="20" height="20"
                                   class="mr-2">
                              <span>EN</span>
                            </div>
                          </template>
                        </b-tab>
                        <b-tab title="UZ" @click="getLanguageFromBtn('uz')">
                          <template #title>
                            <div class="d-flex justify-content-center">
                              <img src="@/assets/images/uz.svg" width="20" height="20"
                                   class="mr-2">
                              <span>UZ</span>
                            </div>
                          </template>
                        </b-tab>
                      </b-tabs>

                      <div class="mt-5">
                        <div>
                          <label class="font-weight-bold">Мета заголовок
                            {{ language.toUpperCase() }}</label>
                          <b-form-input placeholder="Название"></b-form-input>
                        </div>
                        <div class="mt-2">
                          <label class="font-weight-bold">Мета описание {{ language.toUpperCase() }}</label>
                          <b-textarea class="h-30"></b-textarea>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>

                <div class="mt-3 justify-content-center align-items-center">
                  <button @click="createOuterTournament()" type="button" class="btn btn-success">Опубликовать</button>
                  <div>{{ errorMessage }}</div>
                </div>
              </b-card>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import {VueEditor} from "vue2-editor";
import {TournamentOuterAdapterMixin} from "../../mixins/TournamentOuter/TournamentOuterAdapterMixin";
import {LanguageAdapterMixin} from "../../mixins/Language/LanguageAdapterMixin";
import Multiselect from "vue-multiselect";

export default {
  name: 'CreatePromoTournament',
  mixins: [TournamentOuterAdapterMixin, LanguageAdapterMixin],
  components: {DatePicker, VueEditor, Multiselect},
  data() {
    return {
      files: [],
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
    }
  },
  created() {
    Promise.all([
      this.grabGamesOuter(),
      this.grabOrganizersOuter(),
      this.indexFormat({}),
      this.indexTimeZones()
    ])
  },
  methods: {
    showImage(event) {
      let output = document.getElementById('outputCreateOuter')
      output.src = URL.createObjectURL(event.target.files[0])
      output.onload = function (){
        URL.revokeObjectURL(output.src)
      }
    }
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>