import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/v1/admin/exports/top-20-users';

export default {
    /**
     * CREATE: excel
     * @returns {Promise<AxiosResponse<any>>}
     */
    create: () => adminHttp.post(END_POINT),

    /**
     * GET: excel
     * @returns {Promise<AxiosResponse<any>>}
     */
    index: (id, params) => adminHttp.get(END_POINT + `/${id}`, params),
    
    /**
     * GET: tournament statistics excel
     * @returns {Promise<AxiosResponse<any>>}
     */
    getTournamentStatistics: (id, params) => adminHttp.get(`/api/v1/admin/tournament/${id}/stages/statistics/export`, params),
}