<template>
  <div class="team-card-tournament-wrapper" @click="selectedTeamId = team.id">
    <h3>
      <img :src="team.logo?.original_url || TeamImagePug" alt="" @error="event => event.target.src = TeamImagePug" />
      {{ team.name }}
    </h3>
    <div class="information">
      <p v-if="currentStage"><span>Текущий этап: </span> {{ currentStage }}</p>
      <p v-if="currentGroup"><span>Текущая группа: </span> {{ currentGroup }}</p>
    </div>
  </div>
</template>

<script>
import TeamImagePug from '@/assets/images/pugs/team.png'

export default {
  name: "TeamCardTournament",
  props: {
    team: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      TeamImagePug
    }
  },
  computed: {
    selectedTeamId: {
      get() {
        return this.$store.state.moduleNewTournament.selectedTeamId
      },
      set() {
        this.$store.commit('moduleNewTournament/setFirstLevelData', {
          name: 'selectedTeamId',
          value: this.team.id
        })
      }
    },
    currentStage() {
      const stageTeams = this.team?.tournament_team?.stage_teams
      if (!stageTeams || !stageTeams.length) return false
      return stageTeams.at(-1).stage.name
    },
    currentGroup() {
      const stageTeams = this.team?.tournament_team?.stage_teams
      if (!stageTeams || !stageTeams.length || !stageTeams.at(-1).group_team) return false
      return stageTeams.at(-1).group_team.group.name
    }
  }
}
</script>

<style scoped lang="scss">
.team-card-tournament-wrapper {
  background: white;
  padding: 25px;
  border-radius: 10px;
  position: relative;
  transition: .2s;
  //transition: 10s;
  cursor: pointer;
  outline: none;

  &::after {
    content: url('../../../assets/icons/arrow-right.svg');
    width: 14px;
    top: 12px;
    right: 15px;
    position: absolute;
    transition: .2s;
    opacity: 0;
  }

  &:hover {
    border: none;
    outline: 1px solid #d1d1d1;

    &::after {
      opacity: 1;
    }
  }

  h3 {
    font-size: 18px;
    margin: 0;

    display: flex;
    align-items: center;

    img {
      height: 30px;
      width: 30px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;

      margin-right: 10px;
    }
  }

  .information {
    margin-top: 20px;

    p {
      font-size: 12px;
      margin-bottom: 0;

      span {
        font-weight: 500;
      }
    }
  }
}
</style>