export const NAME_CHANGED = 'name-changed';
export const ROLE_ASSIGNED = 'role-assigned';
export const TOURNAMENT_PARTICIPATION_CANCELED = 'tournament-participation-canceled';
export const TOOK_PART_TOURNAMENT = 'took-part-tournament';
export const REMOVED_TEAM_ENTRY = 'removed-team-entry';
export const MEMBER_KICKED = 'member-kicked';
export const MEMBER_ADDED = 'member-added';
export const TOURNAMENT_PARTICIPATION_ACCEPTED = 'tournament-participation-accepted';
export const TOURNAMENT_PARTICIPATION_DELETED = 'tournament-participation-deleted';
export const USER_LEAVE_TEAM = 'user-leave-team';
export const REMOVE_TEAM_IN_GROUP = 'remove-team-in-group';
export const REMOVE_TEAM_IN_STAGE = 'remove-team-in-stage';
export const ADD_TEAM_TO_TOURNAMENT_BY_ADMIN = 'add-team-to-tournament-by-admin';
export const TRANSFERRED_TO_NEW_STAGE = 'transferred-to-new-stage';



export const CODES = {
    [NAME_CHANGED]: `Изменено название команды на`,
    [ROLE_ASSIGNED]: `Изменилась роль у пользователя`,
    [TOURNAMENT_PARTICIPATION_CANCELED]: 'Отозвана заявка на турнир',
    [TOOK_PART_TOURNAMENT]: 'Подана заявка на турнир',
    [REMOVED_TEAM_ENTRY]: 'Отменена заявка на турнир',
    [MEMBER_KICKED]: 'Удален из команды игрок',
    [MEMBER_ADDED]: 'Добавлен в команду игрок',
    [TOURNAMENT_PARTICIPATION_ACCEPTED]: 'Принята заявка на турнир',
    [TOURNAMENT_PARTICIPATION_DELETED]: 'Удалена команда с турнира',
    [USER_LEAVE_TEAM]: 'Игрок покинул команду',
    [REMOVE_TEAM_IN_GROUP]: 'Команда удалена из группы',
    [REMOVE_TEAM_IN_STAGE]: 'Команда удалена из этапа',
    [ADD_TEAM_TO_TOURNAMENT_BY_ADMIN]: 'Команда добавлена на турнир',
    [TRANSFERRED_TO_NEW_STAGE]: 'Команда перенесена в новый этап',
};
