<template>
  <div class="confirm-unban-wow-streamer-wrapper">
    <b-form @submit.prevent="submitForm">
      <h5>Вы действительно хотите разбанить стримера?</h5>
      <b-alert v-if="errorText" show variant="danger" class="mt-3">{{ errorText }}</b-alert>
      <footer>
        <b-button type="submit" :disabled="isLoadingSaveForm" variant="primary" class="m-1">
          {{ isLoadingSaveForm ? 'В процессе...' : 'ОК' }}
        </b-button>
        <b-button @click="$emit('closeModal')" variant="secondary" class="m-1">
          Отменить
        </b-button>
      </footer>
    </b-form>
  </div>
</template>

<script>
import { WowStreamersAdapterMixin } from "@/mixins/WowStreamers/WowStreamersAdapterMixin";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ConfirmUnbanWowStreamer",
  mixins: [WowStreamersAdapterMixin],
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoadingSaveForm: false,
      errorText: null,
    };
  },
  methods: {
    ...mapActions(['unbanWowStreamer', 'fetchBannedWowStreamers']),
    async submitForm() {
      try {
        this.isLoadingSaveForm = true;
        this.errorText = null;
        await this.unbanWowStreamer(this.params.id);
        this.$emit("closeModal");
        this.$emit("unbanSuccess");
        this.$toast.success('Стример разбанен', { position: 'top-right' });
        this.fetchBannedWowStreamers({ pageNumber: this.getCurrentPage });
      } catch (e) {
        this.errorText = e.response.data.message;
        this.$toast.error(`Ошибка при разбане стримера: ${this.errorText}`, { position: 'top-right' });
      } finally {
        this.isLoadingSaveForm = false;
      }
    },
  },
};
</script>

<style scoped>
.confirm-unban-wow-streamer-wrapper {
  footer {
    border-top: 1px solid #dee2e6;
    padding-top: 15px;
    margin-top: 15px;
    text-align: right;
  }
}
</style>
