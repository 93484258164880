import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/user';
const END_POINT_PERMISSIONS = '/api/admin/permission'

export default {
    /**
     * GET: Organizer users
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    // index: (params) => adminHttp.get(END_POINT + `/all/${params.organizerId}`,{params: params}),
    index: (params) => adminHttp.get(END_POINT, {params: params}),
    
    /**
     * GET: Organizer users
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    indexFiltered: (params) => adminHttp.get(END_POINT + `/?filter[email]=${params.email}`, params),

    /**
     * Post: store organizer user
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    store: (params) => adminHttp.post(END_POINT,params),

    /**
     * Delete: delete organizer user
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete: (params) => adminHttp.delete(END_POINT,{params: params}),
    
    /**
     * GET: permissions
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    getPermissions: () => adminHttp.get(END_POINT_PERMISSIONS),
    
    /**
     * GET: Organizer users
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    show: (id) => adminHttp.get(END_POINT + `/${id}`),
    
    /**
     * UPDATE: Organizer users
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateUser: (id, params) => adminHttp.put(END_POINT + `/${id}`, params)
}