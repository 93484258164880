<template>
  <div>
    <Breadcrumbs main="Команды" title="Информация об игроке"></Breadcrumbs>
    <div class="container">
      <b-card>
        <div class="row justify-content-between">
          <div>
            IMAGE
          </div>
          <div>
            <h3>User Id</h3>
            <h3>Nick</h3>
          </div>
        </div>

        <div class="mt-5">
          <h3 class="text-center">Общие</h3>
          <div>
            <table class="table mt-5">
              <th>mail</th>
              <td>exmaple@mail.ru</td>
              <tr>
                <th>Status</th>
                <td>Status example</td>
              </tr>
              <tr>
                <th>Команды</th>
                <td>Пример команды</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="mt-5">
          <h3 class="text-center">Игровой профиль</h3>
          <div>
            <table class="table mt-5">
              <th>Ник</th>
              <td>
                <ValidationProvider rules="min:2" v-slot="{errors}">
                <b-form-input type="text" v-model="newNickName"></b-form-input>
                  <div class="text-danger">{{errors[0]}}</div>
                </ValidationProvider>
              </td>
              <tr>
                <th>Game id</th>
                <td><b-form-input type="number"></b-form-input></td>
              </tr>
              <b-btn variant="danger">Затереть данные</b-btn>
              <tr class="mt-2">
                <th>ФИО</th>
                <td>Тестовый Тест Тестович</td>
              </tr>
              <tr>
                <th>Дата рождения</th>
                <td>Тестовый Тест Тестович</td>
              </tr>
              <tr>
                <th>Город</th>
                <td>Тестовый Тест Тестович</td>
              </tr>
              <tr>
                <th>Телефон</th>
                <td>Тестовый Тест Тестович</td>
              </tr>
              <tr>
                <th>Язык пользователя</th>
                <td>Тестовый Тест Тестович</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="mt-5">
          <h3 class="text-center">Верификация</h3>
          <div>
            <table class="table mt-5">
              <th>Верификация</th>
              <td>вер</td>
              <tr>
                <th>Дата верификации</th>
                <td>example</td>
              </tr>
              <tr>
                <th>Админ верификации</th>
                <td>Пример</td>
              </tr>
              <tr>
                <th>Документы</th>
                <td>Пример</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="mt-5">
          <h3 class="text-center mb-3">Логи</h3>
          <b-table :fields="fields" responsive></b-table>
        </div>

        <div class="mt-5">
          <h3 class="text-center mb-3">IP</h3>
          <b-table :fields="fields2" responsive></b-table>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamPlayerInfo',
  data() {
    return {
      fields: [
        {key: 'id', label: '№',},
        {key: 'sana', label: 'Sana',},
        {key: 'type', label: 'Тип',},
        {key: 'cause', label: 'Причина',},
        {key: 'prev', label: 'Пред',},
        {key: 'new', label: 'Новый',},
        {key: 'who', label: 'Кто',},
      ],
      fields2: [
        {key: 'id', label: '№',},
        {key: 'sana', label: 'Sana',},
        {key: 'type', label: 'Тип',},
      ],
      newNickName: ''
    }
  },
}
</script>