<template>
  <div class="confirm-deactivate-stream-wrapper">
    <b-form @submit.prevent="submitForm">
      <h5>Вы действительно хотите деактивировать стрим?</h5>
      <b-form-group label="Причина деактивации" label-for="endReason" class="mt-3">
        <b-form-textarea
          id="endReason"
          v-model="formModel.endReason"
          placeholder="Укажите причину деактивации"
          required
        ></b-form-textarea>
      </b-form-group>
      <b-alert v-if="errorText" show variant="danger" class="mt-3">{{ errorText }}</b-alert>
      <footer>
        <b-button type="submit" :disabled="isLoadingSaveForm" variant="primary" class="m-1">
          {{ isLoadingSaveForm ? 'В процессе...' : 'ОК' }}
        </b-button>
        <b-button @click="$emit('closeModal')" variant="secondary" class="m-1">
          Отмена
        </b-button>
      </footer>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { WowStreamersAdapterMixin } from "@/mixins/WowStreamers/WowStreamersAdapterMixin";

export default {
  name: "ConfirmDeactivateStream",
  mixins: [WowStreamersAdapterMixin],
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoadingSaveForm: false,
      errorText: null,
      formModel: {
        endReason: '',
      },
    };
  },
  methods: {
    ...mapActions(['deactivateWowStream', 'fetchWowStreamer']),
    async submitForm() {
      if (!this.formModel.endReason) {
        this.errorText = "Пожалуйста, заполните все поля";
        return;
      }

      try {
        this.isLoadingSaveForm = true;
        this.errorText = null;
        await this.deactivateWowStream({
          streamId: this.params.streamId,
          data: { end_reason: this.formModel.endReason },
        });
        this.$emit("closeModal");
        this.$emit("actionSuccess");
        this.$toast.success('Стрим деактивирован', { position: 'top-right', timeout: 2000 });
        this.$router.push("/wowstreamers");
      } catch (e) {
        this.errorText = e.response.data.message;
        this.$toast.error(`Ошибка при деактивации стрима: ${this.errorText}`, { position: 'top-right', timeout: 3000 });
      } finally {
        this.isLoadingSaveForm = false;
      }
    },
  },
};
</script>

<style scoped>
.confirm-deactivate-stream-wrapper {
  footer {
    border-top: 1px solid #dee2e6;
    padding-top: 15px;
    margin-top: 15px;
    text-align: right;
  }
}
</style>
