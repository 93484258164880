import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const OrganizersAdapterMixin = {
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            getOrganizersList                     : 'getOrganizersList',
            getOrganizerShow                     : 'getOrganizerShow',
        }),
        organizers             : {get() {return this.getOrganizersList                 }, set(value) {this.setOrganizersList(value)     }},
        showOrganizer             : {get() {return this.getOrganizerShow                 }, set(value) {this.setOrganizerShow(value)     }},
    },
    methods: {
        ...mapMutations({
            setOrganizersList                  : mutypes.SET_ORGANIZERS,
            setOrganizerShow                  : mutypes.SET_ORGANIZER_SHOW,
        }),
        ...mapActions([
            'grabOrganizersList',
            'showOrganizer',
            'updateOrganizer',
            'storeOrganizer',
            'deleteOrganizer'
        ]),
        async createOrganizer(){
                this.$router.push({name: 'organizer_create'})
        },
    }
}