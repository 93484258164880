<template>
  <div class="edit-stage-tournament-modal-wrapper">
    <b-form @submit="submitForm">

      <!--  Названиие этапа   -->
      <b-form-group label="Название этапа" label-for="name">
        <b-form-input v-model="formModel.name" id="name" type="text" required/>
      </b-form-group>

      <!--   Формат этапа для MLBB   -->
      <b-form-group v-if="gameSlug === 'mlbb'" label="Формат" label-for="format_id">
        <b-form-select v-model="formModel.format_id"
                       id="format_id"
                       :options="stageTypes.map(({ id, title }) => ({ text: title, value: id }))"
                       disabled
                       required />
      </b-form-group>

      <!--   Динамические поля в зависимости от игры и формата   -->
      <div class="grid-mini-inputs" v-if="isShowDynamicFields">
        <b-form-group v-for="({ label, key, elementTag, classList, ...otherProps}) in fieldsByForm"
                      :class="[ classList ]"
                      v-bind="{
                        ...(typeof elementTag === 'string' && { label })
                      }"
                      :label-for="key">
          <component :is="elementTag"
                     :id="key"
                     v-model="formModel[key]"
                     :label="label"
                     v-bind="{
                      ...otherProps
                     }"
          />
        </b-form-group>
      </div>

      <b-alert v-if="errorText" show variant="danger">{{ errorText }}</b-alert>
      <div class="form-footer">
        <b-button :disabled="isLoadingSaveForm" type="submit" variant="primary">
          {{ isLoadingSaveForm ? 'Сохраняем...' : 'Сохранить' }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { stageTypes, fieldsByGamePUBGMobile, fieldsByGameMLBBByStageTypeMap } from "@/common/tournamentCommon";

export default {
  name: "EditStageTournament",
  computed: {
    ...mapGetters('moduleNewTournament', ['gameSlug'])
  },
  props: {
    params: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      stageTypes,
      isLoadingSaveForm: false,
      isShowDynamicFields: false,
      fieldsByForm: [],
      formModel: {
        name: '', // Название этапа
      },
      errorText: null,
    }
  },
  methods: {
    setNewFields() {
      this.isShowDynamicFields = false
      const oldFields = JSON.parse(JSON.stringify(this.fieldsByForm))

      // Вставка новых вычесленных полей
      if (this.gameSlug === 'pubg_mobile') this.$set(this, 'fieldsByForm', fieldsByGamePUBGMobile)
      if (this.gameSlug === 'mlbb') {
        if (this.formModel.format_id) this.$set(this, 'fieldsByForm', fieldsByGameMLBBByStageTypeMap.get(this.formModel.format_id).fields)
        else this.$set(this, 'fieldsByForm', [])
      }

      // Вставка моделей по полям
      oldFields.forEach(({ key }) => this.$delete(this.formModel, key))
      this.fieldsByForm.forEach(({ key, defaultValue }) => this.$set(this.formModel, key, defaultValue))

      this.isShowDynamicFields = true
    },
    async submitForm(event) {
      try {
        event.preventDefault()
        this.isLoadingSaveForm = true
        this.errorText = null
        await this.$store.dispatch('moduleNewTournament/updateStage', {
          data: this.formModel,
          id: this.params.id
        })
        await this.$store.dispatch('moduleNewTournament/getTournament', {
          id: this.$route.params.standingId
        })
        await this.$emit('closeModal')
      } catch (e) {
        const message = e.response.data.message
        let errors = ''
        if (e.response.data.errors) errors = Object.entries(e.response.data.errors).map(([name, values]) => `${name}: ${values.join(', ')}`).join('. ')
        this.errorText = `${message}; ${errors}.`
      } finally {
        this.isLoadingSaveForm = false
      }
    },
    async getDefaultData() {

      // Get Stage
      const stage = await this.$store.dispatch('moduleNewTournament/getStage', {
        id: this.params.id
      })

      // For MLBB
      if (this.gameSlug === 'mlbb') this.$set(this.formModel, 'format_id', stage.format_id)

      // Generate fields
      this.setNewFields()

      this.$nextTick(() => {
        this.$set(this.formModel, 'name', stage.name)

        this.$set(this.formModel, 'stage_divisions', stage['stage_divisions'].map((division) => ({
          division_id: division.division_id,
          division_name: division.division.name.ru,
          slot_count: division.slot_count
        })))

        this.fieldsByForm.forEach(({ key }) => {
          if (key === 'stage_divisions') return
          if (key === 'extra_stage_points') {
            this.$set(this.formModel, key, stage[key].map(({ place, experience_points }) => ({
              place,
              experience_points
            })))
            return
          }
          this.$set(this.formModel, key, stage[key])
        })
      })
    },
  },
  watch: {
    'formModel.format_id'() {
      this.setNewFields()
    }
  },
  mounted() {
    // For MLBB
    if (this.gameSlug === 'mlbb') this.$set(this.formModel, 'format_id', 0)

    this.getDefaultData()
  }
}
</script>

<style scoped lang="scss">
.edit-stage-tournament-modal-wrapper {
  .form-group {
    width: 100%;
  }

  &::v-deep {
    .grid-mini-inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;

      @media screen and (max-width: 800px) {
        display: block;
      }

      & > * {
        align-self: center;
        justify-self: center;
      }

      .full-width {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }

  .form-footer {
    border-top: 1px solid #dee2e6;
    text-align: right;
    padding-top: 15px;
  }
}
</style>