import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"
import Vue from "vue";

export default {
    state: () => {
        return {
            tournament: {
                name: "",
                game: {},
            },
            stages: [],
            groups: [],
            matches: [],
            teams: [],
            selectedStage: null,
            selectedGroup: null,
            selectedGroupName: null,
            searchTeam: '',
            selectedGroupMatchesResult: [],
            stageMembers: [],
            teamStagePlayers: [],
            teamSlots: [],
            selectedTeamIds: [],
            groupsForNextStage: [],
            tournamentStatus: null,
            currentStage: null,
            currentStatus: null,
            prevStageId: null
        }
    },
    mutations: {
        [mutypes.SET_TOURNAMENT_STANDING_NAME]: (state, value) => state.tournament.name = value,
        [mutypes.SET_TOURNAMENT_STANDING_GAME]: (state, value) => state.tournament.game = value,
        [mutypes.SET_TOURNAMENT_STANDING_STAGES]: (state, value) => state.stages = value,
        [mutypes.SET_TOURNAMENT_STANDING_GROUPS]: (state, value) => state.groups = value,
        [mutypes.SET_TOURNAMENT_STANDING_TEAMS]: (state, value) => state.teams = value,
        [mutypes.SET_TOURNAMENT_STANDING_STATUS]: (state, value) => state.tournamentStatus = value,
        [mutypes.APPEND_TOURNAMENT_STANDING_RESULT_KEY]: (state, value) => state.result_keys = value,

        [mutypes.SET_TOURNAMENT_STANDING_SELECTED_STAGE_ID]: (state, value) => state.selectedStage = value,
        [mutypes.SET_TOURNAMENT_STANDING_SELECTED_GROUP_ID]: (state, value) => state.selectedGroup = value,
        [mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP_NAME]: (state, value) => state.selectedGroupName = value,
        [mutypes.SET_TOURNAMENT_SEARCH_TEAM]: (state, value) => state.searchTeam = value,

        [mutypes.SET_TOURNAMENT_STANDING_MATCHES]: (state, value) => state.matches = value,
        [mutypes.SET_TOURNAMENT_STANDING_SELECTED_GROUP_RESULT]: (state, value) => state.selectedGroupMatchesResult = value,
        [mutypes.SET_TOURNAMENT_STANDING_MEMBERS]: (state, value) => state.stageMembers = value,
        [mutypes.SET_TOURNAMENT_STANDING_MEMBERS_TEAM]: (state, value) => state.teamStagePlayers = value,

        [mutypes.SET_TOURNAMENT_STANDING_TEAM_SLOTS]: (state, value) => state.teamSlots = value,

        [mutypes.SELECT_TOURNAMENT_STANDING_TEAM]: (state, value) => state.selectedTeamIds = value,
        [mutypes.SELECT_TOURNAMENT_STANDING_GROUPS_FOR_NEXT_STAGE]: (state, value) => state.groupsForNextStage = value,
        [mutypes.SET_CURRENT_STAGE]: (state, value) => state.currentStage = value,
        [mutypes.SET_TOURNAMENT_STANDING_CURRENT_STATUS]: (state, value) => state.currentStatus = value,
        [mutypes.SET_TOURNAMENT_STANDING_PREV_STAGE_ID]: (state, value) => state.prevStageId = value,

    },

    getters: {
        getStandingsTournament: state => state.tournament,
        getStandingsTournamentName: state => state.tournament.name,
        getStandingsTournamentGame: state => state.tournament.game,
        getStandingsStages: state => state.stages,
        getStandingsGroups: state => state.groups,
        getStandingsTeams: state => state.teams,
        getStandingResultKeys: state => state.result_keys,
        getStandingSelectedStageId: state => state.selectedStage,
        getStandingSelectedGroupId: state => state.selectedGroup,
        getStandingSelectedGroupName: state => state.selectedGroupName,
        getStandingMatches: state => state.matches,
        getSearchTeam: state => state.searchTeam,
        getSelectedGroupMatchesResult: state => state.selectedGroupMatchesResult,
        getStandingTournamentMembers: state => state.stageMembers,
        getStandingTournamentTeamMembers: state => state.teamStagePlayers,
        getStandingTournamentTeamSlots: state => state.teamSlots,
        getStandingTournamentSelectedTeams: state => state.selectedTeamIds,
        getStandingTournamentGroupsForNextStage: state => state.groupsForNextStage,
        getStandingTournamentStatus: state => state.tournamentStatus,
        getCurrentStage: state => state.currentStage,
        getTournamentStandingCurrentStatus: state => state.currentStatus,
        getTournamentStandingPrevStageId: state => state.prevStageId
    },

    actions: {
        grabStandingTournament: async ({commit, getters}, id) => {
            const response = await api.tournament.grabTournamentStandings(id).catch(()=> {
                this._vm.$toast("Не удалось загрузить турнир");
            });
            commit(mutypes.SET_TOURNAMENT_STANDING_NAME, response.data.tournament.name);
            commit(mutypes.SET_TOURNAMENT_STANDING_STAGES, response.data.tournament.stages);
            commit(mutypes.SET_TOURNAMENT_STANDING_CURRENT_STATUS, response.data.tournament.status)
            commit(mutypes.SET_TOURNAMENT_STANDING_STATUS, response.data.tournament.status.name);
            console.log('response.data.tournament.game ', response.data.tournament.game)
            commit(mutypes.SET_TOURNAMENT_STANDING_GAME, response.data.tournament.game);

            const selectedStageId = localStorage.getItem('selectedStageId')
            const selectedGroupId = localStorage.getItem('selectedGroupId')
            if (selectedStageId || selectedGroupId) {
              const stagesIndex =  response.data.tournament.stages.findIndex((el)=> el.id == selectedStageId)
              const groups =  response.data.tournament.stages.map((el)=> {
                 return el.groups
                })
                let groupsIndex = null
                for (const element of groups) {
                   const index = element.findIndex((el)=> el.id == selectedGroupId)
                   if (index >= 0 ){
                       groupsIndex = index
                   }
                }
                commit(mutypes.SET_TOURNAMENT_STANDING_GROUPS, response.data.tournament.stages[stagesIndex]?.groups);
                commit(mutypes.SET_TOURNAMENT_STANDING_SELECTED_STAGE_ID, response.data.tournament.stages[stagesIndex]?.id);
                commit(mutypes.SET_TOURNAMENT_STANDING_SELECTED_GROUP_ID, response.data.tournament.stages[stagesIndex]?.groups[groupsIndex]?.id);
                commit(mutypes.SET_TOURNAMENT_STANDING_MATCHES, response.data.tournament.stages[stagesIndex]?.groups[groupsIndex]?.groupMatches);
                commit(mutypes.SET_TOURNAMENT_STANDING_TEAMS, response.data.tournament.stages[stagesIndex]?.groups[groupsIndex]?.groupResults);
                commit(mutypes.SET_TOURNAMENT_STANDING_MEMBERS, response.data.tournament.stages[stagesIndex]?.stageTournamentMembers);
                commit(mutypes.SET_TOURNAMENT_STANDING_PREV_STAGE_ID, response.data.tournament.stages[stagesIndex]?.prev_stage_id);

            } else {
                //Берем первые значения со всего запроса
                commit(mutypes.SET_TOURNAMENT_STANDING_GROUPS, response.data.tournament.stages[0]?.groups);
                commit(mutypes.SET_TOURNAMENT_STANDING_SELECTED_STAGE_ID, response.data.tournament.stages[0]?.id);
                commit(mutypes.SET_TOURNAMENT_STANDING_SELECTED_GROUP_ID, response.data.tournament.stages[0]?.groups[0]?.id);
                commit(mutypes.SET_TOURNAMENT_STANDING_MATCHES, response.data.tournament.stages[0]?.groups[0]?.groupMatches);
                commit(mutypes.SET_TOURNAMENT_STANDING_TEAMS, response.data.tournament.stages[0]?.groups[0]?.groupResults);
                commit(mutypes.SET_TOURNAMENT_STANDING_MEMBERS, response.data.tournament.stages[0]?.stageTournamentMembers);
                commit(mutypes.SET_TOURNAMENT_STANDING_PREV_STAGE_ID, response.data.tournament.stages[0]?.prev_stage_id);
            }
            return getters.getTournament;
        },
        removeStandingTeam: async ({commit, getters}, params) => {
            api.tournamentGroupResult.destroy({id:params.id,reject_description: params.reject_description})
                .then(function(){
                const standingTeams = getters.getStandingsTeams.filter((groupResult) => {
                    return Number.parseInt(groupResult.id) !== Number.parseInt(params.id);
                });
                commit(mutypes.SET_TOURNAMENT_STANDING_TEAMS, standingTeams);
            })
                .catch(function(){
                Vue.toasted.error('Не удалось удалить команду', {
                    duration: 3000
                });
            })
        },

        removeTeamInTournamentStage: async ({commit, getters}, params) => {
            api.tournamentStage.dropStageTeam(params).then(()=>{
                const stageTeams = getters.getTeams.filter((team) => Number.parseInt(team.id) !== Number.parseInt(params.team_id));
                commit(mutypes.SET_TEAMS, stageTeams);
            }).catch(() =>{
                Vue.toasted.error('Не удалось удалить команду', {
                    duration: 3000
                });
            });

        },


        selectStage: async ({commit, getters}, id) => {
            const groups = getters.getStandingsStages.filter(t => t.id === id)[0].groups;
            const prevStage = getters.getStandingsStages.filter(t => t.id === id)[0]?.prev_stage_id;
            commit(mutypes.SET_TOURNAMENT_STANDING_SELECTED_STAGE_ID, id);
            localStorage.setItem('selectedStageId', id)
            commit(mutypes.SET_TOURNAMENT_STANDING_SELECTED_GROUP_ID, groups[0]?.id);
            localStorage.setItem('selectedGroupId', groups[0]?.id)
            commit(mutypes.SET_TOURNAMENT_STANDING_GROUPS, groups);
            //При выборе стадии берем первую группу
            commit(mutypes.SET_TOURNAMENT_STANDING_TEAMS, groups[0]?.groupResults)
            // Берем матчи из первой группы
            commit(mutypes.SET_TOURNAMENT_STANDING_MATCHES, groups[0]?.groupMatches)
            //Берем prev_stage_id
            commit(mutypes.SET_TOURNAMENT_STANDING_PREV_STAGE_ID, prevStage)
        },
        showTeamMembers: ({commit, getters}, teamId) => {
            const members = getters.getStandingTournamentMembers.filter((member) => {
                return member.team_id === teamId;
            });
            commit(mutypes.SET_TOURNAMENT_STANDING_MEMBERS_TEAM, members);
        },

        selectGroup: ({commit}, stageGroup) => {
            //@todo добавить вывод списка лобби матчей
            commit(mutypes.SET_TOURNAMENT_STANDING_SELECTED_GROUP_ID, stageGroup.id);
            localStorage.setItem('selectedGroupId', stageGroup.id)
            commit(mutypes.SET_TOURNAMENT_STANDING_TEAMS, stageGroup.groupResults)
            commit(mutypes.SET_TOURNAMENT_STANDING_MATCHES, stageGroup.groupMatches)
        },
        deleteTournamentStage: async function (_, id) {
            await api.tournamentStage.delete(id).then(() => window.location.reload())
        },
        showTournamentStage: async ({commit}, stageId) => {
            let response = await api.tournamentStage.show(stageId)
            let stageVariables = response.data.stage
            commit(mutypes.SET_CURRENT_STAGE, stageVariables)
            commit(mutypes.SET_TOURNAMENT_STAGE_NAME, stageVariables.name)
            commit(mutypes.SET_TOURNAMENT_STAGE_RATING_COEFFICIENT, stageVariables.rating_coefficient)
            commit(mutypes.SET_TOURNAMENT_STAGE_REPLACEMENT, stageVariables.replace_count)
            commit(mutypes.SET_TOURNAMENT_STAGE_THRESHOLD_RATING, stageVariables['threshold_rating'])

            if (stageVariables.stage_divisions) {
                commit(mutypes.SET_TOURNAMENT_STAGE_DIVISIONS, stageVariables.stage_divisions.map((div) => ({
                    division_id: div.division.id,
                    division_name: div.division.name.ru,
                    slot_count: div.slot_count,
                })))
            }

            commit(mutypes.SET_TOURNAMENT_STAGE_COEF_PERSONAL_RATING, stageVariables['player_rating_coefficient'])


            let values = {}
            values = {
                value: stageVariables.status.id,
                text: stageVariables.status.code
            }
            commit(mutypes.SET_TOURNAMENT_STAGE_STATUS, values)
        },
        async delTournamentStageGroup(_, groupId) {
            await api.tournamentStageGroup.del(groupId).then(() => window.location.reload())
        },
        async grabSelectedGroupMatchesResult({commit}, groupResultId) {
            const response = await api.tournamentGroupResult.grabGroupMatchesResult(groupResultId);
            commit(mutypes.SET_TOURNAMENT_STANDING_SELECTED_GROUP_RESULT, response.data.group_match_results);

        },
        async grabStandingTournamentTeamSlots({commit}, params) {
            const response = await api.tournamentGroupResult.grabTeamSlots(params)
            commit(mutypes.SET_TOURNAMENT_STANDING_TEAM_SLOTS, response.data.teams);
        },
        async changeSlotNumber({commit, getters}, params) {
            const response = await api.tournamentGroupResult.updateSlotNumber(params);

            let slots = getters.getStandingTournamentTeamSlots;
            slots = slots.map((item) => {
                if (item.id === response.data.group_result.id) {
                    item.groupResults.slot_number = response.data.group_result.slot_number;
                    return item;
                }
            });

            commit(mutypes.SET_TOURNAMENT_STANDING_TEAM_SLOTS, slots);
        },

        selectStandingTournamentTeam({commit, getters}, tournamentTeamId) {
            let selectedTeamIds = getters.getStandingTournamentSelectedTeams;

            if (selectedTeamIds.includes(tournamentTeamId)) {
                selectedTeamIds = selectedTeamIds.filter((item) => {
                    return item !== tournamentTeamId;
                });
            } else {
                selectedTeamIds.push(tournamentTeamId);
            }
            commit(mutypes.SELECT_TOURNAMENT_STANDING_TEAM, selectedTeamIds);
        },

        filterGroupForSelectedNextStage({commit, getters}, selectedNextStageId) {
            const nextStage = getters.getStandingsStages.find((stage) => {
                return stage.id === selectedNextStageId;
            })
            commit(mutypes.SELECT_TOURNAMENT_STANDING_GROUPS_FOR_NEXT_STAGE, nextStage.groups);
        },

        async transferTeamToNextStage({commit}, params) {
            const response = await api.tournament.transferTeamsToNextStage(params);
            commit(mutypes.SELECT_TOURNAMENT_STANDING_TEAM, []);
            commit(mutypes.SELECT_TOURNAMENT_STANDING_GROUPS_FOR_NEXT_STAGE, []);
            return response;
        },

        async transferTeamWithMembersToTournament(_, params) {
            const response = await api.tournament.transferTeamWithMembersToTournament(params)
                .then(() =>
                    this._vm.$toast("Команда добавлена")
                ).catch((error) => {
                    if (error.response) {
                        this._vm.$toast(error.response.data.message);
                    }
                });
            return response;
        },
        async finishTournament(_, payload){
            await api.tournament.finishTournament( payload.id, {end_time: payload.value + ':00'}).then(()=>{
                this._vm.$toast("Турнир завершен")
            }).catch(()=>{
                this._vm.$toast("Не удалось завершить турнир");
            })
        }
    }
}
