<template>
  <div>
    <Breadcrumbs main="Турниры" title="Промо турниры"/>
    <!-- Container-fluid starts-->
    <b-modal centered id="modal-EntryDelete" title="Предупреждение" v-model="show">
      <p class="my-4">Вы действительно хотите удалить заявку?</p>
      <template #modal-footer>
        <div>
          <b-btn variant="primary" class="mr-3" @click="deleteOuterTournamentModal">Подтвердить</b-btn>
          <b-btn variant="danger" @click="show=false">Отмена</b-btn>
        </div>
      </template>
    </b-modal>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="d-flex mb-2">
                <SearchInput v-model="searchForPromoTournamentsModel" @search="getFilteredPromoTournaments()" :placeholder-name="'Поиск рекламных турниров'"/>
              </div>
              <div class="row justify-content-left">
                <div class="col-md-6 col-lg-7">
                  <router-link to="create_promo_tournament" class="btn btn-success">Создать турнир
                  </router-link>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="">
                <b-table
                    responsive
                    ref="table"
                    show-empty
                    stacked="md"
                    :striped="striped"
                    :bordered="bordered"
                    :fields="fields"
                    :items="tournamentsOuter"
                    :current-page=0
                    :per-page=10
                >
                  <template v-slot:cell(name)="{item}">
                    {{ item.name }}
                  </template>
                  <template v-slot:cell(organizer)="{item}">
                    {{ item.organizer?.name }}
                  </template>
                  <template v-slot:cell(status)="{item}">
                    {{ item.status.code }}
                  </template>
                  <template v-slot:cell(platform)="{item}">
                    {{ item.organizer?.platform?.name }}
                  </template>
                  <template v-slot:cell(action)="{ item }">
                    <div class="d-flex flex-wrap align-items-center">
                      <router-link
                          :to="{ name: 'tournament_outer_edit', params: { id: item.id }}"
                      >
                        <span>
                          <b-btn variant="primary" class="mr-2 p-1 px-2 mb-1"><i class="fa fa-edit"></i></b-btn>
                        </span>
                      </router-link>
                      <span>
                        <b-btn variant="danger"
                               class="mr-2 p-1 px-2 mb-1"
                               @click="deleteOuterTournament(item.id)"
                        ><i class="fa fa-trash" style="font-size: 18px"></i></b-btn>
                      </span>
                    </div>
                  </template>
                </b-table>
              </div>

              <b-col md="6" class="p-0">
                <b-pagination
                    @change="handlePageChange"
                    v-model="currentPage"
                    :total-rows="total"
                    :per-page="10"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {TournamentOuterAdapterMixin} from "@/mixins/TournamentOuter/TournamentOuterAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: "PromoTournaments",
  components: {SearchInput},
  mixins: [ModalAdapterMixin, TournamentOuterAdapterMixin, PaginationHelperMixin],
  created() {
    this.recordPageToStorage(this.currentPage, this.pageKey)
    this.indexTournamentsOuter({
      pageNumber: this.getCurrentPageNumber(this.pageKey),
    })
    this.indexFormat({});
    this.indexTimeZones();
  },
  data() {
    return {
      searchForPromoTournamentsModel: '',
      show: false,
      tournamentId: null,
      pageKey: 'pageNumberPromoTournaments',
      fields: [
        {key: 'id', label: 'id', sortable: true,},
        {key: 'name', label: 'Турнир', sortable: true,},
        {key: 'organizer', label: 'Организатор', sortable: true,},
        {key: 'platform', label: 'Платформа', sortable: true,},
        {key: 'status', label: 'Статус', sortable: true,},
        {key: 'action', label: 'Действие', sortable: false,},
      ],
      striped: true,
      bordered: true,
    };
  },
  methods: {
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.indexTournamentsOuter({
        pageNumber: pageNumber,
        ...(this.searchForPromoTournamentsModel && {search: this.searchForPromoTournamentsModel}),
      })
    },
    getFilteredPromoTournaments() {
      this.isLoaded = true
      this.indexTournamentsOuter({search: this.searchForPromoTournamentsModel})
          .then(() => {
            this.isLoaded = false
          })
    },
    async deleteOuterTournament(id) {
      this.tournamentId = id
      this.show = true
    },
    deleteOuterTournamentModal() {
      this.deleteOuterTournaments({tournamentId: this.tournamentId})
          .then(() => this.show = false)
    },
  },

};
</script>