import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'
import Breadcrumbs from './components/bread_crumbs'
import { store } from './store';
import VueFeather from 'vue-feather';
import Toasted from 'vue-toasted';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { VueReCaptcha } from 'vue-recaptcha-v3'
import HighchartsVue from 'highcharts-vue'
import Emoji from 'emoji-picker-element';

import PxCard  from './components/Pxcard.vue'
Vue.component(PxCard.name, PxCard)
Vue.use(Toast)



// Import Theme scss
import "vue-select/dist/vue-select.css";
import './assets/scss/app.scss'
import {ValidationProvider} from "vee-validate";
import { extend } from 'vee-validate';
import { required, email, min, min_value, max,max_value } from 'vee-validate/dist/rules';
extend('email', email);
extend('min', min);
extend('min_value', min_value);
extend('required', {
    ...required,
    message: 'This field is required'
});


Vue.use(VueFeather);
Vue.use(BootstrapVue);
Vue.use(Toasted);
Vue.component('Breadcrumbs', Breadcrumbs);
Vue.component('ValidationProvider', ValidationProvider)
Vue.use(VueReCaptcha, { siteKey: '6LdEv0wnAAAAAGwgU1qE3z97nIn3mjnwvNPlCEih' })
Vue.use(HighchartsVue)
Vue.component('EmojiPicker', Emoji)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')