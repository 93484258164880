import { mapActions, mapGetters, mapMutations } from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const DashboardAdapterMixin = {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getDashboard: "getDashboard",
    }),
    dashboardInfo: {
      get() {
        return this.getDashboard;
      },
      set(value) {
        this.setDashboard(value);
      },
    },
  },
  methods: {
    ...mapMutations({
      setDashboard: mutypes.SET_DASHBOARD,
    }),
    ...mapActions(["grabDashboard"]),
  },
};
