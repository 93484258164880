import Vue from 'vue'
import api from '/src/api/api';

const getDefaultState = () => {
    return {
        fakeTeams: null,
        totalPagesOfFakeTeams: 1,
        games: null
    }
}

// initial state
const state = getDefaultState()

const actions = {
    async getFakeTeams({ commit }, data) {
        const response = await api.fakeTeams.index(data)
        commit('setFirstLevelData',{
            name: 'fakeTeams',
            value: response.data.teams
        })
        commit('setFirstLevelData', {
            name: 'totalPagesOfFakeTeams',
            value: response.data.pagination.total
        })
        return response.data.teams
    },
    async getGames({ commit }) {
        const response = await api.games.index()
        commit('setFirstLevelData',{
            name: 'games',
            value: response.data.games
        })
        return response.data.games
    },
    getDefaultDataForFakeTeam: async (_,  id ) => (await api.fakeTeams.show(id)).data.team,
}

const mutations = {
    setFirstLevelData(state, { name, value }) {
        Vue.set(state, name, value)
    },
}

const getters = {
    gamesForSelect: (state) => state.games.map((item) => ({
        value: item.id,
        text: item.name
    })),
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
