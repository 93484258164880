<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Логи" title="Лог начисления levels"/>
      <b-card>
        <b-table
            responsive
            show-empty
            stacked="md"
            :striped="striped"
            :bordered="bordered"
            :fields="fields"
            :items="userLevelsLogs"
            :current-page=0
            :per-page=10
            class="mb-5"
        >
          <template #cell(experience)="data">
            {{ data.item?.experience_points }}
          </template>
          <template #cell(description)="data">
            {{ data.item?.description }}
          </template>
        </b-table>
        <b-col md="6" class="p-0">
          <b-pagination
              @change="handlePageChange"
              v-model="userLevelsLogsCurrentPage"
              :total-rows="totalUserLevelsLogsPagination"
              :per-page="10"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-card>
    </div>
  </div>
</template>

<script>
import {UsersAdapterMixin} from "../../mixins/Users/UsersAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";

export default {
  name: "ListUsersLevels",
  mixins: [UsersAdapterMixin, PaginationHelperMixin],
  created() {
    this.recordPageToStorage(this.userLevelsLogsCurrentPage, this.pageKey)
    this.grabUserLevelsLogs({
      gameProfileId: this.$route.params.id,
      pageNumber: this.getCurrentPageNumber(this.pageKey),
    })
  },

  data() {
    return {
      pageKey: 'pageNumberUserlevelsLogs',
      fields: [
        {key: 'id', label: 'id', sortable: true},
        {key: 'experience', label: 'Кол-во очков опыта', sortable: true},
        {key: 'description', label: 'Описание', sortable: true},
      ],
      striped: true,
      bordered: true
    }
  },
  methods: {
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.grabUserLevelsLogs({
        gameProfileId: this.$route.params.id,
        pageNumber: pageNumber,
      })
    },
  },
}
</script>