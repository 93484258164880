<template>
  <div class="snippets-wrapper">
    <div class="snippet"
         v-for="snippet in list"
         :class="[ snippet.key === model && 'active' ]"
         @click="model = snippet.key"
         :key="snippet.key">
      {{ snippet.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Snippets",
  props: {
    value: {
      type: [String, Number],
      default: () => ''
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    model: {
      get() { return this.value },
      set(newValue) { this.$emit('input', newValue) }
    }
  }
}
</script>

<style scoped lang="scss">
.snippets-wrapper {
  background: #dad6ff;
  border-radius: 10px;
  margin-right: 4px;
  display: flex;
  align-items: center;

  & > .snippet:not(:last-child) {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      height: 50%;
      background: #7366ff;
    }
  }

  .snippet {
    color: #7366ff;
    padding: 7px 15px;
    cursor: pointer;
    position: relative;
    transition: .2s;
    border-radius: 10px;
    white-space: nowrap;

    @media screen and (max-width: 800px) {
      font-size: 13px;
    }

    &.active {
      background: #7366ff;
      color: white;
      box-shadow: 0 0 7px 0 #7366ff;
    }
  }
}
</style>