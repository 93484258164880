import {mapActions, mapGetters, mapMutations} from "vuex";

export const ModalAdapterMixin = {
    computed: {
        ...mapGetters({
            getShow: 'getShow',
            getOption: 'getOption',
            getIdModalForDelete: 'getIdModalForDelete'
        }),
    },
    methods: {
        ...mapMutations({
            setShowOpen: 'openShow',
            setCloseModal: 'closeShow',
            setOptionName: 'changeOption',
            setId: 'setIdForDelete'
        }),
        ...mapActions([
            'closeModal',
            'openModal',
            'optionName',
            'cleanOption',
            'idForDeleting',
            'deleteTournament',
            'deleteHelpDesk',
            'deleteBlogPost',
            'deleteTournamentStage',
            'delTournamentStageGroup',
            'deleteFakeUser',
            'deleteTeams',
            'deleteTournamentEntries',
            'grabStandingTournament',
            'deleteTournamentEntry'])

    }
}