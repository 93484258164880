<template>
  <div>
    <div v-if="params.isLoadingDocs" class="d-flex justify-content-center">
      <b-spinner/>
    </div>
    <div v-else-if="params.userDocuments.length">
      <div v-for="file in params.userDocuments" :key="'fileId' + file.id" class="d-flex flex-wrap justify-content-center">
        <iframe v-if="file?.mime_type === 'application/pdf'"
                :src="`${getMimeTypes(file?.mime_type).mimeType}, ${file.file}`"
                class="mb-4 mr-2" width="100%" height="600px">
        </iframe>
        <img v-else :src="`${getMimeTypes(file?.mime_type).mimeType}, ${file.file}`" class="mb-4 mr-2 w-75"/>
      </div>
    </div>
    <div v-else>Нет загруженных документов</div>
    <b-alert v-if="params.error" show variant="danger">{{ params.error }}</b-alert>
  </div>
</template>
<script>
import {MIMES} from '@/constants/mimeTypes';

export default {
  name: 'UserDocuments',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getMimeTypes(mime) {
      return { mimeType: `${MIMES[mime]}`}
    },
  }
}
</script>
