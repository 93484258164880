<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Реальные пользователи" title="Список реальных пользователей"/>
      <b-card>
        <div class="d-flex">
          <SearchInput v-model="searchForUsersModel" @search="getFilteredUsers()" :placeholder-name="'Поиск пользователей'"/>
        </div>
        <div class="notification-for-search mb-4">Поиск по id, username, почте, названию команды, игровым данным: pubg id pubg nick</div>
        <div v-if="isLoadingUsers" class="loading-wrapper">
          <b-spinner class="spinner"></b-spinner>
        </div>
        <b-table
            v-else
            responsive
            show-empty
            stacked="md"
            :striped="striped"
            :bordered="bordered"
            :fields="fields"
            :items="users"
            :tbody-tr-class="trClass"
            :current-page=0
            :per-page=10
            class="mb-5"
        >
          <template #cell(username)="data">
            <div>{{ data.item.username }}</div>
          </template>
          <template #cell(email)="data">
            <div>{{ data.item.email }}</div>
          </template>
          <template #cell(status)="data">
            <div v-if="data.item.game_profiles_count > 0">Игровой /</div>
            <div v-else>Не игровой /</div>
            <div v-if="data.item?.verified_social_accounts_count > 0">Активирован</div>
            <div v-else>Не активирован</div>
          </template>
          <template #cell(action)="data">
            <div class="row justify-content-center">
              <b-btn variant="primary" class="mr-1 p-1" @click="showUserInfo(data.item.id)"><i
                  class="fa fa-eye"></i></b-btn>
              <div v-if="data.item.currentBans.length">
                <div v-for="bans in data.item.currentBans" :key="bans.id">
                  <b-btn variant="warning p-1" @click="deleteUserBan({userId: bans.id})">
                    <i class="fa fa-ban"></i>
                  </b-btn>
                </div>
              </div>
              <div v-else>
                <b-btn variant="danger p-1" @click="showUserBanModal(data.item.id)">
                  <i class="fa fa-ban"></i>
                </b-btn>
              </div>
            </div>
          </template>
        </b-table>
        <b-col md="6" class="p-0">
          <b-pagination
              @change="handlePageChange"
              v-model="currentPage"
              :total-rows="totalUserPagination"
              :per-page="10"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-card>
    </div>
    <Modal name="BanUser" title="Бан пользователя" v-model="isShowBanUserModal" :params="{ id: selectedUserId }"/>
  </div>
</template>

<script>
import {UsersAdapterMixin} from "../../mixins/Users/UsersAdapterMixin";
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import {UserBanAdapterMixin} from "@/mixins/UserBan/UserBanAdapterMixin";
import SearchInput from "@/components/SearchInput.vue";
import Modal from '@/components/modals/index.vue'

export default {
  name: "ListUsers",
  components: {SearchInput, Modal},
  mixins: [UsersAdapterMixin, ModalAdapterMixin, PaginationHelperMixin, UserBanAdapterMixin],
  created() {
    try {
      this.isLoadingUsers = true
      this.recordPageToStorage(this.currentPage, this.pageKey)
      this.grabUsers({
        pageNumber: this.getCurrentPageNumber(this.pageKey),
      })
    } finally {
      this.isLoadingUsers = false
    }
  },

  data() {
    return {
      searchForUsersModel: '',
      pageKey: 'pageNumberUsersList',
      fields: [
        {key: 'id', label: '№', sortable: true,},
        {key: 'username', label: 'Имя', sortable: true,},
        {key: 'email', label: 'Почта', sortable: true,},
        {key: 'status', label: 'Статус', sortable: true,},
        {key: 'action', label: 'Инструменты', sortable: true,},
      ],
      striped: true,
      bordered: true,
      isLoadingUsers: false,
      isShowBanUserModal: false,
      selectedUserId: null
    }
  },
  methods: {
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.grabUsers({
        pageNumber: pageNumber,
        ...(this.searchForUsersModel && {search: this.searchForUsersModel}),
      })
    },
    getFilteredUsers() {
      this.isLoadingUsers = true
      this.grabUsers({search: this.searchForUsersModel})
          .then(() => {
            this.isLoadingUsers = false
          })
    },
    trClass: rowData => rowData?.currentBans.length && 'table-dark',
    showUserBanModal(userId) {
      this.selectedUserId = userId
      this.isShowBanUserModal = true
    }
  },
}
</script>