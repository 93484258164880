<template>
<div>
  <Breadcrumbs main="Роли" title="Cоздать роль"/>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
        <div class="p-3">
          <label for=""><b>Название роли</b></label>
          <b-form-input v-model="newRoleName"></b-form-input>
          <div class="mt-3">
            <label class="font-weight-bold">Доступы</label>
            <Multiselect :options="rolePermissions" v-model="newPermissions" label="name" track-by="id" :multiple="true" placeholder="Выберите доступы" :close-on-select="false" :hide-selected="true">
            </Multiselect>
          </div>
          <div class="mt-3 d-flex">
            <b-btn variant="success" class="mr-5" @click.prevent="createRoleFunc({name: newRoleName, permissions: newPermissions})">Создать</b-btn>
            <b-btn variant="primary" @click="$router.go(-1)">Назад</b-btn>

          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {RolesAdapterMixin} from "@/mixins/Roles/RolesAdapterMixin";
import Multiselect from "vue-multiselect";

export default {
  name: "RoleCreate",
  mixins: [RolesAdapterMixin],
  components: {Multiselect},
  created() {
    this.indexRolePermissions()
  }
}
</script>

<style scoped>

</style>