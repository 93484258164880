<template>
  <div class="team-card-wrapper"
       v-if="team"
       :class="[
           selectedModel.some(({ id }) => id === team.id) && 'active',
       ]"
  >
    <div class="selected">
<!--      <b-form-checkbox size="lg" v-model="selectedModel" :value="team">-->
<!--        <span class="checkbox-label">Выбрать</span>-->
<!--      </b-form-checkbox>-->
    </div>
    <div class="content">
      <header>
        <div>
          <h4 class="allow-select" @click="$emit('editTeam', { team, result })">
            {{ serialNumber && `${ serialNumber }. ` }}{{ team?.name }}
            <img class="pen-icon" :src="PenIcon" alt="" />
          </h4>
          <span class="subtitle">{{ team?.division.name['ru'] }}</span>
        </div>
        <div class="right-header">
          <button class="action show-players" @click="isShowPlayers = !isShowPlayers">
            <span class="d-none d-sm-block"> {{ isShowPlayers ? 'Скрыть игроков' : 'Показать игроков' }}</span>
            <span class="d-sm-none d-block"><i class="fa fa-users fa-lg" aria-hidden="true"></i></span>
          </button>
          <button class="action show-matches" @click="isShowMatches ? isShowMatches = false : getResultMatches()">
            <span class="d-none d-sm-block">{{ isLoadingMatches ? 'Получение матчей' : isShowMatches ? 'Скрыть матчи' : 'Показать матчи' }}</span>
            <span class="d-sm-none d-block"><i class="fa fa-trophy fa-lg" aria-hidden="true"></i></span>
          </button>
          <span class="id-team">
          slot:
          <template v-if="isLoadingSaveSlot">
            <b-spinner />
          </template>
          <template v-else>
            <template v-if="!isEditSlot">
<!--              {{ slotModel v-if="isEditSlot" }}-->
              <b-form-input v-model="slotModel"
                            v-if="!isCompletedTournament"
                            @input="callSaveSlotNumber"
                            type="number"
                            size="sm" />
              <template v-else>
                {{ slotModel }}
              </template>
            </template>
<!--            <b-form-input v-model="slotModel"-->
<!--                          v-if="isEditSlot"-->
<!--                          type="number"-->
<!--                          size="sm" />-->
<!--            <img v-if="!isEditSlot" @click="isEditSlot = true" :src="PenGrayIcon" alt="" />-->
<!--            <img v-else @click="saveSlotNumber" :src="FloppyDiskGrayIcon" alt="">-->
          </template>
        </span>
        </div>
      </header>
      <div class="body">
        <div v-if="isShowPlayers" class="players-data">
          <template v-if="players.length">
            <h4>Игроки</h4>
            <table>
              <tr>
                <td>Username</td>
                <td>Nick</td>
                <td>PUBG ID</td>
              </tr>
              <tr v-for="player in players" :key="'player-' + player.id">
                <td>{{ player.user.username }}</td>
                <td>{{ getGameProfile(player).fields.nick }}</td>
                <td>{{ getGameProfile(player).fields.pubg_id }}</td>
              </tr>
            </table>
          </template>
          <div v-else class="pug">
            Игроков нет
          </div>
        </div>
        <div v-if="isShowMatches" class="matches-data">
          <h4 :class="[isShowPlayers && 'mt-4']">Матчи</h4>
          <table v-if="matches.length">
            <tr>
              <td>Название матча</td>
              <td>Убийства</td>
              <td>Place PTS</td>
              <td>Общее</td>
            </tr>
            <tr v-for="match in matches" :key="match.id">
              <td>{{ match?.match?.name || '' }}</td>
              <td>{{ match.kill_pts }}</td>
              <td>{{ match.place_pts }}</td>
              <td>{{ match.kill_pts + match.place_pts }}</td>
            </tr>
          </table>
          <div v-else class="pug">
            Матчей нет
          </div>
        </div>
      </div>
      <div class="main-data">
        <div class="activity-board">
          <div v-for="activity in activityData" :key="activity.value + activity.icon" class="activity">
            <img :src="activity.icon" alt="" />
            <span>{{ activity.value }}</span>
          </div>
        </div>
        <div v-if="!isCompletedTournament" class="actions-boards">
          <button class="action select" @click="toggleTeam">{{ selectedModel.some(({ id }) => id === team.id) ? 'Отменить выбор' : 'Выбрать' }}</button>
          <button class="action delete" v-if="!activityData.at(-1).value" @click="isShowDeleteTeamModal = !isShowDeleteTeamModal">Удалить</button>
        </div>
      </div>
      <div class="status" v-if="result?.is_approve_game_time !== null">
        <img class="status-icon" :src="getStatusOfParticipation(result?.is_approve_game_time).icon" alt=""/>
        <h1 class="status-text" :style="getStatusOfParticipation(result?.is_approve_game_time).style">
          {{getStatusOfParticipation(result?.is_approve_game_time).text}}
        </h1>
      </div>
      <footer>
        <b-alert v-if="errorText" class="mt-4 mb-0" show variant="danger">
          {{ errorText }}
        </b-alert>
      </footer>
      <Modals v-model="isShowDeleteTeamModal" name="BasicDeleteTeamTournament" title="Удаление команды" :params="{ team, result }" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import api from "@/api/api";

import Modals from "@/components/modals/index.vue";

import GunIcon from '@/assets/icons/gun.svg'
import RankingStarIcon from '@/assets/icons/ranking-star.svg'
import GripLinesIcon from '@/assets/icons/grip-lines.svg'
import PenGrayIcon from '@/assets/icons/pen-gray.svg'
import FloppyDiskGrayIcon from '@/assets/icons/floppy-disk-gray.svg'
import PenIcon from '@/assets/icons/pen.svg'
import NotApprovedIcon from '@/assets/icons/clock.svg'
import ApprovedIcon from '@/assets/icons/check-black.svg'
import RejectedIcon from '@/assets/icons/xmark-black.svg'

import lodash from 'lodash'

export default {
  name: "TeamCard",
  props: {
    value: {
      type: Array,
      required: true,
    },
    team: {
      type: Object,
      required: true
    },
    result: {
      type: Object,
      required: true
    },
    members: {
      type: Array,
      required: true
    },
    serialNumber: {
      type: Number,
      default: 0
    }
  },
  components: {
    Modals
  },
  computed: {
    ...mapState('moduleNewTournament', ['tournament', 'selectedGroupId', 'selectedRoundId', 'selectedStageId']),
    ...mapGetters('moduleNewTournament', ['selectedStage', 'isCompletedTournament']),
    activityData() {
      return [
        {
          icon: GunIcon,
          value: this.result.group_result.result_fields.kill_pts
        },
        {
          icon: RankingStarIcon,
          value: this.result.group_result.result_fields.place_pts
        },
        {
          icon: GripLinesIcon,
          value: this.result.group_result.result_fields.kill_pts + this.result.group_result.result_fields.place_pts
        }
      ]
    },
    players() {
      return this.members
    },
    selectedModel: {
      get() { return this.value },
      set(newValue) { this.$emit('input', newValue) }
    },
  },
  data() {
    return {
      PenIcon,
      PenGrayIcon,
      FloppyDiskGrayIcon,
      isShowPlayers: false,
      isEditSlot: false,
      isLoadingSaveSlot: false,
      isShowDeleteTeamModal: false,
      isLoadingMatches: false,
      isShowMatches: false,
      slotModel: 0,
      errorText: '',
      matches: []
    }
  },
  methods: {
    async saveSlotNumber() {
      try {
        this.errorText = ''
        this.isLoadingSaveSlot = true
        await api.tournamentGroupResult.newUpdateGroupResult(this.result.group_result.id,{
          slot_number: this.slotModel,
        })
        if (this.tournament.game.slug === 'pubg_mobile')
          await this.$store.dispatch('moduleNewTournament/getTeamsByGroup', { id: this.selectedGroupId })
        if (this.tournament.game.slug === 'mlbb')
          await this.$store.dispatch('moduleNewTournament/getGroupsWithTeamsByMLBB', { roundId: this.selectedRoundId })
        await this.$store.dispatch('moduleNewTournament/getTournament', {
          id: this.$route.params.standingId
        })
      } catch (e) {
        this.errorText = `Ошибка по сохранению номер слота: ${ e.response.data.message }`
      } finally {
        this.isLoadingSaveSlot = false
        this.isEditSlot = false
      }
    },
    async getResultMatches() {
      this.isLoadingMatches = true
      const response = (await api.tournamentGroupResult.newGrabGroupMatchesResult(this.result.group_result.id)).data;
      this.matches = response.group_result.group_match_results.map(({ result_fields, ...other }) => ({ ...other, ...result_fields }))
      this.isShowMatches = true
      this.isLoadingMatches = false
    },
    toggleTeam() {
      // const selectedTeam = this.selectedModel.find(({ id }) => id === this.team.id)
      const selectedTeamIndex = this.selectedModel.findIndex(({ id }) => id === this.team.id)
      if (selectedTeamIndex > -1) {
        this.selectedModel.splice(selectedTeamIndex, 1)
      } else {
        this.selectedModel.push({
          ...this.team,
          stage_team_id: this.result.stage_team_id
        })
      }
    },
    getGameProfile(player) {
      return player.user.game_profiles.find(({ game_id }) => game_id === this.tournament.game_id)
    },
    getStatusOfParticipation(status) {
      const participationStatus = {
        NOT_APPROVED: 0,
        REJECTED: 1,
        APPROVED: 2
      };
      if(status === participationStatus.NOT_APPROVED) {return {icon: NotApprovedIcon, text: 'Не подтверждено', style: 'color: #db9950'}}
      if(status === participationStatus.REJECTED) {return {icon: RejectedIcon, text: 'Отклонено', style: 'color: #de5858'}}
      if(status === participationStatus.APPROVED) {return {icon: ApprovedIcon, text: 'Подтверждено', style: 'color: #1a7a1a'}}
      return false
    },
    callSaveSlotNumber: lodash.debounce(function () {
      this.saveSlotNumber()
    }, 2000)
  },
  watch: {
    'result': {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.slotModel = newValue?.group_result?.slot_number || 0
      }
    }
  },
}
</script>

<style scoped lang="scss">
.team-card-wrapper {
  transition: .2s;
  display: grid;
  grid-template-columns: 0 auto;

  &.active {
    grid-template-columns: 10px auto;

    .selected {
      max-width: 100vw;
      margin-right: -10px;
    }
  }

  & > * {
    align-self: center;
  }

  .selected {
    background: #7366ff;
    height: 100%;
    max-width: 0;
    overflow: hidden;
    transition: .2s;
    border-radius: 10px 0 0 10px;
    position: relative;
    z-index: 2;

    .checkbox-label {
      display: block;
      font-size: 14px;
      margin-top: 5px;
    }
  }

  .content {
    box-shadow: 0 0 3px 0 #00000026;
    background: white;
    padding: 20px;
    border-radius: 10px;
    transition: all .2s;
    z-index: 3;

    button.action {
      font-size: 11px;
      border-radius: 13px;
      padding: 5px 13px 5px 13px;
      background: white;
      color: #000000bf;
      border: 1px solid #000000bf;
      transition: .2s;

      &:hover {
        background: #000000bf;
        color: white;
      }

      &.delete {
        color: #ff0000c9;
        border-color: #ff0000c9;

        &:hover {
          color: white;
          background: #ff0000c9;
        }
      }

      &.select {
        border: 1px solid #7366ff;
        color: #7366ff;

        &:hover {
          color: white;
          background: #7366ff;
        }
      }
    }

    header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      h4 {
        margin: 0;
        font-size: 18px;
        transition: .2s;

        @media screen and (max-width: 400px) {
          font-size: 14px;
        }

        &.allow-select {
          cursor: pointer;

          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;

          .pen-icon {
            width: 12px;
            opacity: 0;
            transition: .2s;
          }

          &:hover {
            color: #7366ff;

            .pen-icon {
              opacity: 1;
            }
          }
        }
      }

      .subtitle {
        font-size: 12px;
        color: gray;
      }

      .id-team {
        color: #808080c7;
        font-size: 12px;
        display: flex;
        align-items: center;
        white-space: nowrap;

        @media screen and (max-width: 370px) {
          justify-content: end;
          margin-top: 10px;
        }

        .spinner-border {
          width: 20px;
          height: 20px;
          margin-left: 5px;
        }

        input {
          width: 40px;
          padding: 0;
          height: 21px;
          text-align: center;
          margin-left: 4px;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }

        img {
          cursor: pointer;
          height: 10px;
          margin-left: 5px;
          margin-top: -2px;
        }
      }

      .right-header {
        display: flex;
        align-items: center;
        gap: 10px;

        @media screen and (max-width: 370px) {
          display: block;
          text-align: center;
        }

        button.action {
          white-space: nowrap;

          @media screen and (max-width: 370px) {
            margin-right: 5px;
          }

          @media screen and (max-width: 575px) {
            padding: 10px;
            border-radius: 100%;
          }
        }
      }
    }

    .body {
      @media screen and (max-width: 400px) {
        width: 300px;
      }

      @media screen and (max-width: 370px) {
        width: 270px;
      }

      @media screen and (max-width: 350px) {
        width: 250px;
      }

      @media screen and (max-width: 350px) {
        width: 230px;
      }

      .matches-data, .players-data {
        width: 100% !important;

        @media screen and (max-width: 400px) {
          overflow-x: scroll;
        }

        h4 {
          font-size: 17px;
          text-align: center;

          @media screen and (max-width: 800px) {
            font-size: 15px;
          }
        }

        table {
          width: 100%;

          tr {
            transition: .2s;
          }

          td {
            padding: 10px;

            @media screen and (max-width: 800px) {
              font-size: 12px;
            }
          }

          & > tr:not(:last-child) {
            border-bottom: 1px solid #00000026;
          }

          & > tr:not(:first-child) {
            &:hover {
              background: #00000026;
            }
          }

          & > tr > td:not(:last-child) {
            border-right: 1px solid #00000026;
          }

          & > tr > td:not(:first-child) {
            text-align: center;
          }
        }
      }

      .players-data {
        width: fit-content;

        .player {
          font-size: 14px;
          padding: 10px 25px;
          width: 100%;
        }

        & > .player:not(:last-child) {
          border-bottom: 1px solid #80808059;
        }
      }

      .pug {
        text-align: center;
        margin: 15px 0;
        color: rgba(128, 128, 128, 0.6901960784);
        font-size: 14px;
        font-weight: 400;
      }
    }

    .main-data {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
    .status {
      display: flex;
      gap: 5px;
      margin-top: 5px;

      .status-icon {
        width: 14px;
        transition: .2s;
      }
      .status-text {
        font-size: 14px;
        color: #2b2b2b;
        margin-top: 13px;
      }
    }

    .actions-boards {
      display: flex;
      align-items: center;
      gap: 10px;

      @media screen and (max-width: 325px) {
        margin-left: 10px;
      }

      @media screen and (max-width: 405px) {
        display: grid;
        gap: 5px;
        margin-top: 10px;
      }
    }

    .activity-board {
      display: flex;
      gap: 10px;
      margin-top: 15px;

      & > .activity:not(:last-child) {
        padding-right: 10px;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          width: 1px;
          height: 80%;
          background: #8080806e;
          transform: translateY(-50%);
        }
      }

      .activity {
        display: flex;
        align-items: center;
        gap: 5px;
        position: relative;

        img {
          width: 13px;
          height: 13px;
        }

        span {
          display: block;
          font-size: 15px;

          @media screen and (max-width: 400px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  footer {
    margin-left: 0 !important;
  }
}
</style>