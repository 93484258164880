<template>
  <div class="deep-edit-team-from-tournament-wrapper">
    <div v-if="isLoadingTeam" class="loading-wrapper">
      <b-spinner class="spinner" />
    </div>
    <template v-else>
      <h3>Команда: {{ teamData.name }}</h3>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from "@/api/api";

export default {
  name: "DeepEditTeamFromTournament",
  computed: {
    ...mapState('moduleNewTournament', ['selectedTeamId', 'tournament']),
  },
  data() {
    return {
      isLoadingTeam: false,
      teamData: null
    }
  },
  methods: {
    async getTeamData() {
      this.isLoadingTeam = true
      const response = await api.newTeams.getTeam(this.selectedTeamId, this.tournament.id)
      this.teamData = response.data.team
      this.isLoadingTeam = false
    }
  },
  created() {
    this.getTeamData()
  }
}
</script>

<style scoped lang="scss">
.deep-edit-team-from-tournament-wrapper {
  h3 {
    font-size: 18px;
    margin: 15px 0;
  }
}
</style>