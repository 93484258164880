<template>
  <div class="not-teams-workspace-wrapper">
    <h6>Команд нет</h6>
    <button @click="$emit('changeWorkspace', 'AddTeam')">Добавить команду</button>
  </div>
</template>

<script>
export default {
  name: "NotTeamsWorkspace"
}
</script>

<style scoped lang="scss">
.not-teams-workspace-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h6 {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 15px;
  }

  button {
    display: block;
    font-size: 11px;
    border-radius: 15px;
    padding: 10px 20px;
    background: #7366ff;
    color: white;
    border: none;
  }
}
</style>