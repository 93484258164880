<template>
  <div class="add-match-modal-wrapper">
    <b-form>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Название матча</label>
        <b-form-input v-model="name"
                      placeholder="Введите имя матча"/>
      </div>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Lobby number</label>
        <b-form-input v-model="login"
                      placeholder="Введите номер лобби"></b-form-input>
      </div>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Кол-во хостов</label>
        <b-form-input v-model="hostCount"
                      placeholder="Введите количество хостов"></b-form-input>
      </div>

      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Password</label>
        <b-form-input v-model="password"
                      placeholder="Введите пароль матча"></b-form-input>
      </div>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Дата</label>
        <DatePicker v-model="date" mode="dateTime" is24hr
                    :min-date='new Date()'
                    :model-config="modelConfig" :popover="{visibility: 'click'}">
          <template v-slot="{inputValue, inputEvents}">
            <b-form-input :value="inputValue" v-on="inputEvents"/>
          </template>
        </DatePicker>
      </div>
      <b-alert v-if="errorText" show variant="danger">{{ errorText }}</b-alert>
      <div class="form-footer">
        <b-btn :disabled="isLoadingSaveForm" variant="primary" @click="addMatch">
          {{ isLoadingSaveForm ? 'Сохраняем...' : 'Сохранить' }}
        </b-btn>
      </div>
    </b-form>
  </div>
</template>

<script>
import {BookAdapterMixin} from "@/mixins/Book/BookAdapterMixin";
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: "AddMatchForBook",
  components: {
    DatePicker
  },
  mixins: [BookAdapterMixin],
  data() {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
      isLoadingSaveForm: false,
      errorText: null,
    };
  },
  methods: {
    async addMatch(event) {
      try {
        event.preventDefault()
        this.isLoadingSaveForm = true
        await this.storeMatch({
              name: this.name,
              group_id: this.$route.params.group_id,
              lobby_number: this.login,
              password: this.password,
              start_time: this.date,
              status: 0,
              host_count: this.hostCount,
              with_bookmaker: 1
            })

        await this.$store.dispatch('showGroupMatchesBook', this.$route.params.group_id)
        await this.$emit('submit')
        await this.$emit('closeModal')
        // } catch (e) {
        // console.log(e);
        // console.log('catch block');
        // //let errors = ''
        // // if (e.response.data.errors) errors = Object.entries(e.response.data.errors).map(([ name, values ]) => `${ name }: ${ values.join(', ') }`).join('. ')
        // this.errorText = e?.response?.data?.message
      } finally {
        this.isLoadingSaveForm = false
      }
    }
  },
};
</script>

<style scoped lang="scss">
.add-match-modal-wrapper {

  .form-footer {
    border-top: 1px solid #dee2e6;
    text-align: right;
    padding-top: 15px;
  }
}
</style>