<template>
  <div class="move-to-next-stage-tournament-wrapper">
    <h6>Вы переносте следующие команды: <b>{{ params.teams.map(({ name }) => name).join(', ') }}</b></h6>
    <div class="stages-and-groups">
      <SidebarBlock>
        <template #header>
          <span class="block-name">Выберите Этап</span>
        </template>
        <template #body>
          <Tabs :list="stages"
                hide-actions
                v-model="selectedStageId" />
        </template>
      </SidebarBlock>
      <SidebarBlock>
        <template #header>
          <span class="block-name">Выберите группу</span>
        </template>
        <template #body>
          <Tabs :list="groups"
                hide-actions
                v-model="selectedGroupId">
            <template #pug><h6>Групп нет</h6></template>
          </Tabs>
        </template>
      </SidebarBlock>
    </div>
    <b-alert v-if="errorText" class="mt-3" show variant="danger">{{ errorText }}</b-alert>
    <footer>
      <b-button :disabled="isDisabledSubmit"
                @click="submit"
                class="submit-button"
                type="submit"
                variant="primary">
        {{ isLoadingSubmit ? 'Переносим...' : 'Перенести' }}
      </b-button>
    </footer>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import api from "@/api/api";

import SidebarBlock from "@/components/Tournament/SidebarBlock.vue";
import Tabs from "@/components/Tournament/Tabs.vue";

export default {
  name: "MoveToNextStageTournament",
  components: {
    Tabs,
    SidebarBlock
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedStageId: null,
      selectedGroupId: null,
      isLoadingSubmit: false,
      errorText: ''
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['tournament', 'selectedRoundId']),
    ...mapState('moduleNewTournament', {
      selectedGroupIdFromStore: state => state.selectedGroupId
    }),
    ...mapGetters('moduleNewTournament', ['stages']),
    groups() {
      if (!this.selectedStageId) return []
      return this.stages.find(({ id }) => id === this.selectedStageId)?.groups || []
    },
    isDisabledSubmit() {
      // return !this.selectedStageId || !this.selectedGroupId
      return !this.selectedStageId
    }
  },
  methods: {
    async submit() {
      try {
        this.errorText = ''
        this.isLoadingSubmit = true
        await api.tournament.newTransferTeamsToNextStage({
          // group_id: this.selectedGroupId,
          ...(this.selectedGroupId && { group_id: this.selectedGroupId }),
          stage_id: this.selectedStageId,
          stage_team_ids: this.params.teams.map(({ stage_team_id }) => stage_team_id)
        })
        if (this.tournament.game.slug === 'pubg_mobile')
          await this.$store.dispatch('moduleNewTournament/getTeamsByGroup', { id: this.selectedGroupIdFromStore })
        if (this.tournament.game.slug === 'mlbb')
          await this.$store.dispatch('moduleNewTournament/getGroupsWithTeamsByMLBB', { roundId: this.selectedRoundId })
        await this.$store.dispatch('moduleNewTournament/getTournament', {
          id: this.$route.params.standingId
        })
        this.$emit('submit')
        this.$emit('closeModal')
      } catch (e) {
        console.log('e ', { e })
        if (e?.response?.data) this.errorText = e.response.data.message
      } finally {
        this.isLoadingSubmit = false
      }
    }
  },
  watch: {
    selectedStageId() {
      this.selectedGroupId = null
    }
  }
}
</script>


<style scoped lang="scss">
.move-to-next-stage-tournament-wrapper {
  h6 {
    font-weight: 400;
  }

  footer {
    border-top: 1px solid #dee2e6;
    text-align: right;
    padding-top: 15px;
    margin-top: 15px;
  }

  .submit-button {
    background: #7366ff;
    //width: 100%;
  }

  .stages-and-groups {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px
  }
}
</style>