<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Команды" title="Список удаленных команд"/>
      <b-card>
        <div class="d-flex  mb-5">
          <div>
            <label>Search</label>
            <b-form @submit.prevent="filterTeams()">
              <b-input type="search" class="w-50" v-model="dataForFilter"/>
              <div class="mt-4">
                <b-form-checkbox-group
                    v-model="filteredArray"
                    :options="filters"
                >
                </b-form-checkbox-group>
              </div>
              <b-btn variant="primary"
                     @click.prevent="filterTeams()"
                     class="mt-2 ml-2 row justify-content-center justify-content-md-start">
                Поиск
              </b-btn>
            </b-form>
          </div>
        </div>
        <div class="mb-5 d-flex justify-content-end">
          <b-dropdown variant="primary" id="dropdown-1" text="Дисциплина">
            <b-dropdown-item @click="getDeletedTeamsByGame()">
              <div class="text-dark">Все</div>
            </b-dropdown-item>
            <b-dropdown-item v-for="(game, index) in games" :key="index + 'game-' + game.value"
                             @click="getDeletedTeamsByGame(game.value)"
                             variant="primary">
              <div class="text-dark">{{ game.text }}</div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div v-if="isLoaded" class="loading-wrapper">
          <b-spinner class="spinner"></b-spinner>
        </div>
        <b-table v-else
                 responsive
                 ref="table"
                 show-empty
                 stacked="md"
                 :striped="striped"
                 :bordered="bordered"
                 :items="deletedTeams"
                 :fields="fields"
                 :current-page=0
                 :per-page=10
                 class="mb-5">
          <template #cell(team)="data">
             {{ data.item.name }}
          </template>
          <template #cell(participants)="data">
            <div class="text-center">
              <div v-if="data.item.member_count">{{ data.item.member_count }}</div>
              <div v-else>0</div>
            </div>
          </template>
          <template #cell(last_tournament)="data">
            {{data.item?.lastTournament?.created_at ? moment(data.item?.lastTournament?.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm') : '' }}
          </template>
          <template #cell(tournament_count)="data">
            <div class="text-center">
              <div v-if="data.item.tournaments_count">
                {{ data.item.tournaments_count }}
              </div>
              <div v-else>0</div>
            </div>
          </template>
          <template #cell(game_title)="data">
            <div>{{ data.item.game.name }}</div>
          </template>
          <template #cell(created_date)="data">
            <div>{{ moment(data.item.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm')}}</div>
          </template>
          <template #cell(deleted_date)="data">
            <div>{{ moment(data.item.deleted_at, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY HH:mm')}}</div>
          </template>
          <template #cell(team_owner)="data">
            <div>{{ data.item.teamOwner.email }}</div>
          </template>
          <template #cell(action)="data">
            <div class="row justify-content-center">
              <b-btn variant="primary" class="mr-1 p-1" @click="showTeamInfo(data.item.id)"><i
                  class="fa fa-eye"></i></b-btn>
            </div>
          </template>
        </b-table>
        <b-col md="6" class="p-0">
          <b-pagination
              @change="handlePageChange"
              v-model="deletedTeamsCurrentPage"
              :total-rows="totalDeletedTeams"
              :per-page="10"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-card>
    </div>
  </div>
</template>

<script>
import {TeamsAdapterMixin} from "@/mixins/Teams/TeamsAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import moment from 'moment'

export default {
  name: 'DeletedTeamsList',
  mixins: [TeamsAdapterMixin, PaginationHelperMixin],
  mounted() {
    this.recordPageToStorage(this.deletedTeamsCurrentPage, this.pageKey),
    Promise.all([
      this.trashedTeams({
        pageNumber: this.getCurrentPageNumber(this.pageKey),
      }),
      this.grabGamesForTeams()
    ])
  },
  data() {
    return {
      moment,
      filteredArray: [],
      dataForFilter: '',
      pageKey: 'pageNumberDeletedTeamsList',
      fields: [
        {key: 'id', label: '№', sortable: true},
        {key: 'team', label: 'Команды', sortable: true},
        {key: 'participants', label: 'Участники (всего/игр)', sortable: true},
        {key: 'last_tournament', label: 'Дата последнего турнира', sortable: true},
        {key: 'tournament_count', label: 'Количество турниров', sortable: true},
        {key: 'game_title', label: 'Название игры', sortable: true},
        {key: 'created_date', label: 'Дата создания', sortable: true},
        {key: 'deleted_date', label: 'Дата удаления', sortable: false},
        {key: 'team_owner', label: 'Cоздатель команды', sortable: false},
        {key: 'action', label: 'Инструменты', sortable: false},
      ],
      filters: {
        id: {text: "Найти по id команды", value: "id"},
        name: {text: "Найти по названию команды", value: "name"},
        email: {text: "Найти по email капитана", value: "email"},
        userName: {text: "Найти по имени капитана", value: "userName"},
        gameName: {text: "Найти по названию игры", value: "gameName"}
      },
      striped: true,
      bordered: true
    }
  },
  methods: {
    handlePageChange(pageNumber) {
      let gameIds = this.games.map((game) => game.value)
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.trashedTeams({
            pageNumber: pageNumber,
            ...(this.filteredArray.includes('userName') && this.filteredArray.length === 1 && {
              userName: this.dataForFilter,
            }),
            ...(this.filteredArray.includes('gameName') && this.filteredArray.length === 1 && {
              gameName: this.dataForFilter,
            }),
            ...(this.filteredArray.includes('email') && this.filteredArray.length === 1 && {
              email: this.dataForFilter,
            }),
            ...(this.filteredArray.includes('id') && this.filteredArray.length === 1 && {
              id: this.dataForFilter,
            }),
            ...(this.filteredArray.includes('name') && this.filteredArray.length === 1 && {
              name: this.dataForFilter,
            }),
            ...(gameIds.includes(this.selectedGameId) && {
              gameId: this.selectedGameId
            })
          },
      )
    },
    async deleteButton(id) {
      this.optionName('Вы действительно хотите удалить эту команду?')
      this.idForDeleting(id)
      this.openModal()
    },
    filterTeams() {
      let filteredData = {};
      this.filteredArray.forEach((item) => {
        filteredData[item] = this.dataForFilter;
      })
      this.trashedTeams(filteredData)
    },
  },

}
</script>
