<template>
  <div class="delete-stage-tournament-wrapper">
    <h5>Вы действительно хотите удалить {{ params?.stage?.name || '' }}?</h5>
    <b-alert v-if="errorText" show variant="danger">{{ errorText }}</b-alert>
    <footer>
      <b-button @click="submitForm" :disabled="isLoadingSaveForm">
        {{ isLoadingSaveForm ? 'Удаляем...' : 'Удалить' }}
      </b-button>
    </footer>
  </div>
</template>

<script>
export default {
  name: "DeleteStageTournament",
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoadingSaveForm: false,
      errorText: null
    }
  },
  methods: {
    async submitForm() {
      try {
        this.isLoadingSaveForm = true
        this.errorText = null
        await this.$store.dispatch('moduleNewTournament/deleteStage', {
          id: this.params.stage.id
        })
        await this.$store.dispatch('moduleNewTournament/getTournament', {
          id: this.$route.params.standingId
        })
        await this.$emit('closeModal')
      } catch (e) {
        const message = e.response.data.message
        let errors = ''
        if (e.response.data.errors) errors = Object.entries(e.response.data.errors).map(([ name, values ]) => `${ name }: ${ values.join(', ') }`).join('. ')
        this.errorText = `${ message }; ${ errors }.`
      } finally {
        this.isLoadingSaveForm = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.delete-stage-tournament-wrapper {
  h5 {
    font-size: 16px;
    font-weight: 400;
  }
  footer {
    border-top: 1px solid #dee2e6;
    padding-top: 15px;
    margin-top: 15px;
    text-align: right;
  }
}
</style>