<template>
  <div>
    <Breadcrumbs main="Бан" title="Список банов"/>
    <!-- Container-fluid starts-->
    <b-card>
      <div class="d-flex">
        <SearchInput v-model="searchForBanedTeamsModel" @search="getFilteredBanedTeams()" :placeholder-name="'Поиск команд'"/>
      </div>
      <div class="notification-for-search mb-4">Поиск по id, названию команды, почте и username капитана</div>
      <div v-if="isLoadingBanedTeams" class="loading-wrapper">
        <b-spinner class="spinner"></b-spinner>
      </div>
      <b-table
          v-else
          responsive
          show-empty
          stacked="md"
          fixed
          :striped="striped"
          :bordered="bordered"
          :fields="fields"
          :items="teamsBanList"
          :current-page=0
          :per-page=10
          class="mb-5"
      >
        <template #cell(teamName)="{item}">
          {{ item.name }}
        </template>
        <template #cell(contact)="{item}">

          <div v-for="captain in item.captain.socialAccounts" :key="captain.id">
            <a target="_blank"
               :href="getAccountsLinks(captain.provider, captain.data.email, captain.data.username, captain.provider_id).provider">
              {{ captain.provider }}
            </a>
          </div>
        </template>
        <template #cell(title)="{item}">
          <div v-for="{game_name, id} in item.currentBans" :key="id">
            {{ game_name }}
          </div>
        </template>
        <template #cell(start)="{item}">
          <div v-for="{from, id} in item.currentBans" :key="id">
            {{ from }}
          </div>
        </template>
        <template #cell(end)="{item}">
          <div v-for="{to, id} in item.currentBans" :key="id">
            {{ to }}
          </div>
        </template>
        <template #cell(reason)="{item}">
          <div v-for="{description, id} in item.currentBans" :key="id">
            {{ description }}
          </div>
        </template>
        <template #cell(who)="{item}">
          <div v-for="{author, id} in item.currentBans" :key="id">
            {{ author.username }}
          </div>
        </template>
        <template #cell(action)="{item}">
          <div class="d-flex flex-wrap">
            <div v-for="action in item.currentBans" :key="action.id" class="mb-2 mr-1">
              <b-btn variant="warning p-1" @click="deleteTeamBan({teamId: action.id})"><i
                  class="fa fa-ban"></i>
              </b-btn>
            </div>
            <b-btn variant="primary" class="mr-1 p-1 mb-2" @click="showTeamInfo(item.id)"><i
                class="fa fa-eye"></i></b-btn>
          </div>

        </template>
      </b-table>
      <b-col md="6" class="p-0">
        <b-pagination
            @change="handlePageChange"
            v-model="banTeamsCurrentPage"
            :total-rows="banTeamsTotalPagination"
            :per-page="10"
            class="my-0"
        ></b-pagination>
      </b-col>
    </b-card>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import {TeamBanAdapterMixin} from "@/mixins/TeamBan/TeamBanAdapterMixin";
import {TeamsAdapterMixin} from "@/mixins/Teams/TeamsAdapterMixin";
import {ACCOUNTS} from '@/constants/SocialAccounts/providers';
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: "ListTeamsBan",
  components: {SearchInput},
  mixins: [PaginationHelperMixin, TeamBanAdapterMixin, TeamsAdapterMixin],
  created() {
    (async () => {
      try {
        this.isLoadingBanedTeams = true
        this.recordPageToStorage(this.banTeamsCurrentPage, this.pageKey)
        await this.indexTeamsBan({
          pageNumber: this.getCurrentPageNumber(this.pageKey),
        })
      } finally {
        this.isLoadingBanedTeams = false
      }
    })()
  },
  data() {
    return {
      searchForBanedTeamsModel: '',
      pageKey: 'pageNumberBanTeams',
      fields: [
        {key: 'id', label: '№', sortable: true,},
        {key: 'teamName', label: 'Название команды', sortable: true,},
        {key: 'contact', label: 'Контакт капитана', sortable: true,},
        {key: 'title', label: 'Тайтл игры', sortable: true,},
        {key: 'start', label: 'Дата начала бана', sortable: true,},
        {key: 'end', label: 'Дата окончания бана', sortable: true,},
        {key: 'reason', label: 'Причина бана', sortable: true,},
        {key: 'who', label: 'Кто забанил', sortable: true,},
        {key: 'action', label: 'Действия', sortable: false},
      ],
      striped: true,
      bordered: true,
      isLoadingBanedTeams: false
    };
  },
  methods: {
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.indexTeamsBan({
        pageNumber: pageNumber,
        ...(this.searchForBanedTeamsModel && {search: this.searchForBanedTeamsModel}),
      })
    },
    getFilteredBanedTeams() {
      this.isLoadingBanedTeams = true
      this.indexTeamsBan({search: this.searchForBanedTeamsModel})
          .then(() => {
            this.isLoadingBanedTeams = false
          })
    },
    getAccountsLinks(account, userDataForLink) {
      return {
        provider: `${ACCOUNTS[account]}${userDataForLink}`,
      }
    }
  },
}
</script>
