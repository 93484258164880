<template>
  <div class="row">
  <div class="col-md-6 col-12" v-for="(team,index) in teamSlots" :key="index">
    <div class="card" >
      <div class="card-body">
        <div  >
          <div>
            <div class="mb-2">
              Слот: {{team.groupResults.slot_number}}
            </div>
            <div class="mb-1">
              <img :src="require(`@/assets/images/teamImgSample.jpg`)" alt="team" height="35" width="37">
              {{ team.name}}
            </div>
            <b-table
                :fields="userTableFields"
                striped hover :items="team.tournamentMembers">
            </b-table>
          </div>
        </div>
      </div>
    </div>

  </div>

  </div>
</template>

<script>
import {TournamentStandingsAdapterMixin} from "../../../mixins/Tournament/TournamentStandingsAdapterMixin";

export default {
  name: 'SlotsStandingsTournament',
  mixins:[TournamentStandingsAdapterMixin],
  data() {
    return {
      userTableFields: [
        {key: 'id', label: 'id', sortable: false,},
        {key: 'user.gameProfiles[0].fields.nick', label: 'nick', sortable: false,},
        {key: 'user.gameProfiles[0].fields.pubg_id', label: 'pubg_id', sortable: false,},
      ],
    }
  },
}
</script>