<template>
<div>
  <Breadcrumbs main="Модераторы" title="Cоздать модератора"/>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h2 class="mb-2 text-center">Создать модератора</h2>
            <b-form>
              <label class=""><b>Имя</b></label>
              <b-input v-model="newName"></b-input>

              <label class="mt-2"><b>Должность</b></label>
              <b-input v-model="newJob"></b-input>

              <label class="mt-2"><b>Тайтл</b></label>
              <b-input v-model="newTitle"></b-input>

              <label class="mt-2"><b>Почта</b></label>
              <b-input v-model="newEmail"></b-input>

              <label class="mt-2"><b>Пароль</b></label>
              <b-input v-model="newPassword"></b-input>

              <div class="mt-3">
                <label class="font-weight-bold">Доступы</label>
                  <Multiselect :options="permissionsUsers" v-model="newPermissions" label="prefix" track-by="prefix" :multiple="true" placeholder="Выберите доступы" :close-on-select="false">
                  </Multiselect>
              </div>

              <div class="d-flex flex-wrap">
              <b-btn variant="success" class="mt-3 mr-5" @click.prevent="createOrganizerUser">Сохранить</b-btn>
              <b-btn variant="primary" class="mt-3" @click.prevent="$router.go(-1)">Назад</b-btn>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {OrganizerUserAdapterMixin} from "@/mixins/Organizer/OrganizerUsersAdapterMixin";
import Multiselect from "vue-multiselect";

export default {
  name: "OrganizerUsersCreate",
  mixins: [OrganizerUserAdapterMixin],
  components: {Multiselect},
  created() {
    this.getPermissions()
  }
}
</script>

<style>
.multiselect__tags-wrap {
  display: flex !important;
  flex-wrap: wrap !important;

}
.multiselect__tag {
  display: flex !important;
  width: 30% !important;
  color: #000000;
}
</style>