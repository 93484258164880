import adminHttp from "/src/api/adminHttp";
import adminHttpImg from "@/api/adminHttpImg";

const END_POINT = '/api/blog/post';
const V1_END_POINT = '/api/v1/admin/posts';

export default {
    index: (params) => adminHttp.get(END_POINT, {params: params}),
    // New V1 API routes
    show: (id) => adminHttp.get(V1_END_POINT + `/${id}`),
    store: (params) => adminHttpImg.post(V1_END_POINT, params),
    update: (id, data) => adminHttpImg.post(V1_END_POINT + `/${id}`, data),
    delete: (id) => adminHttp.delete(V1_END_POINT + `/${id}`),
}