import {
    CODES,
    DATA_CHANGE,
    DELETE_FOR_TEAM,
    ENTRY_DECLINE,
    ENTRY_STORE,
    GAME_PROFILE_DATA_CHANGED,
    LEAVE_TEAM,
    SOCIAL_ACCOUNT_ATTACHED,
    SOCIAL_ACCOUNT_DETACHED,
    TOOK_PART_TEAM,
    VERIFICATION_PHONE_ATTACHED,
    VERIFICATION_PHONE_DETACHED
} from "@/constants/User/userLogs";
import moment from 'moment'

export function getLogData(data) {
    return {
        [ENTRY_STORE]: getEntryLogData(data),
        [ENTRY_DECLINE]: getEntryLogData(data),
        [GAME_PROFILE_DATA_CHANGED]: getGameProfileLogData(data),
        [DATA_CHANGE]: getProfileChangesLogData(data),
        [SOCIAL_ACCOUNT_ATTACHED]: getSocialAccountLogData(data),
        [SOCIAL_ACCOUNT_DETACHED]: getSocialAccountLogData(data),
        [TOOK_PART_TEAM]: getUserTeamLogData(data),
        [LEAVE_TEAM]: getUserTeamLogData(data),
        [DELETE_FOR_TEAM]: getUserTeamLogData(data),
        [VERIFICATION_PHONE_DETACHED]: getUserPhoneLogData(data),
        [VERIFICATION_PHONE_ATTACHED]: getUserPhoneLogData(data),
    }
}
export function getEntryLogData(data) {
    try {
        return `${CODES[data.item.type.code]}` + '\n' + `${data.item?.entry?.tournament.name || ''}`;
    } catch (e) {
        return ''
    }
}
export function getGameProfileLogData(data) {
    try {
        if(data.item.data.new_fields) {
            const fieldData = data.item?.data
            let oldFieldData = `Было: ${fieldData?.old_fields.nick}, ${fieldData?.old_fields.pubg_id || fieldData?.old_fields.mlbb_id}`
            let newFieldData = `Стало: ${fieldData?.new_fields.nick}, ${fieldData?.new_fields.pubg_id || fieldData?.new_fields.mlbb_id}`

            return `${CODES[data.item.type.code]}` + '\n' + oldFieldData + '\n' + newFieldData
        }
    } catch (e) {
        return ''
    }
}
export function getProfileChangesLogData(data) {
    try {
        return Object.keys(data.item.data).reduce((result, key) => {
            const fieldData = data.item.data[key];
            const birthdayOldData = moment(fieldData.old, 'YYYY-MM-DD').format('DD.MM.YYYY');
            const birthdayNewData = moment(fieldData.new, 'YYYY-MM-DD').format('DD.MM.YYYY');

            const arrayOfDataChanges = [
                `\n${CODES[data.item.type.code]}`,
                `${key}: ${key === 'birthday' ? birthdayOldData : fieldData.old} -> ${key === 'birthday' ? birthdayNewData : fieldData.new}`
            ];

            return result + arrayOfDataChanges.join('\n');
        }, '');
    } catch (e) {
        return ''
    }
}

export function getSocialAccountLogData(data) {
    try {
        return `${CODES[data.item.type.code]}` + '\n'+ `${data.item?.data?.provider || ''}`
    } catch (e) {
        return ''
    }
}
export function getUserTeamLogData(data) {
    try {
        return `${CODES[data.item.type.code]}` + '\n'+ `${data.item?.data?.team_name || ''}`
    } catch (e) {
        return ''
    }
}
export function getUserPhoneLogData(data) {
    try {
        return `${CODES[data.item.type.code]}`
    } catch (e) {
        return ''
    }
}