<template>
  <div>
    <b-textarea :class="$props.editorId"></b-textarea>
  </div>
</template>

<script>
import {PostsAdapterMixin} from "@/mixins/Posts/PostsAdapterMixin";
import {LanguageAdapterMixin} from "@/mixins/Language/LanguageAdapterMixin";
import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";
import 'suneditor/dist/css/suneditor.min.css'

export default {
  name: 'SunEditor',
  mixins: [PostsAdapterMixin, LanguageAdapterMixin],
  data() {
    return {
      editor: null,
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    editorId: {
      type: String,
      default: ''
    }
  },
  mounted() {
    const textarea = document.getElementsByClassName(this.$props.editorId)
    for (let i = 0; i < textarea.length; i++) {
      this.editor = suneditor.create(textarea[i], {
        plugins: plugins,
        height: 300,
        buttonList: [
          [
            'undo', 'redo',
            'font', 'fontSize', 'formatBlock',
            'paragraphStyle', 'blockquote',
            'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript',
            'fontColor', 'hiliteColor', 'textStyle',
            'removeFormat',
            'outdent', 'indent',
            'align', 'horizontalRule', 'list', 'lineHeight',
            'table', 'link', 'image', 'video', 'audio', /** 'math', */ // You must add the 'katex' library at options to use the 'math' plugin.
            /** 'imageGallery', */ // You must add the "imageGalleryUrl".
            'fullScreen', 'showBlocks', 'codeView',
            'preview', 'print', 'save', 'template',
            /** 'dir', 'dir_ltr', 'dir_rtl' */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
          ]
        ],
      })
      this.editor.setContents(this.value)
      this.editor.onInput = (event) => {
        // console.log('event.target.innerHTML ', event.target.innerHTML)
        // console.log('event.target.innerHTML ', event)
        this.$emit('input', event.target.innerHTML)
      }
      this.editor.onImageUploadBefore = (files, info, core, uploadHandler) => {
        (async () => {
          const formData = new FormData();
          formData.append("image", files[0]);
          let response = await this.$store.dispatch('moduleStoreImage/storeImage', formData)
          let result = {
            "result": [{ "url": response.url, "name": files[0].name, "size": files[0].size },]
          };
          uploadHandler(result);
        })();
        uploadHandler();
      }
      this.$nextTick(() => {
        this.editor.onChange = (htmlContent) => {
          // console.log('event.target.innerHTML ', event.target.innerHTML)
          // console.log('event.target.innerHTML ', event)
          this.$emit('input', htmlContent)
          // this.$emit('input', event.target.innerHTML)
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "suneditor/dist/css/suneditor.min.css";
</style>