<template>
  <div class="basic-delete-team-tournament-wrapper">
    <h5>Вы действительно хотите удалить {{ params.groupTeam.team.name }}?</h5>
    <h5 class="font-weight-bold mt-3">Укажите причину:</h5>
    <div v-for="reason in reasonsForDelete" :key="'reasonId' + reason.id" class="form-check">
      <input class="form-check-input"
             type="radio" name="reasonsForDelete"
             :id="reason.id"
             v-model="formModel.reject_description"
             :value="reason.text">
      <label class="form-check-label" :for="reason.id">{{reason.text}}</label>
    </div>
    <footer>
      <b-button @click="submitForm" :disabled="isLoadingSaveForm">
        {{ isLoadingSaveForm ? 'Удаляем...' : 'Удалить' }}
      </b-button>
    </footer>
    <b-alert v-if="errorText" show variant="danger" style="margin: 10px 0 0 0">{{ errorText }}</b-alert>
  </div>
</template>

<script>
import api from "@/api/api";
import {mapState} from 'vuex'
import {reasonsForDelete} from '@/constants/reasonsForDeleteTeam';

export default {
  name: "BasicDeleteTeamTournament",
  props: {
    params: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      reasonsForDelete,
      isLoadingSaveForm: false,
      errorText: null,
      formModel: {
        reject_description: ''
      }
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['selectedGroupId', 'selectedRoundId']),
  },
  methods: {
    async submitForm() {
      try {
        this.isLoadingSaveForm = true
        await api.teams.deleteTeamFromGroup(
            this.params.groupTeam.id,
            {...(this.formModel.reject_description && {delete_description: this.formModel.reject_description})})
        await this.$store.dispatch('moduleNewTournament/getGroupsWithTeamsByMLBB', {
          roundId: this.selectedRoundId
        })
        this.$emit('closeModal')
      } catch (e) {
        const message = e.response.data.message
        let errors = ''
        if (e.response.data.errors) errors = Object.entries(e.response.data.errors).map(([ name, values ]) => `${ name }: ${ values.join(', ') }`).join('. ')
        this.errorText = `${ message }; ${ errors }.`
      } finally {
        this.isLoadingSaveForm = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.basic-delete-team-tournament-wrapper {
  h5 {
    font-size: 16px;
    font-weight: 400;
  }
  footer {
    border-top: 1px solid #dee2e6;
    padding-top: 15px;
    margin-top: 15px;
    text-align: right;
  }
}
</style>