import adminHttp from "@/api/adminHttp";

const END_POINT = '/api/v1/admin/wow-streamers';
const STREAMS_END_POINT = '/api/v1/admin/wow-streams'

export default {
    /**
     * POST: Create Wow Streamer
     * @param userId
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    create: (userId, params) => adminHttp.post(`${END_POINT}/${userId}/wow-streamers`, params),

    /**
     * PUT: Update Wow Streamer
     * @param streamerId
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    update: (streamerId, params) => adminHttp.put(`${END_POINT}/${streamerId}`, params),

    /**
     * GET: Get all Wow Streamers
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAll: (params) => adminHttp.get(END_POINT, {params: params}),

    /**
     * GET: Get Wow Streamer entries
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    getEntries: (params) => adminHttp.get(`${END_POINT}/entries`, { params: params }),

    /**
     * GET: Get approved Wow Streamers
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    getApproved: (params) => adminHttp.get(`${END_POINT}/approved`, { params }),

    /**
     * GET: Get rejected Wow Streamers
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    getRejected: (params) => adminHttp.get(`${END_POINT}/rejected`, { params }),

    /**
     * GET: Get banned Wow Streamers
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    getBanned: (params) => adminHttp.get(`${END_POINT}/banned`, { params }),

    /**
     * GET: Get one Wow Streamer
     * @param streamerId
     * @returns {Promise<AxiosResponse<any>>}
     */
    getOne: (streamerId) => adminHttp.get(`${END_POINT}/${streamerId}`),

    /**
     * DELETE: Delete Wow Streamer
     * @param streamerId
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete: (streamerId) => adminHttp.delete(`${END_POINT}/${streamerId}`),

    /**
     * PATCH: Ban Wow Streamer
     * @param streamerId
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    ban: (streamerId, params) => adminHttp.patch(`${END_POINT}/${streamerId}/ban`, params),

    /**
     * PATCH: Unban Wow Streamer
     * @param streamerId
     * @returns {Promise<AxiosResponse<any>>}
     */
    unban: (streamerId) => adminHttp.patch(`${END_POINT}/${streamerId}/unban`),

    /**
     * PATCH: Approve Wow Streamer
     * @param streamerId
     * @returns {Promise<AxiosResponse<any>>}
     */
    approve: (streamerId) => adminHttp.patch(`${END_POINT}/${streamerId}/approve`),

    /**
     * PATCH: Reject Wow Streamer
     * @param streamerId
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    reject: (streamerId, params) => adminHttp.patch(`${END_POINT}/${streamerId}/reject`, params),

    /**
     * PATCH: Deactivate Wow Stream
     * @param streamId
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    deactivate: (streamId, params) => adminHttp.patch(`${STREAMS_END_POINT}/${streamId}`, params),
}