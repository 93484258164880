<template>
  <div>
    <Breadcrumbs main="Трансферы" title="Трансферы пользователей"/>
    <div class="container">
      <b-card>
        <div class="pb-4">
          <SearchInput v-model="search" @search="() => handleLogsPageChange(1)" :placeholder-name="'Поиск'"/>
          <div class="notification-for-search">Поиск по имени пользователя</div>
        </div>
        <b-table
            responsive
            show-empty
            stacked="md"
            :striped="isStriped"
            :bordered="isBordered"
            :fields="fieldsLogs"
            :items="userTransferLogs"
            :current-page=1
            :per-page=10
            @sort-changed="sortByDate"
        >
          <template #cell(date)="data">
            {{ data.item.created_at ? moment(data.item.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm') : '' }}
          </template>
          <template #cell(type)="data">
            {{ data.item.type.code }}
          </template>
          <template #cell(description)="data">
            <pre class="description">{{ getCodeStateMessage(data.item.type.code, data).log }}</pre>
          </template>
          <template #cell(who)="data">
            {{ data.item?.author.username }}
          </template>
        </b-table>
        <b-col md="6" class="p-0">
          <b-pagination
              @change="handleLogsPageChange"
              v-model="logsCurrentPage"
              :total-rows="totalPagesOfTransferLogs"
              :per-page="10"
              class="my-0 mt-5"
          ></b-pagination>
        </b-col>
      </b-card>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import {getLogData} from "@/common/userLogs";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import moment from 'moment'
import Search from "@/components/header.vue";
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: 'UserTransferLog',
  components: {SearchInput, Search},
  mixins: [PaginationHelperMixin],
  data() {
    return {
      moment,
      fieldsLogs: [
        {key: 'id', label: '№',},
        {key: 'date', label: 'Дата', sortable: true},
        {key: 'type', label: 'Тип'},
        {key: 'description', label: 'Описание',},
        {key: 'who', label: 'Пользователь',}
      ],
      userTransferLogs: [],
      isStriped: true,
      isBordered: true,
      logsPageKey: 'userTransferLogsPageNumber',
      totalPagesOfTransferLogs: this.logsCurrentPage * 10,
      logsCurrentPage: 1,
      sort: 'desc',
      search: ''
    }
  },
  created() {
    this.handleLogsPageChange(this.getCurrentPageNumber(this.logsPageKey))
  },
  methods: {
    getCodeStateMessage(code, data) {
      return {log: `${getLogData(data)[code]}`}
    },
    async handleLogsPageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.logsPageKey)
      const response = await api.users.userTransferLogs({
        page: pageNumber,
        search: this.search || undefined,
        sort: this.sort
      })
      this.userTransferLogs = response.data.user_transfer_logs || []
      this.totalPagesOfTransferLogs = response.data.pagination.total
      this.logsCurrentPage = pageNumber
    },
    async sortByDate() {
      this.sort = this.sort === 'asc' ? 'desc' : 'asc'
      await this.handleLogsPageChange(1)
    }
  },
}
</script>

<style scoped>
.description {
  background-color: white;
  padding: 0;
}
</style>