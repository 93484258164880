import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types";

export default {
    namespaced: true,
    state: () => ({
        watches: [],
        mainBanner: null,
        currentWatch: null,
    }),
    mutations: {
        [mutypes.SET_WATCHES]: (state, watches) => state.watches = watches,
        [mutypes.SET_MAIN_BANNER]: (state, mainBanner) => state.mainBanner = mainBanner,
        [mutypes.SET_CURRENT_WATCH]: (state, watch) => state.currentWatch = watch,
        [mutypes.ADD_WATCH]: (state, watch) => state.watches.push(watch),
        [mutypes.UPDATE_WATCH]: (state, updatedWatch) => {
            const index = state.watches.findIndex(w => w.id === updatedWatch.id);
            if (index !== -1) {
                state.watches.splice(index, 1, updatedWatch);
            }
        },
        [mutypes.REMOVE_WATCH]: (state, watchId) => {
            state.watches = state.watches.filter(w => w.id !== watchId);
        },
    },
    actions: {
        async fetchWatches({ commit }) {
            try {
                const response = await api.watch.index();

                if (response.data.data) {
                    const watches = response.data.data.watches || [];
                    const mainBanner = response.data.data.main_banner || null;
                    
                    commit(mutypes.SET_WATCHES, watches);
                    commit(mutypes.SET_MAIN_BANNER, mainBanner);
                } else {
                    console.error('No data in the API response');
                }
            } catch (error) {
                console.error('Error fetching watches:', error);
            }
        },
        async createWatch({ commit }, watchData) {
            try {
                const response = await api.watch.store({ watch: watchData });
                commit(mutypes.ADD_WATCH, response.data.watch);
                return response.data.watch;
            } catch (error) {
                console.error('Error creating watch:', error);
            }
        },
        async setMainBanner({ commit }, watchData) {
            try {
                const response = await api.watch.setMainBanner({ watch: watchData });
                commit(mutypes.UPDATE_WATCH, response.data.watch);
                return response.data.watch;
            } catch (error) {
                console.error('Error setting main banner:', error);
            }
        },
        async updateWatch({ commit }, { id, watchData }) {
            try {
                const response = await api.watch.update(id, { watch: watchData });
                commit(mutypes.UPDATE_WATCH, response.data.watch);
                return response.data.watch;
            } catch (error) {
                console.error('Error updating watch:', error);
            }
        },
        async deleteWatch({ commit }, id) {
            try {
                await api.watch.delete(id);
                commit(mutypes.REMOVE_WATCH, id);
            } catch (error) {
                console.error('Error deleting watch:', error);
            }
        },
    },

    getters: {
        allWatches: state => state.watches,
        currentWatch: state => state.currentWatch,
        getWatchById: (state) => (id) => {
            return state.watches.find(watch => watch.id === id);
        },
    },
};
