import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/menu';

export default {
    /**
     * GET: menus for user role
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    index:()=> adminHttp.get(END_POINT),
}