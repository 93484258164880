<template>
    <div class="modalTech" v-if="getShow" @click.self="closeModal">
        <div class="modalTech_block d-flex">
            <div class="modalTech_window">
                <div class="d-flex justify-content-end">
                    <button class="btn" @click="closeModal">
                        <img src="@/assets/images/bi_x.svg">
                    </button>
                </div>
                <div class="text-center">
                    <h1>Предупреждение</h1>
                    <p>{{ getOption }}</p>
                </div>
                <div class="d-flex justify-content-center mt-5">
                    <button class="btn btn-success mr-3" @click="acceptAndClose">Okay</button>
                    <button class="btn btn-warning" @click="closeModal()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";

export default {
    name: 'ModalWindow',
    mixins: [ModalAdapterMixin],
    methods: {
        //@TODO Переписать этот метод
        async acceptAndClose() {
            if (this.getOption === 'Вы действительно хотите удалить турнир?') {
                await this.deleteTournament(this.getIdModalForDelete).then(() => {
                    this.closeModal()
                    this.cleanOption()
                })
            } else if (this.getOption === 'Вы действительно хотите удалить помощь?') {
                await this.deleteHelpDesk(this.getIdModalForDelete).then(() => {
                    this.closeModal()
                    this.cleanOption()
                })
            } else if (this.getOption === 'Вы действительно хотите удалить этого пользователя?') {
                await this.deleteFakeUser(this.getIdModalForDelete).then(() => {
                    this.closeModal()
                    this.cleanOption()
                })
            } else if (this.getOption === 'Вы действительно хотите удалить эту команду?') {
                await this.deleteTeams(this.getIdModalForDelete).then(() => {
                    this.closeModal()
                    this.cleanOption()
                })
            } else if (this.getOption === 'Вы действительно хотите удалить заявку?') {
                await this.deleteTournamentEntry(this.getIdModalForDelete).then(() => {
                    this.closeModal()
                    this.cleanOption()
                })
            } else if (this.getOption === 'Вы действительно хотите удалить из этапа эту(-и) команду(-ы)?') {
                await this.deleteTournamentEntry(this.getIdModalForDelete).then(() => {
                    this.closeModal()
                    this.cleanOption()
                })
            }

        },
    }
}
</script>
