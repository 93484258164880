<template>
  <div>
    <Breadcrumbs main="Прочее" title="Новости"></Breadcrumbs>
    <div>
      <b-modal centered id="modal-StageDelete" title="Предупреждение" v-model="show">
        <p class="my-4">Вы действительно хотите удалить эту новость?</p>
        <template #modal-footer>
          <div>
            <b-btn variant="primary" class="mr-3" @click="deleteBlogPostBtn(postId)">
              <b-icon icon="check2-circle" aria-hidden="true"></b-icon> Подтвердить
            </b-btn>
            <b-btn variant="danger" @click="show=false">
              <b-icon icon="x-circle" aria-hidden="true"></b-icon> Отмена
            </b-btn>
          </div>
        </template>
      </b-modal>
    </div>
    <div class="container">
      <b-card>
        <b-card-header>
          <div class="row justify-content-between align-items-center">
            <div>
              <b-btn variant="primary" @click="$router.push({name: 'posts_create'})">
                Добавить новость
              </b-btn>
            </div>
          </div>
        </b-card-header>
        <b-card-body>
          <b-table :fields="fields" :items="getPosts">
            <template #cell(title)="data">
              {{ data.item.title.ru }}
            </template>
            <template #cell(actions)="data">
              <div class="action-buttons">
                <b-btn variant="primary" class="action-btn mb-2" @click="showBlogPostsPage(data.item.id)">
                  <b-icon icon="pencil-square" aria-hidden="true"></b-icon> Редактировать
                </b-btn>
                <b-btn variant="danger" class="action-btn mb-2" @click.prevent="deletePost(data.item.id)">
                  <b-icon icon="trash" aria-hidden="true"></b-icon> Удалить
                </b-btn>
              </div>
            </template>
          </b-table>
        </b-card-body>
        <b-col md="6" class="p-0">
          <b-pagination
            @change="handlePageChange"
            v-model="currentPage"
            :total-rows="totalPosts"
            :per-page="10"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-card>
    </div>
  </div>
</template>

<script>
import { PostsAdapterMixin } from "@/mixins/Posts/PostsAdapterMixin";
import { BIcon, BIconPlusCircle, BIconPencilSquare, BIconTrash, BIconCheck2Circle, BIconXCircle } from 'bootstrap-vue';

export default {
  name: 'PostsList',
  components: {
    BIcon,
    BIconPlusCircle,
    BIconPencilSquare,
    BIconTrash,
    BIconCheck2Circle,
    BIconXCircle
  },
  mixins: [PostsAdapterMixin],
  mounted() {
    this.indexBlogPosts()
  },
  data() {
    return {
      fields: [
        {key: 'id', label: '№',},
        {key: 'title', label: 'Заголовок',},
        {key: 'actions', label: 'Инструменты',},
      ],
      show: false,
      postId: null,
    }
  },
  methods: {
    deletePost(id) {
      this.show = true
      this.postId = id
    },
    deleteBlogPostBtn(id){
      this.deleteBlogPost(id)
      this.show = false
    },
    handlePageChange(pageNumber) {
      this.indexBlogPosts(pageNumber).then(function (data) {
        return data;
      }.bind(this));
    },
  },
}
</script>

<style scoped>
.action-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0.5rem;
}

.action-btn {
  flex: 1 0 140px;
  max-width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-btn .b-icon {
  margin-right: 0.5rem;
}

@media (max-width: 576px) {
  .action-buttons {
    flex-direction: column;
  }

  .action-btn {
    flex: 1 0 auto;
    max-width: none;
    width: 100%;
  }
}
</style>