<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Рейтинг" title="История рейтинга команд турнира"/>
      <b-card>
        <div>
          <b-form>
            <div v-if="isLoading" class="loading-wrapper">
              <b-spinner class="spinner"></b-spinner>
            </div>
            <div v-else>
              <b-table
                  responsive
                  show-empty
                  stacked="md"
                  :striped="striped"
                  :bordered="bordered"
                  :fields="fields"
                  :items="tournamentTeamsRating"
                  :current-page=0
                  :per-page=10
                  class="mb-5"
              >
                <template v-slot:cell(id)="{item}">
                  {{ item.id }}
                </template>
                <template v-slot:cell(team)="{item}">
                  {{ item.name }}
                </template>
                <template v-slot:cell(rating)="{item}">
                  {{ item.tournament_rating_points }}
                </template>
                <template #cell(details)="data">
                  <div class="d-flex justify-content-center">
                    <b-button size="lg" @click="showTeamRatingsDetails(data)" class="px-1 py-0 d-flex" variant="none">
                      <i class="fa fa-arrow-circle-o-down"></i>
                    </b-button>
                  </div>
                </template>
                <template #row-details="data">
                  <div class="card p-0 m-0">
                    <b-table :items="tournamentTeamShowRating" :fields="oneTeamRatingsFields" striped hover responsive
                             show-empty>
                      <template #cell(stage)="data">
                        {{ data.item?.ratingStage.name }}
                      </template>
                      <template #cell(group)="data">
                        {{ data.item?.ratingGroup.name }}
                      </template>
                      <template #cell(coeff_rating)="data">
                        {{ data.item?.ratingStage.rating_coefficient }}
                      </template>
                      <template #cell(coeff_personal_rating)="data">
                        {{ data.item?.ratingStage.player_rating_coefficient }}
                      </template>
                      <template #cell(matches_count)="data">
                        {{ data.item?.ratingStage.match_count }}
                      </template>
                      <template #cell(point)="data">
                        {{ data.item?.point }}
                      </template>
                      <template #cell(date)="data">
                        {{ data.item.created_at }}
                      </template>
                    </b-table>
                  </div>
                </template>
              </b-table>
              <b-col md="6" class="p-0">
                <b-pagination
                    @change="handlePageChange"
                    v-model="tournamentTeamsRatingCurrentPage"
                    :total-rows="tournamentTeamsRatingTotal"
                    :per-page="10"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </div>
          </b-form>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import {TournamentsRatingAdapterMixin} from "@/mixins/Rating/TournamentsRatingAdapterMixin";

export default {
  name: "TournamentTeamsRatingInfo",
  mixins: [PaginationHelperMixin, TournamentsRatingAdapterMixin],
  created() {
    this.isLoading = true
    this.recordPageToStorage(this.tournamentTeamsRatingCurrentPage, this.pageKey)
    this.grabTournamentTeamsRating({
        pageNumber: this.getCurrentPageNumber(this.pageKey),
        tournId: this.$route.params.id,
    }).then(() => this.isLoading = false)
  },
  data() {
    return {
      pageKey: 'tournamentTeamsRatingPageNumber',
      fields: [
        {key: 'id', label: 'id', sortable: true},
        {key: 'team', label: 'Команда', sortable: true},
        {key: 'rating', label: 'Общий рейтинг', sortable: true},
        {key: 'details', label: 'Подробнее', sortable: true},
      ],
      oneTeamRatingsFields: [
        {key: 'id', label: 'id', sortable: true},
        {key: 'stage', label: 'Стадия', sortable: true},
        {key: 'group', label: 'Группа', sortable: true},
        {key: 'coeff_rating', label: 'Коэфф. рейтинга', sortable: true},
        {key: 'coeff_personal_rating', label: 'Коэфф. личного рейтинга', sortable: true},
        {key: 'matches_count', label: 'Кол-во матчей', sortable: true},
        {key: 'point', label: 'Рейтинг', sortable: true},
        {key: 'date', label: 'Дата', sortable: true},
      ],
      striped: true,
      bordered: true,
      isLoading: false
    };
  },
  methods: {
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.grabTournamentTeamsRating({
          pageNumber: pageNumber,
          tournId: this.$route.params.id,
      })
    },
    showTeamRatingsDetails(data) {
      this.showTournamentTeamInfoRating({tournId: this.$route.params.id, teamId: data.item.id});
      data.toggleDetails();
    },
  }
};
</script>