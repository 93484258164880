import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/dashboard';

export default {
    /**
     * GET: dashboard
     * @returns {Promise<AxiosResponse<any>>}
     */
    index: () => adminHttp.get(END_POINT + '/analytics'),

    /**
     * GET: tournament teams analytics
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchTournamentTeamsAnalytics: () => adminHttp.get(END_POINT + '/analytics/tournament-teams'),

    /**
     * GET: not logged users count
     * Пользователи, не логинившиеся больше 12/6/3/1 мес - Новое выводить - Позвать когда приступишь к задаче
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchNotLoggedUsersCount: () => adminHttp.get(END_POINT +'/analytics/not-logged-users-count'),
    /**
     * GET: last quarter logged users count
     * Вход пользователей за последний квартал - Кол-во юзеров логинившихся за последний квартал
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchLastQuarterLoggedUsersCount: () => adminHttp.get(END_POINT + '/analytics/last-quarter-logged-users-count'),
    /**
     * GET: users can participate in tournament count
     * Турнирные пользователи (уже есть такая колонка, надо заменить и добавить кнопку вывода в эксель)
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchUsersCanParticipateInTournamentCount: () => adminHttp.get(END_POINT + '/analytics/users-can-participate-in-tournament-count'),
    /**
     * GET: tournament participated teams count
     * Команды принявшие участие на турнире - Кол-во команд, которые хоть раз принимали участие
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchTournamentParticipatedTeamsCount: () => adminHttp.get(END_POINT + '/analytics/tournament-participated-teams-count'),
    /**
     * Турнирные команды - Кол-во команд, кто проходят по требованиям
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchTeamsCanParticipateInTournamentCount: () => adminHttp.get(END_POINT + '/analytics/teams-can-participate-in-tournament-count'),
     /**
     * GET: team members count mean
     * Игроков в команде - Среднее количество игроков в команде
     * @returns {Promise<AxiosResponse<any>>}
     */
     fetchTeamMembersCountMean: () => adminHttp.get(END_POINT + '/analytics/team-members-count-mean'),
    /**
     * GET: tournament with fully registered teams count
     * Заполненные турниры - Все турниры, где был добор команд (принятые заявки на турнир/доступные места)
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchTournamentWithFullyRegisteredTeamsCount: () => adminHttp.get(END_POINT + '/analytics/tournament-with-fully-registered-teams-count'),
    
    /**
     * GET: played duo teams count
     * Доля команд, которые принимали участие на турнирах DUO
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchPlayedDuoTeamsCount: () => adminHttp.get(END_POINT + '/analytics/played-duo-teams-count'),

}
