import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            entry: {},
        }
    },
    mutations: {
        [mutypes.SET_TOURNAMENT_ENTRY]: (state, value) => state.entry = value,
    },

    getters: {
        getTournamentEntry: state => state.entry,
    },

    actions: {
        showTournamentEntry: async ({commit, getters}, params) => {
            const response = await api.tournament.grabTournamentEntry({
                tournamentId: params.tournamentId,
                entryId: params.entryId
            });
            commit(mutypes.SET_TOURNAMENT_ENTRY, response.data.entry);

            return getters.getTournamentEntries;
        },
        changeTournamentEntryStatus: async (_, params) => {
            let response;
            if (params.status == 2) {
                response = await api.tournament.acceptTournamentEntry(params.entryId);
            } else {
                response = await api.tournament.rejectTournamentEntry({
                    id: params.entryId,
                    entryId: params.entryId,
                    status: params.status,
                    reject_description: params.reject_description
                });
            }

            return response.status;
        },

    }
}
