import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            matches: [],
            matchResults: [],
            teamResultsPlacePts: [],
            selectedMatchId: null,
            resultTeamId: null,
        }
    },
    mutations: {
        [mutypes.SET_TOURNAMENT_GROUP_RESULT_MATCHES]: (state, value) => state.matches = value,
        [mutypes.SET_TOURNAMENT_GROUP_RESULT_PlACE_PTS]: (state, value) => state.teamResultsPlacePts = value,
        [mutypes.SELECT_TOURNAMENT_GROUP_RESULT_MATCH_ID]: (state, value) => state.selectedMatchId = value,
        [mutypes.SET_TOURNAMENT_GROUP_MATCH_RESULTS]: (state, value) => state.matchResults = value,
        [mutypes.SET_TOURNAMENT_GROUP_MATCH_RESULT_TEAM_ID]: (state, value) => state.resultTeamId = value,
    },

    getters: {
        getGroupResultMatches: state => state.matches,
        getGroupMatchResults: state => state.matchResults,
        getSelectedMatchId: state => state.selectedMatchId,
        getResultTeamId: state => state.resultTeamId,
        getResultTeamPlacePts: state => state.teamResultsPlacePts,
    },

    actions: {
        async showMatchesResult({commit, getters}, id) {
            let response = await api.tournamentGroupResult.show(id);

            let matches = [];
            const players = response.data.tournament_members;
            let currentResults = [];
            let currentPlacePtsResult = {};

            response.data.group_matches.forEach((match) => {
                match = {
                    id: match.id,
                    name: match.name,
                    team_id: response.data.group_result.team_id,
                    start_time: match.start_time,
                    group_id: match.group_id,
                };

                // добавляем резы по одному матчу
                const groupMatchResult = response?.data?.group_result?.groupMatchResults
                    ?.find((groupMatchResult) => groupMatchResult.match_id === match.id);

                // проходимся по всем игрокам этой группы и если нет резов забиваем нулевые резы
                match.player_results = [];
                players.forEach((player) => {
                    let playerResult = null;

                    if (groupMatchResult?.groupMatchResultPlayers) {
                        playerResult = groupMatchResult?.groupMatchResultPlayers?.find((resultPlayer) => {
                            return resultPlayer.user_id === player.user_id;
                        });
                    }

                    if (!playerResult) {
                        match.player_results.push({
                            user_id: player.user_id,
                            kill_pts: 0,
                            team_id: match.team_id,
                            match_id: match.id,
                            place_pts: 0,
                            user: player.user
                        })

                        currentResults.push({
                            match_id: match.id,
                            user_id: player.user_id,
                            team_id: match.team_id,
                            kill_pts: 0,
                            place_pts: 0
                        })

                    } else {
                        currentResults.push({
                            id: playerResult.id,
                            match_id: match.id,
                            user_id: playerResult.user_id,
                            team_id: match.team_id,
                            kill_pts: playerResult.kill_pts,
                            place_pts: playerResult.place
                        })
                        match.player_results.push(playerResult);
                    }
                });
                match.place_pts = match?.player_results[0]?.place || 0;

                currentPlacePtsResult[match.id] = match.place_pts;


                matches.push(match);
            });

            commit(mutypes.SET_TOURNAMENT_GROUP_RESULT_PlACE_PTS, currentPlacePtsResult);

            commit(mutypes.SET_TOURNAMENT_GROUP_MATCH_RESULTS, currentResults);
            commit(mutypes.SET_TOURNAMENT_GROUP_RESULT_MATCHES, matches);
            commit(mutypes.SET_TOURNAMENT_GROUP_MATCH_RESULT_TEAM_ID, response.data.group_result.team_id);
        },
        clearMatchesResultState({commit, getters}) {
            commit(mutypes.SET_TOURNAMENT_GROUP_MATCH_RESULTS, []);
            commit(mutypes.SET_TOURNAMENT_GROUP_RESULT_MATCHES, []);
            commit(mutypes.SET_TOURNAMENT_GROUP_MATCH_RESULT_TEAM_ID, null);
            commit(mutypes.SET_TOURNAMENT_GROUP_RESULT_PlACE_PTS, []);
        },

        addPlayerResult({commit, getters}, params) {
            let currentResults = getters.getGroupMatchResults;

            currentResults = currentResults.map((result) => {
                if (result.match_id === params.match_id
                    && result.user_id === params.user_id) {

                    result.kill_pts = params.kill_pts;
                }
                return result;
            })

            commit(mutypes.SET_TOURNAMENT_GROUP_MATCH_RESULTS, currentResults);
        },
        addResultTeamPlacePts({commit, getters}, params) {
            let currentPlacePtsResult = getters.getResultTeamPlacePts;

            currentPlacePtsResult[params.match_id] = params.place_pts;

            commit(mutypes.SET_TOURNAMENT_GROUP_RESULT_PlACE_PTS, currentPlacePtsResult);
        },

        async storeMatchesResult({commit, getters}, id) {
            let matches = getters.getGroupMatchResults;

            matches.map((match) => {

                if (typeof getters.getResultTeamPlacePts[match.match_id] === 'number') {
                    match.place = getters.getResultTeamPlacePts[match.match_id];
                }

                return match;
            })
            return await api.tournamentGroupResult.store(id, {
                matches: matches,
                team_id: getters.getResultTeamId
            });
        },
    }
}