<template>
  <div>
    <Breadcrumbs main="Список пользователей" title="Список пользователей"/>
    <b-card>
      <div class="d-flex">
        <SearchInput v-model="searchForAddPlayersInTeamModel" @search="getFilteredPlayersForAddInTeam()" :placeholder-name="'Поиск пользователей'"/>
      </div>
      <div class="notification-for-search mb-4">Поиск по id, username, почте, названию команды, игровым данным: pubg id pubg nick</div>
      <div v-if="isLoadingUsers" class="loading-wrapper">
        <b-spinner class="spinner"></b-spinner>
      </div>
      <b-table
          v-else
          responsive
          show-empty
          stacked="md"
          :striped="striped"
          :bordered="bordered"
          :fields="fields"
          :items="users"
          :current-page=0
          :per-page=10
          class="mb-5"
      >
        <template #cell(login)="data">
          <div>{{ data.item.username ? data.item.username : 'не указано' }}</div>
        </template>
        <template #cell(pubg_nick)="data">
          <div>{{ data.item.gameProfiles[0]?.fields ? data.item.gameProfiles[0].fields.nick : 'не указано' }}</div>
        </template>
        <template #cell(pubg_id)="data">
          <div>{{ data.item.gameProfiles[0]?.fields ? data.item.gameProfiles[0].fields.pubg_id : 'не указано' }}</div>
        </template>
        <template #cell(status)="data">
          <div>
            <div v-if="data.item.gameProfiles.length <= 0">Не игровой/</div>
            <div v-else>Игровой/</div>
          </div>
          <div>
            <div v-if="data.item.verified_social_accounts_count <= 0">Не активирован/</div>
            <div v-else>Активирован</div>
          </div>
        </template>
        <template #cell(country)="data">
          <div>{{ data.item.country? data.item.country.name : 'не указано' }}</div>
        </template>
        <template #cell(action)="data">
          <b-btn
              type="submit"
              variant="primary"
              @click.prevent="
                                storeTeamMember({
                                    teamId:$route.params.id,
                                    userId:data.item.id,
                                    roleId:2
                                })"
              :id="'addPlayer' + data.item.id"
          >
            Добавить игрока
          </b-btn>
        </template>
      </b-table>

      <b-col md="6" class="p-0">
        <b-pagination
            @change="handlePageChange"
            v-model="currentPage"
            :total-rows="totalUserPagination"
            :per-page="10"
            class="my-0"
        ></b-pagination>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import {UsersAdapterMixin} from "@/mixins/Users/UsersAdapterMixin";
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import {TeamsAdapterMixin} from "@/mixins/Teams/TeamsAdapterMixin";
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: "ListUsers",
  components: {SearchInput},
  mixins: [UsersAdapterMixin, ModalAdapterMixin, PaginationHelperMixin, TeamsAdapterMixin],
  mounted() {
    (async () => {
      try {
        this.isLoadingUsers = true
        this.recordPageToStorage(this.currentPage, this.pageKey)
        await this.grabUsers({
          pageNumber: this.getCurrentPageNumber(this.pageKey),
        })
      } finally {
        this.isLoadingUsers = false
      }
    })()
  },
  data() {
    return {
      searchForAddPlayersInTeamModel: '',
      pageKey: 'pageNumberAddPlayer',
      fields: [
        {key: 'id', label: '№', sortable: true,},
        {key: 'login', label: 'Логин', sortable: true,},
        {key: 'pubg_nick', label: 'Pubg nick', sortable: true,},
        {key: 'pubg_id', label: 'Pubg id', sortable: true,},
        {key: 'status', label: 'Статус', sortable: true,},
        {key: 'country', label: 'Страна', sortable: true,},
        {key: 'action', label: 'Просмотр', sortable: true,},
      ],
      striped: true,
      bordered: true,
      isLoadingUsers: false
    }
  },
  methods: {
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.grabUsers({
        pageNumber: pageNumber,
        ...(this.searchForAddPlayersInTeamModel && {search: this.searchForAddPlayersInTeamModel}),
      })
    },
    getFilteredPlayersForAddInTeam() {
      this.isLoadingUsers = true
      this.grabUsers({search: this.searchForAddPlayersInTeamModel})
          .then(() => {
            this.isLoadingUsers = false
          })
    },
  },
}
</script>
