import { SET_TOURNAMENT_STATISTICS, SET_TEAM_STATISTICS } from '/src/store/mutation.types';
import TournamentStatisticsApi from '@/api/nodes/api.admin.tournament.statistics';

export default {
  namespaced: true,
  state: {
    tournamentStatistics: [],
    teamStatistics: {}
  },
  mutations: {
    [SET_TOURNAMENT_STATISTICS](state, statistics) {
      state.tournamentStatistics = statistics;
    },
    [SET_TEAM_STATISTICS](state, { teamId, statistics }) {
      state.teamStatistics[teamId] = statistics;
    }
  },
  actions: {
    async fetchTournamentStatistics({ commit }, tournamentId) {
      try {
        const statistics = await TournamentStatisticsApi.getTournamentStatistics(tournamentId);
        commit(SET_TOURNAMENT_STATISTICS, statistics);
      } catch (error) {
        console.error('Error fetching tournament statistics:', error);
      }
    },
    async fetchTeamStatistics({ commit }, { tournamentId, teamId, gameId }) {
      try {
        const statistics = await TournamentStatisticsApi.getTournamentTeamStatistics(tournamentId, teamId, gameId);
        commit('SET_TEAM_STATISTICS', { teamId, statistics });
      } catch (error) {
        console.error('Error fetching team statistics:', error);
      }
    }
  },
  getters: {
    tournamentStatistics: state => state.tournamentStatistics,
    teamStatistics: state => teamId => state.teamStatistics[teamId] || []
  }
};