import Vue from "vue";
import Vuex from "vuex";
// import 'es6-promise/auto';
import layout from './modules/layout';
import menu from './modules/menu';
import tournament from './modules/module.tournament';
import tournaments from './modules/module.tournaments';
import moduleTournamentStage from "./modules/module.tournamentStage";
import moduleTournamentStandings from "./modules/module.tournament.standings";
import moduleTournamentStageGroup from "./modules/module.tournament.stage.group";
import moduleTournamentMatches from "./modules/module.tournament.matches";
import moduleTournamentGroupResults from './modules/module.tournament.group.results';
import moduleTeams from "@/store/modules/module.teams";
import moduleHelp from '@/store/modules/module.help'
import modulePosts from "@/store/modules/module.posts";
import moduleTournamentEntries from "./modules/module.tournament.entries";
import moduleTournamentEntry from "./modules/module.tournament.entry";
import moduleOrganizerUsers from "./modules/module.organizer.users";
import moduleAuth from './modules/module.auth';
import moduleUsersFake from "./modules/module.users.fake";
import moduleUsers from "./modules/module.users";
import moduleTeamsBan from "@/store/modules/module.teams.ban";
import moduleUserFake from "@/store/modules/module.user.fake";
import moduleRating from "@/store/modules/module.rating";
import moduleOrganizers from "@/store/modules/module.organizers";
import modulePlatforms from "@/store/modules/module.platforms";
import moduleBook from "@/store/modules/module.book";
import moduleTournamentsOuter from "@/store/modules/module.tournaments.outer";
import moduleTournamentOuter from "@/store/modules/module.tournament.outer";
import moduleSeo from '@/store/modules/module.seo'
import moduleDashboard from "@/store/modules/module.dashboard";
import moduleFeatures from "@/store/modules/module.features";
import moduleNewsletter from "@/store/modules/module.newsletter";
import moduleDivisions from "@/store/modules/module.divisions";
import moduleRoles from "@/store/modules/module.roles"
import moduleUserBan from "@/store/modules/module.user.ban";
import moduleUsesRating from "@/store/modules/module.users.rating";
import moduleTournamentsRating from "@/store/modules/module.tournaments.rating";
import moduleNewTournament from "@/store/modules/module.new-tournament";
import moduleStoreImage from "@/store/modules/module.store-image";
import moduleFakeUsers from "@/store/modules/module.fake-users";
import moduleFakeTeams from "@/store/modules/module.fake-teams";
import moduleTournamentStatistics from "@/store/modules/module.tournament.statistics";
import moduleWowStreamers from "@/store/modules/module.wowstreamers";
import moduleWatch from "@/store/modules/module.watch";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import modal from "@/store/modules/modal";
//import "bootstrap-vue/dist/bootstrap.css";
Vue.use(Vuex);
Vue.use(IconsPlugin);
Vue.use(BootstrapVue);
export const store = new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        layout,
        menu,
        tournament,
        tournaments,
        moduleTournamentStage,
        moduleTournamentStageGroup,
        modal,
        moduleTeams,
        moduleUsersFake,
        moduleHelp,
        modulePosts,
        moduleTournamentStandings,
        moduleTournamentMatches,
        moduleTournamentGroupResults,
        moduleAuth,
        moduleTournamentEntries,
        moduleTournamentEntry,
        moduleOrganizerUsers,
        moduleUsers,
        moduleTeamsBan,
        moduleUserFake,
        moduleRating,
        moduleOrganizers,
        modulePlatforms,
        moduleBook,
        moduleTournamentsOuter,
        moduleTournamentOuter,
        moduleSeo,
        moduleDashboard,
        moduleFeatures,
        moduleNewsletter,
        moduleRoles,
        moduleDivisions,
        moduleUserBan,
        moduleUsesRating,
        moduleTournamentsRating,
        moduleNewTournament,
        moduleStoreImage,
        moduleFakeUsers,
        moduleFakeTeams,
        moduleTournamentStatistics,
        moduleWowStreamers,
        moduleWatch
    },
    strict:false
});

