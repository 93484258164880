<template>
  <div class="ban-wow-streamer-wrapper">
    <b-form @submit.prevent="submitForm">
      <div>
        <label for="banStartTime">Дата начала бана</label>
        <datetime format="YYYY-MM-DD H:i" v-model="formModel.banStartTime" />
      </div>
      <div>
        <label for="banEndTime">Дата окончания бана</label>
        <datetime format="YYYY-MM-DD H:i" v-model="formModel.banEndTime" />
      </div>
      <div class="mt-3">
        <label for="banReason">Причина бана</label>
        <b-form-textarea v-model="formModel.banReason" />
      </div>
      <b-alert v-if="errorText" show variant="danger" class="mt-3">{{ errorText }}</b-alert>
      <footer>
        <b-button type="submit" :disabled="isLoadingSaveForm" variant="primary" class="m-1">
          {{ isLoadingSaveForm ? 'В процессе...' : 'ОК' }}
        </b-button>
        <b-button @click="$emit('closeModal')" variant="secondary" class="m-1">
          Отмена
        </b-button>
      </footer>
    </b-form>
  </div>
</template>

<script>
import datetime from "vuejs-datetimepicker";
import { WowStreamersAdapterMixin } from "@/mixins/WowStreamers/WowStreamersAdapterMixin";
import { mapActions } from "vuex";

export default {
  name: "BanWowStreamer",
  components: { datetime },
  mixins: [WowStreamersAdapterMixin],
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoadingSaveForm: false,
      errorText: null,
      formModel: {
        banStartTime: null,
        banEndTime: null,
        banReason: '',
      },
    };
  },
  methods: {
    ...mapActions(['banWowStreamer', 'fetchApprovedWowStreamers']),
    async submitForm() {
      if (!this.formModel.banStartTime || !this.formModel.banEndTime || !this.formModel.banReason) {
        this.errorText = "Пожалуйста, заполните все поля";
        return;
      }

      try {
        this.isLoadingSaveForm = true;
        this.errorText = null;
        await this.banWowStreamer({
          streamerId: this.params.id,
          data: {
            from: this.formModel.banStartTime,
            to: this.formModel.banEndTime,
            ban_description: this.formModel.banReason,
          },
        });
        this.$emit("closeModal");
        this.$emit("banSuccess");
        this.$toast.success('Стример успешно забанен', { position: 'top-right' });
        this.fetchApprovedWowStreamers({ pageNumber: this.getCurrentPage });
      } catch (e) {
        this.errorText = e.response.data.message;
        this.$toast.error(`Ошибка при бане стримера: ${this.errorText}`, { position: 'top-right' });
      } finally {
        this.isLoadingSaveForm = false;
      }
    },
  },
};
</script>

<style scoped>
.ban-wow-streamer-wrapper {
  footer {
    border-top: 1px solid #dee2e6;
    padding-top: 15px;
    margin-top: 15px;
    text-align: right;
  }
}
</style>
