import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            tournamentTeamsRating: [],
            pagination: {
                by: 10,
                currentPage: 1,
                totalPages: 1,
            },
            tournamentTeamInfoRating: []
        }
    },
    mutations: {
        [mutypes.SET_TOURNAMENT_TEAMS_RATING]: (state, value) => state.tournamentTeamsRating = value,
        [mutypes.SET_TOURNAMENT_TEAMS_RATING_PAGINATION_TOTAL]: (state, value) => state.pagination.totalPages = value,
        [mutypes.SET_TOURNAMENT_TEAMS_RATING_PAGINATION_CURRENT_PAGE]: (state, value) => state.pagination.currentPage = value,
        [mutypes.SET_TOURNAMENT_TEAM_SHOW_RATING]: (state, value) => state.tournamentTeamInfoRating = value,
    },

    getters: {
        getTournamentTeamsRating: state => state.tournamentTeamsRating,
        getTournamentTeamsRatingPaginationTotal: state => state.pagination.totalPages,
        getTournamentTeamsRatingPaginationCurrentPage: state => state.pagination.currentPage,
        getTournamentTeamShowRating: state => state.tournamentTeamInfoRating,
    },

    actions: {
        grabTournamentTeamsRating: async ({commit, getters}, params) => {
            let response = await api.tournamentsRating.index({
                tournId: params.tournId,
                page: params.pageNumber,
            });
            commit(mutypes.SET_TOURNAMENT_TEAMS_RATING, response.data.teams || []);
            commit(mutypes.SET_TOURNAMENT_TEAMS_RATING_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_TOURNAMENT_TEAMS_RATING_PAGINATION_CURRENT_PAGE, params.pageNumber);

            return response.status;
        },
        showTournamentTeamInfoRating: async ({commit, getters}, params) => {
            let response = await api.tournamentsRating.show({
                tournId: params.tournId,
                teamId: params.teamId,
            });
            commit(mutypes.SET_TOURNAMENT_TEAM_SHOW_RATING, response.data.rating || []);

            return response.status;
        },
    }
}