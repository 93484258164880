import NotTeams from "@/components/Tournament/workspaces/NotTeams.vue";
import HaveTeams from "@/components/Tournament/MLBB/workspaces/HaveTeams.vue";
import AddTeam from "@/components/Tournament/workspaces/AddTeam.vue";
import EditorMatches from "@/components/Tournament/EditorMatches.vue";
import LoadTeams from "@/components/Tournament/workspaces/LoadTeams.vue";
import HaveTeamsOlympic from "@/components/Tournament/MLBB/workspaces/HaveTeamsOlympic.vue";
import HaveMatches from "@/components/Tournament/MLBB/workspaces/HaveMatches.vue";

const workspaces = new Map()

workspaces.set('NotTeams', NotTeams)
workspaces.set('HaveTeams', HaveTeams)
workspaces.set('AddTeam', AddTeam)
workspaces.set('EditorMatches', EditorMatches)
workspaces.set('LoadTeams', LoadTeams)
workspaces.set('HaveTeamsOlympic', HaveTeamsOlympic)
workspaces.set('HaveMatches', HaveMatches)

export {
    workspaces
}