import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/mailing';
const END_POINT_TELEGRAM = '/api/v1/admin/telegram-mailing';

export default {
    /**
     * GET: Mailing
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    index: (params) => adminHttp.get(END_POINT_TELEGRAM + '/log/' + params.id, {params: params}),

    /**
     * POST: Mailing
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    store: (params) => adminHttp.post(END_POINT, params),

    /**
     * GET: Countries
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCountries: () => adminHttp.get('https://core.bigplay.gg/api/country'),

    /**
     * GET: Message types
     * @returns {Promise<AxiosResponse<any>>}
     */
    messageTypes: () => adminHttp.get(END_POINT + '/message-types'),

    /**
     * GET: Tournament type
     * @returns {Promise<AxiosResponse<any>>}
     */
    tournamentTypes: () => adminHttp.get(END_POINT + '/tournament-types'),

    /**
     * GET: Filtered newsletters
     * @returns {Promise<AxiosResponse<any>>}
     */
    getFilteredMailing: (params) => adminHttp.post(END_POINT + '/social-account-count', params),

    /**
     * GET: Telegram mailing
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    getTelegramMailing: (params) => adminHttp.get(END_POINT_TELEGRAM, {params: params}),

    /**
     * POST: Telegram mailing
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    storeTelegramMailing: (params) => adminHttp.post(END_POINT_TELEGRAM, params),

    getExpectedMailingCount: (params) => adminHttp.post(END_POINT_TELEGRAM + '/expected/mailing-count', params),

    runTelegramMailing: (id) => adminHttp.post(END_POINT_TELEGRAM + '/start/' + id),


}