import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            usersRating: [],
            pagination: {
                by: 10,
                currentPage: 1,
                totalPages: 1,
            },
            userRatingsPagination: {
                by: 10,
                currentPage: 1,
                totalPages: 1,
            },
            showUserRatings: null,
        }
    },
    mutations: {
        [mutypes.SET_USERS_RATING]: (state, value) => state.usersRating = value,
        [mutypes.SET_USERS_RATING_PAGINATION_TOTAL]: (state, value) => state.pagination.totalPages = value,
        [mutypes.SET_USERS_RATING_PAGINATION_CURRENT_PAGE]: (state, value) => state.pagination.currentPage = value,
        [mutypes.SET_USER_RATINGS_SHOW]: (state, value) => state.showUserRatings = value,
        [mutypes.SET_USER_RATINGS_SHOW_PAGINATION_TOTAL]: (state, value) => state.userRatingsPagination.totalPages = value,
        [mutypes.SET_USER_RATINGS_SHOW_PAGINATION_CURRENT_PAGE]: (state, value) => state.userRatingsPagination.currentPage = value,
    },

    getters: {
        getUsersRating: state => state.usersRating,
        getUsersRatingPaginationTotal: state => state.pagination.totalPages,
        getUsersRatingPaginationCurrentPage: state => state.pagination.currentPage,
        getUserRatingsShow: state => state.showUserRatings,
        getUserRatingsShowPaginationTotal: state => state.userRatingsPagination.totalPages,
        getUserRatingsShowPaginationCurrentPage: state => state.userRatingsPagination.currentPage,
    },

    actions: {
        grabUsersRating: async ({commit, getters}, params) => {
            let response = await api.usersRating.index({
                page: params.pageNumber,
                "search": params.search,
            });
            commit(mutypes.SET_USERS_RATING, response.data.users || []);
            commit(mutypes.SET_USERS_RATING_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_USERS_RATING_PAGINATION_CURRENT_PAGE, params.pageNumber);

            return response.status;
        },
        async showUserRatings({commit, getters}, params) {
            let response = await api.usersRating.show(params.id, {
                page: params.pageNumber,
            })
            commit(mutypes.SET_USER_RATINGS_SHOW, response.data)
            commit(mutypes.SET_USER_RATINGS_SHOW_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_USER_RATINGS_SHOW_PAGINATION_CURRENT_PAGE, params.pageNumber);
            return response.status;
        },
    }
}