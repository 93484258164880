<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Рейтинг" title="История рейтинга пользователя"/>
      <b-card>
        <div>
          <b-form>
            <div class="mb-2">PUBG nick:
              {{
                !userRatingsShow.user.gameProfiles.length ? 'нет игрового профиля' :
                    (userRatingsShow.user.gameProfiles.find((profile) => profile.game.slug === 'pubg_mobile')?.fields.nick || 'По данной игре nick не найден')
              }}
            </div>
            <div class="mb-2">PUBG id:
              {{
                !userRatingsShow.user.gameProfiles.length ? 'нет игрового профиля' :
                    (userRatingsShow.user.gameProfiles.find((profile) => profile.game.slug === 'pubg_mobile')?.fields.pubg_id || 'По данной игре id не найден')
              }}
            </div>
            <div v-if="userRatingsShow.user.teams.length" class="mb-2 d-flex">Команды:
              <span v-for="team in userRatingsShow.user.teams" :key="'teamPubgId-' + team.id">
                   {{ team.name || '' }}
              </span>
            </div>
            <div v-else>Команды отсутствуют</div>
            <div>
              <b-table
                  table-variant="secondary"
                  responsive
                  show-empty
                  stacked="md"
                  :striped="striped"
                  :bordered="bordered"
                  :fields="fields"
                  :items="userRatingsShow.tournaments"
                  :current-page=0
                  :per-page=10
                  class="mb-5"
              >
                <template v-slot:cell(id)="{item}">
                  {{item.id}}
                </template>
                <template v-slot:cell(tournament)="{item}">
                  <div>{{ item.name }}</div>
                </template>
                <template v-slot:cell(description)="{item}">
                  <div v-for="rating in item.userGroupRatings" :key="'rating-' + rating.id">
                    {{rating.description}}
                  </div>
                </template>
                <template v-slot:cell(rating)="{item}">
                  <div v-for="rating in item.userGroupRatings" :key="'rating-' + rating.id">
                    {{rating.points.toFixed(2)}}
                  </div>
                </template>
                <template v-slot:cell(total)="{item}">
                  {{item.sum_points.toFixed(2)}}
                </template>
              </b-table>
              <b-col md="6" class="p-0">
                <b-pagination
                    @change="handlePageChange"
                    v-model="currentPageShow"
                    :total-rows="totalShow"
                    :per-page="10"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </div>
          </b-form>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import {UsersRatingAdapterMixin} from "@/mixins/Rating/UserRatingAdapterMixin";

export default {
  name: "UserRatingsInfo",
  mixins: [PaginationHelperMixin, UsersRatingAdapterMixin],
  created() {
    this.recordPageToStorage(this.currentPageShow, this.pageKey)
    this.showUserRatings({
      pageNumber: this.getCurrentPageNumber(this.pageKey),
      id: this.$route.params.id,
    })
  },
  data() {
    return {
      pageKey: 'userRatingsShowPageNumber',
      fields: [
        {key: 'id', label: 'id', sortable: true},
        {key: 'tournament', label: 'Турнир', sortable: true},
        {key: 'description', label: 'Описание', sortable: true},
        {key: 'rating', label: 'Рейтинг', sortable: true},
        {key: 'total', label: 'Общий рейтинг', sortable: true},
      ],
      striped: true,
      bordered: true,
    };
  },
  methods: {
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.showUserRatings({
        pageNumber: pageNumber,
        id: this.$route.params.id,
      })
    },
  }
};
</script>