<template>
  <div class="teams-by-tournament-wrapper" ref="teamsTournamentWrapper">
    <div class="filters-wrapper">
      <h4>Фильтры</h4>
      <div class="filters">
        <b-form-group class="filter-block" label="Поиск" label-for="status">
          <b-form-input v-model="filters.search" placeholder="По названию команды" @change="callGetTeams" />
        </b-form-group>
        <b-form-group class="filter-block" label="Этап" label-for="status">
          <b-form-select @change="changeStage" v-model="filters.stage">
            <b-form-select-option :value="null">Не выбрано</b-form-select-option>
            <b-form-select-option v-for="stage in dictionaries.stages" :value="stage.id">{{ stage.name }}</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group class="filter-block" label="Группа" label-for="status">
          <b-form-select @change="changeGroup" v-model="filters.group" id="status">
            <b-form-select-option :value="null">Не выбрано</b-form-select-option>
            <b-form-select-option v-for="group in dictionaries.groups" :value="group.id">{{ group.name }}</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </div>
    </div>
    <div v-if="isLoadingTeams" class="loading-wrapper">
      <b-spinner class="spinner"></b-spinner>
    </div>
    <div class="not-teams-pug" v-else-if="!teams.length">
      <h4>Имеющиеся команды отсутсвуют</h4>
    </div>
    <!--    Список команд-->
    <template v-else>
      <transition-group name="list"
                        tag="div"
                        class="team-list"
                        :class="[
                            adaptiveClassTeamsClass && adaptiveClassTeamsClass
                        ]"
      >
        <TeamCardTournament v-for="team in teams" :team="team" :key="`god-${ team.id }`" />
      </transition-group>
      <InfiniteLoading @infinite="(state) => getTeams({ page: ++currentPageTeams }, state)" />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from "@/api/api"
import debounce from 'lodash.debounce'
import TeamCardTournament from "@/components/Tournament/components/TeamCardTournament.vue";
import InfiniteLoading from 'vue-infinite-loading'


export default {
  name: "TeamsByTournament",
  components: {
    TeamCardTournament,
    InfiniteLoading
  },
  computed: {
    ...mapState('moduleNewTournament', ['tournament']),
  },
  data() {
    return {
      isLoadingTeams: false,
      currentPageTeams: 1,
      adaptiveClassTeamsClass: '',
      filters: {
        stage: null,
        group: null,
        search: ''
      },
      dictionaries: {
        stages: [],
        groups: [],
      },
      teams: []
    }
  },
  methods: {
    async getTeams(params, $state) {
      this.currentPageTeams = params?.page || 1
      if(this.currentPageTeams === 1) this.isLoadingTeams = true
      const response = await api.newTeams.getTeams(this.tournament.id, {
        ...(this.filters.stage && { 'filter[stage]': this.filters.stage }),
        ...(this.filters.group && { 'filter[group]': this.filters.group }),
        ...(this.filters.search && { 'filter[name]': this.filters.search }),
        // 'name': this.filters.search,
        page: this.currentPageTeams,
      })
      if (this.currentPageTeams === 1) {
        this.teams = response.data.teams
      } else {
        this.teams = response?.data?.teams ? [...this.teams, ...response?.data?.teams ] : []
      }
      if ($state && response.data.teams.length) $state.loaded()
      if ($state && !response.data.teams.length) $state.complete()
      this.isLoadingTeams = false
    },
    async getStages() {
      const response = await api.newTeams.getStages(this.tournament.id)
      this.dictionaries.stages = response.data.stages
    },
    async getGroups() {
      const response = await api.newTeams.getGroups(this.filters.stage)
      this.dictionaries.groups = response.data.groups
    },
    changeStage() {
      this.filters.group = null
      this.dictionaries.groups = []
      if (this.filters.stage) this.getGroups()
      this.getTeams()
    },
    changeGroup() {
      this.getTeams()
    },
    callGetTeams: debounce(function () {
      this.getTeams()
    }, 1000)
  },
  mounted() {
    const resizeObserverActionsBlock = new ResizeObserver((entries) => {
      try {
        const parentWrapperWidth = entries[0].target.offsetWidth
        if (parentWrapperWidth <= 500) {
          this.adaptiveClassTeamsClass = 'c-1'
          return
        }
        if (parentWrapperWidth <= 800) {
          this.adaptiveClassTeamsClass = 'c-2'
          return
        }
        if (parentWrapperWidth <= 1000) this.adaptiveClassTeamsClass = 'c-3'
      } catch (e) {
        console.log('e ', { e })
      }
    })
    resizeObserverActionsBlock.observe(this.$refs.teamsTournamentWrapper)
    this.getTeams()
    this.getStages()
  },
}
</script>

<style scoped lang="scss">
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active, .list-leave-active {
  transition: all .2s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.teams-by-tournament-wrapper {
  //padding-top: 20px;

  .filters-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 14px 0;

    h4 {
      font-size: 15px;
      margin: 0;
    }

    .filters {
      display: flex;
      align-items: center;
      gap: 15px;

      .filter-block::v-deep {
        margin: 0;

        label {
          font-size: 10px;
          color: #aeaeae;
          margin: 0 0 -3px;
        }

        select {
          border-radius: 0;
          background: transparent;
          border: none;
          border-bottom: 1px solid #c3c3c3;
          padding: 2px 13px 2px 7px;
          height: auto;
          color: #666666;
          font-size: 13px;
        }

        input {
          font-size: 13px;
          border: none;
          border-bottom: 1px solid #c3c3c3;
          background: transparent;
          border-radius: 0;
          padding: 2px 13px 2px 7px;
          height: auto;
        }
      }
    }
  }

  .team-list {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    &.c-1 {
      grid-template-columns: 1fr;
    }

    &.c-2 {
      grid-template-columns: 1fr 1fr;
    }

    &.c-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}


.not-teams-pug {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;

  h4 {
    margin: 0;
    font-size: 20px;
  }
}

::v-deep {
  .infinite-status-prompt {
    display: none;
  }
}
</style>