<template>
  <div class="review-group-tournament">
    <div class="block">
      <label>Название группы</label>
      <span>{{ groupData.name }}</span>
    </div>
    <div class="block">
      <label>Слот</label>
      <span>{{ groupData.slot_count }}</span>
    </div>
    <div class="block">
      <label>Статус</label>
      <span>{{ statuses.find(({ value }) => value === groupData.status).text }}</span>
    </div>
    <div class="block">
      <label>Дата</label>
      <span>{{ moment(groupData.game_time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm DD.MM.YYYY') }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: "ReviewGroupTournament",
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      moment,
      groupData: null,
      statuses: [
        {
          text: 'Игра не начата',
          value: 0
        },
        {
          text: 'Игра уже идет',
          value: 1
        },
        {
          text: 'Игра окончена',
          value: 2
        }
      ],
    }
  },
  methods: {
    async getGroupData() {
      this.groupData = await this.$store.dispatch('moduleNewTournament/getGroup', {
        id: this.params.group.id
      })
    }
  },
  created() {
    this.getGroupData()
  }
}
</script>

<style scoped lang="scss">
.review-group-tournament {
  .block {
    display: flex;
    flex-direction: column;

    label {
      font-size: 17px;
      font-weight: 500;
      margin: 0;
    }

    span {
      color: gray;
    }
  }

  & > .block:not(:last-child) {
    margin-bottom: 20px;
  }
}
</style>