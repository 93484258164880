<template>
  <div>
  <Breadcrumbs main="Турниры" :title="`Создание этапа для Турнира`"/>
    <b-form>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Название этапа <span class="text-danger">*</span></label>
        <ValidationProvider rules="min:2|required" v-slot="{errors}">
        <b-form-input v-model="newStageName"></b-form-input>
          <div class="text-danger">{{errors[0]}}</div>
        </ValidationProvider>
      </div>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Коэффициент этапа - Рейтинг команд <span class="text-danger">*</span></label>
        <ValidationProvider rules="required" v-slot="{errors}">
        <b-form-input type="number" v-model="newStageCoeff"></b-form-input>
          <div class="text-danger">{{errors[0]}}</div>
        </ValidationProvider>
      </div>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Коэффициент этапа - Рейтинг пользователя <span class="text-danger">*</span></label>
        <ValidationProvider rules="required" v-slot="{errors}">
          <b-form-input type="number" v-model="personalRating"></b-form-input>
          <div class="text-danger">{{errors[0]}}</div>
        </ValidationProvider>
      </div>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Кол-во замен <span class="text-danger">*</span></label>
        <ValidationProvider rules="required" v-slot="{errors}">
        <b-form-input type="number" v-model="newReplacements"></b-form-input>
        <div class="text-danger">{{errors[0]}}</div>
        </ValidationProvider>
      </div>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Cтатус <span class="text-danger">*</span></label>
        <ValidationProvider rules="required" v-slot="{errors}">
          <b-form-select  v-model="newStatus" :options="newStatusOptions"></b-form-select>
          <div class="text-danger">{{errors[0]}}</div>
        </ValidationProvider>
      </div>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Минимальной значение рейтинга для подачи в этап</label>
        <ValidationProvider rules="required" v-slot="{errors}">
          <b-form-input type="number" v-model="thresholdRating"></b-form-input>
          <div class="text-danger">{{errors[0]}}</div>
        </ValidationProvider>
      </div>

      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Кол-во команд, разрешенное для подачи в этап</label>
        <div class="mb-3" v-for="division in divisions" :key="division.id">
          <div class="grid-division">
            <div>{{ division['division_name'] }}</div>
            <div>
              <ValidationProvider rules="required" v-slot="{errors}">
                <b-form-input type="number" v-model="division['slot_count']"></b-form-input>
                <div class="text-danger">{{errors[0]}}</div>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center flex-wrap">
        <button class="btn btn-success mr-3 mt-2" @click.prevent="createTournamentStage" :disabled="newStageName === '' || newStageCoeff === '' || newStatus === ''">Сохранить</button>
        <button class="btn btn-primary mr-3 mt-2" @click.prevent="$router.go(-1)">Назад</button>
      </div>
    </b-form>
  </div>
</template>

<script>
import {TournamentStageAdapterMixin} from "../../../mixins/Tournament/TournamentStageAdapterMixin";


export default {
  name: 'CreateTournamentStage',
  mixins: [TournamentStageAdapterMixin],
  created() {
    this.getDivisions()
  }
}
</script>
<style>
.grid-division {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 15px;

  & > * {
    align-self: center;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
