import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const PostsAdapterMixin = {
    data() {
        return {
            newPostTitle: {
                ru: null,
                en: null,
                uz: null,
                kz: null,
            },
            newPostDescription: {
                ru: null,
                en: null,
                uz: null,
                kz: null,
            },
            newPostPreview: {
                ru: null,
                en: null,
                uz: null,
            },
            newPostMetaDescription: {
                ru: null,
                en: null,
                uz: null,
                kz: null,
            },
            newPostData: null,
            newPostLogo: null,
            newPostType: null,
            postTypes: [
                { value: 1, text: 'Интервью (Интервью с игроками)' },
                { value: 2, text: 'Обучающее (Гайды по игре)' },
                { value: 3, text: 'Справочник (Гайды по платформе)' },
            ],
        }
    },
    computed: {
        ...mapGetters({
            getPosts: 'getPosts',
            getCurrentPost: 'getCurrentPost',
            getTotalPosts: 'getTotalPosts',
            getCurrentPage: 'getCurrentPage',
            getCurrentImage: 'getCurrentImage',
            getCurrentPreview: 'getCurrentPreview'
        }),
        totalPosts:  { get(){return this.getTotalPosts           }, set(value){this.setTotalPosts(value)}},
        currentPage:  { get(){return this.getCurrentPage          }, set(value){this.setCurrentPage(value)}},
        currentPost:  { get(){return this.getCurrentPost         }, set(value){this.setCurrentPost(value)}},
        currentImage:  { get(){return this.getCurrentImage         }, set(value){this.setCurrentImage(value)}},
        currentPreview:  { get(){return this.getCurrentPreview         }, set(value){this.setCurrentPreview(value)}},
    },
    methods: {
        ...mapMutations({
            setTotalPosts: mutypes.SET_POSTS_TOTAL,
            setCurrentPage: mutypes.SET_POSTS_CURRENT_PAGE,
            setCurrentPost: mutypes.SET_CURRENT_POST,
            setCurrentImage: mutypes.SET_POSTS_CURRENT_IMAGE,
            setCurrentPreview: mutypes.SET_POSTS_CURRENT_PREVIEW,
        }),
        ...mapActions([
            'indexBlogPosts',
            'showBlogPosts',
            'updateBlogPosts',
            'storeBlogPosts',
            'deleteBlogPost',
        ]),
        showBlogPostsPage(id){
            this.showBlogPosts(id).then(()=>{
                this.$router.push({name: 'posts_edit', params: {id: id}})
            })
        },
        updateBlogPostsPage(data){
            const formData = this.prepareFormData(data);
            formData.append('_method', 'put');

            this.updateBlogPosts({id: this.$route.params.id, params: formData}).then(()=>{
                this.$router.push({name: 'posts'})
            })
        },
        storeBlogPostsPage(data) {
            const formData = this.prepareFormData(data);
            this.storeBlogPosts(formData).then(() => {
                this.$router.push({name: 'posts'})
            })
        },
        prepareFormData(data) {
            const formData = new FormData();

            if (data.description) {
                Object.entries(data.description).forEach(([key, value]) => {
                    if (value) {
                        formData.append(`description[${key}]`, JSON.stringify(value));
                    }
                });
            }

            if (data.title) {
                Object.entries(data.title).forEach(([key, value]) => {
                    if (value) {
                        formData.append(`title[${key}]`, value);
                    }
                });
            }

            if (data.preview) {
                Object.entries(data.preview).forEach(([key, value]) => {
                    if (value) {
                        formData.append(`preview[${key}]`, value);
                    }
                });
            }

            if (data.meta_description) {
                Object.entries(data.meta_description).forEach(([key, value]) => {
                    if (value) {
                        formData.append(`meta_description[${key}]`, value);
                    }
                });
            }

            if (data.logo) {
                formData.append('logo', data.logo);
            }

            if (data.type) {
                formData.append('type', data.type);
            }

            return formData;
        },
    }
}