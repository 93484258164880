<template>
  <div>
    <div v-if="isLoading" class="loading-wrapper">
      §
    </div>
    <template v-else>
      <Breadcrumbs title="Главная страница"/>
      <div class="container-fluid">
        <div class="card">
          <div class="card-body">
            <div class="page-grid">
              <div class="viewer">
                <b-form>
                  <b-form-group
                      label="Ссылка на YouTube"
                      label-for="link-youtube"
                  >
                    <b-form-input
                        id="link-youtube"
                        :disabled="!isEditor"
                        v-model="mainModel['youtube_link']"
                        placeholder="Введите ссылку"
                    ></b-form-input>
                  </b-form-group>
                  <b-tabs class="mt-3">
                    <b-tab title="RU" @click="getLanguageFromBtn('ru')">
                      <template #title>
                        <div class="d-flex justify-content-center">
                          <img src="@/assets/images/ru.svg" width="20" height="20" class="mr-2">
                          <span>RU</span>
                        </div>
                      </template>
                    </b-tab>
                    <b-tab title="EN" @click="getLanguageFromBtn('en')">
                      <template #title>
                        <div class="d-flex justify-content-center">
                          <img src="@/assets/images/usa.svg" width="20" height="20" class="mr-2">
                          <span>EN</span>
                        </div>
                      </template>
                    </b-tab>
                    <b-tab title="UZ" @click="getLanguageFromBtn('uz')">
                      <template #title>
                        <div class="d-flex justify-content-center">
                          <img src="@/assets/images/uz.svg" width="20" height="20" class="mr-2">
                          <span>UZ</span>
                        </div>
                      </template>
                    </b-tab>
                    <b-tab title="KZ" @click="getLanguageFromBtn('kz')">
                      <template #title>
                        <div class="d-flex justify-content-center">
                          <img src="@/assets/images/kz.png" width="20" height="20" class="mr-2">
                          <span>KZ</span>
                        </div>
                      </template>
                    </b-tab>
                  </b-tabs>
                  <b-form-group
                      label-for="title"
                  >
                    <label class="mt-2">Заголовок {{ language }}</label>
                    <b-form-input
                        id="title"
                        :disabled="!isEditor"
                        v-model="mainModel.title[language]"
                        placeholder="Введите заголовок"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                      label="Ссылка"
                      label-for="link"
                  >
                    <b-form-input
                        id="link"
                        :disabled="!isEditor"
                        v-model="mainModel.link"
                        placeholder="Введите ссылку"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                      label-for="description"
                  >
                    <label class="mt-2">Описание {{ language }}</label>
                    <b-form-textarea
                        id="description"
                        :disabled="!isEditor"
                        v-model="mainModel.description[language]"
                        placeholder="Введите описание"
                    ></b-form-textarea>
                  </b-form-group>
                  <b-form-group
                      label="Стили"
                      label-for="styles"
                  >
                    <b-form-textarea
                        id="description"
                        :disabled="!isEditor"
                        v-model="mainModel.styles"
                        placeholder="Введите стили"
                        rows="3"
                        max-rows="10"
                    ></b-form-textarea>
                    <template>
                      <h6 class="mt-2">Если у вас пустые стили, то вы можете скопировать этот код и встаить его в поле, а так же отредактировать</h6>
                      <pre>
    #render-title-for-banner {
      color: black;
      text-shadow: 2px 2px 2px black;
      font-size: 50px;
    }

    #render-description-for-banner {
      color: black;
      text-shadow: 2px 2px 2px black;
      font-size: 16px;
    }</pre>
                    </template>
                  </b-form-group>
                  <div class="p-relative" v-if="renderImage">
                    <!-- Preview image -->
                    <img v-if="renderImage"
                         :src="renderImage"
                         class="render-image"
                         alt=""
                    />
                    <h1 id="render-title-for-banner">
                      {{mainModel.title.ru}}
                    </h1>
                    <p id="render-description-for-banner">
                      {{mainModel.description.ru}}
                    </p>
                  </div>
                  <div class="d-flex mt-2">
                    <b-form-file id="select-image"
                                 ref="default"
                                 :disabled="!isEditor"
                                 placeholder="Кликните, чтобы выбрать изображение"
                                 @change="changeFunction"
                                 browse-text="Выбрать"
                                 class="mr-2"
                    />
                    <b-button :disabled="!isEditor" variant="danger" @click="deleteBanner()">
                      Удалить
                    </b-button>
                  </div>
                </b-form>
              </div>
              <div class="actions">
                <template v-if="!isEditor">
                  <b-button variant="info" @click="isEditor = true">
                    Изменить
                  </b-button>

                </template>
                <template v-else>
                  <b-button @click="isEditor = false"
                            variant="dark">
                    Отменить редактирование
                  </b-button>
                  <b-button variant="secondary" @click="resetEditData">
                    Сбросить изменения
                  </b-button>
                  <b-button variant="success"
                            @click="updateMainBannerData">
                    Сохранить изменения
                  </b-button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import api from "@/api/api";
import {LanguageAdapterMixin} from "@/mixins/Language/LanguageAdapterMixin";
import image from '@/assets/images/backgroundMain.webp'
import Vue from "vue";
// import image from "@/assets/images/backgroundMain.webp";
export default {
  name: 'MainBannerPage',
  mixins: [LanguageAdapterMixin],
  data() {
    return {
      isLoading: true,
      isEditor: false,
      oldMainModel: '',
      mainModel: {
        image:{original_url:''},
        title: {
          ru: '',
          en: '',
          uz: '',
          kz: '',
        },
        description: {
          ru: '',
          en: '',
          uz: '',
          kz: '',
        },
      },
    }
  },
  computed: {
    renderImage() {
      if (this.mainModel?.image?.['original_url']) {
        if (typeof this.mainModel?.image?.['original_url'] === 'object') {
          return URL.createObjectURL(this.mainModel?.image['original_url'])
        }
        return this.mainModel?.image['original_url']
      }
      return ''
    },
  },
  methods: {
    async getMainBannerData() {
      this.mainModel = (await api.mainBanner.get()).data['main_banner']
      this.oldMainModel = JSON.stringify(this.mainModel)
      if (this.mainModel?.image?.original_url === undefined) {
        this.$set(this.mainModel, 'image', { original_url: '' })
      }
      this.isLoading = false
    },
    async updateMainBannerData() {
      try {
        this.isLoading = true
        const sendBody = new FormData()
        Object.entries(this.mainModel).forEach(([key, value]) => {
          if (key === 'image') {
            const imageFile = this.$refs.default.$el.children[0].files[0]
            if (imageFile) sendBody.append(key, imageFile)
            return
          }
          sendBody.append(key, value || '')
        })
        Object.entries(this.mainModel.title).forEach(([key, value]) => sendBody.append(`title[${key}]`, value || ''))
        Object.entries(this.mainModel.description).forEach(([key, value]) => sendBody.append(`description[${key}]`, value || ''))
        await api.mainBanner.update(sendBody)
      } catch (e) {
          Vue.toasted.error("Не удалось обновить", {
            position: 'top-right',
            duration: 3000
          });
      } finally {
        await this.getMainBannerData()
        this.isEditor = false
        this.isLoading = false
      }
    },
    async resetEditData() {
      this.mainModel = JSON.parse(this.oldMainModel)
    },
    async deleteBanner() {
      let image = require('@/assets/images/backgroundMain.webp')

      // Getting file in binary format to retype blob
      const response = await fetch(image, { method: 'GET' }).then((res) => res.blob())
      // Rerecording type File
      const newFile = new File([response], 'test.webp')

      // Set default image in html input for request
      let container = new DataTransfer();
      container.items.add(newFile);
      this.$refs.default.$el.children[0].files = container.files;

      // Set default image for preview for user
      this.mainModel.image.original_url = image
    },
    changeFunction(event) {
      this.mainModel.image.original_url = event.target.files[0]
    }
  },
  mounted() {
    (async () => {
      await this.getMainBannerData()
    })()
  },

  watch: {
    'mainModel.styles': {
      deep: true,
      immediate: true,
      handler() {
        const stylesSelector = document.getElementById('styles-for-main-banner')
        stylesSelector.innerHTML = this.mainModel.styles
      }
    }
  }
}
</script>

<style scoped lang="scss">
.loading-wrapper {
  min-height: calc(100vh - 180px);
  display: flex;
  align-items: center;
  justify-content: center;

  & > .spinner {
    scale: 2;
  }
}

.page-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  .actions {
    justify-self: end;

    display: grid;
    grid-template-columns: 100%;
    gap: 15px;
    height: fit-content;

    & > * {
      min-width: 200px;
    }
  }

  .render-image {
    width: 200%;
  }
  #render-title-for-banner {
    position: absolute;
    top: 20%;
    left: 20%;
  }
  #render-description-for-banner {
    position: absolute;
    top: 60%;
    left: 20%;
  }
}
</style>
