<template>
  <div>
    <Breadcrumbs main="Прочее" title="Помощь"></Breadcrumbs>
    <div class="container">
      <b-card>
        <b-card-header>
          <div class="row justify-content-between align-items-center">
              <b-btn variant="success" class="mr-3" @click="$router.push({name: 'help_create'})">Добавить раздел помощи</b-btn>
          </div>
        </b-card-header>
        <b-card-body>
          <div v-if="isLoadingHelpdesks" class="loading-wrapper">
            <b-spinner class="spinner"></b-spinner>
          </div>
          <div v-else-if="cloneRecords.length" class="help-table-wrapper">
            <b-table-simple>
              <thead>
              <b-tr>
                <b-th>№</b-th>
                <b-th>Заголовок</b-th>
                <b-th>Инструменты</b-th>
              </b-tr>
              </thead>
              <draggable v-model="cloneRecords" tag="tbody" @change="debounceSaveChangedPosition">
                <b-tr v-for="(element) in cloneRecords" :key="element.id" class="drag-element">
                  <b-td>
                    {{ element.position }}
                  </b-td>
                  <b-td>
                    {{element.title}}
                  </b-td>
                  <b-td class="d-flex flex-wrap">
                    <b-btn variant="primary" class="mr-3 mb-2 mb-mb-0" @click="showHelpDeskCurrent(element.id)">Редактировать</b-btn>
                    <b-btn variant="danger" @click.prevent="removeHelpDesk(element.id)">Удалить</b-btn>
                  </b-td>
                </b-tr>
              </draggable>
            </b-table-simple>
            <div class="loader-wrapper" v-if="isLoadingChangedPositions">
              <div class="template">
                <b-spinner></b-spinner>
                Сохраняем новые позиции записей
              </div>
            </div>
          </div>
          <div v-else>Список помощи отсутсвует</div>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import {HelpAdapterMixin} from "@/mixins/helpdesk/HelpAdapterMixin";
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import draggable from 'vuedraggable'
import debounce from 'lodash.debounce'
import api from '@/api/api'

export default {
  name: 'HelpList',
  components: {
    draggable
  },
  mixins: [HelpAdapterMixin, ModalAdapterMixin],
  mounted() {
    (async () => {
      this.isLoadingHelpdesks = true
      await this.indexHelpDesk()
      this.isLoadingHelpdesks = false
    })()
  },
  data() {
    return {
      fields: [
        {key: 'index', label: '№',},
        {key: 'title', label: 'Заголовок',},
        // {key: 'author', label: 'Автор',},
        {key: 'actions', label: 'Инструменты',},
      ],
      cloneRecords: [],
      isLoadingChangedPositions: false,
      isLoadingHelpdesks: false
    }
  },
  methods: {
    removeHelpDesk(id) {
      this.optionName('Вы действительно хотите удалить помощь?')
      this.idForDeleting(id)
      this.openModal()
    },
    async saveChangedPosition(draggedElement) {
      try {
        this.isLoadingChangedPositions = true;
        let newIndex = draggedElement.moved.newIndex + 1
        let newPosition = null
        this.cloneRecords.forEach((item) => { newPosition = item.position = newIndex })
        await api.helpdesk.changePosition({id: draggedElement.moved.element.id, position: newPosition});
        await this.indexHelpDesk()
      } finally {
        this.isLoadingChangedPositions = false;
      }
    },
    debounceSaveChangedPosition: debounce(function (draggedElement) {
      this.saveChangedPosition(draggedElement)
    }, 1000)
  },
  watch: {
    'getHelp': {
      deep: true,
      handler(newValue) {
        if (newValue && newValue.length) this.cloneRecords = JSON.parse(JSON.stringify(newValue))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.help-table-wrapper {
  position: relative;

  .drag-element {
    cursor: pointer;
  }

  .loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000042;

    .template {
      background: #fffffff0;
      display: flex;
      align-items: center;
      padding: 20px;
      border-radius: 20px;

      .spinner-border {
        margin-right: 20px;
      }
    }
  }
}
</style>
