<template>
<div>
<Breadcrumbs title="Добавить команду"></Breadcrumbs>
  <div>

    <div class="container">
      <div class="card p-3">
        <h1 style="font-size: 25px">{{tournamentName}}</h1>
        <h2 style="font-size: 25px">Этап: {{currentStage?.name}}</h2>
        <h2 style="font-size: 25px; margin-bottom: 30px">Группа: {{stageGroup.name}}</h2>

        <div>
          <b-tabs content-class="mt-3">
            <b-tab title="Заявки"  active  >
              <div>
              <AddTeamEntriesStandingTournament></AddTeamEntriesStandingTournament>
            </div>
            </b-tab>
            <b-tab title="Все команды"
            @click="indexTeams({})"
            >
              <div>
              <AllTeamsStandingTournament></AllTeamsStandingTournament>
            </div>
            </b-tab>
            <b-tab title="Из этапа"
                   @click="indexTeams({
                      stageId:$route.params.stage_id,
                      tournament_id:$route.params.standingId
                   })"
            >
              <div>
                <FromStageStandingTournament></FromStageStandingTournament>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import AddTeamEntriesStandingTournament
  from "@/pages/Tournament/StandingsTournament/AddTeamStandingTournament/AddTeamEntriesStandingTournament.vue";
import AllTeamsStandingTournament
  from "@/pages/Tournament/StandingsTournament/AddTeamStandingTournament/AllTeamsStandingTournament.vue";
import FromStageStandingTournament
  from "@/pages/Tournament/StandingsTournament/AddTeamStandingTournament/FromStageStandingTournament.vue";
import {TournamentStandingsAdapterMixin} from "@/mixins/Tournament/TournamentStandingsAdapterMixin";
import {TournamentStageGroupAdapterMixin} from "@/mixins/Tournament/TournamentStageGroupAdapterMixin";
import {TeamsAdapterMixin} from "@/mixins/Teams/TeamsAdapterMixin";
import {TournamentEntriesAdapterMixin} from "@/mixins/Tournament/TournamentEntriesAdapterMixin";

export default {
  name: "AddTeamStandingsTournament",
  components: {FromStageStandingTournament, AllTeamsStandingTournament, AddTeamEntriesStandingTournament},
  mixins: [TournamentStandingsAdapterMixin, TournamentStageGroupAdapterMixin, TeamsAdapterMixin, TournamentEntriesAdapterMixin],
    created() {
        this.showTournamentStage(this.$route.params.stage_id);
        this.showTournamentStageGroup(this.$route.params.group_id)
    }
}
</script>

<style scoped>

</style>