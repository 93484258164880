<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Фейковые команды" title="Список фейковых команд"/>
      <Modal name="DeleteFakeTeam" title="Удаление фейковой команды" v-model="isShowDeleteFakeTeamModal"
             :params="{ id: selectedFakeTeamId }"/>
      <Modal name="EditFakeTeam" title="Редактирование фейковой команды" v-model="isShowEditFakeTeamModal"
             :params="{ id: selectedFakeTeamId }"/>
      <Modal name="CreateFakeTeam" title="Создание фейковой команды" v-model="isShowCreateFakeTeamModal"/>
      <b-card>
        <div class="d-flex flex-wrap justify-content-between  align-items-center mb-5">
          <SearchInput v-model="searchForFakeTeamsModel" @search="getFilteredFakeTeams()"
                       :placeholder-name="'Поиск фейк команды'"/>
          <b-btn @click="createFakeTeam" variant="primary">Создать</b-btn>
        </div>
        <div v-if="isLoadingFakeTeams" class="loading-wrapper">
          <b-spinner class="spinner"></b-spinner>
        </div>
        <b-table
            v-else
            responsive
            show-empty
            stacked="md"
            :striped="isStriped"
            :bordered="isBordered"
            :fields="fields"
            :items="fakeTeams"
            :current-page=0
            :per-page=10
            class="mb-5"
        >
          <template #cell(username)="data">
            {{ data.item.name }}
          </template>
          <template #cell(game)="data">
            {{ data.item.game.name }}
          </template>
          <template #cell(members)="data">
            <div v-for="member in data.item.members" :key="'memberId' + member.id">
              {{ getNickOfFakeUser(member, data.item.game) }}
            </div>
          </template>
          <template #cell(action)="data">
            <div class="row justify-content-center">
              <b-btn variant="primary" class="mr-1 p-1" @click="editFakeTeam(data.item.id)">
                <i class="fa fa-edit"></i>
              </b-btn>
              <b-btn variant="danger" class="mr-2 p-1" @click="deleteFakeTeam(data.item.id)">
                <i class="fa fa-trash" style="font-size: 18px"></i>
              </b-btn>
            </div>
          </template>
        </b-table>

        <b-col md="6" class="p-0">
          <b-pagination
              @change="handleFakeTeamsPageChange"
              v-model="fakeTeamsCurrentPage"
              :total-rows="totalPagesOfFakeTeams"
              :per-page="10"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-card>
    </div>
  </div>
</template>

<script>
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import SearchInput from "@/components/SearchInput.vue";
import Modal from '@/components/modals/index.vue'
import {mapState} from "vuex";

export default {
  name: "FakeTeams",
  components: {SearchInput, Modal},
  mixins: [ModalAdapterMixin, PaginationHelperMixin],
  created() {
    try {
      this.isLoadingFakeTeams = true;
      (async () => {
        this.recordPageToStorage(this.fakeTeamsCurrentPage, this.fakeTeamsPageKey)
        await this.$store.dispatch('moduleFakeTeams/getFakeTeams', {
          page: this.getCurrentPageNumber(this.fakeTeamsPageKey),
        })
      })()
    } finally {
      this.isLoadingFakeTeams = false
    }
  },

  data() {
    return {
      searchForFakeTeamsModel: '',
      fakeTeamsPageKey: 'pageNumberFakeTeams',
      fields: [
        {key: 'id', label: '№', sortable: true},
        {key: 'name', label: 'Название', sortable: true},
        {key: 'game', label: 'Дисциплина', sortable: true},
        {key: 'members', label: 'Состав', sortable: true},
        {key: 'action', label: 'Инструменты', sortable: true},
      ],
      isStriped: true,
      isBordered: true,
      isLoadingFakeTeams: false,
      isShowDeleteFakeTeamModal: false,
      isLoadingCreateFakeTeam: false,
      isShowEditFakeTeamModal: false,
      isShowCreateFakeTeamModal: false,
      selectedFakeTeamId: 0,
      fakeTeamsCurrentPage: 1
    }
  },
  computed: {
    ...mapState('moduleFakeTeams', ['fakeTeams', 'totalPagesOfFakeTeams']),
    searchModel: {
      get() { return this.value },
      set(newValue) { this.$emit('input', newValue) }
    }
  },
  methods: {
    async handleFakeTeamsPageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.fakeTeamsPageKey)
      await this.$store.dispatch('moduleFakeTeams/getFakeTeams', {
        page: pageNumber,
        ...(this.searchForFakeTeamsModel && {search: this.searchForFakeTeamsModel})
      })
    },
    getFilteredFakeTeams() {
      this.isLoadingFakeTeams = true
      this.$store.dispatch('moduleFakeTeams/getFakeTeams', {search: this.searchForFakeTeamsModel})
          .then(() => {
            this.isLoadingFakeTeams = false
          })
    },
    deleteFakeTeam(id) {
      this.selectedFakeTeamId = id
      this.isShowDeleteFakeTeamModal = true
    },
    editFakeTeam(id) {
      this.selectedFakeTeamId = id
      this.isShowEditFakeTeamModal = true
    },
    createFakeTeam() {
      this.isShowCreateFakeTeamModal = true
    },
    getNickOfFakeUser(user, games) {
      if (user.game_profiles.length) {
        return user.game_profiles.find((game) => game.game_id === games.id).fields.nick
      }
      return ''
    },
  },
}
</script>
