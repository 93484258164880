import * as mutypes from "@/store/mutation.types";
import api from "@/api/api";

export default {
    state: () => {
        return {
            roles: [],
            currentRole: null,
            permissions: [],
        }
    },
    mutations: {
        [mutypes.SET_ROLES]: (state, value) => state.roles = value,
        [mutypes.SET_CURRENT_ROLE]: (state, value) => state.currentRole = value,
        [mutypes.SET_ROLE_PERMISSIONS]: (state, value) => state.permissions = value,
    },
    getters: {
        getRoles: state => state.roles,
        getCurrentRole: state => state.currentRole,
        getRolePermissions: state => state.permissions
    },
    actions: {
        async indexRoles({commit,getters}){
            const response = await api.roles.indexRoles()
            commit(mutypes.SET_ROLES, response.data.roles)
            return response
        },
        async deleteRole({commit,getters},id){
          const response = await api.roles.deleteRole(id)
            let roles = getters.getRoles.filter(t => t.id !== id)
            commit(mutypes.SET_ROLES, roles);
        },
        async createRole({commit,getters}, params){
            const response = await api.roles.storeRole(params)
        },
        async indexRolePermissions({commit,getters}){
            const response = await api.roles.getPermissions()
            commit(mutypes.SET_ROLE_PERMISSIONS, response.data.permissions)
            return response
        },
        async showRole({commit,getters},id){
            const response = await api.roles.showRole(id)
            commit(mutypes.SET_CURRENT_ROLE, response.data.role)
            return response
        },
        async updateRole({commit,getters}, params){
            const response = await api.roles.updateRole(params.id, {name: params.name, permissions: params.permissions})
            return response
        }
    }
}