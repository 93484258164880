<template>
  <div class="complete-tournament-wrapper">
    <h5>Вы действительно хотите завершить турнир <b>{{ tournament.name }}</b>?</h5>
    <b-form @submit="submitForm">
      <b-form-group label="Дата завершения" label-for="game_time">
        <DatePicker mode="dateTime" v-model="formModel.completeDate" is24hr :model-config="modelConfig" :popover="{visibility: 'click'}">
          <template v-slot="{inputValue, inputEvents}">
            <b-form-input :value="inputValue" v-on="inputEvents" required />
          </template>
        </DatePicker>
      </b-form-group>
      <b-alert v-if="errorText" show variant="danger">{{ errorText }}</b-alert>
      <div class="form-footer">
        <b-button :disabled="isLoadingSaveForm" type="submit" variant="primary">
          {{ isLoadingSaveForm ? 'Завершаем...' : 'Завершить' }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import api from "@/api/api";

export default {
  name: "CompleteTournament",
  components: {
    DatePicker
  },
  computed: {
    ...mapState('moduleNewTournament', ['tournament'])
  },
  data() {
    return {
      formModel: {
        completeDate: ''
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
      errorText: null,
      isLoadingSaveForm: false
    }
  },
  methods: {
    async submitForm(event) {
      try {
        event.preventDefault()
        this.errorText = null
        this.isLoadingSaveForm = true
        const formData = new FormData()
        formData.append('end_time', this.formModel.completeDate + ':00')
        formData.append('_method', 'PUT')
        await api.tournament.update(this.tournament.id, formData)
        await this.$store.dispatch('moduleNewTournament/getTournament', {
          id: this.$route.params.standingId
        })
        this.$emit('closeModal')
      } catch (e) {
        const message = e.response.data.message
        let errors = ''
        if (e.response.data.errors) errors = Object.entries(e.response.data.errors).map(([ name, values ]) => `${ name }: ${ values.join(', ') }`).join('. ')
        this.errorText = `${ message }; ${ errors }.`
      } finally {
        this.isLoadingSaveForm = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.complete-tournament-wrapper {
  h5 {
    font-size: 16px;
    font-weight: 400;
  }
  .form-footer {
    border-top: 1px solid #dee2e6;
    text-align: right;
    padding-top: 15px;
  }
}
</style>