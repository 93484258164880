<template>
  <div class="editor-matches-wrapper">
    <header>
      <button class="button-back" @click="$emit('changeWorkspace', undefined)">
        <img :src="AngleLeftIcon" alt="" />
        Назад
      </button>
      <h2>
        Добавление и Редактирование матчей
      </h2>
    </header>
    <div v-if="isLoadingMatches" class="loading-wrapper">
      <b-spinner />
    </div>
    <template v-else>
      <div class="body">
        <MatchCard v-for="(match, index) in matches"
                   v-model="match.new"
                   :index="index"
                   :startEdit="!!match.startEdit"
                   @delete="deleteMatch"
                   :key="match.name + (match?.id || match.group_id)" />
        <b-alert class="mb-3" v-if="errorText" show variant="danger" style="margin: 10px 0 0 0">{{ errorText }}</b-alert>
        <div class="actions">
          <b-button @click="addMatch" variant="primary" class="add-match-button">
            Добавить матч
          </b-button>
          <b-button @click="saveMatches" :disabled="isLoadingSubmitMatches" variant="primary" class="save-match-button">
            {{ isLoadingSubmitMatches ? 'Сохраняем все...' : 'Сохранить все' }}
          </b-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from "@/api/api"

import AngleLeftIcon from '@/assets/icons/angle-left.svg'

import MatchCard from "@/components/Tournament/MatchCard.vue";

export default {
  name: "EditorMatches",
  components: {
    MatchCard
  },
  data() {
    return {
      AngleLeftIcon,
      matches: [],
      errorText: '',
      isLoadingMatches: false,
      isLoadingSubmitMatches: false,
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['selectedGroupId'])
  },
  methods: {
    async getMatches() {
      this.isLoadingMatches = true
      const response = await api.tournamentMatches.showMatches(this.selectedGroupId)
      this.matches = response.data?.group_matches.map((match) => ({ ...match, new: match })) || []
      this.isLoadingMatches = false
    },
    addMatch() {
      this.matches.push({
        startEdit: true,
        new: {
          name: '',
          lobby_number: '',
          password: '',
          status: 0,
          group_id: this.selectedGroupId,
          start_time: '',
        }
      })
    },
    async deleteMatch(index) {
      const foundMatch = this.matches.find((_, idx) => idx === index)
      if (foundMatch.id) {
        this.matches.splice(index, 1)
        await api.tournamentMatches.deleteMatch(foundMatch.id)
        const response = await api.tournamentMatches.showMatches(this.selectedGroupId)
        this.matches = response.data?.group_matches.map((match) => ({ ...match, new: match })) || []
        await this.$store.dispatch('moduleNewTournament/getTournament', {
          id: this.$route.params.standingId
        })
      } else {
        this.matches.splice(index, 1)
      }
    },
    async saveMatches() {
      try {
        this.errorText = ''
        this.isLoadingSubmitMatches = true
        await api.tournamentMatches.update({
          group_matches: this.matches.map((match) => ({ ...match.new }))
        })
        await this.getMatches()
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSubmitMatches = false
      }
    }
  },
  created() {
    this.getMatches()
  }
}
</script>

<style scoped lang="scss">
.editor-matches-wrapper {

  .body {
    padding-top: 20px;

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      & > * {
        margin: 0;
      }

      button {
        font-size: 13px;
        border-radius: 15px;
        display: block;

        @media screen and (max-width: 800px) {
          font-size: 11px;
        }

        @media screen and (max-width: 500px) {
          margin-bottom: 6px;
        }

        &.add-match-button {
          background-color: white !important;
          color: #7366ff;

          &:hover {
            background-color: #7366ff !important;
            color: white;
          }
        }
      }
    }

    & > .match-card-wrapper:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .loading-wrapper {
    min-height: 50vh;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: left;

    @media screen and (max-width: 560px) {
      display: block;
    }

    h2 {
      font-size: 24px;
      margin: 0 0 0 10px;

      @media screen and (max-width: 800px) {
        font-size: 22px;
      }

      @media screen and (max-width: 500px) {
        font-size: 18px;
      }

    }
  }

  .button-back {
    background: transparent;
    color: #7366ff;
    border: none;
    border-radius: 15px;
    padding: 8px 14px;
    font-size: 14px;
    transition: .2s;

    display: flex;
    align-items: center;

    &:hover {
      background: #dad6ff;
    }

    img {
      margin-right: 7px;
      height: 12px;
    }
  }
}
</style>