<template>
  <div v-if="stageData" class="review-stage-tournament">
    <div class="block">
      <label>Название этапа</label>
      <span>{{ stageData.name }}</span>
    </div>
    <div class="block">
      <label>Коэффициент этапа - Рейтинг команд</label>
      <span>{{ stageData.rating_coefficient }}</span>
    </div>
    <div class="block">
      <label>Коэффициент этапа - Рейтинг пользователя</label>
      <span>{{ stageData.player_rating_coefficient || 'Не заполнено' }}</span>
    </div>
    <div class="block">
      <label>Количество замен</label>
      <span>{{ stageData.replace_count }}</span>
    </div>
    <div class="block">
      <label>Статус</label>
      <span>{{ statuses.find((status) => status.value === stageData.status).text }}</span>
    </div>
    <div class="block">
      <label>Минимальной значение рейтинга для подачи в этап</label>
      <span>{{ stageData.threshold_rating }}</span>
    </div>
    <div class="block">
      <label>Коэффициент P (значимость стадии)</label>
      <span>{{ stageData.level_experience_coefficient }}</span>
    </div>
    <div class="block">
      <label>Кол-во команд, разрешенное для подачи в этап</label>
      <template v-if="divisions.length">
        <span v-for="division in divisions" :key="division.division_id">
          {{ division.division_name }}: {{ division.slot_count }}
        </span>
      </template>
      <template v-else>
        <span>Не заполнено</span>
      </template>
    </div>
    <div class="block">
      <label>Экстра очки опыта</label>
      <template v-if="divisions.length">
        <span v-for="extraPoints in stageData.extra_stage_points"
              :key="extraPoints.experience_points">
        {{ extraPoints.place + ' место' }}: {{ extraPoints['experience_points'] }}
      </span>
      </template>
      <span v-else>Не заполнено</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReviewStageTournament",
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      stageData: null,
      statuses: [
        {
          text: 'Не активно',
          value: 0
        },
        {
          text: 'Активно',
          value: 1
        },
      ],
    }
  },
  computed: {
    divisions() {
      if (!this.stageData?.stage_divisions) return []
      return this.stageData.stage_divisions.map((div) => ({
        division_id: div.division_id,
        division_name: div.division.name.ru,
        slot_count: div.slot_count
      }))
    }
  },
  methods: {
    async getStageData() {
      this.stageData = await this.$store.dispatch('moduleNewTournament/getStage', {
        id: this.params.stage.id
      })
    }
  },
  created() {
    this.getStageData()
  }
}
</script>

<style scoped lang="scss">
.review-stage-tournament {
  .block {
    display: flex;
    flex-direction: column;

    label {
      font-size: 17px;
      font-weight: 500;
      margin: 0;
    }

    span {
      color: gray;
    }
  }

  & > .block:not(:last-child) {
    margin-bottom: 20px;
  }
}
</style>