<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Рейтинг" title="Все команды - рейтинг"/>
      <b-card>
        <div class="d-flex justify-content-center mb-2 justify-content-md-between">
          <div>
            <label>Дивизион</label>
            <div class="d-flex flex-wrap align-items-center">
              <b-btn variant="primary"
                     class="m-md-2 mb-2 mr-2"
                     @click="getTeamsByDivision()">Все
              </b-btn>
              <div v-for="(division, idx) in newDivisionsList" :key="division.id" class="m-md-2 mb-2 mr-2">
                <b-btn
                    variant="primary"
                    @click="getTeamsByDivision(division.slug)"
                >
                  {{ division.name.ru }}
                </b-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap mb-5 align-items-center justify-content-between">
          <SearchInput v-model="searchForTeamsRatingModel" @search="getFilteredTeamsRating()"
                       :placeholder-name="'Поиск команд'"/>
          <div>
            <div class="mt-2">
              <b-btn @click="submitForm" :disabled="isLoadingRatingActualization"
                     :variant="isLoadingRatingActualization ? 'primary' : 'info'">
                {{ isLoadingRatingActualization ? 'Идет актуализация...' : 'Актуализировать рейтинг по турнирам' }}
              </b-btn>
            </div>
            <div style="color: #666666" class="mt-2">Дата актуализации: {{ actualizedRatingTime || '' }}</div>
          </div>
        </div>
        <div v-if="isLoadingRatingForTeams" class="loading-wrapper">
          <b-spinner class="spinner"></b-spinner>
        </div>
        <div v-else>
          <b-table
              responsive
              show-empty
              stacked="md"
              :striped="striped"
              :bordered="bordered"
              :fields="fields"
              :items="teamsRating"
              :current-page=0
              :per-page=10
              class="mb-5"
          >
            <template v-slot:cell(team)="{item}">
              {{ item.name }}
            </template>
            <template v-slot:cell(rating)="{item}">
              {{ item.rating.toFixed(2) }}
            </template>
            <template v-slot:cell(country)="{item}">
              {{ item.country?.name }}
            </template>
            <template v-slot:cell(count)="{item}">
              {{ item.tournament_count }}
            </template>
            <template v-slot:cell(last_data)="{item}">
              {{ item.tournament_last_date }}
            </template>
            <template v-slot:cell(created_at)="{item}">
              {{ item.created_at }}
            </template>
            <template v-slot:cell(action)="{item}">
              <div class="align-items-center">
                <router-link :to="{name:'team_ratings_info', params: {id: item.id }}">
                  <span><b-btn variant="primary" class="mr-2 p-1 px-2 mb-1">Посмотреть историю</b-btn></span>
                </router-link>
              </div>
            </template>
          </b-table>

          <b-col md="6" class="p-0">
            <b-pagination
                @change="handlePageChange"
                v-model="currentPage"
                :total-rows="total"
                :per-page="10"
                class="my-0"
            ></b-pagination>
          </b-col>
        </div>

      </b-card>
    </div>
  </div>
</template>

<script>
import {RatingAdapterMixin} from "../../mixins/Rating/RatingAdapterMixin";
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import {DivisionsAdapterMixin} from "@/mixins/Divisions/DivisionsAdapterMixin";
import SearchInput from "@/components/SearchInput.vue";
import api from "@/api/api";
import Vue from "vue";

export default {
  name: "ListTeamsRating",
  components: {SearchInput},
  mixins: [RatingAdapterMixin, ModalAdapterMixin, PaginationHelperMixin, DivisionsAdapterMixin],
  created() {
    (async () => {
      try {
        this.isLoadingRatingForTeams = true
        this.recordPageToStorage(this.currentPage, this.pageKey)
        await this.grabTeamsRating({
          pageNumber: this.getCurrentPageNumber(this.pageKey),
        })
        await this.grabNewDivisions({slug: 'pubg_mobile'})
        await this.getDivisionsSlug()


        // Получение даты актуализации рейтинга
        const actualizedTimeForTournamentsRating = api.rating.actualizedTime().then((response) => {
          this.actualizedRatingTime = response.data.data.lastActualizeDatetime || '';
        });

        // Получение доступных игр
        const gameList = api.games.index().then((gameList) => {
          this.gameId = gameList.data.games.find((game) => game.slug === 'pubg_mobile').id;
        });

        await Promise.all([actualizedTimeForTournamentsRating, gameList])
      } finally {
        this.isLoadingRatingForTeams = false
      }
    })()
  },
  data() {
    return {
      pageKey: 'ratingPageNumber',
      fields: [
        {key: 'id', label: 'id', sortable: true},
        {key: 'team', label: 'Команда', sortable: true},
        {key: 'rating', label: 'Рейтинг', sortable: true},
        {key: 'country', label: 'Страна', sortable: true},
        {key: 'count', label: 'Кол-во турниров', sortable: true},
        {key: 'last_data', label: 'Дата последнего турнира', sortable: true},
        {key: 'created_at', label: 'Дата создания', sortable: true},
        {key: 'action', label: 'Действия', sortable: false},
      ],
      striped: true,
      bordered: true,
      divisionsSlug: [],
      gameId: null,
      actualizedRatingTime: '',
      isLoadingRatingActualization: false,
    };
  },
  methods: {
    async submitForm() {
      try {
        this.isLoadingRatingActualization = true
        await api.rating.actualize(this.gameId)
        Vue.toasted.success("Успешно актуализирован", {
          position: 'top-right',
          duration: 3000
        })
      } catch (error) {
        Vue.toasted.error(error.response.data.message || "Не удалось актуализировать", {
          position: 'top-right',
          duration: 3000
        });
      } finally {
        this.isLoadingRatingActualization = false
      }
    },
    getDivisionsSlug() {
      this.divisionsSlug = this.newDivisionsList.map((division) => division.slug)
    },
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.grabTeamsRating({
        pageNumber: pageNumber,
        ...(this.searchForTeamsRatingModel && {search: this.searchForTeamsRatingModel}),
        ...(this.divisionsSlug.includes(this.selectedDivisionSlug) && {division: this.selectedDivisionSlug})
      })
    },
    getFilteredTeamsRating() {
      this.isLoadingRatingForTeams = true
      this.grabTeamsRating({
        search: this.searchForTeamsRatingModel,
        ...(this.divisionsSlug.includes(this.selectedDivisionSlug) && {division: this.selectedDivisionSlug})
      })
          .then(() => {
            this.isLoadingRatingForTeams = false
          })
    },
  },

};
</script>

<style>
.loading-wrapper {
  min-height: calc(100vh - 180px);
  display: flex;
  align-items: center;
  justify-content: center;

  & > .spinner {
    scale: 2;
  }
}
</style>