<template>

<div class="row d-flex justify-content-center justify-content-md-start" >
    <table class="mr-3 card" v-for="match in groupMatches" :key="match.id">
      <div class="px-2" >
      <tbody>
      <tr>
        <th class=""><p  style="font-size: 15px; margin-bottom: 5px">{{match.name}}</p></th>
      </tr>
      <tr>
        <td class=""><h6 class="font-weight-light"><i class="fa fa-sign-in text-info"></i>:{{match.lobby_number}}</h6></td>
      </tr>
      <tr>
        <td><h6 class="font-weight-light"><i class="fa fa-lock text-info"></i>:{{match.password}}</h6></td>
      </tr>

      </tbody>
      </div>
    </table>

</div>
</template>

<script>
import {TournamentStandingsAdapterMixin} from "../../../mixins/Tournament/TournamentStandingsAdapterMixin";

export default {
  name: 'InDataStandingsTournament',
  mixins:[TournamentStandingsAdapterMixin],

}
</script>