<template>
  <div>
    <Breadcrumbs main="Стримеры Wow" :title="wowStreamer.user.username"/>
    <div v-if="wowStreamer" class="video-container">
      <div class="container-fluid">
        <div class="row">
          <div v-if="activeStream.id" class="col-md-6">
            <div class="description-wrapper">
              <h2>{{ activeStream.name }}</h2>
              <p>{{ activeStream.description }}</p>
              <hr class="divider" />
              <ul class="streamer-wrapper">
                <li class="streamer-item">
                  <h3>Номер лобби:</h3>
                  <div class="stream-info">
                    <span class="highlight">{{ activeStream.data.lobby_number }}</span>
                    <div class="custom-tooltip" :class="{ 'copied': copied === 'lobby_number' }">
                      <img src="/copy-link-icon.svg" alt="Copy" class="copy-icon" @click="copyToClipboard(activeStream.data.lobby_number, 'lobby_number')" />
                      <span class="tooltip-text">{{ copied === 'lobby_number' ? 'Скопировано!' : 'Копировать' }}</span>
                    </div>
                  </div>
                </li>
                <li class="streamer-item">
                  <h3>Пароль:</h3>
                  <div class="stream-info">
                    <span class="highlight">{{ activeStream.data.password }}</span>
                    <div class="custom-tooltip" :class="{ 'copied': copied === 'password' }">
                      <img src="/copy-link-icon.svg" alt="Copy" class="copy-icon" @click="copyToClipboard(activeStream.data.password, 'password')" />
                      <span class="tooltip-text">{{ copied === 'password' ? 'Скопировано!' : 'Копировать' }}</span>
                    </div>
                  </div>
                </li>
                <hr class="divider" />
                <li class="streamer-prize">
                  <h3>Приз:</h3>
                  <span class="highlight">{{ activeStream.data.prize }} UC</span>
                </li>
              </ul>
              <button @click="showDeactivateModal" class="deactivate-button">Деактивировать</button>
            </div>
          </div>
          <div v-else class="col-md-6">
            <div class="no-active-stream">
              <p>Нет активного стрима</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="video-wrapper">
              <iframe v-if="videoUrl" :src="videoUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div class="stream-history">
          <h3>История стримов</h3>
          <ul v-if="sortedStreams.length" class="dropdown">
            <li v-for="stream in sortedStreams" :key="stream.id" class="dropdown-item">
              <div class="stream-info">
                <h4>{{ stream.name }} <span v-if="stream.id === activeStream.id" class="active-dot"></span></h4>
                <p>{{ stream.description }}</p>
                <div class="stream-details">
                  <p><strong>Номер лобби:</strong> {{ stream.data.lobby_number }}</p>
                  <p><strong>Пароль:</strong> {{ stream.data.password }}</p>
                  <p><strong>Приз:</strong> {{ stream.data.prize }} UC</p>
                  <p><a :href="stream.link" target="_blank" class="stream-link">Ссылка на стрим</a></p>
                </div>
              </div>
            </li>
          </ul>
          <p v-else class="no-streams">Нет доступных стримов</p>
        </div>
      </div>
    </div>
    <p v-else>Нет данных о стримере</p>
    <Modal
      name="DeactivateStream"
      title="Деактивация стрима"
      v-model="isShowDeactivateModal"
      :params="{ streamId: activeStream.id, streamerId: wowStreamer.user.id }"
      @close="resetModal"
      @actionSuccess="handleDeactivateSuccess"
    />
  </div>
</template>

<script>
import { WowStreamersAdapterMixin } from "@/mixins/WowStreamers/WowStreamersAdapterMixin";
import Modal from "@/components/modals/index.vue";

export default {
  name: "WowStreamerInfo",
  mixins: [WowStreamersAdapterMixin],
  components: {
    Modal,
  },
  data() {
    return {
      copied: null,
      isShowDeactivateModal: false,
    };
  },
  computed: {
    wowStreamer() {
      return this.getWowStreamer;
    },
    activeStream() {
      return this.wowStreamer?.active_wow_stream || {};
    },
    sortedStreams() {
      const streams = this.wowStreamer?.wow_streams || [];
      return streams.sort((a, b) => b.id - a.id);
    },
    videoUrl() {
      const link = this.activeStream.link;
      if (this.isTwitchStream(link)) {
        const channelName = this.extractTwitchChannelName(link);
        return channelName ? `https://player.twitch.tv/?channel=${channelName}&parent=${window.location.hostname}` : '';
      } else if (this.isYouTubeStream(link)) {
        const videoId = this.extractYouTubeVideoId(link);
        return videoId ? `https://www.youtube.com/embed/${videoId}` : '';
      }
      return '';
    }
  },
  async created() {
    await this.fetchWowStreamer(this.$route.params.id);
  },
  methods: {
    isYouTubeStream(url) {
      const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
      return youtubeRegex.test(url);
    },
    isTwitchStream(url) {
      const twitchRegex = /^(https?:\/\/)?(www\.)?(twitch\.tv)\/.+$/;
      return twitchRegex.test(url);
    },
    extractYouTubeVideoId(url) {
      const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/i;
      const match = url.match(regex);
      return match ? match[1] : null;
    },
    extractTwitchChannelName(url) {
      const regex = /^(?:https?:\/\/)?(?:www\.)?twitch\.tv\/([^/?]+)(?:$|[?/])/i;
      const match = url.match(regex);
      return match ? match[1] : null;
    },
    copyToClipboard(text, field) {
      navigator.clipboard.writeText(text).then(() => {
        this.copied = field;
        setTimeout(() => {
          this.copied = null;
        }, 2000);
      });
    },
    showDeactivateModal() {
      this.isShowDeactivateModal = true;
    },
    handleDeactivateSuccess() {
      this.isShowDeactivateModal = false;
      this.fetchWowStreamer(this.$route.params.id);
      this.$router.push("/wowstreamers");
    },
    resetModal() {
      this.isShowDeactivateModal = false;
    },
  }
};
</script>

<style scoped>
.video-container {
  display: grid;
  gap: 30px;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 20px;
  padding-bottom: 50px;
  border-radius: 20px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    padding: 0;
  }
}

.video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 15px;
}

.no-active-stream {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #f9f9f9;
  border-radius: 20px;
  padding: 20px;
  color: #3C3C3C;
  font-size: 1.2em;
}

.description-wrapper {
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  text-wrap: wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  border-radius: 20px;
  @media (max-width: 1024px) {
    padding-top: 16px;
  }

  .deactivate-button {
    margin: 20px 0;
    padding: 14px 24px;
    background-color: #F73164;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .deactivate-button:hover {
    background-color: #EC0944;
  }

  h2 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #7366FF;
    @media (max-width: 768px) {
      font-size: 1.5em;
    }
  }

  h3 {
    font-size: 1.1em;
    font-weight: 500;
    color: #7366FF;
  }

  p {
    font-size: 1em;
    margin-bottom: 20px;
    color: #3C3C3C;
    @media (max-width: 768px) {
      font-size: 0.9em;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      font-size: 1em;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        font-size: 0.9em;
      }

      strong {
        font-weight: bold;
        margin-right: 8px;
      }

      .copy-icon {
        margin-left: 8px;
        cursor: pointer;
        width: 20px;
        height: 20px;
      }

      &:before {
        margin-right: 8px;
        color: #9147ff;
      }
    }

    .stream-info {
      display: flex;
      align-items: center;
    }

    .highlight {
      color: #3C3C3C;
      font-weight: 600;
    }

    .streamer-item,
    .streamer-prize {
      margin: 20px 0;
      display: flex;
      align-items: center;
      gap: 30px;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.stream-history {
  margin-top: 30px;
}

.dropdown {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-item {
  padding: 10px;
  margin: 5px 0;
  background: #f9f9f9;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}

.dropdown-item h4 {
  margin: 0 0 5px 0;
  font-size: 1.2em;
  color: #7366FF;
  display: flex;
  align-items: center;
}

.dropdown-item p {
  margin: 0 0 5px 0;
  font-size: 1em;
  color: #3C3C3C;
}

.stream-details p {
  margin: 0;
  font-size: 0.9em;
}

.stream-link {
  color: #7366FF;
  text-decoration: none;
}

.stream-link:hover {
  text-decoration: underline;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.3);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.active-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  background-color: green;
  border-radius: 50%;
  animation: pulsate 2s infinite;
}

.no-streams {
  font-size: 1.2em;
  color: #3C3C3C;
  text-align: center;
  margin-top: 20px;
}

.breadcrumbs,
.card-header {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: #7366FF;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateY(10px);
  font-size: 14px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.custom-tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #7366FF transparent transparent transparent;
}

.custom-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

@keyframes copied-animation {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.custom-tooltip.copied .tooltip-text {
  background-color: #4CAF50;
}

.custom-tooltip.copied .tooltip-text::after {
  border-color: #4CAF50 transparent transparent transparent;
}

.custom-tooltip.copied img {
  animation: copied-animation 0.3s ease-in-out;
}
</style>