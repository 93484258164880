import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            organizers: [],
            showOrganizer: {}
        }
    },
    mutations: {
        [mutypes.SET_ORGANIZERS]: (state, value) => state.organizers = value,
        [mutypes.SET_ORGANIZER_SHOW]: (state, value) => state.showOrganizer = value,
    },

    getters: {
        getOrganizersList: state => state.organizers,
        getOrganizerShow: state => state.showOrganizer,
    },

    actions: {
        grabOrganizersList: async ({commit, getters}) => {
            let response = await api.organizers.index();
            commit(mutypes.SET_ORGANIZERS, response.data.organizers);

        },
        showOrganizer: async ({commit, getters}, id) => {
            let response = await api.organizers.show(id);
            commit(mutypes.SET_ORGANIZER_SHOW, response.data.organizer);
        },
        async updateOrganizer ({commit, getters}, params){
            await api.organizers.update(
                params.organizerId,
                {
                    name: params.name,
                    platform_id: params.platform_id,
                    link: params.link,
                    rating: params.rating,
                })
                .then(() => {
                    this._vm.$toast("Вы успешно изменили данные");
                })
                .catch(() => {
                    this._vm.$toast("Не удалось изменить данные");
                });
        },
        async storeOrganizer ({commit, getters}, params) {
           await api.organizers.store({
                name: params.name,
                platform_id: params.platform_id,
                link: params.link,
                rating: params.rating,
            })
                .then(() => {
                    this._vm.$toast("Вы успешно добавили организатора");
                })
                .catch(() => {
                    this._vm.$toast("Не удалось добавить организатора");
                });
        },
        deleteOrganizer: async ({commit, dispatch}, params) => {
            api.organizers.delete(params.organizerId).then(() => {
                dispatch('grabOrganizersList');
            })
        }
    }
}