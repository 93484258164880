import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const UserFakeAdapterMixin = {
    data() {
        return {
            userFakeOwner: '',
            userFakeMember: '',
            newGameId: 1,
            membersSearch:[],
        }
    },
    computed: {
        ...mapGetters({
            getUserFake: 'getUserFake',
            getUserFakeName: 'getUserFakeName',
            getUserFakeEmail: 'getUserFakeEmail',
            getUserFakePassword: 'getUserFakePassword',
            getUserFakeTeamName: 'getUserFakeTeamName'
        }),
        userFake: { get(){ return this.getUserFake               }, set(value){this.setUserFake(value)}},
        userFakeName: { get(){ return this.getUserFakeName               }, set(value){this.setUserFakeName(value)}},
        userFakeTeamName: { get(){ return this.getUserFakeTeamName               }, set(value){this.setUserFakeName(value)}},
        userFakeEmail: { get(){ return this.getUserFakeEmail              }, set(value){this.setUserFakeTeamName(value)}},
        userFakePassword: { get(){ return this.getUserFakePassword              }, set(value){this.setUserFakePassword(value)}},
    },
    methods: {
        ...mapMutations({
            setUserFake             : mutypes.SET_USER_FAKE,
            setUserFakeName         : mutypes.SET_USER_FAKE_NAME,
            setUserFakeEmail        : mutypes.SET_USER_FAKE_EMAIL,
            setUserFakePassword     : mutypes.SET_USER_FAKE_PASSWORD,
            setUserFakeTeamName     : mutypes.SET_USER_FAKE_TEAM_NAME
        }),
        ...mapActions([
            'indexUserFake',
            'showUserFake',
            'storeUserFake',
            'updateUserFake',
            'deleteUserFake',
        ]),
    }
}