<template>
  <div>
    <Breadcrumbs main="Бан" title="Список банов"/>
    <!-- Container-fluid starts-->
    <b-card>
      <div class="d-flex">
        <SearchInput v-model="searchForBanedUsersModel" @search="getFilteredBanedUsers()" :placeholder-name="'Поиск пользователей'"/>
      </div>
      <div class="notification-for-search mb-4">Поиск по id, username, почте, названию команды, игровым данным: pubg id pubg nick</div>
      <div v-if="isLoadingBanedUsers" class="loading-wrapper">
        <b-spinner class="spinner"></b-spinner>
      </div>
      <b-table
          v-else
          responsive
          show-empty
          stacked="md"
          fixed
          :striped="striped"
          :bordered="bordered"
          :fields="fields"
          :items="usersBanList"
          :current-page=0
          :per-page=10
          class="mb-5"
      >
        <template #cell(username)="{item}">
          {{ item.username }}
        </template>
        <template #cell(contact)="{item}">
          <div v-for="contacts in item.socialAccounts" :key="contacts.id">
            {{ contacts.provider }}
          </div>
        </template>
        <template #cell(title)="{item}">
          <div v-for="title in item.currentBans" :key="title.id">
            {{ title.game_name }}
          </div>
        </template>
        <template #cell(gameNick)="{item}">
          <div v-for="gameProfile in item.gameProfiles" :key="gameProfile.id">
            <div>Nick - {{ gameProfile.fields.nick }}</div>
            <div>Pubg_id - {{ gameProfile.fields.pubg_id }}</div>
          </div>
        </template>
        <template #cell(start)="{item}">
          <div v-for="startTime in item.currentBans" :key="startTime.id">
            {{ startTime.from }}
          </div>
        </template>
        <template #cell(end)="{item}">
          <div v-for="endTime in item.currentBans" :key="endTime.id">
            {{ endTime.to }}
          </div>
        </template>
        <template #cell(reason)="{item}">
          <div v-for="reason in item.currentBans" :key="reason.id">
            {{ reason.description }}
          </div>
        </template>
        <template #cell(who)="{item}">
          <div v-for="who in item.currentBans" :key="who.id">
            {{ who.author.username }}
          </div>
        </template>
        <template #cell(action)="{item}">
          <div class="d-flex flex-wrap">
            <div v-for="action in item.currentBans" :key="action.id" class="mb-2 mr-1">
              <b-btn variant="warning p-1" @click="deleteUserBan({userId: action.id})"><i
                  class="fa fa-ban"></i>
              </b-btn>
            </div>
            <b-btn variant="primary" class="mr-1 p-1 mb-2" @click="showUserInfo(item.id)"><i
                class="fa fa-eye"></i></b-btn>
            <b-btn variant="primary" class="mr-1 p-1 mb-2" @click="showBansInfo(item.id)">
              <i class="fa fa-solid fa-arrow-right"></i>
            </b-btn>
          </div>

        </template>
      </b-table>
      <b-col md="6" class="p-0">
        <b-pagination
            @change="handlePageChange"
            v-model="banUsersCurrentPage"
            :total-rows="totalBanUsersPagination"
            :per-page="10"
            class="my-0"
        ></b-pagination>
      </b-col>
    </b-card>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import {UserBanAdapterMixin} from "@/mixins/UserBan/UserBanAdapterMixin";
import {UsersAdapterMixin} from "@/mixins/Users/UsersAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: "ListUsersBan",
  components: {SearchInput},
  mixins: [UserBanAdapterMixin, UsersAdapterMixin, PaginationHelperMixin],
  created() {
    try {
      this.isLoadingBanedUsers = true
      this.recordPageToStorage(this.banUsersCurrentPage, this.pageKey)
      this.indexUsersBan({
        pageNumber: this.getCurrentPageNumber(this.pageKey),
      })
    } finally {
      this.isLoadingBanedUsers = false
    }
  },
  data() {
    return {
      searchForBanedUsersModel: '',
      pageKey: 'pageNumberBanUsers',
      fields: [
        {key: 'id', label: '№', sortable: true,},
        {key: 'username', label: 'Имя пользователя', sortable: true,},
        {key: 'contact', label: 'Контакты', sortable: true,},
        {key: 'title', label: 'Тайтл игры', sortable: true,},
        {key: 'gameNick', label: 'Игровые данные', sortable: true,},
        {key: 'start', label: 'Дата начала бана', sortable: true,},
        {key: 'end', label: 'Дата окончания бана', sortable: true,},
        {key: 'reason', label: 'Причина бана', sortable: true,},
        {key: 'who', label: 'Кто забанил', sortable: true,},
        {key: 'action', label: 'Действия', sortable: false},
      ],
      striped: true,
      bordered: true,
      isLoadingBanedUsers: false
    };
  },
  methods: {
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.indexUsersBan({
        pageNumber: pageNumber,
        ...(this.searchForBanedUsersModel && {search: this.searchForBanedUsersModel}),
      })
    },
    getFilteredBanedUsers() {
      this.isLoadingBanedUsers = true
      this.indexUsersBan({search: this.searchForBanedUsersModel})
          .then(() => {
            this.isLoadingBanedUsers = false
          })
    },
  },
}
</script>
