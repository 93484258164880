<template>
    <div>
        <Breadcrumbs main="Организации" :title="`Редактирование организатора ${showOrganizer.name}`"/>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Редактирование организатора {{showOrganizer.name}}</h5>
                        </div>
                        <div class="card-body">
                            <b-card>
                                <div class="mt-3">
                                    <label class="font-weight-bold">Наименование</label>
                                    <ValidationProvider rules="min:2|required" v-slot="{errors}">
                                        <b-form-input v-model="showOrganizer.name" :placeholder="showOrganizer.name ? showOrganizer.name : 'Не указано'"></b-form-input>
                                        <div class="text-danger">{{ errors[0] }}</div>
                                    </ValidationProvider>
                                </div>
                                <div class="mt-3">
                                    <label class="font-weight-bold">Ссылка на сайт</label>
                                    <b-form-input v-model="showOrganizer.link" :placeholder="showOrganizer.link ? showOrganizer.link : 'Не указано'"></b-form-input>
                                </div>

                                <div class="mt-3">
                                    <label class="font-weight-bold">Платформа</label>
                                    <ValidationProvider rules="required" v-slot="{errors}">
                                        <b-form-select :options="platforms" v-model="showOrganizer.platform.id"></b-form-select>
                                        <div class="text-danger">{{errors[0]}}</div>
                                    </ValidationProvider>
                                </div>
                                <div class="mt-3">
                                    <label class="font-weight-bold">Рейтинг</label>
                                        <b-form-input v-model="showOrganizer.rating" :placeholder="showOrganizer.rating ? showOrganizer.rating : 'Не указано'"></b-form-input>
                                </div>
                                <b-btn @click.prevent="updateOrganizer({
                                    organizerId: $route.params.id,
                                    name: showOrganizer.name,
                                    platform_id: showOrganizer.platform.id,
                                    link: showOrganizer.link,
                                    rating: Number.parseInt(showOrganizer.rating)
                                })" variant="primary" class="mt-4">Сохранить</b-btn>
                            </b-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {OrganizersAdapterMixin} from "@/mixins/Organizers/OrganizersAdapterMixin";
import {PlatformsAdapterMixin} from "@/mixins/Platforms/PlatformsAdapterMixin";
import {TournamentAdapterMixin} from "@/mixins/Tournament/TournamentAdapterMixin";

export default {
    name: "EditOrganizer",
    mixins: [ModalAdapterMixin, OrganizersAdapterMixin, PlatformsAdapterMixin, TournamentAdapterMixin],
    created() {
        this.showOrganizer(this.$route.params.id);
        this.grabPlatformsList();
    },
}
</script>

<style scoped>

</style>