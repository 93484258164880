<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Стримеры Wow" title="Забаненные стримеры Wow"/>
      <b-card>
        <div class="d-flex">
          <SearchInput v-model="searchModel" @search="getFilteredStreamers()" :placeholder-name="'Поиск забаненных стримеров Wow'"/>
        </div>
        <div class="notification-for-search mb-4">Поиск по идентификатору, имени пользователя, электронной почте или контактам</div>
        <div v-if="isLoading" class="loading-wrapper">
          <b-spinner class="spinner"></b-spinner>
        </div>
        <b-table
            v-else
            responsive
            show-empty
            stacked="md"
            :striped="striped"
            :bordered="bordered"
            :fields="fields"
            :items="currentList"
            :tbody-tr-class="trClass"
            class="mb-5"
        >
          <template #cell(username)="data">
            <div>{{ data.item.user.username }}</div>
          </template>
          <template #cell(email)="data">
            <div>{{ data.item.user.email }}</div>
          </template>
          <template #cell(ban_description)="data">
            <div>{{ data.item.ban_description }}</div>
          </template>
          <template #cell(banned_at)="data">
            <div>{{ new Date(data.item.banned_at).toLocaleString() }}</div>
          </template>
          <template #cell(action)="data">
            <div class="row justify-content-center">
              <b-btn variant="success" class="p-1 m-1" @click="showUnbanModal(data.item.id)">
                <i class="fa fa-check mr-1"></i>Разбанить
              </b-btn>
            </div>
          </template>
        </b-table>
        <b-col md="6" class="p-0">
          <b-pagination
              @change="handlePageChange"
              v-model="currentPage"
              :total-rows="totalPagination"
              :per-page="10"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-card>
    </div>
    <Modal
      name="ConfirmUnbanWowStreamer"
      title="Разбанить стримера"
      v-model="isShowUnbanWowStreamerModal"
      :params="{ id: selectedStreamerId }"
      @close="resetModal"
      @unbanSuccess="refreshStreamers"
    />
  </div>
</template>

<script>
import { WowStreamersAdapterMixin } from "@/mixins/WowStreamers/WowStreamersAdapterMixin";
import SearchInput from "@/components/SearchInput.vue";
import Modal from "@/components/modals/index.vue";

export default {
  name: "ListBannedWowStreamers",
  components: { SearchInput, Modal },
  mixins: [WowStreamersAdapterMixin],
  async created() {
    this.isLoading = true;
    this.currentListType = 'banned';
    
    try {
      await this.fetchCurrentList({
        pageNumber: this.getCurrentPage
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },
  data() {
    return {
      searchModel: '',
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'username', label: 'Имя пользователя', sortable: true },
        { key: 'email', label: 'Электронная почта', sortable: true },
        { key: 'ban_description', label: 'Описание бана', sortable: true },
        { key: 'banned_at', label: 'Дата бана', sortable: true },
        { key: 'action', label: 'Инструменты', sortable: false },
      ],
      striped: true,
      bordered: true,
      isLoading: false,
      isShowUnbanWowStreamerModal: false,
      selectedStreamerId: null,
    };
  },
  methods: {
    handlePageChange(pageNumber) {
      this.fetchCurrentList({
        pageNumber,
        ...(this.searchModel && { search: this.searchModel }),
      });
    },
    getFilteredStreamers() {
      this.isLoading = true;
      this.fetchCurrentList({ search: this.searchModel }).finally(() => {
        this.isLoading = false;
      });
    },
    showUnbanModal(streamerId) {
      this.selectedStreamerId = streamerId;
      this.isShowUnbanWowStreamerModal = true;
    },
    refreshStreamers() {
      this.getFilteredStreamers();
    },
    resetModal() {
      this.isShowUnbanWowStreamerModal = false;
      this.selectedStreamerId = null;
    },
    async unbanEntry(id) {
      await this.unbanWowStreamer(id);
      this.getFilteredStreamers();
    },
    trClass() {
      return '';
    },
  },
};
</script>

<style scoped>
.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.row .b-btn {
  margin-top: 5px;
  margin-bottom: 5px;
}

@media (max-width: 767.98px) {
  .row .b-btn {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>