import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const RolesAdapterMixin = {
    data() {
        return {
            newRoleName: null,
            newPermissions: null,
        }
    },
    computed: {
        ...mapGetters({
            getRoles: 'getRoles',
            getCurrentRole: 'getCurrentRole',
            getRolePermissions: 'getRolePermissions'
        }),
        roles: {
            get() {
                return this.getRoles
            }, set(value) {
                this.setRoles(value)
            }
        },
        currentRole: {
            get() {
                return this.getCurrentRole
            }, set(value) {
                this.setCurrentRole(value)
            }
        },
        rolePermissions: {
            get() {
                return this.getRolePermissions
            }, set(value) {
                this.setRolePermissions(value)
            }
        },
    },
    methods: {
        ...mapMutations({
            setRoles: mutypes.SET_ROLES,
            setCurrentRole: mutypes.SET_CURRENT_ROLE,
            setRolePermissions: mutypes.SET_ROLE_PERMISSIONS
        }),
        ...mapActions([
            'indexRoles',
            'deleteRole',
            'createRole',
            'indexRolePermissions',
            'showRole',
            'updateRole'
        ]),
        async createRoleFunc(params) {
            const values = []
            params.permissions.forEach((el) => {
                values.push(el.id)
            })
            await this.createRole({name: params.name, permissions: values}).then(() => {
                this.$router.push({name: 'roles'})
            })
        },
        async updateRoleFunc(){
            const values = []
            this.currentRole.permissions.forEach((el)=>{
                values.push(el.id)
            })
            await this.updateRole({id: this.$route.params.id, name: this.currentRole.name, permissions: values}).then(()=>{
                this.$router.push({name: 'roles'})
            })
        }
    }
}