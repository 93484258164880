<template>
  <div>
    <div class="d-flex  mb-5">
      <div>
        <label>Search</label>
        <b-form @submit.prevent="filterTeams()">
          <b-input type="search" class="w-50" v-model="dataForFilter"/>
          <div class="mt-4">
            <b-form-checkbox-group
                v-model="filteredArray"
                :options="filters"
            >
            </b-form-checkbox-group>
          </div>
          <b-btn variant="primary"
                 @click.prevent="filterTeams()"
                 class="mt-2 ml-2 row justify-content-center justify-content-md-start">Поиск
          </b-btn>
        </b-form>
      </div>
    </div>
    <b-table
        responsive
        show-empty
        stacked="md"
        :striped="striped"
        :bordered="bordered"
        :fields="fields"
        :items="teams"
        :current-page=0
        :per-page=10
        class="mb-5">

      <template #cell(plus)="data">
        <div class="mb-2 row justify-content-center">
          <b-dropdown
              class="my-class"
              style="position: static"
              @hide="resetCheckbox()"
              variant="primary"
              checkbox-menu
              allow-focus
              dropright>
            <template #button-content>
              <i class="fa fa-plus-square-o" style="font-size: 20px;"></i>
            </template>
            <div class="d-flex justify-content-center">
              <b-btn :disabled="selectedUsersId.length <= 2" @click="addMember(data.item.id, selectedUsersId)">
                Сохранить
              </b-btn>
            </div>
            <b-dropdown-form
                v-for="members in data.item.members"
                :key="members.id"
                :value="members.id">
              <b-form-checkbox
                  :value="members.id"
                  v-model="selectedUsersId">
                {{ members.username }}
              </b-form-checkbox>
            </b-dropdown-form>
          </b-dropdown>
        </div>
      </template>
      <template #cell(team_name)="data">
        {{ data.item.name }}
      </template>
      <template #cell(team_cap)="data">
        {{ data.item.teamOwner?.full_name }}
      </template>
    </b-table>

    <b-col md="6" class="p-0">
      <b-pagination
          @change="handlePageChange"
          v-model="currentPage"
          :total-rows="total"
          :per-page="10"
          class="my-0"
      ></b-pagination>
    </b-col>
  </div>

</template>

<script>
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {TeamsAdapterMixin} from "@/mixins/Teams/TeamsAdapterMixin";
import {TournamentStandingsAdapterMixin} from "@/mixins/Tournament/TournamentStandingsAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";

export default {
  name: "AllTeamsStandingTournament",
  mixins: [TeamsAdapterMixin, ModalAdapterMixin, TournamentStandingsAdapterMixin, PaginationHelperMixin],
  data() {
    return {
      filteredArray: [],
      dataForFilter: '',
      selectedUsersId: [],
      pageKey: 'pageNumberTeamList',
      fields: [
        {key: 'plus', label: 'Добавить',},
        {key: 'id', label: 'id', sortable: true,},
        {key: 'team_name', label: 'Название команды', sortable: false,},
        {key: 'team_cap', label: 'Капитан команды', sortable: false,},
        {key: 'contacts', label: 'Контакты', sortable: false},
      ],
      filters: {
        name: {text: "Найти по названию команды", value: "name"},
        userName: {text: "Найти по никнейму капитана", value: "userName"},
      },
      striped: true,
      bordered: true
    }
  },
  created() {
    this.grabStandingTournamentWithId(this.$route.params.standingId);
    this.recordPageToStorage(this.currentPage, this.pageKey)
    this.indexTeams({
      pageNumber: this.getCurrentPageNumber(this.pageKey),
    })
  },
  methods: {
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.indexTeams({
        pageNumber: pageNumber,
      })
    },
    addMember(team_id, user_ids) {
      this.selectedUsersId = []
      this.transferTeamWithMembersToTournament({
        stage_id: this.$route.params.stage_id,
        group_id: this.$route.params.group_id,
        team_id: team_id,
        user_ids: user_ids
      })
    },
    resetCheckbox() {
      this.selectedUsersId = [];
    },
    filterTeams() {
      let filteredData = {};
      this.filteredArray.forEach((item) => {
        filteredData[item] = this.dataForFilter;
      })
      this.indexTeams(filteredData);
    },
  },
}
</script>

<style>
.my-class .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
</style>