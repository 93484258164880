<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Стримеры Wow" title="Заявки стримеров Wow"/>
      <b-card>
        <div class="d-flex">
          <SearchInput v-model="searchModel" @search="getFilteredStreamers()" :placeholder-name="'Поиск заявок стримеров Wow'"/>
        </div>
        <div class="notification-for-search mb-4">Поиск по идентификатору, имени пользователя, электронной почте или контактам</div>
        <div v-if="isLoading" class="loading-wrapper">
          <b-spinner class="spinner"></b-spinner>
        </div>
        <b-table
            v-else
            responsive
            show-empty
            stacked="md"
            :striped="striped"
            :bordered="bordered"
            :fields="fields"
            :items="currentList"
            :tbody-tr-class="trClass"
            class="mb-5"
        >
          <template #cell(username)="data">
            <div>{{ data.item.user.username }}</div>
          </template>
          <template #cell(telegram)="data">
            <a :href="data.item.telegram" target="_blank">{{ data.item.telegram }}</a>
          </template>
          <template #cell(channel)="data">
            <a :href="data.item.channel" target="_blank">{{ data.item.channel }}</a>
          </template>
          <template #cell(followers_count)="data">
            <div>{{ data.item.followers_count }}</div>
          </template>
          <template #cell(action)="data">
            <div class="row justify-content-center">
              <b-btn variant="success" class="p-1 m-1" @click="showApproveModal(data.item.id)">
                <i class="fa fa-check mr-1"></i>Одобрить
              </b-btn>
              <b-btn variant="danger" class="p-1 m-1" @click="showRejectModal(data.item.id)">
                <i class="fa fa-times mr-1"></i>Отклонить
              </b-btn>
            </div>
          </template>
        </b-table>
        <b-col md="6" class="p-0">
          <b-pagination
              @change="handlePageChange"
              v-model="currentPage"
              :total-rows="totalPagination"
              :per-page="10"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-card>
    </div>
    <Modal
      name="ConfirmApproveWowStreamer"
      title="Одобрение стримера"
      v-model="isShowApproveModal"
      :params="{ id: selectedStreamerId }"
      @close="resetModal"
      @actionSuccess="refreshStreamers"
    />
    <Modal
      name="ConfirmRejectWowStreamer"
      title="Отклонение стримера"
      v-model="isShowRejectModal"
      :params="{ id: selectedStreamerId }"
      @close="resetModal"
      @actionSuccess="refreshStreamers"
    />
  </div>
</template>

<script>
import { WowStreamersAdapterMixin } from "@/mixins/WowStreamers/WowStreamersAdapterMixin";
import SearchInput from "@/components/SearchInput.vue";
import Modal from "@/components/modals/index.vue";

export default {
  name: "ListWowStreamerEntries",
  components: { SearchInput, Modal },
  mixins: [WowStreamersAdapterMixin],
  async created() {
    this.isLoading = true;
    this.currentListType = 'entries';
    
    try {
      await this.fetchCurrentList({
        pageNumber: this.getCurrentPage
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },
  data() {
    return {
      searchModel: '',
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'username', label: 'Имя пользователя', sortable: true },
        { key: 'telegram', label: 'Telegram', sortable: true },
        { key: 'channel', label: 'Канал', sortable: true },
        { key: 'followers_count', label: 'Количество подписчиков', sortable: true },
        { key: 'action', label: 'Действия', sortable: false },
      ],
      striped: true,
      bordered: true,
      isLoading: false,
      isShowApproveModal: false,
      isShowRejectModal: false,
      selectedStreamerId: null,
    };
  },
  methods: {
    handlePageChange(pageNumber) {
      this.fetchCurrentList({
        pageNumber,
        ...(this.searchModel && { search: this.searchModel }),
      });
    },
    getFilteredStreamers() {
      this.isLoading = true;
      this.fetchCurrentList({ search: this.searchModel }).finally(() => {
        this.isLoading = false;
      });
    },
    showApproveModal(streamerId) {
      this.selectedStreamerId = streamerId;
      this.isShowApproveModal = true;
    },
    showRejectModal(streamerId) {
      this.selectedStreamerId = streamerId;
      this.isShowRejectModal = true;
    },
    refreshStreamers() {
      this.getFilteredStreamers();
    },
    resetModal() {
      this.isShowApproveModal = false;
      this.isShowRejectModal = false;
      this.selectedStreamerId = null;
    },
    trClass() {
      return '';
    },
  },
};
</script>

<style scoped>
.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.row .b-btn {
  margin-top: 5px;
  margin-bottom: 5px;
}

@media (max-width: 767.98px) {
  .row .b-btn {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
