export const ALL = 'all';
export const ENTRIES = 'entries';
export const APPROVED = 'approved';
export const REJECTED = 'rejected';
export const BANNED = 'banned';

export const ListTypes = {
    [ALL]: 'all',
    [ENTRIES]: 'entries',
    [APPROVED]: 'approved',
    [REJECTED]: 'rejected',
    [BANNED]: 'banned',
};