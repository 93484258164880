<template>
  <div>
    <b-form-input
        :disabled="showEventData?.type !== 'not_sended'"
        :value="$props.defaultRate"
        @change="event => changeRate(event)"
        style="font-size: 16px; width: 100px"
    >
    </b-form-input>
  </div>
</template>

<script>
import {BookAdapterMixin} from "@/mixins/Book/BookAdapterMixin";

export default {
  name: "RateInput",
  mixins: [BookAdapterMixin],
  updated() {
    if (this.$props.defaultRate !== 0) {
      this.changeRate(this.$props.defaultRate)
    }
  },
  props: {
    defaultRate: {
      type: Number,
      default: 0
    },
    teamId: {
      type: Number,
      default: 0
    },
  },
  methods: {
    changeRate(rate) {
      if (rate) {
        this.addRate({team_id: this.teamId, rate: Number.parseInt(rate)})
      }
    }
  },
}
</script>
