<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Фейковые пользователи" title="Список фейковых пользователей"/>
      <Modal name="DeleteFakeUser" title="Удаление фейкового пользователя" v-model="isShowDeleteFakeUserModal"
             :params="{ id: selectedFakeUserId }"/>
      <Modal name="EditFakeUser" title="Редактирование фейк пользователя" v-model="isShowEditFakeUserModal"
             :params="{ id: selectedFakeUserId }"/>
      <Modal name="CreateFakeUser" title="Создание фейк пользователя" v-model="isShowCreateFakeUserModal"/>
      <b-card>
        <div class="d-flex flex-wrap justify-content-between  align-items-center mb-5">
          <SearchInput v-model="searchForFakeUsersModel" @search="getFilteredFakeUsers()" :placeholder-name="'Поиск фейк пользователей'"/>
          <b-btn @click="isShowCreateFakeUserModal = true" variant="primary">
            Создать
          </b-btn>
        </div>
        <div v-if="isLoadingFakeUsers" class="loading-wrapper">
          <b-spinner class="spinner"></b-spinner>
        </div>
        <b-table
            v-else
            responsive
            show-empty
            stacked="md"
            :striped="isStriped"
            :bordered="isBordered"
            :fields="fields"
            :items="fakeUsers"
            :current-page=0
            :per-page=10
            class="mb-5"
        >
          <template #cell(username)="data">
            {{ data.item.username }}
          </template>
          <template #cell(gameProfiles)="data">
            <div>
             {{ getPubgFieldsOfFakeUser(data.item) || '' }}
            </div>
            <div>
             {{ getMlbbFieldsOfFakeUser(data.item) || '' }}
            </div>
          </template>
          <template #cell(action)="data">
            <div class="row justify-content-center">
              <b-btn variant="primary" class="mr-1 p-1" @click="editFakeUser(data.item.id)">
                <i class="fa fa-edit"></i>
              </b-btn>
              <b-btn variant="danger" class="mr-2 p-1" @click="deleteFakeUser(data.item.id)">
                <i class="fa fa-trash" style="font-size: 18px"></i>
              </b-btn>
            </div>
          </template>
        </b-table>

        <b-col md="6" class="p-0">
          <b-pagination
              @change="handleFakeUsersPageChange"
              v-model="fakeUsersCurrentPage"
              :total-rows="totalPagesOfFakeUsers"
              :per-page="10"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-card>
    </div>
  </div>
</template>

<script>
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import SearchInput from "@/components/SearchInput.vue";
import api from "@/api/api";
import Modal from '@/components/modals/index.vue'
import Vue from "vue";
import {mapState} from "vuex";

export default {
  name: "FakeUsers",
  components: {SearchInput, Modal},
  mixins: [ModalAdapterMixin, PaginationHelperMixin],
  created() {
    try {
      this.isLoadingFakeUsers = true;
      (async () => {
        this.recordPageToStorage(this.fakeUsersCurrentPage, this.fakeUsersPageKey)
        await this.$store.dispatch('moduleFakeUsers/getFakeUsers', {
          page: this.getCurrentPageNumber(this.fakeUsersPageKey),
        })
      })()
    } finally {
      this.isLoadingFakeUsers = false
    }
  },

  data() {
    return {
      searchForFakeUsersModel: '',
      fakeUsersPageKey: 'pageNumberFakeUsers',
      fields: [
        {key: 'id', label: '№', sortable: true},
        {key: 'username', label: 'Username', sortable: true},
        {key: 'gameProfiles', label: 'Game profiles', sortable: true},
        {key: 'action', label: 'Инструменты', sortable: true},
      ],
      isStriped: true,
      isBordered: true,
      isLoadingFakeUsers: false,
      isShowDeleteFakeUserModal: false,
      isLoadingCreateFakeUser: false,
      isShowEditFakeUserModal: false,
      isShowCreateFakeUserModal: false,
      selectedFakeUserId: 0,
      fakeUsersCurrentPage: 1,
    }
  },
  computed: {
    ...mapState('moduleFakeUsers', ['fakeUsers', 'totalPagesOfFakeUsers']),
    searchModel: {
      get() { return this.value },
      set(newValue) { this.$emit('input', newValue) }
    }
  },
  methods: {
    async handleFakeUsersPageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.fakeUsersPageKey)
      await this.$store.dispatch('moduleFakeUsers/getFakeUsers', {
        page: pageNumber,
        ...(this.searchForFakeUsersModel && {search: this.searchForFakeUsersModel})
      })
    },
    getFilteredFakeUsers() {
      this.isLoadingFakeUsers = true
      this.$store.dispatch('moduleFakeUsers/getFakeUsers', { search: this.searchForFakeUsersModel })
          .then(() => {
            this.isLoadingFakeUsers = false
          })
    },
    getPubgFieldsOfFakeUser(user) {
      const pubgFields = user?.game_profiles.find((gameData) => gameData.game.slug === 'pubg_mobile')?.fields
      if (pubgFields) {
        return 'pubg nick: ' + pubgFields?.nick + ' ' + 'pubg id: ' + pubgFields?.pubg_id
      }
      return ''
    },
    getMlbbFieldsOfFakeUser(user) {
      const mlbbFields = user?.game_profiles.find((gameData) => gameData.game.slug === 'mlbb')?.fields
      if (mlbbFields) {
        return 'mlbb nick: ' + mlbbFields?.nick + ' ' + 'mlbb id: ' + mlbbFields?.mlbb_id
      }
      return ''
    },
    deleteFakeUser(id) {
      this.selectedFakeUserId = id
      this.isShowDeleteFakeUserModal = true
    },
    editFakeUser(id) {
      this.selectedFakeUserId = id
      this.isShowEditFakeUserModal = true
    }
  }
}
</script>

<style scoped>

</style>