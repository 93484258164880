import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/user-document';

export default {
    /**
     * GET: Show
     * @returns {Promise<AxiosResponse<any>>}
     */
    showUserDocs: (userId) => adminHttp.get(END_POINT + `/${userId}`),
    
    /**
     * DELETE: Docs
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete: (docId) => adminHttp.delete(END_POINT + `/${docId}`),
}