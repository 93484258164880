import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/v1/admin/tournament/variations';

export default {
    /**
     * GET: Tournament Variations
     * @returns {Promise<AxiosResponse<any>>}
     */
    index: async () => {
      const response = await adminHttp.get(END_POINT);
      return { variations: response.data };
    }
}