<template>
  <div class="delete-watch-video-wrapper">
    <h5>Вы действительно хотите удалить это видео?</h5>
    <p>Это действие нельзя будет отменить.</p>
    <b-alert v-if="errorText" show variant="danger">{{ errorText }}</b-alert>
    <footer>
      <b-button variant="danger" @click="submitForm" :disabled="isLoadingSaveForm">
        {{ isLoadingSaveForm ? 'Удаляем...' : 'Удалить' }}
      </b-button>
      <b-button variant="primary" @click="$emit('closeModal')" :disabled="isLoadingSaveForm" class="ml-2">
        Отмена
      </b-button>
    </footer>
  </div>
</template>

<script>
import api from "@/api/api";

export default {
  name: "DeleteWatchVideo",
  props: {
    params: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isLoadingSaveForm: false,
      errorText: null
    }
  },
  methods: {
    async submitForm() {
      try {
        this.isLoadingSaveForm = true;
        this.errorText = null;
        await api.watch.delete(this.params.id);

        // Обновление списка watch видео
        await this.$store.dispatch('moduleWatchVideos/getWatchVideos', {
          page: localStorage.getItem('pageNumberWatchVideos'),
        });

        this.$emit('closeModal');
        this.$toast.success('Видео успешно удалено', { 
          position: 'top-right', 
          timeout: 2000 
        });
      } catch (e) {
        this.errorText = e.response?.data?.message || 'Произошла ошибка при удалении видео';
        this.$toast.error(this.errorText, { 
          position: 'top-right', 
          timeout: 2000 
        });
      } finally {
        this.isLoadingSaveForm = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.delete-watch-video-wrapper {
  h5 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    color: #6c757d;
    margin-bottom: 20px;
  }

  footer {
    border-top: 1px solid #dee2e6;
    padding-top: 15px;
    margin-top: 15px;
    text-align: right;
  }
}
</style>