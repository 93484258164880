<template>
  <div>
    <Breadcrumbs main="Команды" :title='teamsShow.name'/>

    <b-modal centered id="modal-addTeamRating" :title="`Добавить рейтинг для команды ${teamsShow.name}`" v-model="show">
      <div>
        <label for="">Рейтинг</label>
        <b-form-input type="number" v-model="newRating"></b-form-input>
        <label for="" class="mt-2">Описание</label>
        <b-form-textarea v-model="newDescription"></b-form-textarea>
      </div>
      <template #modal-footer>
        <div>
          <b-btn variant="primary" class="mr-3" @click="acceptTeamRating">Подтвердить</b-btn>
          <b-btn variant="danger" @click="show=false">Отмена</b-btn>
        </div>
      </template>
    </b-modal>

    <div class="container">
      <b-card>
        <form>
          <div class="row justify-content-sm-between justify-content-center mb-5">
            <img :src="teamsShow?.logo?.original_url || TeamImagePug"
                 class="teamLogo"
                 @error="event => event.target.src = TeamImagePug"
            />
            <div>
              <h1>{{ teamsShow.name }}</h1>
              <label>Введите новое название</label>
              <ValidationProvider rules="min:2" v-slot="{errors}">
                <b-form @submit.prevent="updateTeamName({
                                    teamId: $route.params.id,
                                    name: teamsShow.name,
                                })">
                  <b-input type="text" v-model="teamsShow.name"></b-input>
                </b-form>
                <b-btn @click.prevent="updateTeamName({
                                    teamId: $route.params.id,
                                    name: teamsShow.name,
                                })" variant="primary" class="mt-2">Сохранить
                </b-btn>
                <div class="text-danger">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>
          </div>
        </form>
        <b-tabs>
          <b-tab title="Общее" active>
            <table class="table mt-5">
              <tr v-if="teamsShow?.currentBans.length">
                <th class="text-danger">Бан</th>
                <td>
                   <span v-for="(bans, idx) in teamsShow.currentBans" :key="bans.id">
                     Команда по {{ bans?.game_name }} находится в бане от {{ bans?.from }} до {{ bans?.to }}
                     Причина: {{ bans?.description }}
                  </span>
                </td>
              </tr>
              <tr v-if="teamsShow?.deleted_at">
                <th class="text-danger">Команда удалена</th>
                <td class="text-danger">
                  {{
                    teamsShow?.deleted_at ? moment(teamsShow?.deleted_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY.MM.DD HH:mm') : ''
                  }}
                </td>
              </tr>
              <tr>
                <th>Создатель</th>
                <div v-for="member in members" :key="'_member' + member.id">
                  <td v-if="member.role_in_team.code == 'owner'">
                    {{ member.role_in_team.code == null ? 'не указано' : member.username }}
                  </td>
                </div>
              </tr>
              <tr>
                <th>Страна</th>
                <td>{{ teamsShow.country == null ? 'не указано' : teamsShow.country?.name }}</td>
              </tr>
              <tr>
                <th>Дата создания</th>
                <td>{{ teamsShow.created_at }}</td>
              </tr>
              <tr>
                <th>Дивизион</th>
                <td>
                  {{ teamsShow.division?.name }}
                </td>
              </tr>
              <tr>
                <th>Рейтинг</th>
                <td>
                  <div>
                    {{ teamsShow.rating }}
                  </div>
                </td>
              </tr>
              <tr>
                <th>Всего турниров</th>
                <td>{{ teamsShow.tournaments_count }}</td>
              </tr>
              <tr>
                <th>Название игры</th>
                <td>{{ teamsShow.game?.name }}</td>
              </tr>
            </table>
            <div class="mt-5">
              <h2 class="text-center">Участники</h2>
              <router-link to="">
                <b-btn variant="primary"
                       @click.prevent="$router.push({name: 'teams_add_player', params: {id:$route.params.id,}})">
                  Добавить игрока
                </b-btn>
              </router-link>

              <PubgPlayerTable
                  v-if="teamsShow.game.slug === 'pubg_mobile'"
                  :tr-class="trClass" :members="members"/>
              <MlbbPlayerTable
                  v-if="teamsShow.game.slug === 'mlbb'"
                  :tr-class="trClass" :members="members"/>


            </div>
          </b-tab>
          <b-tab title="Турниры">
            <b-form>
              <b-table
                  responsive
                  stacked="md"
                  :striped="striped"
                  :bordered="bordered"
                  :fields="tournamentLogsFields"
                  :items="teamTournamentLogs"
                  :current-page=0
                  :per-page=10
                  show-empty
                  class="mt-5">
                <template #cell(name)="data">
                  {{ data.item.tournament.name }}
                </template>
                <template #cell(format)="data">
                  {{ data.item.tournament.format.name }}
                </template>
                <template #cell(compound)="data">
                  <div v-for="member in data.item?.members" :key="'teamMembers-'+member.id">
                    {{ member.username }}
                  </div>
                </template>
                <template #cell(details)="data">
                  <div class="d-flex justify-content-center">
                    <b-button size="lg" @click="showTournDetails(data)" class="px-1 py-0 d-flex" variant="none">
                      <i class="fa fa-arrow-circle-o-down"></i>
                    </b-button>
                  </div>
                </template>
                <template #row-details="tournamentData">
                  <div class="card p-0 m-0" v-if="tournamentData.item?.stage_team?.tournament_team?.stage_teams">
                    <b-table :items="tournamentData.item?.stage_team?.tournament_team?.stage_teams"
                             :fields="tournamentLogFields" striped hover responsive
                             show-empty>
                      <template #cell(stage)="data">
                        {{ data.item?.stage.name }}
                      </template>
                      <template #cell(members)="data">
                        <div v-for="member in data.item?.stage_team_members" :key="'memberId' + member.id">
                          {{ member.user.username }}
                        </div>
                      </template>
                    </b-table>
                  </div>
                  <div v-else class="d-flex justify-content-center">Информация отсутствует</div>
                </template>
                <template #cell(date)="data">
                  {{ data.item?.tournament.start_time }}
                </template>
              </b-table>
              <b-col md="6" class="p-0 mt-5">
                <b-pagination
                    @change="handleTournamentLogsPageChange"
                    v-model="teamTournamentLogsCurrentPage"
                    :total-rows="totalPagesOfTeamTournamentLogs"
                    :per-page="10"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </b-form>
          </b-tab>
          <b-tab title="Матчи">
            <b-form>
              <b-table
                  responsive
                  stacked="md"
                  :striped="striped"
                  :bordered="bordered"
                  :fields="fieldsTournaments"
                  :current-page=0
                  :per-page=10
                  show-empty
                  class="mt-5">
                <template #cell(name)="data">
                </template>
                <template #cell(format)="data">
                  <div></div>
                </template>
                <template #cell(data)="data">
                  <div></div>
                </template>
                <template #cell(compound)="data">
                  <div></div>
                </template>
              </b-table>
            </b-form>
          </b-tab>
          <b-tab title="Логи">
            <div class="text-center">
              <b-form>
                <div>
                  <b-table
                      responsive
                      stacked="md"
                      :striped="striped"
                      :bordered="bordered"
                      :fields="fieldsLogos"
                      :items="teamLogs"
                      show-empty
                      class="mt-5">
                    <template #cell(date)="data">
                      {{ moment(data.item.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm') }}
                    </template>
                    <template #cell(type)="data">
                      {{ data.item.type.code }}
                    </template>
                    <template #cell(reason)="data">
                      <div>{{ getCodeStateMessage(data.item.type.code).description }}
                        {{ data.item?.tournament?.name }}
                        {{ data.item?.data?.new_name }}
                        {{ data.item?.user?.username }}
                      </div>
                      <div v-if="data.item?.data?.tournament?.name">
                        {{ `Турнир: ${data.item?.data?.tournament?.name}` }}
                      </div>
                      <div v-if="data.item?.data?.stage?.name">
                        {{ `Этап: ${data.item?.data?.stage?.name}` }}
                      </div>
                      <div v-if="data.item?.data?.old_stage?.name">
                        {{ `Из этапа: ${data.item?.data?.old_stage?.name}` }}
                      </div>
                      <div v-if="data.item?.data?.new_stage?.name">
                        {{ `В этап: ${data.item?.data?.new_stage?.name}` }}
                      </div>
                      <div v-if="data.item?.data?.group?.name">
                        {{ `Группа: ${data.item?.data?.group?.name}` }}
                      </div>
                      <div v-if="data.item?.data?.reject_description">
                        {{ `Причина: ${data.item?.data?.reject_description}` }}
                      </div>
                      <div v-if="data.item.type.code == 'role-assigned'">
                        {{ getRoleStateMessage(data.item.data.old_role, data.item.data.role).role }}
                      </div>
                    </template>
                    <template #cell(who)="data">
                      {{ data.item?.author?.username }}
                    </template>
                  </b-table>
                </div>
                <b-col md="6" class="p-0 mt-5">
                  <b-pagination
                      @change="logsHandlePageChange"
                      v-model="teamLogsCurrentPage"
                      :total-rows="teamLogsTotal"
                      :per-page="10"
                      class="my-0"
                  ></b-pagination>
                </b-col>
              </b-form>
            </div>
          </b-tab>
          <b-tab title="Настройки">
            <b-table :fields="fieldRating" :items="getTeamCurrentRating" responsive striped class="mt-5">
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(points)="{item}">
                <b-form-input type="number" v-model="item.points" class="w-25"
                              @change="updateCurrentTeamRating(item.points, item.id, item.description)"></b-form-input>
              </template>
              <template #cell(description)="{item}">
                <b-form-textarea v-model="item.description"
                                 @change="updateCurrentTeamRating(item.points, item.id, item.description)"></b-form-textarea>
              </template>
            </b-table>
            <b-btn variant="success" class="mb-3 mt-3" @click.prevent="show = true">Добавить рейтинг</b-btn>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import {TeamsAdapterMixin} from "@/mixins/Teams/TeamsAdapterMixin";
import {UsersAdapterMixin} from "@/mixins/Users/UsersAdapterMixin";
import {ROLES} from '@/constants/TeamMember/roles';
import {CODES} from '@/constants/TeamMember/logCodes';
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import moment from 'moment'
import MlbbPlayerTable from "@/components/TeamInfo/MlbbPlayerTable.vue";
import PubgPlayerTable from "@/components/TeamInfo/PubgPlayerTable.vue";
import TeamImagePug from '@/assets/images/pugs/team.png'
import api from "@/api/api";

export default {
  name: 'TeamInfo',
  components: {MlbbPlayerTable, PubgPlayerTable},
  mixins: [TeamsAdapterMixin, UsersAdapterMixin, PaginationHelperMixin],

  created() {
    (async () => {
      Promise.all([
            this.showTeams(this.$route.params.id),
            this.showTeamRating(this.$route.params.id),
            this.shortTeamRating(this.$route.params.id),
            this.recordPageToStorage(this.teamTournamentLogsCurrentPage, this.teamTournamentLogsPageKey),
            api.teams.teamTournamentLogs({
              teamId: this.$route.params.id,
              page: this.getCurrentPageNumber(this.teamTournamentLogsPageKey),
            }),
            this.recordPageToStorage(this.teamLogsCurrentPage, this.pageKeyLogs),
            this.teamLogsShow({
              teamId: this.$route.params.id,
              pageNumber: this.getCurrentPageNumber(this.pageKeyLogs),
            })
          ]
      )
      const tournamentLogs = (await api.teams.teamTournamentLogs({
        teamId: this.$route.params.id,
        page: this.teamTournamentLogsCurrentPage
      })).data
      this.teamTournamentLogs = tournamentLogs.entry_logs || []
      this.totalPagesOfTeamTournamentLogs = tournamentLogs.pagination.total
    })()
  },
  data() {
    return {
      TeamImagePug,
      moment,
      pageKeyLogs: 'pageNumberTeamLogs',
      teamTournamentLogsPageKey: 'teamTournamentLogsPageNumber',
      teamTournamentLogs: [],
      totalPagesOfTeamTournamentLogs: 1,
      teamTournamentLogsCurrentPage: 1,
      fields: [
        {key: 'id', label: '№',},
        {key: 'nick', label: 'Nick',},
        {key: 'pubg_nick', label: 'Pubg nick'},
        {key: 'pubg_id', label: 'Pubg id',},
        {key: 'status', label: 'Cтатус',},
        {key: 'country', label: 'Страна',},
        {key: 'socials', label: 'Социальные сети',},
        {key: 'role', label: 'Роль',},
        {key: 'inspect', label: 'Просмотр',},
      ],
      tournamentLogsFields: [
        {key: 'id', label: '№',},
        {key: 'name', label: 'Название',},
        {key: 'format', label: 'Формат'},
        {key: 'details', label: 'Детали',},
        {key: 'date', label: 'Дата турнира',},
      ],
      fieldsLogos: [
        {key: 'id', label: '№',},
        {key: 'date', label: 'Дата и время',},
        {key: 'type', label: 'Тип'},
        {key: 'reason', label: 'Описание',},
        {key: 'who', label: 'Кто',},
      ],
      fieldRating: [
        {key: 'index', label: '#'},
        {key: 'points', label: 'Рейтинг'},
        {key: 'description', label: 'Описание'},
        {key: 'created_at', label: 'Дата создания'},
        {key: 'id', label: 'ID'}
      ],
      tournamentLogFields: [
        {key: 'id', label: 'id', sortable: true},
        {key: 'stage', label: 'Стадия', sortable: true},
        {key: 'members', label: 'Состав', sortable: true},
      ],
      striped: true,
      bordered: true,
      totalRows: 10,
      show: false,
    }
  },
  methods: {
    acceptTeamRating() {
      this.addTeamRating({
        team_id: this.$route.params.id,
        points: this.newRating,
        description: this.newDescription
      }).then(() => {
        this.show = false
        this.shortTeamRating(this.$route.params.id)
      })
    },
    updateCurrentTeamRating(points, id, description) {
      this.updateTeamRating({points: points, id: id, team_id: this.$route.params.id, description: description})
    },
    getRoleStateMessage(old_role, role) {
      return {
        role: `Был ${ROLES[old_role]}` + ' - ' + `Стал ${ROLES[role]}`,
      }
    },
    getCodeStateMessage(code) {
      return {
        description: `${CODES[code]}`,
      }
    },
    trClass: rowData => rowData?.currentBans.length && 'table-danger',

    logsHandlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKeyLogs)
      this.teamLogsShow({
        teamId: this.$route.params.id,
        pageNumber: pageNumber,
      })
    },
    async handleTournamentLogsPageChange(pageNumber) {
      const params = {page: pageNumber, teamId: this.$route.params.id}
      this.recordPageToStorage(pageNumber, this.teamTournamentLogsPageKey)
      const response = await api.teams.teamTournamentLogs(params)
      this.teamTournamentLogs = response.data.entry_logs || []
    },
    showTournDetails(data) {
      data.toggleDetails();
    },
  },
}
</script>

<style scoped>
.teamLogo {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%

}
</style>