<template>
  <div>
    <Breadcrumbs main="Турниры" title="Редактировать турнир" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Редактировать турнир</h5>
            </div>
            <div class="card-body">
              <b-card>
                <b-tabs>
                  <ValidationObserver ref="form">
                    <b-tab title="Основные" active>
                      <template #title>
                        <span class="fa fa-home mr-2"></span>
                        <span>Основные</span>
                      </template>
                      <b-form>
                        <div class="custom-control custom-checkbox mb-3 pl-0">
                          <div class="mt-3">
                            <span v-if="tournament.hash">
                              <a
                                :href="
                                  'https://bigplay.gg/tournaments/' +
                                  tournament.slug +
                                  '?hash=' +
                                  tournament.hash
                                "
                              >
                                ссылка турнира для доступа
                              </a>
                            </span>
                            <ValidationProvider>
                              <b-form-checkbox
                                :checked="tournament.hash"
                                v-model="isHashTournament"
                                >Показывать только по ссылке
                              </b-form-checkbox>
                            </ValidationProvider>
                            <ValidationProvider>
                              <b-form-checkbox
                                :checked="withComments"
                                v-model="withComments"
                                >Включить чат флудилку
                              </b-form-checkbox>
                            </ValidationProvider>
                          </div>
                          <b-tabs
                            class="mt-3 d-flex justify-content-md-start justify-content-center flex-wrap"
                          >
                            <b-tab
                              v-for="lang in languages"
                              :title="lang.language"
                              :key="'lang-' + lang.id"
                              @click="
                                getLanguageFromBtn(lang.language.toLowerCase())
                              "
                              nav-class="mr-2 mb-2"
                            >
                              <template #title>
                                <div class="d-flex justify-content-center">
                                  <img
                                    :src="lang.img"
                                    width="20"
                                    height="20"
                                    class="mr-2"
                                  />
                                  <span>{{ lang.language }}</span>
                                </div>
                              </template>
                            </b-tab>
                          </b-tabs>

                          <div class="mt-3">
                            <label class="font-weight-bold star"
                              >Название турнира {{ language }}</label
                            >
                            <ValidationProvider
                              rules="min:2|required"
                              v-slot="{ errors }"
                            >
                              <b-form-input
                                v-model="name"
                                placeholder="Название турнира"
                                :state="!errors.length ? null : false"
                              ></b-form-input>
                              <div class="text-danger">{{ errors[0] }}</div>
                            </ValidationProvider>
                          </div>
                          <div style="margin-top: 10px">
                            <label class="font-weight-bold star"
                              >Дисциплина</label
                            >
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-form-select
                                :options="games"
                                v-model="gameId"
                                disabled
                              ></b-form-select>
                              <div class="text-danger">{{ errors[0] }}</div>
                            </ValidationProvider>
                          </div>
                          <div style="margin-top: 10px">
                            <label class="font-weight-bold star"
                              >Организатор турнира</label
                            >
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-form-select
                                :options="organizers"
                                v-model="organizerId"
                                :state="!errors.length ? null : false"
                              ></b-form-select>
                              <div class="text-danger">{{ errors[0] }}</div>
                            </ValidationProvider>
                          </div>
                          <div class="mt-3">
                            <label class="font-weight-bold star"
                              >Призовой фонд</label
                            >
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-form-input
                                v-model="prize"
                                :state="!errors.length ? null : false"
                              ></b-form-input>
                              <div class="text-danger">{{ errors[0] }}</div>
                            </ValidationProvider>
                          </div>
                          <div>
                            <label class="font-weight-bold star"
                              >Кол-во команд</label
                            >
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-form-input
                                type="number"
                                v-model="slot_count"
                                :state="!errors.length ? null : false"
                              ></b-form-input>
                              <div class="text-danger">{{ errors[0] }}</div>
                            </ValidationProvider>
                          </div>
                          <div class="mt-3">
                            <label class="font-weight-bold star">Формат</label>
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Multiselect
                                :options="formats"
                                v-model="currentFormats"
                                label="text"
                                track-by="value"
                                disabled
                              ></Multiselect>
                              <div class="text-danger">{{ errors[0] }}</div>
                            </ValidationProvider>
                          </div>
                          <div class="mt-3">
                            <label class="font-weight-bold">Slug</label>
                            <b-form-input
                              type="text"
                              v-model="currentSlug"
                            ></b-form-input>
                          </div>

                          <!--                        <div class="mb-2">-->
                          <!--                          <label class="font-weight-bold">Cтатус</label>-->
                          <!--                          <ValidationProvider rules="required" v-slot="{errors}">-->
                          <!--                            <b-form-select  v-model="currentStatus.value" :options="newStatusOptions"></b-form-select>-->
                          <!--                            <div class="text-danger">{{errors[0]}}</div>-->
                          <!--                          </ValidationProvider>-->
                          <!--                        </div>-->

                          <div class="mb-2 mt-3">
                            <label class="font-weight-bold">Выбор страны</label>
                            <b-form-select
                              v-model="currentCountry"
                              :options="countries"
                            ></b-form-select>
                          </div>
                          <div class="mb-2">
                            <label class="font-weight-bold star"
                              >Подача для Tier</label
                            >
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Multiselect
                                :options="divisions"
                                v-model="currentDivisions"
                                label="text"
                                track-by="value"
                                :multiple="true"
                                :hide-selected="true"
                                :state="!errors.length ? null : false"
                              ></Multiselect>
                              <div class="text-danger">{{ errors[0] }}</div>
                            </ValidationProvider>
                          </div>
                          <div class="mt-3">
                            <label class="font-weight-bold"
                              >Ссылка трансляции</label
                            >
                            <b-form-input
                              type="text"
                              v-model="currentYoutubeLink"
                            ></b-form-input>
                          </div>
                          <div class="mt-3">
                            <label class="font-weight-bold"
                              >Серия турниров</label
                            >
                            <b-form-input
                              placeholder="Введите id турниров через запятую"
                              type="text"
                              v-model="currentRelatedTournaments"
                            ></b-form-input>
                          </div>
                          <div class="mt-3">
                            <label class="font-weight-bold">Feature</label>
                            <Multiselect
                              :options="featuresList"
                              v-model="currentFeature"
                              label="text"
                              track-by="value"
                              :multiple="true"
                            ></Multiselect>
                          </div>
                        </div>
                      </b-form>
                    </b-tab>
                    <b-tab title="Выбор времени">
                      <template #title>
                        <span class="fa fa-globe mr-2"></span>
                        <span>Выбор времени</span>
                      </template>
                      <b-form>
                        <div class="custom-control custom-checkbox pl-0">
                          <div class="mt-5">
                            <label for="" class="font-weight-bold"
                              >Часовой пояс
                              <span class="text-danger">*</span></label
                            >
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-form-select
                                format="YYYY-MM-DD H:i"
                                :options="timezones"
                                v-model="tournament_timezone"
                                :state="!errors.length ? null : false"
                              ></b-form-select>
                              <div class="text-danger">{{ errors[0] }}</div>
                            </ValidationProvider>
                          </div>

                          <div>
                            <label for="" class="font-weight-bold"
                              >Начало регистрации
                              <span class="text-danger">*</span></label
                            >
                            <div>
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <DatePicker
                                  mode="dateTime"
                                  v-model="startRegTime"
                                  is24hr
                                  :model-config="modelConfig"
                                  :popover="{ visibility: 'click' }"
                                >
                                  <template
                                    v-slot="{ inputValue, inputEvents }"
                                  >
                                    <b-form-input
                                      :value="inputValue"
                                      v-on="inputEvents"
                                      :state="!errors.length ? null : false"
                                    />
                                  </template>
                                </DatePicker>
                                <div class="text-danger">{{ errors[0] }}</div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div>
                            <label for="" class="font-weight-bold"
                              >Завершение регистрации
                              <span class="text-danger">*</span></label
                            >
                            <div>
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <DatePicker
                                  mode="dateTime"
                                  v-model="endRegTime"
                                  is24hr
                                  :model-config="modelConfig"
                                  :popover="{ visibility: 'click' }"
                                >
                                  <template
                                    v-slot="{ inputValue, inputEvents }"
                                  >
                                    <b-form-input
                                      :value="inputValue"
                                      v-on="inputEvents"
                                      :state="!errors.length ? null : false"
                                    />
                                  </template>
                                </DatePicker>
                                <div class="text-danger">{{ errors[0] }}</div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div>
                            <label for="" class="font-weight-bold"
                              >Дата начала турнира
                              <span class="text-danger">*</span></label
                            >
                            <div class="w-100">
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <DatePicker
                                  mode="dateTime"
                                  v-model="startTime"
                                  is24hr
                                  :model-config="modelConfig"
                                  :popover="{ visibility: 'click' }"
                                >
                                  <template
                                    v-slot="{ inputValue, inputEvents }"
                                  >
                                    <b-form-input
                                      :value="inputValue"
                                      v-on="inputEvents"
                                      :state="!errors.length ? null : false"
                                    />
                                  </template>
                                </DatePicker>
                                <div class="text-danger">{{ errors[0] }}</div>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                      </b-form>
                    </b-tab>

                    <b-tab title="Описание">
                      <template #title>
                        <span class="fa fa-list-alt mr-2"></span>
                        <span>Описание</span>
                      </template>
                      <div class="d-flex">
                        <b-tabs
                          class="mt-3 d-flex justify-content-md-start justify-content-center flex-wrap"
                        >
                          <b-tab
                            v-for="lang in languages"
                            :title="lang.language"
                            :key="'lang-' + lang.id"
                            @click="
                              getLanguageFromBtn(lang.language.toLowerCase())
                            "
                            nav-class="mr-2 mb-2"
                          >
                            <template #title>
                              <div class="d-flex justify-content-center">
                                <img
                                  :src="lang.img"
                                  width="20"
                                  height="20"
                                  class="mr-2"
                                />
                                <span>{{ lang.language }}</span>
                              </div>
                            </template>
                          </b-tab>
                        </b-tabs>
                      </div>
                      <div class="mt-5" v-if="!isLoadingAllData">
                        <label for="" class="font-weight-bold"
                          >Расписание {{ language.toUpperCase() }}</label
                        >
                        <template v-for="lang in Object.keys(schedule)">
                          <ValidationProvider
                            v-if="lang === 'ru'"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <SunEditor
                              :key="lang + '-editSchedule'"
                              v-if="language === lang"
                              v-model="schedule[lang]"
                              :editorId="'editSchedule'"
                            />
                            <div class="text-danger">{{ errors[0] }}</div>
                          </ValidationProvider>
                          <div v-else>
                            <SunEditor
                              :key="lang + '-editSchedule'"
                              v-if="language === lang"
                              v-model="schedule[lang]"
                              :editorId="'editSchedule'"
                            />
                          </div>
                        </template>
                      </div>
                      <div class="mt-2" v-if="!isLoadingAllData">
                        <label class="font-weight-bold"
                          >Описание {{ language.toUpperCase() }}</label
                        >
                        <template v-for="lang in Object.keys(description)">
                          <ValidationProvider
                            v-if="lang === 'ru'"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <SunEditor
                              :key="lang + '-editDescription'"
                              v-if="language === lang"
                              v-model="description[lang]"
                              :editorId="'editDescription'"
                            />
                            <div class="text-danger">{{ errors[0] }}</div>
                          </ValidationProvider>
                          <div v-else>
                            <SunEditor
                              :key="lang + '-editDescription'"
                              v-if="language === lang"
                              v-model="description[lang]"
                              :editorId="'editDescription'"
                            />
                          </div>
                        </template>
                      </div>
                      <div class="mt-2" v-if="!isLoadingAllData">
                        <label class="font-weight-bold"
                          >Правила {{ language.toUpperCase() }}</label
                        >
                        <template v-for="lang in Object.keys(rules)">
                          <ValidationProvider
                            v-if="lang === 'ru'"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <SunEditor
                              :key="lang + '-editRules'"
                              v-if="language === lang"
                              v-model="rules[lang]"
                              :editorId="'editRules'"
                            />
                            <div class="text-danger">{{ errors[0] }}</div>
                          </ValidationProvider>
                          <div v-else>
                            <SunEditor
                              :key="lang + '-editRules'"
                              v-if="language === lang"
                              v-model="rules[lang]"
                              :editorId="'editRules'"
                            />
                          </div>
                        </template>
                      </div>
                      <div class="mt-2">
                        <label class="font-weight-bold">Выбрать обложку</label>
                        <b-form-file
                          v-model="newImage"
                          plain
                          @change="showImage($event)"
                          class="mb-3"
                        ></b-form-file>
                        <img id="outputTournament" class="w-100" />
                        <label class="mt-3">Текущая обложка</label>
                        <b-img
                          :src="tournamentCurrentImage"
                          style="width: 100%"
                        />
                      </div>
                    </b-tab>
                    <b-tab title="Place pts">
                      <template #title>
                        <span class="fa fa-database mr-2"></span>
                        <span>Очки за place</span>
                      </template>
                      <AddTournamentPlace></AddTournamentPlace>
                    </b-tab>
                    <b-tab title="Place pts">
                      <template #title>
                        <span class="fa fa-database mr-2"></span>
                        <span>Призы за place</span>
                      </template>
                      <AddTournamentPrize />
                    </b-tab>
                    <b-tab title="Мета данные">
                      <template #title>
                        <span class="fa fa-database mr-2"></span>
                        <span>Мета данные</span>
                      </template>
                      <div>
                        <b-tabs
                          class="mt-3 d-flex justify-content-md-start justify-content-center flex-wrap"
                        >
                          <b-tab
                            v-for="lang in languages"
                            :title="lang.language"
                            :key="'lang-' + lang.id"
                            @click="
                              getLanguageFromBtn(lang.language.toLowerCase())
                            "
                            nav-class="mr-2 mb-2"
                          >
                            <template #title>
                              <div class="d-flex justify-content-center">
                                <img
                                  :src="lang.img"
                                  width="20"
                                  height="20"
                                  class="mr-2"
                                />
                                <span>{{ lang.language }}</span>
                              </div>
                            </template>
                          </b-tab>
                        </b-tabs>

                        <div class="mt-5">
                          <div>
                            <label class="font-weight-bold"
                              >Мета заголовок {{ language }}</label
                            >
                            <b-form-input
                              v-model="metaTitle[language]"
                              placeholder="Название"
                            ></b-form-input>
                          </div>
                          <div class="mt-2">
                            <label class="font-weight-bold"
                              >Мета описание {{ language }}</label
                            >
                            <b-textarea
                              v-model="metaDescription[language]"
                              class="h-30"
                            ></b-textarea>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                  </ValidationObserver>
                </b-tabs>
                <div class="mt-3 justify-content-center align-items-center">
                  <button
                    @click.prevent="updateCurrentTournament"
                    type="button"
                    class="btn btn-success"
                  >
                    Сохранить
                  </button>
                </div>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { TournamentAdapterMixin } from "../../mixins/Tournament/TournamentAdapterMixin";
import { LanguageAdapterMixin } from "../../mixins/Language/LanguageAdapterMixin";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import Multiselect from "vue-multiselect";
import { FeaturesAdapterMixin } from "@/mixins/Features/FeaturesAdapterMixin";
import AddTournamentPlace from "@/pages/Tournament/AddTournamentPlace.vue";
import SunEditor from "@/components/SunEditor.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AddTournamentPrize from './AddTournamentPrize.vue';

export default {
  mixins: [TournamentAdapterMixin, LanguageAdapterMixin, FeaturesAdapterMixin],
  name: "EditPageTournament",
  components: {
    SunEditor,
    AddTournamentPlace,
    Multiselect,
    DatePicker,
    ValidationObserver,
    ValidationProvider,
    AddTournamentPrize,
  },
  data() {
    return {
      isLoadingAllData: true,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD HH:mm:ss",
      },
      isHashTournament: false,
    };
  },
  async created() {
    this.isLoadingAllData = true;
    await this.grabGames();
    await this.grabDivisionsList({
      slug: "mlbb",
      ...(this.gameId === 1 && { slug: "pubg_mobile" }),
      ...(this.gameId === 3 && { slug: "mlbb" }),
    });
    await this.indexTimeZones();
    await this.grabOrganizers();
    await this.indexFormat({ gameId: this.gameId });
    // this.grabTournament(this.$route.params.id).then(() => {
    //   console.log(JSON.stringify(this.tournament))
    //   this.isHashTournament = this.tournament.hash ? true : false;
    // });
    await this.grabTournament(this.$route.params.id);
    await this.getCountriesQuery();
    await this.grabFeaturesList();
    this.isLoadingAllData = false;
  },
  methods: {
    showImage(event) {
      let output = document.getElementById("outputTournament");
      output.src = URL.createObjectURL(event.target.files[0]);
      output.onload = function () {
        URL.revokeObjectURL(output.src);
      };
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
@import "~@/assets/scss/star.scss";
</style>
