<template>
  <div class="add-tournament-team-card">
    <header>
      <div class="team-name-block">
        <h4>
          {{ team.name }}
        </h4>
        <button @click="isShowPlayers = !isShowPlayers"
                v-if="team?.tournament_team?.stage_teams?.length"
                class="show-players-button"
                :disabled="isLoadingSubmit">
          {{ isShowPlayers ? 'Скрыть игроков' : 'Показать игроков' }}
        </button>
      </div>
      <span class="id-team">id: {{ team.id }}</span>
    </header>
    <div class="team-add-action-wrapper">
      <div v-if="team?.team_owner?.length" class="captain-team">
        <span class="label">Капитан:</span> <span class="value">{{ team?.team_owner?.[0]?.full_name }} ({{ team?.team_owner?.[0]?.username }})</span>
      </div>
      <div v-else></div>
      <div class="d-flex justify-content-end action-block align-items-end">
        <b-button class="custom-remove-team"
                  @click="isShowDeleteTeamFromTournamentModal = true"
                  variant="primary">Удалить из турнира</b-button>
<!--        <b-dropdown id="dropdown-right"-->
<!--                    right-->
<!--                    text="Удалить"-->
<!--                    variant="primary"-->
<!--                    :class="[!isHideAdd && 'mr-2 mt-2']"-->
<!--                    class="custom-remove-team-dropdown">-->
<!--          <b-dropdown-item @click="isShowDeleteTeamFromStageModal = true">Из этапа</b-dropdown-item>-->
<!--          <b-dropdown-item @click="isShowDeleteTeamFromTournamentModal = true" href="#">Из турнира</b-dropdown-item>-->
<!--        </b-dropdown>-->
        <template v-if="!isHideAdd">
          <template v-if="!hasAdded">
            <button @click="addTeam"
                    class="select-team-button"
                    :disabled="isLoadingSubmit">
              {{ isLoadingSubmit ? 'Добавляем' : 'Добавить' }}
            </button>
          </template>
          <span v-if="hasAdded" class="team-has-added">Добавлена</span>
        </template>
      </div>
    </div>
    <div v-if="isShowPlayers" class="players-data">
      <template v-if="team?.tournament_team?.stage_teams?.length">
        <template v-for="stageTeam in team?.tournament_team?.stage_teams">
          <h4>Игроки этапа {{ stageTeam.stage.name }}</h4>
          <table>
            <tr>
              <td>Username</td>
              <td>Nick</td>
              <td>{{ tournament.game.slug === 'pubg_mobile' ? 'PUBG ID' : 'MLBB ID' }}</td>
            </tr>
            <tr v-for="player in stageTeam.stage_team_members" :key="'player-' + player.id">
              <td>{{ player.user?.username || '' }}</td>
              <td>{{ getGameProfile(player)?.fields?.nick || ''}}</td>
              <td>{{ getGameProfile(player)?.fields?.[tournament.game.slug === 'pubg_mobile' ? 'pubg_id' : 'mlbb_id'] || ''}}</td>
            </tr>
          </table>
        </template>
      </template>
      <div v-else class="pug">
        Игроков нет
      </div>
    </div>
    <Modals name="DeleteTeamFromStageTournament" v-model="isShowDeleteTeamFromStageModal" title="Удаление команды из этапа" :params="{ team, stage: selectedStage }" @submit="(team) => $emit('clearTeamFromStorage', { team, listName })"/>
    <Modals name="DeleteTeamFromTournament" v-model="isShowDeleteTeamFromTournamentModal" title="Удаление команды из турнира" :params="{ team, tournament }" @submit="(team) => $emit('clearTeamFromStorage', { team, listName })"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Modals from "@/components/modals/index.vue";

export default {
  name: "AddTournamentTeamCard",
  components: {
    Modals,
  },
  props: {
    team: {
      type: Object,
      required: true
    },
    isHideAdd: {
      type: Boolean,
      default: false
    },
    listName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isShowDeleteTeamFromStageModal: false,
      isShowDeleteTeamFromTournamentModal: false,
      isLoadingSubmit: false,
      isShowPlayers: false
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['selectedStageId', 'selectedRoundId', 'selectedGroupId', 'tournament', 'groupsWithTeamsByMLBB', 'teamsByGroup', 'tournament']),
    ...mapGetters('moduleNewTournament', ['selectedGroup', 'selectedStage']),
    hasAdded() {
      // For MLBB
      if (this.tournament.game.slug === 'mlbb') {
        const allGroupTeams = this.groupsWithTeamsByMLBB.reduce((state, group) => {
          state = [...state, ...group.group_teams]
          return state
        }, [])
        if (!allGroupTeams || !allGroupTeams.length) return false
        return allGroupTeams.some(({ team: { id } }) => id === this.team.id)
      }
      // For PUBG
      if (!this.teamsByGroup || !this.teamsByGroup.length) return false
      return this.teamsByGroup.some(({ team: { id } }) => id === this.team.id)
    },
  },
  methods: {
    getGameProfile(player) {
      return player.user.game_profiles.find(({ game_id }) => game_id === this.tournament.game_id)
    },
    // addTeam() {
    //
    // }
  }
}
</script>

<style scoped lang="scss">

.custom-remove-team-dropdown::v-deep {
  button {
    color: #ff0000c9 !important;
    border-color: #ff0000c9 !important;
    background-color: white !important;

    font-size: 11px;
    border-radius: 13px;
    padding: 5px 13px 5px 13px;
    transition: 0.2s;
    line-height: 13px;

    &:hover, &:focus {
      color: white !important;
      background-color: #ff0000c9 !important;
      border-color: #ff0000c9 !important;
    }
  }
}

.custom-remove-team {
  color: #ff0000c9 !important;
  border-color: #ff0000c9 !important;
  background-color: white !important;

  font-size: 11px;
  border-radius: 13px;
  padding: 5px 13px 5px 13px;
  transition: 0.2s;
  line-height: 13px;
  white-space: nowrap;

  &:hover, &:focus {
    color: white !important;
    background-color: #ff0000c9 !important;
    border-color: #ff0000c9 !important;
  }
}

.players-data {
  margin-top: 30px;
  width: 100% !important;

  @media screen and (max-width: 400px) {
    overflow-x: scroll;
  }

  h4 {
    font-size: 17px !important;
    text-align: center !important;
    margin-bottom: 10px !important;

    @media screen and (max-width: 800px) {
      font-size: 15px;
    }
  }

  table {
    width: 100%;

    tr {
      transition: .2s;
    }

    td {
      padding: 10px;

      @media screen and (max-width: 800px) {
        font-size: 12px;
      }
    }

    & > tr:not(:last-child) {
      border-bottom: 1px solid #00000026;
    }

    & > tr:not(:first-child) {
      &:hover {
        background: #00000026;
      }
    }

    & > tr > td:not(:last-child) {
      border-right: 1px solid #00000026;
    }

    & > tr > td:not(:first-child) {
      text-align: center;
    }
  }
}

.add-tournament-team-card {
  background: white;
  padding: 20px;
  border-radius: 10px;
  transition: .2s;

  margin-bottom: 20px;

  header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .id-team {
      color: #808080c7;
      font-size: 12px;

    }
  }

  .team-name-block {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .show-players-button {
    display: block;
    font-size: 13px;
    border-radius: 15px;
    padding: 5px 13px 5px 13px;
    background: white;
    color: #7366ff;
    border: none;
    transition: .2s;
    outline: 1px solid #7366ff;
    outline-offset: -1px;

    &:hover {
      background: #7366ff;
      color: white;
    }
  }

  h4 {
    margin: 0;
    font-size: 16px;

    @media screen and (max-width: 400px) {
      font-size: 14px;
    }

  }

  .team-has-added {
    color: #008300de;
    text-align: right;
  }

  .team-add-action-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @media screen and (max-width: 550px) {
      display: block;
    }

    .action-block {
      @media screen and (max-width: 550px) {
        margin-top: 10px;
      }
    }
  }

  .select-team-button {
    display: block;
    font-size: 13px;
    border-radius: 15px;
    padding: 5px 13px 5px 13px;
    background: #dad6ff;
    color: #7366ff;
    border: none;
    transition: .2s;

    &:hover {
      background: #7366ff;
      color: white;
    }

    &.reject {
      background: white;
      outline: 1px solid #dad6ff;
      outline-offset: -1px;
      color: #dad6ff;

      &:hover {
        outline: 1px solid #7366ff;
        color: #7366ff;
      }
    }
  }

  .captain-team {
    margin-top: 10px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 500px) {
      display: block;
    }

    .label {
      color: black;
      font-size: 16px;
      margin-right: 6px;
    }

    .value {
      color: #000000ab;
      margin-bottom: -2px;
    }
  }
}
</style>