import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";
import Vue from "vue";

export const RatingAdapterMixin = {
    data() {
        return {
            searchForTeamsRatingModel: '',
            selectedDivisionSlug: '',
            isLoadingRatingForTeams: false
        }
    },
    computed: {
        ...mapGetters({
            getTeamsRating               : 'getTeamsRating',
            getPaginationTotal           : 'getTeamsRatingPaginationTotal',
            getCurrentPage               : 'getTeamsRatingPaginationCurrentPage',
            getTeamRatingsShow           : 'getTeamRatingsShow',
            getPaginationTotalShow       : 'getTeamRatingsShowPaginationTotal',
            getCurrentPageShow           : 'getTeamRatingsShowPaginationCurrentPage',
            getSelectedDivision          : 'getSelectedDivision',
        }),
        teamsRating       : {get() {return this.getTeamsRating             }, set(value) {this.setTeamsRating(value)     }},
        total             : {get() {return this.getPaginationTotal         }, set(value) {this.setPaginationTotal(value)  }},
        currentPage       : {get() {return this.getCurrentPage             }, set(value) {this.setCurrentPage(value)  }},
        teamRatingsShow   : {get(){ return this.getTeamRatingsShow         }, set(value){this.setTeamRatingsShow(value) }},
        totalShow         : {get() {return this.getPaginationTotalShow     }, set(value) {this.setPaginationTotalShow(value)  }},
        currentPageShow   : {get() {return this.getCurrentPageShow         }, set(value) {this.setCurrentPageShow(value)  }},
        selectedDivision  : {get() {return this.getSelectedDivision        }, set(value) {this.setSelectedDivision(value)  }},
    },
    methods: {
        ...mapMutations({
            setTeamsRating            : mutypes.SET_TEAMS_RATING,
            setPaginationTotal        : mutypes.SET_TEAMS_RATING_PAGINATION_TOTAL,
            setCurrentPage            : mutypes.SET_TEAMS_RATING_PAGINATION_CURRENT_PAGE,
            setTeamRatingsShow        : mutypes.SET_TEAM_RATINGS_SHOW,
            setPaginationTotalShow    : mutypes.SET_TEAM_RATINGS_SHOW_PAGINATION_TOTAL,
            setCurrentPageShow        : mutypes.SET_TEAM_RATINGS_SHOW_PAGINATION_CURRENT_PAGE,
            setSelectedDivision       : mutypes.SET_SELECTED_DIVISION,
        }),
        ...mapActions([
            'grabTeamsRating',
            'showTeamRatings',
        ]),
        async getTeamsByDivision(division) {
            this.selectedDivisionSlug = ''
            this.isLoadingRatingForTeams = true
            await this.grabTeamsRating({
                ...(division && {division: division}),
                ...(this.searchForTeamsRatingModel && {search: this.searchForTeamsRatingModel}),
            })
                .finally(() => {
                    if (division) {
                        this.selectedDivisionSlug = division || ''
                        this.isLoadingRatingForTeams = false
                    }
                    this.isLoadingRatingForTeams = false
                })
        },
    }
}