var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"mt-5"},[_vm._v("Игровой профиль "+_vm._s(_vm.game.game.name))]),_c('table',{staticClass:"table mt-5"},[_c('tr',[_c('th',[_vm._v("Ник")]),_c('td',[_c('b-input',{attrs:{"type":"text","placeholder":"nick"},model:{value:(_vm.gameProfileNick),callback:function ($$v) {_vm.gameProfileNick=$$v},expression:"gameProfileNick"}})],1)]),_c('tr',[_c('th',[_vm._v("Игровой ID")]),_c('td',[_c('b-input',{attrs:{"type":"number","placeholder":"id"},model:{value:(_vm.gameProfileId),callback:function ($$v) {_vm.gameProfileId=$$v},expression:"gameProfileId"}})],1)])]),_c('div',{staticClass:"d-flex flex-wrap justify-content-md-start justify-content-center"},[_c('b-btn',{staticClass:"ml-5 mt-2",attrs:{"variant":"primary","id":'update' + _vm.userShow.id},on:{"click":function($event){$event.preventDefault();return _vm.saveData.apply(null, arguments)}}},[_vm._v("Сохранить данные ")]),(_vm.game.id)?_c('b-btn',{staticClass:"ml-5 mt-2",on:{"click":function($event){$event.preventDefault();_vm.overwriteFields({ userId: _vm.game.id })
          .then(() => {
            _vm.gameProfileNick = '';
            _vm.gameProfileId = '';
          })
          .then(() => {
            _vm.showUser(_vm.$route.params.id);
          })}}},[_vm._v("Затереть данные ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }