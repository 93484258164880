import { render, staticRenderFns } from "./EditorTeam.vue?vue&type=template&id=ed781a24&scoped=true"
import script from "./EditorTeam.vue?vue&type=script&lang=js"
export * from "./EditorTeam.vue?vue&type=script&lang=js"
import style0 from "./EditorTeam.vue?vue&type=style&index=0&id=ed781a24&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed781a24",
  null
  
)

export default component.exports