<template>
  <div class="posts-edit">
    <Breadcrumbs main="Новости" title="Редактировать новость"></Breadcrumbs>
    <div class="container">
      <b-card>
        <b-tabs class="mt-3 d-flex justify-content-md-start justify-content-center flex-wrap">
          <b-tab v-for="lang in languages"
                 :title="lang.language"
                 :key="'lang-' + lang.id"
                 @click="getLanguageFromBtn(lang.language.toLowerCase())"
                 nav-class="mr-2 mb-2">
            <template #title>
              <div class="d-flex justify-content-center">
                <img :src="lang.img" width="20" height="20" class="mr-2">
                <span>{{ lang.language }}</span>
              </div>
            </template>
          </b-tab>
        </b-tabs>

        <b-form>
          <label class="mt-3"><b>Заголовок {{ language.toUpperCase() }}</b></label>
          <ValidationProvider rules="min:2|required" v-slot="{errors}">
            <b-form-input type="text" placeholder="Заголовок" v-model="currentPost.title[language]"></b-form-input>
            <div class="text-danger">{{ errors[0] }}</div>
          </ValidationProvider>

          <label class="mt-3"><b>SEO Мета Описание {{ language.toUpperCase() }}</b></label>
          <b-form-input type="text" placeholder="Описание" v-model="currentPost.meta_description[language]"></b-form-input>
          
          <label for="" class="mt-3"><b>Превью {{ language.toUpperCase() }}</b></label>
          <b-form-input type="text" placeholder="Превью" v-model="currentPreview[language]"></b-form-input>

          <label class="mt-3"><b>Категория</b></label>
          <b-form-select v-model="currentPost.type" :options="postTypes"></b-form-select>
          
          <label class="mt-3"><b>Описание {{ language.toUpperCase() }}</b></label>
          <template v-for="lang in languages">
            <EditorJS :key="lang.language.toLowerCase() + '-currentPostDescription'"
                      v-if="language === lang.language.toLowerCase()"
                      v-model="currentPost.description[lang.language.toLowerCase()]"
                      :editorId="'currentPostDescription-' + lang.language.toLowerCase()"
            />
          </template>
        </b-form>

        <div class="posts-edit__form-group">
          <label class="posts-edit__label"><b>Выбрать обложку</b></label>
          <div 
            v-if="!previewImage"
            class="posts-edit__file-drop-area"
            :class="{ 'is-dragover': isDragover }"
            @dragenter.prevent="isDragover = true"
            @dragleave.prevent="isDragover = false"
            @dragover.prevent
            @drop.prevent="handleFileDrop"
            @click="$refs.fileInput.click()"
          >
            <input 
              ref="fileInput"
              type="file"
              @change="handleFileSelect"
              style="display: none;"
              accept="image/*"
            >
            <div class="posts-edit__file-drop-content">
              <img :src="require('@/assets/icons/image-drop.svg')" alt="Drop image" class="posts-edit__file-icon">
              <p class="posts-edit__file-text">{{ isDragover ? 'Перетащите изображение сюда' : 'Перетащите изображение сюда или нажмите, чтобы загрузить' }}</p>
            </div>
          </div>
          <div v-else class="posts-edit__preview-container">
            <img :src="previewImage" class="posts-edit__preview-image"/>
            <button @click="deleteImage" class="posts-edit__delete-btn">Удалить</button>
          </div>
        </div>

        <div class="mt-5" v-if="currentImage">
          <label for="" class="mr-5">Текущая обложка</label>
          <b-img :src="currentImage" style="width: 100%"/>
        </div>

        <b-btn variant="success" class="posts-edit__submit-btn mt-5" @click.prevent="updatePost">
          Сохранить
        </b-btn>
      </b-card>
    </div>
  </div>
</template>


<script>
import {PostsAdapterMixin} from "@/mixins/Posts/PostsAdapterMixin";
import {LanguageAdapterMixin} from "@/mixins/Language/LanguageAdapterMixin";
import EditorJS from "@/components/EditorJS.vue";

export default {
  name: 'PostsEdit',
  mixins: [PostsAdapterMixin, LanguageAdapterMixin],
  components: {EditorJS},
  data() {
    return {
      isDragover: false,
      previewImage: null,
      editorData: {},
    }
  },
  created() {
    this.showBlogPosts(this.$route.params.id).then(() => {
      this.initializeEditorData();
      if (typeof this.currentPost.type === 'object' && this.currentPost.type !== null) {
        this.currentPost.type = parseInt(this.currentPost.type.code);
      }
    });
  },
  methods: {
    initializeEditorData() {
    this.languages.forEach(lang => {
      const langCode = lang.language.toLowerCase();
      if (!this.currentPost.description[langCode]) {
        this.$set(this.currentPost.description, langCode, null);
      }
      try {
        let parsedData;
        if (typeof this.currentPost.description[langCode] === 'string') {
          parsedData = JSON.parse(this.currentPost.description[langCode]);
        } else if (typeof this.currentPost.description[langCode] === 'object') {
          parsedData = this.currentPost.description[langCode];
        } else {
          parsedData = null;
        }
        this.$set(this.editorData, langCode, parsedData || this.getEmptyEditorData());
      } catch (error) {
        console.error('Error parsing description data:', error);
        this.$set(this.editorData, langCode, this.getEmptyEditorData());
      }
    });
  },
    htmlToEditorJS(html) {
      return {
        blocks: [
          {
            type: 'paragraph',
            data: {
              text: html
            }
          }
        ]
      };
    },
    handleFileSelect(event) {
      const file = event.target.files[0];
      this.processFile(file);
    },
    handleFileDrop(event) {
      this.isDragover = false;
      const file = event.dataTransfer.files[0];
      this.processFile(file);
    },
    processFile(file) {
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
          this.newPostLogo = file;
        };
        reader.readAsDataURL(file);
      }
    },
    deleteImage() {
      this.previewImage = null;
      this.newPostLogo = null;
    },
    updatePost() {
      const updatedPost = {
        id: this.currentPost.id,
        title: this.currentPost.title,
        description: this.editorData,
        preview: this.currentPreview,
        meta_description: this.currentPost.meta_description,
        type: parseInt(this.currentPost.type),
        logo: this.newPostLogo
      };

      this.updateBlogPostsPage(updatedPost);
    },
    getEmptyEditorData() {
      return {
        blocks: []
      };
    },
  },
  watch: {
    currentPost: {
      handler() {
        this.initializeEditorData();
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.posts-edit {
  &__file-drop-area {
    width: 100%;
    height: 180px;
    border: 3px dashed #ddd;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;

    &.is-dragover {
      background-color: rgba(0, 0, 0, 0.05);
      border-color: #999;
    }
  }

  &__file-drop-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__file-text {
    color: #777777;
    max-width: 200px;
    margin: 0;
    font-size: 14px;
    line-height: 1.4;
  }

  &__file-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 10px;
    color: black;
  }

  &__submit-btn {
    margin-top: 30px;
    padding: 10px 30px;
    border-radius: 20px;
  }

  &__preview-container {
    position: relative;
    display: inline-block;
  }

  &__preview-image {
    max-width: 100%;
    margin-top: 10px;
    border-radius: 4px;
  }

  &__delete-btn {
    position: absolute;
    top: 20px;
    right: 10px;
    background-color: rgba(255, 49, 49, 0.8);
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(202, 40, 40, 0.8);
    }
  }
}
</style>