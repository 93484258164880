import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const SeoAdapterMixin = {
    data() {
        return {
            newTitle: '',
            newDescription: '',
            newSlug: '',
        }
    },
    computed: {
        ...mapGetters({
            getSeoList: 'getSeoPages',
            getCurrentSeoPage: 'getCurrentSeoPage'
        }),
        seo             : {get() {return this.getSeoList            }, set(value) {this.setSeoList(value)  }},
        currentSeo      : {get() {return this.getCurrentSeoPage     }, set(value) {this.setCurrentSeo(value)  }},
    },
    methods: {
        ...mapMutations({
            setSeoList: mutypes.SET_SEO_PAGES,
            setCurrentSeo: mutypes.SET_CURRENT_SEO_PAGE,
        }),
        ...mapActions([
            'indexSeoPages',
            'showSeoPage',
            'postSeo',
            'updateSeo',
            'deleteSeo'
        ]),
    }
}