<template>
  <div>
    <Breadcrumbs main="Роли" title="Список ролей"/>
    <b-modal centered id="modal-seoDelete" title="Предупреждение" v-model="show">
      <p class="my-4">Вы подтверждаете удаление?</p>
      <template #modal-footer>
        <div>
          <b-btn variant="primary" class="mr-3" @click="deleteRole(currentId).then(()=> show = false)">Подтвердить</b-btn>
          <b-btn variant="danger" @click="show=false">Отмена</b-btn>
        </div>
      </template>
    </b-modal>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="p-3">
              <div class="d-flex justify-content-between">
              <b-btn variant="success" class="m-2" @click="$router.push({name: 'create_role'})">Создать роль</b-btn>
              <b-btn variant="primary" class="m-2" @click="$router.push({name: 'organizer_users'})">К списку модераторов</b-btn>
              </div>
              <div class="mt-3">
              <b-table :fields="tablefields" :items="roles">
                <template #cell(index)="data">
                  {{data.index + 1}}
                </template>
                <template #cell(actions)="{item}">
                  <router-link
                      :to="{ name: 'edit_role', params: { id: item.id }}"
                  ><span>
                      <b-btn variant="primary" class="mr-2 p-1 px-2 mb-1"><i class="fa fa-edit"></i></b-btn>
                    </span>
                  </router-link>
                  <span><b-btn variant="danger" class="mr-2 p-1 px-2 mb-1"
                               @click="deleteButton(item.id)"
                  ><i class="fa fa-trash" style="font-size: 18px"></i></b-btn></span>
                </template>
              </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {RolesAdapterMixin} from "@/mixins/Roles/RolesAdapterMixin";

export default {
  name: "RolesList",
  mixins: [RolesAdapterMixin],
  created() {
    this.indexRoles()
  },
  data() {
    return {
      tablefields: [
        {key: 'index', label: '№', },
        {key: 'id', label: 'id', },
        {key: 'name', label: 'name', },
        {key: 'actions', label: 'действия',},
      ],
      show: false,
      currentId: null,
    }
  },
  methods: {
    deleteButton(id) {
      this.show = true
      this.currentId = id
    }
  },
}
</script>

<style scoped>

</style>