import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const TeamBanAdapterMixin = {
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            getTeamsBan: 'getTeamsBan',
            getBanGamesByTeam: 'getBanGamesByTeam',
            getBanGameIdByTeam: 'getBanGameIdByTeam',
            getBanMembersByTeam: 'getBanMembersByTeam',
            getBanTeamsTotalPagination: 'getBanTeamsTotalPagination',
            getBanTeamsCurrentPage: 'getBanTeamsPaginationCurrentPage',
        }),
        teamsBanList: {
            get() {
                return this.getTeamsBan
            }, set(value) {
                this.setTeamsBan(value)
            }
        },
        banGamesByTeam: {
            get() {
                return this.getBanGamesByTeam
            }, set(value) {
                this.setBanGamesByTeam(value)
            }
        },
        banGameIdByTeam: {
            get() {
                return this.getBanGameIdByTeam
            }, set(value) {
                this.setBanGameIdByTeam(value)
            }
        },
        banMembersByTeam: {
            get() {
                return this.getBanMembersByTeam
            }, set(value) {
                this.setBanMembersByTeam(value)
            }
        },
        banTeamsTotalPagination: {
            get() {
                return this.getBanTeamsTotalPagination
            }, set(value) {
                this.setBanTeamsTotalPagination(value)
            }
        },
        banTeamsCurrentPage: {
            get() {
                return this.getBanTeamsCurrentPage
            }, set(value) {
                this.setBanTeamsCurrentPage(value)
            }
        },
    },
    methods: {
        ...mapMutations({
            setTeamsBan: mutypes.SET_TEAMS_BAN,
            setBanGamesByTeam: mutypes.SET_BAN_GAMES_BY_TEAM,
            setBanGameIdByTeam: mutypes.SET_BAN_GAME_ID_BY_TEAM,
            setBanMembersByTeam: mutypes.SET_BAN_MEMBERS_BY_TEAM,
            setBanTeamsTotalPagination: mutypes.SET_BAN_USERS_PAGINATION_TOTAL,
            setBanTeamsCurrentPage: mutypes.SET_BAN_USERS_PAGINATION_CURRENT_PAGE,
        }),
        ...mapActions([
            'indexTeamsBan',
            'grabTeamGames',
            'deleteTeamBan'
        ]),
    },
}