import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const PlatformsAdapterMixin = {
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            getPlatformsList                     : 'getPlatformsList',

        }),
        platforms             : {get() {return this.getPlatformsList                }, set(value) {this.setPlatformsList(value)      }},
    },
    methods: {
        ...mapMutations({
            setPlatformsList                  : mutypes.SET_PLATFORMS,
        }),
        ...mapActions([
            'grabPlatformsList',
        ]),
    }
}