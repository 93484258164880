import NotTeams from "@/components/Tournament/workspaces/NotTeams.vue";
import HaveTeams from "@/components/Tournament/PUBG/workspaces/HaveTeams.vue";
import AddTeam from "@/components/Tournament/workspaces/AddTeam.vue";
import EditorMatches from "@/components/Tournament/EditorMatches.vue";
import EditorTeam from "@/components/Tournament/PUBG/workspaces/EditorTeam.vue";
import LoadTeams from "@/components/Tournament/workspaces/LoadTeams.vue";
import ShowStatistics from "@/components/Tournament/workspaces/ShowStatistics.vue"

const workspaces = new Map()

workspaces.set('NotTeams', NotTeams)
workspaces.set('HaveTeams', HaveTeams)
workspaces.set('AddTeam', AddTeam)
workspaces.set('EditorMatches', EditorMatches)
workspaces.set('EditorTeam', EditorTeam)
workspaces.set('LoadTeams', LoadTeams)
workspaces.set('ShowStatistics', ShowStatistics)

export {
    workspaces
}