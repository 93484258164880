<template>
  <div>
    <b-modal centered id="modal-EntryDelete" title="Предупреждение" v-model="show">
      <p class="my-4">Вы действительно хотите удалить заявку?</p>
      <template #modal-footer>
        <div>
          <b-btn variant="primary" class="mr-3" @click="deleteEntryModal">Подтвердить</b-btn>
          <b-btn variant="danger" @click="show=false">Отмена</b-btn>
        </div>
      </template>
    </b-modal>
    <div class="d-flex  mb-5">
      <div>
        <label>Search</label>
        <b-form @submit.prevent="filterEntries()">
          <b-input type="search" class="w-50" v-model="dataForFilter"/>
          <div class="mt-4">
            <b-form-checkbox-group
                v-model="filteredArray"
                :options="filters"
            >
            </b-form-checkbox-group>
          </div>
          <b-btn variant="primary"
                 @click.prevent="filterEntries()"
                 class="mt-2 ml-2 row justify-content-center justify-content-md-start">Поиск
          </b-btn>
        </b-form>
      </div>
    </div>
    <b-table
        responsive
        show-empty
        stacked="md"
        :striped="striped"
        :bordered="bordered"
        :fields="fields"
        :items="entries"
        :current-page=0
        :per-page=10
        class="mb-5">
      <template #cell(team_name)="data">
        {{ data.item.team.name }}
      </template>
      <template #cell(team_cap)="data">
        {{ data.item.team?.captain?.full_name }}
      </template>
    </b-table>

    <b-col md="6" class="p-0">
      <b-pagination
          @change="handlePageChange"
          v-model="currentPage"
          :total-rows="total"
          :per-page="10"
          class="my-0"
      ></b-pagination>
    </b-col>
  </div>
</template>

<script>
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {TournamentEntriesAdapterMixin} from '@/mixins/Tournament/TournamentEntriesAdapterMixin';
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";

export default {
  name: "AddTeamEntriesStandingTournament",
  mixins: [TournamentEntriesAdapterMixin, ModalAdapterMixin, PaginationHelperMixin],
  data() {
    return {
      filteredArray: [],
      dataForFilter: '',
      pageKey: 'pageNumberEntriesList',
      show: false,
      entryId: null,
      fields: [
        {key: 'id', label: 'id', sortable: true},
        {key: 'team_name', label: 'Имя команды', sortable: true},
        {key: 'contacts', label: 'Контакты', sortable: true},
        {key: 'team_cap', label: 'Капитан команды', sortable: true},
      ],
      filters: {
        teamName: {text: "Найти по названию команды", value: "teamName"},
        ownerName: {text: "Найти по имени капитана", value: "ownerName"},
      },
      striped: true,
      bordered: true
    }
  },
  mounted() {
    this.recordPageToStorage(this.currentPage, this.pageKey)
    this.indexTournamentEntries({
      pageNumber: this.getCurrentPageNumber(this.pageKey),
      id: this.$route.params.standingId,
    })
  },
  methods: {
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.indexTournamentEntries({
        pageNumber: pageNumber,
        id: this.$route.params.standingId
      })
    },
    async deleteEntry(id) {
      this.entryId = id
      this.show = true
    },
    deleteEntryModal() {
      this.deleteTournamentEntry({entryId: this.entryId, tournamentId: this.$route.params.standingId})
          .then(() => this.show = false)
    },
    filterEntries() {
      let filteredData = {};
      this.filteredArray.forEach((item) => {
        filteredData[item] = this.dataForFilter;
      })
      filteredData['id'] = this.$route.params.standingId

      this.indexTournamentEntries(filteredData);
    }
  }
}
</script>

<style scoped>

</style>