<template>
  <div class="complete-tournament-mini-block-wrapper"
       :class="[
           tournament.end_time && 'has-end-time'
       ]"
  >
    <div class="left-time-complete">
      <template v-if="countDownTime">
        <span>Завершение через:</span>
        <span>{{ countDownTime }}</span>
      </template>
      <template v-else-if="isCompletedTournament">
        Завершен
      </template>
    </div>
    <button class="action-button"
            @click="isShowCompleteTournamentModal = true">
      {{ tournament.end_time ? 'Изменить дату' : 'Завершить' }}
    </button>
    <Modal name="CompleteTournament" title="Завершение турнира" v-model="isShowCompleteTournamentModal" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import moment from 'moment'

import Modal from '@/components/modals/index.vue'

export default {
  name: "CompleteTournamentMiniBlock",
  components: {
    Modal
  },
  computed: {
    ...mapState('moduleNewTournament', ['tournament']),
    ...mapGetters('moduleNewTournament', ['isCompletedTournament']),
    countDownTime() {
      // If not have end time tournament
      if (!this.tournament.end_time) return false
      const completeDate = moment(this.tournament.end_time.split('+')[0], 'YYYY-MM-DDTHH:mm:ss')
      // If status ended by date
      if (this.currentDate.isAfter(completeDate)) return false

      const dateByList = []
      const diff = completeDate.diff(this.currentDate)
      const duration = moment.duration(diff)

      if(duration.years()) dateByList.push(duration.years() + 'г.')
      if(duration.months()) dateByList.push(duration.months() + 'м.')
      if(duration.days()) dateByList.push(duration.days() + 'д.')
      if(duration.hours()) dateByList.push(duration.hours() + 'ч.')
      if(duration.minutes()) dateByList.push(duration.minutes() + 'м.')
      if(duration.seconds()) dateByList.push(duration.seconds() + 'с.')

      return dateByList.join(' ')
    }
  },
  data() {
    return {
      isShowCompleteTournamentModal: false,
      currentDate: moment()
    }
  },
  mounted() {
    if (this.tournament.end_time) {
      setInterval(() => this.currentDate = moment(), 1000)
    }
  }
}
</script>

<style scoped lang="scss">
.complete-tournament-mini-block-wrapper {

  &.has-end-time {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .left-time-complete {
    font-size: 11px;
    color: #b6b6b6;
    line-height: 14px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 5px;

    & * {
      display: block;
      white-space: nowrap;
    }
  }
}
</style>