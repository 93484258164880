<template>
  <div>
    <b-input
        :value="$props.slot_number"
        @change="changeSlotNumber($event)"
        type="number">
    </b-input>
  </div>
</template>

<script>
export default {
  name: "change_slot_number_input",
  props: {
    slot_number: {
      type: Number|null,
      default: 0
    },
    item: {
      type: Object,
      default: {}
    },
  },
  data() {
    return {
      slotNumber: this.$props.slot_number || 0,
    };
  },
  methods: {
    changeSlotNumber(value) {

      this.$emit('changeSlotNumber', {
        id:this.$props.item.id,
        slot_number:value
      })
    }
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>