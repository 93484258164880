import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/group'
const NEW_END_POINT = '/api/v1/admin/group'

export default {
    /**
     * GET: Tournament, can be filtered by params
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    index: (params) => adminHttp.get(END_POINT + `/`, params),
    
    /**
     * POST: Tournament, can be stored by id
     * @returns {Promise<AxiosResponse<any>>}
     */
    store: (params) => adminHttp.post(NEW_END_POINT, params),
    
    /**
     * DELETE: Tournament, can be stored by id
     * @returns {Promise<AxiosResponse<any>>}
     */
    
    del: (id) => adminHttp.delete(NEW_END_POINT + `/${id}`),

    show:(id) => adminHttp.get(NEW_END_POINT + `/${id}`),

    update:(id, params) => adminHttp.put(NEW_END_POINT + `/${id}`, params)
}