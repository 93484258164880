import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            features: [],
        }
    },
    mutations: {
        [mutypes.SET_FEATURES]: (state, value) => state.features = value,
    },

    getters: {
        getFeaturesList: state => state.features,
    },

    actions: {
        grabFeaturesList: async ({commit, getters}) => {
            let response = await api.features.index();
            let featureList = response.data.features
            let values = []
            featureList.forEach((item, index) => {
                values[index] = {
                    value: item.id,
                    text: item.name
                };
            })

            commit(mutypes.SET_FEATURES, values);

        },
    }
}