<template>
  <div class="load-teams-wrapper">
    <b-spinner class="spinner" />
    <span>Загрузка команд</span>
  </div>
</template>

<script>
export default {
  name: "LoadTeams"
}
</script>

<style scoped lang="scss">
.load-teams-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30vh;

  span {
    margin-top: 20px;
  }
}
</style>