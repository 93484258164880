
export const DISCORD = 'discord';
export const TELEGRAM = 'telegram';
export const EMAIL = 'email';
export const GOOGLE = 'google';

export const ACCOUNTS = {
    [DISCORD]: 'https://discordapp.com/users/',
    [TELEGRAM]: 'https://telegram.me/',
    [EMAIL]: 'mailto:',
    [GOOGLE]: 'http://mail.google.com/mail/',
}