import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/stage'
const NEW_END_POINT = '/api/v1/admin/stage'

export default {
    /**
     * POST: Tournament, can be stored by id
     * @returns {Promise<AxiosResponse<any>>}
     */
    store: (params) => adminHttp.post(NEW_END_POINT, params),

    /**
     * PUT: Tournament, can be updated by id
     * @returns {Promise<AxiosResponse<any>>}
     */
    update: (id, data) => adminHttp.put(NEW_END_POINT + `/${id}`, data),

    /**
     * DELETE: Tournament, can be updated by id
     * @returns {Promise<AxiosResponse<any>>}
     */

    delete: (id) => adminHttp.delete(NEW_END_POINT + `/${id}`),

    /**
     * Get: Tournament, can be updated by id
     * @returns {Promise<AxiosResponse<any>>}
     */
    show: (id) => adminHttp.get(NEW_END_POINT + `/${id}`),

    /**
     * Post: Drop team, in tournament stage
     * @returns {Promise<AxiosResponse<any>>}
     */
    
    dropStageTeam: (params) => adminHttp.post(END_POINT + `/drop-team/${params.team_id}/${params.stage_id}`, params),

    createRound: (stageId, params) => adminHttp.post(`/api/v1/admin/stage/round/store/${ stageId }`, params),

    updateRound: (id, params) => adminHttp.post(`/api/v1/admin/stage/round/${ id }`, params),

    getGroupsFromNextRound: (currentRoundId) => adminHttp.get(`/api/v1/admin/stage/round/next-round-groups/${ currentRoundId }`),

    tournamentStages: (tournamentId) => adminHttp.get(`/api/v1/admin/tournament/stage-list/${ tournamentId }`),

    entryStatuses: () => adminHttp.get('/api/v1/admin/entry/status-list')
}