import adminHttp from '@/api/adminHttp';

const END_POINT = '/api/v1/admin/tournament';

export default {
  /**
   * GET: Tournament statistics
   * @param {number} tournamentId - The ID of the tournament
   * @returns {Promise<Array>} - A promise that resolves to an array of team statistics
   */
  getTournamentStatistics(tournamentId) {
    return adminHttp.get(`${END_POINT}/${tournamentId}/statistics`)
      .then(response => {
        return response.data.map(team => ({
          id: team.id,
          name: team.name,
          kill_pts: team.group_teams.reduce((acc, group) => acc + group.group_result.result_fields.kill_pts, 0),
          place_pts: team.group_teams.reduce((acc, group) => acc + group.group_result.result_fields.place_pts, 0),
        }));
      });
  },

  /**
   * GET: Team statistics for a tournament
   * @param {number} tournamentId - The ID of the tournament
   * @param {number} teamId - The ID of the team
   * @returns {Promise<Array>} - A promise that resolves to an array of team member statistics
   */
  getTournamentTeamStatistics(tournamentId, teamId) {
    return adminHttp.get(`${END_POINT}/${tournamentId}/statistics/${teamId}`)
      .then(response => {  
        if (Array.isArray(response.data)) {
          const mappedData = response.data.map(member => ({
            user_id: member.user_id,
            username: member.user.username,
            kill_pts: member.kill_pts,
          }));
          return mappedData;
        } else {
          console.error('Response data is not an array:', response.data);
          return [];
        }
      });
  }
};