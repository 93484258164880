export const providersList = [
    {
        name: 'email',
        link: 'mailto:'
    },
    {
        name: 'google',
        link: 'http://mail.google.com/mail/'
    },
    {
        name: 'discord',
        link: 'https://discordapp.com/users/'
    },
    {
        name: 'telegram',
        link: 'https://telegram.me/'
    },
    {
        name: 'phone',
    },
    {
        name: 'whatsapp',
    },
]
