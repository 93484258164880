import adminHttp from "@/api/adminHttp";

const END_POINT = '/api';

export default {
    index: (params) => adminHttp.get(END_POINT + '/admin/user/rating', {params: params}),

    show: (id, params) => adminHttp.get(END_POINT + `/admin/user/rating/${id}`, {params: params}),

    actualize: (gameId) => adminHttp.get(END_POINT + `/admin/rating/actualize/user/${gameId}`),

    actualizedTime: () => adminHttp.get(END_POINT + `/v1/admin/user/rating-actualize-datetime`),
}