<template>
  <div>
    <b-modal id="modalExistingMatch" v-model="show" centered>
        <div>Вы действительно хотите удалить этот матч?</div>
      <template #modal-footer>
        <div>
          <b-btn variant="primary" class="mr-3" @click="realDeleteMatch">Удалить</b-btn>
          <b-btn variant="danger" @click="show = false">Отмена</b-btn>
        </div>
      </template>
    </b-modal>
  <div class="container-fluid">
  <div v-for="match in currentMatches" :key="match.id + 'id'">
    <h1>Матч {{ match.id }}</h1>
    <b-form>
      <div class="d-flex flex-wrap">
        <div class="mr-2">
          <label>Название матча</label>
          <ValidationProvider rules="min:2|required" v-slot="{errors}">
            <b-form-input @input="onChange(match.name,'name')" v-model="match.name"
                          placeholder="Введите имя матча"/>
            <div class="text-danger">{{errors[0]}}</div>
          </ValidationProvider>
        </div>

        <div class="mr-2">
          <label>Lobby number</label>
          <ValidationProvider rules="min:2|required" v-slot="{errors}">
            <b-form-input @input="onChange(match.lobby_number,'lobby_number')" v-model="match.lobby_number"
                          placeholder="Введите номер лобби"></b-form-input>
            <div class="text-danger">{{errors[0]}}</div>
          </ValidationProvider>
        </div>

        <div class="mr-2">
          <label>Password</label>
          <ValidationProvider rules="min:2|required" v-slot="{errors}">
            <b-form-input @input="onChange(match.password,'password')" v-model="match.password"
                          placeholder="Введите пароль матча"></b-form-input>
            <div class="text-danger">{{errors[0]}}</div>
          </ValidationProvider>
        </div>

        <div class="mr-2">
          <label>Дата</label>
            <DatePicker @input="onChange(match.start_time,'start_time')" v-model="match.start_time" mode="dateTime"  is24hr :model-config="modelConfig" :popover="{visibility: 'click'}">
              <template v-slot="{inputValue, inputEvents}">
                <b-form-input :value="inputValue" v-on="inputEvents"/>
              </template>
            </DatePicker>
        </div>

<!--        <div class="mr-2">-->
<!--          <label>Cтатус</label>-->
<!--            <b-form-input type="number" @input="onChange(match.status,'status')" v-model="match.status"-->
<!--                          placeholder="Введите статус"/>-->
<!--        </div>-->

        <div class="">
          <div class="mb-1 mt-1">Удаление</div>
          <b-btn variant="danger" type="button" v-b-modal="'modalExistingMatch'"  class="m-0 px-1" @click="deleteModalMatch(match.id)">Удалить матч</b-btn>
        </div>
      </div>
    </b-form>
  </div>
  </div>
  </div>
</template>

<script>
import {TournamentMatchesAdapterMixin} from "@/mixins/TournamentMatch/TournamentMatchesAdapterMixin";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
export default {
  name: "ExistingMatchForms",
  mixins: [TournamentMatchesAdapterMixin],
  components: {DatePicker},
  data() {
    return {
      show: false,
      id: null,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
    }
  },
  methods: {
    onChange(val, resKey) {
      let data = {};
      data[resKey] = val;
      data['key'] = resKey;
      this.$emit('changeMatch',data);
    },
    deleteModalMatch(id){
      this.id = id
    },
    realDeleteMatch(){
      this.show = false
      this.deleteShowMatch(this.id)
    }
  },
}
</script>

<style scoped>

</style>