<template>
  <tr :style="member.death > 0 ? 'background-color: #d6d6d6' : ''">
    <td class="align-middle " style="width: 140px"> {{ member.info.nick }}</td>
    <td class="align-middle mr-2">{{ member.kills + killForSend }}</td>
    <td class="p-1 align-middle">
      <b-btn :disabled="showEventData?.type === 'ended'"
             @click="increaseKill()"
             variant="primary"
             class="p-1 mr-4">
        <i class="fa fa-caret-up p-1 px-2"></i>
      </b-btn>
    </td>
    <td class="p-1 align-middle">
      <b-btn :disabled="showEventData?.type === 'ended'"
             @click="decreaseKill()"
             variant="danger"
             class="p-1 mr-4">
        <i class="fa fa-caret-down p-1 px-2"></i>
      </b-btn>
    </td>
    <td class="p-1 align-middle">
      <b-btn :disabled="showEventData?.type === 'ended'"
             @click="changeDeath(member.death)"
             :variant="member.death > 0 ? 'dark' : 'primary'"
             class="px-2 mr-4">
        {{ deathInString }}
      </b-btn>
    </td>
    <td class="p-1 align-middle">
      <b-btn
          :disabled="showEventData?.type === 'ended'"
          @click.prevent="storeKill({
                                              matchId: selectedMatch,
                                              token: team.hash,
                                              user_id: member.user_id,
                                              kill: calculateKills(),
                                              death: deathForSend
                                            }).then((res)=>{
                                                killForSend = 0;
                                            })" variant="primary" class="p-2">Send
      </b-btn>
    </td>
  </tr>
</template>

<script>
import {BookAdapterMixin} from "@/mixins/Book/BookAdapterMixin";

export default {
  name: "RowInHostsBook",
  mixins: [BookAdapterMixin],
  data() {
    return {
      killForSend: 0,
      deathForSend: 0,
      deathInString: 'Да',
      clickDeathButton: false,
      clickSendWithNull: false,
    };
  },
  props: {
    member: {
      type: Object,
      default: null
    },
    team: {
      type: Object,
      default: null
    },
  },
  mounted() {
    this.deathInString = (this.$props.member.death === 1) ? 'Нет' : 'Да';
    this.deathForSend = (this.$props.member.death === 1) ? 1 : 0;
  },
  methods: {
    increaseKill() {
      this.killForSend += 1
      this.clickSendWithNull = true
    },
    decreaseKill() {
      --this.killForSend;
      this.clickDeathButton = true
    },
    calculateKills() {
      // let kills = 1;
      // if (this.clickDeathButton) {
      //   kills = -1;
      // } else {
      //   kills = this.clickSendWithNull == false ? 0 : 1;
      // }
      this.clickDeathButton = false;
      this.clickSendWithNull = false;
      return this.killForSend;
    },
    changeDeath() {
      this.deathInString = (this.deathInString === 'Нет')  ? 'Да' : 'Нет';
      this.deathForSend = (this.deathInString === 'Нет') ? 1 : 0;
    }
  }
}
</script>

<style scoped>

</style>