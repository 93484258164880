import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";
import api from '@/api/api'

export const TournamentStageAdapterMixin = {
    data() {
        return {
            newStageName: '',
            newStageCoeff: '',
            newReplacements: null,
            newStatus: null,
            thresholdRating: 0,
            newStatusOptions: [
                {value: 1, text: 'Активный'},
                {value: 0, text: 'Завершен'}
            ],
            divisions: [],
            personalRating: null
        }
    },
    computed: {
        ...mapGetters({
            getTournamentStage       : 'getTournamentStage',
            getTournamentStageName   : 'getTournamentStageName',
            getTournamentStageRating : 'getTournamentStageRating',
            getTournamentStageStatus : 'getTournamentStageStatus',
            getTournamentStageReplacement : 'getTournamentStageReplacement',
            getTournamentStageThresholdRating: 'getTournamentStageThresholdRating',
            getTournamentStageDivisions: 'getTournamentStageDivisions',
            getTournamentStageCoefPersonalRating: 'getTournamentStageCoefPersonalRating'

        }),
        stage               : {get() {return this.getTournamentStage                 }, set(value) {this.setTournamentStage(value)       }},
        name                : {get() {return this.getTournamentStageName                 }, set(value) {this.setTournamentStageName(value)       }},
        rating              : {get() {return this.getTournamentStageRating                 }, set(value) {this.setTournamentStageRating(value)       }},
        status              : {get() {return this.getTournamentStageStatus                }, set(value) {this.setTournamentStageStatus(value)       }},
        replacement         : {get() {return this.getTournamentStageReplacement           }, set(value) {this.setTournamentStageReplacement(value) }},
        thresholdRatingUpdate     : {get() { return this.getTournamentStageThresholdRating }, set(value) { this.setTournamentStageThresholdRating(value) } },
        divisionsUpdate: { get() { return this.getTournamentStageDivisions }, set(value) { this.setTournamentStageDivisions(value) } },
        personalRatingUpdate: { get() { return this.getTournamentStageCoefPersonalRating }, set(value) { this.setTournamentStageCoefPersonalRating(value) } }
    },
    methods: {
        ...mapMutations({
            setTournamentStage: mutypes.SET_TOURNAMENT_STAGE,
            setTournamentStageName: mutypes.SET_TOURNAMENT_STAGE_NAME,
            setTournamentStageRating: mutypes.SET_TOURNAMENT_STAGE_RATING_COEFFICIENT,
            setTournamentStageStatus: mutypes.SET_TOURNAMENT_STAGE_STATUS,
            setTournamentStageReplacement: mutypes.SET_TOURNAMENT_STAGE_REPLACEMENT,
            setTournamentStageThresholdRating: mutypes.SET_TOURNAMENT_STAGE_THRESHOLD_RATING,
            setTournamentStageDivisions: mutypes.SET_TOURNAMENT_STAGE_DIVISIONS,
            setTournamentStageCoefPersonalRating: mutypes.SET_TOURNAMENT_STAGE_COEF_PERSONAL_RATING,
        }),
        ...mapActions([
            'updateTournamentStage',
            'showTournamentStage',
            'storeTournamentStage',
            'updateTournamentStage'
        ]),
        async getDivisions() {
            this.divisions = (await api.divisions.getDefaultStageDivisions(this.$route.params.id)).data
        },
        async createTournamentStage() {
            await this.storeTournamentStage( {
                    'name'              : this.newStageName,
                    'rating_coefficient': this.newStageCoeff,
                    'status'            : this.newStatus,
                    'tournament_id'     : this.$route.params.id,
                    'replace_count'     : +this.newReplacements,
                    'divisions'         : this.divisions.map((div) => ({
                        division_id: div.division_id,
                        slot_count: Number(div.slot_count)
                    })),
                    'threshold_rating'  : Number(this.thresholdRating),
                    'player_rating_coefficient': this.personalRating,
            }).
            then(()=>this.$router.go(-1))
        },
    }
}
