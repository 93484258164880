import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            fakeUsers: [],
            pagination:{
                by:10,
                currentPage:1,
                totalPages:1,
            },
            fakeUser:{
                email:'',
                name:'',
                password:'123456bigplay'
            },
        }
    },
    mutations: {
        [mutypes.SET_FAKE_USERS]: (state, value) => state.fakeUsers = value,
        [mutypes.SET_FAKE_USERS_PAGINATION_TOTAL]: (state, value) => state.pagination.totalPages = value,
        [mutypes.SET_FAKE_USERS_PAGINATION_CURRENT_PAGE]: (state, value) => state.pagination.currentPage = value,

        [mutypes.SET_FAKE_USER_EMAIL]: (state, value) => state.fakeUser.email = value,
        [mutypes.SET_FAKE_USER_NAME]: (state, value) => state.fakeUser.name = value,
        [mutypes.SET_FAKE_USER_PASSWORD]: (state, value) => state.fakeUser.password = value,

    },

    getters: {
        getFakeUsers: state => state.fakeUsers,
        getFakeUsersPaginationTotal            : state => state.pagination.totalPages,
        getFakeUsersPaginationCurrentPage      : state => state.pagination.currentPage,

        getFakeUserEmail                        : state => state.fakeUser.email,
        getFakeUserName                         : state => state.fakeUser.name,
        getFakeUserPassword                     : state => state.fakeUser.password,


    },

    actions: {
        grabFakeUsers: async ({commit, getters}, params) => {
            const response = await api.userFake.index({
                page: params.pageNumber || 1,
            });
            commit(mutypes.SET_FAKE_USERS, response.data.fake_users);
            commit(mutypes.SET_FAKE_USERS_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_FAKE_USERS_PAGINATION_CURRENT_PAGE, params.pageNumber);

            return response.status;
        },
        storeFakeUser: async ({commit, getters}, params) => {
            const response = await api.userFake.store(params);
            return response.status;
        },
        updateFakeUser: async ({commit, getters},params) => {
            const response = await api.userFake.update(params.id,params);
            return response.status;
        },
        grabFakeUser: async ({commit, getters}, id) => {
            const response = await api.userFake.show(id);

            commit(mutypes.SET_FAKE_USER_EMAIL, response.data.fake_user.email);
            commit(mutypes.SET_FAKE_USER_NAME, response.data.fake_user.name);

            return response.status;
        },
        deleteFakeUser: async ({commit, getters}, id) => {
            const response = await api.userFake.delete(id)
            let fakeUsers = getters.getFakeUsers.filter(t => t.id !== id)
            await commit(mutypes.SET_USERS, fakeUsers);
        }

    }
}