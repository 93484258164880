<template>
  <div class="editor-team-wrapper">
    <header>
      <button class="button-back" @click="$emit('changeWorkspace', null)">
        <img :src="AngleLeftIcon" alt="" />
        Назад
      </button>
      <h2>
        Редактирование результатов {{ selectedTeam.name }}
      </h2>
    </header>
    <div class="body">
      <div v-if="isLoadingData" class="loading-wrapper">
        <b-spinner class="spinner"></b-spinner>
      </div>
      <div v-else-if="matches && !matches.length" class="not-matches">
        <h4>Отсутвуют матчи для добавления результатов</h4>
      </div>
      <template v-else-if="matches && matches.length">
        <EditResultsTeamCard v-for="match in matches"
                             v-model="match.newMatch"
                             :key="'match-' + match.id + match.lobby_number" />
        <div v-if="!isCompletedTournament" class="actions">
          <b-button @click="saveResults" :disabled="isLoadingSubmitResults" variant="primary" class="save-match-button">
            {{ isLoadingSubmitResults ? 'Сохраняем все...' : 'Сохранить все' }}
          </b-button>
        </div>
      </template>
      <b-alert v-if="errorText" show class="mt-4" variant="danger">
        {{ errorText }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AngleLeftIcon from '@/assets/icons/angle-left.svg'
import api from "@/api/api";
import EditResultsTeamCard from "@/components/Tournament/PUBG/components/EditResultsTeamCard.vue";

export default {
  name: "EditTeam",
  components: {
    EditResultsTeamCard
  },
  data() {
    return {
      AngleLeftIcon,
      isLoadingData: false,
      errorText: null,
      matches: null,
      isLoadingSubmitResults: false
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['selectedTeamId', 'selectedGroupResultId', 'tournament', 'selectedGroupId']),
    ...mapGetters('moduleNewTournament', ['selectedTeam', 'isCompletedTournament'])
  },
  methods: {
    async getTeamResults() {
      try {
        this.errorText = null
        this.isLoadingData = true
        const response = (await api.tournamentGroupResult.newShow(this.selectedGroupResultId)).data
        this.matches = response?.matches.map((match) => {
          const foundMatch = response.group_result.group_match_results.find(({ match_id }) => match_id === match.id) || {
            place: 0,
            match_id: match.id,
            groupMatchResultPlayers: response.players.map((player) => ({
              ...player,
              ...(() => {
                const user = JSON.parse(JSON.stringify(player))
                user.gameProfile = user.game_profiles.find(({ game_id }) => game_id === this.tournament.game_id)
                return {
                  user,
                }
              })(),
              kill_pts: 0,
              place: 0
            }))
          }
          foundMatch.match = match
          if (foundMatch.result_fields) foundMatch.place = foundMatch.result_fields.place
          if (foundMatch.group_match_result_players) {
            foundMatch.groupMatchResultPlayers = response.players.map((player) => {
              const playerResults = foundMatch.group_match_result_players.find(({ user_id }) => user_id === player.id)

              const user = JSON.parse(JSON.stringify(player))
              user.gameProfile = user.game_profiles.find(({ game_id }) => game_id === this.tournament.game_id)

              return {
                ...(playerResults && playerResults.result_fields || { kill_pts: 0, place: 0 }),
                user,
                ...player,
              }
            })
          }
          return {
            ...foundMatch,
            newMatch: JSON.parse(JSON.stringify(foundMatch))
          }
        }) || []
      } catch (e) {
        console.log('e ', { e })
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingData = false
      }
    },
    async saveResults() {
      try {
        this.isLoadingSubmitResults = true
        this.errorText = null
        await api.tournamentGroupResult.newStore(this.selectedGroupResultId, {
          team_id: this.selectedTeamId,
          matches: this.matches.reduce((state, { newMatch }) => {
            const players = newMatch.groupMatchResultPlayers.map((player) => ({
              kill_pts: Number(player.kill_pts),
              match_id: newMatch.match_id,
              place: Number(newMatch.place),
              user_id: player.user.id
            }))
            return [ ...state, ...players ]
          }, [])
        })
        await this.$store.dispatch('moduleNewTournament/getTournament', {
          id: this.$route.params.standingId
        })
        await this.$store.dispatch('moduleNewTournament/getTeamsByGroup', { id: this.selectedGroupId })
        await this.getTeamResults()
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSubmitResults = false
      }
    }
  },
  created() {
    this.getTeamResults()
  },
  destroyed() {
    this.$store.commit('moduleNewTournament/setFirstLevelData', {
      name: 'selectedTeamId',
      value: null
    })
    this.$store.commit('moduleNewTournament/setFirstLevelData', {
      name: 'selectedGroupResult',
      value: null
    })
  }
}
</script>

<style scoped lang="scss">
.editor-team-wrapper {
  header {
    display: flex;
    align-items: center;
    justify-content: left;

    @media screen and (max-width: 560px) {
      display: block;
    }

    h2 {
      font-size: 24px;
      margin: 0 0 0 10px;

      @media screen and (max-width: 800px) {
        font-size: 22px;
      }

      @media screen and (max-width: 500px) {
        font-size: 18px;
      }
    }
  }

  .button-back {
    background: transparent;
    color: #7366ff;
    border: none;
    border-radius: 15px;
    padding: 8px 14px;
    font-size: 14px;
    transition: .2s;

    display: flex;
    align-items: center;

    &:hover {
      background: #dad6ff;
    }

    img {
      margin-right: 7px;
      height: 12px;
    }
  }

  .body {
    .loader-wrapper,
    .not-matches {
      min-height: 50vh;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .not-matches {
      h4 {
        font-size: 17px;

        @media screen and (max-width: 400px) {
          font-size: 14px;
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;

      & > * {
        margin: 0;
      }

      button {
        font-size: 13px;
        border-radius: 15px;
        display: block;

        @media screen and (max-width: 800px) {
            font-size: 11px;
        }

        &.add-match-button {
          background-color: white !important;
          color: #7366ff;

          &:hover {
            background-color: #7366ff !important;
            color: white;
          }
        }
      }
    }
  }
}
</style>