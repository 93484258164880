<template>
  <div class="transfer-team-to-next-round">
    <h5>Уверены ли вы, что команда <b>{{ params.teamResult.team.name }}</b> переходит в след. раунд?</h5>
    <footer>
      <b-button @click="sendWinner" :disabled="isLoadingSaveForm">
        {{ isLoadingSaveForm ? 'Переносим...' : 'Да' }}
      </b-button>
    </footer>
    <b-alert v-if="errorText" show variant="danger" style="margin: 10px 0 0 0">{{ errorText }}</b-alert>
  </div>
</template>

<script>
import {mapState} from "vuex";
import api from "@/api/api";

export default {
  name: "TransferTeamToNextRound",
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoadingSaveForm: false,
      errorText: null,
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['selectedRoundId']),
  },
  methods: {
    async sendWinner() {
      try {
        this.isLoadingSaveForm = true
        this.errorText = null
        await api.tournamentGroupResult.sendWinner(this.params.teamResult.id)

        await this.$store.dispatch('moduleNewTournament/getTournament', {
          id: this.$route.params.standingId
        })
        await this.$store.dispatch('moduleNewTournament/getGroupsWithTeamsByMLBB', { roundId: this.selectedRoundId })
        this.$emit('submit', this.params.teamResult)
        this.$emit('closeModal')
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSaveForm = false
      }
    },
  }
}
</script>

<style scoped lang="scss">
.transfer-team-to-next-round {
  h5 {
    font-size: 16px;
    font-weight: 400;
  }
  footer {
    border-top: 1px solid #dee2e6;
    padding-top: 15px;
    margin-top: 15px;
    text-align: right;
  }
}
</style>