<template>
  <div class="have-teams-workspace-wrapper">
    <MainActions v-model="selectedTeams" v-if="!isCompletedTournament" />
    
    <div ref="actionsBlock" class="actions">
      <div>  
        <b-input v-model="searchTeamModel"
                  class="search-teams-input-custom"
                  v-b-tooltip.hover.top="'Поиск доступен по названию команды, nick и PUBG_ID'"
                  placeholder="Поиск команды" />     
      </div>
        <div class="buttons" v-if="!isCompletedTournament">
          <template v-if="isShowAllActions">
            <button v-for="action in actions"
                    class="action-button"
                    :key="action.title"
                    @click="action.method">
              <span class="d-none d-sm-block">{{ action.title }}</span>
              <span v-if="action.icon" class="d-sm-none d-block" v-html="action.icon" />
            </button>
          </template>
          <template v-else>
            <button v-for="action in actions.filter((action) => action.main)"
                    class="action-button"
                    :key="action.title"
                    @click="action.method">
              <span class="d-none d-sm-block">{{ action.title }}</span>
              <span v-if="action.icon" class="d-sm-none d-block" v-html="action.icon" />
            </button>
            <b-dropdown right
                        text="Еще"
                        variant="primary"
                        class="action-more-dropdown">
              <b-dropdown-item v-for="action in actions.filter((action) => !action.main)"
                              @click="action.method"
                              :key="action.title">
                {{ action.title }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </div>
        <!-- For ended tournaments -->
        <div v-else class="buttons">
          <template>
            <button v-for="action in newactions"
                    class="action-button"
                    :key="action.title"
                    @click="action.method">
              <span class="d-none d-sm-block">{{ action.title }}</span>
              <span v-if="action.icon" class="d-sm-none d-block" v-html="action.icon" />
            </button>
          </template>
        </div>
    </div>
    <transition-group name="list-complete" tag="div" class="transition-list">
      <template v-for="({ team, group_members, ...other }, index) in filteredTeams">
        <TeamCard v-if="team"
                  :serialNumber="index + 1"
                  v-model="selectedTeams"
                  @editTeam="editTeam"
                  :team="team"
                  :result="other"
                  :members="group_members"
                  :key="`team-card-${ other.id } ${ other.team_id } ${ other.group_id }`"
        />
      </template>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import TeamCard from "@/components/Tournament/PUBG/components/TeamCard.vue";
import MainActions from "@/components/Tournament/MainActions.vue";
import api from "@/api/api";
import Vue from "vue";
import moment from 'moment'

export default {
  name: "HaveTeamsWorkspace",
  components: {
    TeamCard,
    MainActions
  },
  computed: {
    ...mapGetters('moduleNewTournament', ['selectedGroup', 'isCompletedTournament', 'tournament', 'tournamentStatistics', 'teamStatistics']),
    ...mapState('moduleNewTournament', ['selectedGroupId', 'selectedStageId', 'teamsByGroup', 'tournament']),
    filteredTeams() {
      let resultList = []
      if (!this.searchTeamModel.length) {
        resultList = this.teamsByGroup
      } else {
        const byTeams = this.teamsByGroup.filter(({ team }) => {
          return team.name.toLowerCase().search(this.searchTeamModel.toLowerCase()) > -1
        })
        const byMembersNickAndId = this.teamsByGroup.filter(({ group_members }) => {
          return group_members.some((groupMember) => {
            const gameProfile = groupMember.user.game_profiles.find(({ game_id }) => game_id === this.tournament.game_id)
            const nick = gameProfile?.fields?.nick || ''
            const id = String(gameProfile?.fields?.pubg_id) || ''
            return nick.toLowerCase().search(this.searchTeamModel.toLowerCase()) > -1 || id.search(this.searchTeamModel.toLowerCase()) > -1
          })
        })
        const resultTeams = [
          ...byTeams,
          ...byMembersNickAndId
        ]
        const unicalResultTeams = [...new Set(resultTeams.map(({ team_id }) => team_id ))]
        resultList = unicalResultTeams.map((teamId) => resultTeams.find(({ team_id }) => team_id === teamId))
      }
      return resultList.sort((a, b) => {
        const aKillPts = a.group_result.result_fields.kill_pts
        const bKillPts = b.group_result.result_fields.kill_pts

        const aPlacePts = a.group_result.result_fields.place_pts
        const bPlacePts = b.group_result.result_fields.place_pts

        const aData = aKillPts + aPlacePts
        const bData = bKillPts + bPlacePts

        if (aData === bData && aPlacePts === bPlacePts) return bKillPts - aKillPts
        if (aData === bData) return bPlacePts - aPlacePts
        return bData - aData
      })
    },
  },
  data() {
    return {
      selectedTeams: [],
      searchTeamModel: '',
      isShowAllActions: false,
      selectedTeamId: null,
      isLoadingDownloadExcel: false,
      newactions: [
        {
          main: true,
          title: 'Статистика',
          icon: '<i class="fa fa-trophy fa-lg" aria-hidden="true"></i> +',
          method: () => {
            this.$emit('changeWorkspace', 'ShowStatistics')
          }
        },
        {
          main: true,
          title: 'Скачать статистику',
          icon: '<i class="fa fa-trophy fa-lg" aria-hidden="true"></i> +',
          method: () => { this.downloadStatisticsExcel() }
        },
      ],
      actions: [
        {
          title: 'Режим бога',
          icon: '<i class="fa fa-trophy fa-lg" aria-hidden="true"></i> +',
          method: () => {
            // this.$emit('changeWorkspace', 'EditorMatches')
            this.$store.commit('moduleNewTournament/setFirstLevelData', {
              name: 'selectedDashboard',
              value: 'god'
            })
          }
        },
        {
          main: true,
          title: 'Добавить матч',
          icon: '<i class="fa fa-trophy fa-lg" aria-hidden="true"></i> +',
          method: () => {
            this.$emit('changeWorkspace', 'EditorMatches')
          }
        },
        {
          main: true,
          title: 'Добавить команду',
          icon: '<i class="fa fa-users fa-lg" aria-hidden="true"></i> +',
          method: () => {
            this.$emit('changeWorkspace', 'AddTeam')
          }
        },
        {
          title: 'Live',
          method: () => {
            this.$router.push({
              name: 'live',
              params: {
                group_id: this.selectedGroupId,
                standingId: this.$route.params.standingId,
                stage_id: this.selectedStageId
              }
            })
          }
        }
      ]
    }
  },
  methods: {
    ...mapActions('moduleNewTournament', ['fetchTournamentStatistics', 'fetchTeamStatistics', 'setFilteredTeamIds']),
    editTeam({ team, result }) {
      this.$store.commit('moduleNewTournament/setFirstLevelData', { name: 'selectedTeamId',value: team.id })
      this.$store.commit('moduleNewTournament/setFirstLevelData', { name: 'selectedGroupResultId', value: result.group_result.id })
      this.$emit('changeWorkspace', 'EditorTeam')
    },
    redirectToLive() {
      this.$router.push({
        name: 'live',
        params: {
          group_id: this.selectedGroupId,
          standingId: this.$route.params.standingId,
          stage_id: this.selectedStageId
        }
      })
    },
    async downloadStatisticsExcel() {
      try {
        this.isLoadingDownloadExcel = true

        const downloadResponse = await api.excel.getTournamentStatistics(this.tournament.id,{
          method: 'GET',
          headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'blob'
        });
        // get current date
        let currentDate = new Date();
        let formattedDate = moment(currentDate).format('DD.MM.YYYY');

        const link = document.createElement('a');
        link.href = URL.createObjectURL(downloadResponse.data);
        link.setAttribute('download', `${formattedDate }_statistics.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        Vue.toasted.success("Успешно скачано", {
          position: 'top-right',
          duration: 3000
        })
      } catch (error) {
        Vue.toasted.error(error.response.data.message || "Не удалось скачать", {
          position: 'top-right',
          duration: 3000
        });
      } finally {
        this.isLoadingDownloadTopPlayers = false
      }
    },
  },
  mounted() {
    const teamIds = this.filteredTeams.map(team => team.team.id);

    this.setFilteredTeamIds(teamIds);

    const resizeObserverActionsBlock = new ResizeObserver((entries) => {
      try {
        const parentWrapperWidth = entries[0].target.offsetWidth
        this.isShowAllActions = parentWrapperWidth >= 640
      } catch (e) {
        console.log('e ', { e })
      }
    })
    resizeObserverActionsBlock.observe(this.$refs.actionsBlock)
  }
}
</script>


<style scoped lang="scss">
.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}
.list-complete-enter, .list-complete-leave-to
  /* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(30px);
}
.list-complete-leave-active {
  position: absolute;
}
.have-teams-workspace-wrapper {
  position: relative;

  button.action {
    font-size: 11px;
    border-radius: 13px;
    padding: 5px 13px;
    background: white;
    color: #000000bf;
    border: 1px solid #000000bf;
    transition: .2s;

    &:hover {
      background: #000000bf;
      color: white;
    }

    &.delete {
      color: #ff0000c9;
      border-color: #ff0000c9;

      &:hover {
        color: white;
        background: #ff0000c9;
      }
    }

    &.select {
      border: 1px solid #7366ff;
      color: #7366ff;

      &:hover {
        color: white;
        background: #7366ff;
      }
    }
  }

  .search-teams-input-custom {
    font-size: 13px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    @media screen and (max-width: 500px) {
      display: block;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      @media screen and (max-width: 500px) {
        margin-top: 14px;
        justify-content: flex-start;
      }
    }
  }

  & > .transition-list {
    margin-top: 20px;

    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .action-button {
    display: block;
    font-size: 11px;
    border-radius: 15px;
    padding: 10px 20px;
    background: #7366ff;
    color: white;
    border: none;
    white-space: nowrap;

    @media screen and (max-width: 800px) {
      padding: 10px 15px;
    }
  }
}

.action-more-dropdown::v-deep {
  button {
    display: block;
    font-size: 11px;
    border-radius: 15px;
    padding: 10px 20px;
    background: #7366ff;
    color: white;
    border: none;
    line-height: 12px;
  }
}
</style>