import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            users: [],
            pagination:{
                by:10,
                currentPage:1,
                totalPages:1,
            },
            showUser: [],
            socialAccounts: null,
            usersLevels: [],
            userLevelsLogs: [],
            usersLevelsPagination:{
                by:10,
                currentPage:1,
                totalPages:1,
            },
            userLevelsLogsPagination:{
                by:10,
                currentPage:1,
                totalPages:1,
            },
            userLoginLogs: [],
            userLoginLogsPagination:{
                by:10,
                currentPage:1,
                totalPages:1,
            },
        }
    },
    mutations: {
        [mutypes.SET_USERS]: (state, value) => state.users = value,
        [mutypes.SET_USERS_PAGINATION_TOTAL]: (state, value) => state.pagination.totalPages = value,
        [mutypes.SET_USERS_PAGINATION_CURRENT_PAGE]: (state, value) => state.pagination.currentPage = value,
        [mutypes.SET_USER_SHOW]: (state, value) => state.showUser = value,
        [mutypes.SET_USER_SOCIAL_ACCOUNTS_SHOW]: (state, value) => state.socialAccounts = value,
        [mutypes.SET_USERS_LEVELS]: (state, value) => state.usersLevels = value,
        [mutypes.SET_USERS_LEVELS_PAGINATION_TOTAL]: (state, value) => state.usersLevelsPagination.totalPages = value,
        [mutypes.SET_USERS_LEVELS_PAGINATION_CURRENT_PAGE]: (state, value) => state.usersLevelsPagination.currentPage = value,
        [mutypes.SET_USER_LEVELS_LOGS]: (state, value) => state.userLevelsLogs = value,
        [mutypes.SET_USER_LEVELS_LOGS_PAGINATION_TOTAL]: (state, value) => state.userLevelsLogsPagination.totalPages = value,
        [mutypes.SET_USER_LEVELS_LOGS_PAGINATION_CURRENT_PAGE]: (state, value) => state.userLevelsLogsPagination.currentPage = value,
        [mutypes.SET_USER_LOGIN_LOGS]: (state, value) => state.userLoginLogs = value,
        [mutypes.SET_USER_LOGIN_LOGS_PAGINATION_TOTAL]: (state, value) => state.userLoginLogsPagination.totalPages = value,
        [mutypes.SET_USER_LOGIN_LOGS_PAGINATION_CURRENT_PAGE]: (state, value) => state.userLoginLogsPagination.currentPage = value,
    },

    getters: {
        getUsers: state => state.users,
        getUsersPaginationTotal: state => state.pagination.totalPages,
        getUsersPaginationCurrentPage: state => state.pagination.currentPage,
        getUserShow: state => state.showUser,
        getUserSocialAccountsShow: state => state.socialAccounts,
        getUsersLevels: state => state.usersLevels,
        getUsersLevelsPaginationTotal: state => state.usersLevelsPagination.totalPages,
        getUsersLevelsPaginationCurrentPage: state => state.usersLevelsPagination.currentPage,
        getUserLevelsLogs: state => state.userLevelsLogs,
        getUserLevelsLogsPaginationTotal: state => state.userLevelsLogsPagination.totalPages,
        getUserLevelsLogsPaginationCurrentPage: state => state.userLevelsLogsPagination.currentPage,
        getUserLoginLogs: state => state.userLoginLogs,
        getUserLoginLogsPaginationTotal: state => state.userLoginLogsPagination.totalPages,
        getUserLoginLogsPaginationCurrentPage: state => state.userLoginLogsPagination.currentPage,
    },

    actions: {
        grabUsers: async ({commit, getters}, params) => {
            const response = await api.users.index({
                page: params.pageNumber,
                "search": params.search
            });
            commit(mutypes.SET_USERS, response.data.users);
            commit(mutypes.SET_USERS_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_USERS_PAGINATION_CURRENT_PAGE, params.pageNumber);

            return response.status;
        },
        async showUser({commit, getters}, id) {
            let response = await api.users.show(id)
            commit(mutypes.SET_USER_SHOW, response.data.user)
            commit(mutypes.SET_USER_SOCIAL_ACCOUNTS_SHOW, response.data.user.verified_social_accounts_count ? response.data.user.verified_social_accounts_count : '')

        },
        deleteUser: async ({commit, getters}, id) => {
           await api.users.delete(id)
            let users = getters.users.filter(t => t.id !== id)
            await commit(mutypes.SET_USERS, users);
        },
        async updateUserFields({commit,getters}, params){
            let usersData = {
                game_name: params.game_name,
                game_profile_id:params.game_profile_id,
                user_id: params.user_id,
                status:params.status}
            let fieldsData = {
                fields: {
                    nick: params.nick,
                    ...(params.game_name.includes('pubg_mobile') && {pubg_id: params.pubg_id}),
                    ...(params.game_name.includes('mlbb') && {mlbb_id: params.mlbb_id})
                }
            }
           await api.users.updateUserFields({
              ...usersData,
              ...fieldsData
           })
               .then((res) => {
                   this._vm.$toast("Вы успешно изменили ник и игровой id");
               })
                .catch((err) => {
                    if (err.response.status === 422){
                        this._vm.$toast.error('Данный ник или ID уже существуют');
                    } else {
                        this._vm.$toast.error("Не удалось изменить ник и игровой id");
                    }
                });
        },
        async overwriteFields({commit,getters}, params){
        return await api.users.overwriteFields(params.userId)
                .then((res) => {
                    this._vm.$toast("Вы успешно затерли данные");
                })
                .catch((res) => {
                    this._vm.$toast.error("Не удалось затереть данные");
                });
        },
        async changeStatus({commit,getters}, params){
          await api.users.changeStatus(params.userId)
                .then((res) => {
                    this._vm.$toast("Статус активирован");
                })
                .catch((res) => {
                    this._vm.$toast.error("Не удалось активировать статус");
                });
        },
        grabUsersLevels: async ({commit, getters}, params) => {
            const response = await api.users.usersLevelsList({
                page: params.pageNumber,
                "search":params.search,
            });
            commit(mutypes.SET_USERS_LEVELS, response.data.users || []);
            commit(mutypes.SET_USERS_LEVELS_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_USERS_LEVELS_PAGINATION_CURRENT_PAGE, params.pageNumber);

            return response.status;
        },
        grabUserLevelsLogs: async ({commit, getters}, params) => {
            const response = await api.users.userLevelsLogs({
                gameProfileId: params.gameProfileId,
                page: params.pageNumber,
            });
            commit(mutypes.SET_USER_LEVELS_LOGS, response.data.level_histories || []);
            commit(mutypes.SET_USER_LEVELS_LOGS_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_USER_LEVELS_LOGS_PAGINATION_CURRENT_PAGE, params.pageNumber);

            return response.status;
        },
        async grabUserLoginLogs({commit, getters}, params) {
            let response = await api.users.showLoginLogs({
                userId: params.userId,
                page: params.pageNumber,
            })
            commit(mutypes.SET_USER_LOGIN_LOGS, response.data.user_login_logs)
            commit(mutypes.SET_USER_LOGIN_LOGS_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_USER_LOGIN_LOGS_PAGINATION_CURRENT_PAGE, params.pageNumber);
        },
    }
}