<template>
  <div class="ban-team-wrapper">
    <p class="">{{ teamsShow.name }}</p>
    <p class="">Язык команды - {{params.lang}}</p>
    <div>
      <label for="">Дисциплина</label>
      <b-form-select :options="banGamesByTeam" v-model="gameIdForBan"></b-form-select>
    </div>
    <div>
      <label for="">Дата начала бана</label>
      <datetime format="YYYY-MM-DD H:i" v-model="startTimeToBanTeam"></datetime>
    </div>
    <div>
      <label for="">Дата окончания бана</label>
      <datetime format="YYYY-MM-DD H:i" v-model="endTimeToBanTeam"></datetime>
    </div>
    <div class="mt-3">
      <label for="">Причина бана</label>
      <b-form-textarea v-model="descriptionForBan"></b-form-textarea>
    </div>
    <div class="mt-3">
      <label for="">Участники команды</label>
      <multiselect v-model="membersIds" :options="teamMembers" :multiple="true" track-by="value"
                   label="text" class=""></multiselect>
    </div>
    <b-alert v-if="errorText" show variant="danger" class="mt-3">{{ errorText }}</b-alert>
    <footer>
      <b-button @click="submitForm" :disabled="isLoadingSaveForm" variant="primary">
        {{ isLoadingSaveForm ? 'Сохраняем...' : 'Сохранить' }}
      </b-button>
    </footer>
  </div>
</template>

<script>
import datetime from "vuejs-datetimepicker";
import {LanguageAdapterMixin} from "@/mixins/Language/LanguageAdapterMixin";
import Multiselect from "vue-multiselect";
import {TeamsAdapterMixin} from "@/mixins/Teams/TeamsAdapterMixin";
import {TeamBanAdapterMixin} from "@/mixins/TeamBan/TeamBanAdapterMixin";
import api from "@/api/api";

export default {
  name: "BanTeam",
  components: {Multiselect, datetime},
  mixins: [TeamsAdapterMixin, LanguageAdapterMixin, TeamBanAdapterMixin],
  props: {
    params: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isLoadingSaveForm: false,
      errorText: null,
      teamMembers: [],
      startTimeToBanTeam: null,
      endTimeToBanTeam: null,
      descriptionForBan: null,
      membersIds: [],
      gameIdForBan: null
    }
  },
  methods: {
    async submitForm() {
      try {
        this.isLoadingSaveForm = true
        this.errorText = null
        const userIds = this.membersIds.map((item) => item.value)
        await api.teamBan.store({
          game_id: this.gameIdForBan,
          from: this.startTimeToBanTeam,
          to: this.endTimeToBanTeam,
          description: this.descriptionForBan,
          model_id: this.params.id,
          user_ids: userIds
        })
        await this.$store.dispatch('indexTeams', {pageNumber: localStorage.getItem('pageNumberTeamList')})
        await this.$emit('closeModal')
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSaveForm = false
      }
    }
  },
  mounted() {
    (async () => {
      await Promise.all([
        this.showTeams(this.params.id),
        this.grabTeamGames(),
      ]).then(() => this.teamMembers = this.teamsShow.members.map((user) => ({value: user.id, text: user.username || 'не указано'})))
    })()
  },
}
</script>


<style scoped lang="scss">
.ban-team-wrapper {

  footer {
    border-top: 1px solid #dee2e6;
    padding-top: 15px;
    margin-top: 15px;
    text-align: right;
  }
}
</style>