<template>
  <div v-if="isLoadingTournament" class="loading-tournament-wrapper">
    <b-spinner class="spinner"></b-spinner>
  </div>
  <component v-else-if="templateComponent && !isLoadingTournament" :is="templateComponent" />
</template>

<script>
import { mapState } from "vuex";

import NEWPUBGTemplate from '@/pages/Tournament/StandingsTournament/Games/PUBG/new.vue'
import PUBGTemplate from "@/pages/Tournament/StandingsTournament/Games/PUBG/index.vue";
import MLBBTemplate from "@/pages/Tournament/StandingsTournament/Games/MLBB/index.vue";

export default {
  name: 'StandingsTournament',
  data() {
    return {
      isLoadingTournament: false
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['tournament']),
    templateComponent() {
      if (this.tournament) {
        if (Object.keys(this.tournament.game).length) {
          if (this.tournament.game.name === 'MLBB') return MLBBTemplate
          if (this.$route.query?.old) return PUBGTemplate
          return NEWPUBGTemplate
        }
      }
      return false
    }
  },
  created() {
    (async () => {
      this.isLoadingTournament = true
      this.$store.commit('moduleNewTournament/resetState')
      await this.$store.dispatch('moduleNewTournament/getTournament', {
        id: this.$route.params.standingId
      })
      this.isLoadingTournament = false
    })()
  },
}
</script>

<style scoped lang="scss">
.loading-tournament-wrapper {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>