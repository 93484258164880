import * as mutypes from "@/store/mutation.types";
import api from "@/api/api";

export default {
    state: () => ({
        posts: [],
        currentPost: null,
        total: null,
        currentPage: 1,
        currentImage: null,
        currentPreview: null,
    }),
    mutations: {
        [mutypes.SET_POSTS]: (state, value) => state.posts = value,
        [mutypes.SET_CURRENT_POST]: (state, value) => state.currentPost = value,
        [mutypes.SET_POSTS_TOTAL]: (state, value) => state.total = value,
        [mutypes.SET_POSTS_CURRENT_PAGE]: (state, value) => state.currentPage = value,
        [mutypes.SET_POSTS_CURRENT_IMAGE]: (state, value) => state.currentImage = value,
        [mutypes.SET_POSTS_CURRENT_PREVIEW]: (state, value) => state.currentPreview = value,
    },
    getters: {
        getPosts: state => state.posts,
        getCurrentPost: state => state.currentPost,
        getTotalPosts: state => state.total,
        getCurrentPage: state => state.currentPage,
        getCurrentImage: state => state.currentImage,
        getCurrentPreview: state => state.currentPreview
    },
    actions: {
        async indexBlogPosts({commit}, pageNumber = 1) {
            let response = await api.blog.index({
                page: pageNumber
            })
            commit(mutypes.SET_POSTS, response.data.posts)
            commit(mutypes.SET_POSTS_TOTAL, response.data.pagination.total)
            commit(mutypes.SET_POSTS_CURRENT_PAGE, pageNumber)
        },
        async showBlogPosts({commit}, id) {
            let response = await api.blog.show(id)
            
            // Parse the description field if it's stored as a string
            if (response.data.posts && response.data.posts.description) {
                Object.keys(response.data.posts.description).forEach(lang => {
                    if (typeof response.data.posts.description[lang] === 'string') {
                        try {
                            response.data.posts.description[lang] = JSON.parse(response.data.posts.description[lang]);
                        } catch (e) {
                            console.error(`Failed to parse description for language ${lang}:`, e);
                            // If parsing fails, set it to an empty EditorJS data structure
                            response.data.posts.description[lang] = { blocks: [] };
                        }
                    }
                });
            }
            
            commit(mutypes.SET_CURRENT_POST, response.data.posts)
            commit(mutypes.SET_POSTS_CURRENT_IMAGE, response.data.posts?.logo?.original_url)
            commit(mutypes.SET_POSTS_CURRENT_PREVIEW, response.data.posts?.preview)
        },
        async updateBlogPosts({commit}, data) {
            let response = await api.blog.update(data.id, data.params)
            // Optionally, you can update the state here if needed
            // commit(mutypes.SET_CURRENT_POST, response.data.post)
        },
        async storeBlogPosts({commit}, formData) {
            let response = await api.blog.store(formData)
            // Optionally, you can update the state here if needed
            // commit(mutypes.SET_CURRENT_POST, response.data.post)
        },
        async deleteBlogPost({commit, getters}, id) {
            let response = await api.blog.delete(id)
            let blog = getters.getPosts.filter(t => t.id !== id)
            await commit(mutypes.SET_POSTS, blog)
        }
    }
}