import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/seo-page';

export default {
    getSeoList: ()=> adminHttp.get(END_POINT),

    showSeo: (id) => adminHttp.get(END_POINT + `/${id}`),

    postSeo: (params) => adminHttp.post(END_POINT, params),

    updateSeo: (id,params) => adminHttp.put(END_POINT + `/${id}`, params),

    deleteSeo: (id) => adminHttp.delete(END_POINT + `/${id}`)
}