<template>
    <div>
        <Breadcrumbs main="Организации" title="Создание организатора"/>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Создание организатора</h5>
                        </div>
                        <div class="card-body">
                            <b-card>
                                <div class="mt-3">
                                    <label class="font-weight-bold">Наименование</label>
                                    <ValidationProvider rules="min:2|required" v-slot="{errors}">
                                        <b-form-input v-model="name" placeholder="Наименование"></b-form-input>
                                        <div class="text-danger">{{ errors[0] }}</div>
                                    </ValidationProvider>
                                </div>
                                <div class="mt-3">
                                    <label class="font-weight-bold">Ссылка на сайт</label>
                                    <b-form-input v-model="link" placeholder="Ссылка на сайт"></b-form-input>
                                </div>

                                <div class="mt-3">
                                    <label class="font-weight-bold">Платформа</label>
                                    <ValidationProvider rules="required" v-slot="{errors}">
                                        <b-form-select :options="platforms" v-model="platform"></b-form-select>
                                        <div class="text-danger">{{errors[0]}}</div>
                                    </ValidationProvider>
                                </div>
                                <div class="mt-3">
                                    <label class="font-weight-bold">Рейтинг</label>
                                    <ValidationProvider rules="required" v-slot="{errors}">
                                        <b-form-input v-model="rating" placeholder="Рейтинг"></b-form-input>
                                        <div class="text-danger">{{ errors[0] }}</div>
                                    </ValidationProvider>
                                </div>
                                <b-btn @click.prevent="storeOrganizer({
                                    name: name,
                                    platform_id: platform,
                                    link: link,
                                    rating: rating
                                })" variant="primary" class="mt-4">Создать</b-btn>
                            </b-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {OrganizersAdapterMixin} from "@/mixins/Organizers/OrganizersAdapterMixin";
import {PlatformsAdapterMixin} from "@/mixins/Platforms/PlatformsAdapterMixin";

export default {
    name: "CreateOrganizer",
    mixins: [ModalAdapterMixin, OrganizersAdapterMixin, PlatformsAdapterMixin],
    data() {
        return {
            name: '',
            link: '',
            rating: '',
            platform: ''
        }
    },
    created() {
        this.grabPlatformsList();
    },
}
</script>

<style scoped>

</style>