import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const TournamentStageGroupAdapterMixin = {
    data() {
        return {
            newStageGroupData: null,
            newGroupName: '',
            newGroupSlot: '',
            newGroupData: null,
            newGroupStatus: null,
            newGroupStatusOptions: [
                {value: 0, text: 'not_started_status'},
                {value: 1, text: 'game_process_status'},
                {value: 2, text: 'ended_status'},
            ],
        }
    },
    computed: {
        ...mapGetters({
            getTournamentStageGroup         : 'getTournamentStageGroup',
            getTournamentStageGroupId       : 'getTournamentStageGroupId',
            getTournamentStageGroupSlot     : 'getTournamentStageGroupSlot',
            getTournamentStageGroupStatus   : 'getTournamentStageGroupStatus',
            getTournamentStageGroupNewName  : 'getTournamentStageGroupNewName',
            getTournamentStageGroupNewSlot  : 'getTournamentStageGroupNewSlot',
            getTournamentStageGroupGameTime : 'getTournamentStageGroupGameTime',
        }),
        stageGroup: {get() { return this.getTournamentStageGroup}, set(value) {this.setTournamentStageGroup(value)}},
        stageGroupId: {get() { return this.getTournamentStageGroupId}, set(value) {this.setTournamentStageGroupId(value)}},
        stageGroupSlot: {get() { return this.getTournamentStageGroupSlot}, set(value) {this.setTournamentStageGroupSlot(value)}},
        stageGroupStatus: {get() { return this.getTournamentStageGroupStatus}, set(value) {this.setTournamentStageGroupStatus(value)}},
        stageGroupNewName: {get() { return this.getTournamentStageGroupNewName}, set(value) {this.setTournamentStageGroupNewName(value)}},
        stageGroupNewSlot: {get() { return this.getTournamentStageGroupNewSlot}, set(value) {this.setTournamentStageGroupNewSlot(value)}},
        stageGroupGameTime: {get() { return this.getTournamentStageGroupGameTime}, set(value) {this.setTournamentStageGroupGameTime(value)}},
    },
    methods: {
        ...mapMutations({
            setTournamentStageGroup: mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP,
            setTournamentStageGroupId: mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP_ID,
            setTournamentStageGroupSlot: mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP_SLOT_COUNT,
            setTournamentStageGroupStatus: mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP_STATUS,
            setTournamentStageGroupNewName: mutypes.SET_TOURNAMENT_STAGES_GROUP_NEW_NAME,
            setTournamentStageGroupNewSlot: mutypes.SET_TOURNAMENT_STAGES_GROUP_NEW_SLOT,
            setTournamentStageGroupGameTime: mutypes.SET_TOURNAMENT_STAGES_GROUP_GAME_TIME,
        }),
        ...mapActions([
            'indexTournamentStageGroup',
            'storeTournamentStageGroup',
            'showTournamentStageGroup',
            'updateTournamentStageGroup'
        ]),
        async storeTournamentStageGroupPost() {
            await this.storeTournamentStageGroup({
                stage_id: this.$route.params.stage_id,
                name: this.newGroupName,
                slot_count: +this.newGroupSlot,
                game_time: this.newGroupData,
                status: this.newGroupStatus,
            }).then(()=> this.$router.go(-1))
        },
        async updateTournamentStageGroupPut(){

            await this.updateTournamentStageGroup(this.$route.params.group_id).
            then(()=> this.$router.go(-1))
        }
    },
}
