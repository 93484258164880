import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            organizerUsers: [],
            permissions: [],
            organizerRoles: null,
            currentOrganizerUser: null,
            pagination:{
                by:10,
                currentPage:1,
                totalPages:1,
            },
        }
    },
    mutations: {
        [mutypes.SET_ORGANIZER_USERS]: (state, value) => state.organizerUsers = value,
        [mutypes.SET_ORGANIZER_PERMISSIONS]: (state, value) => state.permissions = value,
        [mutypes.SET_ORGANIZER_ROLES]: (state, value) => state.organizerRoles = value,
        [mutypes.SET_ORGANIZER_CURRENT_USER]: (state, value) => state.currentOrganizerUser = value,
        [mutypes.SET_ORGANIZER_PAGINATION_TOTAL]: (state, value) => state.pagination.totalPages = value,
        [mutypes.SET_ORGANIZER_PAGINATION_CURRENT_PAGE]: (state, value) => state.pagination.currentPage = value,
    },

    getters: {
        getOrganizerUsers: state => state.organizerUsers,
        getPermissionsUsers: state => state.permissions,
        getOrganizerRoles: state => state.organizerRoles,
        getCurrentOrganizerUser: state => state.currentOrganizerUser,
        getOrganizerPaginationTotal: state => state.pagination.totalPages,
        getOrganizerCurrentPage: state => state.pagination.currentPage
    },

    actions: {
        grabOrganizerUsers: async ({commit, getters}, params) => {
            // const response = await api.organizerUsers.index({
            //     pageNumber: params.pageNumber || 1,
            //     organizerId: params.organizerId
            // });
            const response = await api.organizerUsers.index({
                page: params.page,
                onlyModerators:true,
                search:params.search
            });
            commit(mutypes.SET_ORGANIZER_USERS, response.data.users)
            commit(mutypes.SET_ORGANIZER_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_ORGANIZER_PAGINATION_CURRENT_PAGE, params.page);
            return response.status;
        },
        grabFilteredUser: async ({commit, getters}, params) => {
            const response = await api.organizerUsers.indexFiltered(params)
            commit(mutypes.SET_ORGANIZER_USERS, response.data.users);
            return response
        },
        storeOrganizerUsers: async ({commit, getters}, params) => {
            const response = await api.organizerUsers.store(params);
        },
        getPermissions: async ({commit, getters}) => {
            const response = await api.organizerUsers.getPermissions()
            commit(mutypes.SET_ORGANIZER_PERMISSIONS, response.data.permissions)
        },
        grabRoles: async ({commit, getters}) => {
            const response = await api.roles.indexRoles()
            let values = response.data.roles
            values.unshift({name: '----', id: []})
            commit(mutypes.SET_ORGANIZER_ROLES, values)
            return response
        },
        showUser: async ({commit, getters}, id) => {
            const response = await api.organizerUsers.show(id)
            commit(mutypes.SET_ORGANIZER_CURRENT_USER, response.data.user)
            return response
        },
        updateUser: async ({commit, getters}, params) => {
            const response = await api.organizerUsers.updateUser(params.id, {
                job: params.job,
                game: params.game,
                stamp: params.stamp,
                roles: params.roles
            })
            return response
        }
    }
}