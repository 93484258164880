import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";
import {providersList} from "@/constants/user";
import moment from 'moment'
import api from '/src/api/api';

export const UsersAdapterMixin = {
    data() {
        return {
            gameProfileNick: '',
            gameProfileId: '',
            gameName: ''
        }
    },
    computed: {
        ...mapGetters({
            getUsers: 'getUsers',
            getPaginationTotal: 'getUsersPaginationTotal',
            getCurrentPage: 'getUsersPaginationCurrentPage',
            getUserShow: 'getUserShow',
            getUserSocialAccountsShow: 'getUserSocialAccountsShow',
            getUsersLevels: 'getUsersLevels',
            getUsersLevelsPaginationTotal: 'getUsersLevelsPaginationTotal',
            getUsersLevelsCurrentPage: 'getUsersLevelsPaginationCurrentPage',
            getUserLevelsLogs: 'getUserLevelsLogs',
            getUserLevelsLogsPaginationTotal: 'getUserLevelsLogsPaginationTotal',
            getUserLevelsLogsCurrentPage: 'getUserLevelsLogsPaginationCurrentPage',
            getUserLoginLogs: 'getUserLoginLogs',
            getUserLoginLogsPaginationTotal: 'getUserLoginLogsPaginationTotal',
            getUserLoginLogsCurrentPage: 'getUserLoginLogsPaginationCurrentPage',
        }),
        users: {
            get() {
                return this.getUsers
            }, set(value) {
                this.setUsers(value)
            }
        },
        totalUserPagination: {
            get() {
                return this.getPaginationTotal
            }, set(value) {
                this.setPaginationTotal(value)
            }
        },
        currentPage: {
            get() {
                return this.getCurrentPage
            }, set(value) {
                this.setCurrentPage(value)
            }
        },
        userShow: {
            get() {
                return this.getUserShow
            }, set(value) {
                this.setUserShow(value)
            }
        },
        userSocialAccountsShow: {
            get() {
                return this.getUserSocialAccountsShow
            }, set(value) {
                this.setUserSocialAccountsShow(value)
            }
        },
        usersLevels: {
            get() {
                return this.getUsersLevels
            }, set(value) {
                this.setUsersLevels(value)
            }
        },
        totalUsersLevelsPagination: {
            get() {
                return this.getUsersLevelsPaginationTotal
            }, set(value) {
                this.setUsersLevelsPaginationTotal(value)
            }
        },
        usersLevelsCurrentPage: {
            get() {
                return this.getUsersLevelsCurrentPage
            }, set(value) {
                this.setUsersLevelsCurrentPage(value)
            }
        },
        userLevelsLogs: {
            get() {
                return this.getUserLevelsLogs
            }, set(value) {
                this.setUserLevelsLogs(value)
            }
        },
        totalUserLevelsLogsPagination: {
            get() {
                return this.getUserLevelsLogsPaginationTotal
            }, set(value) {
                this.setUserLevelsLogsPaginationTotal(value)
            }
        },
        userLevelsLogsCurrentPage: {
            get() {
                return this.getUserLevelsLogsCurrentPage
            }, set(value) {
                this.setUserLevelsLogsCurrentPage(value)
            }
        },
        userLoginLogs: {
            get() {
                return this.getUserLoginLogs
            }, set(value) {
                this.setUserLoginLogs(value)
            }
        },
        userLoginLogsTotalPagination: {
            get() {
                return this.getUserLoginLogsPaginationTotal
            }, set(value) {
                this.setUserLoginLogsPaginationTotal(value)
            }
        },
        userLoginLogsCurrentPage: {
            get() {
                return this.getUserLoginLogsPaginationCurrentPage
            }, set(value) {
                this.setUserLoginLogsPaginationCurrentPage(value)
            }
        },
        socialAccounts() {
            const socialAccounts = this.userShow.socialAccounts

            return providersList.reduce((state, {name: provideName, link}) => {
                const account = socialAccounts.find((acc) => acc.provider === provideName)
                if (account) {
                    state.push({
                        name: account.provider,
                        id: account.id,
                        isPrimary: !!account.primary,
                        ...(link && {
                            ...(provideName === 'email' && {
                                link: `${link}${account.data.email}`
                            }),
                            ...(['google', 'discord'].includes(provideName) && {
                                link: `${link}${account['provider_id']}`
                            }),
                            ...(provideName === 'telegram' && {
                                link: `${link}${account.data.username}`
                            })
                        }),
                        ...(!['phone', 'whatsapp'].includes(provideName) && {
                            nickname: account?.data?.nickname,
                        }),
                        ...(['phone', 'whatsapp'].includes(provideName) && {
                            phone: account?.data?.phone
                        }),
                        ...(['email', 'google', 'discord'].includes(provideName) && {
                            email: account?.data?.email || (typeof account?.data === 'string' && account?.data)
                        }),
                        ...(['phone', 'whatsapp'].includes(provideName) && {
                            country: this.userShow?.country?.name
                        }),
                        ...(account['verified_at'] && {
                            verifiedDate: moment(account['verified_at']).format('DD.MM.YYYY')
                        })
                    })
                }
                return state
            }, [])
        }
    },
    methods: {
        ...mapMutations({
            setUsers: mutypes.SET_USERS,
            setPaginationTotal: mutypes.SET_USERS_PAGINATION_TOTAL,
            setCurrentPage: mutypes.SET_USERS_PAGINATION_CURRENT_PAGE,
            setUserShow: mutypes.SET_USER_SHOW,
            setUserSocialAccountsShow: mutypes.SET_USER_SOCIAL_ACCOUNTS_SHOW,
            setUsersLevels: mutypes.SET_USERS_LEVELS,
            setUsersLevelsPaginationTotal: mutypes.SET_USERS_LEVELS_PAGINATION_TOTAL,
            setUsersLevelsCurrentPage: mutypes.SET_USERS_LEVELS_PAGINATION_CURRENT_PAGE,
            setUserLevelsLogs: mutypes.SET_USER_LEVELS_LOGS,
            setUserLevelsLogsPaginationTotal: mutypes.SET_USER_LEVELS_LOGS_PAGINATION_TOTAL,
            setUserLevelsLogsCurrentPage: mutypes.SET_USER_LEVELS_LOGS_PAGINATION_CURRENT_PAGE,
            setUserLoginLogs: mutypes.SET_USER_LOGIN_LOGS,
            setUserLoginLogsPaginationTotal: mutypes.SET_USER_LOGIN_LOGS_PAGINATION_TOTAL,
            setUserLoginLogsPaginationCurrentPage: mutypes.SET_USER_LOGIN_LOGS_PAGINATION_CURRENT_PAGE
        }),
        ...mapActions([
            'grabUsers',
            'deleteUser',
            'showUser',
            'updateUserFields',
            'overwriteFields',
            'changeStatus',
            'grabUsersLevels',
            'grabUserLevelsLogs',
            'grabUserLoginLogs'
        ]),
        async showUserInfo(id) {
            await this.$router.push({name: 'user_info', params: {id: id}})
        },
        async unlinkSocialAccount(providerId, userId) {
            await api.socialAccount.detach({
                id: providerId,
                userId,
            })
            await this.showUser(this.$route.params.id)
        },
    }
}
