import adminHttp from "@/api/adminHttp";

const END_POINT = '/api/admin/rating';
const NEW_END_POINT = '/api/v1/admin';

export default {
    index: (params) => adminHttp.get(END_POINT + `/1`, {params: params}),

    show: (id, params) => adminHttp.get(END_POINT + '/show' + `/${id}`, {params: params}),

    update: (id, params) => adminHttp.put(END_POINT + `/${id}`, params),

    actualize: (id) => adminHttp.get(END_POINT + `/actualize/${id}`),

    short: (id)=> adminHttp.get(END_POINT + `/short/${id}`),

    store: (params)=> adminHttp.post(END_POINT, params),

    actualizedTime: () => adminHttp.get(NEW_END_POINT + `/rating-actualize-datetime`),
}