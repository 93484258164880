<template>
  <div>
    <b-button variant="primary" class="trash-action-button d-none d-sm-block"  @click="isShowDeleteTeamModal = true">Удалить</b-button>
    <Modals v-model="isShowDeleteTeamModal" name="BasicDeleteTeamTournamentMlbb" title="Удаление команды" :params="{ groupTeam }" />
  </div>
</template>

<script>
import Modals from "@/components/modals/index.vue";

export default {
  name: "DeleteTeam",
  components: {Modals},
  props: {
    groupTeam: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isShowDeleteTeamModal: false,
    }
  }
}
</script>

<style scoped lang="scss">
.trash-action-button {
  border-radius: 15px;
  font-size: 11px;
  padding: 5px 18px;
  color: #ff5e5e;
  border-color: #ff5e5e !important;
  margin-right: 10px;
  background-color: white !important;


  @media screen and (max-width: 800px) {
    padding: 10px 15px;
  }

  &:hover, &:active, &:focus {
    color: #fff !important;
    background-color: #ff5e5e !important;
    border-color: #ff5e5e !important;
  }
}
</style>