<template>
  <div>
    <Breadcrumbs main="Live" title="Live"/>
    <!-- Container-fluid starts-->
    <b-modal centered id="modal-EntryDelete" title="Предупреждение" v-model="showDelete">
      <p class="my-4">Вы действительно хотите удалить матч?</p>
      <template #modal-footer>
        <b-btn variant="primary" class="mr-3" @click="deleteMatchModal">Подтвердить</b-btn>
        <b-btn variant="danger" @click="showDelete=false">Отмена</b-btn>
      </template>
    </b-modal>
    <Modal name="AddMatchForBook" title="Добавить матч" v-model="showCreateMatchModal"/>
    <Modal name="EditMatchForBook" title="Редактировать матч" v-model="showEditMatchModal"/>
    <div v-if="currentMatchesBook.length > 0" class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-md-end justify-content-center">
                <b-btn variant="primary" @click="showCreateMatchModal = true">Добавить матч</b-btn>
              </div>
              <div class="mt-2 mb-2">
                <b-tabs
                        nav-class="d-flex justify-content-md-start justify-content-center flex-wrap">
                  <b-tab @click="selectMatch(match)" v-model="selectedMatch"
                         v-for="match in currentMatchesBook"
                         :title="match.name" nav-class="mr-2 mb-2"
                         :key="'matches' + match.id"
                  >
                    <template #title>
                      <div class="d-flex flex-wrap align-items-center">
                        <div>{{ match.name }}</div>
                        <b-btn @click="editMatch(match.id)"
                               variant="outline-primary"
                               class="p-1 px-1 ml-2"
                               style="border: none;"><i class="fa fa-edit mt-1 ml-2"></i>
                        </b-btn>
                        <b-btn @click="deleteMatches(match.id)"
                               variant="outline-primary"
                               class="p-1 px-1"
                               style="border: none;"
                        ><i class="fa fa-trash ml-2" style="font-size: 16px"></i>
                        </b-btn>
                      </div>
                    </template>
                    <div class="d-flex justify-content-md-start justify-content-center">
                      <div>
                        <div class="d-flex justify-content-md-start justify-content-center mt-4">
                          <button v-b-toggle.primary_icon_open_close_1 class="btn btn-light mr-2">
                            Данные матча
                            <i class="fa fa-chevron-down ml-2"></i>
                          </button>
                          <button v-b-toggle.death_life_counter
                                  @click.prevent="showMembersTournamentCount()"
                                  class="btn btn-light">
                            Счетчик
                            <i class="fa fa-chevron-down ml-2"></i>
                          </button>
                        </div>
                        <b-collapse id="death_life_counter" accordion="my-accordion-icon-open-close"
                                    role="tabpanel"
                                    class="mb-4 mt-4">
                          <div class="justify-content-start">
                            <div>Живых команд - <span class="text-success">{{ liveTeamsCount }}</span></div>
                            <div>Живых игроков - <span class="text-success">{{ liveMembersCount }}</span></div>
                          </div>
                        </b-collapse>

                        <b-collapse id="primary_icon_open_close_1" accordion="my-accordion-icon-open-close"
                                    role="tabpanel"
                                    class="mb-4 mt-4">
                          <div class="d-flex justify-content-start">
                            <div>
                              <div><span class="font-weight-bold">Турнир:</span> {{ tournamentName }}</div>
                              <div><span class="font-weight-bold">Этап:</span> {{ currentStage?.name }}</div>
                              <div><span class="font-weight-bold">Группа:</span> {{ stageGroup.name }}</div>
                              <div class="mb-3"><span class="font-weight-bold">Матч:</span> Матч {{ match.id }}</div>
                              <h5 class="mb-4">
                                Настройки матча
                              </h5>
                              <div class="border p-4">
                                <div><span class="font-weight-bold">Дата матча:</span> {{ match.start_time }}</div>
                                <div class="d-flex flex-wrap rounded-lg mt-2 p-1"
                                     style="background-color: #d6d8db">
                                  <div><span class="font-weight-bold">Лобби:</span> {{ match.lobby_number }}</div>
                                  <div><span class="font-weight-bold ml-2">Пароль:</span> {{ match.password }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-collapse>
                      </div>
                    </div>

                    <div class="d-flex justify-content-md-start justify-content-center">
                      <div class="mt-4 p-2 rounded-lg"
                           :style="{'background-color': getMatchStatus(showEventData?.type).style}">
                        <span class="font-weight-bold">Текущий статус матча:</span>
                        {{ getMatchStatus(showEventData?.type).name || 'отсутствует' }}
                      </div>
                    </div>
                    <div class="mt-4 mb-5">
                      <b-tabs nav-class="justify-content-start text-center flex-wrap"
                              v-model="activeTab" @input="handleTabChange"
                      >
                        <b-tab
                            v-if="selectedCurrentMatchIdBook"
                            title="Настройки" nav-class="mr-2 mb-2">
                          <SettingsLive
                          />
                        </b-tab>

                        <b-tab :disabled="showEventData?.type === 'not_sended' || showEventData?.type === 'not_started'"
                               @click="getUniqueHashForRenderTeams"
                               v-for="(host,key) in uniqueHash"
                               nav-class="mr-2 mb-2" :key="key + 1 + '_host'">
                          <template #title>
                            <div>Хост {{ key + 1 }}</div>
                          </template>
                          <div class="d-flex justify-content-start">
                            <HostsLive :host="host"/>
                          </div>
                        </b-tab>
                      </b-tabs>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="currentMatchesBook.length == 0" class="d-flex justify-content-center p-4">
      <b-btn @click="showCreateMatchModal = true" variant="primary">Добавить матч</b-btn>
    </div>
    <div v-if="isLoadingEvent" class="loading-wrapper">
      <b-spinner class="spinner"></b-spinner>
    </div>

  </div>
</template>

<script>

import SettingsLive from "@/pages/Live/SettingsLive.vue";
import HostsLive from "@/pages/Live/HostsLive.vue";
import {TournamentStandingsAdapterMixin} from "@/mixins/Tournament/TournamentStandingsAdapterMixin";
import {TournamentStageGroupAdapterMixin} from "@/mixins/Tournament/TournamentStageGroupAdapterMixin";
import {BookAdapterMixin} from "@/mixins/Book/BookAdapterMixin";
import Modal from '@/components/modals/index.vue'
import {MatchStatuses} from '@/constants/Live/MatchStatuses';
import {StatusesBackground} from '@/constants/Live/MatchStatuses';

export default {
  name: "LivePage",
  components: {HostsLive, SettingsLive, Modal},
  mixins: [TournamentStandingsAdapterMixin, TournamentStageGroupAdapterMixin, BookAdapterMixin],
  data() {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
      activeTab: 1,
      showCreateMatchModal: false,
      showEditMatchModal: false,
      showDelete: false,
      matchId: null,
      isLoadingEvent: false,
      activeSettings:false,
    };
  },
  async created() {
    await Promise.all([
          this.grabStandingTournament(this.$route.params.standingId),
          this.showTournamentStage(this.$route.params.stage_id),
          this.showTournamentStageGroup(this.$route.params.group_id),
          this.showGroupMatchesBook(this.$route.params.group_id),
        ]
    )
    if (this.currentMatchesBook.length > 0) {
      await this.showEventResult(this.selectedCurrentMatchIdBook)
      this.getUniqueHashForRenderTeams()
    }
  },

  methods: {
    async selectMatch(match) {
      this.isLoadingEvent = true;
      await this.showSelectedMatch(match)
      await this.showEventResult(match.id)
      this.isLoadingEvent = false;
    },
    forceRerenderSettingsLive() {
      const oldValue = this.selectedCurrentMatchIdBook;
      this.selectedCurrentMatchIdBook = null;
      this.$nextTick(() => {
        this.selectedCurrentMatchIdBook = oldValue;
      });
      this.activeSettings = true;
    },

    editMatch(id) {
      this.showEditMatchModal = true
    },
    deleteMatchModal() {
      this.deleteMatch({matchId: this.matchId})
          .then(() => this.showDelete = false)
    },
    async deleteMatches(id) {
      this.matchId = id
      this.showDelete = true
    },
    getMatchStatus(status) {
      return {
        name: `${MatchStatuses[status]}`,
        style: `${StatusesBackground[status]}`,
      }
    },
    handleTabChange() {
      if (this.activeTab === 0) {
        this.$nextTick(() => {
          this.isLoadingEvent = true;
          this.showEventResult(this.selectedMatch).then(() => {
            this.isLoadingEvent = false;
          })
        });
      }
    },

    async getNewEventData() {
      this.isLoadingEvent = true;

      this.showEventResult(this.selectedCurrentMatchIdBook).then(() => {
        this.isLoadingEvent = false;
      })
    },

  },

};
</script>