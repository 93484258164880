import adminHttp from "@/api/adminHttp";

const END_POINT = '/api/admin/user';
const END_POINT_UPDATE = '/api/admin/game_profile';
const END_POINT_CHANGE_STATUS = '/api/admin/social_account/change-status';
const NEW_END_POINT = '/api/v1/admin/user';

export default {
    index: (params) => adminHttp.get(END_POINT, {params: params}),

    show: (id) => adminHttp.get(END_POINT + `/${id}`),

    store: (params) => adminHttp.post(END_POINT + `/store`, params),

    update: (id, params) => adminHttp.put(END_POINT + `${id}`, params),

    delete: (id) => adminHttp.delete(END_POINT + `/${id}`),
    /**
     * PUT: update user fields
     * @param params {
     *           fields:object|required
     * }
     * @returns {Promise<AxiosResponse<any>>}

     */
    updateUserFields: (id, params = {}) => adminHttp.put(END_POINT_UPDATE + `/${id}`, params),

    overwriteFields: (userId) => adminHttp.put(END_POINT_UPDATE + `/overwrite` + `/${userId}`),

    changeStatus: (userId) => adminHttp.put(END_POINT_CHANGE_STATUS + `/${userId}`),

    usersLevelsList: (params) => adminHttp.get(END_POINT + '/level', {params: params}),

    userLevelsLogs: (params) => adminHttp.get(END_POINT + '/level/logs' + `/${params.gameProfileId}`, {params: params}),

    createGameProfile: (params) => adminHttp.post('/api/v1/admin/game_profile', params),

    showLoginLogs: (params) => adminHttp.get(NEW_END_POINT + `/login-logs/${params.userId}`, {params: params}),

    userLogs: (params) => adminHttp.get(NEW_END_POINT + `/logs/${params.userId}`, {params: params}),

    userTransferLogs: (params) => adminHttp.get(NEW_END_POINT + `/transfer-logs`, {params: params})
}