<template>
  <div class="add-round-tournament-wrapper">
    <b-form @submit="submitForm">
      <b-form-group label="Название раунда" label-for="round_name">
        <b-form-input v-model="formModel.name" id="round_name" type="text" required />
      </b-form-group>
      <b-form-group label="Описание раунда" label-for="round_description">
        <b-form-textarea v-model="formModel.description" id="round_description" />
      </b-form-group>
      <b-form-group label="Тип сетки" label-for="round_type">
        <b-form-select v-model="formModel.type" id="round_type" :options="typeRounds"/>
      </b-form-group>
      <b-alert v-if="errorText" show variant="danger">{{ errorText }}</b-alert>
      <div class="form-footer">
        <b-button type="submit" variant="primary">
          Создать раунд
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { typeRounds } from "@/common/tournamentCommon";
import { mapState } from "vuex";

export default {
  name: "AddRoundTournament",
  data() {
    return {
      errorText: '',
      isLoadingSaveForm: false,
      formModel: {
        name: '',
        description: '',
        type: 1
      },
      typeRounds: typeRounds.map(({ type, title }) => ({ text: title, value: type }))
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['selectedStageId'])
  },
  methods: {
    async submitForm(event) {
      event.preventDefault()
      try {
        this.isLoadingSaveForm = true
        await this.$store.dispatch('moduleNewTournament/createRound', {
          stageId: this.selectedStageId,
          name: this.formModel.name,
          type: this.formModel.type,
          ...(this.formModel.description && { description: this.formModel.description })
        })
        await this.$store.dispatch('moduleNewTournament/getTournament', {
          id: this.$route.params.standingId
        })
        await this.$emit('submit')
        await this.$emit('closeModal')
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSaveForm = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.add-round-tournament-wrapper {
  .form-footer {
    border-top: 1px solid #dee2e6;
    text-align: right;
    padding-top: 15px;
  }
}
</style>