import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const TournamentsRatingAdapterMixin = {
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            getTournamentTeamsRating: 'getTournamentTeamsRating',
            getTournamentTeamsRatingPaginationTotal: 'getTournamentTeamsRatingPaginationTotal',
            getTournamentTeamsRatingCurrentPage: 'getTournamentTeamsRatingPaginationCurrentPage',
            getTournamentTeamShowRating: 'getTournamentTeamShowRating',
        }),
        tournamentTeamsRating: {
            get() {
                return this.getTournamentTeamsRating
            }, set(value) {
                this.setTournamentTeamsRating(value)
            }
        },
        tournamentTeamsRatingTotal: {
            get() {
                return this.getTournamentTeamsRatingPaginationTotal
            }, set(value) {
                this.setTournamentTeamsRatingPaginationTotal(value)
            }
        },
        tournamentTeamsRatingCurrentPage: {
            get() {
                return this.getTournamentTeamsRatingCurrentPage
            }, set(value) {
                this.setTournamentTeamsRatingCurrentPage(value)
            }
        },
        tournamentTeamShowRating: {
            get() {
                return this.getTournamentTeamShowRating
            }, set(value) {
                this.setTournamentTeamShowRating(value)
            }
        },
    },
    methods: {
        ...mapMutations({
            setTournamentTeamsRating: mutypes.SET_TOURNAMENT_TEAMS_RATING,
            setTournamentTeamsRatingPaginationTotal: mutypes.SET_TOURNAMENT_TEAMS_RATING_PAGINATION_TOTAL,
            setTournamentTeamsRatingCurrentPage: mutypes.SET_TOURNAMENT_TEAMS_RATING_PAGINATION_CURRENT_PAGE,
            setTournamentTeamShowRating: mutypes.SET_TOURNAMENT_TEAM_SHOW_RATING,
        }),
        ...mapActions([
            'grabTournamentTeamsRating',
            'showTournamentTeamInfoRating'
        ]),
    }
}