<template>
  <div>
    <b-input
        v-model="inputValue"
        style="font-size: 16px; width: 100px"
        @change="event => changePlace(event)">
    </b-input>
  </div>
</template>

<script>
import { BookAdapterMixin } from "@/mixins/Book/BookAdapterMixin";

export default {
  name: "PlaceInput",
  mixins: [BookAdapterMixin],
  data() {
    return {
      inputValue: this.$props.defaultPlace.toString(),
      previousValue: this.$props.defaultPlace.toString()
    };
  },
  props: {
    defaultPlace: {
      type: Number,
      default: 0
    },
    teamId: {
      type: Number,
      default: 0
    },
    matchId: {
      type: Number,
      default: 0
    },
  },
  methods: {
    async changePlace(place) {
      try {
        await this.storePlace({
          teamId: this.teamId,
          place: Number.parseInt(place),
          type: 'live',
          matchId: this.$props.matchId
        });
      } catch (error) {
        this.inputValue = this.previousValue;
      }
    }
  },
}
</script>
