<template>
  <div>
    <Breadcrumbs main="Watches" title="Раздел Watch"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Баннер: Создание/Редактирование</h5>
            </div>
            <div class="card-body">
              <b-form @submit.prevent="saveMainBanner">
                <div class="form-group">
                  <label class="font-weight-bold">Ссылка на YouTube</label>
                  <b-form-input
                    v-model="currentWatch.youtube_link"
                    placeholder="https://youtu.be/WxBX4N79r4w"
                    :state="isYoutubeLinkValid"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!isYoutubeLinkValid">
                    Неверный URL YouTube
                  </b-form-invalid-feedback>
                </div>

                <b-tabs class="mt-3">
                  <b-tab v-for="lang in languages" :key="lang.id" :active="lang.language.toLowerCase() === 'ru'">
                    <template #title>
                      <img :src="lang.img" width="20" height="20" class="mr-2">
                      <span>{{ lang.language.toUpperCase() }}</span>
                    </template>
                    
                    <div class="mt-3">
                      <label class="font-weight-bold">Название</label>
                      <b-form-input v-model="currentWatch.title[lang.language.toLowerCase()]" :placeholder="`Название ${lang.language.toUpperCase()}`"></b-form-input>
                    </div>
                    
                    <div class="mt-3">
                      <label class="font-weight-bold">Meta Description {{ lang.language.toUpperCase() }}</label>
                      <b-form-input v-model="currentWatch.meta_description[lang.language.toLowerCase()]" :placeholder="`Описание ${lang.language.toUpperCase()}`"></b-form-input>
                    </div>
                    
                    <div class="mt-3">
                      <label class="font-weight-bold">Описание {{ lang.language.toUpperCase() }}</label>
                      <ckeditor 
                        :editor="editor" 
                        v-model="currentWatch.description[lang.language.toLowerCase()]" 
                        :config="editorConfig"
                      ></ckeditor>
                    </div>
                  </b-tab>
                </b-tabs>
                <b-button type="submit" variant="primary" class="mt-3" :disabled="!isFormValid">Сохранить</b-button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { LanguageAdapterMixin } from "@/mixins/Language/LanguageAdapterMixin";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue';

export default {
  name: "ListWatches",
  components: {
    ckeditor: CKEditor.component
  },
  mixins: [LanguageAdapterMixin],
  data() {
    return {
      currentWatch: {
        youtube_link: '',
        title: {
          ru: '',
          en: '',
          uz: '',
          kz: ''
        },
        meta_description: {
          ru: '',
          en: '',
          uz: '',
          kz: ''
        },
        description: {
          ru: '',
          en: '',
          uz: '',
          kz: ''
        },
        is_main_banner: true
      },
      editor: ClassicEditor,
      editorConfig: {
        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
        language: 'ru',
        height: '400px'
      }
    }
  },
  computed: {
    ...mapState('moduleWatch', ['mainBanner']),
    isYoutubeLinkValid() {
      const regex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
      return regex.test(this.currentWatch.youtube_link);
    },
    isFormValid() {
      return this.isYoutubeLinkValid;
    }
  },
  methods: {
    ...mapActions('moduleWatch', ['fetchWatches', 'setMainBanner', 'updateWatch']),
    async saveMainBanner() {
      if (!this.isFormValid) {
        this.$toast.error('Неверный URL YouTube', { position: 'top-right' });
        return;
      }

      try {
        if (this.mainBanner && this.mainBanner.id) {
          await this.updateWatch({ id: this.mainBanner.id, watchData: this.currentWatch });
          this.$toast.success('Информация обновлена', { position: 'top-right', timeout: 2000 });
        } else {
          await this.setMainBanner(this.currentWatch);
          this.$toast.success('Основной баннер создан', { position: 'top-right', timeout: 2000 });
        }
      } catch (error) {
        console.error('Error saving main banner:', error);
      }
    },
    populateMainBanner() {
      if (this.mainBanner) {
        this.currentWatch.youtube_link = this.mainBanner.youtube_link || '';
        this.currentWatch.title = this.mainBanner.title || { ru: '', en: '', uz: '', kz: '' };
        this.currentWatch.meta_description = this.mainBanner.meta_description || { ru: '', en: '', uz: '', kz: '' };
        this.currentWatch.description = this.mainBanner.description || { ru: '', en: '', uz: '', kz: '' };
      }
    },
  },
  created() {
    this.fetchWatches().then(() => {
      this.populateMainBanner();
    });
    this.grabLanguages();
  },
  watch: {
    mainBanner(newBanner) {
      if (newBanner) {
        this.populateMainBanner();
      }
    }
  }
}
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-control {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.ck-editor__editable {
  min-height: 300px;
}
</style>