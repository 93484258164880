

export default {
    state: ()=>{
        return {
            show: false,
            option: '',
            idModalForDelete: null,
        }
    },
    getters: {
        getShow: state => state.show,
        getOption: state => state.option,
        getIdModalForDelete: state => state.idModalForDelete

    },
    mutations: {
        openShow: (state) =>{
            state.show = true
        },
        closeShow: (state)=>{
          state.show = false
        },
        changeOption: (state, newVal) => {
            state.option = newVal
        },
        setCleanOption: (state)=> {
            state.option = ''
        },
        setIdForDelete(state, newVal){
            state.idModalForDelete = newVal
        }
    },
    actions: {
        openModal(context){
            context.commit('openShow')
        },
        closeModal(context){
          context.commit('closeShow')
        },
        optionName(context, value){
            context.commit('changeOption', value)
        },
        idForDeleting(context,value){
          context.commit('setIdForDelete', value)
        },
        cleanOption(context){
            context.commit('setCleanOption')
        },
    }
}