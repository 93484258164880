<template>
  <b-form-group :label="label" class="editor-divisions-wrapper">
    <div v-for="division in modelValue" class="grid-mini-inputs mb-2" :key="division.division_id">
      <span>{{ division.division_name }}</span>
      <b-form-input v-model="division['slot_count']" type="number" :required="required" />
    </div>
  </b-form-group>
</template>

<script>
export default {
  name: "EditorDivisions",
  props: {
    value: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>