<template>
  <div class="wins-counter-wrapper" :class="[errorText && 'error-counter']">
    <template v-if="errorText">
      Ошибка!
    </template>
    <div v-else-if="isLoadingSubmit" class="saving-text">
      <span class="d-none d-sm-block">Сохраняем...</span>
      <b-spinner class="spinner-border d-sm-none d-block"/>
    </div>
    <template v-else>
      <img @click="model++, callSubmit()" :src="PlusIcon" alt="" class="counter"/>
      <input v-model="model" type="number" readonly />
      <span class="d-none d-sm-block">побед</span>
      <img @click="model--, callSubmit()" :src="MinusIcon" alt="" class="counter"/>
    </template>
  </div>
</template>

<script>
import PlusIcon from '@/assets/icons/plus.svg'
import MinusIcon from '@/assets/icons/minus.svg'

import debounce from 'lodash.debounce'
import api from '@/api/api'
import {mapState} from "vuex";

export default {
  name: "WinsCounter",
  props: {
    value: {
      type: Number,
      default: 0
    },
    teamResult: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      model: 0,
      PlusIcon,
      MinusIcon,
      isLoadingSubmit: false,
      errorText: null,
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['selectedStageId', 'selectedRoundId'])
  },
  methods: {
    async updateWinsCount() {
      try {
        this.isLoadingSubmit = true
        this.errorText = null
        await api.tournamentGroupResult.addData(this.teamResult.group_result.id, {
          winPoint: this.model
        })
        await this.$store.dispatch('moduleNewTournament/getTournament', {
          id: this.$route.params.standingId
        })
        await this.$store.dispatch('moduleNewTournament/getGroupsWithTeamsByMLBB', { roundId: this.selectedRoundId })
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSubmit = false
      }
    },
    callSubmit: debounce(function () {
      this.updateWinsCount()
    }, 500)
  },
  watch: {
    teamResult: {
      deep: true,
      immediate: true,
      handler() {
        this.model = this.teamResult.group_result?.result_fields?.winner_matches_count || 0
      }
    }
  },
}
</script>

<style scoped lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.counter {
  @media screen and (max-width: 500px) {
    width: 12px;
  }
}
.wins-counter-wrapper {
  background: #f5f7fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  border-radius: 20px;

  @media screen and (max-width: 575px) {
    display: grid;
    justify-content: center;
    justify-items: center;
    padding: 6px 7px;
  }

  &.error-counter {
    text-align: center;
    background: #ff0000a3;
    color: white;
    justify-content: center;
  }

  input {
    width: 30px;
    margin: 0 5px;
    border: none;
    background: #f5f7fa;
    text-align: center;

    @media screen and (max-width: 575px) {
      width: 20px;
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }

  span {
    display: block;
    margin-right: 10px;
  }

  img {
    cursor: pointer;
  }

  .saving-text {
    display: block;
    text-align: center;
    margin: 0;
    width: 100%;

    @media screen and (max-width: 575px) {
      width: 20px;
    }

    .spinner-border {
      width: 20px;
      height: 20px;
    }
  }
}
</style>