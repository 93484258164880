import api from "/src/api/api";
import * as mutypes from "/src/store/mutation.types";

export default {
    state: () => {
        return {
            tournamentStage: {
                name: '',
                rating_coefficient: null,
                replacement: null,
                status: null,
                thresholdRating: 0,
                divisions: [],
                personalRating: null
            }
        }
    },
    mutations: {
        [mutypes.SET_TOURNAMENT_STAGE]                       : (state,value) => state.tournamentStage = value,
        [mutypes.SET_TOURNAMENT_STAGE_NAME]                  : (state,value) => state.tournamentStage.name = value,
        [mutypes.SET_TOURNAMENT_STAGE_RATING_COEFFICIENT]    : (state,value) => state.tournamentStage.rating_coefficient = value,
        [mutypes.SET_TOURNAMENT_STAGE_REPLACEMENT]           : (state,value) => state.tournamentStage.replacement = value,
        [mutypes.SET_TOURNAMENT_STAGE_STATUS]                : (state,value) => state.tournamentStage.status = value,
        [mutypes.SET_TOURNAMENT_STAGE_THRESHOLD_RATING]      : (state,value) => state.tournamentStage.thresholdRating = value,
        [mutypes.SET_TOURNAMENT_STAGE_DIVISIONS]             : (state,value) => state.tournamentStage.divisions = value,
        [mutypes.SET_TOURNAMENT_STAGE_COEF_PERSONAL_RATING]  : (state,value) => state.tournamentStage.personalRating = value

    },
    getters: {
        getTournamentStage          : state => state.tournamentStage,
        getTournamentStageName      : state => state.tournamentStage.name,
        getTournamentStageRating    : state => state.tournamentStage.rating_coefficient,
        getTournamentStageReplacement  : state => state.tournamentStage.replacement,
        getTournamentStageStatus    : state => state.tournamentStage.status,
        getTournamentStageThresholdRating: state => state.tournamentStage.thresholdRating,
        getTournamentStageDivisions: state => state.tournamentStage.divisions,
        getTournamentStageCoefPersonalRating: state => state.tournamentStage.personalRating
    },
    actions: {
        storeTournamentStage: async (_,params)=> {
            await api.tournamentStage.store(params)
        },
        updateTournamentStage: async ({getters},id)=> {
            await api.tournamentStage.update(id,{
                'name'              : getters.getTournamentStageName,
                'rating_coefficient': getters.getTournamentStageRating,
                'replace_count'     : getters.getTournamentStageReplacement,
                'status'            : getters.getTournamentStageStatus.value,
                'threshold_rating'  : getters.getTournamentStageThresholdRating,
                'divisions'         : getters.getTournamentStageDivisions.map((div) => ({
                    division_id: div.division_id,
                    'slot_count': div['slot_count']
                })),
                'player_rating_coefficient'  : getters.getTournamentStageCoefPersonalRating,

            })
        },

    },
}
