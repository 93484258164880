<template>
  <div>
    <h5 class="mb-2 mt-4"> Участники </h5>

    <b-table
        v-if="showEventData?.teams"
        responsive
        show-empty
        class="mb-4"
        style="font-size: 16px"
        :striped="striped"
        :bordered="bordered"
        :items="showEventData.teams"
        :fields="fields"
        :key="showEventData.id + '_showEventData'"
        small
    >
      <template v-slot:cell(id)="{item}"> {{ item.team_id }}</template>
      <template v-slot:cell(team)="{item}"> {{ item.info.name }}</template>
      <template v-slot:cell(players)="{item}">
        <div class="d-flex">
          {{ item.players }}
          <div class="ml-2">
            <b-btn variant="info" sm v-b-toggle="'players'+ item.team_id" class="px-3">
              <i class="fa fa-users"></i>
            </b-btn>
            <b-collapse :id="'players'+item.team_id">
              <div class="mt-3">
                <b-list-group :key="'team_member_stage' + index + item.team_id"
                              v-for="(teamMember,index) in item.members">
                  <b-list-group-item class="p-0 px-1 text-left">
                    {{ teamMember ? teamMember.info.nick : 'не указан' }}
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-collapse>
          </div>
        </div>
      </template>
      <template v-slot:cell(rating)="{item}"> {{ item.rating }}</template>
      <template v-slot:cell(position)="{item}">
        <PlaceInput
            :default-place="Number.parseInt(item.place)"
            :team-id="Number.parseInt(item.team_id)"
            :match-id="Number.parseInt(selectedMatch)"
        />
      </template>
      <template v-slot:cell(rate)="{item}">
        <RateInput
            :default-rate="Number.parseInt(item.rate)"
            :team-id="Number.parseInt(item.team_id)"
        />
      </template>
    </b-table>
    <div v-if="!showEventData?.teams" class="loading-wrapper">
      <b-spinner class="spinner"></b-spinner>
    </div>

    <div class="d-flex flex-wrap justify-content-md-start justify-content-center">
      <b-btn v-if="hasSendButton"
             :disabled="showEventData?.type === 'live'"
             @click.prevent="storeRate({
                    matchId: selectedMatch,
                    type: 'not_started'})"
             variant="info"
             class="mb-2"
      >
        Передать
      </b-btn>
      <b-btn v-if="hasLiveButton"
             :disabled="showEventData?.type === 'not_sended'"
             @click.prevent="storeLive({
                    matchId: selectedMatch,
                    type: 'live'})"
             variant="primary"
             class="ml-4 mb-2">
        Live
      </b-btn>
      <b-btn v-if="hasPauseButton"
             :disabled="showEventData?.type === 'suspended'"
             @click.prevent="storeLive({
                    matchId: selectedMatch,
                    type: 'suspended'})"
             variant="warning"
             class="ml-4 mb-2">
        Пауза
      </b-btn>

      <b-btn v-if="hasEndButton"
             @click.prevent="storeEnd({
                     matchId: selectedMatch,
                     type: 'ended'})"
             variant="danger"
             class="ml-4 mb-2">
        Завершить
      </b-btn>
      <b-btn v-if="hasReplayButton"
             @click.prevent="storeEnd({
                      matchId: selectedMatch,
                      type: 'replay'})"
             variant="secondary"
             class="ml-4 mb-2">
        Переигровка
      </b-btn>
    </div>
  </div>
</template>

<script>
import {TournamentStandingsAdapterMixin} from "@/mixins/Tournament/TournamentStandingsAdapterMixin";
import {BookAdapterMixin} from "@/mixins/Book/BookAdapterMixin";
import RateInput from "@/components/Live/RateInput.vue";
import PlaceInput from "@/components/Live/PlaceInput.vue";

export default {
  name: 'SettingsLive',
  components: {RateInput, PlaceInput},
  mixins: [TournamentStandingsAdapterMixin, BookAdapterMixin],
  data() {
    return {
      fields: [
        {key: 'id', label: 'id', sortable: false},
        {key: 'team', label: 'Команда', sortable: false},
        {key: 'players', label: 'Игроки', sortable: false},
        {key: 'rating', label: 'Рейтинг', sortable: true},
        {key: 'position', label: 'Place PTS', sortable: true},
        {key: 'rate', label: 'Rate', sortable: false},
      ],
      striped: true,
      bordered: true,
    }
  },
  async mounted() {
    await this.showEventResult(this.selectedCurrentMatchIdBook);
  },

  computed: {
    hasSendButton() {
      return !['ended', 'replay'].includes(this.showEventData?.type)
    },
    hasLiveButton() {
      return !['live', 'ended', 'replay'].includes(this.showEventData?.type)
    },
    hasPauseButton() {
      return !['ended', 'suspended', 'replay', 'not_sended'].includes(this.showEventData?.type)
    },
    hasEndButton() {
      return ['live'].includes(this.showEventData?.type) && !['ended'].includes(this.showEventData?.type)
    },
    hasReplayButton() {
      return !['replay', 'ended'].includes(this.showEventData?.type)
    }
  }
}
</script>