import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            matches: [],
            currentGroupMatches: [],
            selectedMatchId: null,
        }
    },
    mutations: {
        [mutypes.SET_TOURNAMENT_MATCHES]: (state, value) => state.matches = value,
        [mutypes.SET_TOURNAMENT_CURRENT_MATCHES]: (state, value) => state.currentGroupMatches = value,
        [mutypes.APPEND_TOURNAMENT_MATCH]: (state, match) => state.matches[match.index] = match,
        [mutypes.SET_TOURNAMENT_CURRENT_MATCHES_SELECTED_MATCH_ID]: (state, value) => state.selectedMatchId = value,
    },

    getters: {
        getTournamentMatches: state => state.matches,
        getTournamentCurrentMatches: state => state.currentGroupMatches,
        getTournamentCurrentMatchesSelectedStageId: state => state.selectedMatchId,
    },

    actions: {
        appendMatch: ({commit, getters},match) => {
            let matches = getters.getTournamentMatches;

            let key = match['key'];
            if (matches[match['index']]) {
                matches[match['index']][key] = match[key];
            } else {
                matches[match['index']] = match;
            }
            commit(mutypes.SET_TOURNAMENT_MATCHES,matches)
        },
        async store ({commit, getters},match) {
            let response = await api.tournamentMatches.store({group_matches: getters.getTournamentMatches});
        },
        async updateMatches({commit, getters}, match){
            let response = await api.tournamentMatches.update({group_matches: match})

        },
        async showGroupMatches({commit, getters}, id){
            let response = await api.tournamentMatches.showMatches(id)
            commit(mutypes.SET_TOURNAMENT_CURRENT_MATCHES, response.data.group_matches)
            commit(mutypes.SET_TOURNAMENT_CURRENT_MATCHES_SELECTED_MATCH_ID, response.data.group_matches[0]?.id);
        },
        async deleteShowMatch({commit, getters}, id){
            let response = await api.tournamentMatches.deleteMatch(id)
               let matchValue = getters.getTournamentCurrentMatches.filter((el)=> el.id !== id )
                commit(mutypes.SET_TOURNAMENT_CURRENT_MATCHES, matchValue)
        },
    }
}