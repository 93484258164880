<template>
  <div class="god-dashboard-wrapper">
    <header>
      <div class="left-block">
        <button v-if="selectedTeamId" class="button-back" @click="$store.commit('moduleNewTournament/setFirstLevelData', { name: 'selectedTeamId', value: null })">
          <img :src="AngleLeftIcon" alt="" />
          Назад
        </button>
        <button class="button-home" @click="$store.commit('moduleNewTournament/setFirstLevelData', { name: 'selectedDashboard', value: null })">
          <img :src="HouseIcon" alt="" />
        </button>
        <transition :name="'slide-up'" mode="out-in">
          <h2 v-if="isShowTitle">{{ titleDashboard }}</h2>
        </transition>
      </div>
      <div class="tournament-data">
        <span class="label">Турнир</span>
        <span class="value">{{ tournament.name }}</span>
      </div>
    </header>
    <div class="body">
      <transition :name="'slide-right'" mode="out-in">
        <component :is="workspaceComponent" />
      </transition>
    </div>
  </div>
</template>

<script>
import '@/assets/scss/animations/slide.scss'
import { mapState } from 'vuex'
import AngleLeftIcon from '@/assets/icons/angle-left.svg'
import HouseIcon from '@/assets/icons/house.svg'

import TeamsByTournament from "@/components/Tournament/workspaces/TeamsByTournament.vue";
import DeepEditTeamFromTournament from "@/components/Tournament/workspaces/DeepEditTeamFromTournament.vue";

export default {
  name: "GodDashboard",
  data() {
    return {
      AngleLeftIcon,
      HouseIcon,
      isShowTitle: false
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['selectedTeamId', 'tournament']),
    workspaceComponent() {
      if (this.selectedTeamId) return DeepEditTeamFromTournament
      return TeamsByTournament
    },
    titleDashboard() {
      if (this.selectedTeamId) return `Режим бога: Редактирование команды`
      return `Режим бога: Список команд`
    }
  },
  destroyed() {
    this.$store.commit('moduleNewTournament/setFirstLevelData', {
      name: 'selectedTeamId',
      value: null
    })
  },
  watch: {
    titleDashboard: {
      immediate: true,
      handler() {
        this.isShowTitle = false
        this.$nextTick(() => {
          this.isShowTitle = true
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.god-dashboard-wrapper {
  padding-top: 25px;

  padding-left: 20px;
  padding-bottom: 30px;

  .button-back {
    background: transparent;
    color: #7366ff;
    border: none;
    border-radius: 15px;
    padding: 8px 14px;
    font-size: 14px;
    transition: .2s;

    display: flex;
    align-items: center;

    &:hover {
      background: #dad6ff;
    }

    img {
      margin-right: 7px;
      height: 12px;
    }
  }

  .button-home {
    height: 35px;
    width: 35px;
    background: white;
    border-radius: 50%;
    border: 1px solid #7366ff;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 15px;
      height: 15px;
    }
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    @media screen and (max-width: 560px) {
      display: block;
    }

    h2 {
      font-size: 24px;
      margin: 0;
      //margin: 0 0 0 10px;

      @media screen and (max-width: 800px) {
        font-size: 22px;
      }

      @media screen and (max-width: 500px) {
        font-size: 18px;
      }
    }

    .left-block {
      display: flex;
      align-items: center;
      justify-content: left;
      gap: 10px;
    }

    .tournament-data {
      display: flex;
      flex-direction: column;

      .label {
        text-align: right;
        font-size: 10px;
        color: #b1b1b1 !important;
      }
      .value {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }

  & > .body {
    //margin: 5px 15px;
  }
}
</style>