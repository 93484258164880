<template>
  <div class="add-team-card-wrapper">
    <header>
      <h4>
        {{ team.name }}
      </h4>
      <span class="id-team">id: {{ team.id }}</span>
    </header>
    <div class="team-add-action-wrapper">
      <div class="captain-team">
        <span class="label">Капитан:</span> <span class="value">{{ team?.captain?.full_name }} ({{ team?.captain?.username }})</span>
      </div>
      <div class="d-flex justify-content-end action-block">
        <button v-if="!isShowPlayerList && !hasAdded" class="select-team-button" @click="isShowPlayerList = true">Выбрать</button>
        <span v-if="hasAdded" class="team-has-added">Команда уже добавлена</span>
      </div>
    </div>
    <template v-if="isShowPlayerList">
      <div class="player-list">
        <h6 class="label">
          Игроки:
        </h6>
        <div v-for="player in team.members"
             @click="togglePlayer(player.id)"
             :class="[getStatusDisabledPlayer(player) && 'disabled']"
             class="player">
          <div class="checked-icon">
            <img v-if="selectedPlayers.includes(player.id)" :src="CheckIcon" alt="">
          </div>
          <h6>{{ getMemberFields(player)?.nick || ' - '}}</h6>
        </div>
      </div>
      <footer>
        <button class="select-team-button reject"
                :disabled="isLoadingSubmit"
                @click="isShowPlayerList = false">
          Отменить выбор
        </button>
        <button class="select-team-button"
                :disabled="isLoadingSubmit || selectedPlayers.length < tournament.format.min"
                @click="addTeam">
          {{ isLoadingSubmit ? 'Добавляем...' : 'Добавить' }}
        </button>
      </footer>
      <b-alert v-if="errorText" show variant="danger" style="margin: 10px 0 0 0">{{ errorText }}</b-alert>
    </template>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import api from "@/api/api";
import CheckIcon from '@/assets/icons/check.svg'

export default {
  name: "AddTeamCard",
  props: {
    team: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      CheckIcon,
      isShowPlayerList: false,
      isLoadingSubmit: false,
      selectedPlayers: [],
      errorText: null
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['selectedStageId', 'selectedRoundId', 'selectedGroupId', 'teamsByGroup', 'groupsWithTeamsByMLBB', 'tournament']),
    ...mapGetters('moduleNewTournament', ['selectedGroup']),
    hasAdded() {
      // For MLBB
      if (this.tournament.game.slug === 'mlbb') {
        const allGroupTeams = this.groupsWithTeamsByMLBB.reduce((state, group) => {
          state = [...state, ...group.group_teams]
          return state
        }, [])

        return allGroupTeams.some((group) => (group?.team?.id || 0) === this.team.id)
      }
      // For PUBG
      if (!this.teamsByGroup || !this.teamsByGroup.length) return false
      return this.teamsByGroup.some(({ team: { id } }) => id === this.team.id)
    }
  },
  methods: {
    getStatusDisabledPlayer(player) {
      const maxCount = this.tournament.format.max
      const isDisabledChoice = this.selectedPlayers.length >= maxCount
      return isDisabledChoice ? !this.selectedPlayers.includes(player.id) : false
    },
    togglePlayer(id) {
      if (this.getStatusDisabledPlayer({ id })) return
      const hasSelected = this.selectedPlayers.includes(id)
      if (hasSelected) {
        const index = this.selectedPlayers.indexOf(id)
        this.selectedPlayers.splice(index, 1)
      } else {
        this.selectedPlayers.push(id)
      }
    },
    async addTeam() {
      try {
        this.errorText = null
        this.isLoadingSubmit = true

        if (!this.selectedGroupId && this.tournament.game.slug === 'mlbb') {
          await api.tournament.addTeamToStage({
            stage_id: this.selectedStageId,
            team_id: this.team.id,
            user_ids: this.selectedPlayers,
          })
        } else {
          await api.tournament.newTransferTeamWithMembersToTournament({
            group_id: this.selectedGroupId,
            team_id: this.team.id,
            user_ids: this.selectedPlayers,
          })
        }

        if (this.tournament.game.slug === 'pubg_mobile')
          await this.$store.dispatch('moduleNewTournament/getTeamsByGroup', { id: this.selectedGroupId })
        if (this.tournament.game.slug === 'mlbb')
          await this.$store.dispatch('moduleNewTournament/getGroupsWithTeamsByMLBB', { roundId: this.selectedRoundId })
        await this.$store.dispatch('moduleNewTournament/getTournament', {
          id: this.$route.params.standingId
        })
        this.selectedPlayers = []
        this.isShowPlayerList = false
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSubmit = false
      }
    },
    getMemberFields(member) {
      const foundProfile = member.gameProfiles.find(({ game_id }) => game_id === this.tournament.game.id)
      if (foundProfile) {
        const fields = foundProfile.fields
        fields.id = fields[ this.tournament.game.slug === 'mlbb' ? 'mlbb_id' : 'pubg_id' ]
        return fields
      }
      return {}
    },
  }
}
</script>

<style scoped lang="scss">
.add-team-card-wrapper {
  background: white;
  padding: 20px;
  border-radius: 10px;
  transition: .2s;

  margin-bottom: 20px;

  .team-has-added {
    color: #008300de;
  }

  .player-list {
    width: fit-content;

    & > h6.label {
      color: #000000;
      font-size: 16px;
      margin: 10px 0;
      font-weight: 400;
    }

    & > .player:not(:last-child) {
      border-bottom: 1px solid #0000000f;
    }

    .player {
      padding: 10px 10px;
      cursor: pointer;
      transition: .2s;
      border-radius: 5px;

      display: grid;
      grid-template-columns: 20px auto;
      gap: 10px;

      &:hover {
        background: #dad6ff;
        color: #7366ff;
      }

      &.disabled {
        cursor: not-allowed;
        color: gray;
      }

      .checked-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 13px;
        }
      }

      h6 {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
      }
    }
  }

  header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .id-team {
      color: #808080c7;
      font-size: 12px;
    }
  }

  .team-add-action-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @media screen and (max-width: 550px) {
      display: block;
    }

    .action-block {
      @media screen and (max-width: 550px) {
        margin-top: 10px;
      }
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    @media screen and (max-width: 500px) {
      margin-top: 6px;
    }
  }

  .select-team-button {
    display: block;
    font-size: 13px;
    border-radius: 15px;
    padding: 5px 13px 5px 13px;
    background: #dad6ff;
    color: #7366ff;
    border: none;
    transition: .2s;
    -webkit-border-radius: 15px;

    &:hover {
      background: #7366ff;
      color: white;
    }

    &.reject {
      background: white;
      outline: 1px solid #dad6ff;
      outline-offset: -1px;
      color: #dad6ff;
      -webkit-border-radius: 15px;

      &:hover {
        outline: 1px solid #7366ff;
        color: #7366ff;
      }
    }

    &[disabled] {
      cursor: not-allowed;
    }
  }

  .captain-team {
    margin-top: 10px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 500px) {
    display: block;
    }

    .label {
      color: black;
      font-size: 16px;
      margin-right: 6px;
    }

    .value {
      color: #000000ab;
      margin-bottom: -2px;
    }
  }

  h4 {
    margin: 0;
    font-size: 16px;

    @media screen and (max-width: 400px) {
      font-size: 14px;
    }

  }
}
</style>