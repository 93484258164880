import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";


export const TournamentEntryAdapterMixin = {
    computed: {
        ...mapGetters({
            getTournamentEntry               : 'getTournamentEntry',
        }),
        entry             : {get() {return this.getTournamentEntry                 }, set(value) {this.setEntry(value)       }},
    },
    methods: {
        ...mapMutations({
            setEntry            : mutypes.SET_TOURNAMENT_ENTRY,
        }),
        ...mapActions([
            'showTournamentEntry',
            'changeTournamentEntryStatus'
        ]),

    }
}