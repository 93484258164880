<template>
  <div class="make-winner">
    <Modal name="TransferTeamToNextRound" title="Назначение победителем" v-model="isShowTransferTeamModal" :params="{ teamResult: teamResult }"/>
    <div @click="isShowTransferTeamModal = true" class="rounded-lg p-1" style="background-color: #EFEFEF">
      <img src="@/assets/images/cupWithHand.png" v-b-tooltip.hover.top="`Назначить победителем`" alt="" width="20" class="">
    </div>
  </div>
</template>

<script>
import Modal from '@/components/modals/index.vue'

export default {
  name: "WinsCounter",
  components: {
    Modal
  },
  props: {
    teamResult: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isShowTransferTeamModal: false
    }
  },
}
</script>

<style scoped lang="scss">
.make-winner {
  align-items: center;

  img {
    cursor: pointer;
  }
}
</style>