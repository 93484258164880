<template>
<div>
  <Breadcrumbs  title="SEO" main="Cоздать"/>
  <div class="container">
    <b-card>
      <div class="mb-3">
      <label for="">Title</label>
      <b-form-textarea v-model="newTitle"></b-form-textarea>
      </div>

      <div class="mb-3">
        <label for="">Description</label>
        <b-form-textarea v-model="newDescription"></b-form-textarea>
      </div>

      <div class="mb-5">
        <label for="">Slug</label>
        <b-form-textarea v-model="newSlug"></b-form-textarea>
      </div>

      <div>
        <b-btn variant="primary" class="mr-3" @click.prevent="postSeo({title: newTitle, description: newDescription, slug: newSlug}).then(()=> {$router.go(-1)})">Cоздать</b-btn>
        <b-btn variant="warning" @click.prevent="$router.go(-1)">Назад</b-btn>
      </div>

    </b-card>

  </div>
</div>
</template>

<script>
import {SeoAdapterMixin} from "@/mixins/Seo/SeoAdapterMixin";

export default {
  name: "SeoCreate",
  mixins: [SeoAdapterMixin]
}
</script>

<style scoped>

</style>