<template>
  <div>
    <Breadcrumbs main="Рассылка" title="Создать рассылку"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Создание рассылки</h5>
            </div>
            <div class="card-body">
              <b-card>
                <b-form>
                  <div class="mt-3">
                    <label class="font-weight-bold">Название рассылки</label>
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <b-form-input type="text" v-model="nameMailing"
                                    placeholder="Текст рассылки"></b-form-input>
                      <div class="text-danger">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="mt-3">
                    <label class="font-weight-bold">Тип сообщения</label>
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <b-form-select :options="messageTypes" v-model="typeMessage"></b-form-select>
                      <div class="text-danger">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <!--                  <div class="mt-3">-->
                  <!--                    <label class="font-weight-bold">Турнир</label>-->
                  <!--                    <ValidationProvider rules="required" v-slot="{errors}">-->
                  <!--                      <Multiselect v-model="tournaments" :options="filters.tournaments" label="text" track-by="value"-->
                  <!--                                   :multiple="true"></Multiselect>-->
                  <!--                      <div class="text-danger">{{ errors[0] }}</div>-->
                  <!--                    </ValidationProvider>-->
                  <!--                  </div>-->
                  <div class="mt-3">
                    <label class="font-weight-bold">Тип роли</label>
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <Multiselect :options="roleTypesList" v-model="filters.roleTypes" label="text" track-by="value"
                                   :multiple="true"></Multiselect>
                      <div class="text-danger">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="mt-3">
                    <label class="font-weight-bold">Без команды</label>
                    <ValidationProvider rules="required">
                      <b-checkbox
                        v-model="filters.withoutTeam"
                        value="true"
                      >
                        Игроки без команды
                      </b-checkbox>
                    </ValidationProvider>
                  </div>

                  <div class="mt-3">
                    <label class="font-weight-bold">Дивизион команды</label>
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <Multiselect :options="mailingDivisions" v-model="filters.divisions" label="text" track-by="value"
                                   :multiple="true"></Multiselect>
                      <div class="text-danger">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="mt-3">
                    <label class="font-weight-bold">Страна</label>
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <Multiselect :options="mailingCountries" v-model="filters.countries" label="text" track-by="value"
                                   :multiple="true"></Multiselect>
                      <div class="text-danger">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="mt-3">
                    <label class="font-weight-bold">Вид рассылки</label>
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <Multiselect :options="viaList" v-model="mailingView" label="text" track-by="value"
                                   :multiple="true"></Multiselect>
                      <div class="text-danger">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="mt-3 font-weight-bold">
                    <label>Выбрать обложку</label>
                    <b-form-file v-model=" mailingImage" class="mb-3"></b-form-file>
                  </div>
                  <div class="mt-3">
                    <label class="font-weight-bold">Текст рассылки</label>
                    <ValidationProvider name="Текст рассылки" rules="min:2|required"
                                        v-slot="{errors}">

                      <v-md-editor v-model="textMailing" height="400px"></v-md-editor>
                      <div class="text-danger">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                </b-form>
                <div class="mt-3 justify-content-center align-items-center">
                  <button
                      @click="createMailing"
                      type="button"
                      class="btn btn-success"
                  >Опубликовать
                  </button>
                </div>
                <div class="mt-3 justify-content-center align-items-center">
                  <h2>Прогнозируемое количество подходящих пользователей под рассылку: {{ countUsersForMailing }}</h2>
                </div>

              </b-card>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>


import {MailingAdapterMixin} from "@/mixins/Mailing/MailingAdapterMixin";
import Multiselect from "vue-multiselect";
import {DivisionsAdapterMixin} from "@/mixins/Divisions/DivisionsAdapterMixin";
import api from "@/api/api";


export default {
  name: 'CreateNewsletter',
  components: {Multiselect},
  mixins: [MailingAdapterMixin, DivisionsAdapterMixin],
  data() {
    return {
      countUsersForMailing: 0
    }
  },
  watch: {
    filters: {
      deep: true,
      immediate: true,
      async handler() {
        let result = Object.keys(this.filters).reduce((acc, key) => {
          // Для каждого массива получаем только value
          acc[key] = this.filters[key].map(item => item.value);
          return acc;
        }, {});
        result = {filters: result};

        const response = await api.newsletter.getExpectedMailingCount(result);

        this.countUsersForMailing = response.data.data.total_count;
      }
    }
  },

  created() {
    this.getCountries();
    this.getDivisions();
    this.getTournamentsList()
    this.getMessageTypesList()
    this.getTournamentTypesList()
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>