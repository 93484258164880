<template>
  <div>
    <b-card>

    <div v-if="currentMatches.length > 0">
      <ExistingTournamentMatchForms></ExistingTournamentMatchForms>
    </div>
    <div v-for="(item,index) in components" :key="'match_' + index">
      <ChangeTournamentMatchForms
          @changeMatch="changeMatch"
          @deleteMatchForm="deleteMatchForm(index)"
          :id="'match_' + index"
          :index="index"
          :groupId="+$route.params.group_id"
          :key="'sad_match_' + index"
      />
    </div>

    <div class="d-flex justify-content-center flex-wrap">
    <button class="btn btn-primary mr-3 mt-4" @click="updateAllMatches(getMatches, currentMatches)">Сохранить</button>
    <button class="btn btn-success mt-4" @click="addTournamentMatchForms">Добавить матч</button>
    </div>
    <div class="d-flex justify-content-center flex-wrap">
      <button class="btn text-primary mt-3" @click="$router.go(-1)">Назад</button>
    </div>
    </b-card>
  </div>
</template>

<script>
import ChangeTournamentMatchForms from "@/pages/Tournament/ChangeTournamentMatch/ChangeTournamentMatchForms.vue";
import modal from '@/components/modal.vue'
import {TournamentMatchesAdapterMixin} from "../../../mixins/TournamentMatch/TournamentMatchesAdapterMixin";
import ExistingTournamentMatchForms from "@/pages/Tournament/ChangeTournamentMatch/ExistingTournamentMatchForms.vue";

export default {
  name: 'ChangeTournamentMatch',
  components: {ExistingTournamentMatchForms, ChangeTournamentMatchForms, modal},
  mixins:[TournamentMatchesAdapterMixin],
  data() {
    return {
      components: [],
    }
  },
  mounted() {
    this.showGroupMatches(this.$route.params.group_id)
  },
  destroyed() {
    this.matches = []
  },
  methods: {
    addTournamentMatchForms() {
      this.components.push(ChangeTournamentMatchForms)
    },
    deleteMatchForm(index) {
      this.components.splice(index, 1)
    },
    changeMatch(data) {
      this.appendMatch(data);
    }
  },

}
</script>