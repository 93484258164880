<template>
    <div>
      <Breadcrumbs title="Dashboard"></Breadcrumbs>
      <div v-if="isLoaded" class="loading-wrapper">
        <b-spinner class="spinner"></b-spinner>
      </div>
      <div v-else>
        <div class="container-fluid">
            <div class="row">
                <div v-for="card in dashboardFields" :key="'card-' + card.id" class="col-sm-6 col-lg-3">
                    <div class="card card-hover-shadow h-75">
                        <div class="card-body">
                            <h6 class="card-subtitle">{{ card.name }}</h6>
                            <div class="row align-items-center gx-2 mb-1">
                                 <h2 class="card-title text-inherit mt-2 ml-3">{{ card.count || 0 }}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid mb-4">
            <div class="card card-hover-shadow p-2">
                <highcharts :options="entriesChartOptions"></highcharts>
            </div>
        </div>

        <div class="container-fluid mb-4">
            <div class="card card-hover-shadow p-2">
                <highcharts :options="tournamentsChartOptions"></highcharts>
            </div>
        </div>

        <div class="container-fluid mb-4">
          <div class="card card-hover-shadow p-2">
                <highcharts :options="tournamentTeamsChartOptions"></highcharts>
          </div>
        </div>

        <div class="container-fluid mb-4">
            <div class="table-responsive card card-hover-shadow">
                <table class="table align-items-center mb-0">
                    <thead>
                    <tr>
                        <th width="300" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Страна</th>
                        <th width="300" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Кол-во пользователей
                        </th>
                        <th width="300" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            %
                        </th>
                    </tr>
                    </thead>
                    <tbody v-for="country in dashboardInfo.countries.slice(0, showMoreCountries ? dashboardInfo.countries.length : 5)"
                           :key="country.id">
                    <tr>
                        <td>
                            <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-xs">{{ country.name }}</h6>
                                </div>
                            </div>
                        </td>
                        <td class="d-flex justify-content-center">
                            <p class="text-xs font-weight-bold mb-0">{{ country.users_count }}</p>
                        </td>
                      <td class="">
                        <div class="progress">
                          <div class="progress-bar" :style="{'width': country.users_count / dashboardInfo?.users_count * 100 +'%'}" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                </table>
                <div v-show="dashboardInfo.countries.length >= 5"
                     class="mt-2 d-flex justify-content-center mb-4"
                >
                    <b-btn @click="showMoreCountries = !showMoreCountries" variant="primary" class="d-flex">
                        {{ showMoreCountries ? 'Свернуть' : 'Развернуть' }}
                        <div v-if="showMoreCountries"><i class="fa fa-arrow-up ml-2" aria-hidden="true"></i></div>
                        <div v-else><i class="fa fa-arrow-down ml-2" aria-hidden="true"></i></div>
                    </b-btn>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import {DashboardAdapterMixin} from "@/mixins/Dashboard/DashboardAdapterMixin";
import {Chart} from 'highcharts-vue'

export default {
    name: "DashboardInfo",
    mixins: [DashboardAdapterMixin],
    components: {
        highcharts: Chart
    },
    mounted() {
      try {
        (async () => {
          this.isLoaded = true
            await this.grabDashboard()
                this.dashboardFields = [
                    {id: 1, name: 'Турниры', count: this.dashboardInfo?.tournaments_count},
                    {id: 2, name: 'Команды', count: this.dashboardInfo?.teams_count},
                    {id: 3, name: 'Зарегистрированные пользователи', count: this.dashboardInfo?.users_count},
                    {id: 4, name: 'Турнирных пользователей', count: this.dashboardInfo?.user_with_tournament_game_count},
                    {id: 5, name: 'Пользователи с командой', count: this.dashboardInfo?.users_with_team_count},
                    {id: 6, name: 'Пользователи без команды', count: this.dashboardInfo?.users_without_team_count},
                    {id: 7, name: 'Пользователи с email', count: this.dashboardInfo?.users_with_activated_email_count},
                    {id: 8, name: 'Пользователи с discord или telegram', count: this.dashboardInfo?.users_with_discord_or_tg_count},
                    {id: 9, name: 'Пользователи с whatsapp или telegram', count: this.dashboardInfo?.users_with_wa_or_tg_count},
                    {id: 10, name: 'Пользователи с подтвержденным номером', count: this.dashboardInfo?.users_with_phone_count},
                    {id: 11, name: 'Пользователи, не логинившиеся больше 12/6/3/1 мес', count: this.dashboardInfo?.not_logger_users},
                    {id: 12, name: 'Вход пользователей за последний квартал', count: this.dashboardInfo?.last_quarter_logged_users_count},
                    {id: 13, name: 'Кол-во команд, кто проходят по требованиям', count: this.dashboardInfo?.teams_can_participate_in_tournament_count},
                    {id: 14, name: 'Кол-во команд, которые хоть раз принимали участие', count: this.dashboardInfo?.teams_count.tournament_participated_teams_count},
                    {id: 15, name: 'Среднее количество игроков в команде', count: this.dashboardInfo?.team_members_count_mean},
                    {id: 16, name: 'Заполненные турниры', count: this.dashboardInfo?.tournament_with_fully_registered_teams_count},
                    {id: 17, name: 'Доля команд, которые принимали участие на турнирах DUO', count: this.dashboardInfo?.played_duo_teams_count},
                ]
                this.entriesChartOptions = {
                    title: { text: 'Кол-во поданных заявок на турниры' },
                    chart: { type: 'column' },
                    xAxis: { categories: this.dashboardInfo?.tournaments.map((tourn) => tourn.name) },
                    yAxis: { title: { text: 'Кол-во' } },
                    series: [{ name: 'Заявки', data: this.dashboardInfo?.tournaments.map((tourn) => tourn.entries_count) }]
                }
                this.tournamentsChartOptions = {
                    title: { text: 'Статистика заявок в турнирах' },
                    chart: { type: 'column' },
                    xAxis: { categories: this.dashboardInfo?.tournaments.map((tourn) => tourn.name) },
                    yAxis: { title: { text: 'Кол-во' } },
                    series: [{ name: 'Принятые', data: this.dashboardInfo?.tournaments.map((tourn) => tourn.accepted_entries_count) },
                             { name: 'В ожидании', data: this.dashboardInfo?.tournaments.map((tourn) => tourn.awaited_entries_count) },
                             { name: 'Отказанные', data: this.dashboardInfo?.tournaments.map((tourn) => tourn.declined_entries_count) },
                             { name: 'Удаленные', data: this.dashboardInfo?.tournaments.map((tourn) => tourn.deleted_entries_count) }]
                }
                this.tournamentTeamsChartOptions = {
                  title: { text: 'Статистика кол-ва команд в турнирах' },
                  chart: { type: 'column' },
                  xAxis: { categories: this.dashboardInfo?.tournaments.map((tourn) => tourn.name) },
                  yAxis: { title: { text: 'Кол-во' } },
                  series: [{ name: 'Старые команды', data: this.dashboardInfo?.tournaments.map((tourn) => tourn.already_participate_team_count) },
                           { name: 'Новые команды', data: this.dashboardInfo?.tournaments.map((tourn) => tourn.did_not_participate_tournament_teams_count) }]
                }
        })()
      } catch (error) {
        console.error('Произошла ошибка:', error);
      } finally {
        this.isLoaded = false
      }
    },
    data() {
        return {
            dashboardFields: [],
            showMoreCountries: false,
            entriesChartOptions: {},
            tournamentsChartOptions: {},
            tournamentTeamsChartOptions: {},
            isLoaded: false
        }
    },
}
</script>
