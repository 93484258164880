<template>
  <div>
    <Breadcrumbs main="Новости" title="Добавить помощь"></Breadcrumbs>
    <div class="container">
      <b-card>
        <b-form>
          <b-tabs class="mt-3 d-flex justify-content-md-start justify-content-center flex-wrap">
            <b-tab :title="lang.language"
                   v-for="lang in languages"
                   :key="'lang-' + lang.id"
                   @click="getLanguageFromBtn(lang.language.toLowerCase())"
                   nav-class="mr-2 mb-2">
              <template #title>
                <div class="d-flex justify-content-center">
                  <img :src="lang.img" width="20" height="20" class="mr-2">
                  <span>{{ lang.language }}</span>
                </div>
              </template>
            </b-tab>
          </b-tabs>

          <b-form>
            <label class="mt-3"><b>Заголовок {{ language.toUpperCase() }}</b></label>
            <b-form-input placeholder="Заголовок" v-model="newHelpDeskTitle[language]"></b-form-input>
            <label class="mt-3"><b>Описание {{ language.toUpperCase() }}</b></label>
            <template v-for="lang in Object.keys(newHelpDeskDescription)">
              <SunEditor :key="lang + '-helpDescription'"
                         v-if="language === lang"
                         v-model="newHelpDeskDescription[lang]"
                         :editorId="'helpDescription'"
              />
            </template>
          </b-form>

          <label class="mt-3"><b>Дата</b></label>
          <datetime format="YYYY-MM-DD H:i" v-model="newHelpDeskData"></datetime>

          <label class="mt-3"><b>Дисциплина</b></label>
          <b-form-select v-model="newHelpDeskGameId" :options="games"/>

          <b-btn variant="success" class="mt-5"
                 @click.prevent="storeHelpDeskCreate({
                   title: newHelpDeskTitle,
                   description: newHelpDeskDescription,
                   created_at: newHelpDeskData,
                   game_id: newHelpDeskGameId})">
            Сохранить
          </b-btn>

        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import datetime from 'vuejs-datetimepicker';
import {HelpAdapterMixin} from "@/mixins/helpdesk/HelpAdapterMixin";
import SunEditor from "@/components/SunEditor.vue";
import {LanguageAdapterMixin} from "@/mixins/Language/LanguageAdapterMixin";

export default {
  name: 'PostsCreate',
  mixins: [HelpAdapterMixin, LanguageAdapterMixin],
  components: {SunEditor, datetime},
}
</script>
