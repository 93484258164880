import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const DivisionsAdapterMixin = {
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            getNewDivisions: 'getNewDivisions',
        }),
        newDivisionsList: {
            get() {
                return this.getNewDivisions
            }, set(value) {
                this.setNewDivisions(value)
            }
        },
    },
    methods: {
        ...mapMutations({
            setNewDivisions: mutypes.SET_NEW_DIVISIONS,
        }),
        ...mapActions([
            'grabNewDivisions'
        ]),
    }
}