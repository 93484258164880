export const PNG = 'image/png';
export const WEBP = 'image/webp';
export const JPEG = 'image/jpeg';
export const PDF = 'application/pdf';

export const MIMES = {
    [PNG]: 'data:image/png;base64',
    [WEBP]: 'data:image/webp;base64',
    [JPEG]: 'data:image/jpeg;base64',
    [PDF]: 'data:application/pdf;base64',
}
