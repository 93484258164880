<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Пользователи" title="Список пользователей с levels"/>
      <b-card>
        <div class="d-flex">
          <SearchInput v-model="searchForUsersLevelsModel" @search="getFilteredUsersLevels()" :placeholder-name="'Поиск пользователей'"/>
        </div>
        <div class="notification-for-search mb-4">Поиск по id, username, почте, названию команды, игровым данным: pubg id pubg nick</div>
        <b-table
            responsive
            show-empty
            stacked="md"
            :striped="striped"
            :bordered="bordered"
            :fields="fields"
            :items="usersLevels"
            :current-page=0
            :per-page=10
            class="mb-5"
        >
          <template #cell(username)="data">
            {{ data.item.username }}
          </template>
          <template #cell(level)="data">
            <div v-for="{level, id} in data.item?.gameProfiles" :key="'level' + id">
              {{level?.number}}
            </div>
          </template>
          <template #cell(experience)="data">
            <div v-for="{experience_points, id} in data.item?.gameProfiles" :key="'experience_points' + id">
              {{experience_points}}
            </div>
          </template>
          <template #cell(created_at)="data">
            {{ data.item.created_at }}
          </template>
          <template #cell(game_profiles)="data">
            <div v-for="gameProfile in data.item?.gameProfiles" :key="'gameProfileId' + gameProfile">
              <div v-if="gameProfile" @click="$router.push({name: 'user_levels_logs', params:{id: gameProfile.id}})" class="game-profiles">
                {{ gameProfile?.game.name || '' }}
              </div>
            </div>
          </template>
          <template #cell(action)="data">
            <div class="row justify-content-center">
              <b-btn variant="primary" class="mr-1 p-1" @click="showUserInfo(data.item.id)">
                <i class="fa fa-eye"></i>
              </b-btn>
            </div>
          </template>
        </b-table>
        <b-col md="6" class="p-0">
          <b-pagination
              @change="handlePageChange"
              v-model="usersLevelsCurrentPage"
              :total-rows="totalUsersLevelsPagination"
              :per-page="10"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-card>
    </div>
  </div>
</template>

<script>
import {UsersAdapterMixin} from "../../mixins/Users/UsersAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: "ListUsersLevels",
  components: {SearchInput},
  mixins: [UsersAdapterMixin, PaginationHelperMixin],
  created() {
    this.recordPageToStorage(this.usersLevelsCurrentPage, this.pageKey)
    this.grabUsersLevels({
      pageNumber: this.getCurrentPageNumber(this.pageKey),
    })
  },

  data() {
    return {
      searchForUsersLevelsModel: '',
      pageKey: 'pageNumberUserslevelsList',
      fields: [
        {key: 'id', label: '№', sortable: true,},
        {key: 'username', label: 'Имя', sortable: true,},
        {key: 'level', label: 'Значение level', sortable: true,},
        {key: 'experience', label: 'Кол-во очков опыта', sortable: true,},
        {key: 'created_at', label: 'Дата создания аккаунта', sortable: true,},
        {key: 'game_profiles', label: 'Игровые профиля', sortable: true,},
        {key: 'action', label: 'Инструменты', sortable: true,},
      ],
      striped: true,
      bordered: true
    }
  },
  methods: {
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.grabUsersLevels({
        pageNumber: pageNumber,
        ...(this.searchForUsersLevelsModel && {search: this.searchForUsersLevelsModel}),
      })
    },
    getFilteredUsersLevels() {
      this.grabUsersLevels({search: this.searchForUsersLevelsModel})
    },
  },
}
</script>

<style scoped>
.game-profiles {
  color: blue;
  cursor: pointer
}
</style>
