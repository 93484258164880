<template>
    <div>
        <Breadcrumbs main="Организации" :title="showOrganizer.name"/>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>{{showOrganizer.name}}</h5>
                        </div>
                        <div class="card-body">
                            <b-card>
                                <div class="mt-3">
                                    <label class="font-weight-bold">Наименование</label>
                                        <b-form-input :disabled="true" :placeholder="showOrganizer.name ? showOrganizer.name : 'Не указано'"></b-form-input>
                                </div>
                                <div class="mt-3">
                                    <label class="font-weight-bold">Ссылка на сайт</label>
                                    <b-form-input :disabled="true" :placeholder="showOrganizer.link ? showOrganizer.link : 'Не указано'"></b-form-input>
                                </div>

                                <div class="mt-3">
                                    <label class="font-weight-bold">Платформа</label>
                                        <b-form-input :disabled="true" :placeholder="showOrganizer.platform ? showOrganizer.platform.name : 'Не указано'"></b-form-input>
                                </div>
                                <div class="mt-3">
                                    <label class="font-weight-bold">Рейтинг</label>
                                        <b-form-input :disabled="true" :placeholder="showOrganizer.rating ? showOrganizer.rating : 'Не указано'"></b-form-input>
                                </div>
                            </b-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {OrganizersAdapterMixin} from "@/mixins/Organizers/OrganizersAdapterMixin";

export default {
    name: "OrganizerInfo",
    mixins: [ModalAdapterMixin, OrganizersAdapterMixin],
    created() {
        this.showOrganizer(this.$route.params.id);
    },
}
</script>

<style scoped>

</style>