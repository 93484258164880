import adminHttp from "@/api/adminHttp";

const END_POINT = '/api/v1/admin/user/fake'

export default {
    index: (params) => adminHttp.get(END_POINT, {params: params}),

    show: (id) => adminHttp.get(END_POINT + `/show/${id}`),

    store: (params) => adminHttp.post(END_POINT, params),

    update: (id, params) => adminHttp.put(END_POINT + `/${id}`, params),

    updateGameProfile: (id, params) => adminHttp.post(END_POINT + `/game-profile/${id}`, params),

    delete: (id) => adminHttp.delete(END_POINT + `/${id}`),

    createPubgGameProfile: (id, params) => adminHttp.post(END_POINT + `/${id}/pubg/game-profile`, params),

    createMlbbGameProfile: (id, params) => adminHttp.post(END_POINT + `/${id}/mlbb/game-profile`, params)
}