<template>
  <div class="add-match-wrapper">
    <b-form-group label="Название матча" label-for="name">
      <b-form-input v-model="matchName" id="name" type="text" required />
    </b-form-group>
    <b-form-group label="Lobby number" label-for="lobby">
      <b-form-input v-model="lobbyNumber" id="lobby" type="number" required />
    </b-form-group>
    <b-alert v-if="errorMessage" show variant="danger">{{ errorMessage }}</b-alert>
    <footer>
      <b-button @click="submitForm" :disabled="isLoadingAddingMatch" variant="primary">
        {{ isLoadingAddingMatch ? 'Добавляем...' : 'Добавить' }}
      </b-button>
    </footer>
  </div>
</template>

<script>
import api from "@/api/api";
import {mapState} from "vuex";

export default {
  name: "AddMatchForOlympic",
  props: {
    params: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isLoadingAddingMatch: false,
      errorMessage: null,
      matchName: null,
      lobbyNumber: null
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['selectedGroupId', 'selectedTeamId']),
  },
  methods: {
    async submitForm() {
      try {
        this.isLoadingAddingMatch = true
        this.errorMessage = null
        await api.tournament.storeOlympicMatch({
          match_name: this.matchName,
          lobby_number: this.lobbyNumber,
          pair_id: this.params.pairId
        })
        await this.$store.dispatch('moduleNewTournament/getOlympicMatches', {
          groupId: this.selectedGroupId,
          teamId: this.selectedTeamId
        })
        await this.$emit('closeModal')
      } catch (e) {
        this.errorMessage = e.response.data.message || 'Не удалось добавить матч'
      } finally {
        this.isLoadingAddingMatch = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.add-match-wrapper {

  footer {
    border-top: 1px solid #dee2e6;
    padding-top: 15px;
    margin-top: 15px;
    text-align: right;
  }
}
</style>