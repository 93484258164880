import adminHttp from "/src/api/adminHttp";

const GET_END_POINT = '/api/v1/client/watch'
const END_POINT = '/api/admin/watch'

export default {
    /**
     * GET: Fetch all watches
     * @returns {Promise<AxiosResponse<any>>}
     */
    index: () => adminHttp.get(GET_END_POINT),

    /**
     * POST: Store a new watch
     * @param {Object} params - Watch data
     * @returns {Promise<AxiosResponse<any>>}
     */
    store: (params) => adminHttp.post(END_POINT, params),

    /**
     * POST: Set main banner
     * @param {Object} params - Watch data for main banner
     * @returns {Promise<AxiosResponse<any>>}
     */
    setMainBanner: (params) => adminHttp.post(`${END_POINT}/setMainBanner`, params),

    /**
     * PUT: Update a watch
     * @param {number} id - Watch ID
     * @param {Object} params - Updated watch data
     * @returns {Promise<AxiosResponse<any>>}
     */
    update: (id, params) => adminHttp.put(`${END_POINT}/${id}`, params),

    /**
     * DELETE: Delete a watch
     * @param {number} id - Watch ID
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete: (id) => adminHttp.delete(`${END_POINT}/${id}`),
}