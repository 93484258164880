<template>
  <div :id="editorId" class="editor-container"></div>
</template>

<script>
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Image from '@editorjs/image';
import List from '@editorjs/list';
import Checklist from '@editorjs/checklist';
import Quote from '@editorjs/quote';
import LinkTool from '@editorjs/link';
import Table from '@editorjs/table';
import Delimiter from '@editorjs/delimiter';
import Paragraph from '@editorjs/paragraph';

export default {
  name: 'EditorJS',
  props: {
    value: {
      type: Object,
      default: () => ({ blocks: [] })
    },
    editorId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      editor: null,
      internalValue: null,
    }
  },
  mounted() {
    this.initEditor();
  },
  methods: {
    initEditor() {
      this.editor = new EditorJS({
        holder: this.editorId,
        tools: {
          header: Header,
          paragraph: {
            class: Paragraph,
            inlineToolbar: true
          },
          image: {
            class: Image,
            config: {
              uploader: {
                uploadByFile: (file) => {
                  return new Promise((resolve, reject) => {
                    const formData = new FormData();
                    formData.append("image", file);
                    this.$store.dispatch('moduleStoreImage/storeImage', formData)
                      .then(response => {
                        resolve({
                          success: 1,
                          file: {
                            url: response.url
                          }
                        });
                      })
                      .catch(error => {
                        reject(error);
                      });
                  });
                }
              }
            }
          },
          list: List,
          checklist: Checklist,
          quote: Quote,
          linkTool: LinkTool,
          table: Table,
          delimiter: Delimiter,
        },
        data: this.value,
        onChange: () => {
          this.editor.save().then((outputData) => {
            this.internalValue = outputData;
            this.$emit('input', outputData);
          });
        }
      });
    },
    updateEditor(data) {
      if (this.editor && JSON.stringify(data) !== JSON.stringify(this.internalValue)) {
        this.editor.render(data);
      }
    }
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal !== this.internalValue) {
          this.updateEditor(newVal);
        }
      },
      deep: true
    }
  }
}
</script>

<style>
.editor-container {
  width: 100%;
  position: relative;
}

.codex-editor {
  width: 100%;
  padding: 0;
}

.codex-editor__redactor {
  padding-bottom: 6rem !important;
  padding-left: 3.5rem !important;
  margin-left: 0 !important;
}

.ce-block__content,
.ce-toolbar__content {
  max-width: calc(100% - 1rem) !important;
  padding-left: 0 !important;
}

.ce-toolbar__actions {
  left: 0.5rem !important;
  right: auto !important;
}

.ce-toolbar__plus {
  left: 0.5rem !important;
}

.ce-toolbar__settings-btn {
  left: 0.5rem !important;
  right: auto !important;
}

.ce-paragraph {
  padding-left: 0 !important;
}

.ce-block:first-child {
  margin-top: 0 !important;
}

.ce-inline-toolbar {
  width: auto;
  max-width: calc(100% - 2rem);
}

/* Toolbar styles */
.ce-toolbar {
  left: 0 !important;
  right: auto !important;
  width: 2.5rem !important;
  background: #f8f9fa;
}

.ce-toolbar__content {
  max-width: 2.5rem !important;
  min-width: 2.5rem !important;
}

/* Responsive adjustments */
@media (max-width: 650px) {
  .codex-editor__redactor {
    padding-left: 2.5rem !important;
  }

  .ce-block__content,
  .ce-toolbar__content {
    max-width: 100% !important;
  }

  .ce-toolbar {
    width: 2rem !important;
  }

  .ce-toolbar__content {
    max-width: 2rem !important;
    min-width: 2rem !important;
  }

  .ce-toolbar__plus,
  .ce-toolbar__settings-btn {
    left: 0.25rem !important;
  }
}

/* Ensure proper sizing for block content */
.ce-block__content {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

/* Improve image handling */
.ce-block__content img {
  max-width: 100%;
  height: auto;
}

/* Improve table responsiveness */
.ce-block__content table {
  width: 100%;
  overflow-x: auto;
  display: block;
}

/* Ensure inline tools don't overflow */
.ce-inline-toolbar__buttons {
  flex-wrap: wrap;
}
</style>