<template>
  <div>
    <transition
        name="slide-up"
        mode="out-in"
    >
      <component :is="dashboardComponent">
        <template v-if="!selectedDashboard" #workspace>
          <transition :name="selectedWorkspace ? 'slide-left' : 'slide-right'" mode="out-in">
            <component v-if="workspaceComponent"
                       @changeWorkspace="changeWorkspace"
                       :is="workspaceComponent" />
          </transition>
        </template>
        <template v-if="!selectedDashboard" #sidebar>
          <Sidebar @changeWorkspace="changeWorkspace" />
        </template>
      </component>
    </transition>
  </div>
</template>

<script>
import '@/assets/scss/animations/slide.scss'
import Dashboard from "@/components/Tournament/Dashboard.vue";
import GodDashboard from "@/components/Tournament/GodDashboard.vue";
import Sidebar from "@/components/Tournament/PUBG/Sidebar.vue";
import {workspaces} from "@/components/Tournament/PUBG/workspaces/list";
import {mapGetters, mapState} from "vuex";

export default {
  name: "PUBGPage",
  components: {
    Dashboard,
    Sidebar
  },
  data() {
    return {
      selectedWorkspace: 'AddTeam',
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['selectedStageId', 'selectedGroupId', 'teamsByGroup', 'selectedDashboard']),
    ...mapGetters('moduleNewTournament', ['selectedGroup', 'selectedStage']),
    forWatcherWorkspaces() { return { stage: this.selectedStageId } },
    workspaceComponent() {
      if (this.selectedWorkspace) return workspaces.get(this.selectedWorkspace)

      if (this.selectedGroup && this.teamsByGroup && this.teamsByGroup.length) {
        return workspaces.get('HaveTeams')
      }
      if (this.selectedGroup && (!this.teamsByGroup || !this.teamsByGroup.length)) {
        return workspaces.get('NotTeams')
      }
      return false
    },
    dashboardComponent() {
      if (this.selectedDashboard && this.selectedDashboard === 'god') return GodDashboard
      if (!this.selectedDashboard) return Dashboard
      return null
    }
  },
  methods: {
    changeWorkspace(workspaceName) {
      this.selectedWorkspace = workspaceName
    }
  },
  watch: {
    forWatcherWorkspaces: {
      deep: true,
      immediate: true,
      handler() {
        this.changeWorkspace(null)
      }
    }
  },
  destroyed() {
    this.$store.commit('moduleNewTournament/resetState')
  }
}
</script>

<style scoped lang="scss">
.pubg-page-wrapper {

}
</style>