<template>
<div>
  <Breadcrumbs  title="SEO" main="Редактировать"/>
  <div class="container">
    <b-card>
      <div class="mb-3">
        <label for="">Title</label>
        <b-form-textarea v-model="currentSeo.title"></b-form-textarea>
      </div>

      <div class="mb-3">
        <label for="">Description</label>
        <b-form-textarea v-model="currentSeo.description"></b-form-textarea>
      </div>

      <div class="mb-5">
        <label for="">Slug</label>
        <b-form-textarea v-model="currentSeo.slug"></b-form-textarea>
      </div>

      <div>
        <b-btn variant="primary" class="mr-3" @click.prevent="updateSeo({title: currentSeo.title, description: currentSeo.description, slug: currentSeo.slug, id: $route.params.id}).then(()=> {$router.go(-1)})">Cоздать</b-btn>
        <b-btn variant="warning" @click.prevent="$router.go(-1)">Назад</b-btn>
      </div>

    </b-card>
  </div>
</div>
</template>

<script>
import {SeoAdapterMixin} from "@/mixins/Seo/SeoAdapterMixin";

export default {
  name: "SeoEdit",
  mixins: [SeoAdapterMixin],
  created() {
    this.showSeoPage(this.$route.params.id)
  },
}
</script>

<style scoped>

</style>