import api from "@/api/api";
import * as mutypes from "@/store/mutation.types";
import jwt_decode from "jwt-decode";

export default {
    state: {
        auth: {
            token: localStorage.getItem('token') || '',
            data: {
                email: '',
                password: '',
            }
        },
        user: {
            email: localStorage.getItem('user_email') || '',
            name: localStorage.getItem('user_name') || '',
        },
        //@TODO поправить вывод организаторов при авторизации
        organizers: [],
        email: localStorage.getItem('email') || '',
        roles: JSON.parse(localStorage.getItem('roles')) || []
    },

    mutations: {
        [mutypes.SET_AUTH_TOKEN]: (state, token) => {
            localStorage.setItem('token', token);
            state.auth.token = token;
        },
        [mutypes.SET_AUTH_USER_EMAIL]: (state, email) => {
            localStorage.setItem('user_email', email);
            state.user.email = email;
        },
        [mutypes.SET_AUTH_USER_NAME]: (state, name) => {
            localStorage.setItem('user_name', name);
            state.user.name = name;
        },
        [mutypes.SET_USER_ORGANIZATIONS]: (state, organizers) => {
            localStorage.setItem('user_organizers', JSON.stringify(organizers));
            state.organizers = organizers
        },
        [mutypes.AUTH_LOGOUT]: state => {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_email');
            localStorage.removeItem('user_organizers');
        },
        [mutypes.SET_AUTH_DATA_EMAIL]: (state, value) => state.auth.data.email = value,
        [mutypes.SET_AUTH_DATA_PASSWORD]: (state, value) => state.auth.data.password = value,
        [mutypes.SET_USER_EMAIL]: (state, email) => {
            localStorage.setItem('email', email);
            state.email = email
        },
        [mutypes.SET_USER_ROLES]: (state, roles) => {
            localStorage.setItem('roles', JSON.stringify(roles));
            state.roles = roles
        }
    },

    actions: {
        authLogin: async ({commit, getters}, params) => {
            // try {
            const resp = await api.auth.login({email: getters.getAuthDataEmail, password: getters.getAuthDataPassword,g_recaptcha_response: params });
            const decodeToken = jwt_decode(resp.data.token_data.token);
            commit(mutypes.SET_AUTH_TOKEN, resp.data.token_data.token);
            commit(mutypes.SET_AUTH_DATA_PASSWORD, '');
            commit(mutypes.SET_AUTH_USER_EMAIL, decodeToken.email);
            commit(mutypes.SET_AUTH_USER_NAME, "full_name"); //@todo fix this
            commit(mutypes.SET_USER_ORGANIZATIONS, decodeToken.organizers);
            commit(mutypes.SET_USER_EMAIL, resp.data.user.email);
            commit(mutypes.SET_USER_ROLES, resp.data.user.roles || []);

            return true
            // } catch (e) {
            //     throw new Error('### NO AUTH')
            // }
        },
        authLogout: async ({commit, getters}) => {
            try {
                //const resp = await api.auth.logout(getters.getAuthData);
                commit(mutypes.AUTH_LOGOUT);
                return true
            } catch (e) {
                throw new Error('### NO AUTH')
            }
        },
    },

    getters: {
        getAuthToken: state => state.auth.token,
        getAuthUserEmail: state => state.user.email,
        getUserName: state => state.user.name,
        getAuthData: state => state.auth.data,
        getAuthDataEmail: state => state.auth.data.email,
        getAuthDataPassword: state => state.auth.data.password,
        getOrganizersForUser: state => state.organizers,
        getUserEmail: state => state.email,
        getUserRoles: state => state.roles
    },
}

