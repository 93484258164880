<template>
  <div>
    <Breadcrumbs main="Модераторы" title="Список модераторов"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header d-flex align-items-center justify-content-between flex-wrap">
              <div>
                <b-input-group>
                  <b-btn variant="primary" @click.prevent="getEmailBySearch">Поиск</b-btn>
                  <b-input v-model="emailForSearch" placeholder="Введите email" type="email"></b-input>
                </b-input-group>
              </div>
              <div>
                <b-btn @click="$router.push({name: 'roles'})" variant="success">
                  Роли
                  <i class="fa fa-users ml-1"></i></b-btn>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive datatable-vue">
                <b-table
                    ref="table"
                    show-empty
                    stacked="md"
                    :items="organizerUsers"
                    :fields="tablefields"
                    :current-page=0
                    :per-page=10
                >
                  <template v-slot:cell(actions)="{ item }">
                    <div class="d-flex flex-wrap align-items-center">
                      <router-link
                          :to="{ name: 'organizer_users_edit', params: { id: item.id }}"
                      ><span>
                      <b-btn variant="primary" class="mr-2 p-1 px-2 mb-1"><i class="fa fa-edit"></i></b-btn>
                    </span>
                      </router-link>
                    </div>
                  </template>
                  <template v-slot:cell(roles)="{item}">
                    {{ item.roles[0]?.name }}
                  </template>

                </b-table>
              </div>

              <b-col md="6" class="p-0">
                <b-pagination
                    @change="handlePageChange"
                    v-model="organizerCurrentPage"
                    :total-rows="organizerTotal"
                    :per-page="10"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import {OrganizerUserAdapterMixin,} from "../../mixins/Organizer/OrganizerUsersAdapterMixin";
import {AuthAdapterMixin} from "../../mixins/Auth/AuthAdapterMixin";

export default {
  name: "ListOrganizerUsers",
  mixins: [OrganizerUserAdapterMixin, AuthAdapterMixin],
  created() {
    // this.grabOrganizerUsers({
    //   //@TODO ВАЖНО ПРОРАБОТАТЬ ИНТЕРФЕЙС ЧТОБЫ ПОЛЬЗОВАТЕЛЬ МОГ УПРАВЛЯТЬ ОДНОВРЕМЕННО НЕСКОЛЬКИМИ ОРГАНИЗАЦИЯМИ
    //   organizerId: this.organizationsForUser[0].id
    // });
    this.grabOrganizerUsers({page: 1})
  },
  data() {
    return {
      tablefields: [
        {key: 'id', label: 'id', sortable: true,},
        {key: 'email', label: 'email', sortable: true,},
        {key: 'username', label: 'name', sortable: true,},
        {key: 'roles', label: 'роль', sortable: true,},
        {key: 'actions', label: 'действия', sortable: true,},
      ],
      totalRows: 10,
      emailForSearch: "",
    };
  },
  methods: {
    getEmailBySearch() {
      this.grabOrganizerUsers({search: this.emailForSearch})
    },
    async handlePageChange(page) {
      await this.grabOrganizerUsers({page: page, email: this.emailForSearch})
    }
  },
}
</script>

<style scoped>

</style>