<template>
  <div>
    <b-form-input
        type="number"
        @change="addPlayerRes({
                          id:$props.id,
                          user_id:$props.user_id,
                          team_id:$props.team_id,
                          match_id:$props.match_id,
                        })"
        v-model="killPts"
    >

    </b-form-input>
  </div>

</template>

<script>
import {TournamentGroupResultAdapterMixin} from "../mixins/TournamentGroupResult/TournamentGroupResultAdapterMixin";

export default {
  name: "kill_pts_input",
  mixins: [TournamentGroupResultAdapterMixin],
  data() {
    return {
      killPts:0,
    };
  },
  created() {
    this.killPts = this.$props.kill_pts;
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
    user_id: {
      type: Number,
      default: 0
    },
    team_id: {
      type: Number,
      default: 0
    },
    match_id: {
      type: Number,
      default: 0
    },
    kill_pts: {
      type: Number,
      default: 0
    }
  },
  methods: {
    addPlayerRes(params) {
      console.log(params);
      this.addPlayerResult({
        id:params.id,
        user_id:params.user_id,
        team_id:params.team_id,
        match_id:params.match_id,
        kill_pts:Number.parseInt(this.killPts)
      })
    }
  }
}
</script>

<style scoped>

</style>