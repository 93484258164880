import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin';

export default {
    /**
     * POST: ban
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    store: (params) => adminHttp.post(END_POINT + '/ban/user/store',params),
    
    /**
     * GET: ban
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    index: (params) => adminHttp.get(END_POINT + '/user/banned', {params: params}),
    
    /**
     * DELETE: ban
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete: (id) => adminHttp.delete(END_POINT + '/ban' + `/${id}`),
    
    /**
     * GET: ban
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    show: (id) => adminHttp.get(END_POINT + '/user/ban' + `/${id}`)
}