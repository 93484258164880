<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Рейтинг" title="История рейтинга команды"/>
      <b-card>
        <div class="d-flex">
          <SearchInput v-model="searchForTeamRatingInfoModel" @search="getFilteredTeamsRatingInfo()" :placeholder-name="'Поиск турниров'"/>
        </div>
        <div class="notification-for-search mb-4">Поиск по id и названию турнира</div>
        <div>
          <b-form>
            <div>
              <b-table
                  table-variant="secondary"
                  responsive
                  show-empty
                  stacked="md"
                  :striped="striped"
                  :bordered="bordered"
                  :fields="fields"
                  :items="teamRatingsShow"
                  :current-page=0
                  :per-page=10
                  class="mb-5"
              >
                <template v-slot:cell(id)="{item}">
                  <div>{{ item.id }}</div>
                </template>
                <template v-slot:cell(name)="{item}">
                  <div>{{ item.name }}</div>
                </template>
                <template v-slot:cell(description)="{ item }">
                  <div v-for="{description, id} in item.teamRatings" :key="id">
                    <div>{{ description }}</div>
                  </div>
                </template>
                <template v-slot:cell(rating)="{item}">
                  <div v-for="{points, id,is_actual} in item.teamRatings" :key="id">
                    <div
                        :class="{'text-danger':!is_actual} "
                    >{{ points.toFixed(2) }}
                    </div>
                  </div>
                </template>
                <template v-slot:cell(total)="{item}">
                  <div>{{ item.sum_points.toFixed(2) }}</div>
                </template>
                <template v-slot:cell(created_at)="{item}">
                  <div v-for="{created_at, id} in item.teamRatings" :key="id">
                    <div>{{ created_at }}</div>
                  </div>
                </template>
              </b-table>
              <b-col md="6" class="p-0">
                <b-pagination
                    @change="handlePageChange"
                    v-model="currentPageShow"
                    :total-rows="totalShow"
                    :per-page="10"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </div>
          </b-form>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {RatingAdapterMixin} from "../../mixins/Rating/RatingAdapterMixin";
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: "TeamsRatingInfo",
  components: {SearchInput},
  mixins: [RatingAdapterMixin, ModalAdapterMixin, PaginationHelperMixin],
  created() {
    this.recordPageToStorage(this.currentPageShow, this.pageKey)
    this.showTeamRatings({
      pageNumber: this.getCurrentPageNumber(this.pageKey),
      id: this.$route.params.id,
    })
  },
  data() {
    return {
      searchForTeamRatingInfoModel: '',
      pageKey: 'ratingShowPageNumber',
      fields: [
        {key: 'id', label: 'id', sortable: true},
        {key: 'name', label: 'Турнир', sortable: true},
        {key: 'description', label: 'Описание', sortable: false},
        {key: 'rating', label: 'Рейтинг', sortable: true},
        {key: 'total', label: 'Общий рейтинг', sortable: true},
        {key: 'created_at', label: 'Дата создания', sortable: false},
      ],
      striped: true,
      bordered: true,
    };
  },
  methods: {
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.showTeamRatings({
        pageNumber: pageNumber,
        id: this.$route.params.id,
        ...(this.searchForTeamRatingInfoModel && {search: this.searchForTeamRatingInfoModel}),
      })
    },
    getFilteredTeamsRatingInfo() {
      this.showTeamRatings({id: this.$route.params.id, search: this.searchForTeamRatingInfoModel})
    },
  }
};
</script>