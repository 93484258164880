<template>
  <div>
    <div class="">
      <div class="d-flex flex-wrap">
        <div class="mr-2">
          <label>Место</label>
          <b-form-input :value="$props.place"
                        disabled
          ></b-form-input>
        </div>
        <div class="mr-2">
          <label>Очки</label>
          <b-form-input @change="onChange($event)"
                        :value="$props.place_pts"
                        placeholder="Введите количество очков"></b-form-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {TournamentAdapterMixin} from "@/mixins/Tournament/TournamentAdapterMixin";

export default {
  name: 'AddTournamentPlaceForm',
  mixins: [TournamentAdapterMixin],
  props: {
    place: {
      type: Number,
      default: 0,
    },
    place_pts: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onChange(value) {
      this.changeTournamentPlace({
        place: this.$props.place,
        place_pts: Number.parseInt(value)
      })
    }
  },
}
</script>
