<template>
  <div class="sidebar-block-wrapper">
    <header :class="[ headerBetween && 'justify-between', $slots.body && 'mb-2' ]">
      <slot v-if="$slots.header" name="header" />
    </header>
    <div class="body" v-if="$slots.body">
      <slot name="body"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarBlock",
  props: {
    headerBetween: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.sidebar-block-wrapper::v-deep {

  * {
    margin: 0;
  }

  // Item title
  .block-name {
    font-size: 12px;
    color: #8080809c;
    display: block;

    &.right {
      text-align: right;
    }
  }

  // Tournament title styles
  .tournament-title {
    font-size: 18px;
  }

  header {
    &.justify-between {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .action-button {
      display: block;
      font-size: 11px;
      border-radius: 10px;
      padding: 5px 13px 5px 13px;
      background: #7366ff;
      color: white;
      border: none;
    }
  }

}
</style>