import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const TournamentGroupResultAdapterMixin = {
    computed: {
        ...mapGetters({
            getGroupResultMatches         : 'getGroupResultMatches',
            getGroupMatchResults          : 'getGroupMatchResults',
            getSelectedMatchId            : 'getSelectedMatchId',
            getResultTeamId               : 'getResultTeamId',
            getResultPlacePts             : 'getResultTeamPlacePts'
        }),
        matches: {get() { return this.getGroupResultMatches}, set(value) {this.setGroupResultMatches(value)}},
        selectedMatchId: {get() { return this.getSelectedMatchId}, set(value) {this.setGroupResultMatchId(value)}},
        matchResults: {get() { return this.getGroupMatchResults}, set(value) {this.setGroupMatchResults(value)}},
        resultTeamId: {get() { return this.getResultTeamId}, set(value) {this.setGroupMatchTeamId(value)}},
        resultPlacePts: {get() { return this.getResultPlacePts}, set(value) {this.setResultTeamPlacePts(value)}},
    },
    methods: {
        ...mapMutations({
            setGroupResultMatches: mutypes.SET_TOURNAMENT_GROUP_RESULT_MATCHES,
            setGroupResultMatchId: mutypes.SELECT_TOURNAMENT_GROUP_RESULT_MATCH_ID,
            setGroupMatchResults : mutypes.SET_TOURNAMENT_GROUP_MATCH_RESULTS,
            setGroupMatchTeamId  : mutypes.SET_TOURNAMENT_GROUP_MATCH_RESULT_TEAM_ID,
            setResultTeamPlacePts: mutypes.SET_TOURNAMENT_GROUP_RESULT_PlACE_PTS,
        }),
        ...mapActions([
            'showMatchesResult',
            'clearMatchesResultState',
            'addPlayerResult',
            'addResultTeamPlacePts',
            'storeMatchesResult'
        ]),
    },
}