<template>
  <tr class="body">
    <td class="match-name">{{ $props.matchData.match.name }}</td>
    <td class="action-button-cell">
      <b-button :variant="isWinner ? 'success' : 'light'"
                class="action-button"
                @click="sendMatchResToTeam({matchId: $props.matchData.match.id, firstTeamId: selectedTeamId, secondTeamId: secondTeamId})">
        W
      </b-button>
    </td>
    <td class="action-button-cell">
      <b-button :variant="isLose ? 'warning' : 'light'"
                class="action-button"
                @click="sendMatchResToTeam({matchId: $props.matchData.match.id, firstTeamId: secondTeamId, secondTeamId: selectedTeamId})">
        L
      </b-button>
    </td>
    <td class="action-button-cell">
      <b-button variant="danger"
                class="action-button"
                @click="deleteMatch($props.matchData.match.id)">
        <i class="fa fa-trash" style="font-size: 16px"></i>
      </b-button>
    </td>
  </tr>
</template>

<script>
import api from '/src/api/api'
import {mapState} from "vuex";

export default {
  name: "RowInOlympicMatch",
  props: {
    matchData: {
      type: Object,
      default: null
    },
    pair: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isLoadingSendingRes: false,
      isLoadingDeletingMatch: false
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['selectedTeamId', 'selectedGroupId']),
    isWinner() {
      const currentTeam = this.$props.matchData.match?.group_match_result.find(teamData => teamData.team_id === this.selectedTeamId);
      return currentTeam?.result_fields?.is_winner;
    },
    isLose() {
      const currentTeam = this.$props.matchData.match?.group_match_result.find(teamData => teamData.team_id === this.selectedTeamId);
      return currentTeam && currentTeam.result_fields && currentTeam.result_fields.is_winner === 0;
    },
    secondTeamId() {
      return this.$props.pair.first_team_id !== this.selectedTeamId ? this.$props.pair.first_team_id : this.$props.pair.second_team_id;
    }
  },
  methods: {
    async sendMatchResToTeam({matchId, firstTeamId, secondTeamId}) {
      try {
        this.isLoadingSendingRes = true
        await api.tournament.storeMatchResInOlympic({
          match_id: matchId,
          winner_team_id: firstTeamId,
          lose_team_id: secondTeamId,
        })
        await this.$store.dispatch('moduleNewTournament/getOlympicMatches', {
          groupId: this.selectedGroupId,
          teamId: this.selectedTeamId
        })
      } catch (error) {
        this.$emit('error', error.response.data.message || 'Не удалось отправить результаты матча');
      } finally {
        this.isLoadingSendingRes = false
      }
    },
    async deleteMatch(matchId) {
      try {
        this.isLoadingDeletingMatch = true
        await api.tournament.deleteOlympicMatch(matchId)
        await this.$store.dispatch('moduleNewTournament/getOlympicMatches', {
          groupId: this.selectedGroupId,
          teamId: this.selectedTeamId
        })
      } catch (error) {
        this.$emit('error', error.response.data.message || 'Не удалось удалить матч');
      } finally {
        this.isLoadingDeletingMatch = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.body {

  .match-name {
    max-width: 200px;
  }

  .action-button-cell {
    text-align: center;
  }

  .action-button {
    border-radius: 10px;
    padding: 5px 10px;;
  }

  td {
    padding: 5px;
  }
}

</style>