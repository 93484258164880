<template>
  <div>
    <Breadcrumbs main="Турниры" :title="`Редактирование этапа для ${name}`"/>
    <b-form>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Название этапа <span class="text-danger">*</span></label>
        <ValidationProvider rules="min:2|required" v-slot="{errors}">
        <b-form-input v-model="name"></b-form-input>
          <div class="text-danger">{{errors[0]}}</div>
        </ValidationProvider>
      </div>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Коэффициент этапа - Рейтинг команд <span class="text-danger">*</span></label>
        <ValidationProvider rules="required" v-slot="{errors}">
        <b-form-input type="number" v-model="rating"></b-form-input>
          <div class="text-danger">{{errors[0]}}</div>
        </ValidationProvider>
      </div>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Коэффициент этапа - Рейтинг пользователя <span class="text-danger">*</span></label>
        <ValidationProvider rules="required" v-slot="{errors}">
          <b-form-input type="number" v-model="personalRatingUpdate"></b-form-input>
          <div class="text-danger">{{errors[0]}}</div>
        </ValidationProvider>
      </div>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Кол-во замен <span class="text-danger">*</span></label>
        <b-form-input type="number" v-model="replacement"></b-form-input>
      </div>


      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Cтатус <span class="text-danger">*</span></label>
        <ValidationProvider rules="required" v-slot="{errors}">
          <b-form-select v-model="status.value">

            <option
                v-for="statusStage in newStatusOptions"
                :key="'groupStatus-' + statusStage.value"
                :value="statusStage.value">{{statusStage.text}}
            </option>
          </b-form-select>
          <div class="text-danger">{{errors[0]}}</div>
        </ValidationProvider>
      </div>
      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Минимальной значение рейтинга для подачи в этап</label>
        <ValidationProvider rules="required" v-slot="{errors}">
          <b-form-input type="number" v-model="thresholdRatingUpdate"></b-form-input>
          <div class="text-danger">{{errors[0]}}</div>
        </ValidationProvider>
      </div>

      <div class="mb-2 pl-2 pr-2">
        <label class="font-weight-bold">Кол-во команд, разрешенное для подачи в этап</label>
        <div class="mb-3" v-for="division in divisionsUpdate" :key="division.id">
          <div class="grid-division">
            <div>{{ division['division_name'] }}</div>
            <div>
              <ValidationProvider rules="required" v-slot="{errors}">
                <b-form-input type="number" v-model="division['slot_count']"></b-form-input>
                <div class="text-danger">{{errors[0]}}</div>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center flex-wrap">
        <button class="btn btn-success mr-3 mt-2" @click.prevent="updateTournamentStageClick" :disabled="name === '' || rating === ''">Сохранить</button>
        <button class="btn btn-primary mt-2 mr-3" @click.prevent="$router.go(-1)">Назад</button>
      </div>
    </b-form>
  </div>
</template>

<script>
import {TournamentStageAdapterMixin} from "../../../mixins/Tournament/TournamentStageAdapterMixin";
import {TournamentStandingsAdapterMixin} from "@/mixins/Tournament/TournamentStandingsAdapterMixin";


export default {
  name: 'CreateTournamentStage',
  mixins: [TournamentStageAdapterMixin, TournamentStandingsAdapterMixin],
  mounted() {
    this.showTournamentStageLoad()
  }
}
</script>
