import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const UserBanAdapterMixin = {
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            getUsersBan: 'getUsersBan',
            getBanGamesByUser: 'getBanGamesByUser',
            getBanGameIdByUser: 'getBanGameIdByUser',
            getBanUsersPaginationTotal: 'getBanUsersPaginationTotal',
            getBanUsersCurrentPage: 'getBanUsersPaginationCurrentPage',
            getUsersBansInfo: 'getUsersBansInfo',
        }),
        usersBanList: {
            get() {
                return this.getUsersBan
            }, set(value) {
                this.setUsersBan(value)
            }
        },
        banGamesByUser: {
            get() {
                return this.getBanGamesByUser
            }, set(value) {
                this.setBanGamesByUser(value)
            }
        },
        banGameIdByUser: {
            get() {
                return this.getBanGameIdByUser
            }, set(value) {
                this.setBanGameIdByUser(value)
            }
        },
        totalBanUsersPagination: {
            get() {
                return this.getBanUsersPaginationTotal
            }, set(value) {
                this.setBanUsersPaginationTotal(value)
            }
        },
        banUsersCurrentPage: {
            get() {
                return this.getBanUsersCurrentPage
            }, set(value) {
                this.setBanUsersCurrentPage(value)
            }
        },
        userBansList: {
            get() {
                return this.getUsersBansInfo
            }, set(value) {
                this.setUsersBansInfo(value)
            }
        },
    },
    methods: {
        ...mapMutations({
            setUsersBan: mutypes.SET_USER_BAN,
            setBanGamesByUser: mutypes.SET_GAMES,
            setBanGameIdByUser: mutypes.SET_GAME_ID,
            setBanUsersPaginationTotal: mutypes.SET_BAN_USERS_PAGINATION_TOTAL,
            setBanUsersCurrentPage: mutypes.SET_BAN_USERS_PAGINATION_CURRENT_PAGE,
            setUsersBansInfo: mutypes.SET_USER_SHOW_BANS,
        }),
        ...mapActions([
            'indexUsersBan',
            'grabUserGames',
            'deleteUserBan',
            'showUsersBan'
        ]),
        async showBansInfo(id) {
            this.$router.push({name: 'bans_info', params: {id: id}})
        }
    },
}