<template>
  <div>
    <b-modal id="changeMatch" v-model="show" centered>
      <div>Вы действительно хотите удалить этот матч?</div>
      <template #modal-footer>
        <div>
          <b-btn variant="primary" class="mr-3" @click="deleteMatchForm">Удалить</b-btn>
          <b-btn variant="danger" @click="show = false">Отмена</b-btn>
        </div>
      </template>
    </b-modal>
  <div class="container-fluid">
    <h1>Новый матч</h1>
    <b-form>
      <div class="d-flex flex-wrap">
        <div class="mr-2">
          <label>Название матча</label>
          <ValidationProvider rules="min:2|required" v-slot="{errors}">
          <b-form-input @input="onChange(name,'name')" v-model="name"
          placeholder="Введите имя матча"/>
            <div class="text-danger">{{errors[0]}}</div>
          </ValidationProvider>
        </div>

        <div class="mr-2">
          <label>Lobby number</label>
          <ValidationProvider rules="min:2|required" v-slot="{errors}">
          <b-form-input @input="onChange(login,'lobby_number')" v-model="login"
                        placeholder="Введите номер лобби"></b-form-input>
            <div class="text-danger">{{errors[0]}}</div>
          </ValidationProvider>
        </div>

        <div class="mr-2 ">
          <label>Password</label>
          <ValidationProvider rules="min:2|required" v-slot="{errors}">
          <b-form-input @input="onChange(password,'password')" v-model="password"
                        placeholder="Введите пароль матча"></b-form-input>
            <div class="text-danger">{{errors[0]}}</div>
          </ValidationProvider>
        </div>

        <div class="mr-2">
          <label>Дата</label>
            <DatePicker @input="onChange(date,'start_time')" v-model="date" mode="dateTime"  is24hr :model-config="modelConfig" :popover="{visibility: 'click'}">
              <template v-slot="{inputValue, inputEvents}">
                <b-form-input :value="inputValue" v-on="inputEvents"/>
              </template>
            </DatePicker>
        </div>


<!--        <div class="mr-2">-->
<!--          <label>Cтатус</label>-->
<!--            <b-form-input type="number" @input="onChange(status,'status')" v-model="status"-->
<!--            placeholder="Введите статус"/>-->
<!--        </div>-->

        <div class="">
          <div class="mb-1 mt-1">Удаление</div>
          <b-btn variant="danger" type="button" v-b-modal="'changeMatch'" class="m-0 px-1">Удалить матч</b-btn>
        </div>
        </div>
    </b-form>
  </div>
  </div>
</template>

<script>
import {TournamentMatchAdapterMixin} from "../../../mixins/TournamentMatch/TournamentMatchAdapterMixin";
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'ChangeTournamentMatchForms',
  mixins: [TournamentMatchAdapterMixin],
  components: {DatePicker},
  props: {
    index: {
      type: Number,
      default: 0
    },
    groupId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      show: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
    }
  },
  methods: {
    deleteMatchForm() {
      this.show = false
      this.$emit('deleteMatchForm')
    },
    onChange(val, resKey) {
      let data = {};
      data[resKey] = val;
      data['index'] = this.$props.index;
      data['group_id'] = this.groupId
      data['key'] = resKey;
      this.$emit('changeMatch',data);
    }
  },
}
</script>

<style scoped>
.btn-danger {
  height: 40px;
}
</style>