import RuIcon from '@/assets/images/ru.svg'
import EnIcon from '@/assets/images/usa.svg'
import UzIcon from '@/assets/images/uz.svg'
import KzIcon from '@/assets/images/kz.png'

export const LanguageAdapterMixin = {
    data() {
        return {
            language: 'ru',
            languages: [
                {id: 1, language: 'RU', img: RuIcon},
                {id: 2, language: 'EN', img: EnIcon},
                {id: 3, language: 'UZ', img: UzIcon},
                {id: 4, language: 'KZ', img: KzIcon}
            ]
        }
    },
    methods: {
        getLanguageFromBtn(language) {
            this.language = language
        }
    },
}