<template>
  <div>
    <div class="authpage__bg"></div>
    <div class="authpage__wave"></div>
    <div class="authpage__wave"></div>
    <div class="authpage__wave"></div>
    <div class="authpage__wrapper">
      <div class="text-center">
        <h1>
          <a class="navbar-brand title text-uppercase logo-indent-mr text-dark px-2"
             href="https://bigplay.gg">bigplay</a>
        </h1>

      </div>
      <b-form method="POST" action="https://bigplay.gg/login">
        <div class="row justify-content-center">
          <input type="hidden" name="_token" value="HSNomLZpbRhGalzczxGg3DZdDEN187Jj2iOLC7FH">
          <ValidationProvider name="email" rules="required|email" v-slot="{ errors }" class="w-100 pl-4 pr-4 block m-auto">
          <b-form-input type="text" v-model="authDataEmail" name="email" :class="error ? 'def w-100 border-danger' : 'def w-100'" placeholder="Email" value=""/>
            <div class="text-danger">{{errors[0]}}</div>
          </ValidationProvider>
          <ValidationProvider name="password" rules="required" v-slot="{errors}" class="w-100 pl-4 pr-4 block m-auto">
          <b-input v-model="authDataPassword" type="password" name="password" :class="error ? 'def w-100 border-danger' : 'def w-100'" placeholder="Пароль"/>
            <div class="text-danger">{{errors[0]}}</div>
          </ValidationProvider>
        </div>

        <p class="recover">
          <a href="https://bigplay.gg/password/reset">Забыли пароль?</a>
        </p>
        <b-btn @click="login" variant="primary" style="width: 90%" :disabled="authDataEmail === '' || authDataPassword === ''">Войти</b-btn>
        <div class="text-danger">{{error}}</div>

      </b-form>

      <div class="or">

      </div>
    </div>
  </div>
</template>

<script>
import {AuthAdapterMixin} from "../../mixins/Auth/AuthAdapterMixin";


export default {
  name: 'AuthPage',
  mixins: [AuthAdapterMixin],
  methods:{
    async login() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('login')
      await this.authLogin(token).then(()=>{
        this.$router.push('/');
      }).catch((err)=>{
        this.error = 'Пароль или почта указаны неверно'
      })
    },
  }
}
</script>

<style scoped>


.def {
  width: 85%;
  outline: none;
  background: #dfe9f5;
  margin-bottom: 10px;
  border-radius: 10px;
}

.recover {
  text-align: right;
  font-size: 0.8rem;
  margin: 0.2rem 1.7rem 0 0;
}

.or {
  font-size: 0.8rem;
  margin-top: 1.5rem;
}

.icons i {
  padding: 0.8rem 1.5rem;
  border-radius: 10px;
  margin-left: 0.9rem;
  font-size: 1.5rem;
  cursor: pointer;
  border: 2px solid #dfe9f5;
}

.fa-google {
  color: #db4a39;
}


.authpage__bg {
   animation: slide 5s ease-in-out infinite alternate;
   background-image: linear-gradient(-60deg, #3e1b8b 50%, #23993d 50%);
   bottom: 0;
   left: -50%;
   opacity: .5;
   position: fixed;
   right: -50%;
   top: 0;
   z-index: -1;
 }

.authpage__bg2 {
   animation-direction: alternate-reverse;
   animation-duration: 7s;
 }

.authpage__bg3 {
   animation-duration: 8s;
 }

.authpage__wrapper {
   width: 330px;
   padding: 2rem 0 1rem 0;
   margin: 50px auto;
   background: #fff;
   border-radius: 10px;
   text-align: center;
   box-shadow: 0 20px 35px rgb(0 0 0 / 10%);
 }

.authpage__bg {
   margin: auto;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
   overflow: auto;
   background: linear-gradient(315deg, rgba(101,0,94,1) 3%, rgba(60,132,206,1) 38%, rgba(48,238,226,1) 68%, rgba(255,25,25,1) 98%);
   animation: gradient 15s ease infinite;
   background-size: 400% 400%;
   background-attachment: fixed;
 }

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.authpage__wave {
   background: rgb(255 255 255 / 25%);
   border-radius: 1000% 1000% 0 0;
   position: fixed;
   width: 200%;
   height: 12em;
   animation: wave 10s -3s linear infinite;
   transform: translate3d(0, 0, 0);
   opacity: 0.8;
   bottom: 0;
   left: 0;
   z-index: -1;
 }

.authpage__wave:nth-of-type(2) {
   bottom: -1.25em;
   animation: wave 18s linear reverse infinite;
   opacity: 0.8;
 }

.authpage__wave:nth-of-type(3) {
   bottom: -2.5em;
   animation: wave 20s -1s reverse infinite;
   opacity: 0.9;
 }

@keyframes authpage__wave {
  2% {
    transform: translateX(1);
  }

  25% {
    transform: translateX(-25%);
  }

  50% {
    transform: translateX(-50%);
  }

  75% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(1);
  }
}

</style>