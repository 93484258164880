<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Удаленные команды" title="Список удаленных команд"/>
      <b-card>
        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <SearchInput v-model="searchForTeamsModel"
                       @search="getFilteredTrashedTeams()"
                       :placeholder-name="'Поиск команд'"/>
          <b-dropdown variant="primary" id="dropdown-1" text="Дисциплина" class="mt-2">
            <b-dropdown-item @click="getTrashedTeamsByGame()">
              <div class="text-dark">Все</div>
            </b-dropdown-item>
            <b-dropdown-item
                v-for="(game, index) in games" :key="index + 'game-' + game.value"
                @click="getTrashedTeamsByGame(game.value)"
                variant="primary"
            >
              <div class="text-dark">
                {{ game.text }}
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="notification-for-search mb-4">Поиск по id, названию команды, почте и username капитана</div>
        <div v-if="isLoadingTeams" class="loading-wrapper">
          <b-spinner class="spinner"></b-spinner>
        </div>
        <b-table
            v-else
            responsive
            ref="table"
            show-empty
            stacked="md"
            :striped="striped"
            :bordered="bordered"
            :items="deletedTeams"
            :fields="fields"
            :current-page=0
            :per-page=10
            class="mb-5"
            key="deletedTeams"
        >
          <template #cell(team)="teamData">
            {{ teamData.item.name }}
          </template>
          <template #cell(participants)="teamData">
            <div class="text-center">
              {{ teamData.item.member_count || 0 }}
            </div>
          </template>
          <template #cell(tournament_count)="teamData">
            <div class="text-center">
               {{ teamData.item.tournaments_count || 0 }}
            </div>
          </template>
          <template #cell(game_title)="teamData">
            {{ teamData.item.game.name }}
          </template>
          <template #cell(deleted_date)="teamData">
            {{ teamData.item.deleted_at ? moment(teamData.item.deleted_at, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY HH:mm') : '' }}
          </template>
          <template #cell(action)="teamData">
            <div class="row justify-content-center">
              <b-btn variant="primary" class="mr-1 p-1" @click="showTeamInfo(teamData.item.id)">
                <i class="fa fa-eye"></i>
              </b-btn>
            </div>
          </template>
        </b-table>
        <b-col md="6" class="p-0">
          <b-pagination
              @change="handlePageChange"
              v-model="deletedTeamsCurrentPage"
              :total-rows="totalDeletedTeams"
              :per-page="10"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-card>
    </div>
  </div>
</template>

<script>
import {TeamsAdapterMixin} from "@/mixins/Teams/TeamsAdapterMixin";
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import moment from 'moment'
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: 'TrashedTeams',
  components: {SearchInput},
  mixins: [TeamsAdapterMixin, ModalAdapterMixin, PaginationHelperMixin],
  mounted() {
    (async () => {
      Promise.all([
        this.recordPageToStorage(this.deletedTeamsCurrentPage, this.pageKey),
        await this.trashedTeams({
          pageNumber: this.getCurrentPageNumber(this.pageKey),
        }),
        await this.grabGamesForTeams(),
        await this.getGameIds()
      ])
    })()
  },
  data() {
    return {
      moment,
      pageKey: 'pageNumberTrashedTeams',
      fields: [
        {key: 'id', label: '№', sortable: true},
        {key: 'team', label: 'Команды', sortable: true},
        {key: 'participants', label: 'Участники (всего/игр)', sortable: true},
        {key: 'tournament_count', label: 'Количество турниров', sortable: true},
        {key: 'game_title', label: 'Название игры', sortable: true},
        {key: 'deleted_date', label: 'Дата удаления', sortable: true},
        {key: 'action', label: 'Инструменты', sortable: false},
      ],
      striped: true,
      bordered: true,
      gameIds: []
    }
  },
  methods: {
    getGameIds() {
      this.gameIds = this.games.map((game) => game.value)
    },
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.trashedTeams({
            pageNumber: pageNumber,
            ...(this.searchForTeamsModel && {search: this.searchForTeamsModel}),
            ...(this.gameIds.includes(this.selectedGameId) && {gameId: this.selectedGameId})
          },
      )
    },
    getFilteredTrashedTeams() {
      this.isLoadingTeams = true
      this.trashedTeams({
        page: localStorage.getItem('pageNumberTrashedTeams'),
        search: this.searchForTeamsModel,
        ...(this.gameIds.includes(this.selectedGameId) && {gameId: this.selectedGameId})
      })
          .then(() => {
            this.isLoadingTeams = false
          })
    },
  },

}
</script>
