import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const UsersRatingAdapterMixin = {
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            getUsersRating: 'getUsersRating',
            getPaginationTotal: 'getUsersRatingPaginationTotal',
            getCurrentPage: 'getUsersRatingPaginationCurrentPage',
            getUserRatingsShow: 'getUserRatingsShow',
            getPaginationTotalShow: 'getUserRatingsShowPaginationTotal',
            getCurrentPageShow: 'getUserRatingsShowPaginationCurrentPage',
        }),
        usersRating: {
            get() {
                return this.getUsersRating
            }, set(value) {
                this.setUsersRating(value)
            }
        },
        total: {
            get() {
                return this.getPaginationTotal
            }, set(value) {
                this.setPaginationTotal(value)
            }
        },
        currentPage: {
            get() {
                return this.getCurrentPage
            }, set(value) {
                this.setCurrentPage(value)
            }
        },
        userRatingsShow: {
            get() {
                return this.getUserRatingsShow
            }, set(value) {
                this.setUserRatingsShow(value)
            }
        },
        totalShow: {
            get() {
                return this.getPaginationTotalShow
            }, set(value) {
                this.setPaginationTotalShow(value)
            }
        },
        currentPageShow: {
            get() {
                return this.getCurrentPageShow
            }, set(value) {
                this.setCurrentPageShow(value)
            }
        },
    },
    methods: {
        ...mapMutations({
            setUsersRating: mutypes.SET_USERS_RATING,
            setPaginationTotal: mutypes.SET_USERS_RATING_PAGINATION_TOTAL,
            setCurrentPage: mutypes.SET_USERS_RATING_PAGINATION_CURRENT_PAGE,
            setUserRatingsShow: mutypes.SET_USER_RATINGS_SHOW,
            setPaginationTotalShow: mutypes.SET_USER_RATINGS_SHOW_PAGINATION_TOTAL,
            setCurrentPageShow: mutypes.SET_USER_RATINGS_SHOW_PAGINATION_CURRENT_PAGE,
        }),
        ...mapActions([
            'grabUsersRating',
            'showUserRatings',
        ]),
    }
}