import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const TournamentOuterAdapterMixin = {
    data() {
        return {
            newTournamentName: '',
            newStartReg: '',
            newEndReg: '',
            newStartTournament: '',
            newRules_ru: '',
            newRules_en: '',
            newRules_uz: '',
            newRules: {
                ru: '',
                en: '',
                uz: '',
            },
            newDescription: {
                ru: '',
                en: '',
                uz: '',
                kz: '',
            },
            newSchedule: {
                ru: '',
                en: '',
                uz: '',
            },
            newMetaTitle: '',
            newMetaDescription: '',
            newPrizeFond: '',
            newSlotCount: '',
            newFormats: null,
            newTimezone: null,
            values: [],
            newStatus: null,
            newStatusOptions: [
                {value: 2, text: 'Registration'},
                {value: 3, text: 'Game'},
                {value: 1, text: 'Registration not started'},
                {value: 4, text: 'Ended'}
            ],
            errorMessage: '',
            newImage: null,
        }
    },
    computed: {
        ...mapGetters({
            getGames        : 'getTournamentOuterGames',
            getGameId       : 'getTournamentOuterGameId',
            getTournamentOuterOrganizers   : 'getTournamentOuterOrganizers',
            getOrganizerId  : 'getTournamentOuterOrganizerId',
            getName         : 'getTournamentName',
            getStartTime    : 'getTournamentOuterStartTime',
            getEndRegTime   : 'getTournamentOuterEndRegTime',
            getStartRegTime : 'getTournamentOuterStartRegTime',
            getRules        : 'getTournamentOuterRules',
            getDescription  : 'getTournamentOuterDescription',
            getTournamentsOuter     : 'getTournamentsOuter',
            getTournamentsOuterPaginationTotal : 'getTournamentsOuterPaginationTotal',
            getTournamentsOuterPaginationCurrentPage     : 'getTournamentsOuterPaginationCurrentPage',
            getTournament      : 'getTournament',
            getPrizeFond       : 'getTournamentOuterPrize',
            getFormats         : 'getTournamentsFormats',
            getTimezones       : 'getTimezones',
            getSlotCount       : 'getTournamentOuterSlotCount',
            getTournamentFormats : 'getTournamentFormats',
            getTournamentTimezone : 'getTournamentTimezone',
            getTournamentCurrentFormats : 'getTournamentCurrentFormats',
            getTournamentCurrentStatus : 'getTournamentCurrentStatus',
            getTournamentSchedule : 'getTournamentSchedule',
            getTournamentCurrentImage: 'getTournamentCurrentImage',
            getTournamentCurrentSlug: 'getTournamentCurrentSlug',

        }),
        tournament        : { get(){return this.getTournament            }, set(value){this.setTournament(value)}},
        games             : {get() {return this.getGames                 }, set(value) {this.setGames(value)       }},
        gameId            : {get() {return this.getGameId             }, set(value) {this.setGameId(value)      }},
        organizersOuter        : {get() {return this.getTournamentOuterOrganizers         }, set(value) {this.setTournamentOuterOrganizers(value)  }},
        organizerId       : {get() {return this.getOrganizerId             }, set(value) {this.setOrganizerId(value)  }},
        endRegTime        : {get() {return this.getEndRegTime             }, set(value) {this.setEndRegTime(value)  }},
        startRegTime      : {get() {return this.getStartRegTime             }, set(value) {this.setRegTime(value)  }},
        name              : {get() {return this.getName             }, set(value) {this.setName(value)  }},
        startTime         : {get() {return this.getStartTime             }, set(value) {this.setStartTime(value)  }},
        rules          : {get() {return this.getRules             }, set(value) {this.setRules(value)  }},
        description    : {get() {return this.getDescription             }, set(value) {this.setDescription(value)  }},
        tournamentsOuter       : {get() {return this.getTournamentsOuter             }, set(value) {this.setTournamentsOuter(value)  }},
        total             : {get() {return this.getTournamentsOuterPaginationTotal             }, set(value) {this.setTournamentsOuterPaginationTotal(value)  }},
        currentPage       : {get() {return this.getTournamentsOuterPaginationCurrentPage             }, set(value) {this.setTournamentsOuterCurrentPage(value)  }},
        prize             : {get() {return this.getPrizeFond               }, set(value) {this.setPrize(value)}},
        formats           : {get() {return this.getFormats              }, set(value) {this.setFormats(value)}},
        timezones         : {get() {return this.getTimezones            }, set(value) {this.setTimezones(value)}},
        slot_count        : {get() {return this.getSlotCount            }, set(value) {this.setSlotCount(value)}},
        tournament_formats : {get() {return this.getTournamentFormats           }, set(value) {this.setTournamentFormats(value)}},
        tournament_timezone : {get() {return this.getTournamentTimezone          }, set(value) {this.setTournamentTimezone(value)}},
        currentFormats      : {get() {return this.getTournamentCurrentFormats    }, set(value) {this.setTournamentCurrentFormats(value)}},
        currentStatus     : {get() {return this.getTournamentCurrentStatus   }, set(value) {this.setTournamentCurrentStatus(value)}},
        schedule     : {get() {return this.getTournamentSchedule   }, set(value) {this.setTournamentSchedule(value)}},
        tournamentCurrentImage: {get() {return this.getTournamentCurrentImage}, set(value) {this.setTournamentCurrentImage(value)}},
        currentSlug: {get() {return this.getTournamentCurrentSlug}, set(value) {this.setTournamentCurrentSlug(value)}},
    },

    methods: {
        ...mapMutations({
            setTournament   : mutypes.SET_TOURNAMENT,
            setGames        : mutypes.SET_TOURNAMENT_GAMES,
            setGameId       : mutypes.SET_TOURNAMENT_GAME_ID,
            setTournamentOuterOrganizers   : mutypes.SET_TOURNAMENT_ORGANIZERS_OUTER,
            setOrganizerId  : mutypes.SET_TOURNAMENT_ORGANIZER_ID,
            setEndRegTime   : mutypes.SET_TOURNAMENT_END_REG_TIME,
            setRegTime      : mutypes.SET_TOURNAMENT_REG_TIME,
            setStartTime    : mutypes.SET_TOURNAMENT_START_TIME,
            setName         : mutypes.SET_TOURNAMENT_NAME,
            setRules     : mutypes.SET_TOURNAMENT_RULES,
            setDescription : mutypes.SET_TOURNAMENT_DESCRIPTION,
            setTournamentsOuter  : mutypes.SET_TOURNAMENTS_OUTER,
            setTournamentsOuterPaginationTotal: mutypes.SET_TOURNAMENTS_OUTER_PAGINATION_TOTAL,
            setTournamentsOuterCurrentPage    : mutypes.SET_TOURNAMENTS_OUTER_PAGINATION_CURRENT_PAGE,
            setPrize          : mutypes.SET_TOURNAMENT_PRIZE_FOND,
            setFormats        : mutypes.SET_TOURNAMENTS_OUTER_FORMATS,
            setTimezones       : mutypes.SET_TIMEZONES,
            setSlotCount      : mutypes.SET_TOURNAMENT_SLOT_COUNT,
            setTournamentFormats : mutypes.SET_TOURNAMENT_FORMATS,
            setTournamentTimezone : mutypes.SET_TOURNAMENT_TIMEZONE,
            setTournamentCurrentFormats: mutypes.SET_TOURNAMENT_CURRENT_FORMATS,
            setTournamentCurrentStatus: mutypes.SET_TOURNAMENT_CURRENT_STATUS,
            setTournamentSchedule: mutypes.SET_TOURNAMENT_SCHEDULE,
            setTournamentCurrentImage: mutypes.SET_TOURNAMENT_CURRENT_IMAGE,
            setTournamentCurrentSlug: mutypes.SET_TOURNAMENT_CURRENT_SLUG,
        }),

        ...mapActions([
            'storeTournamentOuter',
            'indexTournamentsOuter',
            'deleteOuterTournaments',
            'grabTournamentOuter',
            'updateTournamentOuter',
            'grabGamesOuter',
            'grabOrganizersOuter',
            'indexFormat',
            'indexTimeZones',
        ]),
        async createOuterTournament(){
            let formats = []

            this.newFormats.forEach((item)=>{
                formats.push({
                    id: item.value
                })
            })

            formats = formats.map((item)=>{
                return item.id;
            })

            const formData = new FormData()
            formData.append("game_id", this.gameId)
            formData.append("organizer_id", this.organizerId)
            formData.append("end_reg_time", this.newEndReg + ":00")
            formData.append("start_reg_time", this.newStartReg + ":00")
            formData.append("start_time", this.newStartTournament + ":00")
            formData.append("name", this.newTournamentName)
            for (const [key, value] of Object.entries(this.newDescription)) {
                formData.append(`description[${key}]`, value)
            }
            for (const [key, value] of Object.entries(this.newRules)) {
                formData.append(`rules[${key}]`, 'test')
            }
            for (const [key, value] of Object.entries(this.newSchedule)) {
                formData.append(`schedule[${key}]`, 'test')
            }
            formData.append("prize_fond", this.newPrizeFond)
            formData.append("formats[]", formats)
            formData.append("timezone", this.newTimezone)
            formData.append("slot_count", this.newSlotCount)
            formData.append("type", 1)
            formData.append("place_points", '[]');
            if (this.newImage){
                formData.append('logo', this.newImage)
            }

            await this.storeTournamentOuter(formData).then(()=> this.$router.push({path: 'tournaments'})).catch((err)=>{
                alert(err)
            })
        },
        updateCurrentTournament(){
            const newValues = this.currentFormats.map((el)=>{
                return el.value
            })

            if (this.newImage) {
                this.updateTournamentOuter({
                    id: this.$route.params.id,
                    formats: newValues,
                    logo: this.newImage,
                }).then(() => {
                    this.$router.go(-1)
                })
            } else {
                this.updateTournamentOuter({
                    id: this.$route.params.id,
                    formats: newValues,
                }).then(() => {
                    this.$router.go(-1)
                })
            }
        }
    }
}
