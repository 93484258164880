<template>
  <div>
    <Breadcrumbs main="Команды" title="Создать команду"></Breadcrumbs>
    <div class="container">
      <b-card>
        <b-form>
          <label>Game ID</label>
          <b-form-input v-model="newGameId" type="number"></b-form-input>
          <label>Название команды</label>
          <ValidationProvider  rules="min:2|required" v-slot="{errors}" >
          <b-input type="text" v-model="userFakeTeamName"></b-input>
            <div class="text-danger">{{errors[0]}}</div>
          </ValidationProvider>
          <label>Выберите владельца команды</label>
          <div>
            <ValidationProvider rules="required" v-slot="{errors}">
            <multiselect @search-change="searchMember" :internal-search="false" :loading="isLoading" v-model="owner"
                         :options="membersSearch" :clear-on-select="false" :close-on-select="true">
              <template slot="tag" slot-scope="{ option, remove }"><span class="custom__tag"><span>{{
                  option.name
                }}</span></span></template>
              <template slot="clear" slot-scope="props">
                <div class="multiselect__clear" v-if="membersSearch.length"></div>
              </template>
              <span slot="noResult">не найден пользователь</span>
            </multiselect>
              <div class="text-danger">{{errors[0]}}</div>
            </ValidationProvider>
          </div>
          <label>Выберите участников команды</label>
          <ValidationProvider rules="required" v-slot="{errors}">
          <multiselect v-model="selectedMembers" @search-change="searchMembers" label="name" track-by="id"
                       placeholder="Type to search" open-direction="bottom" :options="membersForTeam" :multiple="true"
                       :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false"
                       :close-on-select="false"
                       :hide-selected="true">
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="custom__tag"><span>{{ option.name }}</span>
                <span class="custom__remove" @click="remove(option)">❌</span>
              </span>
            </template>
          </multiselect>
            <div class="text-danger">{{errors[0]}}</div>
          </ValidationProvider>

          <div class="mt-5">
            <b-btn variant="success mr-3" @click.prevent="saveTeam">Сохранить
            </b-btn>
            <b-btn variant="primary" @click.prevent="$router.push({name: 'teams'})">Назад</b-btn>
          </div>
        </b-form>
      </b-card>
    </div>
  </div>

</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import {TeamsAdapterMixin} from "@/mixins/Teams/TeamsAdapterMixin";
import {UserFakeAdapterMixin} from "@/mixins/Teams/UserFakeAdapterMixin";
import Multiselect from 'vue-multiselect'

export default {
  name: 'CreateTeam',
  mixins: [TeamsAdapterMixin, UserFakeAdapterMixin],
  components: {
    Multiselect
  },
  data() {
    return {
      options: ['list', 'of', 'options'],
      searchText: '', // If value is falsy, reset searchText & searchItem
      items: [],
      membersSearch: [],
      membersForTeam: [],
      selectedMembers: [],
      lastSelectItem: {},
      owner: {},
      isLoading: false
    }
  },
  methods: {
    onSelect(items, lastSelectItem) {
      this.items = items
      this.lastSelectItem = lastSelectItem
    },
    searchMember(query) {
      if (query.length > 2) {
        this.isLoading = true;
        this.indexUserFake({
          "filter[email]": query,
        }).then(response => {
          this.membersSearch = response.map(function (item) {
            return {
              "id": item.id,
              "name": item.name + ' | ' + item.email
            }
          });
          this.isLoading = false;
        })
      }
    },
    searchMembers(query) {
      if (query.length > 2) {
        this.isLoading = true;
        this.indexUserFake({
          "filter[email]": query,
        }).then(response => {
          this.membersForTeam = response;
          this.isLoading = false;
        })
      }
    },
    saveTeam() {

      let members = [];
      members.push({
        id:this.owner.id,
        role:1,
      });
      this.selectedMembers.forEach((item)=>{
        members.push({
          id:item.id,
          role:2,
        })
      })

      this.storeTeam({
        game_id:1,
        type:1,
        name:this.userFakeName,
        members:members
      }).then((response)=>{
        this.$router.push({name: 'teams'});
      });

    },
  },
  mounted() {
    //this.indexUserFake()
  }
}
</script>