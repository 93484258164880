import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";


export const OrganizerUserAdapterMixin = {
    data() {
        return {
            newRole: null,

        }
    },
    computed: {
        ...mapGetters({
            getOrganizerUsers: 'getOrganizerUsers',
            getPermissionsUsers: 'getPermissionsUsers',
            getOrganizerRoles: 'getOrganizerRoles',
            getCurrentOrganizerUser: 'getCurrentOrganizerUser',
            getOrganizerPaginationTotal: 'getOrganizerPaginationTotal',
            getOrganizerCurrentPage: 'getOrganizerCurrentPage'
        }),
        organizerUsers: {
            get() {
                return this.getOrganizerUsers
            }, set(value) {
                this.setOrganizerUsers(value)
            }
        },
        permissionsUsers: {
            get() {
                return this.getPermissionsUsers
            }, set(value) {
                this.setPermissionsUsers(value)
            }
        },
        organizerRoles: {
            get() {
                return this.getOrganizerRoles
            }, set(value) {
                this.setOrganizerRoles(value)
            }
        },
        currentOrganizerUser: {
            get() {
                return this.getCurrentOrganizerUser
            }, set(value) {
                this.setCurrentOrganizerUser(value)
            }
        },
        organizerTotal: {
            get() {
                return this.getOrganizerPaginationTotal
            }, set(value) {
                this.setOrganizerPaginationTotal(value)
            }
        },
        organizerCurrentPage: {
            get() {
                return this.getOrganizerCurrentPage
            }, set(value) {
                this.setOrganizerCurrentPage(value)
            }
        },
    },
    methods: {
        ...mapMutations({
            setOrganizerUsers: mutypes.SET_ORGANIZER_USERS,
            setPermissionsUsers: mutypes.SET_ORGANIZER_PERMISSIONS,
            setOrganizerRoles: mutypes.SET_ORGANIZER_ROLES,
            setCurrentOrganizerUser: mutypes.SET_ORGANIZER_CURRENT_USER,
            setOrganizerPaginationTotal: mutypes.SET_ORGANIZER_PAGINATION_TOTAL,
            setOrganizerCurrentPage: mutypes.SET_ORGANIZER_PAGINATION_CURRENT_PAGE,
        }),
        ...mapActions([
            'grabOrganizerUsers',
            'storeOrganizerUsers',
            'grabFilteredUser',
            'grabRoles',
            'getPermissions',
            'showUser',
            'updateUser'
        ]),
        createOrganizerUser() {
            this.storeOrganizerUsers({
                email: this.newEmail,
                job: this.newJob,
                game: this.newTitle,
                stamp: this.newName,
                password: this.newPassword,
                permissions: this.newPermissions
            })
        },
        async updateUserRole() {
           await this.updateUser({
                id: this.$route.params.id,
                job: this.currentOrganizerUser.job,
                game: this.currentOrganizerUser.game,
                stamp: this.currentOrganizerUser.stamp,
                roles: [this.currentOrganizerUser.roles.id]
            }).then(()=>{
                this.$router.push({name: 'organizer_users'})
           })
        }
    }
}