import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            tournaments:[],
            pagination:{
                by:10,
                currentPage:1,
                totalPages:1,
            },
            formats: [],
            formatNames: [],
            formatId: [],
            formatFormats: [],
            timezones: [],
            countries: null,
        }
    },
    mutations: {
        [mutypes.SET_TOURNAMENTS]: (state, value) => state.tournaments = value,
        [mutypes.SET_TOURNAMENTS_PAGINATION_TOTAL]: (state, value) => state.pagination.totalPages = value,
        [mutypes.SET_TOURNAMENTS_PAGINATION_CURRENT_PAGE]: (state, value) => state.pagination.currentPage = value,
        [mutypes.SET_TOURNAMENTS_FORMATS]: (state, value) => state.formats = value,
        [mutypes.SET_TIMEZONES]: (state, value) => state.timezones = value,
        [mutypes.SET_COUNTRIES]: (state, value) => state.countries = value,
    },

    getters:{
        getTournaments                           : state => state.tournaments,
        getTournamentsPaginationTotal            : state => state.pagination.totalPages,
        getTournamentsPaginationCurrentPage      : state => state.pagination.currentPage,
        getTournamentsFormats                    : state => state.formats,
        getTimezones                             : state => state.timezones,
        getCountries                             : state => state.countries,
    },

    actions: {
        indexTournaments: async ({commit,getters}, params) => {
            const response = await api.tournament.index({
                page: params.pageNumber,
                "filter[game_id]":params.gameId,
                "search": params.search
            });

            await commit(mutypes.SET_TOURNAMENTS, response.data.tournaments);
            await commit(mutypes.SET_TOURNAMENTS_PAGINATION_TOTAL, response.data.pagination.total);
            await commit(mutypes.SET_TOURNAMENTS_PAGINATION_CURRENT_PAGE, params.pageNumber);


            // const formats = response.data.tournaments.map((el)=> el.formats)
            // let values = []
            // let testArr = []
            // formats.forEach((item, index) => {
            //
            //    c  item.forEach((it,idx)=>{
            //         values[idx] = {
            //              formats:it.formats,
            //              id: it.id,
            //              name:it.name
            //          };
            //          console.log(values[idx], 'formats')
            //
            //
            //      })})
            // commit(mutypes.SET_TOURNAMENTS_FORMATS, values);

            return getters.getTournaments;
        },
        changeCurrentPage: ({commit,getters},page) => {
            commit(mutypes.SET_TOURNAMENTS_PAGINATION_CURRENT_PAGE,page);
        },
        deleteTournament: async ({commit,getters},id) => {
            //@todo проверять статус код
            await api.tournament.destroy(id);
            let tournaments = getters.getTournaments.filter(t => t.id !== id)
            await commit(mutypes.SET_TOURNAMENTS, tournaments);
        },
        indexFormat: async({commit, getters}, params) => {
             const response = await api.format.index({
                 "filter[game_id]": params.gameId
             })
            let values = [];
            response.data.formats.forEach((item, index) => {
                values[index] = {
                    text:item.name,
                    formats: item.formats,
                    value:item.id,
                };
            })

            commit(mutypes.SET_TOURNAMENTS_FORMATS, values)

            localStorage.setItem('formats', JSON.stringify(values));
        },
        indexTimeZones: async ({commit, getters}) => {
            const response = await api.timezones.index()
            commit(mutypes.SET_TIMEZONES, response.data.timezones)
            localStorage.setItem('timezones', JSON.stringify(response.data.timezones));
        },
        getCountriesQuery: async ({commit, getters}) => {
            const response = await api.tournament.getCountries()

            let countries = response.data.countries
            let values = []
            countries.forEach((item, index)=> {
                values[index] = {
                    text: item.name,
                    value: item.id
                }
            })
            values.unshift({text: '----', value: ''})
            localStorage.setItem('countries', JSON.stringify(values));
            commit(mutypes.SET_COUNTRIES, values)
        }
    }
}