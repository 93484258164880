<template>
  <div>
    <Breadcrumbs main="Помощь" title="Редактировать раздел помощи"></Breadcrumbs>
    <div class="container">
      <b-card>
        <b-tabs class="mt-3 d-flex justify-content-md-start justify-content-center flex-wrap">
          <b-tab v-for="lang in languages"
                 :title="lang.language"
                 :key="'lang-' + lang.id"
                 @click="getLanguageFromBtn(lang.language.toLowerCase())"
                 nav-class="mr-2 mb-2">
            <template #title>
              <div class="d-flex justify-content-center">
                <img :src="lang.img" width="20" height="20" class="mr-2">
                <span>{{ lang.language }}</span>
              </div>
            </template>
          </b-tab>
        </b-tabs>

        <b-form>
          <label class="mt-3"><b>Заголовок {{ language.toUpperCase() }}</b></label>
          <b-form-input placeholder="Заголовок" v-model="currentHelp.title[language]"></b-form-input>
          <label class="mt-3"><b>Описание {{ language.toUpperCase() }}</b></label>
          <template v-for="lang in Object.keys(currentHelp.description)">
            <SunEditor :key="lang + '-helpDescription'"
                       v-if="language === lang"
                       v-model="currentHelp.description[lang]"
                       :editorId="'helpDescription'"
            />
          </template>
        </b-form>

        <label class="mt-3"><b>Дата</b></label>
        <datetime format="YYYY-MM-DD H:i" v-model="currentHelp.created_at"></datetime>

<!--        <label>Автор</label>-->
<!--        <b-form-input v-model="currentHelp.author.name" disabled></b-form-input>-->

        <b-btn variant="success" class="mt-5"
               @click.prevent="updateHelpDeskEdit({
                 id: currentHelp.id,
                 title: currentHelp.title,
                 description: currentHelp.description,
                 position: currentHelp.position,
                 created_at: currentHelp.created_at})">
          Сохранить
        </b-btn>
      </b-card>
    </div>
  </div>
</template>

<script>
import datetime from 'vuejs-datetimepicker';
import {HelpAdapterMixin} from "@/mixins/helpdesk/HelpAdapterMixin";
import SunEditor from "@/components/SunEditor.vue";
import {LanguageAdapterMixin} from "@/mixins/Language/LanguageAdapterMixin";

export default {
  name: 'HelpEdit',
  mixins: [HelpAdapterMixin, LanguageAdapterMixin],
  components: {SunEditor, datetime},
}
</script>
