import { mapActions, mapGetters, mapMutations } from "vuex";
import * as mutypes from "/src/store/mutation.types";
import { ALL, ENTRIES, APPROVED, REJECTED, BANNED, ListTypes } from '/src/constants/WowStreamers/listTypes.js';

export const WowStreamersAdapterMixin = {
    data() {
        return {
            currentListType: ALL,
        }
    },
    computed: {
        ...mapGetters({
            getWowStreamers: 'getWowStreamers',
            getWowStreamerEntries: 'getWowStreamerEntries',
            getApprovedWowStreamers: 'getApprovedWowStreamers',
            getBannedWowStreamers: 'getBannedWowStreamers',
            getRejectedWowStreamers: 'getRejectedWowStreamers',
            getCurrentPage: 'getWowStreamersPaginationCurrentPage',
            getWowStreamersPaginationTotal: 'getWowStreamersPaginationTotal',
            getWowStreamer: 'getWowStreamer',
        }),
        currentList: {
            get() {
                switch (this.currentListType) {
                    case ENTRIES:
                        return this.getWowStreamerEntries;
                    case APPROVED:
                        return this.getApprovedWowStreamers;
                    case REJECTED:
                        return this.getRejectedWowStreamers;
                    case BANNED:
                        return this.getBannedWowStreamers;
                    default:
                        return this.getWowStreamers;
                }
            },
            set(value) {
                switch (this.currentListType) {
                    case ENTRIES:
                        this.setWowStreamerEntries(value);
                        break;
                    case APPROVED:
                        this.setApprovedWowStreamers(value);
                        break;
                    case REJECTED:
                        this.setRejectedWowStreamers(value);
                        break;
                    case BANNED:
                        this.setBannedWowStreamers(value);
                        break;
                    default:
                        this.setWowStreamers(value);
                }
            }
        },
        currentPage: {
            get() {
                return this.getCurrentPage;
            },
            set(value) {
                this.setCurrentPage(value);
            }
        },
        totalPagination: {
            get() {
                return this.getWowStreamersPaginationTotal;
            },
            set(value) {
                this.setWowStreamersPaginationTotal(value);
            }
        },
    },
    methods: {
        ...mapMutations({
            setWowStreamers: mutypes.SET_WOWSTREAMERS,
            setApprovedWowStreamers: mutypes.SET_APPROVED_WOWSTREAMERS,
            setRejectedWowStreamers: mutypes.SET_REJECTED_WOWSTREAMERS,
            setBannedWowStreamers: mutypes.SET_BANNED_WOWSTREAMERS,
            setWowStreamerEntries: mutypes.SET_WOWSTREAMER_ENTRIES,
            setWowStreamersPaginationTotal: mutypes.SET_WOWSTREAMERS_PAGINATION_TOTAL,
            setCurrentPage: mutypes.SET_WOWSTREAMERS_PAGINATION_CURRENT_PAGE,
            setWowStreamer: mutypes.SET_WOWSTREAMER,
        }),
        ...mapActions([
            'fetchWowStreamers',
            'fetchApprovedWowStreamers',
            'fetchRejectedWowStreamers',
            'fetchBannedWowStreamers',
            'fetchWowStreamerEntries',
            'fetchWowStreamer',
            'createWowStreamer',
            'updateWowStreamer',
            'deleteWowStreamer',
            'banWowStreamer',
            'unbanWowStreamer',
            'approveWowStreamer',
            'rejectWowStreamer',
            'deactivateWowStream'
        ]),
        fetchCurrentList(params) {
            switch (this.currentListType) {
                case ENTRIES:
                    return this.fetchWowStreamerEntries(params);
                case APPROVED:
                    return this.fetchApprovedWowStreamers(params);
                case REJECTED:
                    return this.fetchRejectedWowStreamers(params);
                case BANNED:
                    return this.fetchBannedWowStreamers(params);
                default:
                    return this.fetchWowStreamers(params);
            }
        },
    },
};
