import Vue from 'vue';
import Router from "vue-router";
import Body from '../components/body';
import dashboard from "@/components/dashboard.vue";
// Авторизация
import AuthPage from "@/pages/Auth/AuthPage.vue";
// Раздел: Турниры
import ListsTournament from "../pages/Tournament/ListsTournament";
import CreateTournament from "../pages/Tournament/CreateTournament";
import EditPage from "../pages/Tournament/ЕditPageTournament.vue";
import TournamentEntries from "@/pages/Tournament/Entries/TournamentEntries";
import CreatePromoTournament from "../pages/Tournament/CreatePromoTournament";
import PromoTournaments from "@/pages/Tournament/PromoTournaments.vue";
import StandingsTournament from "@/pages/Tournament/StandingsTournament/StandingsTournament.vue";
import ChangeTournamentMatch from "@/pages/Tournament/ChangeTournamentMatch/ChangeTournamentMatch.vue";
import CreateTournamentStage from "../pages/Tournament/StandingsTournament/CreateTournamentStage.vue";
import TeamInfoStandingsTournament from "@/pages/Tournament/StandingsTournament/TeamInfoStandingsTournament.vue";
import CreateGroupStageStandingsTournament
    from "@/pages/Tournament/StandingsTournament/CreateGroupStageStandingsTournament.vue";
import EditGroupStageStandingTournament
    from "@/pages/Tournament/StandingsTournament/EditGroupStageStandingTournament.vue";
import EditTournamentStage from "@/pages/Tournament/StandingsTournament/EditTournamentStage.vue";
import CreateTeam from "@/pages/Tournament/Teams/CreateTeam.vue";
import TeamPlayerInfo from "@/pages/Tournament/Teams/TeamPlayerInfo.vue";
import TournamentEntry from "../pages/Tournament/Entries/TournamentEntry";
import AddTeamStandingsTournament from "@/pages/Tournament/StandingsTournament/AddTeamStandingsTournament.vue";
import AddPlayer from "@/pages/Tournament/Teams/AddPlayer.vue";
import EditPagePromoTournament from "../pages/Tournament/EditPagePromoTournament";
import Live from "../pages/Live/Live";
import RolesList from "@/pages/Roles/RolesList.vue";
import RoleCreate from "@/pages/Roles/RoleCreate.vue";
import RoleEdit from "@/pages/Roles/RoleEdit.vue";
import UserLevelsLogsInfo from "@/pages/User/UserLevelsLogsInfo.vue";
import DeletedTeamsList from "@/pages/Tournament/Teams/DeletedTeamsList.vue";
// Раздел: Команды
import TeamsList from "@/pages/Tournament/Teams/TeamsList.vue";
import TeamInfo from "@/pages/Tournament/Teams/TeamInfo.vue";
import ListFakeTeams from "@/pages/Tournament/FakeTeams/ListFakeTeams.vue";
import TrashedTeams from "@/pages/Tournament/Teams/TrashedTeams.vue";
// Раздел: Пользователи
import ListUsers from "../pages/User/ListUsers";
import ListFakeUsers from "../pages/UserFake/ListFakeUsers";
import ListUsersLevels from "@/pages/User/ListUsersLevels.vue";
import UserInfo from "@/pages/User/UserInfo.vue";
import UserTransferLog from "@/pages/User/UserTransferLog.vue";
// Раздел: Бан
import ListUsersBan from "@/pages/Ban/ListUsersBan.vue";
import ListTeamsBan from "@/pages/Ban/ListTeamsBan.vue";
import UserBansInfoList from "@/pages/Ban/UserBansInfoList.vue";
// Раздел: Организации
import ListOrganizers from "../pages/Organizers/ListOrganizers";
import CreateOrganizer from "../pages/Organizers/CreateOrganizer.vue";
import OrganizerInfo from "../pages/Organizers/OrganizerInfo.vue";
import EditOrganizer from "../pages/Organizers/EditOrganizer.vue";
import ListOrganizerUsers from "../pages/Organizer/ListOrganizerUsers";
import OrganizerUsersCreate from "@/pages/Organizer/OrganizerUsersCreate.vue";
import OrganizerUsersEdit from "@/pages/Organizer/OrganizerUsersEdit.vue";
// Раздел: Инструменты
import PostsList from "@/pages/Tournament/Posts/PostsList.vue";
import PostsCreate from "@/pages/Tournament/Posts/PostsCreate.vue";
import PostsEdit from "@/pages/Tournament/Posts/PostsEdit.vue";
import HelpList from "@/pages/Tournament/Help/HelpList.vue";
import HelpCreate from "@/pages/Tournament/Help/HelpCreate.vue";
import HelpEdit from "@/pages/Tournament/Help/HelpEdit.vue";
import HelpFilter from "@/pages/Tournament/Help/HelpFilter.vue";
import MainBanner from '@/pages/MainBanner'
// Раздел: Рейтинг
import ListTeamsRating from "../pages/Rating/ListTeamsRating";
import TeamRatingsInfo from "../pages/Rating/TeamRatingsInfo";
import ListTournamentsRating from "@/pages/Rating/ListTournamentsRating.vue";
import ListUsersRating from "@/pages/Rating/ListUsersRating.vue";
import UserRatingsInfo from "@/pages/Rating/UserRatingsInfo.vue";
import TournamentRatingsInfo from "@/pages/Rating/TournamentTeamsRatingInfo.vue";
// Раздел: Рассылка
import CreateNewsletter from "@/pages/Newsletter/CreateNewsletter.vue";
import NewsletterLog from "@/pages/Newsletter/NewsletterLog.vue";
import TelegramNewsletters from "@/pages/Newsletter/TelegramNewsletters.vue";
// SEO
import SeoList from "@/pages/Seo/SeoList.vue";
import SeoCreate from "@/pages/Seo/SeoCreate.vue";
import SeoEdit from "@/pages/Seo/SeoEdit.vue";
// Раздел: Кастомные игры
import ListWowStreamers from '@/pages/WowStreamers/ListWowStreamers.vue';
import ListWowStreamersEntries from '@/pages/WowStreamers/ListWowStreamersEntries.vue';
import ListBannedWowStreamers from '@/pages/WowStreamers/ListBannedWowStreamers';
import WowStreamerInfo from '@/pages/WowStreamers/WowStreamerInfo.vue';
// Раздел: Watch
import ListWatches from '@/pages/Watch/ListWatches.vue'
import CreateWatches from '@/pages/Watch/CreateWatches.vue'

import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import ruRu from '@kangc/v-md-editor/lib/lang/ru-RU';

VueMarkdownEditor.use(vuepressTheme);
VueMarkdownEditor.lang.use('ru-RU',ruRu);

Vue.use(Router)
Vue.use(VueMarkdownEditor);

const routes = [
    {path: '', redirect: {name: 'dashboard'}},
    {
        path: '/dashboard',
        component: Body,
        children: [
            {
                path: 'main',
                name: 'dashboard',
                component: dashboard,
                meta: {
                    title: 'main',
                }
            },
            {
                path: 'create_tournament',
                name: 'create_tournament',
                component: CreateTournament,
                meta: {
                    title: 'Create tournaments',
                }
            },
            {
                path: 'users/fake',
                name: 'users_fake',
                component: ListFakeUsers,
                meta: {
                    title: 'Index fake users',
                }
            },
            {
                path: 'users/real',
                name: 'users_real',
                component: ListUsers,
                meta: {
                    title: 'Index fake users',
                }
            },
            {
                path: 'users/levels',
                name: 'users_levels',
                component: ListUsersLevels,
                meta: {
                    title: 'List users levels',
                }
            },
            {
                path: 'users/transfer-logs',
                name: 'users_transfer_logs',
                component: UserTransferLog,
                meta: {
                    title: 'Users transfer logs list',
                }
            },
            {
                path: 'users/levels/logs/:id',
                name: 'user_levels_logs',
                component: UserLevelsLogsInfo,
                meta: {
                    title: 'List user levels logs',
                }
            },
            {
                path: 'organizer/users',
                name: 'organizer_users',
                component: ListOrganizerUsers,
                meta: {
                    title: 'Auth page'
                }
            },
            {
                path: 'organizer/users/create',
                name: 'organizer_users_create',
                component: OrganizerUsersCreate,
                meta: {
                    title: 'Auth page'
                }
            },
            {
                path: 'organizer/users/:id/edit',
                name: 'organizer_users_edit',
                component: OrganizerUsersEdit,
                meta: {
                    title: 'Auth page'
                }
            },
            {
                path: 'tournaments',
                name: 'tournaments',
                component: ListsTournament,
                meta: {
                    title: 'Tournament list',
                }
            },
            {
                path: 'create_tournament_stage/:id',
                name: 'create_tournament_stage',
                component: CreateTournamentStage,
                meta: {
                    title: 'Create tournament stage'
                }
            },
            {
                path: 'edit_tournament_stage/:standingId/:stage_id',
                name: 'edit_tournament_stage',
                component: EditTournamentStage,
                meta: {
                    title: 'Edit tournament stage'
                }
            },
            {
                path: 'tournament/edit/:id',
                name: 'tournament_edit',
                component: EditPage,
                meta: {
                    title: 'Default Dashboard | Endless - Premium Admin Template',
                },
            },
            {
                path: 'tournament/:standingId/standings',
                name: 'tournament_standings',
                component: StandingsTournament,
                meta: {
                    title: 'Tournaments standings'
                },
            },
            {
                path: 'matches/:group_id/create',
                name: 'change_tournament_group_matches',
                component: ChangeTournamentMatch,
                meta: {
                    title: 'Change tournament match'
                }
            },
            {
                path: '/matches/edit/:group_result_id/:tournament_id',
                name: 'team_info_standings_tournament',
                component: TeamInfoStandingsTournament,
                meta: {
                    title: 'Edit team info'
                }
            },

            {
                path: '/tournaments/entries/:id',
                name: 'tournament_teams',
                component: TournamentEntries,
                meta: {
                    title: 'Tournament teams'
                }
            },
            {
                path: '/tournament/:tournamentId/entries/:entryId',
                name: 'tournament_entry',
                component: TournamentEntry,
                meta: {
                    title: 'Tournament entry'
                }
            },
            {
                path: '/tournaments/group/create/:stage_id',
                name: 'create_group_tournament',
                component: CreateGroupStageStandingsTournament,
                meta: {
                    title: 'Create group in tournament stage'
                }
            },
            {
                path: '/tournaments/group/edit/:group_id',
                name: 'edit_group_tournament',
                component: EditGroupStageStandingTournament,
                meta: {
                    title: 'edit group in tournament stage'
                }
            },
            {
                path: '/tournaments/team/add/:standingId/:stage_id/:group_id',
                name: 'add_team',
                component: AddTeamStandingsTournament,
                meta: {
                title: 'add team in standings'
                }
            },
            {
                path: '/teams',
                name: 'teams',
                component: TeamsList,
                meta: {
                    title: 'TeamMember'
                }
            },
            {
                path: '/teams/trashed',
                name: 'trashedTeams',
                component: TrashedTeams,
                meta: {
                    title: 'TrashedTeams'
                }
            },
            {
                path: '/teams/fake',
                name: 'teams_fake',
                component: ListFakeTeams,
                meta: {
                    title: 'Index fake teams',
                }
            },
            {
                path: '/teams/:id(\\d+)',
                name: 'teams_info',
                component: TeamInfo,
                meta: {
                    title: 'TeamMember'
                }
            },
            {
                path: '/teams/deleted',
                name: 'deleted_teams',
                component: DeletedTeamsList,
                meta: {
                    title: 'Deleted teams'
                }
            },
            {
                path: '/users/:id(\\d+)',
                name: 'user_info',
                component: UserInfo,
                meta: {
                    title: 'Users'
                }
            },
            {
                path: '/teams/create_team',
                name: 'teams_create',
                component: CreateTeam,
                meta: {
                    title: 'TeamMember create'
                }
            },
            {
                path: '/teams/user_card/:id',
                name: 'teams_player',
                component: TeamPlayerInfo,
                meta: {
                    title: 'Team player info'
                }
            },
            {
                path: '/teams/add_player/:id',
                name: 'teams_add_player',
                component: AddPlayer,
                meta: {
                    title: 'Add player to team'
                }
            },
            {
                path: '/posts',
                name: 'posts',
                component: PostsList,
                meta: {
                    title: 'Posts'
                }
            },
            {
                path: '/posts/create',
                name: 'posts_create',
                component: PostsCreate,
                meta: {
                    title: 'Posts create'
                }
            },
            {
                path: '/posts/:id/edit',
                name: 'posts_edit',
                component: PostsEdit,
                meta: {
                    title: 'Posts edit'
                }
            },
            {
                path: '/help',
                name: 'help',
                component: HelpList,
                meta: {
                    title: 'help'
                }
            },
            {
                path: '/help/create',
                name: 'help_create',
                component: HelpCreate,
                meta: {
                    title: 'help create'
                }
            },
            {
                path: '/help/:id/edit',
                name: 'help_edit',
                component: HelpEdit,
                meta: {
                    title: 'help edit'
                }
            },
            {
                path: '/help/filter',
                name: 'help_filter',
                component: HelpFilter,
                meta: {
                    title: 'help filter'
                }
            },
            {
                path: '/rating/teams',
                name: 'rating_teams',
                component: ListTeamsRating,
                meta: {
                    title: 'Rating teams'
                }
            },
            {
                path: '/rating/teams/:id',
                name: 'team_ratings_info',
                component: TeamRatingsInfo,
                meta: {
                    title: 'Team ratings info'
                }
            },
            {
                path: '/rating/tournaments',
                name: 'rating_tournaments',
                component: ListTournamentsRating,
                meta: {
                    title: 'Rating tournaments'
                }
            },
            {
                path: '/rating/tournaments/:id',
                name: 'tournament_ratings_info',
                component: TournamentRatingsInfo,
                meta: {
                    title: 'Tournament ratings info'
                }
            },
            {
                path: '/organizers',
                name: 'organizers',
                component: ListOrganizers,
                meta: {
                    title: 'List organizers'
                }
            },
            {
                path: '/organizers/create',
                name: 'organizer_create',
                component: CreateOrganizer,
                meta: {
                    title: 'Create organizer'
                }
            },
            {
                path: '/organizers/edit/:id',
                name: 'organizer_edit',
                component: EditOrganizer,
                meta: {
                    title: 'Edit organizer'
                }
            },
            {
                path: '/organizers/info/:id',
                name: 'organizer_info',
                component: OrganizerInfo,
                meta: {
                    title: 'Organizer info'
                }
            },
            {
                path: 'promo/tournaments',
                name: 'promo',
                component: PromoTournaments,
                meta: {
                    title: 'Promo tournaments',
                }
            },
            {
                path: 'promo/create_promo_tournament',
                name: 'create_promo_tournament',
                component: CreatePromoTournament,
                meta: {
                    title: 'Create promo tournaments',
                }
            },
            {
                path: 'tournamentOuter/edit/:id',
                name: 'tournament_outer_edit',
                component: EditPagePromoTournament,
                meta: {
                    title: 'Default Dashboard | Endless - Premium Admin Template',
                },
            },
            {
                path: '/live/:standingId/:group_id/:stage_id',
                name: 'live',
                component: Live,
                meta: {
                    title: 'Live'
                }
            },
            {
                path: '/seo',
                name: 'seo',
                component: SeoList,
                meta: {
                    title: 'Seo'
                }
            },
            {
                path: '/seo/create',
                name: 'SeoCreate',
                component: SeoCreate,
                meta: {
                    title: 'Seo create'
                }
            },
            {
                path: '/seo/:id',
                name: 'SeoEdit',
                component: SeoEdit,
                meta: {
                    title: 'Seo edit'
                }
            },
            {
                path: '/newsletter/create',
                name: 'newsletter_create',
                component: CreateNewsletter,
                meta: {
                    title: 'Newsletter create'
                }
            },
            {
                path: '/newsletter/telegram',
                name: 'telegram_newsletters',
                component: TelegramNewsletters,
                meta: {
                    title: 'Telegram newsletters'
                }
            },
            {
                path: '/newsletter/telegram/:id',
                name: 'telegram_newsletter_log',
                component: NewsletterLog,
                meta: {
                    title: 'Telegram newsletters'
                }
            },
            {
                path: '/roles',
                name: 'roles',
                component: RolesList,
                meta: {
                    title: 'Roles list'
                }
            },
            {
                path: '/roles/create',
                name: 'create_role',
                component: RoleCreate,
                meta: {
                    title: 'Role create'
                }
            },
            {
                path: '/roles/:id',
                name: 'edit_role',
                component: RoleEdit,
                meta: {
                    title: 'Role edit'
                }
            },
            {
                path: '/ban/users',
                name: 'ban_users',
                component: ListUsersBan,
                meta: {
                    title: 'Users ban'
                }
            },
            {
                path: '/ban/:id(\\d+)',
                name: 'bans_info',
                component: UserBansInfoList,
                meta: {
                    title: 'Bans info'
                }
            },
            {
                path: '/main-banner',
                name: 'main-banner',
                component: MainBanner,
            },
            {
                path: '/ban/teams',
                name: 'ban_teams',
                component: ListTeamsBan,
                meta: {
                    title: 'Teams ban'
                }
            },
            {
                path: '/rating/users',
                name: 'rating_users',
                component: ListUsersRating,
                meta: {
                    title: 'Rating users'
                }
            },
            {
                path: '/rating/users/:id',
                name: 'user_ratings_info',
                component: UserRatingsInfo,
                meta: {
                    title: 'User ratings info'
                }
            },
            {
                path: '/wowstreamers',
                name: 'wow_streamers',
                component: ListWowStreamers,
                meta: {
                    title: 'Wow streamers info'
                }
            },
            {
                path: '/wowstreamers/:id(\\d+)',
                name: 'wow_streamer_info',
                component: WowStreamerInfo,
                meta: {
                    title: 'Wow Streamer Info'
                }
            },
            {
                path: '/wowstreamers/entries',
                name: 'wow_streamers_entries',
                component: ListWowStreamersEntries,
                meta: {
                    title: 'Wow streamers entries'
                }
            },
            {
                path: '/wowstreamers/ban',
                name: 'banned_wow_streamers',
                component: ListBannedWowStreamers,
                meta: {
                    title: 'Wow streamers ban'
                }
            },
            {
                path: '/watch',
                name: 'watches',
                component: ListWatches,
                meta: {
                  title: 'List of Watches'
                }
            },
            {
                path: '/watch/create',
                name: 'create_watches',
                component: CreateWatches,
                meta: {
                    title: 'Create Watches'
                }
            }
        ]
    },
    {
        path: '/auth',
        name: 'auth',
        component: AuthPage,
        meta: {
            title: 'Auth page'
        }
    },

    ];

const router = new Router({
    routes,
    base: '/',
    mode: 'history',
    linkActiveClass: "active",
    scrollBehavior() {
        return {x: 0, y: 0}
    }
});

export default router