<template>
  <div class="mlbb-sidebar-wrapper" id="list-groups-sidebar">
    <!--  НАЗВАНИЕ ТУРНИРА  -->
    <SidebarBlock class="mb-4" header-between>
      <template #header>
        <div>
          <span class="block-name">Название турнира</span>
          <h2 class="tournament-title">{{ tournament?.name || 'Отсутсвует' }}</h2>
        </div>
        <CompleteTournamentMiniBlock />
      </template>
    </SidebarBlock>

    <StagesForSidebar @changeWorkspace="(data) => $emit('changeWorkspace', data)" />
    <RoundsForSidebar v-if="isShowRoundsBlock" 
                      @changeWorkspace="(data) => $emit('changeWorkspace', data)" />
    <GroupsForSidebar v-if="isShowGroupsBlock"
                      @changeWorkspace="(data) => $emit('changeWorkspace', data)"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SidebarBlock from "@/components/Tournament/SidebarBlock.vue";
import CompleteTournamentMiniBlock from "@/components/Tournament/components/CompleteTournamentMiniBlock.vue";
import StagesForSidebar from "@/components/Tournament/MLBB/components/StagesForSidebar.vue";
import RoundsForSidebar from "@/components/Tournament/MLBB/components/RoundsForSidebar.vue";
import GroupsForSidebar from "@/components/Tournament/MLBB/components/GroupsForSidebar.vue";

export default {
  name: "MLBBSidebar",
  components: {
    SidebarBlock,
    CompleteTournamentMiniBlock,
    StagesForSidebar,
    RoundsForSidebar,
    GroupsForSidebar
  },
  computed: {
    ...mapGetters('moduleNewTournament', ['rounds', 'selectedRound', 'formatBySelectedStage']),
    ...mapState('moduleNewTournament', ['tournament']),

    isShowRoundsBlock() {
      if (this.formatBySelectedStage === 'single') return !!this.rounds.length
      if (this.formatBySelectedStage === 'double') return true
      return false
    },
    isShowGroupsBlock() {
      if (this.formatBySelectedStage !== 'olympic') return this.selectedRound
      return true
    }
  },
}
</script>

<style scoped lang="scss">
.mlbb-sidebar-wrapper {
  &::v-deep(.tab-label) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}
</style>