<template>
  <div class="delete-fake-user-wrapper">
    <h5>Вы действительно хотите удалить фейкового пользователя?</h5>
    <b-alert v-if="errorText" show variant="danger">{{ errorText }}</b-alert>
    <footer>
      <b-button @click="submitForm" :disabled="isLoadingSaveForm">
        {{ isLoadingSaveForm ? 'Удаляем...' : 'Удалить' }}
      </b-button>
    </footer>
  </div>
</template>

<script>
import api from "@/api/api";
import {mapState} from "vuex";

export default {
  name: "DeleteFakeUser",
  props: {
    params: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isLoadingSaveForm: false,
      errorText: null
    }
  },
  methods: {
    async submitForm() {
      try {
        this.isLoadingSaveForm = true
        this.errorText = null
        await api.fakeUsers.delete(this.params.id)
        await this.$store.dispatch('moduleFakeUsers/getFakeUsers', {
          page: localStorage.getItem('pageNumberFakeUsers'),
        })
        await this.$emit('closeModal')
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSaveForm = false
      }
    }
  }
}
</script>


<style scoped lang="scss">
.delete-fake-user-wrapper {
  h5 {
    font-size: 16px;
    font-weight: 400;
  }

  footer {
    border-top: 1px solid #dee2e6;
    padding-top: 15px;
    margin-top: 15px;
    text-align: right;
  }
}
</style>