<template>
  <div>
    <b-alert v-if="errorText" show variant="danger">{{ errorText }}</b-alert>
    <div class="flex-column">
      <div>
        <b>Название рассылки:</b> {{ params.mailing.title }}
      </div>
      <div>
        <b>Id рассылки:</b> {{ params.mailing.id }}
      </div>
      <div>
        <b>Текст рассылки:</b> {{ params.mailing.text }}
      </div>
      <div>
        <b>Прогнозируемое кол-во получателей:</b> {{ countUsersForMailing }}
      </div>
    </div>

    <footer>
      <b-button @click="submitForm" :disabled="isLoadingSaveForm">
        {{ isLoadingSaveForm ? 'Запускаем...' : 'Запустить' }}
      </b-button>
    </footer>
  </div>
</template>

<script>
import api from "@/api/api";

export default {
  name: "RunMailing",
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoadingSaveForm: false,
      errorText: null,
      countUsersForMailing: 0,
    }
  },
  mounted() {
    api.newsletter.getExpectedMailingCount(
        JSON.parse(this.params.mailing.data)
    ).then((response) => {
      this.countUsersForMailing = response?.data?.data?.total_count || 0;
    })
  },
  methods: {
    async submitForm() {
      try {
        this.isLoadingSaveForm = true
        this.errorText = null
        await api.newsletter.runTelegramMailing(this.params.mailing.id)
        await this.$store.dispatch('moduleNewsletter/grabMailingList')
        await this.$emit('closeModal')
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSaveForm = false
      }
    }
  }
}
</script>
