<template>
  <div>
    <div>Игровые данные {{ gameProfile.slug }}</div>
    <div class="grid-mini-inputs">
      <b-form-group label="Ник" label-for="gameProfileNick">
        <b-form-input v-model="gameProfile.fields.nick" id="gameProfileNick" type="text" required/>
      </b-form-group>
      <b-form-group label="Игровой ID (10 символов)" label-for="gameProfileId">
        <b-form-input v-model="gameProfile.fields[fieldsIds[gameProfile.slug]]"
                      id="gameProfileId" required/>
      </b-form-group>
    </div>
    <b-button @click="saveData" :disabled="isLoadingSaveGameProfile" type="submit" variant="primary" class="mb-3">
      {{ isLoadingSaveGameProfile ? 'Сохраняем...' : 'Сохранить данные' }}
    </b-button>
    <b-alert v-if="errorText" show variant="danger">{{ errorText }}</b-alert>
  </div>
</template>

<script>
import api from "@/api/api";

export default {
  name: "FakeUserGameProfile",
  props: {
    gameProfile: {
      type: Object,
      default: null
    },
    userId: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      isLoadingSaveGameProfile: false,
      errorText: '',
      fieldsIds: {'pubg_mobile': 'pubg_id', 'mlbb': 'mlbb_id'},
    }
  },
  methods: {
    async saveData() {
      await this[this.gameProfile.id ? 'updateGameProfile' : 'createGameProfile']()
    },
    async updateGameProfile() {
      try {
        this.isLoadingSaveGameProfile = true
        this.errorText = null
        await api.fakeUsers.updateGameProfile(
            this.gameProfile.id,
            {
              fields: {
                nick: this.gameProfile.fields.nick,
                ...(this.gameProfile.slug.includes('pubg_mobile') && {pubg_id: this.gameProfile.fields.pubg_id}),
                ...(this.gameProfile.slug.includes('mlbb') && {mlbb_id: this.gameProfile.fields.mlbb_id}),
              }
            }
        )
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSaveGameProfile = false
      }
    },
    async createGameProfile() {
      try {
        this.isLoadingSaveGameProfile = true
        this.errorText = null

        const gameProfileApis = {
          mlbb: api.fakeUsers.createMlbbGameProfile,
          pubg_mobile: api.fakeUsers.createPubgGameProfile,
        };

        const createGameProfileApi = gameProfileApis[this.gameProfile.slug];

        const gameProfileData = {
          nick: this.gameProfile.fields.nick,
          ...(this.gameProfile.slug.includes('pubg_mobile') && {pubg_id: this.gameProfile.fields.pubg_id}),
          ...(this.gameProfile.slug.includes('mlbb') && {mlbb_id: this.gameProfile.fields.mlbb_id}),
        };
        await createGameProfileApi(this.$props.userId, gameProfileData);
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSaveGameProfile = false
      }
    },
  }
}
</script>

<style scoped lang="scss">
.grid-mini-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;

  @media screen and (max-width: 800px) {
    display: block;

  }

  & > * {
    align-self: center;
    justify-self: center;
  }
}
</style>