import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";


export const TournamentMatchesAdapterMixin = {
    computed: {
        ...mapGetters({
            getMatches: 'getTournamentMatches',
            getCurrentMatches: 'getTournamentCurrentMatches',
            getTournamentCurrentMatchesSelectedStageId: 'getTournamentCurrentMatchesSelectedStageId',

        }),
        matches: {
            get() {
                return this.getMatches
            }, set(value) {
                this.setMatches(value)
            }
        },
        currentMatches: {
            get() {
                return this.getCurrentMatches
            }, set(value) {
                this.setCurrentMatches(value)
            }
        },
        selectedCurrentMatchId: {
            get() {
                return this.getTournamentCurrentMatchesSelectedStageId
            }, set(value) {
                this.setTournamentCurrentMatchesSelectedStageId(value)
            }
        },
    },
    methods: {
        ...mapMutations({
            setMatches: mutypes.SET_TOURNAMENT_MATCHES,
            setCurrentMatches: mutypes.SET_TOURNAMENT_CURRENT_MATCHES,
            setTournamentCurrentMatchesSelectedStageId: mutypes.SET_TOURNAMENT_CURRENT_MATCHES_SELECTED_MATCH_ID,

        }),
        ...mapActions([
            'appendMatch',
            'store',
            'showGroupMatches',
            'updateMatches',
            'deleteShowMatch',
        ]),
        updateAllMatches(newMatches, currentMatches) {
            let values = [];
            values = [...newMatches, ...currentMatches]
            this.updateMatches(values).then(() => {
                this.$router.go(-1)
            })
        }
    }
}