<template>
  <div>
    <Breadcrumbs main="Модераторы" title="Редактировать модератора"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h2 class="mb-2 text-center">{{ currentOrganizerUser.email }}</h2>
              <b-form>
<!--                Возможно пригодится, пока не понятно что с этим полем делать-->
<!--                <label class="mt-2"><b>Должность</b></label>-->
<!--                <b-input v-model="currentOrganizerUser.job"></b-input>-->

<!--                <label class="mt-2"><b>Тайтл</b></label>-->
<!--                <b-input v-model="currentOrganizerUser.game"></b-input>-->

<!--                <label class="mt-2"><b>Stamp</b></label>-->
<!--                <b-input v-model="currentOrganizerUser.stamp"></b-input>-->

                <div class="mt-3">
                  <label class="font-weight-bold">Доступы</label>
                  <Multiselect :options="organizerRoles" v-model="currentOrganizerUser.roles" label="name" track-by="id" placeholder="Выберите доступы" :hide-selected="true" >
                  </Multiselect>
                </div>

                <div class="d-flex flex-wrap">
                  <b-btn variant="success" class="mt-3 mr-5" @click.prevent="updateUserRole">Сохранить</b-btn>
                  <b-btn variant="primary" class="mt-3" @click.prevent="$router.go(-1)">Назад</b-btn>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {OrganizerUserAdapterMixin} from "@/mixins/Organizer/OrganizerUsersAdapterMixin";
import Multiselect from "vue-multiselect";

export default {
  name: "OrganizersUsersEdit",
  components: {Multiselect},
  mixins: [OrganizerUserAdapterMixin],
 async created() {
   await this.grabRoles()
   await this.showUser(this.$route.params.id)
  },
}
</script>

<style>
.multiselect__tag {
  background-color: white !important;
  border: 1px solid #a6a2a2;
  font-size: 16px;
  color: #5d5b5b;
}
</style>