import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";


export const TournamentStandingsAdapterMixin = {

    data() {
        return {
            selectedNextStage: null,
            selectedNextGroup: null,
            loadStandingTournament:false,
            reasonsForDelete: [
                {id: '1', label: 'Не подтверждено участие'},
                {id: '2', label: 'Отказ от участия', thClass: 'p-1', tdClass: 'p-1'},
                {id: '3', label: 'Добавлена по ошибке', thClass: 'p-1', tdClass: 'p-1'},
            ],
            selectedReasonText: '',
        }
    },
    computed: {
        ...mapGetters({
            getTournamentGame: 'getStandingsTournamentGame',
            getTournamentName: 'getStandingsTournamentName',
            getStages: 'getStandingsStages',
            getGroups: 'getStandingsGroups',
            getStandingsTeams: 'getStandingsTeams',
            getStandingResultKeys: 'getStandingResultKeys',
            getStandingSelectedStageId: 'getStandingSelectedStageId',
            getStandingSelectedGroupId: 'getStandingSelectedGroupId',
            getStandingSelectedGroupName: 'getStandingSelectedGroupName',
            getTournamentStageGroup: 'getTournamentStageGroup',
            getSearchTeam: 'getSearchTeam',
            getGroupMatches: 'getStandingMatches',
            getSelectedTeamResults: 'getSelectedGroupMatchesResult',
            getStandingTournamentMembers: 'getStandingTournamentMembers',
            getStandingTournamentTeamMembers: 'getStandingTournamentTeamMembers',
            getStandingTournamentTeamSlots: 'getStandingTournamentTeamSlots',
            getSelectedStandingTournamentTeams: 'getStandingTournamentSelectedTeams',
            getGroupsForNextStage: 'getStandingTournamentGroupsForNextStage',
            getTournamentStatus: 'getStandingTournamentStatus',
            getCurrentStage: 'getCurrentStage',
            getTournamentStandingCurrentStatus: 'getTournamentStandingCurrentStatus',
            getTournamentStandingPrevStageId: 'getTournamentStandingPrevStageId'

        }),
        tournamentName: {
            get() {
                return this.getTournamentName
            }, set(value) {
                this.setTournamentName(value)
            }
        },
        tournamentStandingCurrentStatus: {
          get(){
              return this.getTournamentStandingCurrentStatus
          }, set(value){
              this.setTournamentStandingCurrentStatus(value)
            }
        },
        groupsForNextStage: {
            get() {
                return this.getGroupsForNextStage
            }, set(value) {
                this.setGroupsForNextStage(value)
            }
        },

        tournamentGame: {
            get() {
                return this.getTournamentGame
            }, set(value) {
                this.setTournamentGame(value)
            }
        },
        tournamentStages: {
            get() {
                return this.getStages
            }, set(value) {
                this.setStages(value)
            }
        },
        tournamentGroups: {
            get() {
                return this.getGroups
            }, set(value) {
                this.setGroups(value)
            }
        },
        groupTeams: {
            get() {
                return this.getStandingsTeams
            }, set(value) {
                this.setGroupTeams(value)
            }
        },
        tournamentResultKeys: {
            get() {
                return this.getStandingResultKeys
            }, set(value) {
                this.setResultKeys(value)
            }
        },
        selectedStageId: {
            get() {
                return this.getStandingSelectedStageId
            }, set(value) {
                this.setSelectedStageId(value)
            }
        },
        selectedGroupId: {
            get() {
                return this.getStandingSelectedGroupId
            }, set(value) {
                this.setSelectedGroupId(value)
            }
        },
        selectedGroupName: {
            get() {
                return this.getStandingSelectedGroupName
            }, set(value) {
                this.setSelectedGroupName(value)
            }
        },
        tournamentStageGroup: {
            get() {
                return this.getTournamentStageGroup
            }, set(value) {
                this.setTournamentStageGroup(value)
            }
        },
        searchTeam: {
            get() {
                return this.getSearchTeam
            }, set(value) {
                this.setSearchTeam(value)
            }
        },
        groupMatches: {
            get() {
                return this.getGroupMatches
            }, set(value) {
                this.setGroupMatches(value)
            }
        },
        selectedTeamResults: {
            get() {
                return this.getSelectedTeamResults
            }, set(value) {
                this.setSelectedTeamResults(value)
            }
        },
        stageMembers: {
            get() {
                return this.getStandingTournamentMembers
            }, set(value) {
                this.setStandingTournamentMembers(value)
            }
        },
        teamMembers: {
            get() {
                return this.getStandingTournamentTeamMembers
            }, set(value) {
                this.setStandingTournamentMembersTeam(value)
            }
        },
        teamSlots: {
            get() {
                return this.getStandingTournamentTeamSlots
            }, set(value) {
                this.setStandingTournamentTeamSlots(value)
            }
        },
        selectedStandingTournamentTeams: {
            get() {
                return this.getSelectedStandingTournamentTeams
            }, set(value) {
                this.setSelectedStandingTournamentTeam(value)
            }
        },
        tournamentStatus: {
            get() {
                return this.getTournamentStatus
            }, set(value) {
                this.setTournamentStatus(value)
            }
        },
        currentStage: {
            get() {
                return this.getCurrentStage
            }, set(value) {
                this.setCurrentStage(value)
            }
        },
        prevStageId: {
            get() {
                return this.getTournamentStandingPrevStageId
            }, set(value) {
                this.setTournamentStandingPrevStageId(value)
            }
        }

    },
    methods: {
        ...mapMutations({
            setTournamentName: mutypes.SET_TOURNAMENT_STANDING_NAME,
            setTournamentGame: mutypes.SET_TOURNAMENT_STANDING_GAME,
            setStages: mutypes.SET_TOURNAMENT_STANDING_STAGES,
            setGroups: mutypes.SET_TOURNAMENT_STANDING_GROUPS,
            setGroupTeams: mutypes.SET_TOURNAMENT_STANDING_TEAMS,
            setResultKeys: mutypes.APPEND_TOURNAMENT_STANDING_RESULT_KEY,
            setSelectedStageId: mutypes.SET_TOURNAMENT_STANDING_SELECTED_STAGE_ID,
            setSelectedGroupId: mutypes.SET_TOURNAMENT_STANDING_SELECTED_GROUP_ID,
            setSelectedGroupName: mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP_NAME,
            setTournamentStageGroup: mutypes.SET_TOURNAMENT_STANDING_STAGES_GROUP,
            setSearchTeam: mutypes.SET_TOURNAMENT_SEARCH_TEAM,
            setGroupMatches: mutypes.SET_TOURNAMENT_STANDING_MATCHES,
            setSelectedTeamResults: mutypes.SET_TOURNAMENT_STANDING_SELECTED_GROUP_RESULT,
            setStandingTournamentMembers: mutypes.SET_TOURNAMENT_STANDING_MEMBERS,
            setStandingTournamentMembersTeam: mutypes.SET_TOURNAMENT_STANDING_MEMBERS_TEAM,
            setStandingTournamentTeamSlots: mutypes.SET_TOURNAMENT_STANDING_TEAM_SLOTS,
            setSelectedStandingTournamentTeam: mutypes.SELECT_TOURNAMENT_STANDING_TEAM,
            setGroupsForNextStage: mutypes.SELECT_TOURNAMENT_STANDING_GROUPS_FOR_NEXT_STAGE,
            setTournamentStatus: mutypes.SET_TOURNAMENT_STAGE_STATUS,
            setCurrentStage: mutypes.SET_CURRENT_STAGE,
            setTournamentStandingCurrentStatus: mutypes.SET_TOURNAMENT_STANDING_CURRENT_STATUS,
            setTournamentStandingPrevStageId: mutypes.SET_TOURNAMENT_STANDING_PREV_STAGE_ID
        }),
        ...mapActions([
            'grabStandingTournament',
            'selectStage',
            'selectGroup',
            'deleteTournamentStage',
            "showTournamentStage",
            'showTournamentStage',
            "delTournamentStageGroup",
            "grabSelectedGroupMatchesResult",
            'showTeamMembers',
            'changeSlotNumber',
            'grabStandingTournamentTeamSlots',
            'selectStandingTournamentTeam',
            'filterGroupForSelectedNextStage',
            'transferTeamToNextStage',
            'transferTeamWithMembersToTournament',
            'removeStandingTeam',
            'finishTournament',
            'removeTeamInTournamentStage'
        ]),
        async updateTournamentStageClick() {
            await this.updateTournamentStage(this.selectedStageId || this.$route.params.stage_id).then(() => this.$router.go(-1))
        },
        async showTournamentStageLoad() {
            this.showTournamentStage(this.$route.params.stage_id)
        },
        async destroyTournamentStageGroup(groupId) {
            await this.delTournamentStageGroup(groupId)

        },
        async searchForTeam() {
            const search = this.searchTeam
            const res = this.groupTeams.filter(function (val) {
                return val.team.name === search
            })
            await this.setGroupTeams(res)
        },

        filterGroupsForNextStageTransfer() {
            this.filterGroupForSelectedNextStage(this.selectedNextStage);
        },

        transferTeamToNextStageWithMessage() {
            this.transferTeamToNextStage({
                next_stage_id: this.selectedNextStage,
                group_id: this.selectedNextGroup,
                tournament_team_ids: this.selectedStandingTournamentTeams
            }).then((response) => {
                this.$toasted.show(response.data.message, {
                    theme: "outline",
                    position: "top-right",
                    type: "success",
                    duration: 2000,
                });
            }).then(() => {
                this.$store.dispatch('grabStandingTournament', this.$route.params.standingId);
            })
            this.selectedStandingTournamentTeams = []
        },
        //@todo далее разобраться нафиг этот метод сделали
        async grabStandingTournamentWithId(id) {
            this.loadStandingTournament = true;
            await this.grabStandingTournament(id).finally(()=>{
                this.loadStandingTournament = false;
            })
        },
        resetCheckbox() {
            this.selectedReasonText = '';
        },
    }
}
