<template>
  <div class="pair-results-wrapper">
    <div class="d-flex align-items-center mb-3 justify-content-between">
      <h4>{{ $props.pairResult.first_team.name }} - {{ $props.pairResult.second_team.name }}</h4>
      <div class="action-buttons">
        <b-button :variant="isWinner ? 'success' : 'light'"
                  class="action-button"
                  @click="sendPairResToTeam( { teamPairId: $props.pairResult.id, firstTeamId: selectedTeamId, secondTeamId: secondTeamId })">
          W
        </b-button>
        <b-button :variant="isLose ? 'danger' : 'light'"
                  class="action-button"
                  @click="sendPairResToTeam({ teamPairId: $props.pairResult.id, firstTeamId: secondTeamId, secondTeamId:  selectedTeamId })">
          L
        </b-button>
      </div>
    </div>
    <div class="body">
      <div class="matches-data">
        <table>
          <tr>
            <td>Название матча</td>
            <td>Победа</td>
            <td>Проигрыш</td>
          </tr>
          <RowInOlympicMatch v-for="match in pairResult.team_pair_matches"
                             :key="'pairMatchId' + match.id"
                             :matchData="match"
                             :pair="pairResult"
                             @error="handleError"/>
        </table>
          <b-button variant="primary"
                    class="action-button mt-2"
                    @click="isShowAddMatchModal = true">
            Добавить матч
          </b-button>
      </div>
    </div>
    <b-alert v-if="errorMessage" show variant="danger" class="mt-2">{{ errorMessage }}</b-alert>
    <Modal name="AddMatchForOlympic" title="Добавить матч" v-model="isShowAddMatchModal" :params="{pairId: pairResult.id}"/>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import api from "@/api/api";
import RowInOlympicMatch from "@/components/Tournament/MLBB/components/RowInOlympicMatch.vue";
import Modal from "@/components/modals/index.vue";

export default {
  name: "PairResultsData",
  components: {RowInOlympicMatch, Modal},
  data() {
    return {
      isLoadingSendingRes: false,
      isShowAddMatchModal: false,
      errorMessage: null
    }
  },
  props: {
    pairResult: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['selectedGroupId', 'selectedTeamId']),
    isWinner() {
      const currentTeam = this.$props.pairResult?.team_pair_results.find(teamData => teamData.team_id === this.selectedTeamId);
      return currentTeam?.result_fields?.is_winner;
    },
    isLose() {
      const currentTeam = this.$props.pairResult?.team_pair_results.find(teamData => teamData.team_id === this.selectedTeamId);
      return currentTeam && currentTeam?.result_fields && currentTeam?.result_fields.is_winner === 0;
    },
    secondTeamId() {
      return this.$props.pairResult.first_team_id !== this.selectedTeamId ? this.$props.pairResult.first_team_id : this.$props.pairResult.second_team_id;
    }
  },
  methods: {
    async sendPairResToTeam({ teamPairId, firstTeamId, secondTeamId }) {
      try {
        this.isLoadingSendingRes = true
        this.errorMessage = null
        await api.tournament.storePairResInOlympic({
          team_pair_id: teamPairId,
          winner_team_id: firstTeamId,
          lose_team_id: secondTeamId,
        })
        await this.$store.dispatch('moduleNewTournament/getOlympicMatches', {
          groupId: this.selectedGroupId,
          teamId: this.selectedTeamId
        })
      } catch (error) {
        this.errorMessage = error.response.data.message || 'Не удалось отправить результаты матча'
      } finally {
        this.isLoadingSendingRes = false
      }
    },
    handleError(errorMessage) {
     this.errorMessage = errorMessage
    }
  }
}
</script>

<style scoped lang="scss">
.pair-results-wrapper {

  h4 {
    font-size: 18px;
    margin-right: 30px;
  }

  .action-buttons {
    display: flex;
    gap: 10px;

    .action-button {
      border-radius: 10px;
      padding: 5px 10px;;
    }
  }

  .body {
    @media screen and (max-width: 400px) {
      width: 300px;
    }

    @media screen and (max-width: 370px) {
      width: 270px;
    }

    @media screen and (max-width: 350px) {
      width: 250px;
    }

    @media screen and (max-width: 350px) {
      width: 230px;
    }

    .matches-data {
      width: 100% !important;

      @media screen and (max-width: 400px) {
        overflow-x: scroll;
      }

      table {
        width: 100%;

        tr {
          transition: .2s;
        }

        td {
          padding: 10px;

          @media screen and (max-width: 800px) {
            font-size: 12px;
          }
        }

        & > tr:not(:last-child) {
          border-bottom: 1px solid #00000026;
        }

        & > tr:not(:first-child) {
          &:hover {
            background: #00000026;
          }
        }

        & > tr > td:not(:last-child) {
          border-right: 1px solid #00000026;
        }

        & > tr > td:not(:first-child) {
          text-align: center;
        }
      }
    }
  }
}
</style>