import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const FeaturesAdapterMixin = {
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            getFeaturesList                     : 'getFeaturesList',
        }),
        featuresList             : {get() {return this.getFeaturesList                 }, set(value) {this.setFeaturesList(value)     }},
    },
    methods: {
        ...mapMutations({
            setFeaturesList                  : mutypes.SET_FEATURES,
        }),
        ...mapActions([
            'grabFeaturesList',
        ]),
    }
}