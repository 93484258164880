import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/match';
const NEW_END_POINT = '/api/v1/admin/group-match'

export default {
    /**
     * Post: Store matches
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    
    store: (params) => adminHttp.post(NEW_END_POINT,params),
    
    /**
     * Post: Update matches
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    update: (params) => adminHttp.put(NEW_END_POINT + '/mass-update',params),
    
    /**
     * Get: Get group matches
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    showMatches: (id) => adminHttp.get(NEW_END_POINT + `/group/${id}`),
    
    /**
     * del: Delete group match
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteMatch: (id) => adminHttp.delete(NEW_END_POINT + `/${id}`)
}