<template>
  <div class="show-statistics-workspace-wrapper">
    <!-- HEADER -->
    <header>
      <button v-if="isShowBackButton" class="button-back" @click="$emit('changeWorkspace', undefined)">
        <img :src="AngleLeftIcon" alt="" />
        Назад
      </button>
      <h2>{{ headerTitle }}</h2>
    </header>

    <section class="statistics-section">
      <div class="card-container" v-if="filteredTournamentStatistics.length">
        <div v-for="(stat, index) in filteredTournamentStatistics" :key="stat.id" class="team-card">
          <div class="team-header">
            {{ index + 1 }}. {{ stat.name }}
          </div>
          <div class="team-info">
            <table class="stats-table">
              <tbody>
                <tr>
                  <td>Total Kills:</td>
                  <td>{{ stat.kill_pts }}</td>
                </tr>
                <tr>
                  <td>Total Place Points:</td>
                  <td>{{ stat.place_pts }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <button class="details-button" :class="{ 'hide-details': selectedTeamId === stat.id }" @click="toggleTeamDetails(stat.id)">
            {{ selectedTeamId === stat.id ? 'Скрыть' : 'Подробнее' }}
          </button>

          <div v-if="selectedTeamId === stat.id && currentTeamStats.length" class="team-details">
            <h4>Статистика игроков:</h4>
            <table>
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Kill Points</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="member in currentTeamStats" :key="member.user_id">
                  <td>{{ member.username }}</td>
                  <td>{{ member.kill_pts }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AngleLeftIcon from '@/assets/icons/angle-left.svg'

export default {
  name: 'ShowStatisticsWorkspace',
  data() {
    return {
      AngleLeftIcon,
      headerTitle: 'Статистика по турниру',
      selectedTeamId: null,
      isShowBackButton: true,
    };
  },
  computed: {
    ...mapGetters('moduleNewTournament', [
      'selectedGroup',
      'isCompletedTournament',
      'tournament',
      'tournamentStatistics',
      'filteredTeamIds',
      'teamStatistics' // This should be used as a function
    ]),
    filteredTournamentStatistics() {
      return this.tournamentStatistics.filter(stat => this.filteredTeamIds.includes(stat.id));
    },
    currentTeamStats() {
      return this.teamStatistics(this.selectedTeamId) || [];
    },
  },
  methods: {
    ...mapActions('moduleNewTournament', [
      'fetchTournamentStatistics',
      'fetchTeamStatistics'
    ]),

    handleFetchTeamStatistics(teamId) {
      this.selectedTeamId = teamId;
    },

    toggleTeamDetails(teamId) {
      if (this.selectedTeamId === teamId) {
          this.selectedTeamId = null;
      } else {
          this.selectedTeamId = teamId;
          this.fetchTeamStatistics({
              tournamentId: this.tournament.id,
              teamId: teamId,
              gameId: this.tournament.game_id
          });
      }
    },

    goBack() {
      this.$emit('changeWorkspace', undefined);
    },

    fetchAllTournamentStatistics() {
      this.fetchTournamentStatistics(this.tournament.id).catch(error => {
        console.error('Error fetching all tournament statistics:', error);
      });
    },
  },
  mounted() {
    this.fetchAllTournamentStatistics();
  }
}
</script>

<style scoped>
.show-statistics-workspace-wrapper {

  .statistics-section {
    margin-top: 15px;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: left;

    @media (max-width: 560px) {
      display: block;
    }

    h2 {
      font-size: 24px;
      margin-left: 10px;
      @media (max-width: 800px) {
        font-size: 22px;
      }
      @media (max-width: 500px) {
        font-size: 18px;
      }
    }
  }

  .button-back {
    background: transparent;
    border: none;
    border-radius: 15px;
    padding: 8px 14px;
    font-size: 14px;
    transition: 0.2s;
    display: flex;
    align-items: center;
    color: #7366ff;
    &:hover {
      background: #dad6ff;
    }
    img {
      margin-right: 7px;
      height: 12px;
    }
  }

  .card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }

  .team-card {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: 0.3s;
    height: fit-content;
    &:hover {
      box-shadow: 0 6px 10px rgba(0,0,0,0.15);
    }
    .team-header {
      font-weight: bold;
    }
    .team-info {
      margin-top: 5px;
    }

    .stats-table {
      width: 100%;
      border-collapse: collapse;
    }

    .stats-table td {
      padding: 4px 8px;
      border-bottom: 1px solid #3d3d5c;
    }

    .stats-table td:last-child {
      text-align: right;
    }

    .stats-table tr:last-child td {
      border-bottom: none;
    }

    .stats-table td:first-child {
      color: #a39bff;
      font-weight: bold;
    }

    .details-button {
      margin-top: 10px;
      background: #7366ff;
      color: white;
      border: none;
      border-radius: 5px;
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        background: #5a4eda;
      }
    }

    .hide-details {
      background: #b0b0b0;
      &:hover {
        background: #9e9e9e;
      }
    }
  }

  .team-details {
    margin-top: 20px;
    table {
      width: 100%;
      border-collapse: collapse;
      th, td {
        border: 1px solid #ddd;
        padding: 8px;
      }
      th {
        background-color: #7366ff;
        color: white;
      }
    }
  }
}
</style>