import Menu from '../../data/menu'
import api from "@/api/api";
const state = {
  data: [], // Хранение данных меню
  searchData: [], // Хранение результатов поиска по меню
  togglesidebar: true // Состояние боковой панели (открыта/закрыта)
}

// getters
const getters = {
// если необходимо можете добавить геттеры
}

// mutations
const mutations = {
  // Переключение состояния боковой панели
  opensidebar: (state) => {
    state.togglesidebar = !state.togglesidebar
  },
  //@todo потом это стоит переписать пока по фасту здесь делаю

  // Установка данных меню
  setMenu: (state,data) => {
    state.data = data;
  },

  // Переключение боковой панели в зависимости от размера экрана
  resizetoggle: (state) => {
    if (window.innerWidth < 1199) {
      state.togglesidebar = false
    } else {
      state.togglesidebar = true
    }
  },

  // Поиск по телефону и фильтрация результатов
  searchTerm: (state, term) => {
    let items = [];
    var searchval = term.toLowerCase();
    state.data.filter(menuItems => {
      if (menuItems.title) {
        if (menuItems.title.toLowerCase().includes(searchval) && menuItems.type === 'link') {
          items.push(menuItems);
        }
        if (!menuItems.children) return false
        menuItems.children.filter(subItems => {
          if (subItems.title.toLowerCase().includes(searchval) && subItems.type === 'link') {
            subItems.icon = menuItems.icon
            items.push(subItems);
          }
          if (!subItems.children) return false
          subItems.children.filter(suSubItems => {
            if (suSubItems.title.toLowerCase().includes(searchval)) {
              suSubItems.icon = menuItems.icon
              items.push(suSubItems);
            }
          })
        })
        state.searchData = items
      }
    });
  },

  // Установка активного элемента навигации
  setNavActive: (state, item) => {
    if (!item.active) {
      state.data.forEach(a => {
        if (state.data.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  },

  // Установка активного маршрута
  setActiveRoute: (state, item) => {
    state.data.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }
};

// actions
const actions = {
  // Вызов мутации для открытия/закрытия боковой панели
  opensidebar: (context, term) => {
    context.commit('opensidebar', term)
  },
  // Вызов мутации для переключения состояния боковой панели в зависимости от размера экрана
  resizetoggle: (context, term) => {
    context.commit('resizetoggle', term)
  },
  // Вызов мутации для поиска по термину
  searchTerm: (context, term) => {
    context.commit('searchTerm', term)
  },
  // Вызов мутации для установки активного элемента навигации
  setNavActive: (context, item) => {
    context.commit('setNavActive', item)
  },
  // Вызов мутации для установки активного маршрута
  setActiveRoute: (context, item) => {
    context.commit('setActiveRoute', item)
  },
  // Получение элементов меню с сервера
  grabMenuItems: async (context) => {
    const menu = await api.menu.index();
    context.commit('setMenu', menu.data.data);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}