import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            mailings: [],
            pagination: {
                by: 10,
                currentPage: 1,
                totalPages: 1,
            },
            mailingCountries: [],
            mailingDivisions: [],
            mailingTournaments: [],
            messageTypes: [],
            tournamentTypes: [],
            roles: [],
            mailingResult: null,
            telegramMailings: [],
            telegramMailingsPagination: {
                by: 10,
                currentPage: 1,
                totalPages: 1,
            },
        }
    },
    mutations: {
        [mutypes.SET_MAILING]: (state, value) => state.mailings = value,
        [mutypes.SET_MAILING_PAGINATION_TOTAL]: (state, value) => state.pagination.totalPages = value,
        [mutypes.SET_MAILING_PAGINATION_CURRENT_PAGE]: (state, value) => state.pagination.currentPage = value,
        [mutypes.SET_MAILING_COUNTRIES]: (state, value) => state.mailingCountries = value,
        [mutypes.SET_MAILING_DIVISIONS]: (state, value) => state.mailingDivisions = value,
        [mutypes.SET_MAILING_TOURNAMENTS]: (state, value) => state.mailingTournaments = value,
        [mutypes.SET_MAILING_MESSAGE_TYPES]: (state, value) => state.messageTypes = value,
        [mutypes.SET_MAILING_TOURNAMENT_TYPES]: (state, value) => state.tournamentTypes = value,
        [mutypes.SET_MAILING_ROLES]: (state, value) => state.roles = value,
        [mutypes.SET_MAILING_RESULT]: (state, value) => state.mailingResult = value,
        [mutypes.SET_TELEGRAM_MAILING]: (state, value) => state.telegramMailings = value,
        [mutypes.SET_TELEGRAM_MAILING_PAGINATION_TOTAL]: (state, value) => state.telegramMailingsPagination.totalPages = value,
        [mutypes.SET_TELEGRAM_MAILING_PAGINATION_CURRENT_PAGE]: (state, value) => state.telegramMailingsPagination.currentPage = value,
    },

    getters: {
        getMailing: state => state.mailings,
        getMailingPaginationTotal: state => state.pagination.totalPages,
        getMailingPaginationCurrentPage: state => state.pagination.currentPage,
        getMailingCountries: state => state.mailingCountries,
        getMailingDivisions: state => state.mailingDivisions,
        getMailingTournaments: state => state.mailingTournaments,
        getMessageTypes: state => state.messageTypes,
        getTournamentTypes: state => state.tournamentTypes,
        getMailingRoles: state => state.roles,
        getMailingResult: state => state.mailingResult,
        getTelegramMailing: state => state.telegramMailings,
        getTelegramMailingPaginationTotal: state => state.telegramMailingsPagination.totalPages,
        getTelegramMailingPaginationCurrentPage: state => state.telegramMailingsPagination.currentPage,
    },

    actions: {
        grabMailingList: async ({commit, getters}, params) => {
            let response = await api.newsletter.index({
                page: params.pageNumber,
                id: params.id,
                search: params.search,
                "filter[status]": params.status,
                "filter[is_read]": params.is_read,

            });
            commit(mutypes.SET_MAILING, response.data.telegram_mailings);
            commit(mutypes.SET_MAILING_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_MAILING_PAGINATION_CURRENT_PAGE, params.pageNumber);
        },
        async storeMailing({commit, getters}, params) {
            await api.newsletter.storeTelegramMailing(params)
                .then(() => {
                    this._vm.$toast("Вы успешно создали рассылку");
                })
                .catch(() => {
                    this._vm.$toast("Не удалось создать рассылку");
                });
        },
        getCountries: async ({commit, getters}) => {
            const response = await api.newsletter.getCountries()

            let countries = response.data.countries
            let values = []
            countries.forEach((item, index) => {
                values[index] = {
                    text: item.name,
                    value: item.id
                }
            })
            localStorage.setItem('countries', JSON.stringify(values));
            commit(mutypes.SET_MAILING_COUNTRIES, values)

        },
        getDivisions: async ({commit, getters}) => {
            const response = await api.divisions.indexDivisions({"slug": "pubg_mobile"});

            let divisions = response.data.divisions
            let values = []
            divisions.forEach((item, index) => {
                values[index] = {
                    text: item.name['ru'],
                    value: item.id
                }
            })
            commit(mutypes.SET_MAILING_DIVISIONS, values)
        },
        getTournamentsList: async ({commit, getters}) => {
            const getTournaments = async function (pageNo = 1) {
                let response = await api.tournament.index({
                    page: pageNo
                })
                const result = response.data.tournaments.filter((el) => {
                    return el.status.code !== 'ended'
                })
                return result
            }

            const getEntireTournamentList = async function (pageNo = 1) {
                const results = await getTournaments(pageNo);
                if (results.length > 0) {
                    return results.concat(await getEntireTournamentList(pageNo + 1));
                } else {
                    return results;
                }
            };

            getEntireTournamentList().then((res) => {
                let values = []
                res.forEach((item, index) => {
                    values[index] =
                        {
                            text: item.name,
                            value: item.id
                        }
                })
                commit(mutypes.SET_MAILING_TOURNAMENTS, values)
            })
        },
        getMessageTypesList: async ({commit, getters}) => {
            const response = await api.newsletter.messageTypes()
            let messages = response.data.statuses
            let values = []
            messages.forEach((item, index) => {
                values[index] = {
                    text: item.code,
                    value: item.id
                }
            })
            commit(mutypes.SET_MAILING_MESSAGE_TYPES, values)

        },
        getTournamentTypesList: async ({commit, getters}) => {
            const response = await api.newsletter.tournamentTypes()
            let tournaments = response.data.statuses
            let values = []
            tournaments.forEach((item, index) => {
                values[index] = {
                    text: item.code,
                    value: item.id
                }
            })
            commit(mutypes.SET_MAILING_TOURNAMENT_TYPES, values)
        },
        getRoles: async ({commit, getters}) => {
            const response = await api.roles.indexRoles()
            let roles = response.data.roles
            let values = []
            roles.forEach((item, index) => {
                values[index] = {
                    text: item.name,
                    value: item.id
                }
            })
            commit(mutypes.SET_MAILING_ROLES, values)
        },
        async filteredMailingFunc({commit, getters}, params) {
            const response = await api.newsletter.getFilteredMailing(params)
            commit(mutypes.SET_MAILING_RESULT, response.data.social_account_count)
        },
        grabTelegramMailingList: async ({commit, getters}, params) => {
            let response = await api.newsletter.getTelegramMailing({
                page: params.pageNumber,
                "search": params.search
            });
            console.log("response.data");
            console.log(response.data);
            commit(mutypes.SET_TELEGRAM_MAILING, response.data.telegram_mailings);
            commit(mutypes.SET_TELEGRAM_MAILING_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_TELEGRAM_MAILING_PAGINATION_CURRENT_PAGE, params.pageNumber);
        },
        async storeTelegramMailing({commit, getters}, params) {
            await api.newsletter.storeTelegramMailing(params)
                .then(() => {
                    this._vm.$toast("Вы успешно создали телеграм рассылку");
                })
                .catch(() => {
                    this._vm.$toast("Не удалось создать телеграм рассылку");
                });
        },
    }
}