import api from "/src/api/api";
import * as mutypes from "/src/store/mutation.types";

export default {
    state: () => ({
        wowStreamers: [],
        wowStreamer: null,
        pagination: {
            by: 10,
            currentPage: 1,
            totalPages: 1,
        },
        wowStreamerEntries: [],
        approvedWowStreamers: [],
        bannedWowStreamers: [],
        rejectedWowStreamers: [],
    }),
    mutations: {
        [mutypes.SET_WOWSTREAMERS]: (state, value) => state.wowStreamers = value,
        [mutypes.SET_APPROVED_WOWSTREAMERS]: (state, value) => state.approvedWowStreamers = value,
        [mutypes.SET_REJECTED_WOWSTREAMERS]: (state, value) => state.rejectedWowStreamers = value,
        [mutypes.SET_BANNED_WOWSTREAMERS]: (state, value) => state.bannedWowStreamers = value,
        [mutypes.SET_WOWSTREAMER_ENTRIES]: (state, value) => state.wowStreamerEntries = value,
        [mutypes.SET_WOWSTREAMERS_PAGINATION_TOTAL]: (state, value) => state.pagination.totalPages = value,
        [mutypes.SET_WOWSTREAMERS_PAGINATION_CURRENT_PAGE]: (state, value) => state.pagination.currentPage = value,
        [mutypes.SET_WOWSTREAMER]: (state, value) => state.wowStreamer = value,
    },
    getters: {
        getWowStreamers: state => state.wowStreamers,
        getApprovedWowStreamers: state => state.approvedWowStreamers,
        getWowStreamersPaginationCurrentPage: state => state.pagination.currentPage,
        getRejectedWowStreamers: state => state.rejectedWowStreamers,
        getBannedWowStreamers: state => state.bannedWowStreamers,
        getWowStreamerEntries: state => state.wowStreamerEntries,
        getWowStreamersPaginationTotal: state => state.pagination.totalPages,
        getWowStreamer: state => state.wowStreamer,
    },
    actions: {
        async fetchWowStreamers({ commit }, params) {
            let response = await api.wowStreamers.getAll({
                page: params.pageNumber,
                "search": params.search,
            });
            commit(mutypes.SET_WOWSTREAMERS, response.data.data);
            commit(mutypes.SET_WOWSTREAMERS_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_WOWSTREAMERS_PAGINATION_CURRENT_PAGE, params.pageNumber);
        },
        async fetchWowStreamerEntries({ commit }, params) {
            let response = await api.wowStreamers.getEntries({
                page: params.pageNumber,
                search: params.search,
            });
            commit(mutypes.SET_WOWSTREAMER_ENTRIES, response.data.data);
            commit(mutypes.SET_WOWSTREAMERS_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_WOWSTREAMERS_PAGINATION_CURRENT_PAGE, params.pageNumber);
        },
        async fetchApprovedWowStreamers({ commit }, params) {
            let response = await api.wowStreamers.getApproved({
                page: params.pageNumber,
                search: params.search,
            });
            commit(mutypes.SET_APPROVED_WOWSTREAMERS, response.data.data);
            commit(mutypes.SET_WOWSTREAMERS_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_WOWSTREAMERS_PAGINATION_CURRENT_PAGE, params.pageNumber);
        },
        async fetchRejectedWowStreamers({ commit }, params) {
            let response = await api.wowStreamers.getRejected({
                page: params.pageNumber,
                search: params.search,
            });
            commit(mutypes.SET_REJECTED_WOWSTREAMERS, response.data.data);
            commit(mutypes.SET_WOWSTREAMERS_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_WOWSTREAMERS_PAGINATION_CURRENT_PAGE, params.pageNumber);
        },
        async fetchBannedWowStreamers({ commit }, params) {
            let response = await api.wowStreamers.getBanned({
                page: params.pageNumber,
                search: params.search,
            });
            commit(mutypes.SET_BANNED_WOWSTREAMERS, response.data.data);
            commit(mutypes.SET_WOWSTREAMERS_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_WOWSTREAMERS_PAGINATION_CURRENT_PAGE, params.pageNumber);
        },
        async fetchWowStreamer({ commit }, id) {
            let response = await api.wowStreamers.getOne(id);
            commit(mutypes.SET_WOWSTREAMER, response.data.data);
        },
        async createWowStreamer({ dispatch }, params) {
            await api.wowStreamers.create(params.userId, params.data);
            dispatch('fetchWowStreamers', { pageNumber: 1 });
        },
        async updateWowStreamer({ dispatch }, params) {
            await api.wowStreamers.update(params.streamerId, params.data);
            dispatch('fetchWowStreamers', { pageNumber: 1 });
        },
        async deleteWowStreamer({ dispatch }, id) {
            await api.wowStreamers.delete(id);
            dispatch('fetchWowStreamers', { pageNumber: 1 });
        },
        async banWowStreamer({ dispatch }, params) {
            await api.wowStreamers.ban(params.streamerId, params.data);
            // dispatch('fetchWowStreamers', { pageNumber: 1 });
        },
        async unbanWowStreamer({ dispatch }, id) {
            await api.wowStreamers.unban(id);
            dispatch('fetchBannedWowStreamers', { pageNumber: 1 });
        },
        async approveWowStreamer({ dispatch }, id) {
            await api.wowStreamers.approve(id);
            // dispatch('fetchApprovedWowStreamers', { pageNumber: 1 });
        },
        async rejectWowStreamer({ dispatch }, params) {
            await api.wowStreamers.reject(params.streamerId, params.data);
            // dispatch('fetchWowStreamerEntries', { pageNumber: 1 });
        },
        async deactivateWowStream({ dispatch }, params) {
            await api.wowStreamers.deactivate(params.streamId, params.data);
        },
    }
};