<template>
  <div class="have-teams-workspace-wrapper">
    <MainActions v-if="!isCompletedTournament" v-model="selectedTeams" />
    <div class="actions">
      <div>
        <b-input v-model="searchTeamModel"
                 @input="callFilteredTeams"
                 class="search-teams-input-custom"
                 v-b-tooltip.hover.top="'Поиск доступен по названию команды, nick и MLBB_ID'"
                 placeholder="Поиск команды" />
      </div>
      <div class="teams-count">Количество команд в этапе: {{stageTeamsCount}}</div>
    </div>
    <transition-group name="list-complete" tag="div" class="group-cards">
      <GroupCard v-for="(group, indexG) in cloneGroups"
                 :group="group"
                 v-model="selectedTeams"
                 :teamsForTransfer="teamsForTransfer"
                 :isLoadingSaveTransferredTeams="isLoadingSaveTransferredTeams"
                 @transferredTeam="transferredTeam"
                 @changeWorkspace="(name) => $emit('changeWorkspace', name)"
                 :key="`${ indexG }` + group.id + '-group-card-' + group.name" />
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import MainActions from "@/components/Tournament/MainActions.vue";
import GroupCard from "@/components/Tournament/MLBB/components/GroupCard.vue";
import debounce from 'lodash.debounce'
import api from "@/api/api";

export default {
  name: "HaveTeamsWorkspace",
  components: {
    MainActions,
    GroupCard
  },
  computed: {
    ...mapState('moduleNewTournament', ['selectedStageId', 'selectedRoundId', 'groupsWithTeamsByMLBB', 'tournament', 'stageTeamsCount']),
    ...mapGetters('moduleNewTournament', ['selectedGroup', 'selectedStage', 'isCompletedTournament']),
  },
  data() {
    return {
      cloneGroups: [],
      selectedTeams: [],
      teamsForTransfer: [],
      isLoadingSaveTransferredTeams: false,
      searchTeamModel: ''
    }
  },
  methods: {
    ...mapMutations('moduleNewTournament', ['setFirstLevelData']),
    transferredTeam(transferTeam) {
      const hasIndexTeamForTransfer = this.teamsForTransfer.findIndex((team) => team.stageTeamId === transferTeam.stageTeamId)

      if (hasIndexTeamForTransfer > -1) {
        this.$set(this.teamsForTransfer, hasIndexTeamForTransfer, transferTeam)
      } else {
        this.teamsForTransfer.push(transferTeam)
      }
    },
    async updatePositionTeams() {
      this.isLoadingSaveTransferredTeams = true
      await Promise.all(this.teamsForTransfer.filter(({ error }) => !error).map(async (team) => {
        const indexTransferTeam = this.teamsForTransfer.findIndex(({ teamId }) => team.teamId === teamId)
        try {
          await api.tournament.mustNewTransferTeamsToNextStage(team.groupResultTeamId, {
            group_id: team.newGroupId
          })
          this.$delete(this.teamsForTransfer, indexTransferTeam)
        } catch (e) {
          this.teamsForTransfer[indexTransferTeam].error = e.response?.data?.message || e.response.data.errors.message
        }
      }))
      await this.$store.dispatch('moduleNewTournament/getGroupsWithTeamsByMLBB', { roundId: this.selectedRoundId })
      await this.$store.dispatch('moduleNewTournament/getTournament', {
        id: this.$route.params.standingId
      })
      this.isLoadingSaveTransferredTeams = false
    },
    callUpdatePositionTeams: debounce(function () {
      this.updatePositionTeams()
    }, 2000),
    callFilteredTeams: debounce(function () {
      this.filteredTeams()
    }, 800),
    filteredTeams() {
      const byTeams = this.groupsWithTeamsByMLBB.filter((group) => {
        return group.group_teams.some(({ team }) => team ? team.name.toLowerCase().search(this.searchTeamModel.toLowerCase()) > -1 : -1)
      })
      const byMembersNickAndId = this.groupsWithTeamsByMLBB.filter((group) => {
        return group.group_teams.some(({ group_users }) => {
          return group_users.some((user) => {
            const gameProfile = user.game_profiles.find(({ game_id }) => game_id === this.tournament.game_id)
            const nick = gameProfile?.fields?.nick || ''
            const id = String(gameProfile?.fields?.mlbb_id) || ''
            return nick.toLowerCase().search(this.searchTeamModel.toLowerCase()) > -1 || id.toLowerCase().search(this.searchTeamModel.toLowerCase()) > -1
          })
        })
      })
      const resultTeams = [
        ...byTeams,
        ...byMembersNickAndId
      ]
      const unicalResultTeams = [...new Set(resultTeams.map(({ id }) => id ))]
      const result = unicalResultTeams.map((unicalId) => resultTeams.find(({ id }) => id === unicalId))
      this.cloneGroups = JSON.parse(JSON.stringify(result))
    }
  },
  watch: {
    groupsWithTeamsByMLBB: {
      deep: true,
      immediate: true,
      handler() {
        this.cloneGroups = JSON.parse(JSON.stringify(this.groupsWithTeamsByMLBB || []))
      }
    },
    teamsForTransfer: {
      deep: true,
      handler() {
        this.callUpdatePositionTeams()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}
.list-complete-enter, .list-complete-leave-to
  /* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(30px);
}
.list-complete-leave-active {
  position: absolute;
}
.have-teams-workspace-wrapper {
  .card-wrapper {
    background: white;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .group-cards {
    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;

    @media screen and (max-width: 500px) {
      display: block;
    }

    .teams-count {
      background-color: white;
      border-radius: 0.25rem;
      padding: 0.375rem 0.75rem;
      border: 1px solid #ced4da;
      color: #707070;
      font-size: 13px;

      @media screen and (max-width: 500px) {
        margin-top: 10px;
        width: fit-content;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      @media screen and (max-width: 500px) {
        margin-top: 14px;
        justify-content: flex-start;
      }
    }

    .search-teams-input-custom {
      font-size: 13px;
    }
  }
}
</style>