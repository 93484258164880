<template>
  <div>
    <b-form-input
        type="number"
        @change="addResultTeamPlacePts({
                          match_id:Number.parseInt($props.match_id),
                          place_pts:Number.parseInt(placePts)
                        })"
        v-model="placePts"
    >

    </b-form-input>
  </div>

</template>

<script>
import {TournamentGroupResultAdapterMixin} from "../mixins/TournamentGroupResult/TournamentGroupResultAdapterMixin";

export default {
  name: "PlacePtsInput",
  mixins: [TournamentGroupResultAdapterMixin],
  data() {
    return {
      placePts: 0,
    };
  },
  created() {
    this.placePts = this.$props.place_pts;
  },
  props: {
    match_id: {
      type: Number,
      default: 0
    },
    place_pts: {
      type: Number,
      default: 0
    }
  },
}
</script>

<style scoped>

</style>