import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const AuthAdapterMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters({
            getAuthToken        : 'getAuthToken',
            getAuthData         : 'getAuthData',
            getAuthDataEmail    : 'getAuthDataEmail',
            getAuthDataPassword : 'getAuthDataPassword',
            getUserName         : 'getUserName',
            getAuthUserEmail        : 'getAuthUserEmail',
            getOrganizersForUser   : 'getOrganizersForUser',
            getUserEmail   : 'getUserEmail',
            getUserRoles   : 'getUserRoles'
        }),
        authToken             : {get() {return this.getAuthToken                 }, set(value) {this.setAuthToken(value)       }},
        authData             : {get() {return this.getAuthData                 }, set(value) {this.setAuthData(value)       }},
        authDataEmail             : {get() {return this.getAuthDataEmail                 }, set(value) {this.setAuthDataEmail(value)       }},
        authDataPassword              : {get() {return this.getAuthDataPassword                }, set(value) {this.setAuthDataPassword(value) }},
        authUserEmail             : {get() {return this.getAuthUserEmail                 }, set(value) {this.setAuthUserEmail(value)       }},
        userName             : {get() {return this.getUserName                 }, set(value) {this.setUserName(value)       }},
        organizationsForUser             : {get() {return this.getOrganizersForUser                 }, set(value) {this.setOrganizersForUser(value)       }},
        userEmail             : {get() {return this.getUserEmail                 }, set(value) {this.setUserEmail(value)       }},
        userRoles             : {get() {return this.getUserRoles                 }, set(value) {this.setUserRoles(value)       }},
    },
    methods: {
        ...mapMutations({
            setAuthData    : mutypes.SET_AUTH_DATA,
            setAuthToken    : mutypes.SET_AUTH_TOKEN,
            setAuthDataEmail: mutypes.SET_AUTH_DATA_EMAIL,
            setAuthDataPassword: mutypes.SET_AUTH_DATA_PASSWORD,
            setAuthUserEmail:mutypes.SET_AUTH_USER_EMAIL,
            setUserName:mutypes.SET_AUTH_USER_NAME,
            setOrganizersForUser:mutypes.SET_USER_ORGANIZATIONS,
            setUserEmail:mutypes.SET_USER_EMAIL,
            setUserRoles:mutypes.SET_USER_ROLES
        }),
        ...mapActions([
            'authLogin',
            'authLogout'
        ]),
    }
}