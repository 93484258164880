<template>
  <div class="mt-4">
    <div
      v-for="tournamentPrize in tournamentPrizes"
      :key="'prizePts_' + tournamentPrize.id"
    >
      <AddTournamentPrizeFormVue
        :index="tournamentPrize.id"
        :place="tournamentPrize.place"
        :dollars="tournamentPrize.prize_data.dollars"
        :key="'prize_form_' + tournamentPrizes.id"
      />
    </div>
    <div class="d-flex justify-content-center flex-wrap">
      <button class="btn btn-success mt-4" @click="addTournamentPrize">
        Добавить приз
      </button>
      <button class="btn btn-danger mt-4 ml-2" @click="deleteTournamentPlaces">Удалить приз</button>
    </div>
  </div>
</template>

<script>
import { TournamentAdapterMixin } from "@/mixins/Tournament/TournamentAdapterMixin";
import { TournamentGroupResultAdapterMixin } from "@/mixins/TournamentGroupResult/TournamentGroupResultAdapterMixin";
import { TournamentStandingsAdapterMixin } from "@/mixins/Tournament/TournamentStandingsAdapterMixin";
import AddTournamentPrizeFormVue from "./AddTournamentPrizeForm.vue";

export default {
  name: "AddTournamentPrice",
  components: { AddTournamentPrizeFormVue },
  mixins: [
    TournamentAdapterMixin,
    TournamentGroupResultAdapterMixin,
    TournamentStandingsAdapterMixin,
  ],
  methods: {
    deleteTournamentPlaces() {
      
      if (this.tournamentPrizes.length > 1) {
        this.tournamentPrizes.pop();
      }
    }
  },
  beforeDestroy() {
    this.tournamentPrizes = [];
  },
  created() {
    if (this.$route.name === "tournament_edit") {
      this.getPlacePtsFromGroupResults();
    }
    
    if (this.$route.name === "create_tournament") {
            
      this.tournamentPrizes = [
          {
            id: 1,
            place: 1,
            prize_data: {
              dollars: 0,
            },
          },
        ];
    }
  },
};
</script>
