<template>
  <div class="edit-fake-user-wrapper">
    <div v-if="isLoadingGames" class="loading-wrapper">
      <b-spinner class="spinner"></b-spinner>
    </div>
    <b-form v-else @submit="submitForm">
      <b-form-group label="Название" label-for="name">
        <b-form-input v-model="formModel.name" id="name" type="text" placeholder="Введите название команды" required/>
      </b-form-group>
      <b-form-group label="Дисциплина" label-for="game">
        <b-form-select :options="gamesForSelect" v-model="formModel.game_id"
                       placeholder="Выберите дисциплину"></b-form-select>
      </b-form-group>
      <b-form-group label="Фейк пользователи" label-for="members">
        <div v-for="member in formModel.members" :key="'memberId' + member.id">
          <GameProfile :gameProfile="getGameProfile(member)"/>
          <b-button :disabled="isLoadingDeleteFakeUser" @click="deleteFakeUser(member.id)" type="submit" variant="danger" class="mb-3">
            {{ isLoadingDeleteFakeUser ? 'Удаляем...' : 'Удалить' }}
          </b-button>
        </div>
        <b-btn @click="createFakeUser" :disabled="isLoadingCreateFakeUser"
               :variant="isLoadingCreateFakeUser ? 'primary' : 'info'">
          {{ isLoadingCreateFakeUser ? 'Идет создание...' : 'Создать фейк пользователя' }}
        </b-btn>
      </b-form-group>
      <b-form-group label="Лого" label-for="logo">
        <b-form-file id="select-image"
                     ref="default"
                     placeholder="Кликните, чтобы выбрать изображение"
                     @change="loadLogoForTeam"
                     browse-text="Выбрать"
        />
      </b-form-group>
      <div class="d-flex justify-content-center" v-if="uploadedImage(formModel?.logo?.['original_url'])">
        <img :src="uploadedImage(formModel?.logo?.['original_url'])"
             class="render-image"
             alt=""
        />
      </div>
      <b-alert v-if="errorText" show variant="danger">{{ errorText }}</b-alert>
      <div class="form-footer">
        <b-button :disabled="isLoadingSaveForm" type="submit" variant="primary">
          {{ isLoadingSaveForm ? 'Сохраняем...' : 'Сохранить' }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import api from "@/api/api";
import {mapGetters} from "vuex";
import GameProfile from "@/components/FakeUserGameProfile.vue";
import {uploadedImage} from "@/common/imageUtils";

export default {
  name: "EditFakeTeam",
  components: {GameProfile},
  props: {
    params: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      uploadedImage,
      isLoadingSaveForm: false,
      errorText: '',
      formModel: {
        name: '',
        game_id: 0,
        members: [],
        logo: {original_url: ''}
      },
      isLoadingGames: false,
      isLoadingCreateFakeUser: false,
      isLoadingDeleteFakeUser: false,
    }
  },
  created() {
    (async () => {
      this.isLoadingGames = true
      await this.$store.dispatch('moduleFakeTeams/getGames')
      await this.getDefaultData()
      this.isLoadingGames = false
    })()
  },
  computed: {
    ...mapGetters('moduleFakeTeams', ['gamesForSelect']),
  },
  methods: {
    async submitForm(event) {
      try {
        event.preventDefault()
        this.isLoadingSaveForm = true
        this.errorText = null

        // set formModel data for update team
        const formData = new FormData()
        formData.append("name", this.formModel.name)
        formData.append("game_id", this.formModel.game_id)
        const imageFile = this.$refs.default.$el.children[0].files[0]
        if (imageFile) formData.append('logo', imageFile)

        await api.fakeTeams.update(this.params.id, formData)

        // update fake teams list
        await this.$store.dispatch('moduleFakeTeams/getFakeTeams', {
          page: localStorage.getItem('pageNumberFakeTeams'),
        })

        await this.$emit('closeModal')
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSaveForm = false
      }
    },
    async getDefaultData() {
      // Set fake team data
      const fakeTeam = await this.$store.dispatch('moduleFakeTeams/getDefaultDataForFakeTeam', this.params.id)
      this.formModel.name = fakeTeam.name
      this.formModel.game_id = fakeTeam.game_id
      this.formModel.members = fakeTeam.members.map(({ id, game_profiles }) => ({ id, game_profiles }))
      this.formModel.logo = fakeTeam.logo || {}
    },
    getGameProfile(member) {
      return member.game_profiles.find(({ game_id }) => game_id === this.formModel.game_id) || {}
    },
    async createFakeUser() {
      try {
        this.isLoadingCreateFakeUser = true
        this.errorText = null
        await api.fakeUsers.store({team_id: this.params.id})

        // update default data in modal
        await this.getDefaultData()

        // update fake teams list
        await this.$store.dispatch('moduleFakeTeams/getFakeTeams', {
          page: localStorage.getItem('pageNumberFakeTeams'),
        })
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingCreateFakeUser = false
      }
    },
    async deleteFakeUser(id) {
      try {
        this.isLoadingDeleteFakeUser = true
        this.errorText = null
        await api.fakeUsers.delete(id)

        // update default data in modal
        await this.getDefaultData()

        // update fake teams list
        await this.$store.dispatch('moduleFakeTeams/getFakeTeams', {
          page: localStorage.getItem('pageNumberFakeTeams'),
        })
      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingDeleteFakeUser = false
      }
    },
    loadLogoForTeam(event) {
      this.formModel.logo.original_url = event.target.files[0]
    },
  },
}
</script>

<style scoped lang="scss">
.edit-fake-user-wrapper {
  .form-group {
    width: 100%;
  }

  .form-footer {
    border-top: 1px solid #dee2e6;
    text-align: right;
    padding-top: 15px;
  }
}
.render-image {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  object-fit: cover;
}
</style>
