import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"
import Vue from "vue";

export default {
    state: () => {
        return {
            showEvent: {},
            teamsForLive: [],
            currentGroupMatchesBook: [],
            selectedMatchIdBook: null,
            selectedMatchId: null,
            selectedMatchName: '',
            selectedMatchLogin: '',
            selectedMatchPassword: '',
            selectedMatchDate: '',
            matchWithBookmaker: false,
            matchHostCount: 4,
            liveMembersCount: null,
            liveTeamsCount: null,
        }
    },

    mutations: {
        [mutypes.SET_EVENT_SHOW]: (state, value) => state.showEvent = value,
        [mutypes.SET_TEAMS_FOR_LIVE]: (state, value) => state.teamsForLive = value,
        [mutypes.SET_TOURNAMENT_CURRENT_MATCHES_BOOK]: (state, value) => state.currentGroupMatchesBook = value,
        [mutypes.SET_TOURNAMENT_CURRENT_MATCHES_SELECTED_MATCH_ID_BOOK]: (state, value) => state.selectedMatchIdBook = value,
        [mutypes.SET_SELECTED_MATCH]: (state, value) => state.selectedMatchId = value,
        [mutypes.SET_SELECTED_MATCH_NAME]: (state, value) => state.selectedMatchName = value,
        [mutypes.SET_SELECTED_MATCH_LOGIN]: (state, value) => state.selectedMatchLogin = value,
        [mutypes.SET_SELECTED_MATCH_PASSWORD]: (state, value) => state.selectedMatchPassword = value,
        [mutypes.SET_SELECTED_MATCH_DATE]: (state, value) => state.selectedMatchDate = value,
        [mutypes.SET_MATCH_WITH_BOOKMAKER]: (state, value) => state.matchWithBookmaker = value,
        [mutypes.SET_MATCH_HOST_COUNT]: (state, value) => state.matchHostCount = value,
        [mutypes.SET_MATCH_LIVE_COUNT]: (state, value) => state.liveMembersCount = value,
        [mutypes.SET_MATCH_LIVE_TEAMS_COUNT]: (state, value) => state.liveTeamsCount = value,
    },

    getters: {
        getEventShow: state => state.showEvent,
        getTeamsForLive: state => state.teamsForLive,
        getTournamentCurrentMatchesBook: state => state.currentGroupMatchesBook,
        getTournamentCurrentMatchesSelectedMatchIdBook: state => state.selectedMatchIdBook,
        getSelectedMatch: state => state.selectedMatchId,
        getSelectedMatchName: state => state.selectedMatchName,
        getSelectedMatchLogin: state => state.selectedMatchLogin,
        getSelectedMatchPassword: state => state.selectedMatchPassword,
        getSelectedMatchDate: state => state.selectedMatchDate,
        getMatchWithBookmaker: state => state.matchWithBookmaker,
        getMatchHostCount: state => state.matchHostCount,
        getLiveMembersCount: state => state.liveMembersCount,
        getLiveTeamsCount: state => state.liveTeamsCount,
    },

    actions: {
        async showGroupMatchesBook({commit, getters}, id) {
            let response = await api.book.showMatchesBook(id)
            commit(mutypes.SET_TOURNAMENT_CURRENT_MATCHES_BOOK, response.data.group_matches)
            commit(mutypes.SET_TOURNAMENT_CURRENT_MATCHES_SELECTED_MATCH_ID_BOOK, response.data.group_matches[0]?.id);
            commit(mutypes.SET_SELECTED_MATCH, response.data.group_matches[0]?.id)
            commit(mutypes.SET_SELECTED_MATCH_NAME, response.data.group_matches[0]?.name)
            commit(mutypes.SET_SELECTED_MATCH_LOGIN, response.data.group_matches[0]?.lobby_number)
            commit(mutypes.SET_SELECTED_MATCH_PASSWORD, response.data.group_matches[0]?.password)
            commit(mutypes.SET_SELECTED_MATCH_DATE, response.data.group_matches[0]?.start_time)
            commit(mutypes.SET_MATCH_WITH_BOOKMAKER, response.data.group_matches[0]?.with_bookmaker);
            commit(mutypes.SET_MATCH_HOST_COUNT, response.data.group_matches[0]?.host_count);
        },
        showSelectedMatch({commit, getters}, match) {
            commit(mutypes.SET_SELECTED_MATCH, match.id);
            commit(mutypes.SET_SELECTED_MATCH_NAME, match.name);
            commit(mutypes.SET_SELECTED_MATCH_LOGIN, match.lobby_number);
            commit(mutypes.SET_SELECTED_MATCH_PASSWORD, match.password);
            commit(mutypes.SET_SELECTED_MATCH_DATE, match.start_time);
            commit(mutypes.SET_MATCH_WITH_BOOKMAKER, match.with_bookmaker);
            commit(mutypes.SET_MATCH_HOST_COUNT, match.host_count);

        },
        async showEventResult({commit, getters}, matchId) {
            commit(mutypes.SET_EVENT_SHOW, null);
            let response = await api.book.showEvent(matchId);
            commit(mutypes.SET_EVENT_SHOW, response.data.event);
            commit(mutypes.SET_TEAMS_FOR_LIVE, response.data.event.teams);
        },
        async showEventResultWithSlotSort({commit, getters}, matchId) {
            //commit(mutypes.SET_EVENT_SHOW, null);
            let response = await api.book.showEvent(matchId)
            commit(mutypes.SET_EVENT_SHOW, response.data.event)
            commit(mutypes.SET_TEAMS_FOR_LIVE, response.data.event.teams.sort(
                (a, b) => a.slot_number - b.slot_number)
            );
        },
        async showMembersTournamentCount({commit, getters}) {
            let response = await api.book.showEventTournamentStatus(getters.getSelectedMatch);
            commit(mutypes.SET_MATCH_LIVE_COUNT, response.data.live_members_count);
            commit(mutypes.SET_MATCH_LIVE_TEAMS_COUNT, response.data.live_teams_count);
        },

        async storeRate({commit, getters, dispatch}, params) {
            // let allRates = [];
            // getters.getTeamsForLive.forEach((e) => {
            //         if (e.rate != 0) {
            //             allRates.push(e.rate);
            //         }
            //     }
            // )
            //
            // allRates = [...new Set(allRates)]

            // if (allRates.length < getters.getTeamsForLive.length){
            //     this._vm.$toast.error("У одной или нескольких команд неуникальный rate");
            //     return;
            // }


            await api.book.storeRate(params.matchId, {
                type: params.type,
                actions: getters.getTeamsForLive
            })
                .then((res) => {

                    const matches = getters.getTournamentCurrentMatchesBook.map((match) => {
                        if (match.id === params.matchId) {
                            getters.getEventShow.type = "not_started";
                        }
                        return match;
                    })

                    commit(mutypes.SET_TOURNAMENT_CURRENT_MATCHES_BOOK, matches);

                    dispatch('showEventResult', params.matchId);
                    this._vm.$toast.success("Rate добавлен");
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data.message);
                    }
                    this._vm.$toast.error(error.response.data.message);
                });
        },

        async storePlace({commit, getters, dispatch}, params) {

            //@todo вынести в функцию

            const teams = getters.getEventShow.teams.map((team) => {
                if (team.team_id == params.teamId) {
                    team.place = params.place;
                }
                return team;
            })
            const eventShow = getters.getEventShow;
            eventShow.teams = teams;

            commit(mutypes.SET_EVENT_SHOW,eventShow);



            // временно убираем
            // await api.book.storeRate(params.matchId, {
            //     type: params.type,
            //     actions: teams
            // })
            //     .then((res) => {
            //         dispatch('showEventResult', params.matchId);
            //         this._vm.$toast("place изменен");
            //
            //     })
            //     .catch((res) => {
            //         this._vm.$toast.error("Необходимо заполнить place");
            //     });
        },


        async storeLive({commit, getters}, params) {
            let filteredTeams = []
            getters.getTeamsForLive.forEach((e) => {
                    filteredTeams.push({team_id: e.team_id, rate: e.rate})
                }
            )
            await api.book.storeRate(params.matchId, {
                type: params.type,
                actions: filteredTeams
            })
                .then((res) => {
                    const matches = getters.getTournamentCurrentMatchesBook.map((match) => {
                        if (match.id === params.matchId) {
                            getters.getEventShow.type = params.type || "live";
                        }
                        return match;
                    })

                    commit(mutypes.SET_TOURNAMENT_CURRENT_MATCHES_BOOK, matches);
                })
        },
        async storeEnd({commit, getters}, params) {
            let filteredTeams = []
            getters.getEventShow.teams.forEach((e) => {
                    filteredTeams.push({team_id: e.team_id, rate: e.rate, place: e.place})
                }
            )

            await api.book.storeRate(params.matchId, {
                type: params.type,
                actions: filteredTeams
            })
                .then((res) => {
                    const matches = getters.getTournamentCurrentMatchesBook.map((match) => {
                        if (match.id === params.matchId) {
                            getters.getEventShow.type = params.type || "ended";
                        }
                        return match;
                    })

                    commit(mutypes.SET_TOURNAMENT_CURRENT_MATCHES_BOOK, matches);
                }).catch((error) => {
                    if(error.response) {
                        Vue.toasted.error(error.response.data.message, {
                            position: 'top-right',
                            duration: 3000
                        });
                    }
                })
        },
        async addRate({commit, getters}, params) {
            const teams = getters.getTeamsForLive;
            const filterTeams = teams.filter((team) => Number.parseInt(team.team_id) !== Number.parseInt(params.team_id));
            filterTeams.push(params)
            commit(mutypes.SET_TEAMS_FOR_LIVE, filterTeams);
        },

        async storeKill({getters, dispatch}, params) {
            await api.book.storeKill(params.matchId, {
                token: params.token,
                actions: [{user_id: params.user_id, kill: params.kill, death: params.death}]
            }).then((res) => {
                dispatch('showEventResultWithSlotSort', params.matchId);
            })
                .catch((error) => {
                    this._vm.$toast.error(error.response.data.message);
                });
        },

        async storeAllTeamsWithDeath({getters, dispatch}, params) {

            let liveTeam = getters.getEventShow.teams.find((team) => {
                return team.team_id === params.teamId;
            })
            const sendTeamToDeath = liveTeam.members.map((member) => {
                return {
                    user_id: member.user_id,
                    // kill: member.kills,
                    kill: 0,
                    death: 1
                }
            })


            await api.book.storeKill(params.matchId, {
                token: params.token,
                actions: sendTeamToDeath
            }).then((res) => {
                dispatch('showEventResultWithSlotSort', params.matchId)
                //dispatch('showEventResult', params.matchId)
            })
                .catch((error) => {
                    this._vm.$toast.error(error.response.data.message);
                });
        },
        async storeMatch({commit, getters, dispatch}, params) {
            await api.book.storeMatch({
                group_matches: [{
                    name: params.name,
                    group_id: params.group_id,
                    lobby_number: params.lobby_number,
                    password: params.password,
                    start_time: params.start_time,
                    status: params.status,
                    host_count: params.host_count,
                    with_bookmaker: params.with_bookmaker
                }]
            })
                .then((res) => {
                    const matches = getters.getTournamentCurrentMatchesBook
                    commit(mutypes.SET_TOURNAMENT_CURRENT_MATCHES_BOOK, matches);
                    dispatch('showGroupMatchesBook', params.group_id);
                    this._vm.$toast.success("Матч добавлен");
                })
                .catch((error) => {
                    this._vm.$toast.error(error.response.data.message);
                })
        },
        async deleteMatch({commit, getters}, params) {
            await api.book.deleteMatch(params.matchId);
            let matchValue = getters.getTournamentCurrentMatchesBook.filter((el) => el.id !== params.matchId)
            commit(mutypes.SET_TOURNAMENT_CURRENT_MATCHES_BOOK, matchValue)
        },
        async updateMatchBook({commit, getters, dispatch}, params) {
            await api.book.updateMatch({
                group_matches: [{
                    id: params.matchId,
                    name: params.name,
                    group_id: params.group_id,
                    lobby_number: params.lobby_number,
                    password: params.password,
                    start_time: params.start_time,
                    status: params.status,
                    host_count: params.host_count,
                    with_bookmaker: params.with_bookmaker
                }]
            })
                .then((res) => {
                    const matches = getters.getTournamentCurrentMatchesBook
                    commit(mutypes.SET_TOURNAMENT_CURRENT_MATCHES_BOOK, matches);
                    dispatch('showGroupMatchesBook', params.group_id);
                    this._vm.$toast.success("Матч обновлен");
                })
                .catch((error) => {
                    this._vm.$toast.error(error.response.data.message);
                })

        },
    }
}