export const AWAIT = 'await';
export const REJECTED = 'rejected';
export const ACCEPTED = 'accepted';
export const DECLINE = 'decline';
export const DELETED = 'deleted';

export const NameOfStatus = {
    [AWAIT]: 'В ожидании',
    [REJECTED]: 'Отказано',
    [ACCEPTED]: 'Принято',
    [DECLINE]: 'Отозвано',
    [DELETED]: 'Удалено',
}
export const StyleOfStatus = {
    [AWAIT]: 'bg-warning rounded p-1',
    [REJECTED]: 'bg-danger rounded p-1 ',
    [ACCEPTED]: 'bg-success rounded p-1 ',
    [DECLINE]: 'bg-info rounded p-1',
    [DELETED]: 'bg-secondary rounded p-1',
}