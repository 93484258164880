import adminHttp from "/src/api/adminHttp";
const END_POINT = '/api/admin/role'

export default {
    /**
     * GET: GET
     * @returns {Promise<AxiosResponse<any>>}
     */
    indexRoles: () => adminHttp.get(END_POINT),
    
    /**
     * Get: SHOW
     * @returns {Promise<AxiosResponse<any>>}
     */
    showRole: (id) => adminHttp.get(END_POINT + `/${id}`),

    /**
     * Post: STORE
     * @returns {Promise<AxiosResponse<any>>}
     */
    storeRole: (params) => adminHttp.post(END_POINT, params),

    /**
     * PUT: UPDATE
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateRole: (id,params) => adminHttp.put(END_POINT + `/${id}`, params),

    /**
     * DELETE: Destroy
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteRole: (id) => adminHttp.delete(END_POINT + `/${id}`),

    /**
     * GET: GET
     * @returns {Promise<AxiosResponse<any>>}
     */
    getPermissions: () => adminHttp.get('api/admin/permission')
}